/**
 *Created by Mikael Lindahl on 2024-03-18
 */
import { CreatedByType } from "../general/action.types";
import { Types } from "mongoose";
import { IntegrationImportJob } from "./integration_import_job.types";

const companyId = new Types.ObjectId("aaaabbbbccccddddeeeefffe"); // replace with actual ObjectId
const groupId1 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaaaa"); // replace with actual ObjectId
const groupId2 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaaab"); // replace with actual ObjectId
const groupId3 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaabb"); // replace with actual ObjectId
const groupId4 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaabbb"); // replace with actual ObjectId
const groupId5 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaabbbb"); // replace with actual ObjectId
const groupId6 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaabbbbb"); // replace with actual ObjectId

const id1 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaaac"); // replace with actual ObjectId
const id2 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaacc"); // replace with actual ObjectId
const id3 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaaaccc"); // replace with actual ObjectId
const id4 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaacccc"); // replace with actual ObjectId
const id5 = new Types.ObjectId("aaaaaaaaaaaaaaaaaaaccccc"); // replace with actual ObjectId
const id6 = new Types.ObjectId("aaaaaaaaaaaaaaaaaacccccc"); // replace with actual ObjectId
const id7 = new Types.ObjectId("aaaaaaaaaaaaaaaaaccccccc"); // replace with actual ObjectId
const id8 = new Types.ObjectId("aaaaaaaaaaaaaaaacccccccc"); // replace with actual ObjectId
const id9 = new Types.ObjectId("aaaaaaaaaaaaaaaccccccccc"); // replace with actual ObjectId

export const integrationImportJobTestData: IntegrationImportJob[] = [
  // Completed import job
  {
    _id: id1, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 2,
    createdAt: new Date("2024-03-14T00:00:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Sven Svensson",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId1, // replace with actual ObjectId
    groupMainImportName: "import_accounts",
    groupPartImportName: "import_company",
    groupPosition: 0,
    groupSize: 4,
    groupStatus: "running",
    locale: "sv",
    name: "import_accounts", // replace with actual value
    status: "completed",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 1,
        progress: {
          processedNumberOfEntries: 10,
          totalNumberOfEntries: 10,
        },
        status: "completed",
        type: "single_json",
      },
    ],
    updatedAt: new Date("2024-03-14T00:00:00.000Z"),
  },

  // Completed import job only page fetch
  {
    _id: id2, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-14T01:00:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Johan Johansson",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId1, // replace with actual ObjectId
    groupMainImportName: "import_accounts",
    groupPartImportName: "import_fiscal_years",
    groupPosition: 1,
    groupSize: 4,
    groupStatus: "running",
    locale: "sv",
    name: "import_accounts", // replace with actual value
    status: "completed",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        dataKey: "test-key",
        currentBatchNumber: 1,
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
    ],
    updatedAt: new Date("2024-03-14T01:00:00.000Z"),
  },
  // Started import job page fetch completed and url fetch started
  {
    _id: id3, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-14T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId1, // replace with actual ObjectId
    groupMainImportName: "import_accounts",
    groupPartImportName: "import_customers",
    groupPosition: 2,
    groupSize: 4,
    groupStatus: "running",
    locale: "sv",
    name: "import_accounts", // replace with actual value
    status: "running",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 1,
        dataKey: "test-key",
        index: 1,
        progress: {
          processedNumberOfEntries: 4, // 1*4
          totalNumberOfEntries: 10,
        },
        status: "running",
        type: "single_json",
      },
    ],
    updatedAt: new Date("2024-03-14T01:10:00.000Z"),
  },
  // Pending import job
  {
    _id: id4, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 0,
    createdAt: new Date("2024-03-14T01:20:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId1, // replace with actual ObjectId
    groupMainImportName: "import_accounts",
    groupPartImportName: "import_accounts",
    groupPosition: 3,
    groupSize: 4,
    groupStatus: "running",
    locale: "sv",
    name: "import_accounts", // replace with actual value
    status: "pending",
    updatedAt: new Date("2024-03-14T01:20:00.000Z"),
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        status: "pending",
        type: "list_json",
      },
    ],
  },
  // Page job completed and file job started
  {
    _id: id5, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-13T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId2, // replace with actual ObjectId
    groupMainImportName: "import_customers",
    groupPartImportName: "import_customers",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "running",
    locale: "sv",
    name: "import_customers", // replace with actual value
    status: "running",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        temporaryStorage: {
          batchSize: 100,
          dataGroupId: new Types.ObjectId(),
          use: true,
        },
        index: 1,
        progress: {
          estimatedFileProcessingInSeconds: 500,
        },
        status: "running",
        type: "file_sie",
      },
    ],
    updatedAt: new Date("2024-03-13T01:10:00.000Z"),
  },
  // Pending import job
  {
    _id: id6, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 0,
    createdAt: new Date("2024-03-14T01:20:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId3, // replace with actual ObjectId
    groupMainImportName: "import_fiscal_years",
    groupPartImportName: "import_fiscal_years",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "pending",
    locale: "sv",
    name: "import_fiscal_years", // replace with actual value
    status: "pending",
    updatedAt: new Date("2024-03-14T01:20:00.000Z"),
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "pending",
        type: "list_json",
      },
    ],
  },

  // Error in step import job
  {
    _id: id7, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-12T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId4, // replace with actual ObjectId
    groupMainImportName: "import_units",
    groupPartImportName: "import_units",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "running",
    locale: "sv",
    name: "import_units", // replace with actual value
    status: "running",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        error: {
          message: "Ops ... an error occurred",
          stack: "This is a stack",
          timestamp: new Date("2024-03-14T01:10:00.000Z"),
        },
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "error",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 1,
        dataKey: "test-key",
        index: 1,
        progress: {
          processedNumberOfEntries: 4, // 1*4
          totalNumberOfEntries: 10,
        },
        status: "running",
        type: "single_json",
      },
      {
        index: 2,
        batchSize: 100,
        type: "post_processing_voucher_generate_incomes",
        status: "pending",
      },
    ],
    updatedAt: new Date("2024-03-12T01:10:00.000Z"),
  },

  // Error in step import job
  {
    _id: id8, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 2,
    createdAt: new Date("2024-03-14T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId5, // replace with actual ObjectId
    groupMainImportName: "import_fiscal_years",
    groupPartImportName: "import_fiscal_years",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "error",
    error: {
      message: "job finished with errors",
    },
    locale: "sv",
    name: "import_fiscal_years", // replace with actual value
    status: "error",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", index: 1 },
            { url: "a-url", index: 2 },
            { url: "a-url", index: 3 },
          ],
          [
            { url: "a-url", index: 4 },
            { url: "a-url", index: 5 },
            { url: "a-url", index: 6 },
          ],
          [
            { url: "a-url", index: 7 },
            { url: "a-url", index: 8 },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 1,
        dataKey: "test-key",
        index: 1,
        error: {
          message: "Another error occurred",
          timestamp: new Date("2024-03-14T01:10:00.000Z"),
        },
        progress: {
          processedNumberOfEntries: 4, // 1*4
          totalNumberOfEntries: 10,
        },
        status: "error",
        type: "single_json",
      },
    ],
    updatedAt: new Date("2024-03-14T01:10:00.000Z"),
  },

  // Completed job in step import job
  {
    _id: id9, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-14T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId6, // replace with actual ObjectId
    groupMainImportName: "import_currencies",
    groupPartImportName: "import_currencies",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "completed",
    locale: "sv",
    name: "import_currencies", // replace with actual value
    status: "completed",
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 1,
        dataKey: "test-key",
        index: 1,
        progress: {
          processedNumberOfEntries: 10, // 1*4
          totalNumberOfEntries: 10,
        },
        status: "completed",
        type: "single_json",
      },
    ],
    updatedAt: new Date("2024-03-14T01:10:00.000Z"),
  },

  // Error job
  {
    _id: id9, // replace with actual ObjectId
    companyId, // replace with actual ObjectId
    currentStepIndex: 1,
    createdAt: new Date("2024-03-14T01:10:00.000Z"),
    createdBy: {
      id: String(new Types.ObjectId()), // replace with actual ObjectId
      name: "Jane Doe",
      type: CreatedByType.UserAction,
    }, // replace with actual ObjectId
    groupId: groupId6, // replace with actual ObjectId
    groupMainImportName: "import_currencies",
    groupPartImportName: "import_currencies",
    groupPosition: 0,
    groupSize: 1,
    groupStatus: "error",
    locale: "sv",
    name: "import_currencies", // replace with actual value
    status: "error",
    error: {
      message: "job finished with errors",
      stack: "This is a stack",
      timestamp: new Date("2024-03-14T01:10:00.000Z"),
    },
    steps: [
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
          [
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
            { url: "a-url", params: { latestUpdatedAt: new Date() } },
          ],
        ],
        currentBatchNumber: 2,
        dataKey: "test-key",
        index: 0,
        progress: {
          processedNumberOfEntries: 8,
          totalNumberOfEntries: 8,
        },
        status: "completed",
        type: "list_json",
      },
      {
        baseUrl: "test-url",
        batchSize: 100,
        batchesToFetch: [
          [
            { url: "test-url-1" },
            { url: "test-url-2" },
            { url: "test-url-3" },
            { url: "test-url-4" },
          ],
          [
            { url: "test-url-5" },
            { url: "test-url-6" },
            { url: "test-url-7" },
            { url: "test-url-8" },
          ],
          [{ url: "test-url-9" }, { url: "test-url-10" }],
        ],
        currentBatchNumber: 1,
        dataKey: "test-key",
        index: 1,
        progress: {
          processedNumberOfEntries: 10, // 1*4
          totalNumberOfEntries: 10,
        },
        status: "completed",
        type: "single_json",
      },
    ],
    updatedAt: new Date("2024-03-14T01:10:00.000Z"),
  },
];
