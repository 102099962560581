export const getDimensionColumns = (dimensions = []) => {
  return dimensions
    .filter((dimension) => dimension.active)
    .map((dimension) => ({
      name: `dimensionId${dimension.number}`,
      label: dimension.name,
      type: "autocomplete",
      items: dimension.items.map((subitem) => {
        return {
          label: `${subitem.code} - ${subitem.name}`,
          value: subitem._id,
        };
      }),
    }));
};

export const getSelectOptions = (items = [], labelField = "name") =>
  items.map((item) => {
    return {
      label:
        typeof labelField === "function" ? labelField(item) : item[labelField],
      value: item._id,
      isActive: item?.active,
    };
  });

export const getAutocompleteOptions = (items = [], labelField = "name") =>
  items.map((item) => {
    return {
      label:
        typeof labelField === "function" ? labelField(item) : item[labelField],
      id: item._id,
    };
  });

export const findById = (items, id) => items?.find((item) => item._id === id);
