/**
 *Created by Mikael Lindahl on 2024-04-08
 */

import useTranslation from "src/hooks/useTranslationWrapper";
import { Box, SxProps, Typography } from "@mui/material";
import Switch from "src/components/Basic/Simple/Switches/AntSwitch";
import { Theme } from "@mui/material/styles";

type SwitchWrapperProps = {
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  sx?: SxProps<Theme>;
};

const SwitchWrapper = ({ label, sx, ...rest }: SwitchWrapperProps) => {
  const [t] = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        columnGap: "var(--space-8px)",
        flexDirection: "row",
        alignItems: "center",
        ...(sx ? sx : {}),
      }}
    >
      <Switch {...rest} inputProps={{ "aria-label": "controlled" }} />
      {label && <Typography sx={{ fontSize: "12px" }}>{t(label)}</Typography>}
    </Box>
  );
};

export default SwitchWrapper;
