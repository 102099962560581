/**
 *Created by Mikael Lindahl on 2024-06-11
 */
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { CompanyConfigGet } from "src/accurasee-backend-types/app/company_config/company_config.types";
import {
  getStructure,
  toSubmitData,
} from "./CompanyConfigIntegrationsGetStructure";
import { Grid, Typography } from "@mui/material";
import {
  useGetCompanyConfigSuperAdminQuery,
  useUpdateCompanyConfigsSuperAdminMutation,
} from "src/redux/services/CompanyConfigsService";
import { useTranslation } from "react-i18next";

type CompanyConfigIntegrationsTPRops = {
  companyId: string;
};

const CompanyConfigIntegrations = (props: CompanyConfigIntegrationsTPRops) => {
  const [t] = useTranslation();

  const { data: companyConfigsQuery, isLoading } =
    useGetCompanyConfigSuperAdminQuery(props.companyId);
  const config = companyConfigsQuery?.data;

  const [updateCompanyConfigsSuperAdmin] =
    useUpdateCompanyConfigsSuperAdminMutation();

  const initialFormData = config;

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<CompanyConfigGet>({
    getStructure,
    initialFormData,
  });

  const submitData = toSubmitData({ data: formData, initData: config });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "Integrations",
      data: {
        update: toSubmitData({ data: formData, initData: config }),
      },
      dataId: String(config?._id),
      apiMutations: {
        update: updateCompanyConfigsSuperAdmin,
      },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit({ action: "update" });
      }}
      ref={refForm}
    >
      <SectionContainer isLoading={isLoading}>
        <Section
          key="company-config-resources-integrations"
          label="Resources configs"
        >
          {BuildItems({
            items: structure.items.filter(
              (item) => item.itemType === "cronjobs",
            ),
            ...commonPropsBuildItem,
          })}
          <Grid item md={6}>
            <Typography color="var(--black-7)" variant="body2">
              {t("Requests")}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography color="var(--black-7)" variant="body2">
              {t("Limits")}
            </Typography>
          </Grid>
          {BuildItems({
            items: structure.items.filter(
              (item) => item.itemType === "service",
            ),
            ...commonPropsBuildItem,
          })}
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer>
        <ButtonGroup>
          <ButtonCancel
            onClick={() => {
              setFormData(initialFormData);
            }}
          />

          <ButtonUpdate
            id={"update-company-config-integrations"}
            initialSubmitData={{}}
            isSubmitting={isSubmitting}
            onSubmit={() => {
              refForm.current.requestSubmit();
            }}
            isValid={isFormValid}
            submitData={submitData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </form>
  );
};

export default CompanyConfigIntegrations;
