import { useParams } from "react-router-dom";

import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";

const BudgetAdjustment = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  return (
    <PageWithTopPadding
      label="Budget adjustment"
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Budget adjustment" },
      ]}
      isLoading={isLoading}
    >
      <div>Coming soon</div>
    </PageWithTopPadding>
  );
};

export default BudgetAdjustment;
