import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { Namespaces } from "../../../../../accurasee-backend-types/app/translation/translation.types";

interface StructureProps {
  key: string;
  namespace: Namespaces;
}

interface GetStructureExtraProps {
  isUniquePair: (data: { key: string; namespace: Namespaces }) => boolean;
}

const getStructure: UseFormContainerGetStructure<
  StructureProps,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer = {
    items: [
      {
        type: "text_input",
        dataName: "key",
        label: "Key",
        required: true,
        validate: (props) => Boolean(extraProps?.isUniquePair(props.data)),
        getErrorText: "Need to provide a unique key namespace pair",
        setFormData,
      },
      {
        type: "text",
        dataName: "namespace",
        label: "Namespace",
        validate: (props) => Boolean(extraProps?.isUniquePair(props.data)),
        getErrorText: "Need to provide a unique key namespace pair",
        required: true,
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
