import { UserFormTableGetStructureOptional } from "../../../../hooks/useFormTable";
import {
  ProjectTrackerPercentageOfCompletionVariant2,
  ProjectTrackerPercentageOfCompletionVariant2Sub,
} from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";

export type GetStructureExtraProps = {
  nameLabel: "Region" | "Company" | "Project";
};

export type PercentageOfCompletionItemTypes = "main" | "sub" | "main-and-sub";

export const getStructure: UserFormTableGetStructureOptional<
  | ProjectTrackerPercentageOfCompletionVariant2
  | ProjectTrackerPercentageOfCompletionVariant2Sub,
  GetStructureExtraProps,
  PercentageOfCompletionItemTypes
> = () => {
  return {
    items: [
      {
        type: "text",
        dataName: "userName",
        headerLabel: "Project leader",
        itemType: "main",
      },
      {
        type: "text",
        dataName: "projectName",
        headerLabel: "Project",
        itemType: "sub",
      },
      {
        type: "text",
        dataName: "projectCost",
        headerLabel: "Project cost",
        itemType: "sub",
      },
      {
        type: "text",
        dataName: "invoicedProject",
        headerLabel: "Invoiced project",
        itemType: "sub",
      },
      {
        type: "text_currency",
        dataName: "alterationAndAdditionalCost",
        headerLabel: "ATA cost",
        itemType: "sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "alterationAndAdditionalIncome",
        headerLabel: "ATA Income",
        itemType: "sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "adjustments",
        headerLabel: "Adjustments",
        itemType: "sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "estimatedProjectResult",
        headerLabel: "Est. Project Result",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
      {
        type: "percent",
        dataName: "estimatedCoverageRate",
        headerLabel: "Est. coverage rate",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "accountingResults",
        headerLabel: "Accounting results",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "percentageOfCompletionCurrent",
        headerLabel: "Current POC",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
    ],
  };
};
