/**
 *Created by Mikael Lindahl on 2023-03-14
 */

import { UserFormTableGetStructure } from "../../../hooks/useFormTable";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import _c from "../../../constants/Constants";
import getSelectOptions from "../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  articles: Article[] | undefined;
  articleIdsUsed: string[];
  currencyCode?: string;
  isIndex?: boolean;
  isMilestone?: boolean;
  isView?: boolean;
  isModal?: boolean;
};

export const getStructure: UserFormTableGetStructure<
  ContractArticle,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  if (!extraProps?.isView) {
    return {
      items: [
        {
          type: "autocomplete",
          dataName: "articleId",
          headerLabel: "Article",
          options: getSelectOptions({
            data: extraProps?.articles,
            value: "_id",
            label: (a) => `${a.number} - ${a.name}`,
          }),
          setFormData,
          required: true,
          disabled: extraProps?.isModal,
          sx: { minWidth: 200 },
        },
        {
          cellLabel: "Default article",
          type: "switch",
          dataName: "defaultArticle",
          setFormDataCustom: (props) => {
            let newData = [...props.data];
            for (let rowIndex in props.data) {
              newData[rowIndex].defaultArticle =
                (Number(rowIndex) === props.rowIndex && props.value) || false;
            }
            setFormData(newData);
          },
          getErrorText: "Please mark a default article",
          validate: (props) => !props.data.every((d) => !d.defaultArticle),
        },
        {
          headerLabel: "Quantity",
          type: "number_input",
          dataName: "quantity",
          sx: { minWidth: 100 },
          setFormData,
        },
        {
          cellLabel: "Estimate",
          type: "switch",
          dataName: "isQuantityEstimate",
          setFormData,
        },
        {
          cellLabel: "Minimum quantity",
          type: "switch",
          dataName: "quantityMin",
          setFormData,
        },
        {
          type: "number_input",
          dataName: "quantityMinValue",
          showCellWhen: (props) => {
            return Boolean(props.data[props.rowIndex].quantityMin);
          },
          getErrorText: "Need to be greater than zero",
          required: true,
          validate: (props) => {
            const isValid =
              Boolean(props.data[props.rowIndex].quantityMin) &&
              (props.data[props.rowIndex].quantityMinValue === undefined ||
                props.data[props.rowIndex].quantityMinValue > 0);
            return isValid;
          },
          setFormData,
        },
        {
          headerLabel: "Price",
          type: "number_input_currency",
          currencyCode: extraProps?.currencyCode,
          dataName: "price",
          sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
          setFormData,
        },
        {
          cellLabel: "Estimate",
          type: "switch",
          dataName: "isPricePerUnitEstimate",
          setFormData,
        },
        {
          showColumnWhen: !!extraProps?.isIndex,
          cellLabel: "Use index",
          type: "switch",
          dataName: "isIndex",
          setFormData,
        },
        {
          type: "icon_button",
          iconType: "delete",
          dataName: "delete",
          headerLabel: "Delete",
          onClick: ({ rowIndex, data }) => {
            let newFormData = [...data];
            newFormData.splice(rowIndex, 1);
            setFormData(newFormData);
          },
        },
      ],
    };
  } else {
    return {
      items: [
        {
          type: "text",
          dataName: "articleId",
          headerLabel: "Article",
          getValue: (props) => {
            const { number, name } = props.data[props.rowIndex];
            const defaultAtyicle = props.data[props.rowIndex].defaultArticle
              ? "Default article"
              : "";
            return (
              `${number} - ${name}` +
              (defaultAtyicle ? " (Default article)" : "")
            );
          },
        },
        {
          headerLabel: "Quantity",
          type: "text",
          dataName: "isQuantityEstimate",
          getValue: (props) => {
            const { quantity, isQuantityEstimate } = props.data[props.rowIndex];
            return isQuantityEstimate ? `${quantity} (Estimate)` : quantity;
          },
          showColumnWhen: !extraProps?.isMilestone,
        },
        {
          type: "text",
          dataName: "quantityMinValue",
          headerLabel: "Minimum quantity",
          getValue: (props) =>
            props.data[props.rowIndex].quantityMin
              ? props.data[props.rowIndex].quantityMinValue
              : "",
          showColumnWhen: !extraProps?.isMilestone,
        },
        {
          headerLabel: "Price",
          type: "text_currency",
          currencyCode: extraProps?.currencyCode,
          dataName: "isPricePerUnitEstimate",
          getValue: (props) => {
            const { price, isPricePerUnitEstimate } =
              props.data[props.rowIndex];
            return isPricePerUnitEstimate ? `${price} (Estimate)` : price;
          },
          showColumnWhen: !extraProps?.isMilestone,
        },
      ],
    };
  }
};

export default getStructure;
