/**
 *Created by Mikael Lindahl on 2023-03-21
 */

import {
  StructureContainer,
  StructureItemContainer,
  VatTypeTooltip,
} from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";
import {
  INVOICE_PLAN_TYPE,
  DimensionItemInvoicePlan,
  InvoicePlanRow,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { Project } from "src/accurasee-backend-types/app/project/project.types";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { DimensionExtra } from "src/redux/services/DimensionService";
import clone from "src/utils/clone";
import { dicToList, listToDic } from "src/utils/transform";
import getValue from "src/components/Builders/Container/Utils/getValue";
import { lessThan, lessOrEqualThan, setFormDataDatePair } from "src/utils/date";
import getVatType from "src/utils/getVatType";
import { getNestedObjectData, setNestedObjectData } from "src/utils/nestedData";
import calculateDependable from "src/accurasee-backend-types/shared/utils/calculateDependable";
import calculateVat from "src/utils/calculateVat";
import { InvoicePlanRowExtended } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import getDimensionItems, {
  InvoicePlanExtended,
  TmpDimensionItems,
} from "src/utils/getDimensionItems";
import { capitalize } from "lodash";
import toUpdateData from "src/utils/toUpdateData";
import addMonths from "date-fns/addMonths";
import { Index } from "src/accurasee-backend-types/app/index/index.types";
import _c from "src/constants/Constants";
import getSelectOptions, {
  SelectOption,
} from "../../../../../../../utils/getSelectOptions";
import getSelectOptionsDayMonthQuarterYear from "../../../../../../../utils/getSelectOptionsDayMonthQuarterYear";
import getSelectOptionsDayWeekMonth from "../../../../../../../utils/getSelectOptionsDayWeekMonth";
import getCustomerName from "../../../../../../../utils/getCustomerName";
import updateRowTotals from "src/utils/updateRowTotals";
import { FormGlue } from "src/hooks/useFormGlue";
import addRetainedFundsDataToInvoiceRows, {
  culculateRetainedAmount,
} from "src/accurasee-backend-types/shared/utils/addRetainedFundsDataToInvoiceRows";

export type GetStructureExtraProps = {
  articles?: Article[];
  contractType?: ContractType;
  contract?: GetSingleContractWithPermissions;
  currencies?: Currency[];
  currentIndex?: Index;
  customers?: Customer[];
  formGlueInvoiceRows: FormGlue<InvoicePlanRow[]>;
  dimensions?: DimensionExtra[];
  indexOptions: SelectOption[];
  invoicePlanTemplates?: InvoicePlanTemplate[];
  projects?: Project[];
  setVatTypeTooltipOnCustomer: (customer: Customer) => void;
  termsOfPayments?: TermsOfPayment[];
  users?: ReturnUser[];
  vatTypeTooltip: VatTypeTooltip;
};

export type ItemsTypes =
  | "plan"
  | "runningAndPeriodicType"
  | "milestone"
  | "customer"
  | "singleinvoice";

export const getStructure: UseFormContainerGetStructure<
  InvoicePlanExtended,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const dimensionItems = getDimensionItems<InvoicePlanExtended, ItemsTypes>({
    contractDimensions: extraProps?.contractType?.contractDimensions,
    dimensions: extraProps?.dimensions,
    itemType: "plan",
    setFormData,
  });

  const showMilestone: StructureItemContainer<InvoicePlanExtended>["showWhen"] =
    ({ data }) =>
      [
        INVOICE_PLAN_TYPE.milestone,
        INVOICE_PLAN_TYPE.milestone_retained_funds,
      ].includes(data?.type as INVOICE_PLAN_TYPE);

  const structure: StructureContainer<InvoicePlanExtended, ItemsTypes> = {
    allowFields: ["contractArticles"],
    items: [
      {
        dataName: "templateId",
        disabled: (props) => Boolean(props?.data?._id),
        options: getSelectOptions({
          data: extraProps?.invoicePlanTemplates?.map((d) => ({
            label: d.name,
            value: String(d._id),
          })),
        }),
        itemType: "plan",
        label: "Invoice template",
        setFormDataCustom: (props) => {
          const customer = extraProps?.customers?.find(
            (c) => c._id === props.data?.customerId,
          );

          const template = extraProps?.invoicePlanTemplates?.find(
            (template) => template._id === props.value,
          );

          let newData: Partial<InvoicePlanExtended> = {
            templateId: props.value,
          };

          if (props.data) {
            newData = {
              ...clone(props.data),
              ...newData,
            };
          }

          if (template) {
            let keys = ["type"];

            if (template.type === "milestone") {
              newData.milestoneDetails = {
                total: extraProps?.contract?.contractFeatures?.totalPrice || 0,
                date:
                  extraProps?.contract?.startDate || addMonths(new Date(), 2),
                ends: extraProps?.contract?.endDate || new Date(),
              };
            }

            if (
              template.type === "periodic" ||
              template.type === "runningrate"
            ) {
              keys = [
                ...keys,
                "reminder",
                "invoicingPeriod.postingDate",
                "invoicingPeriod.repeats.number",
                "invoicingPeriod.repeats.unit",
              ];
              newData.invoicingPeriod = {
                ...newData.invoicingPeriod,
                date:
                  extraProps?.contract?.startDate || addMonths(new Date(), 2),
                ends: extraProps?.contract?.endDate || new Date(),
              };
            }

            for (let key of keys) {
              setNestedObjectData({
                data: newData,
                key,
                value: getNestedObjectData({ data: template, key }),
              });
            }

            if (template?.invoiceRows) {
              const articleDic = listToDic(extraProps?.articles, (c) => c._id);

              const contractArticleDic = listToDic(
                extraProps?.contract?.contractArticles,
                (c) => c.articleId,
              );

              // First use information from invoice template rows
              const newRows = template.invoiceRows.map((e, i) => {
                let row: Partial<InvoicePlanRow> = {
                  itemDescription: e.itemDescription,
                  articleId: e.articleId,
                  isPeriodic: e.isPeriodic,
                };

                if (
                  template.type === "periodic" ||
                  template.type === "runningrate"
                ) {
                  row.discount = e.discount;
                  row.offset = e.offset;
                }

                const contractArticle = contractArticleDic[String(e.articleId)];

                const article = articleDic[String(e.articleId)];

                if (contractArticle) {
                  // Make sure that milestone always has quantity 1
                  row.quantity =
                    template.type === "milestone"
                      ? 1
                      : contractArticle.quantity;
                  row.isQuantityEstimate = contractArticle.isQuantityEstimate;

                  // If contract article exist then use quantity times price for milestone price
                  row.pricePerUnit =
                    template.type === "milestone"
                      ? contractArticle.quantity * contractArticle.price
                      : contractArticle.price;
                  row.isPricePerUnitEstimate =
                    contractArticle.isPricePerUnitEstimate;
                } else {
                  row.quantity = 0;
                  row.isQuantityEstimate = false;
                  row.pricePerUnit = 0;
                  row.isPricePerUnitEstimate = false;
                }

                row.percentVAT = calculateVat(
                  article?.vat !== undefined ? article?.vat : 0.25,
                  customer,
                );

                const { total, totalExcludingVAT } = calculateDependable({
                  data: {
                    quantity: row.quantity,
                    pricePerUnit: row.pricePerUnit,
                    percentVAT: row.percentVAT,
                    discount: row.discount,
                  } as unknown as InvoicePlanRowExtended,
                });
                row.total = total;
                row.totalExcludingVAT = totalExcludingVAT;

                return row;
              });

              newData.invoiceRows = newRows as unknown as InvoicePlanRow[];
            }

            setFormData(newData as unknown as InvoicePlanExtended);
          }
        },
        type: "autocomplete",
      },
      {
        dataName: "name",
        itemType: "plan",
        validate: (props) => {
          const value = getValue(props);
          return !(value && value.length < 3);
        },
        required: true,
        type: "text_input",
        label: "Name",
        disabled: (props) => {
          return Boolean(props?.data?._id);
        },
        setFormData,
      },
      {
        itemType: "plan",
        type: "object",
        items: [
          {
            dataName: "type",
            gridProps: { lg: 6 },
            itemType: "plan",
            type: "selector",
            required: true,
            label: "Type",
            disabled: (props) => Boolean(props?.data?._id),
            // onlyEditableOnCreate: true,
            options: getSelectOptions({
              data: _c.OPTIONS_INVOICE_TYPE.map((c) => ({
                label: t(c.label),
                value: c.value,
              })),
            }),
            setFormDataCustom: (props) => {
              const isMilestone =
                props.value === "milestone" ||
                props.value === "milestone_retained_funds";

              if (props.data) {
                let newData = { ...props.data };
                if (
                  props.value !== "periodic" ||
                  props.value !== "singleinvoice"
                ) {
                  delete newData.invoicingPeriod;
                }
                if (!isMilestone) {
                  delete newData.milestoneDetails;
                  delete newData.invoicingPeriod?.isConsolidatedInvoices;
                  delete newData.invoicingPeriod?.allowExternalRowAdding;
                }
                if (
                  (props.value === "periodic" ||
                    props.value === "runningrate") &&
                  !newData.invoicingPeriod
                ) {
                  newData.invoicingPeriod = {
                    date: extraProps?.contract?.startDate || new Date(),
                    ends: extraProps?.contract?.endDate,
                    repeats: { number: 1, unit: "month" },
                    postingDate: "last",
                  };
                }
                if (
                  isMilestone &&
                  !newData.milestoneDetails &&
                  extraProps?.contract
                ) {
                  newData.milestoneDetails = {
                    total:
                      extraProps?.contract?.contractFeatures?.totalPrice || 0,
                    date: extraProps?.contract?.startDate,
                    ends: extraProps?.contract?.endDate || new Date(),
                  };
                }
                if (props.value === "singleinvoice") {
                  delete newData.reminder;
                  newData.invoicingPeriod = {
                    // For single invoice (only) the default value should be todays date.
                    date: new Date(),
                  };
                }

                setFormData({ ...newData, type: props.value });
              }
            },
          },
          {
            dataName: "reminder",
            gridProps: { lg: 6 },
            itemType: "plan",
            type: "selector",
            required: true,
            label: "Reminder",
            options: getSelectOptions({
              data: [
                {
                  label: "Invoice date",
                  value: "invoicedate",
                },
                {
                  label: "Weekly",
                  value: "weekly",
                },
              ].map((c) => ({ label: t(c.label), value: c.value })),
            }),
            setFormData,
            showWhen: ({ data }) =>
              data?.type !== INVOICE_PLAN_TYPE.singleinvoice,
          },
        ],
      },
      ...dimensionItems,
      {
        gridProps: { xs: 4 },
        itemType: "plan",
        type: "switch",
        label: "Index",
        dataName: "indexOption.index",
        setFormDataCustom: (props) => {
          let newData = clone(props.data);

          if (newData) {
            newData.indexOption = {
              index: props.value,
            };

            for (const invoiceRow of newData?.invoiceRows || []) {
              invoiceRow.isIndex = props.value;
            }

            setFormData(newData);
          }
        },
      },
      {
        gridProps: { xs: 8 },
        itemType: "plan",
        required: true,
        showWhen: (props) => !!props.data?.indexOption?.index,
        type: "autocomplete",
        label: "Set index to use",
        dataName: "indexOption.indexId",
        options: extraProps?.indexOptions,
        getErrorText: _c.ERROR_TEXTS.INACTIVE_INDEX,
        validate: (props) =>
          !(
            extraProps?.currentIndex?.active === false &&
            props.data?.indexOption?.indexId === extraProps?.currentIndex?._id
          ),
        setFormDataCustom: (props) => {
          let newData = clone(props.data);
          if (newData) {
            newData.indexOption = {
              ...(props.data?.indexOption || {}),
              indexId: props.value,
            };

            setFormData(newData);
          }
        },
      },
      {
        itemType: "singleinvoice",
        type: "date",
        dataName: "invoicingPeriod.date",
        label: "Invoice date",
        showWhen: ({ data }) => data?.type === INVOICE_PLAN_TYPE.singleinvoice,
        required: true,
        setFormData,
      },
      {
        itemType: "runningAndPeriodicType",
        type: "object",
        showWhen: ({ data }) =>
          data?.type === INVOICE_PLAN_TYPE.periodic ||
          data?.type === INVOICE_PLAN_TYPE.runningrate,
        items: [
          {
            dataName: "invoicingPeriod.date",
            disabled: (props) =>
              Boolean(props.data?.followContractCancellationTerms) ||
              Boolean(props.data?.locked),
            gridProps: { lg: 6 },
            required: true,
            type: "date",
            label: "Invoicing period start date",
            validate: (props) =>
              props.data?.type === INVOICE_PLAN_TYPE.singleinvoice ||
              lessOrEqualThan(
                props.data?.invoicingPeriod?.date,
                props.data?.invoicingPeriod?.ends,
              ),
            getErrorText:
              "Invoicing period start date can not come after end date",
            setFormDataCustom: ({ data, value }) => {
              setFormDataDatePair({
                data,
                otherKey: "invoicingPeriod.ends",
                otherAction: "to_end_of_month",
                value,
                valueKey: "invoicingPeriod.date",
                setFormData,
              });
            },
          },
          {
            dataName: "invoicingPeriod.ends",
            disabled: (props) => {
              return Boolean(props.data?.followContractCancellationTerms);
            },
            gridProps: { lg: 6 },
            required: true,
            type: "date",
            label: "End date",
            validate: (props) => {
              return lessOrEqualThan(
                props.data?.invoicingPeriod?.date,
                props.data?.invoicingPeriod?.ends,
              );
            },
            getErrorText:
              "Invoicing period end date can not come before start date",
            setFormDataCustom: ({ data, value }) => {
              setFormDataDatePair({
                data,
                otherKey: "invoicingPeriod.date",
                otherAction: "to_start_of_month",
                value,
                valueKey: "invoicingPeriod.ends",
                setFormData,
              });
            },
          },
        ],
      },
      {
        itemType: "runningAndPeriodicType",
        type: "switch",
        dataName: "invoicingPeriod.isConsolidatedInvoices",
        showWhen: ({ data }) => data?.type === INVOICE_PLAN_TYPE.runningrate,
        label: "Invoice all available",
        setFormData,
      },
      {
        itemType: "runningAndPeriodicType",
        type: "object",
        label: "Invoice frequency",
        showWhen: ({ data }) =>
          (data?.type === INVOICE_PLAN_TYPE.periodic ||
            data?.type === INVOICE_PLAN_TYPE.runningrate) &&
          !data?.invoicingPeriod?.isConsolidatedInvoices,
        items: [
          {
            dataName: "invoicingPeriod.repeats.number",
            gridProps: { xs: 3 },
            required: true,
            type: "number",
            validate: (props) => {
              if (props.data?.invoicingPeriod?.repeats?.number) {
                return props.data?.invoicingPeriod?.repeats?.number > 0;
              } else {
                return false;
              }
            },
            getErrorText: () => "Not allowed",
            setFormData,
          },
          {
            dataName: "invoicingPeriod.repeats.unit",
            gridProps: { xs: 9 },
            required: true,
            type: "selector",
            options: getSelectOptionsDayMonthQuarterYear(t),
            setFormData,
          },
        ],
      },
      {
        itemType: "runningAndPeriodicType",
        showWhen: ({ data }) =>
          (data?.type === INVOICE_PLAN_TYPE.periodic ||
            data?.type === INVOICE_PLAN_TYPE.runningrate) &&
          !data?.invoicingPeriod?.isConsolidatedInvoices,
        dataName: "invoicingPeriod.postingDate",
        required: true,
        type: "invoicing_date",
        label: "Invoicing date",
        validate: (props) => {
          const value = props.data?.invoicingPeriod?.postingDate;

          if (value === "first" || value === "last") {
            return true;
          } else if (value) {
            return value > "0";
          } else {
            return false;
          }
        },
        getErrorText: () => "Not allowed",
        setFormData,
      },
      {
        itemType: "runningAndPeriodicType",
        type: "object",
        showWhen: ({ data }) =>
          data?.type === INVOICE_PLAN_TYPE.periodic ||
          data?.type === INVOICE_PLAN_TYPE.runningrate,
        items: [
          {
            gridProps: { xs: 6 },
            dataName: "renewaltype",
            itemType: "runningAndPeriodicType",
            type: "text_input",
            label: "Renewal",
            disabled: true,
            getValue: (props) =>
              capitalize(extraProps?.contract?.renewalTerms?.renewalType),
          },
          {
            gridProps: { xs: 6 },
            dataName: "followContractCancellationTerms",
            itemType: "runningAndPeriodicType",
            required: true,
            type: "switch",
            label: "Same as contract",
            setFormDataCustom: (props) => {
              if (props.data) {
                let newData = {
                  ...clone(props?.data),
                  followContractCancellationTerms: props.value,
                };

                if (!props.value) {
                  const endDateReminder = {
                    endDateReminder: extraProps?.contract?.endDateReminder || {
                      number: 3,
                      unit: "week",
                    },
                  };
                  newData = {
                    ...newData,
                    ...endDateReminder,
                  };
                } else if (newData.invoicingPeriod) {
                  newData = {
                    ...newData,
                    endDateReminder: undefined,
                    invoicingPeriod: {
                      ...newData.invoicingPeriod,
                      ends: extraProps?.contract?.endDate,
                    },
                  };
                } else {
                  newData = {
                    ...newData,
                    endDateReminder: undefined,
                  };
                }
                setFormData(newData);
              }
            },
          },
        ],
      },
      {
        itemType: "runningAndPeriodicType",
        showWhen: ({ data }) =>
          data?.type === INVOICE_PLAN_TYPE.periodic ||
          data?.type === INVOICE_PLAN_TYPE.runningrate,
        type: "object",
        items: [
          {
            gridProps: { lg: 6 },
            itemType: "runningAndPeriodicType",
            type: "object",
            label: "Cancellation terms",
            showWhen: ({ data }) =>
              Boolean(data?.followContractCancellationTerms) &&
              extraProps?.contract?.renewalTerms?.renewalType === "automatic",
            items: [
              {
                dataName: "cancellationTermsNumber",
                gridProps: { xs: 4 },
                type: "text_input",
                disabled: true,
                getValue: (props) =>
                  extraProps?.contract?.cancellationTerms?.number,
              },
              {
                dataName: "cancellationTermsUnit",
                gridProps: { xs: 8 },
                type: "text_input",
                disabled: true,
                getValue: (props) =>
                  capitalize(extraProps?.contract?.cancellationTerms?.unit),
              },
            ],
          },
          {
            gridProps: { lg: 6 },
            itemType: "runningAndPeriodicType",
            showWhen: ({ data }) =>
              Boolean(data?.followContractCancellationTerms) &&
              extraProps?.contract?.renewalTerms?.renewalType === "automatic",
            type: "object",
            label: "Automatic renewal",
            items: [
              {
                dataName: "renewalTermsNumber",
                gridProps: { xs: 4 },
                disabled: true,
                type: "text_input",
                getValue: (props) => {
                  return extraProps?.contract?.renewalTerms?.number;
                },
              },
              {
                dataName: "renewalTermsUnit",
                gridProps: { xs: 8 },
                type: "text_input",
                disabled: true,
                getValue: (props) =>
                  capitalize(extraProps?.contract?.renewalTerms?.unit),
              },
            ],
          },
        ],
      },
      {
        itemType: "runningAndPeriodicType",
        showWhen: ({ data }) =>
          (data?.type === INVOICE_PLAN_TYPE.periodic ||
            data?.type === INVOICE_PLAN_TYPE.runningrate) &&
          Boolean(
            extraProps?.contractType?.contractTypeFeatures?.endDateReminder,
          ) &&
          !Boolean(data.followContractCancellationTerms),
        type: "object",
        gridProps: { md: 12 },
        sx: { width: "50%" },
        dataName: "endDateReminder",
        label: "Email reminder before end date",
        items: [
          {
            gridProps: { xs: 2 },
            type: "number",
            dataName: "endDateReminder.number",
            validate: (props) => {
              if (props.data?.endDateReminder?.number) {
                return props.data?.endDateReminder?.number > 0;
              } else {
                return false;
              }
            },
            getErrorText: "Must be grater than zero",
            setFormData,
          },
          {
            gridProps: { xs: 4 },
            type: "selector",
            dataName: "endDateReminder.unit",
            options: getSelectOptionsDayWeekMonth(t),
            setFormData,
          },
        ],
      },
      {
        itemType: "runningAndPeriodicType",
        type: "switch",
        dataName: "invoicingPeriod.allowExternalRowAdding",
        showWhen: ({ data }) => data?.type === INVOICE_PLAN_TYPE.periodic,
        label: "Allow external row addition",
        setFormData,
      },
      {
        itemType: "milestone",
        type: "object",
        showWhen: showMilestone,
        items: [
          {
            dataName: "milestoneDetails.date",
            gridProps: { lg: 6 },
            required: true,
            type: "date",
            label: "Invoicing period start date",
            // defaultValue: contract?.startDate,
            validate: ({ data, item }) => {
              if (data?.milestoneDetails) {
                return lessThan(
                  data?.milestoneDetails?.date,
                  data?.milestoneDetails?.ends,
                );
              } else {
                return true;
              }
            },
            getErrorText: () =>
              "Invoicing period start date can not come after end date",
            setFormDataCustom: ({ data, value }) => {
              setFormDataDatePair({
                data,
                otherKey: "milestoneDetails.ends",
                otherAction: "to_end_of_month",
                value,
                valueKey: "milestoneDetails.date",
                setFormData,
              });
            },
          },
          {
            dataName: "milestoneDetails.ends",
            gridProps: { lg: 6 },
            required: true,
            type: "date",
            label: "Estimated completion",
            validate: ({ data, item }) => {
              if (data?.milestoneDetails) {
                return lessThan(
                  data?.milestoneDetails?.date,
                  data?.milestoneDetails?.ends,
                );
              } else {
                return true;
              }
            },
            getErrorText: () =>
              "Estimation completion data can not come before invoicing period start date",
            setFormDataCustom: ({ data, value }) => {
              setFormDataDatePair({
                data,
                otherKey: "milestoneDetails.date",
                otherAction: "to_start_of_month",
                value,
                valueKey: "milestoneDetails.ends",
                setFormData,
              });
            },
          },
          {
            dataName: "milestoneDetails.total",
            gridProps: { lg: 6 },
            type: "number_input_currency",
            required: true,
            label: "Total price",
            validate: ({ data }) =>
              data?.milestoneDetails?.total
                ? data?.milestoneDetails?.total > 0
                : true,
            getErrorText: () => "Not allowed",
            setFormDataCustom: ({ data, value }) => {
              let newData = {
                ...clone(data),
                milestoneDetails: {
                  ...data?.milestoneDetails,
                  total: value,
                },
              } as InvoicePlanExtended;
              if (
                data?.type === "milestone_retained_funds" &&
                !!extraProps?.formGlueInvoiceRows
              ) {
                const glueInvoiceRows = extraProps?.formGlueInvoiceRows;
                addRetainedFundsDataToInvoiceRows({
                  invoiceRows: glueInvoiceRows?.formData || [],
                });

                const pricePerUnit = culculateRetainedAmount({
                  invoiceRows: glueInvoiceRows?.formData || [],
                  totalPrice: value,
                });

                const invoiceRows =
                  glueInvoiceRows?.formData?.map((row) => {
                    if (row.isRetainedFunds) {
                      return {
                        ...row,
                        pricePerUnit,
                      };
                    } else return row;
                  }) || [];
                updateRowTotals(invoiceRows);
                addRetainedFundsDataToInvoiceRows({ invoiceRows });
                if (glueInvoiceRows?.setFormData) {
                  glueInvoiceRows.setFormData(invoiceRows);
                }
              }
              setFormData(newData);
            },
          },
        ],
      },
      {
        dataName: "includeProjectNameOnInvoice",
        itemType: "milestone",
        type: "switch",
        label: "Contract name",
        showWhen: showMilestone,
        setFormData,
      },
      {
        dataName: "customerId",
        disabled: ({ data }) => Boolean(data?._id),
        label: "Customer",
        itemType: "customer",
        gridProps: { lg: 8 },
        options: getSelectOptions({
          data:
            extraProps?.customers?.map((c) => ({
              label: getCustomerName(c),
              value: String(c._id),
            })) || [],
        }),
        setFormDataCustom: (props) => {
          const customer = extraProps?.customers?.find(
            (c) => c._id === props.value,
          );

          if (props.data && customer) {
            extraProps?.setVatTypeTooltipOnCustomer(customer);
            const VATType = getVatType(customer);
            let newData: InvoicePlanExtended = {
              ...clone(props.data),
              customerId: props.value,
              currencyCode: customer?.currencyCode,
              termsOfPaymentId:
                customer?.termsOfPaymentId || props?.data?.termsOfPaymentId,
              reversedConstructionVAT:
                customer?.reversedConstructionVAT ||
                props?.data?.reversedConstructionVAT,
              invoiceEmail: customer?.emailInvoice || props?.data?.invoiceEmail,
              yourReference:
                customer?.yourReference || props?.data?.yourReference,
              VATType,
            };

            setFormData(newData);
          }
        },
        required: true,
        type: "autocomplete",
      },
      {
        itemType: "customer",
        required: false,
        type: "autocomplete",
        dataName: "currencyCode",
        label: "Currency",
        gridProps: { xs: 4 },
        options: getSelectOptions({
          data: extraProps?.currencies?.map((c) => ({
            label: `${c.code} (${c.description})`,
            value: c.code,
          })),
        }),
        setFormData,
      },
      {
        itemType: "customer",
        type: "object",
        items: [
          {
            dataName: "termsOfPaymentId",
            gridProps: { lg: 4 },
            itemType: "customer",
            required: true,
            type: "autocomplete",
            label: "Terms of payment",
            options: getSelectOptions({
              data: extraProps?.termsOfPayments?.map((item) => {
                return {
                  label: item.description,
                  value: String(item._id),
                };
              }),
            }),
            setFormData,
          },
          {
            dataName: "VATType",
            gridProps: { lg: 4 },
            vatTypeTooltip: extraProps?.vatTypeTooltip,
            itemType: "customer",
            type: "text",
            label: "VAT type",
          },
          {
            dataName: "orderNumber",
            gridProps: { lg: 4 },
            itemType: "customer",
            type: "text_input",
            // defaultValue: null,
            label: "Your order number",
            setFormData,
          },
        ],
      },
      {
        dataName: "ourReferenceId",
        itemType: "customer",
        required: true,
        type: "autocomplete",
        label: "Our reference",
        options: getSelectOptions({
          data: extraProps?.users?.map((user) => {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: String(user._id),
            };
          }),
        }),
        setFormDataCustom: (props) => {
          const user = extraProps?.users?.find(
            (item) => item._id === props.value,
          );
          if (user && props.data) {
            const newData = {
              ...props.data,
              ourReferenceId: user?._id,
            };

            setFormData(newData);
          }
        },
      },
      {
        dataName: "yourReference",
        itemType: "customer",
        required: false,
        type: "text_input",
        label: "Your reference",
        setFormData,
      },
      {
        dataName: "yourReferenceEmail",
        itemType: "customer",
        type: "text_input",
        required: false,
        label: "Contact person e-mail",
        setFormData,
      },
      {
        dataName: "invoiceEmail",
        itemType: "customer",
        type: "text_input",
        required: false,
        label: "Invoice e-mail",
        setFormData,
      },
    ],
  };

  return structure;
};

export const toData = ({
  data,
}: {
  data?: Partial<InvoicePlanWithPermissions> | undefined;
}) => {
  if (data === undefined) {
    return undefined;
  }

  const tmpDimensionItems: TmpDimensionItems =
    listToDic<DimensionItemInvoicePlan>(data?.dimensionItems || [], (v) =>
      String(v.dimensionId),
    );

  return {
    ...data,
    tmpDimensionItems,
  } as InvoicePlanExtended;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: InvoicePlanExtended;
  initData?: InvoicePlanExtended;
}) => {
  if (data === undefined || initData === undefined) {
    return data;
  }

  const transformData = ({
    dataToTransform,
  }: {
    dataToTransform: InvoicePlanExtended;
  }) => {
    const data = clone<InvoicePlanExtended>(dataToTransform);

    data.dimensionItems = dicToList<DimensionItemInvoicePlan>(
      data.tmpDimensionItems,
    );
    for (let item of data.dimensionItems) {
      //@ts-ignore
      delete item._id;
    }

    delete data.tmpDimensionItems;
    delete data.endDateReminderDay;

    //Clear up on type
    if (data.type === "milestone") {
      delete data.invoicingPeriod;
      if (data.invoiceRows) {
        data.invoiceRows.forEach((row) => {
          row.estimatedInvoiceDate = row.estimatedInvoiceDate || new Date();
        });
      }
    } else if (data.type === "periodic" || data.type === "runningrate") {
      delete data.milestoneDetails;
    }
    return data;
  };

  const submitData = transformData({ dataToTransform: data });
  const initialSubmitData = transformData({ dataToTransform: initData });

  const partialData = toUpdateData({
    data: submitData,
    initData: initialSubmitData,
  });

  return partialData;
};
