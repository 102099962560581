import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams, Routes, Route, Navigate } from "react-router-dom";
import getHiddenComponentStatus from "src/utils/getHiddenComponentStatus";
import getStructure from "./RoutesContractTabStructure";
import LinearProgress from "@mui/material/LinearProgress";
import NavigationButtonsContract from "src/components/Basic/Mixed/NavigationButtons/NavigationButtonsContract";
import NotFound from "src/components/Screens/NotFound/NotFound";
import TabsWithLink from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import useContractTabHiddenComponents from "src/context/hooks/useContractTabHiddenComponents";

const RoutesContract = () => {
  const contractTabHiddenComponents = useContractTabHiddenComponents();
  let { contractId } = useParams<{ contractId: string }>();
  const user = useAppSelector((state) => state.user);
  const isSuperAdmin = user.user?.isSuperAdmin;

  const {
    data: contract,
    isLoading,
    isError,
  } = useGetContractQuery(contractId, {
    skip: contractId === undefined,
  });
  const isServiceOrderContract =
    !!contract?.contractFeatures?.serviceOrderContract;

  const url = `/app/contracts/${contractId}`;
  const path = "/app/contracts/:contractId";
  const redirectPath: RoutePath = "/app/contracts/:contractId";
  const getMainRouteUrl = (path: RoutePath) =>
    contractId ? path.replace(":contractId", contractId) : path;
  const pathToStrip = "/app/contracts/:contractId";

  // Filter away tabs with:
  // 1. no read rights and on contract with contractTypeFeatures?.serviceOrderContract
  // 2. configured in hiddenComponents
  const tabs = getStructure({ isServiceOrderContract, isSuperAdmin })?.filter(
    (tab) => {
      const hiddenComponent = contractTabHiddenComponents.find(
        (data) => data.name === tab.name,
      );
      return getHiddenComponentStatus(hiddenComponent);
    },
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return <NotFound type="contract_list" />;
  }

  return (
    <>
      <TabsWithLink
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name={"contract"}
        navigationButtons={<NavigationButtonsContract />}
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
        noIndicator
        top="102px"
      />
      <Routes>
        <Route
          path="/invoices"
          element={
            <Navigate
              to={getMainRouteUrl(
                "/app/contracts/:contractId/invoices/planned",
              )}
            />
          }
        />
      </Routes>
    </>
  );
};

export default RoutesContract;
