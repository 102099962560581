/**
 *Created by Mikael Lindahl on 2024-04-18
 */
import { useState } from "react";

interface PaginationHook {
  defaultPageSize: number;
  defaultPage: number;
}

export type UsePaginationReturn = {
  page: number;
  pageSize: number;
  setCount: (count: number) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalNumberOfPages: number;
};

const usePagination = ({ defaultPageSize, defaultPage }: PaginationHook) => {
  const [page, setPage] = useState(defaultPage);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const totalNumberOfPages = Math.ceil(count / pageSize);

  const r: UsePaginationReturn = {
    pageSize,
    page,
    setPage,
    setPageSize,
    totalNumberOfPages,
    setCount,
  };

  return r;
};

export default usePagination;
