import { Password } from "src/accurasee-backend-types/app/user/user.types";

import getValue from "src/components/Builders/Container/Utils/getValue";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { getNestedObjectData } from "src/utils/nestedData";

export const getStructure: UseFormContainerGetStructure<Password> = ({
  setFormData,
}) => {
  const structure: StructureContainer<Password> = {
    items: [
      {
        type: "text_input",
        dataName: "currentPassword",
        label: "Current password",
        required: true,
        textInputType: "password",
        setFormData,
      },
      {
        type: "text_input",
        dataName: "newPassword",
        label: "New password",
        required: true,
        textInputType: "password",
        setFormData,
        validate: (props: any) => {
          const newPassword = getValue(props);
          if (!newPassword) return true;

          const currentPassword = getNestedObjectData({
            data: props.data,
            key: "currentPassword",
          });

          return newPassword !== currentPassword;
        },
        getErrorText: () => "Your new password must be different",
      },
      {
        type: "text_input",
        dataName: "newPasswordConfirmation",
        label: "Confirm new password",
        required: true,
        textInputType: "password",
        setFormData,
        validate: (props: any) => {
          const newPasswordConfirmation = getValue(props);
          if (!newPasswordConfirmation) return true;

          const newPassword = getNestedObjectData({
            data: props.data,
            key: "newPassword",
          });

          return newPasswordConfirmation === newPassword;
        },
        getErrorText: () =>
          "Your confirmation password does not match the new password",
      },
    ],
  };

  return structure;
};
