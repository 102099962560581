import { Box, Fade, Link, Typography } from "@mui/material";
import { reset as resetUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import useLoginStyles from "../LoginStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface CheckEmailProps {
  email: string;
  setActiveTabId: (v: number) => void;
}

const CheckEmail = ({ email, setActiveTabId }: CheckEmailProps) => {
  const [t] = useTranslation();
  const classes = useLoginStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState<"Failed" | null>(null);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  return (
    <>
      <MarkEmailReadOutlinedIcon
        style={{ color: "green", fontSize: "40px", width: "100%" }}
      />
      <Typography className={classes.title}>{t("Check your email")}</Typography>
      <Typography
        sx={{
          color: "var(--black-7)",
          fontSize: "16px",
          marginTop: "var(--space-8px)",
          textAlign: "center",
        }}
      >
        {t("We sent a password reset link to")}
      </Typography>
      <Typography
        sx={{
          color: "var(--black-7)",
          fontSize: "16px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {email}
      </Typography>
      <Box
        sx={{
          display: "flex",
          columnGap: "var(--space-4px)",
          justifyContent: "center",
          marginTop: "var(--space-32px)",
        }}
      >
        <Typography sx={{ color: "var(--gray-5)", fontSize: "12px" }}>
          {t("Didn't receive the email?")}
        </Typography>
        <Link
          component="button"
          disabled={isResettingPassword}
          style={{
            display: "block",
            fontSize: "12px",
            fontWeight: 500,
            textDecoration: "none",
          }}
          onClick={() => {
            setIsResettingPassword(true);
            resetUser(email)(dispatch)
              .then((data) => {
                setTimeout(() => {
                  setIsResettingPassword(false);
                }, 10000);
                enqueueSnackbar(
                  t(
                    "A password reset email has been sent to your email address",
                  ),
                  { variant: "success" },
                );
                setActiveTabId(3);
              })
              .catch((err) => {
                console.error(err);
                setIsResettingPassword(false);
                enqueueSnackbar("Can't sent email", { variant: "error" });
                setError(
                  err.response.data.error.message ?? "Request is failed",
                );
              });
          }}
        >
          {t("Click to resend")}
        </Link>
      </Box>
      {error ? (
        <Fade in={!!error}>
          <Typography
            color="error"
            mt="var(--space-24px)"
            className={classes.errorMessage}
          >
            {error}
          </Typography>
        </Fade>
      ) : (
        <></>
      )}
      <Link
        style={{
          cursor: "pointer",
          display: "block",
          fontWeight: 500,
          marginTop: "var(--space-24px)",
          textAlign: "center",
          textDecoration: "none",
        }}
        onClick={() => setActiveTabId(0)}
      >
        <ArrowBackIcon
          fontSize="small"
          sx={{ marginRight: "var(--space-8px)", verticalAlign: "middle" }}
        />
        {t("Back to Login")}
      </Link>
    </>
  );
};

export default CheckEmail;
