import { AccessRolePermission } from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";
import { EditRights } from "src/accurasee-backend-types/app/general/editrights.types";
import { Types } from "mongoose";
import cloneDeep from "lodash/cloneDeep";

export type AccessRolePermissionPartial = Pick<
  AccessRolePermission,
  "collectionPermissions" | "collectionTarget" | "filters" | "roleId"
> & { _id?: Types.ObjectId };

export const PERMISSION_DEFAULT = {
  deleteRights: false,
  exportRights: false,
  readRights: true,
  updateRights: false,
  writeRights: false,
};

const KEYPAIRS = [
  { root: "contracts", additional: "projects" },
  { root: "dimensions", additional: "dimensionitems" },
  { root: "users", additional: "invites" },
];

export const toSubmitData = ({
  data,
  filters,
}: {
  data: AccessRolePermissionPartial[];
  filters: string[];
}) => {
  let submitData = cloneDeep(data);

  submitData = submitData?.map((item) => {
    return {
      _id: item?._id,
      collectionPermissions: item?.collectionPermissions,
      collectionTarget: item?.collectionTarget,
      filters: item?.filters,
      roleId: item?.roleId,
    };
  });

  submitData = submitData?.filter((data) =>
    filters.includes(data?.collectionTarget),
  );

  // This is to add Project and DimensionItems if needed
  // Both dimensionItem and project exists on the backend and are used
  // but are controlled by dimension and contracts
  KEYPAIRS.forEach((pair) => {
    if (filters.includes(pair.root)) {
      const additionalData = submitData.find(
        (item) => item.collectionTarget === pair.root,
      );
      const target: any = data?.find(
        (i) => i.collectionTarget === pair.additional,
      );
      if (target && additionalData) {
        submitData.push({
          ...additionalData,
          _id: target._id,
          collectionTarget: target.collectionTarget,
        });
      }
    }
  });

  return submitData as AccessRolePermissionPartial[];
};

export const PERMISSION_TYPE: {
  label: string;
  value: keyof EditRights["permissions"];
}[] = [
  { label: "Read", value: "readRights" },
  { label: "Create", value: "writeRights" },
  { label: "Update", value: "updateRights" },
  { label: "Delete", value: "deleteRights" },
  { label: "Export", value: "exportRights" },
];
