/**
 *Created by Mikael Lindahl on 2023-11-14
 */

import AdditionalInfo from "src/components/Complex/Containers/AdditionalInfo";
import ButtonBack from "src/components/Basic/Simple/Buttons/ButtonBack";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonDeleteWithYesNoDialog from "src/components/Basic/Simple/Buttons/ButtonDeleteWithYesNoDialog";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import DialogContentWrapper from "src/components/Basic/Simple/Dialogs/DialogContentWrapper";
import InvoicePlanContainer from "./InvoicePlanContainer/InvoicePlanContainer";
import InvoicePlanRows from "./InvoicePlanRow/InvoicePlanRows";
import InvoicePlanTemplateModal from "src/components/Complex/Modals/InvoicePlanTemplateModal";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import isDisableSubmit from "src/utils/isDisableSubmit";
import useFormGlue from "src/hooks/useFormGlue";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useReload from "src/hooks/useReload";
import useTranslation from "src/hooks/useTranslationWrapper";
import { AdditionalInfoData } from "src/components/Complex/Containers/AdditionalInfoGetStructure";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { addMonths } from "date-fns";
import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetInvoiceplanTemplatesQuery } from "src/redux/services/InvoicePlanTemplateService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  INVOICE_PLAN_TYPE,
  InvoicePlan,
  InvoicePlanRow,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import {
  useCreateInvoiceplanMutation,
  useDeleteInvoiceplanMutation,
  useGetInvoiceplanQuery,
  usePatchInvoiceplanMutation,
} from "src/redux/services/InvoiceplanService";
import useConfirmLeave from "src/hooks/useConfirmLeave";

const InvoicePlanComponent = () => {
  const [t] = useTranslation();
  const { reloadKey, reload } = useReload();
  const [openInvoiceTemplateModal, setOpenInvoiceTemplateModal] =
    useState(false);

  let { contractId, invoicePlanId } = useParams<{
    invoicePlanId?: string;
    contractId?: string;
  }>();

  const {
    data: invoicePlanTemplatesQuery,
    isLoading: isLoadingInvoicePlanTemplates,
  } = useGetInvoiceplanTemplatesQuery(undefined);

  const invoicePlanTemplates: InvoicePlanTemplate[] =
    invoicePlanTemplatesQuery?.data || [];

  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    contractId,
    {
      skip: contractId === undefined,
    },
  );

  const isCreate = invoicePlanId === undefined;

  const { data: invoicePlan, isLoading: isLoadingInvoicePlan } =
    useGetInvoiceplanQuery(invoicePlanId, {
      skip: isCreate,
    });

  const invoicePlanInit = isCreate
    ? ({
        contractId: contract?._id!,
        currencyCode: contract?.currencyCode,
        customerStatus: "draft",
        customerId: contract?.customerId,
        dimensionItems: contract?.dimensionItems || [],
        discount: 0,
        followContractCancellationTerms: true,
        houseWork: contract?.contractFeatures?.houseWork || false,
        indexOption: contract?.indexOption,
        invoiceEmail: contract?.invoiceEmail,
        invoiceRows: [],
        invoicingPeriod: {
          date: contract?.startDate || new Date(),
          ends: contract?.endDate,
          postingDate: "last",
          repeats: { number: 1, unit: "month" },
        },
        milestoneDetails: {
          date: contract?.startDate || new Date(),
          ends: contract?.endDate || addMonths(new Date(), 1),
          total: contract?.contractFeatures?.totalPrice || 0,
        },
        name: contract?.name,
        orderNumber: contract?.orderNumber,
        ourReferenceId: contract?.ourReferenceId,
        reminder: "invoicedate",
        reversedConstructionVAT: contract?.reversedConstructionVAT,
        status: "upcoming",
        taxDeductionType: "NONE",
        termsOfPaymentId: contract?.termsOfPaymentId,
        type: contract?.contractFeatures?.totalPrice
          ? INVOICE_PLAN_TYPE.milestone
          : INVOICE_PLAN_TYPE.periodic,
        VATType: contract?.VATType,
        yourReference: contract?.yourReference || undefined, // Note about empty string
        yourReferenceEmail: contract?.yourReferenceEmail,
      } as Partial<InvoicePlan>)
    : (invoicePlan as InvoicePlanWithPermissions);

  const { remarks, ...rest } = invoicePlanInit || {};
  const invoicePlanInitContainer = { ...rest };
  const invoicePlanInitAdditionalInfo: AdditionalInfoData = {
    remarks,
    _id: rest._id,
  };

  const [createInvoicePlan] = useCreateInvoiceplanMutation();
  const [patchInvoicePlan] = usePatchInvoiceplanMutation();
  const [deleteInvoicePlan] = useDeleteInvoiceplanMutation();

  const formGlueAdditionalInfo = useFormGlue<AdditionalInfoData>();
  const formGlueContainer = useFormGlue<InvoicePlanWithPermissions>();
  const formGlueTable = useFormGlue<InvoicePlanRow[]>();

  const submitData = {
    ...formGlueAdditionalInfo.submitData,
    ...formGlueContainer.submitData,
    invoiceRows: isCreate
      ? formGlueContainer.submitData?.invoiceRows || []
      : formGlueTable.submitData || formGlueContainer.submitData?.invoiceRows,
  } as InvoicePlan;

  const initialSubmitData = {
    ...formGlueAdditionalInfo.submitDataInitial,
    ...formGlueContainer.submitDataInitial,
    invoiceRows: isCreate ? [] : formGlueTable.submitDataInitial,
  };

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createInvoicePlan,
        delete: deleteInvoicePlan,
        update: patchInvoicePlan,
      },
      data: {
        create: submitData,
        update: submitData,
        delete: [invoicePlanId],
      },
      dataId: invoicePlanId,
      name: "Invoice plan",
      rerouteUrlOnSuccess: {
        create: ({ responseData }) =>
          `/app/contracts/${contractId}/invoiceplans/${responseData?._id}`,
        delete: `/app/contracts/${contractId}/invoiceplans`,
      },
    },
    onSuccess: () => {
      formGlueAdditionalInfo.setHasTriedToSubmit(false);
      formGlueContainer.setHasTriedToSubmit(false);
      formGlueTable.setHasTriedToSubmit(false);
    },
  });

  const isFormValid =
    formGlueContainer.isFormValid && formGlueTable.isFormValid;

  const submitDisabled = isDisableSubmit({
    data: submitData,
    initialData: initialSubmitData,
    isValid: isFormValid,
  });

  useConfirmLeave({ when: !submitDisabled });

  return (
    <>
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit({ action: invoicePlanId ? "update" : "create" });
        }}
      >
        <PageWithTopPadding
          breadcrumbs={[
            { label: "Contracts", link: "/app/contracts" },
            {
              label: `Contract${escape(
                ` ${contract?.projectExternalId || ""}`,
              )}`,
              link: `/app/contracts/${contractId}`,
            },
            {
              label: "Invoice plans",
              link: `/app/contracts/${contractId}/invoiceplans`,
            },
            {
              label: `Invoice plan ${
                isCreate
                  ? "create"
                  : invoicePlan?.name
                    ? escape(" " + invoicePlan?.name)
                    : ""
              }`,
            },
          ]}
          label={isCreate ? "Create invoice plan" : "Edit invoice plan"}
          isLoading={
            isLoadingContract ||
            isLoadingInvoicePlan ||
            isLoadingInvoicePlanTemplates
          }
        >
          <SectionContainer>
            <InvoicePlanContainer
              contract={contract}
              formGlue={formGlueContainer}
              formGlueInvoiceRows={formGlueTable}
              invoicePlanInit={invoicePlanInitContainer}
              invoicePlanTemplates={invoicePlanTemplates}
              key={"container" + reloadKey}
            />
            <AdditionalInfo
              formGlue={formGlueAdditionalInfo}
              invoicePlanInit={invoicePlanInitAdditionalInfo}
              type="invoice"
              useExpand
            />
            <InvoicePlanRows
              contract={contract}
              formGlue={formGlueTable}
              invoicePlanInit={invoicePlan}
              invoicePlanTemplates={invoicePlanTemplates}
              isValid={
                !formGlueContainer.isHasDataChanged &&
                !formGlueTable.isHasDataChanged &&
                !formGlueTable.isEmpty
              }
              key={"table" + reloadKey}
            />
          </SectionContainer>

          {/* Floating Button Wrapper as a Footer*/}
          <SectionFloatingForButtons isFullWidth>
            <SectionWithButtonContainer sx={{ margin: 0 }}>
              <ButtonBack />
              <ButtonGroup>
                {!isCreate && (
                  <ButtonSecondary
                    onClick={() => setOpenInvoiceTemplateModal(true)}
                    disabled={!submitDisabled}
                  >
                    {t("Save as invoice template")}
                  </ButtonSecondary>
                )}
                <ButtonClear onClick={reload} />
                {!isCreate && (
                  <ButtonDeleteWithYesNoDialog
                    dialogContent={t(
                      `Are you sure you want to delete this invoice plan?`,
                    )}
                    onSubmit={() => {
                      onFormSubmit({ action: "delete" }).catch((e) =>
                        console.log(e),
                      );
                    }}
                  />
                )}
                <ButtonCreateOrUpdate
                  dialogContent={
                    invoicePlan?.locked &&
                    ["periodic", "milestone"].includes(
                      formGlueContainer.submitData?.type ||
                        invoicePlanInit?.type ||
                        "",
                    ) && (
                      <DialogContentWrapper
                        title="Are you sure you want to update the invoiceplan?"
                        message={
                          "This will update the already generated non-exported/invoiced invoices with the new " +
                          "properties and possibly delete/generate new invoices if the end date is altered"
                        }
                      />
                    )
                  }
                  initialSubmitData={initialSubmitData}
                  isSubmitting={isSubmitting}
                  isValid={isFormValid}
                  label={isCreate ? "create" : "update"}
                  onSubmit={() => {
                    refForm.current.requestSubmit();
                    formGlueAdditionalInfo.setHasTriedToSubmit(true);
                    formGlueContainer.setHasTriedToSubmit(true);
                    formGlueTable.setHasTriedToSubmit(true);
                  }}
                  permissions={invoicePlan?.permissions}
                  submitData={submitData}
                />
              </ButtonGroup>
            </SectionWithButtonContainer>
          </SectionFloatingForButtons>
        </PageWithTopPadding>
      </form>
      <InvoicePlanTemplateModal
        contractId={contractId}
        handleCloseModal={() => setOpenInvoiceTemplateModal(false)}
        invoicePlanId={invoicePlanId}
        openModal={openInvoiceTemplateModal}
      />
    </>
  );
};

export default InvoicePlanComponent;
