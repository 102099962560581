import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import {
  HIDDEN_COMPONENT_TYPE,
  HiddenComponent,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import {
  StructureContainer,
  StructureItemContainer,
} from "src/components/Builders/Container/CommonBuilderContainerTypes";

export interface GetStructureExtraProps {
  structure?: any;
}

export type ItemsTypes =
  | HIDDEN_COMPONENT_TYPE.SIDEBAR
  | HIDDEN_COMPONENT_TYPE.CONTRACT_VIEW
  | HIDDEN_COMPONENT_TYPE.HEADER_LINK_MAIN_PAGE;

interface CompanyConfigSubTabs {
  name: string;
  status: boolean;
}

export interface CompanyConfigStructureType {
  hiddenComponentType: HIDDEN_COMPONENT_TYPE;
  hiddenComponents: (HiddenComponent & { subTabs?: CompanyConfigSubTabs[] })[];
}

export const toFormData = ({
  data,
  types,
}: {
  data: CompanyConfigStructureType[];
  types: HIDDEN_COMPONENT_TYPE[];
}) => {
  const valueObj = types.reduce((res, type) => {
    const dataByType = data?.find(
      (d: CompanyConfigStructureType) => d.hiddenComponentType === type,
    );
    const item = dataByType?.hiddenComponents?.reduce(
      (
        res: any,
        currentValue: HiddenComponent & { subTabs?: CompanyConfigSubTabs[] },
      ) => {
        const value = currentValue?.subTabs
          ? {
              ...currentValue,
              subTabs: currentValue?.subTabs?.reduce(
                (resSubTab: any, currentSubTab: HiddenComponent) =>
                  Object.assign(resSubTab, {
                    [currentSubTab.name]: currentSubTab.status,
                  }),
                {},
              ),
            }
          : currentValue.status;
        return Object.assign(res, {
          [currentValue.name]: value,
        });
      },
      {},
    );
    return Object.assign(res, { ...item });
  }, {});
  return valueObj;
};

export const toSubmitData = ({
  data,
  structure,
}: {
  data: any[];
  structure: any;
}) => {
  const value = structure?.map((item: any) => {
    const { hiddenComponents } = item;
    let submitData: any = [];
    hiddenComponents?.forEach((component: any) => {
      const status = data[component.name]?.status || data[component.name];

      if (component?.subTabs) {
        component?.subTabs.forEach((subTab: any) => {
          submitData.push({
            name: subTab.name,
            status: data[component.name]?.subTabs[subTab.name],
          });
        });
      } else {
        submitData.push({ name: component.name, status });
      }
    });
    return { ...item, hiddenComponents: submitData };
  });
  return { hiddenComponentConfigs: value };
};

export const getStructure: UseFormContainerGetStructure<
  CompanyConfigStructureType,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<CompanyConfigStructureType, ItemsTypes> =
    {
      items:
        extraProps?.structure?.map((config: CompanyConfigStructureType) => {
          const item: StructureItemContainer<
            CompanyConfigStructureType,
            ItemsTypes
          > = {
            itemType: config.hiddenComponentType,
            gridProps: { xs: 12 },
            type: "switch_with_right_object",
            label: "",
            dataName: config.hiddenComponentType,
            items: config?.hiddenComponents?.map(
              (
                el: HiddenComponent & { subTabs?: CompanyConfigSubTabs[] },
                i: number,
              ) => ({
                gridProps: { xs: 12 },
                type: "switch_with_right_object",
                label: el.name,
                dataName: el?.subTabs ? "" : el.name,
                ...(el?.subTabs
                  ? {
                      items: el.subTabs?.map((tab: CompanyConfigSubTabs) => ({
                        gridProps: { xs: 6 },
                        type: "switch",
                        label: tab.name,
                        dataName: `${el.name}.subTabs.${tab.name}`,
                        setFormData,
                      })),
                    }
                  : {
                      items: [
                        {
                          gridProps: { xs: 6 },
                          type: "switch",
                          label: el.name,
                          dataName: el.name,
                          setFormData,
                        },
                      ],
                    }),
              }),
            ),
          };
          return item;
        }) || [],
    };

  return structure;
};
