import {
  AccountNumberSeries,
  ReportTemplateYFilter,
} from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import { DimensionExtra } from "src/redux/services/DimensionService";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { Types } from "mongoose";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import clone from "src/utils/clone";
import getSelectOptions from "src/utils/getSelectOptions";
import toUpdateData from "src/utils/toUpdateData";

export type GetStructureExtraProps = {
  labels: string[];
  dimensions?: DimensionExtra[];
};

export const getStructure: UseFormContainerGetStructure<
  Partial<ReportTemplateYFilter>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<ReportTemplateYFilter>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "label",
        label: "Label",
        gridProps: { md: 6 },
        validate: (props: any) => {
          return (
            props.data?.label.length >= 0 &&
            !extraProps?.labels?.includes(
              props.data?.label.toLowerCase().trim(),
            )
          );
        },
        getErrorText: () => "Name already in use. Please select another one",
        setFormData,
      },
      {
        type: "selector",
        dataName: "dimensionItemIds",
        label: "Dimensions",
        gridProps: { md: 6 },
        selectionOptions: { multiple: true },
        options: getSelectOptions({
          data: extraProps?.dimensions?.flatMap((dimension) =>
            dimension.items.map((dimensionItem) => ({
              label: `${dimension.name} - ${dimensionItem.name}`,
              value: `${dimension._id}.${dimensionItem._id}`,
            })),
          ),
        }),
        showWhen: () =>
          !!extraProps?.dimensions?.length &&
          extraProps?.dimensions?.length > 0,
        setFormData,
      },
      {
        type: "switch",
        dataName: "active",
        label: "Active/Inactive",
        gridProps: { md: 12 },
        setFormData,
      },
    ],
  };

  return structure;
};

export const getSeriesStructure: UserFormTableGetStructure<
  Partial<AccountNumberSeries>
> = ({ setFormData }) => {
  return {
    items: [
      {
        required: true,
        type: "number_input",
        dataName: "firstNumber",
        headerLabel: "First number",
        validate: ({ data, rowIndex }) => {
          const firstNumber = Number(data[rowIndex]?.firstNumber);
          const lastNumber = Number(data[rowIndex]?.lastNumber);
          return (
            firstNumber > 0 && (lastNumber === 0 || lastNumber >= firstNumber)
          );
        },
        getErrorText: ({ data, rowIndex }) => {
          const firstNumber = Number(data[rowIndex]?.firstNumber);
          const lastNumber = Number(data[rowIndex]?.lastNumber);

          if (lastNumber !== 0 && firstNumber > 0) {
            return "First number cant be greater than last number";
          }
          return "Number series needs to start from 1 or above";
        },
        setFormData,
      },
      {
        required: true,
        type: "number_input",
        dataName: "lastNumber",
        headerLabel: "Last number",
        validate: ({ data, rowIndex }) => {
          const firstNumber = Number(data[rowIndex]?.firstNumber);
          const lastNumber = Number(data[rowIndex]?.lastNumber);
          return (
            lastNumber > 0 && (firstNumber === 0 || lastNumber >= firstNumber)
          );
        },
        getErrorText: ({ data, rowIndex }) => {
          const firstNumber = Number(data[rowIndex]?.firstNumber);
          const lastNumber = Number(data[rowIndex]?.lastNumber);

          if (firstNumber !== 0 && lastNumber > 0) {
            return "Last number must be equal or greater than First number";
          }
          return "Number series needs to start from 1 or above";
        },
        setFormData,
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        onClick: ({ rowIndex, data }) => {
          let newFormData = [...data];
          newFormData.splice(rowIndex, 1);
          setFormData(newFormData);
        },
      },
    ],
  };
};

interface AddRangesTProps {
  formData: Partial<AccountNumberSeries>[];
  helperText: any[];
  setHelperText: (data: any) => void;
  setFormData: (data: Partial<AccountNumberSeries>[]) => void;
}
export const addRanges = (props: AddRangesTProps) => {
  let row = {
    firstNumber: "",
    lastNumber: "",
  } as Partial<AccountNumberSeries>;

  props.setHelperText([...props.helperText, {}]);
  props.setFormData([...props.formData, row]);
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<ReportTemplateYFilter & { dimensionItemIds: string[] }>;
  initData?: Partial<ReportTemplateYFilter & { dimensionItemIds: string[] }>;
}) => {
  if (!data) {
    return;
  }
  let newData = clone(data);
  const newAccountNumberSeries = newData?.accountNumberSeries?.map((range) => ({
    ...range,
    ...(newData.label ? { label: newData.label } : {}),
    ...(newData.transactionType
      ? { transactionType: newData.transactionType }
      : {}),
    firstNumber: range?.firstNumber?.toString(),
    lastNumber: range?.lastNumber?.toString(),
  }));
  newData.accountNumberSeries = newAccountNumberSeries;

  // re-structure values for dimensions
  const dimensions = newData.dimensionItemIds?.map((id: string) => ({
    dimensionId: new Types.ObjectId(id.split(".")[0]),
    dimensionItemId: new Types.ObjectId(id.split(".")[1]),
  }));
  newData.dimensions = dimensions;
  if (newData.dimensionItemIds) {
    delete newData.dimensionItemIds;
  }

  // Create request data
  let partialData: Partial<ReportTemplateYFilter>;
  if (newData._id) {
    partialData = toUpdateData<Partial<ReportTemplateYFilter>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  return partialData;
};
