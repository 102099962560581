import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { ApiKeyInformation } from "src/accurasee-backend-types/app/company/company.types";
import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { InvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { Types } from "mongoose";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ConsentDialog from "../../Basic/Mixed/Dialogs/ConsentDialog";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";

interface DeletionModalProps {
  deleteData?:
    | ContractType
    | InvoicePlanTemplate
    | InvoicePlan
    | AccessUserRole
    | ApiKeyInformation
    | { _id?: Types.ObjectId; name?: string };
  dataType:
    | "account number series"
    | "contact person"
    | "contract type"
    | "custom field"
    | "invoice plan template"
    | "invoice plan"
    | "file"
    | "project number series"
    | "report filter"
    | "user role"
    | "api key";
  openModal: boolean;
  deleteDataFunction?: (dataId?: string | Types.ObjectId) => void;
  deleteDataFunctionCustom?: () => void;
  setIsOpenDeleteModal: (v: boolean) => void;
}

const DeletionModal = (props: DeletionModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const name =
    (props?.deleteData as any)?.name || (props.deleteData as any)?.description; // Allow more than name

  return (
    <ConsentDialog
      handleClose={() => props.setIsOpenDeleteModal(false)}
      open={props.openModal}
      consentButtons={[
        {
          label: t("Cancel"),
          styles: {
            fontSize: "14px",
            textTransform: "uppercase",
            minWidth: "112px",
            boxShadow: "none",
            transition: "all 0.2s",
          },
        },
        {
          label: t("Delete"),
          styles: {
            fontSize: "14px",
            textTransform: "uppercase",
            minWidth: "112px",
            transition: "all 0.2s",
          },
          onClick: async () => {
            try {
              let deleteResult: any;
              if (!props?.deleteData?._id) {
                // Delete function without id
                if (props?.deleteDataFunctionCustom) {
                  deleteResult = await props.deleteDataFunctionCustom();
                } else {
                  return;
                }
              } else if (props?.deleteDataFunction) {
                deleteResult = await props.deleteDataFunction(
                  props.deleteData._id,
                );
              }
              if (deleteResult?.error) {
                throw deleteResult.error;
              }
              enqueueSnackbar(`${t(name as string)} ${t("has been deleted")}`, {
                variant: "success",
              });
            } catch (err) {
              const errorMessage = parseRestApiErrorMessage(err);
              const msg = `${t("Could not delete this")} ${t(props.dataType)}${errorMessage ? ` - ${errorMessage}` : ""}`;
              enqueueSnackbar(msg, { variant: "error" });
            }
          },
        },
      ]}
    >
      <Typography
        mb={1}
        sx={{
          fontWeight: "bold",
          fontSize: "18px",
          textTransform: "uppercase",
        }}
        color="var(--black-7)"
      >
        {t("Are you sure you want to delete this") +
          " " +
          t(props.dataType) +
          "?"}
      </Typography>
      <Typography
        sx={{ fontSize: "14px", textAlign: "center" }}
        color="var(--black-8)"
      >
        {t("This action is not recomended")}
      </Typography>
    </ConsentDialog>
  );
};
export default DeletionModal;
