import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { LinkedInvoiceDataGetList } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { userMUIDataSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import getStructure from "./RunningRateBillingDataSubTableStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useFormTable from "src/hooks/useFormTable";

interface LinkedInvoiceDataRowsSubtableProps {
  contract: GetSingleContractWithPermissions;
  invoiceDataRows: LinkedInvoiceDataGetList[] | InvoicePlanRow[];
}

const RunningRateBillingSubTable = ({
  contract,
  invoiceDataRows,
}: LinkedInvoiceDataRowsSubtableProps) => {
  const { structure } = useFormTable<LinkedInvoiceDataGetList | InvoicePlanRow>(
    {
      initialFormData: invoiceDataRows,
      getStructure,
      extraProps: { contract },
    },
  );

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<LinkedInvoiceDataGetList | InvoicePlanRow>({
      data: invoiceDataRows,
      item,
    }),
  );
  const options = userMUIDataSubTableOptions({});

  return (
    <SubTableContainer>
      <MuiDataTableWrapper
        title={""}
        data={invoiceDataRows}
        columns={columns}
        options={options}
        tableType="main-nested-level-1-centered"
      />
    </SubTableContainer>
  );
};

export default RunningRateBillingSubTable;
