import { backendApi } from "../api";
import { Cron } from "src/accurasee-backend-types/app/cron/cron.types";

export const cronApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    updateCron: build.mutation<any, Partial<Cron>[]>({
      query: (data) => {
        return {
          url: `/superadmin/cron/bulk`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: ["Crons"],
    }),
    getCron: build.query<{ data: Cron[]; metadata: any }, any>({
      query: (params) => {
        return { url: "/superadmin/cron", params };
      },
      providesTags: ["Crons"],
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateCronMutation, useGetCronQuery } = cronApi;

export default cronApi;
