import { ContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";

import getChangedData from "src/utils/getChangedData";

import { ActiveContract, ContractsByUser } from "./TransferOwnershipsStructure";

import { Mode } from "./EditModalStructure";

export const normailze = ({
  contracts,
  users,
}: {
  contracts: ContractWithPermissions[];
  users: ReturnUser[];
}) => {
  const result = users.map((user) => {
    // find contracts by ownerIds
    const activeContracts = contracts
      ?.filter(
        (contract) => user?._id && contract.ownerIds?.includes(user?._id),
      )
      .map((contract) => ({
        contractId: contract._id,
        contractName: contract.name,
        ownerIds: contract.ownerIds,
      }));
    return {
      id: user?._id,
      name: `${user.firstName} ${user.lastName}`,
      activeContracts,
    } as unknown as ContractsByUser;
  });
  return result;
};

export const getPayload = ({
  formData,
  formTableData,
  data,
  mode,
}: {
  formData?: Partial<ContractWithPermissions>;
  formTableData?: ActiveContract[];
  data?: ContractsByUser;
  mode: Mode;
}) => {
  if (mode === Mode.basic) {
    const payload = data?.activeContracts?.map((item: ActiveContract) => ({
      _id: item?.contractId,
      ownerIds: formData?.ownerIds,
    }));

    return payload;
  } else {
    const newData = getChangedData({
      data: formTableData || [],
      initData: data?.activeContracts,
    }).filter((el) => el !== null) as ActiveContract[];

    const payload = newData?.map((item: ActiveContract) => ({
      _id: item?.contractId,
      ownerIds: item?.ownerIds,
    }));

    return payload;
  }
};
