// @ts-nocheck
import DocumentHandler from "./DocumentHandler";

const PizZip = require("pizzip");
const Docxtemplater = require("docxtemplater");
const XlsxModule = require("docxtemplater-xlsx-module");

export default class ExcelHandler extends DocumentHandler {
  public generateDocument(
    content: ArrayBuffer | string,
    data: any,
  ): Promise<any> {
    console.log("data", data);

    return new Promise((resolve, reject) => {
      try {
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          modules: [new XlsxModule()],
          delimiters: { start: "<", end: ">" },
          nullGetter: this.nullGetter,
          syntax: {
            allowUnopenedTag: true,
            allowUnclosedTag: true,
          },
        });
        doc.setData(data);
        doc.render();
        const out = doc.getZip().generate({
          type: "arraybuffer",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          compression: "DEFLATE",
        });
        resolve(out);
      } catch (error) {
        reject(error);
      }
    });
  }
}
