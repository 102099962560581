import ApprovePlannedInvoicesModalWithForm from "../../Modals/ApprovePlannedInvoicesModalWithForm";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterOwnContractsButtonTooltip from "src/components/Basic/Mixed/Buttons/FilterOwnContractsButtonTooltip";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import getSummaries from "src/utils/getSummaries";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import PreviewInvoiceButton from "src/components/Basic/Mixed/Buttons/PreviewInvoiceButton";
import PromptDialog from "src/components/Basic/Simple/Dialogs/PromptDialog";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useOnSubmit from "src/hooks/useOnSubmit";
import usePlannedInvoicesTableStructure from "./hooks/usePlannedInvoicesTableStructure";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box } from "@mui/material";
import { Filter } from "src/hooks/useMUIDataTableFilterStates";
import { MUIDataTableColumnDef } from "mui-datatables";
import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import {
  useDeletePlannedInvoiceMutation,
  useGetPlannedInvoicesQuery,
} from "src/redux/services/PlannedinvoiceService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";

interface InvoiceTableProps {
  label?: string;
  plannedInvoiceFilter: Filter;
  useColumns: string[];
  redirectFoundationPath?: string;
}

const PlannedInvoicesTable = ({
  label,
  plannedInvoiceFilter,
  useColumns,
  redirectFoundationPath,
}: InvoiceTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data || [];

  const [selectedRows, setSelectedRows] = useState<PlannedInvoiceGetList[]>([]);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState<number[]>([]);
  const [errorCoinvoice, setErrorCoinvoice] = useState("");
  const { contractId, getStructure, openModal, setOpenModal, plannedInvoice } =
    usePlannedInvoicesTableStructure();

  const [deletePlannedInvoice] = useDeletePlannedInvoiceMutation();

  const onSubmit = useOnSubmit({
    apiMutations: { delete: deletePlannedInvoice },
    name: "planned invoice",
  });

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const {
    options,
    data: plannedInvoices,
    isLoading: isPlannedInvoicesLoading,
  } = useMUIDataTableOptionsServerSide<PlannedInvoiceGetList>({
    dataName: "planned invoices",
    filterInit: {
      base: {
        ...plannedInvoiceFilter,
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      isRowSelectable: (data, dataIndex: number) => {
        return data.length !== dataIndex;
      },
      selectableRows: "multiple",
      selectFunction: (_, current: any) => {
        const newListIndex = current.map((c: any) => c.dataIndex);
        const newList = current.map((c: any) => plannedInvoices[c.dataIndex]);
        setSelectedRowsIndex(newListIndex);
        setSelectedRows(newList);
      },
      rowsSelected: selectedRowsIndex,
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()}, ${t(
        "Name",
      ).toLowerCase()}, ${t("Customer name").toLowerCase()} ${t("or")} ${t(
        "Status",
      ).toLowerCase()}...`,
      deleteFunction: (rowsDeleted, newTableData): void | false => {
        const plannedInvoicesToDelete = rowsDeleted.data.map(
          (row) => plannedInvoices[row.dataIndex],
        );

        for (const plannedInvoice of plannedInvoicesToDelete) {
          if (!plannedInvoice?.permissions?.deleteRights) {
            enqueueSnackbar(
              `${t("Could not delete - deletion rights are missing")}`,
              {
                variant: "error",
              },
            );
            return false;
          } else if (plannedInvoice?._id) {
            onSubmit({ data: { delete: plannedInvoice?._id } });
          }
        }
      },
    },
    isRowExpandable: (data, dataIndex: number) => {
      return data.length !== dataIndex;
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const plannedInvoice = plannedInvoices[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={plannedInvoice.currencyCode}
            invoiceRows={plannedInvoice.invoiceRows}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
              "isIndex",
            ]}
          />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: contractId ? ["contractId"] : [],
    useGetDataQuery: useGetPlannedInvoicesQuery,
    customButton: ({ additionalFilter, setAdditionalFilter }) => {
      return (
        <>
          <ExportButton
            buttonType="icon"
            data={() => exportDataRef.current}
            fileName="planned-invoices.xlsx"
            fileType="xlsx"
          >
            <FileDownloadIcon />
          </ExportButton>
          <Box sx={{ display: "inline-flex", columnGap: "var(--space-8px)" }}>
            <FilterOwnContractsButtonTooltip
              additionalFilter={additionalFilter}
              setAdditionalFilter={setAdditionalFilter}
            />
            <PreviewInvoiceButton
              onPostPreview={() => {
                setSelectedRowsIndex([]);
                setSelectedRows([]);
              }}
              selectedRows={selectedRows}
            />
          </Box>
        </>
      );
    },
  });

  const { idSummaries, dataWithSummaries: plannedInvoicesWithSummaries } =
    getSummaries({
      data: plannedInvoices,
      sumTextKey: "number",
      keys: [
        { name: "totalExcludingVAT" },
        { name: "totalVAT" },
        { name: "total" },
      ],
    });

  let plannedInvoiceColumns: MUIDataTableColumnDef[] = getStructure({
    extraProps: {
      ...(redirectFoundationPath ? { redirectFoundationPath } : {}),
      currencyCode: company?.currencyCode,
      customers,
      idSummaries,
      users,
    },
  })
    .items.map((item) =>
      BuilderColumnOptionsSingle({
        data: plannedInvoicesWithSummaries,
        disabled: true,
        item,
      }),
    )
    .filter((column: any) => useColumns.includes(column.name));

  return (
    <SectionForTableWithToolbar
      isLoading={
        isPlannedInvoicesLoading ||
        isLoadingCustomers ||
        isLoadingCompany ||
        isLoadingUsers
      }
      label={label}
      transparent
    >
      <MuiDataTableWrapper
        columns={plannedInvoiceColumns}
        data={plannedInvoicesWithSummaries}
        isFetching={isPlannedInvoicesLoading}
        options={{
          ...options,
          onTableChange: (action, tableState) => {
            exportDataRef.current = getExportData(tableState);
          },
        }}
        tableType="main-with-filter"
        noBackground={!label}
      />
      <ApprovePlannedInvoicesModalWithForm
        plannedInvoice={plannedInvoice}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
      <PromptDialog
        open={errorCoinvoice !== ""}
        onClose={() => setErrorCoinvoice("")}
        title={errorCoinvoice}
        message={errorCoinvoice}
      />
    </SectionForTableWithToolbar>
  );
};

export default PlannedInvoicesTable;
