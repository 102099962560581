/**
 *Created by Mikael Lindahl on 2024-02-05
 */
import Section from "./Section";
import { Grid } from "@mui/material";
import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";

const SectionFill = (
  props: Omit<SectionCoreTProps, "withTightTableHeading">,
) => {
  const { md, lg, xs, ...rest } = props;

  return (
    <Grid item md={md} lg={lg} xs={xs}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        style={{ height: "calc(100% + 8px)" }}
      >
        <Section {...rest}>{props.children}</Section>
      </Grid>
    </Grid>
  );
};

export default SectionFill;
