/**
 *Created by Mikael Lindahl on 2024-06-27
 */

interface JobDisabledParams {
  job: any[];
  integrationImportJobName: string;
}

function isJobDisabled(params: JobDisabledParams) {
  const { job, integrationImportJobName } = params;

  return (
    job &&
    !!job.find(
      (d) =>
        (d.status === "running" || d.status === "pending") &&
        !(d.groupStatus === "error" || d.groupStatus === "completed") &&
        d.name === integrationImportJobName,
    )
  );
}

export default isJobDisabled;
