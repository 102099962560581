import {
  Assignment as AssignmentIcon,
  Home as HomeIcon,
  Description as ContractIcon,
  Apps as OperationsIcon,
  ViewList as ProductPlanIcon,
  Insights,
  Settings,
  Discount as DiscountIcon,
} from "@mui/icons-material";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { CheckAccessPermissionKey } from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";

const structure: {
  children?: {
    label: string;
    link: RoutePath;
    // other links that shares same styling for Section and Link
    otherLinks?: RoutePath[];
    permissions: CheckAccessPermissionKey[];
  }[];
  icon: JSX.Element;
  id: number;
  label: string;
  link?: RoutePath | "/app/dashboard";
  permissions?: CheckAccessPermissionKey[];
  section?: string;
}[] = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    permissions: ["readRights"],
  },
  {
    id: 1,
    section: "offers",
    label: "Contract offers",
    icon: <DiscountIcon />,
    children: [
      {
        label: "Contract offers list",
        link: "/app/contract-offers/active",
        otherLinks: ["/app/contract-offers/archived"],
        permissions: ["readRights"],
      },
      {
        label: "Add new contract offer",
        link: "/app/contract-offers/create",
        permissions: ["writeRights"],
      },
    ],
  },
  {
    id: 3,
    section: "contracts",
    label: "Contracts",
    icon: <ContractIcon />,
    children: [
      {
        label: "Contract list",
        link: "/app/contracts",
        permissions: ["readRights"],
      },
      {
        label: "Add new contract",
        link: "/app/contracts/create",
        permissions: ["writeRights"],
      },
      {
        label: "Contract types",
        link: "/app/contracts/contracttypes",
        permissions: ["readRights"],
      },
      {
        label: "Add contract type",
        link: "/app/contracts/contracttypes/create",
        permissions: ["writeRights"],
      },
      {
        label: "Invoice plan templates",
        link: "/app/contracts/invoiceplantemplates",
        permissions: ["readRights"],
      },
    ],
  },
  {
    id: 5,
    section: "products",
    label: "Products & Plans",
    icon: <ProductPlanIcon />,
    children: [
      {
        label: "Articles",
        link: "/app/product-and-plans/articles",
        permissions: ["readRights"],
      },
    ],
  },

  {
    id: 6,
    section: "operations",
    label: "Operations",
    icon: <OperationsIcon />,
    children: [
      {
        label: "Accounts",
        link: "/app/operations/accounts",
        permissions: ["readRights"],
      },
      {
        label: "Invoices",
        link: "/app/operations/invoices/planned",
        otherLinks: ["/app/operations/invoices/customer"],
        permissions: ["readRights"],
      },
      {
        label: "Supplier invoices",
        link: "/app/operations/supplierinvoices",
        permissions: ["readRights"],
      },
      {
        label: "Customers",
        link: "/app/operations/customers",
        permissions: ["readRights"],
      },
      {
        label: "Suppliers",
        link: "/app/operations/suppliers",
        permissions: ["readRights"],
      },
      {
        label: "Linked invoice data",
        link: "/app/operations/linkedinvoicedata",
        permissions: ["readRights"],
      },
      {
        label: "POC",
        link: "/app/operations/poc",
        permissions: ["readRights"],
      },
      {
        label: "Transfer Ownerships",
        link: "/app/operations/transferownerships",
        permissions: ["readRights"],
      },
    ],
  },
  {
    id: 7,
    section: "insights",
    label: "Insights",
    icon: <Insights />,
    children: [
      {
        label: "Company",
        link: "/app/insights/company",
        permissions: ["readRights"],
      },
      {
        label: "Contract",
        link: "/app/insights/contract",
        permissions: ["readRights"],
      },
      {
        label: "Reports",
        link: "/app/insights/reports",
        permissions: ["readRights"],
      },
    ],
  },
  {
    id: 8,
    section: "setup",
    label: "Setup",
    icon: <Settings />,
    children: [
      {
        label: "Company",
        link: "/app/setup/overview",
        permissions: ["readRights"],
      },
      {
        label: "Custom fields",
        link: "/app/setup/custom-fields",
        permissions: ["readRights"],
      },
      {
        label: "Dimensions",
        link: "/app/setup/dimensions",
        permissions: ["readRights"],
      },
      {
        label: "File drive",
        link: "/app/setup/file-drive",
        permissions: ["readRights"],
      },
      {
        label: "Integrations",
        link: "/app/setup/integrations",
        permissions: ["readRights"],
      },
      {
        label: "Indices",
        link: "/app/setup/indices",
        permissions: ["readRights"],
      },
      {
        label: "Number series",
        link: "/app/setup/number-series",
        permissions: ["readRights"],
      },
      {
        label: "Reports",
        link: "/app/setup/reports",
        permissions: ["readRights"],
      },
      {
        label: "Users",
        link: "/app/setup/members",
        permissions: ["readRights"],
      },
      {
        label: "Accounting settings",
        link: "/app/setup/accounting",
        permissions: ["readRights"],
      },
    ],
  },
  {
    id: 9,
    label: "Project tracker",
    link: "/app/project-tracker",
    icon: <AssignmentIcon />,
    permissions: ["readRights"],
  },
  // Hide FAQ until it is implemented
  // {
  //   id: 19,
  //   label: "FAQ",
  //   link: "/app/faq",
  //   icon: <FAQIcon />,
  // },
];

export default structure;
