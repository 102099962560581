/**
 *Created by Mikael Lindahl on 2024-04-08
 */

import LaunchIcon from "@mui/icons-material/Launch";
import useTranslation from "src/hooks/useTranslationWrapper";
import { FC } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../../accurasee-backend-types/app/general/routes.types";
import styled from "@emotion/styled";

type CustomLinkProps = {
  label: string;
  href: RoutePath;
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--green-primary);
  column-gap: var(--space-4px);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AppLink: FC<CustomLinkProps> = ({ label, href }) => {
  const [t] = useTranslation();
  return (
    <StyledLink to={href}>
      {t(label)}
      <LaunchIcon />
    </StyledLink>
  );
};

export default AppLink;
