/**
 *Created by Mikael Lindahl on 2024-02-05
 */

import useTranslation from "./useTranslationWrapper";
import { FileStorageExtra } from "../components/Builders/Container/ItemTypes/FileItem";
import { useSnackbar } from "notistack";
import {
  useDeleteFileNoInvalidateMutation,
  usePostFileNoInvalidateMutation,
} from "../redux/services/FileService";

const useOnSubmitFiles = ({
  collectionObjectId,
}: {
  collectionObjectId?: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const [deleteFileMutation] = useDeleteFileNoInvalidateMutation();
  const [postFileMutation] = usePostFileNoInvalidateMutation();

  const submitFiles = async ({ files }: { files: FileStorageExtra[] }) => {
    let successes = [];
    let failures = [];

    for (const { _id, file, isDeleted, originalName } of files) {
      try {
        if (isDeleted && _id && collectionObjectId) {
          const res: any = await deleteFileMutation({
            id: _id,
            objectId: collectionObjectId,
          });
          if (res?.error) {
            failures.push({
              error: res.error,
              msg: `${t("Could not delete file")} ${originalName}`,
            });
          } else {
            successes.push({
              msg: `${t("File deleted")} ${originalName}`,
            });
          }
        } else if (file && collectionObjectId) {
          const fileData = new FormData();

          fileData.append("objectId", String(collectionObjectId));
          fileData.append("file", file);
          const res: any = await postFileMutation(fileData);
          if (res?.error) {
            failures.push({
              error: res.error,
              msg: `${t("Could not upload file")} ${originalName}`,
            });
          } else {
            successes.push({
              msg: `${t("File uploaded")} ${originalName}`,
            });
          }
        }
      } catch (error) {
        failures.push({
          error,
          msg: `${t("Could not upload file")} ${originalName}`,
        });
      }
    }
    failures.forEach((f) => {
      console.error(f.error);
      enqueueSnackbar(f.msg, {
        variant: "error",
      });
    });
    successes.forEach((s) => {
      enqueueSnackbar(s.msg, {
        variant: "success",
      });
    });
  };

  return { submitFiles };
};

export default useOnSubmitFiles;
