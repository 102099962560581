import {
  getStructure,
  GetStructureExtraProps,
} from "../Modals/ApprovePlannedInvoicesModalGetStructure";
import {
  PlannedInvoiceGetList,
  PlannedInvoiceRow,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { Box, Typography } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useStyles } from "./PreviewInvoiceContainerStyles";
import BoxScrollWithShadow from "src/components/Basic/Simple/Box/BoxScrollWithShadow";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import InvoicesTotals from "src/components/Complex/Tables/InvoicesTotals";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import toDay from "src/utils/date";
import useFormTable from "src/hooks/useFormTable";
import useGetArticles from "src/hooks/useGetArticles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface PreviewInvoiceContainerProps {
  previewData: PlannedInvoiceGetList;
}

const PreviewInvoiceContainer = ({
  previewData,
}: PreviewInvoiceContainerProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const { articlesWithContractArticleData, isLoading: isLoadingArticles } =
    useGetArticles(previewData?.contractId);

  const { data: customersQuery, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customer = customersQuery?.data?.find(
    (item) => item._id === previewData?.customerId,
  );

  const {
    data: termsOfPaymentsQueryResult,
    isLoading: isLoadingTermsOfPayments,
  } = useGetTermsOfPaymentsQuery(undefined);
  const termsOfPayment = termsOfPaymentsQueryResult?.find(
    (t) => t._id === previewData?.termsOfPaymentId,
  )?.description;

  const { formData, structure } = useFormTable<
    PlannedInvoiceRow,
    GetStructureExtraProps
  >({
    extraProps: {
      articles: articlesWithContractArticleData,
      currencyCode: previewData?.currencyCode,
    },
    initialFormData: previewData?.invoiceRows,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items
    .filter((i) => i.cellLabel !== "Estimate")
    .map((item) =>
      BuilderColumnOptionsSingle<PlannedInvoiceRow>({
        data: formData,
        disabled: true,
        item,
      }),
    );

  const { options } = useMUIDataTableOptions({
    pagination: false,
  });

  return (
    <BoxScrollWithShadow>
      <SectionContainer
        isLoading={
          isLoadingArticles || isLoadingCustomers || isLoadingTermsOfPayments
        }
      >
        <Section boxStyle={{ paddingBottom: 0 }}>
          <Box className={classes.wrapper}>
            <Box className={classes.leftBox}>
              {previewData?.contractName && previewData.projectExternalId ? (
                <Box>
                  <Typography className={classes.contentTitle}>
                    {t("Contract name")}
                  </Typography>
                  <Typography className={classes.content}>
                    {`${previewData.projectExternalId} | ${previewData.contractName}`}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              {termsOfPayment ? (
                <Box>
                  <Typography className={classes.contentTitle}>
                    {t("Terms of payment")}
                  </Typography>
                  <Typography className={classes.content}>
                    {termsOfPayment}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              {previewData?.dueDate ? (
                <Box>
                  <Typography className={classes.contentTitle}>
                    {t("Due date")}
                  </Typography>
                  <Typography className={classes.content}>
                    {toDay(previewData.dueDate)}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              {previewData?.invoiceDate ? (
                <Box>
                  <Typography className={classes.contentTitle}>
                    {t("Invoice date")}
                  </Typography>
                  <Typography className={classes.content}>
                    {toDay(previewData.invoiceDate)}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box className={classes.rightBox}>
              {customer ? (
                <>
                  {customer?.name && customer?.externalId ? (
                    <Box>
                      <Typography className={classes.contentTitle}>
                        {t("Customer")}
                      </Typography>
                      <Typography className={classes.content}>
                        {`${customer.externalId} - ${customer?.name}`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {previewData?.ourReference ? (
                    <Box>
                      <Typography className={classes.contentTitle}>
                        {t("Our reference")}
                      </Typography>
                      <Typography className={classes.content}>
                        {previewData.ourReference}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {previewData?.yourReference ? (
                    <Box>
                      <Typography className={classes.contentTitle}>
                        {t("Your reference")}
                      </Typography>
                      <Typography className={classes.content}>
                        {previewData.yourReference}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {previewData?.yourOrderNumber ? (
                    <Box>
                      <Typography className={classes.contentTitle}>
                        {t("Your order number")}
                      </Typography>
                      <Typography className={classes.content}>
                        {previewData.yourOrderNumber}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Box>
                  <Typography className={classes.contentTitle}>
                    {t("Customer")}
                  </Typography>
                  <Typography className={classes.warning}>
                    {t("Note: This customer is not active")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Section>
        <Section>
          <MuiDataTableWrapper
            title={"Data table"}
            data={formData || []}
            columns={columns}
            options={options}
            noBackground
          />
        </Section>
        <Section
          boxStyle={{
            marginLeft: "auto",
            width: "fit-content",
            backgroundColor: "transparent",
          }}
        >
          <InvoicesTotals
            invoicePlan={previewData}
            invoiceRows={previewData?.invoiceRows}
          />
        </Section>
      </SectionContainer>
    </BoxScrollWithShadow>
  );
};
export default PreviewInvoiceContainer;
