import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import ContactPersonModal from "src/components/Complex/Modals/ContactPersonModal";
import {
  getStructure,
  GetStructureExtraProps,
} from "./ContactPersonTableStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useDeleteContactPersonMutation } from "src/redux/services/ContactPersonService";
import { useGetContactPeopleQuery } from "src/redux/services/ContactPersonService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormTable from "src/hooks/useFormTable";
import usePathPermission from "src/hooks/usePathPermission";
import useTranslation from "src/hooks/useTranslationWrapper";

const ContactPersonTable = () => {
  const [t] = useTranslation();
  let { id } = useParams<{ id: string }>();
  const pathPermissions = usePathPermission("/app/operations/customers");

  const { data: contactsRes, isLoading } = useGetContactPeopleQuery(
    { customerId: id },
    { skip: !id },
  );
  const contacts = contactsRes?.data || [];

  const [deleteContactPerson] = useDeleteContactPersonMutation();

  const [openAddContactPersonModal, setOpenAddContactPersonModal] =
    useState(false);
  const [openEditContactPersonModal, setOpenEditContactPersonModal] =
    useState(false);
  const [editContactPersonData, setEditContactPersonData] = useState<
    ContactPerson | undefined
  >();
  const [openDeleteContactPersonModal, setOpenDeleteContactPersonModal] =
    useState(false);
  const [deleteContactPersonData, setDeleteContactPersonData] = useState<
    ContactPerson | undefined
  >();

  const { formData, structure } = useFormTable<
    ContactPerson,
    GetStructureExtraProps
  >({
    extraProps: {
      onDelete: (props) => {
        setOpenDeleteContactPersonModal(true);
        setDeleteContactPersonData(props.data[props.rowIndex]);
      },
      onEdit: (props) => {
        setOpenEditContactPersonModal(true);
        setEditContactPersonData(props.data[props.rowIndex]);
      },
    },
    initialFormData: contacts,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ContactPerson>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionTight
      isLoading={isLoading}
      label="Contact People"
      rightBoxArea={
        <ButtonPrimary
          id="add-contact-person-button"
          onClick={() => setOpenAddContactPersonModal(true)}
          permissions={pathPermissions}
          permissionType={"writeRights"}
        >
          {t("Add a contact person")}
        </ButtonPrimary>
      }
    >
      <MuiDataTableWrapper
        nameSpace="main-table"
        tableType="main-with-filter"
        title=""
        data={formData}
        columns={columns}
        options={options}
        noBackground
      />

      {/* Add Modal */}
      <ContactPersonModal
        customerId={id}
        openModal={openAddContactPersonModal && !!pathPermissions?.writeRights}
        setOpenModal={setOpenAddContactPersonModal}
      />

      {/* Edit Modal */}
      <ContactPersonModal
        customerId={id}
        data={editContactPersonData}
        openModal={openEditContactPersonModal && !!pathPermissions?.writeRights}
        setOpenModal={setOpenEditContactPersonModal}
      />

      {/* Delete modal */}
      <DeletionModal
        dataType="contact person"
        openModal={openDeleteContactPersonModal}
        deleteData={{
          ...deleteContactPersonData,
          name: `${deleteContactPersonData?.firstName} ${deleteContactPersonData?.lastName}`,
        }}
        deleteDataFunction={deleteContactPerson}
        setIsOpenDeleteModal={setOpenDeleteContactPersonModal}
      />
    </SectionTight>
  );
};

export default ContactPersonTable;
