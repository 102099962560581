import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import FormHelperText from "@mui/material/FormHelperText";
import getHelperText from "../Utils/getHelperText";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import setValue from "../Utils/setValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import useTranslation from "src/hooks/useTranslationWrapper";

type CheckboxItemTProps = CommonBuilderContainerTypes;

const CheckboxItem = (props: CheckboxItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
  };

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "CheckboxItem",
    });
  }

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} alignSelf={"center"} {...props.item.gridProps}>
      <FormControl
        sx={{ backgroundColor: "transparent" }}
        error={Boolean(getHelperText(getHelperTextProps))}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(getValue(commonCallbackProps))}
              name={props.item.dataName}
              disabled={props.itemDisabled}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.persist();
                const { checked } = event.target;
                let changeProps = {
                  ...commonCallbackProps,
                  value: checked,
                };
                if (props.item.setFormDataCustom) {
                  props.item.setFormDataCustom(changeProps);
                } else {
                  setValue(changeProps);
                }
              }}
              sx={{
                padding: "0 var(--space-4px) 0 0",
                "&.Mui-checked": { color: "var(--green-1)" },
              }}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: 12 }}>
              {props.item.label ? t(props.item.label) : ""}
            </Typography>
          }
          labelPlacement="end"
          sx={{ margin: 0 }}
        />
        <FormHelperText
          sx={{
            marginLeft: 0,
            color: "red !important",
            textAlign: "center",
          }}
        >
          {getHelperText(getHelperTextProps)}
        </FormHelperText>
      </FormControl>
    </Grid>
  ) : (
    <></>
  );
};

export default CheckboxItem;
