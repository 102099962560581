/**
 * This is to check if an array of a range is overlapped
 * @returns {boolean} true for overlapped and false for non overlapped
 *
 */

export const DEFAULT_NUMBER_SERIES_OVERLAP_RESULT = {
  isOverlapFirstNumber: false,
  isOverlapLastNumber: false,
  isOverlapInterval: false,
  failedSeriesFirstNumber: null,
  failedSeriesLastNumber: null,
  failedSeriesInterval: [],
};

export type NumberSeries = {
  firstNumber: number;
  lastNumber: number;
  prefix?: string;
};

// For single number
interface checkOverlappedNumberProps {
  number: number;
  numberSeries?: NumberSeries[];
  prefix?: string;
}

export type OverlapResult = {
  isOverlapFirstNumber: boolean;
  isOverlapLastNumber: boolean;
  isOverlapInterval: boolean;
  failedSeriesFirstNumber: NumberSeries | null;
  failedSeriesLastNumber: NumberSeries | null;
  failedSeriesInterval: {
    isOverlapFirstNumber?: Boolean;
    isOverlapLastNumber?: Boolean;
    interval: NumberSeries;
  }[];
};

/**
 * Check if number + prefix is overlapped with the number series
 *
 * @param number
 * @param numberSeries
 * @param prefix
 */
export const checkOverlappedNumber = ({
  number,
  numberSeries,
  prefix,
}: checkOverlappedNumberProps) => {
  let isOverlapped = false;
  let atIndex = -1;

  if (number === undefined || !numberSeries) {
    return {
      isOverlapped,
      atIndex,
    };
  }

  const numberEnsured = Number(number);
  if (isNaN(numberEnsured)) {
    throw new Error("Invalid number in checkOverlappedNumber");
  }

  const emptyPrefixValues = [undefined, ""];

  numberSeries?.forEach((e, index) => {
    if (
      (!emptyPrefixValues.includes(e.prefix) ||
        !emptyPrefixValues.includes(prefix)) &&
      e.prefix !== prefix
    )
      return;

    if (numberEnsured >= e.firstNumber && numberEnsured <= e.lastNumber) {
      isOverlapped = true;
      atIndex = index;
    }
  });

  return { isOverlapped, atIndex, atNumberSeries: numberSeries[atIndex] };
};

/**
 * Check if a single number series is overlapping with a list of number series
 *
 * @param singleNumberSeries
 * @param listNumberSeries
 */
export const checkOverlappedNumberSeries = ({
  singleNumberSeries,
  listNumberSeries,
}: {
  singleNumberSeries?: NumberSeries;
  listNumberSeries?: NumberSeries[];
}) => {
  const failedSeries: {
    isFirstNumberInIntervalOverlap?: Boolean;
    isLastNumberInIntervalOverlap?: Boolean;
    interval: NumberSeries;
  }[] = [];

  const res: OverlapResult = { ...DEFAULT_NUMBER_SERIES_OVERLAP_RESULT };

  if (singleNumberSeries === undefined || listNumberSeries === undefined) {
    return res;
  }

  if (singleNumberSeries.firstNumber !== undefined) {
    const firstOverlap = checkOverlappedNumber({
      number: singleNumberSeries.firstNumber,
      numberSeries: listNumberSeries,
      prefix: singleNumberSeries.prefix,
    });
    res.isOverlapFirstNumber = firstOverlap.isOverlapped;
    res.failedSeriesFirstNumber = firstOverlap.atNumberSeries
      ? firstOverlap.atNumberSeries
      : null;
  }

  if (singleNumberSeries.lastNumber !== undefined) {
    const lastOverlap = checkOverlappedNumber({
      number: singleNumberSeries.lastNumber,
      numberSeries: listNumberSeries,
      prefix: singleNumberSeries.prefix,
    });
    res.isOverlapLastNumber = lastOverlap.isOverlapped;
    res.failedSeriesLastNumber = lastOverlap.atNumberSeries
      ? lastOverlap.atNumberSeries
      : null;
  }

  for (const s of listNumberSeries) {
    const firstOverlap = checkOverlappedNumber({
      number: s.firstNumber,
      numberSeries: [singleNumberSeries],
      prefix: s.prefix,
    });

    const lastOverlap = checkOverlappedNumber({
      number: s.lastNumber,
      numberSeries: [singleNumberSeries],
      prefix: s.prefix,
    });
    if (firstOverlap.isOverlapped || lastOverlap.isOverlapped) {
      failedSeries.push({
        isFirstNumberInIntervalOverlap: firstOverlap.isOverlapped,
        isLastNumberInIntervalOverlap: lastOverlap.isOverlapped,
        interval: s,
      });
    }
  }

  res.isOverlapInterval = failedSeries.length !== 0;
  res.failedSeriesInterval = failedSeries;

  return res;
};

/**
 * Check if first number is less than last number and both are valid numbers
 *
 * @param props
 */
export const isInvalidNumberSeriesRelation = (props?: NumberSeries) => {
  if (props === undefined) {
    return false;
  }

  const firstNumberEnsured = Number(props?.firstNumber);
  const lastNumberEnsured = Number(props?.lastNumber);

  if (isNaN(firstNumberEnsured) || isNaN(lastNumberEnsured)) {
    return false;
  }
  return firstNumberEnsured >= lastNumberEnsured;
};

/**
 * Check if a number is less than 1
 *
 * @param number
 */
export const isNumberToSmall = (number?: number | string) => {
  const numberEnsured = Number(number);
  if (isNaN(numberEnsured)) {
    return false;
  }
  return numberEnsured < 1;
};
