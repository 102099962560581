import { useRef, useState } from "react";

import Tabs, { TabsTProps } from "./Tabs";

/**
 * This component builds on the functionality in Tabs and extends it
 * with the fixed size for Height and Width
 *
 * Value is changed only if the new one is greater than the previous one
 */

const TabsFixedSize = (props: TabsTProps) => {
  // For Tab height consistent
  const tabRef = useRef<HTMLDivElement>(null);

  const [tabHeight, setTabHeight] = useState(0);
  const [tabWidth, setTabWidth] = useState(0);

  // To set minHeight and minWidth for tabPanel
  const onSetPanelSize = () => {
    if (
      tabRef?.current?.clientHeight &&
      tabRef?.current?.clientHeight > tabHeight
    ) {
      setTabHeight(tabRef?.current?.clientHeight);
    }
    if (
      tabRef?.current?.clientWidth &&
      tabRef?.current?.clientWidth > tabWidth
    ) {
      setTabWidth(tabRef?.current?.clientWidth);
    }
  };

  return (
    <div ref={tabRef}>
      <Tabs
        {...props}
        tabContainerSx={{
          marginTop: 0,
          minHeight: tabHeight,
          minWidth: tabWidth,
        }}
        tabLabelSx={{ textTransform: "none" }}
        tabPanelSx={{ padding: "24px 0" }}
        onChange={onSetPanelSize}
      />
    </div>
  );
};

export default TabsFixedSize;
