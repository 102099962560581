import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import MicrosoftDocTemplateTagTable from "./MicrosoftDocTemplateTagTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import templateTagsContract from "src/context/MicrosoftDriveProvider/Data/templateTagsContract";
import templateTagsContractOffer from "src/context/MicrosoftDriveProvider/Data/templateTagsContractOffer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { CompanyConfig } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { Grid } from "@mui/material";
import { getStructure, toSubmitData } from "./FileDriveGetStructure";
import {
  useGetCompanyConfigQuery,
  useUpdateCompanyConfigsMutation,
} from "src/redux/services/CompanyConfigsService";
import Tabs from "src/components/Basic/Mixed/Tabs/Tabs";

const FileDrive = () => {
  const [t] = useTranslation();

  const { data: companyConfigsQuery, isLoading } =
    useGetCompanyConfigQuery(undefined);
  const driveConfig = companyConfigsQuery?.data?.drive || {
    type: "bucket",
    config: null,
  };

  const [updateCompanyConfigs] = useUpdateCompanyConfigsMutation();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<CompanyConfig["drive"]>({
    getStructure,
    initialFormData: driveConfig,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "File drive",
      data: { update: { drive: toSubmitData(formData) } },
      dataId: String(companyConfigsQuery?.data?._id),
      apiMutations: {
        update: updateCompanyConfigs,
      },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setFormData(driveConfig);
    },
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <Page
      label={"File Drive"}
      breadcrumbs={[{ label: "Setup" }, { label: "File drive" }]}
      isFullWidth={false}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit({ action: "update" });
        }}
        ref={refForm}
      >
        <SectionContainer isLoading={isLoading}>
          <Section key="file-drive-type" label="File drive type">
            {BuildItems({
              items: structure.items.filter(
                (item) => item.itemType === "general",
              ),
              ...commonPropsBuildItem,
            })}
          </Section>
          {formData?.type === "microsoft" ? (
            <Section key="microsoft-config" label="Drive Config">
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "type_microsoft",
                ),
                ...commonPropsBuildItem,
              })}
            </Section>
          ) : (
            <Section key="bucket-config" label="Bucket Drive Config">
              <Grid item xs={12}>
                {t("No config needed for bucket")}
              </Grid>
            </Section>
          )}
        </SectionContainer>
        <SectionWithButtonContainer>
          <ButtonGroup>
            <ButtonClear
              id="file-drive-cancel-button"
              onClick={() => setFormData(driveConfig)}
            />
            <ButtonUpdate
              id="file-drive-update-button"
              initialSubmitData={{}}
              isValid={isFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={formData}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </form>

      {formData?.type === "microsoft" && (
        <Section
          label={"Template tags"}
          subLabel={"Microsoft document template key and value pairs"}
        >
          <Tabs
            tabContainerSx={{ width: "100%" }}
            name="Microsoft templates"
            tabs={["Contract", "Contract offer"]}
          >
            <MicrosoftDocTemplateTagTable data={templateTagsContract} />
            <MicrosoftDocTemplateTagTable data={templateTagsContractOffer} />
          </Tabs>
        </Section>
      )}
    </Page>
  );
};

export default FileDrive;
