/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import _c from "src/constants/Constants";
import { InvoicePlanWithPermissions } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";

export type InvoicePlanWithPermissionsExtended = InvoicePlanWithPermissions & {
  rowNumber: string;
};
interface GetStructureExtraProps {
  location: Location;
  onDelete: (rowIndex: number) => void;
}

export const getStructure: UserFormTableGetStructureOptional<
  InvoicePlanWithPermissionsExtended,
  GetStructureExtraProps
> = (props) => {
  const t = props?.t ? props.t : (s: string) => s;
  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
      },
      {
        dataName: "name",
        headerLabel: "name",
        type: "link",
        redirectLink: (_props) =>
          props?.extraProps?.location.pathname +
          "/" +
          String(_props.data[_props.rowIndex]._id),
      },
      {
        dataName: "type",
        headerLabel: "Type",
        type: "text",
        getValue: ({ data, rowIndex }) => {
          const { label } =
            _c.OPTIONS_INVOICE_TYPE.find(
              (e) => e.value === data[rowIndex].type,
            ) || {};
          return label ? t(label) : "-";
        },
      },
      {
        type: "badge",
        dataName: "status",
        alignColumnContent: "center",
        headerLabel: "status",
      },
      {
        type: "badge",
        dataName: "customerStatus",
        alignColumnContent: "center",
        headerLabel: "Customer status",
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        onClick: ({ rowIndex }) => {
          if (props?.extraProps?.onDelete) {
            props?.extraProps?.onDelete(rowIndex);
          }
        },
      },
    ],
  };
};
