import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonSave from "src/components/Basic/Simple/Buttons/ButtonSave";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import getChangedData from "src/utils/getChangedData";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import {
  Company,
  ReturnCompanyWithPermissions,
} from "src/accurasee-backend-types/app/company/company.types";
import { LinearProgress } from "@mui/material";
import {
  getStructure,
  GetStructureExtraProps,
} from "./CompanyDetailsGetStructure";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "src/redux/services/CompanyService";
import useConfirmLeave from "src/hooks/useConfirmLeave";

const CompanyDetails = () => {
  const { data, isLoading } = useGetCompanyQuery(undefined);
  const company = data?.company;

  const [updateCompany] = useUpdateCompanyMutation();

  const { formData, isFormValid, hasDataChanged, helperText, structure } =
    useFormContainer<ReturnCompanyWithPermissions, GetStructureExtraProps>({
      getStructure,
      initialFormData: company,
    });

  const { onFormSubmit, refForm } = useOnFormSubmit<Partial<Company>>({
    submitProps: {
      apiMutations: {
        update: updateCompany,
      },
      data: {
        update: getChangedData<Partial<Company>>({
          data: formData || {},
          initData: company,
        }),
      },
      name: "Company information",
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const isSaveDisabled = !isFormValid || !hasDataChanged;

  useConfirmLeave({
    when: !isSaveDisabled,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        label={"Company information"}
        subLabel={
          "Majority of company data is fetched from accounting integration"
        }
        rightBoxArea={
          <ButtonSave
            disabled={isSaveDisabled}
            onClick={() => {
              refForm.current.requestSubmit();
              // setHasTriedToSubmit(true);
            }}
          />
        }
      >
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </SectionTight>
    </form>
  );
};

export default CompanyDetails;
