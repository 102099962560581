import { Types } from "mongoose";
import { GlobalType } from "../general/global.types";

export enum CriticalLevel {
  Critical = "critical",
  Low = "low",
  Normal = "normal",
}

export enum WarningType {
  ProductionIssue = "ProductionIssue",
  UserAction = "UserAction",
  ReleaseNotes = "ReleaseNotes",
}

export interface Warning extends GlobalType {
  message: string;
  issueType: WarningType;
  page?: string;
  details?: string;
  companyId?: Types.ObjectId;
  criticalLevel: CriticalLevel;
  active: boolean;
}
