/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { FormControl, MenuItem, Select } from "@mui/material";
import getRequired from "../Utils/getRequired";
import getOptions from "../Utils/getOptions";
import getValue from "../Utils/getValue";
import getDisabled from "../Utils/getDisabled";
import setValue from "../Utils/setValue";
import FormHelperText from "@mui/material/FormHelperText";
import getHelperText from "../Utils/getHelperText";
import getSx from "../Utils/getSx";
import getSelectedOptions from "../Utils/getSelectedOptions";
import getIsInActiveWarning from "../../../../utils/getIsInActiveWarning";

type TextColumnItemTProps = CommonBuilderTableTypes;

const SelectorColumnItem = (props: TextColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);

  const options = getOptions(commonCallbackProps) || [];

  const value = getValue(commonCallbackProps);

  const selectedOptions = getSelectedOptions(commonCallbackProps);

  return (
    <FormControl
      sx={{
        backgroundColor: "transparent",
        ...(selectedOptions?.some((o) => o.isActive === false) && {
          "& .MuiOutlinedInput-root": {
            borderColor: "var(--warning)",
            "& fieldset": {
              borderColor: "var(--warning)", // Set your desired color here
            },
            "&:hover fieldset": {
              borderColor: "var(--warning)", // Color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "var(--warning)", // Color when focused
            },
          },
        }),
      }}
      error={Boolean(getHelperText(getHelperTextProps))}
    >
      <Select
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "white",
              },
            },
          },
        }}
        sx={getSx(commonCallbackProps)}
        size="small"
        labelId={props.item.dataName as string}
        id={props.item.dataName as string}
        disabled={itemDisabled}
        name={props.item.dataName as string}
        // return array for mulptiple and string for single selector

        value={!options || options.length === 0 ? "" : value || ""} // To avoid MUI warning out-of-range
        // value={getValue(commonCallbackProps) || ""}
        defaultValue={props.item.defaultValue}
        multiple={props.item?.selectionOptions?.multiple}
        required={getRequired(commonCallbackProps)}
        onChange={(event) => {
          let changeProps = {
            ...commonCallbackProps,
            value: event.target.value,
          };
          if (props.item.setFormDataCustom) {
            props.item.setFormDataCustom(changeProps);
          } else {
            setValue(changeProps);
          }
        }}
      >
        {options?.map((option) => {
          return (
            <MenuItem
              key={`${props.item.dataName as string}.${option.value}`}
              value={option.value}
              disabled={option?.disabled || !option?.isActive}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {selectedOptions?.some((o) => o.isActive === false) && (
        <FormHelperText
          sx={{
            marginLeft: 2,
            color: "var(--gray-3)",
            textAlign: "left",
          }}
        >
          {getIsInActiveWarning(selectedOptions)}
        </FormHelperText>
      )}
      {getHelperText(getHelperTextProps) && (
        <FormHelperText
          sx={{
            marginLeft: 2,
            color: "red !important",
            textAlign: "left",
          }}
        >
          {getHelperText(getHelperTextProps)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectorColumnItem;
