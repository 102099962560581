import AddBoxIcon from "@mui/icons-material/AddBox";
import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";

type ButtonAddBoxIconProps = ButtonSubmitCoreTProps & {
  disabled?: boolean;
  onSubmit: () => void;
};

const ButtonAddBoxIcon = ({ id, label, ...rest }: ButtonAddBoxIconProps) => {
  return (
    <ButtonSubmitCore
      id={id || `${label}-button`}
      label={label}
      startIcon={<AddBoxIcon />}
      {...rest}
    />
  );
};

export default ButtonAddBoxIcon;
