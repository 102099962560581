import { backendApi } from "../api";
import {
  docDeleteTags,
  docListTags,
  docPatchTag,
  listTag,
  docMultipleListTag,
} from "./utils";
import {
  ContractOffer,
  ContractOfferTemplateData,
  RespondGetContractOffer,
} from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";

const CACHE_TYPE = "ContractOffers";

const contractOffersApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getContractOffersActive: build.query<
      { data: ContractOffer[]; metadata: any },
      any
    >({
      query: (params) => ({
        url: `/contract-offers/active`,
        params,
      }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getContractOffersArchived: build.query<
      { data: ContractOffer[]; metadata: any },
      any
    >({
      query: (params) => ({
        url: `/contract-offers/archived`,
        params,
      }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getContractOffer: build.query<ContractOffer, any>({
      query: (id) => ({ url: `/contract-offers/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    getContractOfferTemplateData: build.query<ContractOfferTemplateData, any>({
      query: (id) => ({ url: `/contract-offers/template-data/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    getSubcontractOffers: build.query<
      { data: ContractOffer[]; metadata: any },
      any
    >({
      query: (id) => ({ url: `/contract-offers/sub-contract-offer/${id}` }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    createContractOffer: build.mutation<any, ContractOffer>({
      query: (data) => ({ url: "/contract-offers", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    createContractOfferClone: build.mutation<any, string>({
      query: (id) => ({
        url: `/contract-offers/clone/${id}`,
        method: "POST",
      }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    createSubContract: build.mutation<any, string>({
      query: (id) => ({
        url: `/contract-offers/sub-contract/${id}`,
        method: "POST",
      }),
      invalidatesTags: [CACHE_TYPE],
    }),
    createSubContractOffer: build.mutation<any, string>({
      query: (id) => ({
        url: `/contract-offers/sub-contract-offer/${id}`,
        method: "POST",
      }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    patchContractOffer: build.mutation<
      ContractOffer,
      Partial<ContractOffer> & { id: ContractOffer["_id"] }
    >({
      query: ({ id, ...data }) => ({
        url: `/contract-offers/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deleteContractOffer: build.mutation<any, string>({
      query: (id) => ({ url: `/contract-offers/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
    sendContractOffer: build.mutation<any, { id: string; data: FormData }>({
      query: ({ id, data }) => {
        return {
          url: `contract-offers/send-email/${id}`,
          method: "PATCH",
          data,
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: docMultipleListTag(CACHE_TYPE, "ActivityLogs"),
    }),
    getContractOfferResponse: build.query<RespondGetContractOffer, any>({
      query: ({ id, ...params }) => ({
        url: `/external-time-restricted/contract-offers/respond/${id}`,
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    patchContractOfferResponse: build.mutation<
      ContractOffer,
      Partial<ContractOffer> & { id: ContractOffer["_id"] }
    >({
      query: ({ id, ...data }) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        return {
          url: `/external-time-restricted/contract-offers/respond/${id}?token=${token}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docMultipleListTag(CACHE_TYPE, "ActivityLogs"),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateContractOfferMutation,
  useCreateContractOfferCloneMutation,
  useCreateSubContractMutation,
  useCreateSubContractOfferMutation,
  useGetContractOffersActiveQuery,
  useGetContractOffersArchivedQuery,
  useGetContractOfferQuery,
  useGetContractOfferTemplateDataQuery,
  useGetContractOfferResponseQuery,
  useGetSubcontractOffersQuery,
  useDeleteContractOfferMutation,
  usePatchContractOfferMutation,
  usePatchContractOfferResponseMutation,
  useSendContractOfferMutation,
} = contractOffersApi;

export default contractOffersApi;
