import { backendApi } from "../api";
import { docGetTag, listTag } from "./utils";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";
import startsWithNumber from "src/utils/startsWithNumber";

const CACHE_TAG = "TermsOfPayments";
const termsOfPaymentApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getTermsOfPayments: build.query<TermsOfPayment[], any>({
      query: (params) => ({ url: "/termsofpayments", params }),
      transformResponse: (response) => {
        const res = response.data.sort((a: any, b: any) => {
          if (a.days === b.days) {
            // sort by alphabet and number last
            const startsWithNumberA = startsWithNumber(a.description);
            const startsWithNumberB = startsWithNumber(b.description);

            if (startsWithNumberA !== startsWithNumberB) {
              return Number(startsWithNumberA) - Number(startsWithNumberB);
            }
            return a.description.localeCompare(b.description);
          }
          return a.days - b.days;
        });
        return res;
      },
      providesTags: listTag(CACHE_TAG),
    }),
    getTermsOfPayment: build.query<{ data: TermsOfPayment }, any>({
      query: (id) => ({ url: `/termsofpayments/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
  }),
});

export const { useGetTermsOfPaymentsQuery, useGetTermsOfPaymentQuery } =
  termsOfPaymentApi;

export default termsOfPaymentApi;
