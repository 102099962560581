/**
 *Created by Mikael Lindahl on 2024-06-07
 */

import DocumentHandler from "./Utils/DocumentHandler";
import ExcelHandler from "./Utils/ExcelHandler";
import WordHandler from "./Utils/WordHandler";
import { getNestedObjectData } from "../../../../utils/nestedData";
import {
  MicrosoftTemplateData,
  MicrosoftTemplateTagItem,
  MicrosoftTemplateTagItems,
} from "../../../../accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

class MicrosoftDocTemplateProcessor {
  private readonly content: string;
  private readonly contentType: string;
  private readonly templateData: MicrosoftTemplateData | undefined;
  private readonly template: MicrosoftTemplateTagItems;

  constructor({
    content,
    contentType,
    templateData,
    template,
  }: {
    content: string;
    contentType: string;
    templateData: MicrosoftTemplateData | undefined;
    template: MicrosoftTemplateTagItems;
  }) {
    this.content = content;
    this.contentType = contentType;
    this.templateData = templateData;
    this.template = template;
  }

  private getValue(t: MicrosoftTemplateTagItem, o: any) {
    return String(
      o && typeof t?.property === "string"
        ? getNestedObjectData({ data: o, key: t.property })
        : typeof t?.property === "function"
          ? t.property(o)
          : "missing " + t.description,
    );
  }

  async generateDocument(): Promise<any> {
    const populatedData = this.populateData();

    let doc: DocumentHandler;
    if (
      this.contentType === "application/vnd.ms-excel" ||
      this.contentType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      doc = new ExcelHandler();
    } else {
      doc = new WordHandler();
    }

    const docGenerated = await doc.generateDocument(
      this.content,
      populatedData,
    );

    return docGenerated;

    // return doc.generateDocument(this.content, populatedData);
  }

  populateData(): any {
    if (!this.template) {
      throw "Missing template";
    }
    if (!this.templateData) {
      throw "Missing template data";
    }

    const filledData: any = {};

    const templateTags = this.template;
    for (const templateTag of templateTags) {
      // const strippedTagKey = tagKey.substring(1).slice(0, -1);

      if (!templateTag.property) continue;

      switch (templateTag.source) {
        case "callback":
          filledData[templateTag.key] = templateTag.property();
          break;
        case "template_data":
          filledData[templateTag.key] = this.getValue(
            templateTag,
            this.templateData,
          );
          break;
      }
    }

    return {
      ...filledData,
    };
  }
}

export default MicrosoftDocTemplateProcessor;
