import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getSelectOptions from "../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  groups?: string[];
  types?: string[];
};

export interface MicrosoftDriveFileProps {
  selectedFileName?: string;
  selectedGroup?: string;
  selectedType?: string;
}

export const getStructure: UseFormContainerGetStructure<
  MicrosoftDriveFileProps,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<MicrosoftDriveFileProps> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "selectedFileName",
        label: "File Name",
        setFormData,
      },
      {
        itemType: "info",
        type: "autocomplete",
        required: true,
        dataName: "selectedGroup",
        label: "Group",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: extraProps?.groups,
        }),
        setFormData,
        noCapitalize: true,
      },
      {
        itemType: "info",
        type: "autocomplete",
        required: true,
        dataName: "selectedType",
        label: "Type",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: extraProps?.types,
        }),
        setFormData,
        noCapitalize: true,
      },
    ],
  };

  return structure;
};
