import useFormContainer from "src/hooks/useFormContainer";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";

import { getStructure, toSubmitData } from "./ImportModalStructure";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import BuildItems from "src/components/Builders/Container/BuildItems";
import Section from "src/components/Basic/Simple/Sections/Section";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import { useUploadBudgetMutation } from "src/redux/services/BudgetService";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

interface ImportModalProps {
  open: boolean;
  onClose: () => void;
  contract: GetSingleContractWithPermissions;
}

const ImportModal = ({ open, contract, onClose }: ImportModalProps) => {
  const [uploadBudget] = useUploadBudgetMutation();

  const initialFormData = {
    contractId: String(contract?._id || ""),
    projectExternalId: contract?.projectExternalId || "",
    costCenter: "",
    date: contract?.startDate || new Date(),
    files: [],
  };

  const { formData, helperText, structure, setFormData, isFormValid } =
    useFormContainer({
      getStructure,
      initialFormData,
    });

  const commonPropsBuildItem = {
    id: "import-modal-form",
    data: formData,
    helperText,
  };

  const handleModalClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  const submitData = toSubmitData({
    data: formData,
    initialData: initialFormData,
  });

  const { isSubmitting: isImporting, onFormSubmit: handleImport } =
    useOnFormSubmit({
      submitProps: {
        apiMutations: { create: uploadBudget },
        data: { create: { formData: submitData } },
        name: "budgets",
      },
      onSuccess: () => {
        handleModalClose();
      },
    });

  return (
    <ModalWrapper
      label="Import from XLSX"
      openModal={open}
      handleCloseModal={handleModalClose}
    >
      <Section>
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "attachment",
          ),
          ...commonPropsBuildItem,
        })}
      </Section>
      <Section label="Default value">
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "default_value",
          ),
          ...commonPropsBuildItem,
        })}
      </Section>
      <SectionWithButtonContainer>
        <ButtonGroup>
          <ButtonCancel disabled={isImporting} onClick={handleModalClose} />
          <ButtonPrimary
            disabled={!isFormValid || isImporting}
            onClick={() => handleImport()}
          >
            {isImporting ? "Importing..." : "Import"}
          </ButtonPrimary>
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};

export default ImportModal;
