import {
  useGetContractOfferResponseQuery,
  usePatchContractOfferResponseMutation,
} from "src/redux/services/ContractOfferService";
import { Box, LinearProgress, Typography } from "@mui/material";
import { DrawerHeader } from "src/components/Basic/Mixed/Sidebar/Sidebar";
import { getStructure } from "./SendContractOfferResponseStructure";
import { RespondContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GuestHeader from "src/components/Basic/Mixed/Header/GuestHeader";
import isDisableSubmit from "src/utils/isDisableSubmit";
import MessageBase from "src/components/Basic/Simple/Messages/MessageBase";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useOnSubmit from "src/hooks/useOnSubmit";

const SendContractOfferResponseModal = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const {
    data: offerResponse,
    isLoading,
    isError,
  } = useGetContractOfferResponseQuery(
    { id, token },
    { skip: id === undefined },
  );

  const [respondResult, setRespondResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(
    offerResponse?.customerStatus,
  );

  const offerData: any = {
    companyName: offerResponse?.companyId?.displayName,
    createdAt: toDay(offerResponse?.createdAt),
    emailSender: offerResponse?.ownerIds[0].email,
    message: offerResponse?.activityLogIdLastSent?.comment,
    name: offerResponse?.name,
    projectExternalId: offerResponse?.projectExternalId || "-",
    senderName: `${offerResponse?.ownerIds[0].firstName} ${offerResponse?.ownerIds[0].lastName}`,
  };

  const [patchContractOfferResponse] = usePatchContractOfferResponseMutation();

  const initialFormData = {
    customerStatus:
      offerResponse?.customerStatus && offerResponse?.customerStatus !== "draft"
        ? offerResponse?.customerStatus
        : ("approved" as RespondContractOffer["customerStatus"]),
    message: "",
  };
  const { formData, helperText, structure, isFormValid } =
    useFormContainer<RespondContractOffer>({
      initialFormData,
      getStructure,
    });

  const onSubmit = useOnSubmit({
    apiMutations: { update: patchContractOfferResponse },
    name: "Contract Offer Response",
  });
  const submitDisabled = isDisableSubmit({
    data: formData,
    initialData: initialFormData,
    isValid: isFormValid,
  });

  const onSubmitCustom = async ({
    customerStatus,
  }: {
    customerStatus: RespondContractOffer["customerStatus"];
  }) => {
    setIsSubmitting(customerStatus);
    const res = await onSubmit({
      data: { update: { customerStatus, message: formData?.message } },
      dataId: id,
    });
    setRespondResult(res.status);
  };

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <>
        <GuestHeader />
        <DrawerHeader />
        <Box sx={{ padding: "100px", margin: "auto", maxWidth: "800px" }}>
          <>
            <Typography
              variant="h1"
              sx={{
                fontWeight: "700",
                marginBottom: "var(--space-32px)",
                textAlign: "center",
              }}
            >
              {t("Offer Confirmation")}
            </Typography>
            <MessageBase
              title={t("This offer is invalid")}
              message={t(
                "For further assistance, please contact our Customer support",
              )}
              type="alert"
              textAlign="center"
            />
          </>
        </Box>
      </>
    );
  }

  return (
    <>
      <GuestHeader />
      <DrawerHeader />
      <Box sx={{ padding: "100px", margin: "auto", maxWidth: "800px" }}>
        <>
          <Typography
            variant="h1"
            sx={{ fontWeight: "700", textAlign: "center" }}
          >
            {t("Offer Confirmation")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "var(--space-16px)",
              marginTop: "var(--space-24px)",
            }}
          >
            {/* Left side */}
            <Box>
              <Typography sx={{ fontWeight: "600" }}>
                {offerData?.name}
              </Typography>
              <Typography>{`${t("Offer")}: #${
                offerData?.projectExternalId
              }`}</Typography>
            </Box>
            {/* Right side */}
            <Box>
              <Typography sx={{ fontWeight: "600" }}>
                {offerData?.companyName}
              </Typography>
              <Typography>{`${t("Sender")}: ${
                offerData?.senderName
              }`}</Typography>
              <Typography>{`${t("Email")}: ${
                offerData?.emailSender
              }`}</Typography>
              <Typography>{`${t("Date")}: ${offerData?.createdAt}`}</Typography>
            </Box>
          </Box>
        </>
        <MessageBase
          title={t("Sender's message")}
          type="message"
          message={offerResponse?.activityLogIdLastSent?.comment || "-"}
        />
        {respondResult === "success" ? (
          <Box sx={{ marginTop: "var(--space-32px)", textAlign: "center" }}>
            <CheckCircleIcon style={{ color: "green", fontSize: "40px" }} />
            <Typography
              variant="h3"
              sx={{ fontWeight: "700", textAlign: "center" }}
            >
              {t(isSubmitting === "approved" ? "Accepted" : "Declined")}
            </Typography>
            <MessageBase
              title={t(
                "Thank you for confirming. Your response has been recorded.",
              )}
              subMessage={t(
                "For further assistance, please contact our Customer support",
              )}
              textAlign="center"
            />
          </Box>
        ) : (
          <>
            {offerResponse?.customerStatus === "approved" && (
              <MessageBase
                type="success"
                title={t("You have accepted this offer")}
                message={t(
                  "If you wish to change you response, please continue with the form below",
                )}
              />
            )}
            {offerResponse?.customerStatus === "declined" && (
              <MessageBase
                type="warning"
                title={t("You have declined this offer")}
                message={t(
                  "If you wish to change you response, please continue with the form below",
                )}
              />
            )}
            <SectionContainer>
              {BuildItems({
                items: structure.items,
                ...commonPropsBuildItem,
              })}
            </SectionContainer>
            <SectionWithButtonContainer buttonCenter>
              <ButtonGroup>
                {offerResponse?.customerStatus !== "declined" &&
                  offerResponse?.customerStatus !== "approved" && (
                    <ButtonSecondary
                      isLoading={isSubmitting === "declined"}
                      disabled={submitDisabled || isSubmitting === "approved"}
                      onClick={() =>
                        onSubmitCustom({ customerStatus: "declined" })
                      }
                    >
                      {t("Decline")}
                    </ButtonSecondary>
                  )}
                {offerResponse?.customerStatus === "approved" ? (
                  <ButtonPrimary
                    isLoading={isSubmitting === "declined"}
                    disabled={submitDisabled || isSubmitting === "approved"}
                    onClick={() =>
                      onSubmitCustom({ customerStatus: "declined" })
                    }
                  >
                    {t("Decline")}
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary
                    isLoading={isSubmitting === "approved"}
                    disabled={submitDisabled || isSubmitting === "declined"}
                    onClick={() =>
                      onSubmitCustom({ customerStatus: "approved" })
                    }
                  >
                    {t("Accept")}
                  </ButtonPrimary>
                )}
              </ButtonGroup>
            </SectionWithButtonContainer>
          </>
        )}
      </Box>
    </>
  );
};
export default SendContractOfferResponseModal;
