/**
 *Created by Mikael Lindahl on 2023-04-03
 */

import getChangedData from "./getChangedData";

const toUpdateData = <DataType extends { _id?: any } = any>({
  data,
  initData,
}: {
  data: DataType;
  initData?: DataType;
}) => {
  let partialData: Partial<DataType> | undefined = {};

  if (initData && initData._id) {
    partialData = getChangedData({ data, initData });
  } else {
    partialData = data;
  }

  return partialData;
};

export default toUpdateData;
