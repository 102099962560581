import { Box, Toolbar } from "@mui/material";
import AppBar from "./AppBar";
import useStyles from "./HeaderStyles";

const GuestHeader = () => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.header}>
          <img src="/Logo.png" alt="accurasee-logo" height={24} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default GuestHeader;
