import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../utils/getSelectOptions";
import getCustomerName from "../../../utils/getCustomerName";

export type GetStructureExtraProps = {
  customers?: Customer[];
  customerId?: Customer["_id"] | string;
};
export const getStructure: UseFormContainerGetStructure<
  Partial<ContactPerson>,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<Partial<ContactPerson>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "firstName",
        label: "First name",
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        dataName: "lastName",
        label: "Last name",
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        textInputType: "tel",
        dataName: "phone",
        label: "Phone",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        textInputType: "email",
        dataName: "email",
        label: "Email",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        type: "selector",
        dataName: "customerId",
        label: "Customer",
        options: getSelectOptions({
          data: extraProps?.customers,
          label: (c) => getCustomerName(c),
          value: (c) => String(c._id),
        }),
        setFormData,
        disabled: !!extraProps?.customerId,
      },
    ],
  };
  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data: Partial<ContactPerson> | undefined;
  initData: Partial<ContactPerson> | undefined;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<ContactPerson>;
  if (newData._id) {
    partialData = toUpdateData<Partial<ContactPerson>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  return partialData;
};
