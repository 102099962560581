import _c from "src/constants/Constants";
import toCurrency from "src/utils/toCurrency";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Cost } from "src/accurasee-backend-types/app/cost/cost.types";
import { ReportTemplateYFilter } from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import { Types } from "mongoose";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { checkDimension } from "src/screens/App/Setup/NumberSeries/CompanyProjectNumberSeries/lib/Utils";
import { checkOverlappedNumber } from "src/screens/App/Setup/NumberSeries/CompanyProjectNumberSeries/lib/checkForOverlap";

export interface GetStructureExtraProps {
  articles: Article[];
  currencyCode: string;
  fileDownloading: any;
  idSummaries?: Types.ObjectId;
  reportYFilters: ReportTemplateYFilter[];
  setFileDownloading: (data: String[]) => void;
}

const getStructure: UserFormTableGetStructure<
  Partial<Cost>,
  GetStructureExtraProps
> = ({ extraProps, t }) => {
  const idSummaries = extraProps?.idSummaries;

  return {
    items: [
      {
        type: "text_with_optional_badge",
        dataName: "name",
        headerLabel: "Description",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          const isProcessing = data[rowIndex].incomplete;
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
        sx: {
          minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ITEM_DESCRIPTION,
        },
      },
      {
        itemType: "common",
        type: "text",
        dataName: "accountName",
        headerLabel: "Number series",
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        getValue: ({ data, rowIndex }) => {
          const accountNumber = data[rowIndex]?.accountNumber || "";
          const reportYFilters = extraProps?.reportYFilters || [];
          // No series to check
          if (extraProps?.reportYFilters?.length === 0) {
            return "-";
          }
          let label = "";
          reportYFilters.forEach((filter) => {
            const existingRanges = filter?.accountNumberSeries.map(
              ({ firstNumber, lastNumber }) => ({
                firstNumber: Number(firstNumber),
                lastNumber: Number(lastNumber),
              }),
            );
            const seriesRes = checkOverlappedNumber({
              existingRanges,
              num: accountNumber as number,
            });

            const dimensionRes = checkDimension(
              filter?.dimensions.map((d) => d.dimensionItemId),
              data[rowIndex],
            );
            // Add dimension check
            if (seriesRes?.isOverlapped && dimensionRes?.match) {
              label += filter?.label + ", ";
            }
          });

          // Slice to remvoe last comma
          return label.length > 0 ? label.slice(0, -2) : "-";
        },
      },
      {
        type: "text",
        dataName: "articleId",
        headerLabel: "Article",
        getValue: ({ data, rowIndex }) => {
          const articleId = data[rowIndex]?.articleId || "";
          const article = extraProps?.articles.find((a) => a._id === articleId);
          return article?.name;
        },
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        showColumnWhen: ({ data }) => data.some((row) => row?.articleId),
      },
      {
        type: "text",
        dataName: "costType",
        headerLabel: "Type",
        filter: true,
        getValue: ({ data, rowIndex }) => {
          return t(data[rowIndex]?.costType || "");
        },
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
      },
      {
        type: "text",
        dataName: "accountNumber",
        headerLabel: "Account number",
        filter: true,
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.accountNumber;
          return value || "-";
        },
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
      },
      {
        type: "text",
        dataName: "accountName",
        headerLabel: "Account name",
        filter: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
      },
      {
        type: "date",
        dataName: "transactionDate",
        headerLabel: "Transaction date",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
      },
      {
        type: "text",
        dataName: "quantity",
        headerLabel: "Quantity",
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.quantity;
          return value || "-";
        },
      },
      {
        type: "text",
        dataName: "costPerUnit",
        headerLabel: "Cost per unit",
        filter: false,
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.costPerUnit;
          return value
            ? toCurrency({
                value,
                currencyCode: extraProps?.currencyCode,
              })
            : "-";
        },
      },
      {
        type: "text",
        dataName: "value",
        headerLabel: "Total cost",
        filter: false,
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.value || 0;
          return value
            ? toCurrency({
                value,
                currencyCode: extraProps?.currencyCode,
              })
            : "-";
        },
      },
    ],
  };
};

// Currently not used as we wait for specification on
// how to handle forwardable and reassignable costs
export const getForwardableCosts = (costs?: Cost[]) => {
  return [];
  // (
  //   costs
  //     ? costs.flatMap((cost) =>
  //         cost.costs.filter(
  //           (cost) =>
  //             !cost?.forwardedToLinkedInvoiceDataId &&
  //             !cost?.subcontractId &&
  //             !!cost.articleId,
  //         ),
  //       )
  //     : []
  // ) as CostWithPermissions[];
};

export const getReassignableCosts = (costs?: Cost[]) => {
  return [];
  // (
  //   costs
  //     ? costs.flatMap((cost) =>
  //         cost.costs.filter(
  //           (cost) =>
  //             !cost?.subcontractId &&
  //             cost.costParent === "voucher" &&
  //             cost.costType === "supplier-invoice",
  //         ),
  //       )
  //     : []
  // ) as CostWithPermissions[];
};

export default getStructure;
