/**
 *Created by Mikael Lindahl on 2023-03-27
 */

import { StructureItemContainer } from "../components/Builders/Container/CommonBuilderContainerTypes";
import clone from "./clone";
import {
  ContractWithPermissions,
  DimensionItemContract,
} from "../accurasee-backend-types/app/contracts/contract.types";
import { Types } from "mongoose";
import { setNestedObjectData } from "./nestedData";
// import { ContractExtended } from "../Contract/[contractTypeId]/ContractCreateGetStructure";
import { DimensionExtra } from "../redux/services/DimensionService";
import { ContractDimension } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { InvoicePlanWithPermissions } from "../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import getSelectOptions from "./getSelectOptions";

export type TmpDimensionItems =
  | {
      [key: string]: DimensionItemContract;
    }
  | undefined;

type DimensionExtension = {
  tmpDimensionItems: TmpDimensionItems;
};

export type ContractExtended = ContractWithPermissions & DimensionExtension;
export type ContractCreate = Partial<ContractWithPermissions> &
  Partial<DimensionExtension>;
export type InvoicePlanExtended = InvoicePlanWithPermissions &
  DimensionExtension;

type DataExtended = ContractExtended | InvoicePlanExtended;

type GetDimensionItems<DimensionDataType = any, ItemsTypes = any> = {
  contractDimensions?: ContractDimension[];
  dimensions: DimensionExtra[] | undefined;
  itemType: ItemsTypes;
  setFormData: (data: DimensionDataType) => void;
};

const getDimensionItems = <DimensionDataType = DataExtended, ItemsTypes = any>({
  contractDimensions,
  dimensions,
  itemType,
  setFormData,
}: GetDimensionItems<DimensionDataType, ItemsTypes>) => {
  const dimensionItems =
    dimensions?.map((d) => {
      const item: StructureItemContainer<
        DimensionDataType & Partial<DimensionExtension>,
        ItemsTypes
      > = {
        itemType,
        dataName: `tmpDimensionItems.${String(d._id)}.dimensionItemId`,
        gridProps: { md: 6 },
        type: "autocomplete",
        label: d.name,
        showActiveItems: true,
        ...(contractDimensions !== undefined
          ? {
              showWhen: (props) => {
                if (contractDimensions) {
                  const cd = contractDimensions?.find(
                    (c) => c.dimensionId === d._id,
                  );

                  return !!cd && cd.active;
                } else {
                  return false;
                }
              },
            }
          : {}),
        getValue: (props) => {
          const item = props?.data?.tmpDimensionItems?.[String(d._id)];
          return item?.dimensionItemId;
        },
        options: getSelectOptions({
          data: d.items,
          label: (item) => `${item?.code} - ${item.name}`,
          value: (item) => String(item._id),
        }),
        setFormDataCustom: (props) => {
          if (props.data) {
            const newData = clone(props.data);

            const item = d.items.find((item) => item._id === props.value);
            if (item) {
              const value: DimensionItemContract = {
                code: item.code,
                name: item.name,
                active: item.active,
                dimensionId: item.dimensionId,
                dimensionItemId: item._id as unknown as Types.ObjectId,
              };
              setNestedObjectData({
                data: newData,
                key: `tmpDimensionItems.${String(d._id)}`,
                value,
              });

              setFormData(newData);
            }
          }
        },
      };
      return item;
    }) || [];

  return dimensionItems;
};

export default getDimensionItems;
