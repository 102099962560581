import { useNavigate } from "react-router-dom";
import ArticlesTable from "./ArticlesTable";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import usePathPermission from "src/hooks/usePathPermission";

const Articles = () => {
  const pathPermissions = usePathPermission(
    "/app/product-and-plans/articles/create",
  );
  const navigate = useNavigate();

  return (
    <Page
      label={"Articles"}
      breadcrumbs={[{ label: "Products & Plans" }, { label: "Articles" }]}
      boxRight={
        <ButtonAddBoxIcon
          id="direct-article-create-page"
          label="Create article"
          permissions={pathPermissions}
          onSubmit={() => navigate("/app/product-and-plans/articles/create")}
        />
      }
      isFullWidth={false}
    >
      <SectionContainer>
        <ArticlesTable />
      </SectionContainer>
    </Page>
  );
};

export default Articles;
