import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { useGetContractsQuery } from "src/redux/services/ContractService";

import Constants from "src/constants/Constants";
import sort from "src/utils/sort";

const NavigationButtonsContract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contractId } = useParams<{ contractId: string }>();
  const { data: queryResult, isFetching } = useGetContractsQuery(undefined);
  const contracts = queryResult?.data || [];
  const contractIds =
    sort([...contracts], (v) => v.projectExternalId)
      // desc sort as Contract List
      ?.map((contract) => String(contract?._id)) || [];

  const splittedUrl = location.pathname.split(`${contractId}`);
  const index = contractIds?.findIndex((i) => i === contractId);
  // This check is incase contractId is invalid and causes index === -1
  const currentIndex = index < 0 ? 1 : index;

  // Item that is set to redirect
  const isRedirect = !!Constants.REDIRECT_URL?.find((item: string) =>
    splittedUrl[1].includes(item),
  );
  // Item that has a following id in url
  const lockedItem = Constants.LOCKED_URL?.find((item: string) =>
    splittedUrl[1].includes(item),
  );

  // Redirect to Contract Info page if needed
  const suffixUrl =
    isRedirect &&
    contractId &&
    !!contracts[contractId]?.contractFeatures?.serviceOrderContract
      ? ""
      : lockedItem ?? splittedUrl[1];

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        id="navigation-first-page-button"
        onClick={() => {
          navigate(`${splittedUrl[0]}${contractIds[0]}${suffixUrl}`);
        }}
        onMouseDown={(event) => event.preventDefault()}
        disabled={isFetching || currentIndex === 0}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        id="navigation-previous-page-button"
        onClick={() => {
          navigate(
            `${splittedUrl[0]}${contractIds[currentIndex - 1]}${suffixUrl}`,
          );
        }}
        onMouseDown={(event) => event.preventDefault()}
        disabled={isFetching || currentIndex === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        id="navigation-next-page-button"
        onClick={() => {
          navigate(
            `${splittedUrl[0]}${contractIds[currentIndex + 1]}${suffixUrl}`,
          );
        }}
        onMouseDown={(event) => event.preventDefault()}
        disabled={isFetching || currentIndex === contractIds.length - 1}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        id="navigation-last-page-button"
        onClick={() => {
          navigate(
            `${splittedUrl[0]}${
              contractIds[contractIds.length - 1]
            }${suffixUrl}`,
          );
        }}
        onMouseDown={(event) => event.preventDefault()}
        disabled={isFetching || currentIndex === contractIds.length - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default NavigationButtonsContract;
