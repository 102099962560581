import {
  getStructure,
  GetStructureExtraProps,
} from "./ProfileDetailsGetStructure";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { UPDATE_USER } from "src/redux/actions/types";
import { useDispatch, useSelector } from "react-redux";
import { useGetLanguagesQuery } from "src/redux/services/LanguageService";
import { useUpdateUserMutation } from "src/redux/services/UserService";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import Constants from "src/constants/Constants";
import getChangedData from "src/utils/getChangedData";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

const ProfileDetails = () => {
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();

  const { data: languagesQuery } = useGetLanguagesQuery(undefined);
  const languages = languagesQuery?.data?.filter(
    (language) => language.isActive,
  );

  const { user } = useSelector(
    (state: { user: { user: ReturnUser } }) => state.user,
  );

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    structure,
    setHasTriedToSubmit,
  } = useFormContainer<ReturnUser, GetStructureExtraProps>({
    extraProps: {
      languages: languages || Constants.DEFAULT_LANGUAGES,
    },
    getStructure,
    initialFormData: user,
  });

  const submitData = getChangedData<Partial<ReturnUser>>({
    data: formData || {},
    initData: user,
  });
  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit<
    Partial<ReturnUser>
  >({
    submitProps: {
      apiMutations: { update: updateUser },
      data: { update: submitData },
      name: "Profile",
    },
    onSuccess: ({ responseData }) => {
      setFormData({ ...user });
      setHasTriedToSubmit(false);
      // Keep this to dispathc update of user since the store value
      // might be used for legacy code concerning login flow
      dispatch({ type: UPDATE_USER, payload: responseData?.update });
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        label={"Profile"}
        subLabel={"The information can be edited"}
        rightBoxArea={
          <ButtonGroup>
            <ButtonClear
              id={"update-profile-detail-cancel"}
              onClick={() => setFormData({ ...user })}
            />
            <ButtonUpdate
              id={"update-profile-detail"}
              initialSubmitData={{}}
              isValid={isFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={submitData}
            />
          </ButtonGroup>
        }
      >
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </SectionTight>
    </form>
  );
};

export default ProfileDetails;
