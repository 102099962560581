/**
 *Created by Mikael Lindahl on 2022-12-13
 */

import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import { Typography as MuiTypography } from "@mui/material";
import useTranslation from "src/hooks/useTranslationWrapper";

type BoxHeadingTProps = {
  alignTextCenter?: boolean;
  label?: string;
  rightBoxArea?: JSX.Element | JSX.Element[];
  subLabel?: string;
  sx?: SxProps;
};

const BoxHeadingTight = (props: BoxHeadingTProps) => {
  const [t] = useTranslation();

  return props.label || props.subLabel || props.rightBoxArea ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: props.alignTextCenter ? "center" : "space-between",
        alignItems: "center",
        padding: "var(--space-16px) var(--space-24px)",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          paddingRight: "1rem",
          textAlign: props.alignTextCenter ? "center" : "left",
        }}
      >
        {props.label && (
          <MuiTypography
            variant="body1"
            textTransform="uppercase"
            fontWeight="bold !important"
            fontSize="17px !important"
            marginBottom={"auto"}
          >
            {t(props.label)}
          </MuiTypography>
        )}
        {props.subLabel && (
          <MuiTypography
            variant="body1"
            fontWeight="normal"
            fontSize="15px !important"
            color="var(--gray-4)"
          >
            {t(props.subLabel)}
          </MuiTypography>
        )}
      </Box>
      {props.rightBoxArea && <Box>{props.rightBoxArea}</Box>}
    </Box>
  ) : (
    <></>
  );
};

export default BoxHeadingTight;
