/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import { Box, Tooltip } from "@mui/material";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { formatIfEstimate } from "src/utils/formatIfEstimate";
import get from "../Utils/get";
import getFinalFormatedValue from "../Utils/getFinalFormatedValue";
import getIconByType from "src/components/Builders/Table/Utils/getIconByType";
import getIsEstimate from "../Utils/getIsEstimate";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import getTypeByStatus from "src/utils/getTypeByStatus";
import getValue from "../Utils/getValue";
import StatusCell from "src/components/Complex/Tables/StatusCell";
import toCurrency from "src/utils/toCurrency";

type TextColumnItemTProps = CommonBuilderTableTypes & {
  format?: (value: any) => string | number;
};

const TextColumnItem = (props: TextColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  let value = getValue(commonCallbackProps);

  const isCurrency =
    (props.item.currencyCode || props.data[props.rowIndex].currencyCode) &&
    props.isCurrency;
  if (isCurrency) {
    value = toCurrency({
      value,
      currencyCode:
        get("currencyCode", commonCallbackProps)! ||
        props.data[props.rowIndex].currencyCode,
      toInteger: true, // not use decimal places for displaying data
    });
  }

  value = formatIfEstimate({
    value,
    isEstimate: getIsEstimate(commonCallbackProps),
  });

  // Format value according to type format
  value = props.format
    ? props.format(value)
    : // Able to display zero
      value || typeof value === "number"
      ? value
      : "-";
  // Ad hoc change of value if wanted
  value = getFinalFormatedValue({ ...commonCallbackProps, value });

  // prefixIcon
  const { icon: prefixIcon } = getIconByType(getTypeByStatus(value));

  // Badge and styling
  const textWithOptionalBadgeSetup = get(
    "textWithOptionalBadgeSetup",
    commonCallbackProps,
  );
  const { badgeText, iconType, showBadge } = textWithOptionalBadgeSetup || {};
  const { icon, style: stylingType } = getIconByType(iconType);

  return (
    <Tooltip
      key={`tooltip-${props.rowIndex}-${String(props.item.dataName)}`}
      title={
        // only show Tooltip if the text is long and cut
        value?.includes("...")
          ? props.data[props.rowIndex][props.item.dataName]
          : undefined
      }
      arrow
    >
      <Box
        sx={{
          ...get("sx", commonCallbackProps),
          columnGap: "var(--space-4px)",
          display: "flex", // Uses flexbox layout
          justifyContent: props.justifyContent
            ? props.justifyContent
            : "flex-start", // Spreads out the text across the full width of the box
          overflow: "hidden",
          paddingY: "0.5rem",
          textOverflow: "ellipsis", // Adds ellipsis for overflowing text
          whiteSpace: "nowrap", // Keeps the text on a single line
          ...(props.isTextWithOptionalBadge
            ? { alignItems: "center", columnGap: "var(--space-8px)" }
            : {}),
          ...(props.isTextWithPrefixBullet
            ? { color: getStylingByStatusType(value.toLowerCase()).color }
            : {}),
        }}
      >
        {props.isTextWithPrefixBullet && (
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: getStylingByStatusType(value.toLowerCase())
                .backgroundColor,
              margin: "auto var(--space-8px) auto 0",
            }}
          />
        )}
        {props.isTextWithPrefixIcon && prefixIcon}
        {value}
        {props.isTextWithOptionalBadge && showBadge && (
          <StatusCell
            status={badgeText}
            icon={icon}
            justifyContent={props.justifyContent}
            stylingType={stylingType}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default TextColumnItem;
