import {
  INVOICE_PLAN_TYPE,
  InvoicePlan,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { InvoicePlanRowExtended } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import addRetainedFundsDataToInvoiceRows from "../../../../../../../../accurasee-backend-types/shared/utils/addRetainedFundsDataToInvoiceRows";
import calculateDependable from "src/accurasee-backend-types/shared/utils/calculateDependable";
import calculateVat from "src/utils/calculateVat";

type AddRowTProps = {
  contract: GetSingleContractWithPermissions | undefined;
  customer: Customer | undefined;
  formData: InvoicePlanRowExtended[];
  helperText: any[];
  invoicePlan?: InvoicePlan | undefined;
  setHelperText: (data: any) => void;
  setFormData: (data: InvoicePlanRowExtended[]) => void;
};

const addRow = (props: AddRowTProps) => {
  const defaultArticle = props.contract?.contractArticles?.reduce(
    (article: ContractArticle | undefined, c) => {
      return c.defaultArticle ? c : article;
    },
    undefined,
  );

  const isMilestoneRetainedFunds =
    props.invoicePlan?.type === "milestone_retained_funds";
  const isMilestone =
    props.invoicePlan?.type === "milestone" || isMilestoneRetainedFunds;
  const isSingleInvoice = props.invoicePlan?.type === "singleinvoice";

  let row: InvoicePlanRowExtended = {
    articleId: defaultArticle?.articleId,
    articleNumber: defaultArticle?.number,
    discount: 0,
    estimatedInvoiceDate: new Date(), //moment().format(_c.DATE_FORMAT),
    houseWork: false,
    houseWorkType: "None",
    houseWorkHours: 0,
    itemDescription: isMilestone ? "" : defaultArticle?.name || "",
    percentVAT: calculateVat(defaultArticle?.vat!, props.customer) || 0,
    isIndex:
      defaultArticle !== undefined
        ? !!defaultArticle.isIndex
        : !!props?.invoicePlan?.indexOption?.index,
    isPeriodic: false,
    isPricePerUnitEstimate:
      isMilestone || isSingleInvoice
        ? false
        : defaultArticle?.isPricePerUnitEstimate || false,
    isQuantityEstimate:
      isMilestone || isSingleInvoice
        ? false
        : defaultArticle?.isQuantityEstimate || false,
    pricePerUnit: defaultArticle?.price || 0,
    rowNumber: props.formData.length + 1,
    quantity: isMilestone ? 1 : defaultArticle?.quantity || 0,
    status: isMilestone ? "upcoming" : undefined,
    textRow: false,
    total: 0,
    totalExcludingVAT: 0,
    unitId: defaultArticle?.unitId,
    unitExternalId: defaultArticle?.unitExternalId,
  };
  if (defaultArticle) {
    const dep = calculateDependable({
      data: row,
    });

    row = { ...row, ...dep };
  }

  props.setHelperText([...props.helperText, {}]);
  let rows: InvoicePlanRowExtended[];
  if (props.invoicePlan?.type === INVOICE_PLAN_TYPE.milestone_retained_funds) {
    rows = [...props.formData.slice(0, -1), row, ...props.formData.slice(-1)];
  } else {
    rows = [...props.formData, row];
  }

  addRetainedFundsDataToInvoiceRows({ invoiceRows: rows });
  props.setFormData(rows);
};

export default addRow;
