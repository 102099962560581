/**
 *Created by Mikael Lindahl on 2023-04-26
 */

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormTable from "src/hooks/useFormTable";
import useLanguagesStructure from "./hooks/useLanguagesStructure";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useUpdateLanguagesMutation } from "src/redux/services/LanguageService";

type LanguagesTProps = {
  languages: Language[];
};

const Languages = (props: LanguagesTProps) => {
  const { languages } = props;

  const [updateLanguages] = useUpdateLanguagesMutation();
  const { getStructure, toSubmitData } = useLanguagesStructure();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormTable({
    initialFormData: languages,
    getStructure,
  });

  const { onFormSubmit, refForm, isSubmitting } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateLanguages,
      },
      data: { update: toSubmitData(formData, languages) }, // Remove unchanged, deleted key-value(s) and added blank row
      name: "Languages",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: formData,
      helperText,
      item,
    }),
  );

  return (
    <>
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
      >
        <SectionContainer>
          <MuiDataTableWrapper
            data={formData}
            columns={columns}
            options={options}
            tableType="main-with-filter"
            noBackground={true}
          />
        </SectionContainer>
      </form>
      <SectionWithButtonContainer>
        <ButtonGroup>
          <ButtonClear
            onClick={() => {
              if (languages) {
                setFormData(languages);
                setHasTriedToSubmit(false);
              }
            }}
          />
          <ButtonUpdate
            initialSubmitData={languages}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={formData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </>
  );
};

export default Languages;
