import { backendApi } from "../api";

import { docListTags, docPatchTag } from "./utils";
import {
  Cost,
  CostWithPermissions,
} from "src/accurasee-backend-types/app/cost/cost.types";

const CACHE_COST_TYPES = "Costs";
const costsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCosts: build.query<{ data: CostWithPermissions[]; metadata: any }, any>({
      query: (params) => ({ url: "/costs", params }),
      providesTags: docListTags(CACHE_COST_TYPES),
    }),
    getCostsTable: build.query<{ data: Cost[]; metadata: any }, any>({
      query: (params) => ({ url: "/costs/table", params }),
      providesTags: docListTags(CACHE_COST_TYPES),
    }),
    getCostsSubtable: build.query<
      { data: CostWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/costs/subtable", params }),
      providesTags: docListTags(CACHE_COST_TYPES),
    }),
    patchCosts: build.mutation<any, Partial<Cost>[]>({
      query: (data) => {
        return { url: "/costs/bulk", method: "PATCH", data };
      },
      invalidatesTags: docPatchTag(CACHE_COST_TYPES),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCostsQuery,
  useGetCostsTableQuery,
  useGetCostsSubtableQuery,
  usePatchCostsMutation,
} = costsApi;

export default costsApi;
