/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import { BudgetAdjustmentRow } from "src/accurasee-backend-types/app/budget/budget.types";
import _c from "src/constants/Constants";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import getSelectOptions from "../../../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {};

export const getStructure: UserFormTableGetStructure<
  BudgetAdjustmentRow,
  GetStructureExtraProps
> = ({ setFormData, t }) => {
  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
        getValue: ({ rowIndex }) => rowIndex + 1,
      },
      {
        type: "text_input",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ADJUSTMENT },
        dataName: "eventDescription",
        headerLabel: "Description",
        setFormData,
        required: true,
      },
      {
        type: "date",
        dataName: "adjustmentDate",
        headerLabel: "Adjustment date",
        setFormData,
      },
      {
        type: "autocomplete",
        dataName: "adjustmentType",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ADJUSTMENT },
        headerLabel: "Adjustment type",
        options: getSelectOptions({
          data: [
            { value: "labour", label: t("Labour") },
            { value: "material", label: t("Material") },
            { value: "income", label: t("Income") },
          ],
        }),
        required: true,
        setFormData,
      },
      {
        type: "number_input_currency",
        currencyCode: "SEK", // TODO: update
        dataName: "value",
        headerLabel: "Amount",
        required: true,
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
        setFormData,
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        onClick: ({ rowIndex, data }) => {
          let newFormData = [...data];
          newFormData.splice(rowIndex, 1);
          setFormData(newFormData);
        },
      },
    ],
  };
};

export const toSubmitData = ({ data }: { data?: BudgetAdjustmentRow[] }) => {
  return data?.map(({ _id, ...rest }) => rest);
};
