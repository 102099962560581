import Constants from "src/constants/Constants";
import MuiDrawer from "@mui/material/Drawer";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import getHiddenComponentStatus from "src/utils/getHiddenComponentStatus";
import structure from "./SidebarStructure";
import useSidebarHiddenComponents from "src/context/hooks/useSidebarHiddenComponents";
import { AccessPermissionContext } from "src/context/AccessPermissionProvider";
import { List } from "@mui/material";
import { getPermission } from "src/utils/getPermission";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import { useState, useEffect, useContext } from "react";

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "isWarnings",
})<{
  isWarnings?: boolean;
}>(({ theme, isWarnings }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below App bar
  ...theme.mixins.toolbar,
  // animation when showing/hiding a warning
  transition: theme.transitions.create(["min-height"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isWarnings && {
    minHeight: "118px !important",
    transition: theme.transitions.create(["min-height"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, variant }) => ({
  width: "72px",
  overflowX: "hidden",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& .MuiDrawer-paper": {
    width: "72px",
    border: "none",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(variant === "persistent" &&
    !open && {
      display: "none",
    }),
  ...(open && {
    width: Constants.DRAWER_WIDTH,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiDrawer-paper": {
      width: Constants.DRAWER_WIDTH,
      border: "none",
      visibility: "visible !important",
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
}));

const Sidebar = () => {
  const location = useLocation();
  const theme = useTheme();
  const { accessEndpointMap } = useContext(AccessPermissionContext);
  const sidebarHiddenComponents = useSidebarHiddenComponents();

  const sidebarState = useAppSelector((state) => state.sidebarState);

  // global
  const { isSidebarOpened, isWarnings } = sidebarState;

  // local
  const [isPermanent, setPermanent] = useState(true);
  const [isSidebarHovered, setSidebarHovered] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return () => {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "persistent"}
      anchor="left"
      open={isSidebarOpened || isSidebarHovered}
    >
      <DrawerHeader isWarnings={isWarnings} />
      <List
        sx={{ padding: "var(--space-8px) 0px var(--space-32px) 0px" }}
        onMouseLeave={() => {
          if (isSidebarHovered) {
            setSidebarHovered(false);
          }
        }}
      >
        {structure
          .filter((sidebarObject) => {
            if (sidebarObject.link && sidebarObject.permissions) {
              const permissions = getPermission({
                accessEndpointMap,
                path: sidebarObject.link,
              });

              return sidebarObject.permissions.every(
                (right) => permissions[right],
              );
            } else if (sidebarObject.children) {
              return sidebarObject.children.some((child) => {
                const permissions = getPermission({
                  accessEndpointMap,
                  path: child.link,
                });
                return child.permissions.every((right) => permissions[right]);
              });
            }
          })
          .filter((sidebarObject) => {
            if (sidebarObject.link) {
              const hiddenComponent = sidebarHiddenComponents.find(
                (data) => data.name === sidebarObject.label,
              );

              return getHiddenComponentStatus(hiddenComponent);
            } else if (sidebarObject.children) {
              // To show parent when at least one child status is true
              return sidebarObject.children.some((child) => {
                const hiddenComponent = sidebarHiddenComponents.find(
                  (data) => data.name === child.label,
                );
                return getHiddenComponentStatus(hiddenComponent);
              });
            }
          })
          .map((link) => {
            return (
              <SidebarLink
                location={location}
                nested={false}
                type={undefined}
                key={link.id}
                isSidebarHovered={isSidebarHovered}
                setSidebarHovered={setSidebarHovered}
                {...link}
                children={
                  link?.children
                    ?.filter((sidebarChildObject) => {
                      const permissions = getPermission({
                        accessEndpointMap,
                        path: sidebarChildObject.link,
                      });

                      return !!sidebarChildObject.permissions.every(
                        (right) => permissions[right],
                      );
                    })
                    .filter((sidebarChildObject) => {
                      const configurationDataByName =
                        sidebarHiddenComponents.find(
                          (data) => data.name === sidebarChildObject.label,
                        );

                      return configurationDataByName?.status === undefined
                        ? true
                        : configurationDataByName?.status;
                    }) || []
                }
                section={link.section}
                tooltip={link.label}
              />
            );
          })}
      </List>
    </Drawer>
  );

  // #################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
};

export default Sidebar;
