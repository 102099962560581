/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import _c from "src/constants/Constants";
import {
  CustomButton,
  QueryResponse,
} from "./useMUIDataTableOptionsServerSide";
import {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from "mui-datatables";
import { CustomToolbarButtonWithTooltip } from "src/components/Basic/Mixed/Buttons/CustomToolbarButtonWithTooltip";
import { debounceSearchRender } from "mui-datatables";
import { UseMUIDataTableFilterStatesReturn } from "./useMUIDataTableFilterStates";
import { useEffect, useState } from "react";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Grid } from "@mui/material";

export type FilterOptions<DataType = any> = {
  customButtonsOnToolbar?: {
    buttonType?: "button" | "icon";
    buttonElement?: React.ReactNode;
    actionFunction?: () => void;
    showButton: boolean | ((props: { data?: DataType[] }) => boolean);
    title: string;
  }[];
  clickFunction?: MUIDataTableOptions["onRowClick"];
  deleteFunction?: MUIDataTableOptions["onRowsDelete"];
  isRowSelectable?: MUIDataTableOptions["isRowSelectable"];
  rowsSelected?: MUIDataTableOptions["rowsSelected"];
  // selectableRowsHeader should be false if isRowSelectable is set conditionally
  selectableRowsHeader?: boolean;
  selectFunction?: MUIDataTableOptions["onRowSelectionChange"];
  selectToolbarPlacement?: MUIDataTableOptions["selectToolbarPlacement"];
  selectableRows: "multiple" | "single" | "none";
  searchPlaceholder?: MUIDataTableOptions["searchPlaceholder"];
};

export type SubTableFunction = (rowData: any, rowMeta: any) => any;

export type UseMUIDataTableOptions<DataType = any> = {
  customButton?: CustomButton;
  dataFetchResult?: {
    data: QueryResponse<DataType> | undefined;
    isLoading: boolean;
  };
  defaultPageSize?: number;
  defaultPageSizeOptions?: number[];
  expandableRowsOnClick?: boolean;
  filter?: boolean;
  filterOptions?: FilterOptions<DataType>;
  filterStates?: UseMUIDataTableFilterStatesReturn;
  filterTransform?: Record<string, (val: any) => any>;
  filterTranslatorFunction?: (data: any, key: string) => any;
  isRowExpandable?: MUIDataTableOptions["isRowExpandable"];
  onColumnViewChange?: MUIDataTableOptions["onColumnViewChange"];
  onRowExpansionChange?: (
    currentRowsExpanded: any[],
    allRowsExpanded: any[],
    rowsExpanded?: any[],
  ) => void;
  pagination?: boolean;
  rowsExpanded?: number[];
  serverSide?: boolean;
  subtableFunction?: SubTableFunction;
  searchOpen?: boolean;
};

export function useMUIDatTableOptions<DataType = any>(
  props?: UseMUIDataTableOptions<DataType>,
) {
  // Investigate this and perhaps to pagination!!!
  // Add sort, pagination,
  const [t] = useTranslation();
  const [searchedData, setSearchedData] = useState<number[]>();
  const [rowsPerPage, setRowsPerPage] = useState(
    props?.defaultPageSize || props?.filterStates?.baseFilter.pageSize || 10,
  );

  // Trigger row change when base filter page size changes.
  useEffect(() => {
    if (props?.filterStates?.baseFilter.pageSize) {
      setRowsPerPage(props?.filterStates?.baseFilter.pageSize);
    }
  }, [props?.filterStates?.baseFilter.pageSize]);

  const metadata = props?.dataFetchResult?.data?.metadata;
  const isLoading = props?.dataFetchResult?.isLoading;

  const onFilterChange: MUIDataTableOptions["onFilterChange"] = (
    changedColumn,
    filterList,
    type,
    changedColumnIndex,
    _displayData,
  ) => {
    const columnName = changedColumn?.toString() || "";
    const updatedFilter = filterList[changedColumnIndex];

    if (type === "reset") {
      props?.filterStates?.reset();
    } else {
      // Clear rowsExpanded
      if (props?.onRowExpansionChange) {
        props.onRowExpansionChange([], []);
      }
      props?.filterStates?.setPage(0);
      props?.filterStates?.setAdditionalFilter((state: any) => {
        const newState = {
          ...state,
          [columnName]:
            props?.filterTransform && props?.filterTransform[columnName]
              ? props?.filterTransform[columnName](updatedFilter)
              : updatedFilter,
        };

        return newState;
      });
    }
  };

  let defaultPageSizeOptions = props?.defaultPageSizeOptions || [
    1, 10, 15, 50, 100,
  ];

  // Add rowsPerPage to defaultPageSizeOptions if it is not there
  if (rowsPerPage && !defaultPageSizeOptions.includes(rowsPerPage)) {
    defaultPageSizeOptions.push(rowsPerPage);
    defaultPageSizeOptions.sort((a, b) => a - b);
  }

  let options: MUIDataTableProps["options"] & {
    onChangeAdditionalFilter?: UseMUIDataTableFilterStatesReturn["setAdditionalFilter"];
  } = {
    selectableRowsHeader: props?.filterOptions?.selectableRowsHeader,
    caseSensitive: false,
    count: metadata?.count,
    customSearchRender: debounceSearchRender(500),
    download: false,
    draggableColumns: { enabled: false, transitionTime: 1 },
    elevation: 0,
    expandableRowsOnClick:
      props?.expandableRowsOnClick !== undefined
        ? props?.expandableRowsOnClick
        : Boolean(props?.subtableFunction),
    expandableRows: Boolean(props?.subtableFunction),
    expandableRowsHeader: false, // ??
    filter: props?.filter || false,
    fixedSelectColumn: true,
    filterType: "checkbox",
    isRowExpandable: props?.isRowExpandable,
    isRowSelectable: props?.filterOptions?.isRowSelectable,
    onColumnViewChange: props?.onColumnViewChange,
    // Always have pagination on since we now have feature that user can change and save the state.
    // If it can be false this means that the user no longer can change the page state.
    pagination: true,
    rowsExpanded: props?.rowsExpanded,
    rowsSelected: props?.filterOptions?.rowsSelected,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: defaultPageSizeOptions,
    onRowClick: props?.filterOptions?.clickFunction,
    onRowExpansionChange: props?.onRowExpansionChange,
    onRowSelectionChange: props?.filterOptions?.selectFunction,
    onRowsDelete: props?.filterOptions?.deleteFunction,
    onChangeAdditionalFilter: (v) =>
      props?.filterStates?.setAdditionalFilter({
        ...props?.filterStates?.additionalFilter,
        ...v,
      }),
    onChangeRowsPerPage: (rowsPerPageChange: number) => {
      const page = props?.filterStates?.baseFilter?.page;
      // This is to avoid showing empty row at page > 0
      if (
        page &&
        page > 0 &&
        (rowsPerPageChange > metadata?.count ||
          rowsPerPageChange * page > metadata?.count)
      ) {
        props?.filterStates?.setPage(0);
      }

      if (props?.filterStates?.setPageSize) {
        props?.filterStates?.setPageSize(rowsPerPageChange);
      } else {
        setRowsPerPage(rowsPerPageChange);
      }
    },
    onChangePage: props?.filterStates?.setPage,
    onSearchChange: (searchText: string | null) => {
      props?.filterStates?.setSearch(searchText || "");
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      // This is to get search result
      if (action === "search") {
        const indexes = tableState?.displayData?.map(
          ({ dataIndex }) => dataIndex,
        );
        setSearchedData(indexes);
      }
    },
    onColumnSortChange: (changedColumn: string, direction: "asc" | "desc") => {
      props?.filterStates?.setSortDirection(direction);
      props?.filterStates?.setSortBy(changedColumn);
    },
    onFilterChange, //: _.debounce(onFilterChange, 500),
    print: false,
    renderExpandableRow: props?.subtableFunction,
    searchOpen: props?.searchOpen || false,
    searchPlaceholder:
      props?.filterOptions?.searchPlaceholder ||
      `${t(_c.DEFAULT_PLACEHOLDER_FOR_SEARCH_BOX)}...`,
    selectableRows: props?.filterOptions?.selectableRows || "none",
    serverSide: props?.serverSide,
    selectToolbarPlacement:
      props?.filterOptions?.selectToolbarPlacement || "above",
    textLabels: {
      body: {
        noMatch: isLoading ? "" : t("Sorry, no matching records found"),
        toolTip: t("Sort"),
        columnHeaderTooltip: (column: MUIDataTableColumn) =>
          `${t("Sort for")} ${column.label ? column.label : ""}`,
      },
      pagination: {
        next: t("Next Pages"),
        previous: t("Previous Pages"),
        rowsPerPage: t("Rows per page:"),
        displayRows: t("of"),
      },
      viewColumns: {
        title: t("Show Columns"),
        titleAria: t("Show/Hide Table Columns"),
      },
      selectedRows: {
        text: t("row(s) selected"),
        delete: t("Delete"),
        deleteAria: t("Delete Selected Rows"),
      },
      toolbar: {
        downloadCsv: t("Download csv"),
        filterTable: t("Filter table"),
        print: t("Print"),
        search: t("Search"),
        viewColumns: t("View columns"),
      },
    },
  };

  const customButton = props?.customButton;

  if (customButton !== undefined) {
    options = {
      ...options,
      customToolbar: () =>
        customButton({
          additionalFilter: props?.filterStates?.additionalFilter,
          setAdditionalFilter: props?.filterStates?.setAdditionalFilter,
        }),
    };
  }

  if (props?.filterOptions?.customButtonsOnToolbar) {
    const customButtonsOnToolbar = props?.filterOptions?.customButtonsOnToolbar;
    options = {
      ...options,
      customToolbar: () => (
        <Grid
          container
          direction="row"
          spacing={1}
          sx={{ display: "inline-flex", alignItems: "baseline" }}
        >
          {customButtonsOnToolbar?.map((button, index) => (
            <Grid item xs="auto" key={index}>
              <CustomToolbarButtonWithTooltip
                key={index}
                title={button?.title || ""}
                actionFunction={
                  button?.actionFunction || (() => console.log("Error"))
                }
                showButton={
                  typeof button.showButton === "function"
                    ? button.showButton({
                        data: props?.dataFetchResult?.data?.data,
                      })
                    : button.showButton
                }
                buttonType={button?.buttonType}
                buttonElement={button?.buttonElement}
              />
            </Grid>
          ))}
        </Grid>
      ),
    };
  }

  return {
    options,
    searchedData,
    setSearchedData,
  };
}

type UseMUIDatSubTableOptions = {
  onRowExpansionChange?: (
    currentRowsExpanded: any[],
    allRowsExpanded: any[],
    rowsExpanded?: any[],
  ) => void;
  rowsExpanded?: number[];
  subtableFunction?: (rowData: any, rowMeta: any) => any;
};

export const userMUIDatSubTableOptions = (props?: UseMUIDatSubTableOptions) => {
  const options: MUIDataTableProps["options"] = {
    download: false,
    expandableRows: Boolean(props?.subtableFunction),
    expandableRowsHeader: false, // ??
    expandableRowsOnClick: Boolean(props?.subtableFunction),
    filter: false,
    onRowExpansionChange: props?.onRowExpansionChange,
    pagination: false,
    print: false,
    renderExpandableRow: props?.subtableFunction,
    rowsExpanded: props?.rowsExpanded,
    search: false,
    selectableRows: "none",
    viewColumns: false,
  };

  return options;
};

export const userMUIDataSubTableOptions = userMUIDatSubTableOptions;
export const useMUIDataTableOptions = useMUIDatTableOptions;
