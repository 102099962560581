import { Types } from "mongoose";

export const languageCodes = [
  "bg",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "et",
  "fi",
  "fr",
  "ga",
  "hr",
  "hu",
  "it",
  "lt",
  "lv",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "sl",
  "sv",
  "tr",
  "uk",
  "vi",
] as const;
export type LanguageCode = (typeof languageCodes)[number];

export const Languages: {
  code: LanguageCode;
  name: string;
  nativeName: string;
}[] = [
  { code: "bg", name: "Bulgarian", nativeName: "български език" },
  { code: "cs", name: "Czech", nativeName: "čeština" },
  { code: "da", name: "Danish", nativeName: "dansk" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek", nativeName: "Ελληνικά" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "es", name: "Spanish", nativeName: "español" },
  { code: "et", name: "Estonian", nativeName: "eesti keel" },
  { code: "fi", name: "Finnish", nativeName: "suomi" },
  { code: "fr", name: "French", nativeName: "français" },
  { code: "ga", name: "Irish", nativeName: "Gaeilge" },
  { code: "hr", name: "Croatian", nativeName: "hrvatski jezik" },
  { code: "hu", name: "Hungarian", nativeName: "magyar nyelv" },
  { code: "it", name: "Italian", nativeName: "italiano" },
  { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { code: "mt", name: "Maltese", nativeName: "Malti" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "pt", name: "Portuguese", nativeName: "português" },
  { code: "ro", name: "Romanian", nativeName: "română" },
  { code: "sk", name: "Slovak", nativeName: "slovenčina" },
  { code: "sl", name: "Slovenian", nativeName: "slovenski jezik" },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "tr", name: "Turkish", nativeName: "Türkçe" },
  { code: "uk", name: "Ukrainian", nativeName: "українська мова" },
  { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
];

export const LANGUAGE_CODES = Languages.map((c) => c.code as LanguageCode);

export type Language = {
  _id?: Types.ObjectId;
  isActive: boolean;
  code: LanguageCode;
  name: string;
  nativeName: string;
};
