import { CustomerInvoiceRow } from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { PlannedInvoiceRow } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { SupplierInvoiceRow } from "src/accurasee-backend-types/app/supplierinvoice/supplierinvoice.types";
import { userMUIDatSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";

type InvoiceRowSubtable =
  | PlannedInvoiceRow
  | CustomerInvoiceRow
  | SupplierInvoiceRow
  | InvoicePlanRow;

interface InvoiceSubTableProps {
  currencyCode: string;
  invoiceRows: InvoiceRowSubtable[];
  title?: string;
  titlePosition?: "middle" | "top";
  useColumns: string[];
}

const checkTextRow = (invoiceRow: InvoiceRowSubtable) => {
  return invoiceRow.textRow === true;
};

export function InvoiceRowsSubtable({
  currencyCode,
  invoiceRows,
  title,
  titlePosition,
  useColumns,
}: InvoiceSubTableProps) {
  const invoiceRowsTableStructure: {
    items: StructureItemTable<InvoiceRowSubtable>[];
  } = {
    items: [
      {
        type: "text_with_optional_badge",
        dataName: "itemDescription",
        headerLabel: "Description",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          let isProcessing = false;
          if ("incomplete" in data[rowIndex]) {
            isProcessing = !!(
              data[rowIndex] as PlannedInvoiceRow | InvoicePlanRow
            )?.incomplete;
          }
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
      },
      {
        type: "text",
        dataName: "accountDescription",
        headerLabel: "Description",
      },
      {
        type: "text",
        dataName: "projectExternalId",
        headerLabel: "Contract",
      },
      {
        type: "text",
        dataName: "quantity",
        headerLabel: "Quantity",
        getIsEstimate: "isQuantityEstimate",
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex])
            ? ""
            : value +
              (!!data[rowIndex]?.unitExternalId
                ? " " + data[rowIndex]?.unitExternalId
                : ""),
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "pricePerUnit",
        headerLabel: "Price per unit",
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex]) ? "" : value,
        getIsEstimate: "isPricePerUnitEstimate",
        whiteSpace: "nowrap",
        alignColumnContent: "end",
        currencyCode,
      },
      {
        type: "text",
        dataName: "discount",
        headerLabel: "Discount",
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex]) ? "" : value,
        showColumnWhen: ({ data, item }) =>
          data.some((row) => (row?.discount || 0) > 0),
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        dataName: "totalExcludingVAT",
        headerLabel: "Tot excl. VAT",
        getIsEstimate: ({ data, rowIndex }) =>
          data[rowIndex]["isPricePerUnitEstimate"] ||
          data[rowIndex]["isQuantityEstimate"],
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex]) ? "" : value,
        whiteSpace: "nowrap",
        alignColumnContent: "end",
        currencyCode,
      },
      {
        type: "percent",
        dataName: "percentVAT",
        headerLabel: "VAT",
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex]) ? "" : value,
        getValue: ({ data, rowIndex }) => {
          const row = data[rowIndex];
          return "percentVAT" in row ? row.percentVAT * 100 : 0;
        },
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        dataName: "total",
        headerLabel: "Total",
        getIsEstimate: ({ data, rowIndex }) =>
          data[rowIndex]["isPricePerUnitEstimate"] ||
          data[rowIndex]["isQuantityEstimate"],
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          checkTextRow(data[rowIndex]) ? "" : value,
        whiteSpace: "nowrap",
        alignColumnContent: "end",
        currencyCode,
      },
      {
        type: "date",
        dataName: "importDate",
        headerLabel: "Report date",
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "icon",
        iconType: "check",
        headerLabel: "Use index",
        dataName: "isIndex",
        showCellWhen: ({ data, rowIndex }) => data[rowIndex].isIndex,
        showColumnWhen: ({ data }) => data.some((row) => row?.isIndex),
      },
      {
        type: "icon",
        iconType: "check",
        headerLabel: "Exported",
        dataName: "invoiceDate",
        showCellWhen: ({ data, rowIndex }) =>
          !!(data[rowIndex] as PlannedInvoiceRow & { exported: boolean })
            ?.exported,
        showColumnWhen: ({ data }) =>
          data.some(
            (row) =>
              !!(row as PlannedInvoiceRow & { exported: boolean })?.exported,
          ),
      },
    ],
  };

  const invoiceRowColumns: MUIDataTableColumnDef[] =
    invoiceRowsTableStructure.items
      .map((item) =>
        BuilderColumnOptionsSingle<InvoiceRowSubtable>({
          data: invoiceRows,
          disabled: true,
          item,
        }),
      )
      .filter((column: any) => useColumns.includes(column.name));

  const options = userMUIDatSubTableOptions({});

  return (
    <MuiDataTableWrapper
      title={title || ""}
      titlePosition={titlePosition}
      data={invoiceRows}
      columns={invoiceRowColumns}
      options={options}
      tableType="main-nested-level-1-centered"
    />
  );
}

export default InvoiceRowsSubtable;
