import { useEffect, useRef, useState } from "react";

const useMakeTableScrollableOnOverflow = () => {
  const [isTableScrollable, setIsTableScrollable] = useState(false);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const checkOverflow = () => {
    if (tableRef.current) {
      const containerElement = tableRef.current; // Parent container
      const tableElement = tableRef.current.querySelector("table"); // The actual <table> element inside the MUIDataTable

      if (tableElement) {
        const tableElementWidth = tableElement.scrollWidth;
        const containerElementWidth = containerElement.clientWidth;

        setIsTableScrollable(tableElementWidth > containerElementWidth);
      }
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      checkOverflow(); // Check table and container width when resized
    });

    if (tableRef.current) {
      observer.observe(tableRef.current);
    }

    window.addEventListener("resize", checkOverflow); // Check on window resize

    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current);
      }
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return {
    isTableScrollable,
    tableRef,
  };
};

export default useMakeTableScrollableOnOverflow;
