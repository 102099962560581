import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import EmailTemplateModalWithForm from "./EmailTemplateModalWithForm";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTransparentForTableWithToolbar from "../../../../components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";
import _c from "src/constants/Constants";
import useFormTable from "src/hooks/useFormTable";
import { EmailTemplate } from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery,
} from "src/redux/services/EmailTemplateService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import getStructure, {
  GetStructureExtraProps,
} from "./EmailTemplatesStructure";
import { listToDic } from "../../../../utils/transform";
import { EmailType } from "../../../../accurasee-backend-types/app/general/mailgun/mailgun.types";
import { CommonCallbackProps } from "../../../../components/Builders/Table/CommonBuilderTableTypes";
import useOnFormSubmit from "../../../../hooks/useOnFormSubmit";

const EmailTemplates = ({ languages }: { languages: Language[] }) => {
  const [t] = useTranslation();

  const { data: emailTemplatesQuery, isLoading: isLoadingEmailTemplates } =
    useGetEmailTemplatesQuery(undefined);
  const templates = emailTemplatesQuery?.data || [];
  const [deleteEmailTemplate] = useDeleteEmailTemplateMutation();

  const [openAddModal, setOpenAddModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState("");

  const { onFormSubmit } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        delete: deleteEmailTemplate,
      },
      name: "Email template",
    },
  });
  const onDelete = async ({ data, rowIndex }: CommonCallbackProps) => {
    await onFormSubmit({
      action: "delete",
      data: { delete: data[rowIndex]._id },
    });
  };

  const { helperText, structure } = useFormTable<
    EmailTemplate,
    GetStructureExtraProps
  >({
    extraProps: {
      onDelete,
      setOpenEditModal,
      setEditModalData,
    },
    initialFormData: templates,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: templates,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: templates.length >= 25,
    defaultPageSize: 25,
    defaultPageSizeOptions: [25, 50],
  });

  // This is to check if an email template name is existing
  // const templateNames = templates?.map((item) => item.name.toLowerCase());

  const templatesByEmailType = listToDic(
    templates,
    (t) => t.type,
    true,
  ) as Record<EmailType, EmailTemplate[]>;
  const fullTemplateEmailTypes = Object.values(templatesByEmailType)
    .filter((l) => l.length === languages.length)
    .reduce((tot, t) => {
      tot.push(t[0].type);
      return tot;
    }, [] as EmailType[]);

  return (
    <SectionContainer>
      <SectionTransparentForTableWithToolbar
        isLoading={isLoadingEmailTemplates}
        rightBoxArea={
          <ButtonPrimary onClick={() => setOpenAddModal(true)}>
            {t("Create email template")}
          </ButtonPrimary>
        }
      >
        <MuiDataTableWrapper
          columns={columns}
          data={templates}
          noBackground={true}
          options={options}
          tableType="main-with-filter"
        />
      </SectionTransparentForTableWithToolbar>
      {/* Add modal */}
      <EmailTemplateModalWithForm
        fullTemplateEmailTypes={fullTemplateEmailTypes}
        handleCloseModal={() => setOpenAddModal(false)}
        languages={languages || _c.DEFAULT_LANGUAGES}
        openModal={openAddModal}
        templatesByEmailType={templatesByEmailType}
      />
      {/* Edit modal */}
      <EmailTemplateModalWithForm
        emailTemplateId={editModalData}
        fullTemplateEmailTypes={fullTemplateEmailTypes}
        handleCloseModal={() => setOpenEditModal(false)}
        languages={languages || _c.DEFAULT_LANGUAGES}
        openModal={openEditModal}
        templatesByEmailType={templatesByEmailType}
      />
    </SectionContainer>
  );
};

export default EmailTemplates;
