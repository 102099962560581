/**
 *Created by Mikael Lindahl on 2023-11-23
 */

import { Box, SxProps } from "@mui/material";
import BreadcrumbsWrapper, {
  Breadcrumb,
} from "../Breadcrumb/BreadcrumbsWrapper";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import useTranslation from "src/hooks/useTranslationWrapper";

const PageHeading = (props: {
  boxRight?: JSX.Element | JSX.Element[];
  breadcrumbs?: Breadcrumb[];
  headingSx?: SxProps;
  label?: string;
  labelSize?: "normal" | "large";
  subLabel?: string;
}) => {
  const [t] = useTranslation();

  const isHeader = !!props.label || !!props.subLabel || !!props.boxRight;

  return isHeader ? (
    <SectionFloatingForButtons
      isFullWidth
      position="top-center"
      sx={props?.headingSx}
    >
      <>
        <Box sx={{ marginBottom: "var(--space-4px)" }}>
          {props.breadcrumbs && <BreadcrumbsWrapper list={props.breadcrumbs} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {props.label && (
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: props?.labelSize === "large" ? 36 : 24,
                  ...(props?.labelSize === "large"
                    ? { lineHeight: "42px" }
                    : {}),
                }}
              >
                {t(props.label)}
              </Box>
            )}
            {props.subLabel && (
              <Box
                sx={{
                  fontWeight: "normal",
                  fontSize: props?.labelSize === "large" ? 20 : 16,
                }}
              >
                {t(props.subLabel)}
              </Box>
            )}
          </Box>
          {props.boxRight && (
            <Box
              sx={{
                display: "flex",
                columnGap: "var(--space-8px)",
              }}
            >
              {props.boxRight}
            </Box>
          )}
        </Box>
      </>
    </SectionFloatingForButtons>
  ) : (
    <></>
  );
};

export default PageHeading;
