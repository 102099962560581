import { backendApi } from "../api";
import { listTag } from "./utils";

const CACHE_TAG = "Units";
export const unitApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getUnits: build.query({
      query: (params) => ({ url: "/units", params }),
      transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TAG),
    }),
    getUnit: build.query({
      query: (id) => ({ url: `/units/${id}` }),
    }),
    createUnit: build.mutation({
      query: (data) => ({ url: "/units", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
  }),
});
