import { useState } from "react";
import { MUIDataTableColumnDef } from "mui-datatables";

import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";

import { useGetContractsQuery } from "src/redux/services/ContractService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";

import useFormTable from "src/hooks/useFormTable";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";

import getStructure, {
  ContractsByUser,
  GetStructureExtraProps,
} from "./TransferOwnershipsStructure";
import EditModalWithForm from "./EditModalWithForm";
import { normailze } from "./utils";

const TransferOwnerships = () => {
  // Edit modal
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<ContractsByUser>();

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: queryResult, isLoading } = useGetContractsQuery({});

  const contracts =
    normailze({ contracts: queryResult?.data || [], users }) || [];
  const { options } = useMUIDatTableOptions({
    pagination: contracts && contracts?.length > 10,
  });

  const { structure } = useFormTable<ContractsByUser, GetStructureExtraProps>({
    extraProps: {
      setOpenModal,
      setModalData,
    },
    initialFormData: contracts,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ContractsByUser>({
      data: contracts,
      item,
    }),
  );

  return (
    <Page
      label={"Transfer ownerships"}
      isFullWidth={false}
      breadcrumbs={[{ label: "Operations" }, { label: "Transfer ownership" }]}
    >
      <SectionContainer>
        <MuiDataTableWrapper
          data={contracts}
          columns={columns}
          isFetching={isLoading || isLoadingUsers}
          options={options}
          title=""
          tableType="main-with-filter"
          noBackground={true}
        />
        {openModal && (
          <EditModalWithForm
            users={users}
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={modalData}
          />
        )}
      </SectionContainer>
    </Page>
  );
};

export default TransferOwnerships;
