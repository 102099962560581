import { UserFormTableGetStructure } from "src/hooks/useFormTable";

import {
  Invite,
  INVITE_STATUS,
} from "src/accurasee-backend-types/app/invite/invite.types";
import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";

import { OnSubmit } from "src/hooks/useOnSubmit";

import { lessThan } from "src/utils/date";

export type GetStructureProps = {
  userRoles: AccessUserRole[] | undefined;
  onSubmitInviteUpdate: OnSubmit;
};

const getStatusText = (status: number) => {
  switch (status) {
    case INVITE_STATUS.SENT:
      return "sent";
    case INVITE_STATUS.REJECTED:
      return "rejected";
    case INVITE_STATUS.ACCEPTED:
      return "accepted";
    case INVITE_STATUS.INVALID:
      return "invalid";
    default:
      return "unknown";
  }
};

export const getStructure: UserFormTableGetStructure<
  Invite,
  GetStructureProps
> = ({ setFormData, extraProps }) => {
  return {
    items: [
      {
        type: "text",
        dataName: "toEmail",
        headerLabel: "Email",
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        getValue: ({ data, rowIndex }) =>
          data[rowIndex]?.status === INVITE_STATUS.SENT &&
          lessThan(data[rowIndex]?.validUntil, new Date())
            ? "expired"
            : getStatusText(data[rowIndex]?.status),
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "companyRoleIds",
        headerLabel: "Roles",
        getValue: ({ data, rowIndex }) => {
          const roles = data[rowIndex]?.companyRoleIds?.map(
            (role: any) =>
              extraProps?.userRoles?.find((e) => e._id === role)?.name,
          );
          return roles?.join(", ");
        },
      },
      {
        type: "date",
        dataName: "createdAt",
        headerLabel: "Created at",
      },
      {
        dataName: "",
        cellLabel: "Invalidate",
        type: "button_secondary",
        permissionType: "writeRights",
        onClick: ({ data, rowIndex }) => {
          extraProps?.onSubmitInviteUpdate({
            data: { update: { status: INVITE_STATUS.INVALID } },
            dataId: String(data[rowIndex]._id),
          });
        },
        showCellWhen: (props) =>
          props.data[props.rowIndex]?.status === INVITE_STATUS.SENT &&
          !lessThan(props.data[props.rowIndex]?.validUntil, new Date()),
      },
    ],
  };
};
