import { tagTypes } from "../api";

type TagType = (typeof tagTypes)[number];

// To ensure correct typeing when using simple list of tags strings
export const getPlainListAndIdTags = (...listTypes: TagType[]) => {
  return (result: any, error: any, id: any) => [
    ...listTypes,
    ...listTypes.map((t) => ({ type: t, id: "LIST" })),
    ...listTypes.map((t) => ({ type: t, id })),
  ];
};

export const listTag = (type: TagType) => () => [{ type, id: "LIST" }];

export const listTagTwo = (type1: TagType, type2: TagType) => () => [
  { type: type1, id: "LIST" },
  { type: type2, id: "LIST" },
  type1,
  type2,
];

export const listTagMultiple =
  (...types: TagType[]) =>
  () =>
    types.map((t) => ({ type: t, id: "LIST" }));

export const docGetTag =
  (type: TagType) => (result: any, error: any, id: any) => [{ type, id }];
export const docGetTagMultipleList =
  (type: TagType, ...listTypes: TagType[]) =>
  (result: any, error: any, id: any) => {
    return [{ type, id }, ...listTypes.map((t) => ({ type: t, id: "LIST" }))];
  };
export const docPatchTag =
  (types: TagType | Array<TagType>) =>
  (result: any, error: any, { id }: any) =>
    [types]
      .flat()
      .flatMap((type) => [{ type, id }, { type, id: "LIST" }, type]);

export const docPatchTagNew =
  (type: TagType) =>
  (result: any, error: any, { id }: any) => [
    { type, id },
    { type, id: "LIST" },
    type,
  ];

export const docPatchTagPlusExtraListTag =
  (type: TagType, extraType: TagType) =>
  (result: any, error: any, { id }: any) => [
    { type, id },
    { type, id: "LIST" },
    { type: extraType, id: "LIST" },
  ];
export const docDeleteTags =
  (type: TagType) => (result: any, error: any, id: any) => [
    { type, id },
    { type, id: "LIST" },
    { type },
    type,
  ];
export const docListTags =
  (type: TagType) => (result: any, error: any, id: any) =>
    result
      ? [
          ...result?.data?.map((doc: any) => ({ type, id: doc._id })),
          { type, id: "LIST" },
        ]
      : [];
export const docMultipleListTag =
  (...listTypes: TagType[]) =>
  (result: any, error: any, id: any) => {
    return listTypes;
  };
