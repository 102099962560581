/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    boxSection: {
      height: "100%",
      backgroundColor: theme?.palette?.white?.main,
      padding: "1rem 2rem 2rem 2rem",
      "& .MuiFormHelperText-root": {
        marginLeft: 2,
        marginRight: 0,
      },
      borderRadius: "12px",
    },
  }),
);

export default useStyles;
