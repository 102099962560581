import { type MUIDataTableState } from "mui-datatables";

import getValue from "./getValue";
import toDay from "src/utils/date";
import { type CommonCallbackProps } from "../CommonBuilderTableTypes";

const getExportData = (tableState: MUIDataTableState) => {
  const columns = tableState.columns;

  const rows = tableState.displayData.map((row) => {
    return row.data
      .map((cell) => {
        const cellProps = cell.props as CommonCallbackProps;

        if (Object.keys(cellProps).length === 0) {
          return "";
        }

        const valueGetter = cellProps.item.getSheetExportValue || getValue;

        const value = valueGetter({
          data: cellProps.data,
          rowIndex: cellProps.rowIndex,
          item: cellProps.item,
        });

        switch (cellProps.item.type) {
          case "date":
            return toDay(value);
          case "percent":
            return { v: value / 100, t: "n", z: "0 %" };
          default:
            return value;
        }
      })
      .filter((_, ithCell) => {
        return columns[ithCell].display === "true";
      });
  });

  return [
    columns
      .filter((column) => column.display === "true")
      .map((column) => column.label),
    ...rows,
  ];
};

export default getExportData;
