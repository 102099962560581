/**
 *Created by Mikael Lindahl on 2024-04-23
 */

import { listToDic } from "../../../../../utils/transform";
import sort from "../../../../../utils/sort";
import { IntegrationImportJob } from "../../../../../accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { Types } from "mongoose";

const addJobs = ({
  currentJobs,
  newJobs,
  groupId,
}: {
  currentJobs: IntegrationImportJob[];
  newJobs: IntegrationImportJob[];
  groupId?: Types.ObjectId;
}) => {
  const jobsById = listToDic(currentJobs, (v) => String(v._id));

  newJobs.map((j) => {
    if (
      (!groupId || j.groupId === groupId) &&
      (!jobsById[String(j._id)] ||
        new Date(String(j.updatedAt)) >
          new Date(String(jobsById[String(j._id)].updatedAt)))
    ) {
      jobsById[String(j._id)] = j;
    }
  });

  let jobs = Object.values(jobsById);
  jobs = sort(jobs, (v) => v.groupPosition);

  return jobs;
};

export default addJobs;
