/**
 *Created by Mikael Lindahl on 2023-03-21
 */
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import {
  AlterationAndAdditionalWorkData,
  BudgetReturn,
  MainBudget,
  ResultData,
} from "src/accurasee-backend-types/app/budget/budget.types";
import toUpdateData from "../../../../../../utils/toUpdateData";
import { InvoicePlanTemplate } from "../../../../../../accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import clone from "src/utils/clone";

export type GetStructureExtraProps = {
  currencyCode: string;
  ATAData: AlterationAndAdditionalWorkData;
  resultsData: ResultData;
  totalPrice?: number;
};

export const items = {
  alterationAndAdditionalWork: [
    {
      label: "Running rate ATAs",
      type: "alterationAndAdditionalWork.runningRateATA",
    },
    {
      label: "Fixed price ATAs",
      type: "alterationAndAdditionalWork.fixedPriceATA",
    },
    {
      label: "ATA totals",
      type: "alterationAndAdditionalWork.totals",
    },
  ],
  results: [
    {
      label: "Actuals",
      type: "results.actuals",
    },
    {
      label: "Percentage of completion",
      type: "results.percentageOfCompletion",
    },
    {
      label: "Estimates",
      type: "results.estimates",
    },
  ],
};
export type ItemsTypes =
  | "alterationAndAdditionalWork.fixedPriceATA"
  | "alterationAndAdditionalWork.runningRateATA"
  | "alterationAndAdditionalWork.totals"
  | "alterationAndAdditionalWork"
  | "budget"
  | "results.actuals"
  | "results.estimates"
  | "results.percentageOfCompletion";

export const getStructure: UseFormContainerGetStructure<
  MainBudget,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const isRunningRate = extraProps?.totalPrice === undefined;
  const structure: StructureContainer<MainBudget, ItemsTypes> = {
    items: [
      {
        currencyCode: extraProps?.currencyCode,
        dataName: "supplierCosts",
        gridProps: { xs: 4 },
        itemType: "budget",
        label: "Supplier costs",
        required: true,
        setFormData,
        type: "number_input_currency",
      },
      {
        currencyCode: extraProps?.currencyCode,
        dataName: "labourCosts",
        gridProps: { xs: 4 },
        itemType: "budget",
        label: "Labour costs",
        required: true,
        setFormData,
        type: "number_input_currency",
      },
      {
        currencyCode: extraProps?.currencyCode,
        dataName: "totalPrice",
        showWhen: !isRunningRate,
        getValue: ({ data }) => extraProps?.totalPrice || 0, // Update BE return to include totalPrice
        gridProps: { xs: 4 },
        itemType: "budget",
        label: "Total price",
        type: "text_currency_integer",
      },
      {
        currencyCode: extraProps?.currencyCode,
        dataName: "estimatedTotalPrice",
        showWhen: isRunningRate,
        // getValue: ({ data }) =>
        //   isRunningRate
        //     ? data?.estimatedTotalPrice
        //     : extraProps?.totalPrice || 0,
        gridProps: { xs: 4 },
        itemType: "budget",
        label: "Estimated total price",
        type: "number_input_currency",
        setFormData,
      },
      {
        gridProps: { xs: 4 },
        dataName: "budgetedMargin",
        label: "Budgeted margin",
        disabled: true,
        itemType: "budget",
        type: "text",
        getValue: ({ data }) => {
          const { supplierCosts, labourCosts, estimatedTotalPrice } = data!;
          const calculateMargin = (income: number) => {
            const result =
              (income - (supplierCosts || 0) - (labourCosts || 0)) / income;
            return String(Math.round((result * 100 * 100) / 100)) + " %";
          };

          const { totalPrice } = extraProps!;
          if (totalPrice) {
            return calculateMargin(totalPrice);
          } else if (estimatedTotalPrice) {
            return calculateMargin(estimatedTotalPrice);
          } else return "- %";
        },
      },
      {
        currencyCode: extraProps?.currencyCode,
        dataName: "budgetedResults",
        disabled: true,
        gridProps: { xs: 8 },
        itemType: "budget",
        label: "Budgeted results",
        type: "text_currency_integer",
        getValue: ({ data }) => {
          const { supplierCosts, labourCosts, estimatedTotalPrice } = data!;
          const calculateResult = (income: number) => {
            return income - (supplierCosts || 0) - (labourCosts || 0);
          };

          const { totalPrice } = extraProps!;
          if (totalPrice) {
            return calculateResult(totalPrice);
          } else if (estimatedTotalPrice) {
            return calculateResult(estimatedTotalPrice);
          } else return "-";
        },
      },
      {
        dataName: "numberOfATA",
        label: "Number of ATAs",
        itemType: "alterationAndAdditionalWork",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.numberOfATA;
        },
      },
      {
        dataName: "numberOfInvoicedATA",
        label: "Number of invoiced ATAs",
        itemType: "alterationAndAdditionalWork",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.numberOfInvoicedAtas;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "runningRateATA.income",
        label: "Incomes",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.runningRateATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.runningRateATA.incomes;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "runningRateATA.cost",
        label: "Costs",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.runningRateATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.runningRateATA.costs;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "runningRateATA.results",
        label: "Results",
        currencyCode: extraProps?.currencyCode,
        textColor: () => {
          const { ATAData } = extraProps!;
          return ATAData.runningRateATA.results >= 0;
        },
        itemType: "alterationAndAdditionalWork.runningRateATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.runningRateATA.results;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "fixedPriceATA.income",
        label: "Incomes",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.fixedPriceATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.fixedPriceATA.incomes;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "fixedPriceATA.cost",
        label: "Costs",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.fixedPriceATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.fixedPriceATA.costs;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "fixedPriceATA.results",
        label: "Results",
        currencyCode: extraProps?.currencyCode,
        textColor: () => {
          const { ATAData } = extraProps!;
          return ATAData.fixedPriceATA.results >= 0;
        },
        itemType: "alterationAndAdditionalWork.fixedPriceATA",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.fixedPriceATA.results;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "totals.income",
        label: "Incomes",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.totals",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.totals.incomes;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "totals.cost",
        label: "Costs",
        currencyCode: extraProps?.currencyCode,
        itemType: "alterationAndAdditionalWork.totals",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.totals.costs;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "totals.results",
        label: "Results",
        currencyCode: extraProps?.currencyCode,
        textColor: () => {
          const { ATAData } = extraProps!;
          return ATAData.totals.results >= 0;
        },
        itemType: "alterationAndAdditionalWork.totals",
        type: "text_inline",
        getValue: () => {
          const { ATAData } = extraProps!;
          return ATAData.totals.results;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "actuals.supplierCost",
        label: "Current costs from suppliers",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.actuals",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.actuals.currentSupplierCosts;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "actuals.labourCosts",
        label: "Current labour costs",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.actuals",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.actuals.currentLabourCosts;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "actuals.invoicedMainProject",
        label: "Invoiced on main project",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.actuals",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.actuals.invoicedProject;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "actuals.invoicedTotal",
        label: "Total invoiced",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.actuals",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.actuals.invoicedTotal;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "percentageOfCompletion.estimatedCompletion",
        label: "Estimated completion",
        itemType: "results.percentageOfCompletion",
        type: "text_inline",
        textColor: ({ data }) => {
          const {
            resultsData: {
              percentageOfCompletion: { estimatedCompletion },
            },
          } = extraProps!;
          return getValueOrDash(
            estimatedCompletion >= 0 && estimatedCompletion <= 100,
            data,
          );
        },
        getValue: ({ data }) => {
          const { resultsData } = extraProps!;
          return (
            getValueOrDash(
              String(
                Math.min(
                  resultsData.percentageOfCompletion.estimatedCompletion,
                  100,
                ),
              ),
              data,
            ) + " %"
          );
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "percentageOfCompletion.adjustments",
        label: "Total cost adjustments",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.percentageOfCompletion",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.percentageOfCompletion.adjustments;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "percentageOfCompletion.currentProjectResult",
        label: "Current project results",
        currencyCode: extraProps?.currencyCode,
        textColor: ({ data }) => {
          const { resultsData } = extraProps!;
          return getValueOrDash(
            resultsData.percentageOfCompletion.currentProjectResult >= 0,
            data,
          );
        },
        itemType: "results.percentageOfCompletion",
        type: "text_inline",
        getValue: ({ data }) => {
          const { resultsData } = extraProps!;
          return getValueOrDash(
            resultsData.percentageOfCompletion.currentProjectResult,
            data,
          );
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "percentageOfCompletion.currentTotalResult",
        label: "Current results",
        currencyCode: extraProps?.currencyCode,
        textColor: ({ data }) => {
          const { resultsData } = extraProps!;
          return getValueOrDash(
            resultsData.percentageOfCompletion.currentTotalResult >= 0,
            data,
          );
        },
        itemType: "results.percentageOfCompletion",
        type: "text_inline",
        getValue: ({ data }) => {
          const { resultsData } = extraProps!;
          return getValueOrDash(
            resultsData.percentageOfCompletion.currentTotalResult,
            data,
          );
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "estimates.estTotalIncome",
        label: "Total income",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.estimates",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.estimates.estTotalIncomes;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "estimates.estTotalCost",
        label: "Total cost",
        currencyCode: extraProps?.currencyCode,
        itemType: "results.estimates",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.estimates.estTotalCosts;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "estimates.estTotalResult",
        label: "Total result",
        currencyCode: extraProps?.currencyCode,
        textColor: () => {
          const { resultsData } = extraProps!;
          return resultsData.estimates.estTotalResults >= 0;
        },
        itemType: "results.estimates",
        type: "text_inline",
        getValue: () => {
          const { resultsData } = extraProps!;
          return resultsData.estimates.estTotalResults;
        },
      },
      {
        gridProps: { xs: 12 },
        dataName: "estimates.estMargin",
        label: "Total margin",
        itemType: "results.estimates",
        type: "text_inline",
        textColor: ({ data }) => {
          const { resultsData } = extraProps!;
          return getValueOrDash(resultsData.estimates.estMargin >= 0, data);
        },
        getValue: ({ data }) => {
          const { resultsData } = extraProps!;
          return (
            getValueOrDash(String(resultsData.estimates.estMargin), data) + " %"
          );
        },
      },
    ],
  };

  return structure;
};

export function calculateResultData(
  ATATotals: AlterationAndAdditionalWorkData["totals"],
  totalCostAdjustments: number,
  totalIncomeAdjustments: number,
  budgetBackendReturn: BudgetReturn,
) {
  const {
    currentLabourCosts,
    currentSupplierCosts,
    estimatedTotalPrice,
    invoicedProject,
    invoicedTotal,
    labourCosts,
    supplierCosts,
    totalPrice,
  } = budgetBackendReturn;
  const currentProjectCosts = currentSupplierCosts + currentLabourCosts;
  const projectCosts = supplierCosts + labourCosts;
  const estimatedCompletion =
    currentProjectCosts / (projectCosts + totalCostAdjustments) || 0;
  const estimatedCompletionPercent = Math.round(
    (estimatedCompletion * 100 * 100) / 100,
  );
  const projectIncome =
    (totalPrice || estimatedTotalPrice!) + totalIncomeAdjustments;

  const currentProjectResult = Math.round(
    Math.min(estimatedCompletion, 1) *
      (projectIncome - projectCosts - totalCostAdjustments),
  );

  const estTotalIncomes = projectIncome + ATATotals.incomes;
  const estTotalCosts = totalCostAdjustments + projectCosts + ATATotals.costs;
  const estTotalResults = estTotalIncomes - estTotalCosts;

  return {
    actuals: {
      currentSupplierCosts,
      currentLabourCosts,
      invoicedProject,
      invoicedTotal,
    },
    percentageOfCompletion: {
      estimatedCompletion: estimatedCompletionPercent,
      adjustments: totalCostAdjustments,
      currentProjectResult,
      currentTotalResult: ATATotals.results + currentProjectResult,
    },
    estimates: {
      estTotalCosts,
      estTotalIncomes,
      estTotalResults,
      estMargin: Math.round(
        ((estTotalResults / estTotalIncomes) * 100 * 100) / 100,
      ),
    },
  } as ResultData;
}

const getValueOrDash = (value: any, data?: MainBudget) => {
  const labourOrSupplierCostsExists =
    (data?.labourCosts && data?.labourCosts > 0) ||
    (data?.supplierCosts && data?.supplierCosts > 0);
  if (!!data?._id || labourOrSupplierCostsExists) return value;
  else return "-";
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: MainBudget;
  initData?: MainBudget;
}) => {
  const newData = clone(data);

  let partialData: Partial<InvoicePlanTemplate>;

  if (newData && data?._id) {
    partialData = toUpdateData<Partial<MainBudget>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData || {};
  }

  return partialData;
};
