/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    boxSectionTight: {
      height: "100%",
      backgroundColor: theme?.palette?.white?.main,
      borderRadius: "12px",
      "& .MuiFormHelperText-root": {
        marginLeft: 2,
        marginRight: 0,
      },
    },
  }),
);

export default useStyles;
