import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import WarningModalWithForm from "./WarningModalWithForm";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTransparentForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";
import _c from "src/constants/Constants";
import useFormTable from "src/hooks/useFormTable";
import useOnSubmit from "src/hooks/useOnSubmit";
import { Warning } from "src/accurasee-backend-types/app/warning/warning.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import {
  useGetWarningSuperAdminQuery,
  useUpdateWarningSuperAdminMutation,
} from "src/redux/services/WarningService";
import { useGetCompanySuperAdminQuery } from "src/redux/services/CompanyService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import getStructure, { GetStructureExtraProps } from "./WarningsStructure";

const Warnings = () => {
  const [t] = useTranslation();

  const { data: companiesQuery, isLoading: isLoadingCompanies } =
    useGetCompanySuperAdminQuery(undefined);
  const companies = companiesQuery?.data || [];

  const [updateWarningSuperAdmin] = useUpdateWarningSuperAdminMutation();
  const { data: WarningsQuery, isLoading: isLoadingWarnings } =
    useGetWarningSuperAdminQuery(undefined);
  const warnings = WarningsQuery?.data || [];

  const [openAddModal, setOpenAddModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState<Partial<Warning>>({});

  const onSubmit = useOnSubmit({
    apiMutations: {
      update: updateWarningSuperAdmin,
    },
    name: "Warning",
  });

  const { formData, helperText, structure } = useFormTable<
    Warning,
    GetStructureExtraProps
  >({
    extraProps: {
      companies,
      onSubmitActiveSwitch: onSubmit,
      setOpenEditModal,
      setEditModalData,
    },
    initialFormData: warnings,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionContainer>
      <SectionTransparentForTableWithToolbar
        isLoading={isLoadingCompanies || isLoadingWarnings}
        rightBoxArea={
          <ButtonPrimary onClick={() => setOpenAddModal(true)}>
            {t("Create a warning")}
          </ButtonPrimary>
        }
      >
        <MuiDataTableWrapper
          columns={columns}
          data={formData}
          noBackground={true}
          options={options}
          tableType="main-with-filter"
        />
      </SectionTransparentForTableWithToolbar>
      {/* Add modal */}
      <WarningModalWithForm
        key={"add"}
        openModal={openAddModal}
        handleCloseModal={() => setOpenAddModal(false)}
      />
      {/* Edit modal */}
      <WarningModalWithForm
        key={"edit"}
        warning={editModalData as Warning}
        openModal={openEditModal}
        handleCloseModal={() => setOpenEditModal(false)}
      />
    </SectionContainer>
  );
};

export default Warnings;
