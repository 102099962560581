import { FileStorageExtra } from "src/components/Builders/Container/ItemTypes/FileItem";
import { useDeleteFileMutation } from "src/redux/services/FileService";
import { usePdfDownloader } from "src/general/Pdf";
import { useState } from "react";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import FileService from "src/redux/services/FileService";

type FileChipTProps = {
  allFiles: FileStorageExtra[];
  file: FileStorageExtra;
  fileObjectId: string;
  index: number;
  onDelete?: () => void;
};

const FileChip = ({
  allFiles,
  file,
  fileObjectId,
  index,
  onDelete,
}: FileChipTProps) => {
  const [deleteFileMutation] = useDeleteFileMutation();

  const [disabled, setDisabled] = useState(false);

  // To open Delete Confirm Modal
  const [openModal, setOpenModal] = useState(false);

  const { downloadPdf, isFetchingPdf } = usePdfDownloader(
    () => FileService.getFile(file._id, fileObjectId),
    file.originalName,
  );

  const isNotSave = !file._id;

  return (
    <>
      <Tooltip
        title={isNotSave ? "Pdf needs to be saved before you can open it" : ""}
      >
        <div>
          <Chip
            clickable={!(isFetchingPdf || disabled)}
            color="primary"
            disabled={isFetchingPdf || disabled || isNotSave}
            label={file.originalName}
            onClick={downloadPdf}
            onDelete={() => (onDelete ? onDelete() : setOpenModal(true))}
            variant="outlined"
          />
        </div>
      </Tooltip>

      {openModal && (
        <DeletionModal
          dataType="file"
          deleteData={{ _id: file?._id, name: file.originalName }}
          deleteDataFunction={async () => {
            let newFiles = [...allFiles];
            if (fileObjectId) {
              setDisabled(true);
              await deleteFileMutation({
                id: newFiles[index]._id,
                objectId: fileObjectId,
              });
            }
          }}
          openModal={openModal}
          setIsOpenDeleteModal={setOpenModal}
        />
      )}
    </>
  );
};

export default FileChip;
