import { useCreateContractMutation } from "src/redux/services/ContractService";
import ContractCreateBase from "src/components/Screens/ContractCreateBase/ContractCreateBase";
import { useParams } from "react-router-dom";

const ContractCreateFromOfferId = () => {
  const createMutation = useCreateContractMutation();
  const { contractOfferId } = useParams<{ contractOfferId?: string }>();

  return (
    <ContractCreateBase
      contractOfferId={contractOfferId}
      createMutation={createMutation}
      creationCategory="contract"
      rerouteUrlOnSuccess="/app/contracts"
    />
  );
};

export default ContractCreateFromOfferId;
