import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import {
  CompanyConfig,
  CompanyConfigGet,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

export const getStructure: UseFormContainerGetStructure<CompanyConfigGet> = ({
  setFormData,
}) => {
  const structure: StructureContainer<CompanyConfigGet> = {
    items: [
      {
        type: "text_input",
        dataName: "integration.byggkollen.apiKey",
        label: "Byggkollen api key",
        setFormData,
      },
      {
        type: "text_input",
        dataName: "integration.byggkollen.clientId",
        label: "Byggkollen client id",
        setFormData,
      },
    ],
  };
  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: CompanyConfigGet;
  initData?: CompanyConfigGet;
}) => {
  if (!data) return;

  const newData = clone(data);

  const partialData = toUpdateData<Partial<CompanyConfig>>({
    data: newData,
    initData,
  });

  return partialData;
};
