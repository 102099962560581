import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { getCommonCostTableColumns } from "src/screens/App/Contracts/[contractId]/Costs/utils/getCommonCostTableColumns";
import { Cost } from "src/accurasee-backend-types/app/cost/cost.types";
import { Subcontract } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import getSelectOptions from "../../../utils/getSelectOptions";

export interface GetStructureExtraProps {
  articles: Article[];
  fileDownloading: any;
  formData?: Cost[];
  selectedRows?: Cost[];
  setFileDownloading: (data: String[]) => void;
  setSelectedRows?: (data: Cost[]) => void;
  subcontracts: Subcontract[];
}

export const getStructure: UserFormTableGetStructure<
  Cost,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const commonItems = getCommonCostTableColumns(
    extraProps?.articles || [],
    extraProps?.setFileDownloading!,
    extraProps?.fileDownloading,
    t,
  );
  return {
    items: [
      ...commonItems.filter((item) => item.type !== "icon_button"),
      {
        type: "text_currency",
        currencyCode: "SEK", // TODO: Update
        dataName: "value",
        headerLabel: "Total cost",
      },
      {
        headerLabel: "Assign to subcontract",
        type: "autocomplete",
        dataName: "subcontractId",
        label: "Country",
        alignColumnContent: "center",
        sx: { minWidth: "130px" },
        options: getSelectOptions({
          data: extraProps?.subcontracts,
          value: "_id",
          label: (sc) => sc.number + " - " + sc.name,
        }),
        // })extraProps?.subcontracts?.map((sc) => {
        //   return {
        //     label: sc.number + " - " + sc.name,
        //     value: sc._id,
        //   };
        // }),
        setFormData,
      },
      ...commonItems.filter((item) => item.type === "icon_button"),
    ],
  };
};

export default getStructure;

export const toSubmitData = ({ data }: { data?: Partial<Cost>[] }) => {
  const submitData = data?.map(({ subcontractId, _id, ...rest }) => {
    return {
      _id,
      subcontractId,
      costParent: "alterationAndAdditionalWork",
    } as Partial<Cost>;
  });

  return submitData;
};
