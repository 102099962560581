import { getNestedObjectData } from "src/utils/nestedData";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getValue from "src/components/Builders/Container/Utils/getValue";

export interface NewPasswordInputProps {
  newPassword: string;
}

export const getStructure: UseFormContainerGetStructure<
  Partial<NewPasswordInputProps>
> = ({ setFormData }) => {
  const structure: StructureContainer<Partial<NewPasswordInputProps>> = {
    items: [
      {
        type: "text_input",
        dataName: "newPassword",
        label: "Password",
        required: true,
        textInputType: "password",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
      },
      {
        type: "text_input",
        dataName: "confirmPassword",
        label: "Confirm password",
        required: true,
        textInputType: "password",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
        validate: (props: any) => {
          const confirmPassword = getValue(props);
          if (!confirmPassword) return true;

          const newPassword = getNestedObjectData({
            data: props.data,
            key: "newPassword",
          });

          return confirmPassword === newPassword;
        },
        getErrorText: () =>
          "Your confirmation password does not match the new password",
      },
    ],
  };
  return structure;
};
