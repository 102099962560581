import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

type UseMUICustomToolBarOptions = {
  actionFunction: () => void;
  buttonType?: "button" | "icon";
  buttonElement?: React.ReactNode;
  showButton: boolean;
  title: string;
};

export const CustomToolbarButtonWithTooltip = (
  props: UseMUICustomToolBarOptions,
) => {
  const [t] = useTranslation();

  return props.showButton ? (
    <>
      <Tooltip title={props.title}>
        {props?.buttonType === "icon" && props?.buttonElement ? (
          <>{props.buttonElement}</>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            disableRipple
            onClick={props.actionFunction}
          >
            {t(props.title)}
          </Button>
        )}
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
