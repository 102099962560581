/**
 *Created by Mikael Lindahl on 2024-09-04
 */

import React from "react";
import useTranslation from "src/hooks/useTranslationWrapper";
import ConsentDialog from "../../Mixed/Dialogs/ConsentDialog";

type PromptDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
};

const PromptDialog: React.FC<PromptDialogProps> = ({
  open,
  onClose,
  title,
  message,
}) => {
  const [t] = useTranslation();

  return (
    <ConsentDialog
      title={title}
      consentButtons={[
        {
          label: "Close",
          onClick: onClose,
        },
      ]}
      maxWith={"sm"}
      open={open}
    >
      {t(message)}
    </ConsentDialog>
  );
};

export default PromptDialog;
