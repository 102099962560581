import { useCreateContractOfferMutation } from "src/redux/services/ContractOfferService";
import ContractCreateBase from "src/components/Screens/ContractCreateBase/ContractCreateBase";

const ContractOfferCreate = () => {
  const createMutation = useCreateContractOfferMutation();

  return (
    <ContractCreateBase
      creationCategory="contract_offer"
      rerouteUrlOnSuccess="/app/contract-offers/overview"
      createMutation={createMutation}
    />
  );
};
export default ContractOfferCreate;
