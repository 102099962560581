import BuildItems from "../../Builders/Container/BuildItems";
import useFormContainer from "../../../hooks/useFormContainer";
import { Grid } from "@mui/material";
import { MUIDataTableColumn, MUIDataTableState } from "mui-datatables";
import { toDate } from "src/utils/date";
import { useEffect } from "react";
import {
  ItemsTypes,
  getStructure,
  GetStructureExtraProps,
} from "./FilterCreateContainerGetStructure";

export type minMaxValue = {
  isThisMonth?: "false" | "true";
  minDate?: string | undefined;
  maxDate?: string | undefined;
  minNumber?: number | undefined;
  maxNumber?: number | undefined;
};

type FilterCreateContainerTProps = {
  column: MUIDataTableColumn;
  filterList: MUIDataTableState["filterList"];
  filterName: string;
  index: number;
  initialData: minMaxValue;
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn,
  ) => void;
  type: "date" | "number";
};

export default function FilterCreateContainer(
  props: FilterCreateContainerTProps,
) {
  const {
    formData,
    helperText,
    isFormValid,
    isValidatingData,
    // setFormData,
    // setHelperText,
    structure,
  } = useFormContainer<minMaxValue, GetStructureExtraProps, ItemsTypes>({
    extraProps: {
      column: props.column,
      filterList: props.filterList,
      filterName: props.filterName,
      index: props.index,
      onChange: props.onChange,
      type: props.type,
    },
    initialFormData: props.initialData,
    getStructure,
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  useEffect(() => {
    if (isFormValid && !isValidatingData) {
      const newFilterList = [...props.filterList];
      const newInnerArray = [...newFilterList[props.index]];
      if (props.type === "number") {
        if (formData?.minNumber) {
          newInnerArray[0] = formData.minNumber.toString();
        }
        if (formData?.maxNumber) {
          newInnerArray[1] = formData.maxNumber.toString();
        }
      } else {
        newInnerArray[0] = toDate(formData?.minDate) || "";
        newInnerArray[1] = toDate(formData?.maxDate) || "";
        newInnerArray[2] = formData?.isThisMonth || "false";
      }

      newFilterList[props.index] = newInnerArray;
      props.onChange(newFilterList[props.index], props.index, props.column);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isValidatingData]);

  return (
    <Grid container spacing={2}>
      {BuildItems({
        items: structure.items.filter((item) => item.itemType === props.type),
        ...commonPropsBuildItem,
      })}
    </Grid>
  );
}
