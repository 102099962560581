import { CommonCallbackProps } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { DimensionExtra } from "src/redux/services/DimensionService";
import { OnSubmit } from "src/hooks/useOnSubmit";
import { escape } from "src/utils/translate";
import { ReportTemplateYFilter } from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import clone from "src/utils/clone";

export type GetStructureExtraProps = {
  dimensions?: DimensionExtra[];
  onDelete: (props: CommonCallbackProps) => void;
  onEdit: (props: CommonCallbackProps) => void;
  onSubmitActiveSwitch: OnSubmit;
};
export const getStructure: UserFormTableGetStructureOptional<
  ReportTemplateYFilter,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "label",
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "dimensions",
        headerLabel: "Dimensions",
        getValue: ({ data, rowIndex }) => {
          const results = data[rowIndex]?.dimensions?.map((dimension) => {
            const main = props?.extraProps?.dimensions?.find(
              (e) => e?._id === dimension.dimensionId,
            );
            const item = main?.items?.find(
              (i) => i._id === dimension.dimensionItemId,
            );
            return `${main?.name} - ${item?.name}`;
          });
          return results?.join(", ");
        },
      },
      {
        type: "badge",
        dataName: "accountNumberSeries",
        headerLabel: "First number - Last number (s)",
        getValue: ({ data, rowIndex }) =>
          data[rowIndex]?.accountNumberSeries?.map((series) =>
            escape(`${series.firstNumber} - ${series.lastNumber}`),
          ),
        selectionOptions: { multiple: true },
      },
      {
        type: "switch",
        dataName: "active",
        headerLabel: "Active/Inactive",
        gridProps: { md: 12 },
        setFormDataCustom: async (propsCustom) => {
          let newData = clone(propsCustom.data);
          newData[propsCustom.rowIndex].active = propsCustom.value;
          if (props?.setFormData) {
            props?.setFormData(newData);
          }
          if (props?.extraProps?.onSubmitActiveSwitch) {
            props?.extraProps?.onSubmitActiveSwitch({
              dataId: String(newData[propsCustom.rowIndex]._id),
              data: { update: { active: propsCustom.value } },
            });
          }
        },
        alignColumnContent: "center",
      },
      {
        type: "action_row",
        iconType: "edit",
        dataName: "edit",
        alignColumnContent: "end",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEdit) {
                props?.extraProps?.onEdit(onClickProps);
              }
            },
          },
          {
            elementType: "icon",
            iconType: "delete_no_cross",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onDelete) {
                props?.extraProps?.onDelete(onClickProps);
              }
            },
          },
        ],
      },
    ],
  };
};
