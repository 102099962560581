import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      width: "100%",
      textDecoration: "none",
      color: "var(--gray-11)",
    },
    externalLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none",
    },
    itemButton: {
      display: "flex",
      columnGap: "var(--space-12px)",
      margin: "0 var(--space-8px)",
      borderRadius: "8px",
      "&:hover, &:active": {
        backgroundColor: "var(--green-10)",
      },
    },
    linkActive: {
      backgroundColor: "var(--green-10)",
      "&::before": {
        backgroundColor: "var(--green-1)",
        width: "4px",
        position: "absolute",
        top: "12px",
        bottom: "12px",
        left: "0px",
        borderRadius: "0 4px 4px 0",
        content: '""',
      },
    },
    linkNested: {
      "&:hover": {
        backgroundColor: "var(--green-10)",
      },
      color: "var(--green-1)",
      fontWeight: "normal",
    },
    linkIcon: {
      color: "var(--gray-5)",
      transition: theme.transitions.create("color"),
      width: 24,
      minWidth: 24,
      display: "flex",
      justifyContent: "center",
    },
    linkIconActive: {
      color: "var(--green-1)",
    },
    linkText: {
      padding: 0,
      color: "var(--gray-11)",
      transition: theme.transitions.create(["opacity", "color"]),
      fontSize: "14px",
    },
    linkTextActive: {
      color: "var(--green-1)",
      fontWeight: "bold !important",
    },
    linkTextHidden: {
      opacity: 0,
    },
    linkNoMargin: {
      margin: 0,
    },
    nestedList: {
      marginLeft: "var(--space-36px)",
      borderLeft: "2px solid var(--gray-2)",
    },
    sectionTitle: {
      marginLeft: theme.spacing(4.5),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    expandMore: {
      color: "var(--gray-11)",
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandMoreActive: {
      color: "var(--green-1)",
    },
    expandMoreOpen: {
      transform: "rotate(90deg)",
    },
  }),
);

export default useStyles;
