/**
 *Created by Mikael Lindahl on 2024-09-11
 */
import getGroupDelimiter from "../../../utils/getGroupDelimiter";

export type FormatValueDisplayedCurrency = {
  forward: (value: any, currencyCode?: string) => any;
  reversed: (value: any, currencyCode?: string) => any;
};

const formatValueDisplayedCurrency: FormatValueDisplayedCurrency = {
  forward: (value, currencyCode) => {
    // Split the string into chunks of 3 characters and join with spaces
    const valueAsString = String(value).split("").reverse().join("");
    const chunks = [];
    for (let i = 0; i < valueAsString.length; i += 3) {
      chunks.push(valueAsString.substring(i, i + 3));
    }

    const delimiter = getGroupDelimiter(currencyCode);

    return chunks.join(delimiter).split("").reverse().join("");
  },
  reversed: (value, currencyCode) => {
    const delimiter = getGroupDelimiter(currencyCode);
    return value.split(delimiter).join("");
  },
};

export default formatValueDisplayedCurrency;
