import Accounting from "./Components/Accounting";
import Page from "../../../../components/Basic/Mixed/Pages/Page";
import ApiKeysCard from "./Api/ApiKeysCard";
import IntegrationImports from "./Components/IntegrationImports";
import {
  useGetIntegrationConfigQuery,
  useOauthMutation,
} from "../../../../redux/services/IntegrationService";

export function Integrations() {
  const getIntegrationConfigQuery = useGetIntegrationConfigQuery(undefined);

  const accounting = getIntegrationConfigQuery?.data?.accounting;

  const oauthMutation = useOauthMutation();

  return (
    <Page
      label={"Integrations"}
      breadcrumbs={[{ label: "Setup" }, { label: "Integrations" }]}
    >
      <Accounting
        getIntegrationConfigQuery={getIntegrationConfigQuery}
        oauthMutation={oauthMutation}
      />
      {accounting?.authorized &&
        !oauthMutation[1].error &&
        !accounting?.reAuthNeeded && <IntegrationImports />}
      <ApiKeysCard />
    </Page>
  );
}

export default Integrations;
