import {
  addRanges,
  getSeriesStructure,
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./ReportFilterModalStructure";
import {
  AccountNumberSeries,
  ReportTemplateYFilter,
  TransactionTypesValues,
} from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import {
  useCreateReportTemplateYFilterMutation,
  useUpdateReportTemplateYFilterMutation,
} from "src/redux/services/ReportTemplateFilterService";
import { Box, IconButton } from "@mui/material";
import { EditRights } from "src/accurasee-backend-types/app/general/editrights.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BadgeGreen from "src/components/Basic/Simple/Badges/BadgeGreen";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";

interface ReportTemplateModalWithFormProps {
  transactionType?: TransactionTypesValues;
  handleCloseModal: () => void;
  labels: string[];
  openModal: boolean;
  permissions: EditRights["permissions"];
  row?: ReportTemplateYFilter;
}

const ReportTemplateModalWithForm = ({
  transactionType,
  handleCloseModal,
  labels,
  openModal,
  permissions,
  row,
}: ReportTemplateModalWithFormProps) => {
  const [t] = useTranslation();

  // dimensions
  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const [createReportTemplateYFilter] =
    useCreateReportTemplateYFilterMutation();
  const [updateReportTemplateYFilter] =
    useUpdateReportTemplateYFilterMutation();

  const initialData = transactionType
    ? { label: "", transactionType, active: true }
    : {
        ...row,
        active: true,
        dimensionItemIds: row?.dimensions?.map(
          (d) => `${d.dimensionId}.${d.dimensionItemId}`,
        ),
      };

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<ReportTemplateYFilter>, GetStructureExtraProps>({
    getStructure,
    extraProps: { dimensions, labels },
    initialFormData: initialData,
  });

  const commonPropsBuildItem = {
    id: "report-filter",
    data: formData,
    helperText,
  };

  // For firstNumber and lastNumber
  const initialFormData = row?.accountNumberSeries.map(
    ({ firstNumber, lastNumber }) => ({ firstNumber, lastNumber }),
  );
  const {
    formData: formDataRanges,
    helperText: helperTextRanges,
    isFormValid: isFormValidRanges,
    setFormData: setFormDataRanges,
    setHelperText: setHelperTextRanges,
    structure: structureRanges,
    setHasTriedToSubmit: setHasTriedToSubmitRanges,
  } = useFormTable<Partial<AccountNumberSeries>>({
    initialFormData,
    getStructure: getSeriesStructure,
  });

  const columns: MUIDataTableColumnDef[] = structureRanges.items.map((item) =>
    BuilderColumnOptionsSingle<Partial<AccountNumberSeries>>({
      data: formDataRanges,
      item,
      helperText: helperTextRanges,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formDataRanges.length >= 50,
  });
  const submitData = toSubmitData({
    data: {
      ...formData,
      accountNumberSeries: formDataRanges as AccountNumberSeries[],
    },
    initData: {
      ...initialData,
      accountNumberSeries: initialFormData as AccountNumberSeries[],
    },
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createReportTemplateYFilter,
        update: updateReportTemplateYFilter,
      },
      data: {
        create: submitData,
        update: submitData,
      },
      dataId: String(row?._id) || "",
      name: "report filter",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setHasTriedToSubmitRanges(false);
      setFormData(initialData);
      handleCloseModal();
    },
  });

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={transactionType ? "Add filter" : "Edit filter"}
      labelBadge={
        <BadgeGreen text={transactionType === "cost" ? "cost" : "revenue"} />
      }
      alignTextCenter={false}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit({
              action: transactionType ? "create" : "update",
            });
          }}
          ref={refForm}
        >
          <SectionContainer rowSpacing={2} isLoading={isLoadingDimensions}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
            <MuiDataTableWrapper
              tableType="main"
              title=""
              data={formDataRanges}
              columns={columns}
              options={options}
            />
          </SectionContainer>
          <IconButton
            disableRipple
            color="primary"
            onClick={() =>
              addRanges({
                formData: formDataRanges,
                helperText: helperTextRanges,
                setFormData: setFormDataRanges,
                setHelperText: setHelperTextRanges,
              })
            }
          >
            <AddCircleIcon />
            <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
              {t("Add row")}
            </span>
          </IconButton>
          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonCancel
                onClick={() => {
                  setHasTriedToSubmit(false);
                  setHasTriedToSubmitRanges(false);
                  setFormData(initialData);
                  handleCloseModal();
                }}
              />
              <ButtonCreateOrUpdate
                initialSubmitData={{}}
                isSubmitting={isSubmitting}
                isValid={isFormValid && isFormValidRanges}
                label={transactionType ? "create" : "update"}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                  setHasTriedToSubmitRanges(true);
                }}
                permissions={permissions}
                submitData={submitData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default ReportTemplateModalWithForm;
