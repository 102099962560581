/**
 *Created by Mikael Lindahl on 2023-04-19
 */

import { Article } from "../../accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "../../accurasee-backend-types/app/customer/customer.types";
import { StructureItemTable } from "../../components/Builders/Table/CommonBuilderTableTypes";
import calculateDependable from "src/accurasee-backend-types/shared/utils/calculateDependable";
import calculateVat from "src/utils/calculateVat";

export const setFormDataCustomArticleId = <DataType = any>({
  articles,
  customer,
  isMilestone,
  setFormData,
}: {
  articles: (ContractArticle | Article)[] | undefined;
  customer: Customer | undefined;
  isMilestone?: boolean;
  setFormData: (data: DataType[]) => void;
}) => {
  const setFormDataCustom: StructureItemTable["setFormDataCustom"] = (
    customProps,
  ) => {
    const article = articles?.find((a) => String(a._id) === customProps.value);
    const articleDescriptions = articles?.map((a) => a.name) || [];

    let newFormData = [...customProps.data];
    if (customProps.value === undefined || customProps.value === null) {
      newFormData[customProps.rowIndex] = {
        ...newFormData[customProps.rowIndex],
        articleId: customProps.value,
        articleNumber: undefined,
        houseWork: false,
        houseWorkType: "None",
        invoicingPeriod: undefined,
        isPricePerUnitEstimate: false,
        isQuantityEstimate: false,
        percentVAT: calculateVat(
          article?.vat === undefined ? 0.25 : article.vat,
          customer,
        ),
        textRow: true,
        pricePerUnit: 0,
        quantity: 0,
        total: 0,
        totalExcludingVAT: 0,
        discount: 0,
      };
    } else {
      let pricePerUnit = newFormData[customProps.rowIndex].pricePerUnit;
      let isPricePerUnitEstimate =
        newFormData[customProps.rowIndex].isPricePerUnitEstimate;

      let quantity = newFormData[customProps.rowIndex].quantity;
      let isQuantityEstimate =
        newFormData[customProps.rowIndex].isQuantityEstimate;

      // Use contract article data if it exists
      if ((article as ContractArticle).articleId) {
        pricePerUnit = (article as ContractArticle).price || pricePerUnit;
        isPricePerUnitEstimate = (article as ContractArticle)
          ?.isPricePerUnitEstimate
          ? (article as ContractArticle)?.isPricePerUnitEstimate
          : isPricePerUnitEstimate;

        quantity = (article as ContractArticle).quantity || quantity;
        isQuantityEstimate = (article as ContractArticle)?.isQuantityEstimate
          ? (article as ContractArticle)?.isQuantityEstimate
          : isQuantityEstimate;
      }

      // let itemDescription = article ? article.name || "" : "";
      let itemDescription = articleDescriptions.includes(
        customProps.data[customProps.rowIndex].itemDescription,
      )
        ? article?.name || ""
        : customProps.data[customProps.rowIndex].itemDescription;
      // Eligible milestone for Invoice plan but when it has been created as Planned invoice
      if (isMilestone) {
        // Never use article data on quantity, prices per unit values and estimate for milestone
        quantity = isMilestone ? 1 : quantity;
        isQuantityEstimate = isMilestone ? false : isQuantityEstimate;
        pricePerUnit = isMilestone ? 0 : pricePerUnit;
        isPricePerUnitEstimate = isMilestone ? false : isPricePerUnitEstimate;
        itemDescription =
          customProps.data[customProps.rowIndex].itemDescription;
      }

      newFormData[customProps.rowIndex] = {
        ...newFormData[customProps.rowIndex],
        articleId: customProps.value,
        articleNumber: article?.number,
        isPricePerUnitEstimate,
        isQuantityEstimate,
        itemDescription,
        percentVAT: calculateVat(
          article?.vat === undefined ? 0.25 : article.vat,
          customer,
        ),
        pricePerUnit,
        quantity,
        textRow: !Boolean(customProps.value),
        unitId: article?.unitId,
        unitExternalId: article?.unitExternalId,
      };

      newFormData[customProps.rowIndex] = {
        ...newFormData[customProps.rowIndex],
        ...calculateDependable({
          data: newFormData[customProps.rowIndex],
        }),
      };
    }
    setFormData(newFormData);
  };

  return setFormDataCustom;
};
