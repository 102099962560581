/**
 *Created by Mikael Lindahl on 2024-07-11
 */
import { TFunction } from "../hooks/useTranslationWrapper";
import getSelectOptions from "./getSelectOptions";

const getSelectOptionsAutomaticManual = (t: TFunction) => {
  return getSelectOptions({
    data: [
      {
        label: "Automatic",
        value: "automatic",
      },
      {
        label: "Manual",
        value: "manual",
      },
    ].map((c) => ({ label: t(c.label), value: c.value })),
  });
};

export default getSelectOptionsAutomaticManual;
