import {
  ApiKeyInformation,
  ApiKeyReqeust,
} from "src/accurasee-backend-types/app/company/company.types";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import getValue from "src/components/Builders/Container/Utils/getValue";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getSelectOptions from "../../../../../utils/getSelectOptions";

export interface GetStructureExtraProps {
  apiKeys: ApiKeyInformation[];
  languages: Language[];
}

export const getStructure: UseFormContainerGetStructure<
  ApiKeyReqeust,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<ApiKeyReqeust> = {
    items: [
      {
        dataName: "description",
        validate: (props) => {
          const value: string = getValue(props);
          const lowerApiNames = extraProps?.apiKeys
            .filter((key) => key.description)
            .map((key) => key?.description?.toLowerCase());
          const nameNotUnique = lowerApiNames?.includes(value?.toLowerCase());
          return !(value && value.length < 3) && !nameNotUnique;
        },
        getErrorText: (props) => {
          const value: string = getValue(props);
          const lowerApiNames = extraProps?.apiKeys
            .filter((key) => key.description)
            .map((key) => key?.description?.toLowerCase());

          const nameNotUnique = Boolean(
            lowerApiNames?.includes(value?.toLowerCase()),
          );
          if (value.length < 3) {
            return "Should be longer than three letters";
          } else if (nameNotUnique) {
            return "Name needs to be unique";
          } else {
            return "Other error";
          }
        },
        required: true,
        gridProps: { md: 2 },
        type: "text_input",
        label: "Description",
        setFormData,
      },
      {
        dataName: "email",
        validate: (props) => {
          const value = getValue(props);
          return !(value && value.length < 3);
        },
        required: true,
        type: "text_input",
        label: "Email",
        setFormData,
        gridProps: { md: 2 },
      },
      {
        dataName: "locale",
        required: true,
        gridProps: { md: 2 },
        type: "autocomplete",
        label: "Language",
        options: getSelectOptions({
          data: extraProps?.languages,
          // ?.filter((lang) => lang.isActive)
          label: (l) => l.name,
          value: (l) => l.code,
        }),
        setFormData,
      },
    ],
  };
  return structure;
};
