import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ContractOffers from "src/screens/App/ContractOffers/ContractOffers";
import Page from "src/components/Basic/Mixed/Pages/Page";
import TabWithLinksTransparent from "src/components/Basic/Mixed/Tabs/TabWithLinksTransparent";
import usePathPermission from "src/hooks/usePathPermission";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { TabsType } from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import {
  useGetContractOffersActiveQuery,
  useGetContractOffersArchivedQuery,
} from "src/redux/services/ContractOfferService";
import { useLocation, useNavigate } from "react-router-dom";

const RoutesContractOffersTab = () => {
  const navigate = useNavigate();
  const pathPermissions = usePathPermission("/app/contract-offers/create");

  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);
  const contractId = urlQuery.get("contractId");
  const contractOfferId = urlQuery.get("contractOfferId");
  const familyId = urlQuery.get("familyId");

  const url = "/app/contract-offers";
  const path = "/app/contract-offers";
  const pathToStrip = "/app/contract-offers";
  const redirectPath: RoutePath = "/app/contract-offers/active";
  const getMainRouteUrl = (path: RoutePath) => path;

  const tabs: TabsType[] = [
    {
      name: "Active offers",
      mainRoute: {
        component: (
          <ContractOffers
            familyId={familyId}
            query={useGetContractOffersActiveQuery}
          />
        ),
        path: "/app/contract-offers/active",
      },
    },
    {
      name: "Archived offers",
      mainRoute: {
        component: (
          <ContractOffers
            familyId={familyId}
            query={useGetContractOffersArchivedQuery}
          />
        ),
        path: "/app/contract-offers/archived",
      },
    },
  ];

  const breadcrumbs = [{ label: "Contract offers" }];

  return (
    <Page
      label={"Contract offer list"}
      breadcrumbs={breadcrumbs}
      boxRight={
        <ButtonGroup>
          {contractId && (
            <Link component={RouterLink} to={`/app/contracts/${contractId}`}>
              Back to Contract
            </Link>
          )}
          {contractOfferId && (
            <Link
              component={RouterLink}
              to={`/app/contract-offers/overview/${contractOfferId}`}
            >
              Back to Contract offer
            </Link>
          )}
          <ButtonAddBoxIcon
            id="direct-offer-create-page"
            permissions={pathPermissions}
            label="Create offer"
            onSubmit={() => navigate("/app/contract-offers/create")}
          />
        </ButtonGroup>
      }
    >
      <TabWithLinksTransparent
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name="contract-offers"
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
      />
    </Page>
  );
};

export default RoutesContractOffersTab;
