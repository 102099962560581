import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const developerTypeList = ["integration_import"] as const;
type DeveloperType = (typeof developerTypeList)[number];

export type DeveloperState = {
  debug: Partial<Record<DeveloperType, boolean>>;
  showTestData: Partial<Record<DeveloperType, boolean>>;
};

export const initialDeveloperState = {
  debug: {},
  showTestData: {},
} as DeveloperState;

const DeveloperStateSlice = createSlice({
  name: "DeveloperState",
  initialState: initialDeveloperState,
  reducers: {
    updateDeveloperState(state, action: PayloadAction<DeveloperState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateDeveloperState } = DeveloperStateSlice.actions;
export default DeveloperStateSlice.reducer;
