/**
 *Created by Mikael Lindahl on 2024-01-30
 */
import CustomerInvoiceTable from "../../Complex/Tables/Invoices/CustomerInvoicesTable";
import { useParams } from "react-router-dom";

const CustomerInvoices = () => {
  let { contractId } = useParams<{ contractId: string }>();

  const customerInvoiceFilter = {
    sortBy: "invoiceNumber",
    sort: "desc",
    pageSize: 15,
    ...(!contractId
      ? { status: ["exported", "invoiced", "invoicedoverdue", "fullypaid"] }
      : { contractId }),
  };

  return (
    <CustomerInvoiceTable
      customerInvoiceFilter={customerInvoiceFilter}
      includeProjectNumber={!contractId}
      useColumns={[
        ...[
          "ownerIds",
          "customerName",
          "dueDate",
          "invoiceDate",
          "invoiceNumber",
          "status",
          "total",
          "totalExcludingVAT",
          "totalVAT",
        ],
        ...(contractId ? ["name"] : ["projectExternalId"]),
      ]}
      redirectFoundationPath=""
    />
  );
};

export default CustomerInvoices;
