import { GET_FILE } from "../actions/types";

const FileDownload = require("js-file-download");

const initialState = {
  file: {},
};

const fileReducer = (file = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FILE:
      FileDownload(payload.blob, payload.file.originalName);
      return file;
    default:
      return file;
  }
};

export default fileReducer;
