import { useEffect, useState } from "react";
import { MUIDataTableColumnDef } from "mui-datatables";

import {
  useGetCompanyUsersQuery,
  useGetUserQuery,
} from "src/redux/services/UserService";
import { useGetUserRolesQuery } from "src/redux/services/UserRolesService";

import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";

import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";

import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";

import EditUserModalWithForm from "./EditUserModalWithForm";
import SectionTight from "../../../../../components/Basic/Mixed/Sections/SectionTight";
import sort from "../../../../../utils/sort";

export const UsersTable = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editUser, setEditUser] = useState<ReturnUser | undefined>(undefined);

  const { data: userResponse, isLoading: isLoadingUser } =
    useGetUserQuery(undefined);
  const user = userResponse?.user;
  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery({
      sortBy: "lastName",
      sort: "asc",
    });
  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRolesQuery({ undefined });

  const users = usersResponse?.data || [];
  const userRoles = userRolesResponse?.data || [];

  useEffect(() => {
    if (editUser) {
      setEditUser(users.find((e) => e._id === editUser._id));
    }
  }, [users]);

  const usersTableStructure: {
    items: StructureItemTable<ReturnUser>[];
  } = {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
        getValue: ({ data, rowIndex }) => {
          return data[rowIndex]?.firstName + " " + data[rowIndex]?.lastName;
        },
      },
      {
        type: "text",
        dataName: "email",
        headerLabel: "Email",
      },
      {
        type: "text",
        dataName: "companyRoleIds",
        headerLabel: "Roles",
        getValue: ({ data, rowIndex }) => {
          const roles = sort(
            data[rowIndex]?.companies[String(user?.activeCompanyId)]?.roleIds
              ?.map((roleId) => userRoles?.find((e) => e._id === roleId)?.name)
              .filter((e) => e) || [],
          );

          return roles?.join(", ") || " ";
        },
      },
      {
        type: "text_with_prefix_bullet",
        dataName: "active",
        headerLabel: "",
        sort: true,
        getValue: ({ data, rowIndex }) =>
          user?.activeCompanyId &&
          data[rowIndex]?.companies[String(user.activeCompanyId)]?.active
            ? "Active"
            : "Deactivated",
      },
      {
        dataName: "isSuperAdmin",
        headerLabel: "Superadmin",
        iconType: "check",
        type: "icon",
        alignColumnContent: "center",
        showCellWhen: ({ data, rowIndex }) => {
          return !!data[rowIndex]?.isSuperAdmin;
        },
        showColumnWhen: () => {
          return !!user?.isSuperAdmin;
        },
      },
      {
        dataName: "edit",
        headerLabel: "",
        iconType: "edit",
        permissionType: "updateRights",
        type: "icon_button",
        onClick: async ({ data, rowIndex }) => {
          setEditUser(data[rowIndex]);
          setOpenEditModal(true);
        },
      },
    ],
  };

  const { options } = useMUIDataTableOptions({
    defaultPageSize: 15,
    defaultPageSizeOptions: [10, 15, 50],
    pagination: true,
  });

  const usersColumns: MUIDataTableColumnDef[] = usersTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle({
        data: users,
        disabled: true,
        item,
      }),
  );

  return (
    <SectionTight
      containsTable
      label={"Users"}
      isLoading={isLoadingUser || isLoadingUsers || isLoadingUserRoles}
    >
      <MuiDataTableWrapper
        data={users}
        columns={usersColumns}
        options={options}
        tableType={"compact"}
      />
      <EditUserModalWithForm
        userRoles={userRoles}
        data={editUser}
        handleCloseModal={() => setOpenEditModal(false)}
        openModal={openEditModal}
      />
    </SectionTight>
  );
};

export default UsersTable;
