/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import { CommonCallbackProps } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { AccessUserRoleWithPermissions } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { OnSubmit } from "src/hooks/useOnSubmit";
import clone from "../../../../../../../utils/clone";

export type GetStructureExtraProps = {
  clickedRowIndex?: number;
  isFetching: boolean;
  onDelete: (props: CommonCallbackProps) => void;
  onEdit: (props: CommonCallbackProps) => void;
  onEditPermissions: (props: CommonCallbackProps) => void;
  onSubmitActiveSwitch: OnSubmit;
  setClickedRowIndex: (value: number | undefined) => void;
};
export const getStructure: UserFormTableGetStructureOptional<
  AccessUserRoleWithPermissions,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "description",
        headerLabel: "Description",
      },
      {
        type: "switch",
        disabled: ({ data, rowIndex }) =>
          !data[rowIndex].permissions.updateRights,
        dataName: "active",
        headerLabel: "Active/Inactive",
        gridProps: { md: 12 },
        setFormDataCustom: (propsCustom) => {
          let newData = clone(propsCustom.data);
          newData[propsCustom.rowIndex][propsCustom.item.dataName as string] =
            propsCustom.value;
          if (props?.setFormData) {
            props?.setFormData(newData);
          }
          if (props?.extraProps?.onSubmitActiveSwitch) {
            props?.extraProps?.onSubmitActiveSwitch({
              data: { update: { active: propsCustom.value } },
              dataId: String(newData[propsCustom.rowIndex]._id),
            });
          }
        },
        alignColumnContent: "center",
      },
      {
        type: "action_row",
        iconType: "edit",
        dataName: "edit",
        alignColumnContent: "end",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEdit) {
                props?.extraProps?.onEdit(onClickProps);
              }
            },
            permissionType: "updateRights",
          },
          {
            elementType: "icon",
            iconType: "delete_no_cross",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onDelete) {
                props?.extraProps?.onDelete(onClickProps);
              }
            },
            permissionType: "deleteRights",
          },
          {
            elementType: "button_secondary",
            buttonIsFetching: props?.extraProps?.isFetching,
            clickedRowIndex: props?.extraProps?.clickedRowIndex,
            buttonText: "Edit role's permission",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEditPermissions) {
                props?.extraProps?.setClickedRowIndex(onClickProps.rowIndex);
                props?.extraProps?.onEditPermissions(onClickProps);
              }
            },
            permissionType: "updateRights",
          },
        ],
      },
    ],
  };
};
