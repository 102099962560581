import {
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import { backendApi } from "../api";
import { docListTags } from "./utils";

export const CACHE_TYPE = "Reports";
export const CACHE_TYPE_BUDGET = "BudgetReports";

export const reportsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<
      {
        columns: ReportGetResponseColumn[];
        data: ReportGetResponseData[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/reports", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getReportNestedData: build.query<
      {
        columns: ReportGetResponseColumn[];
        data: ReportGetResponseData[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/reports/nested", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getBudgetReports: build.query<
      {
        columns: ReportGetResponseColumn[];
        data: ReportGetResponseData[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/reports/budget", params }),
      providesTags: docListTags(CACHE_TYPE_BUDGET),
    }),
    getBudgetReportNestedData: build.query<
      {
        columns: ReportGetResponseColumn[];
        data: ReportGetResponseData[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/reports/budget/nested", params }),
      providesTags: docListTags(CACHE_TYPE_BUDGET),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportsQuery,
  useGetReportNestedDataQuery,
  useGetBudgetReportsQuery,
  useGetBudgetReportNestedDataQuery,
} = reportsApi;

export default reportsApi;
