import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreate from "src/components/Basic/Simple/Buttons/ButtonCreate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { InviteSend } from "src/accurasee-backend-types/app/invite/invite.types";
import { useGetUserRolesQuery } from "src/redux/services/UserRolesService";
import { useSendInviteMutation } from "src/redux/services/InviteService";
import {
  getStructure,
  GetStructureExtraProps,
} from "./CompanyInviteModalStructure";

interface CompanyInviteProps {
  openModal: boolean;
  onClose: () => void;
}

const CompanyInviteModalWithForm = ({
  openModal,
  onClose,
}: CompanyInviteProps) => {
  const [sendInvite] = useSendInviteMutation();

  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useGetUserRolesQuery({ undefined });

  const initialFormData = { inviteType: 1 } as InviteSend;

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<InviteSend, GetStructureExtraProps>({
    initialFormData,
    getStructure,
    extraProps: {
      userRoles: userRoles?.data,
    },
  });

  const submitData = formData;

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: sendInvite,
      },
      data: {
        create: submitData,
      },
      name: "Company invite",
    },
    onSuccess: () => {
      setFormData(initialFormData);
      onClose();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit();
      }}
      ref={refForm}
    >
      <ModalWrapper
        handleCloseModal={() => onClose()}
        openModal={openModal}
        label={"INVITE MEMBER"}
        alignTextCenter={false}
      >
        <SectionContainer isLoading={isLoadingUserRoles}>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </SectionContainer>
        <SectionWithButtonContainer>
          <ButtonGroup>
            <ButtonCancel onClick={() => onClose()} />
            <ButtonCreate
              initialSubmitData={{}}
              isSubmitting={isSubmitting}
              isValid={isFormValid}
              onSubmit={async () => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={submitData}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </ModalWrapper>
    </form>
  );
};

export default CompanyInviteModalWithForm;
