import { backendApi } from "../api";
import {
  docDeleteTags,
  docGetTag,
  docListTags,
  docPatchTag,
  listTag,
} from "./utils";

import {
  EmailTemplate,
  EmailTemplateGetHtml,
} from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";

const CACHE_TAG = "EmailTemplates";

export const emailTemplatesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getEmailTemplates: build.query<{ data: EmailTemplate[] }, any>({
      query: (params) => ({ url: "/superadmin/emailtemplate", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getEmailTemplate: build.query<{ data: EmailTemplate }, any>({
      query: (id) => ({ url: `/superadmin/emailtemplate/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
    getEmailTemplateHtml: build.query<{ data: EmailTemplateGetHtml }, any>({
      query: (id) => ({ url: `/superadmin/emailtemplate/html/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
    createEmailTemplate: build.mutation({
      query: (data) => ({
        url: "/superadmin/emailtemplate",
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    updateEmailTemplate: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/superadmin/emailtemplate/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    deleteEmailTemplate: build.mutation<any, string>({
      query: (id) => {
        return {
          url: `/superadmin/emailtemplate/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: docDeleteTags(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEmailTemplateMutation,
  useGetEmailTemplateQuery,
  useGetEmailTemplateHtmlQuery,
  useGetEmailTemplatesQuery,
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
} = emailTemplatesApi;

export default emailTemplatesApi;
