import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorPage = ({
  redirect,
  redirectLabel,
  title,
  subtitle,
  statusCode,
}: {
  redirect?: string;
  redirectLabel?: string;
  title: string;
  subtitle?: string;
  statusCode: number;
}) => {
  const [t] = useTranslation();

  return (
    <Box sx={{ padding: "32px 48px", textAlign: "center" }}>
      <Typography
        variant="h1"
        style={{
          fontWeight: "700",
          fontSize: "72px",
          color: "var(--black-7)",
          opacity: 0.6,
        }}
      >
        {statusCode}
      </Typography>
      <Typography
        variant="h2"
        style={{
          fontWeight: "700",
          color: "var(--black-7)",
          marginTop: "var(--space-8px)",
          marginBottom: "var(--space-32px)",
        }}
      >
        {t(title)}
      </Typography>
      {/* Subtitle Section */}
      {subtitle && (
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: "500",
            color: "var(--black-6)",
            marginBottom: "var(--space-24px)",
          }}
        >
          {t(subtitle)}
        </Typography>
      )}

      {redirect && redirectLabel && (
        <Link
          component={RouterLink}
          to={redirect || "/app/dashboard"}
          underline="none"
          sx={{
            backgroundColor: "var(--green-primary)",
            color: "var(--white-primary)",
            padding: "var(--space-8px) var(--space-16px)",
            borderRadius: "8px",
          }}
        >
          {t(redirectLabel)}
        </Link>
      )}
    </Box>
  );
};
export default ErrorPage;
