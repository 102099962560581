import { useAppSelector } from "src/redux/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

type UseMUIDatTableOptions = {
  additionalFilter: any;
  setAdditionalFilter: React.Dispatch<React.SetStateAction<any>> | undefined;
  ownButtonLabel?: string;
};

const FilterOwnContractsButtonTooltip = (props: UseMUIDatTableOptions) => {
  const [t] = useTranslation();
  const sortingOwnContracts = Boolean(props?.additionalFilter?.ownerIds);

  const user = useAppSelector((state) => state.user.user)!;
  const userId = user._id;

  return (
    <>
      <Tooltip title={"Sort own or all contracts"} arrow>
        {/* This <span> helps to forward refs */}
        <span>
          <ButtonSecondary
            onClick={() => {
              const additionalFilter = {
                ...props?.additionalFilter,
                ownerIds: sortingOwnContracts ? null : userId,
              };
              if (props.setAdditionalFilter) {
                props.setAdditionalFilter(additionalFilter);
              }
            }}
          >
            {t(
              sortingOwnContracts
                ? `All ${props.ownButtonLabel ?? "contracts"}`
                : `Own ${props.ownButtonLabel ?? "contracts"}`,
            )}
          </ButtonSecondary>
        </span>
      </Tooltip>
    </>
  );
};

export default FilterOwnContractsButtonTooltip;
