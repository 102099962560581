/**
 *Created by Mikael Lindahl on 2024-02-07
 */
import { StructureItemTable } from "../components/Builders/Table/CommonBuilderTableTypes";
import { updateIndexRows } from "./updateIndexRows";
import updateRowTotals from "./updateRowTotals";

export const getSetFormDataForInvoiceRows = <DataType = any>({
  key,
  setFormData,
}: {
  key: "pricePerUnit" | "quantity" | "discount";
  setFormData: (data: DataType[]) => void;
}) => {
  const setFormDataCustom: StructureItemTable["setFormDataCustom"] = (
    props,
  ) => {
    let newFormData = [...props.data];

    newFormData[props.rowIndex] = {
      ...newFormData[props.rowIndex],
      [key]: props.value,
    };

    updateIndexRows(newFormData);

    // Update all rows, since also index rows can have changed
    updateRowTotals(newFormData);

    setFormData(newFormData);
  };
  return setFormDataCustom;
};

export default getSetFormDataForInvoiceRows;
