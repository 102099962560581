import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import IndexModalWithForm from "./components/IndexModalWithForm";
import IndexTable from "./components/IndexTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import usePathPermission from "src/hooks/usePathPermission";
import { LinearProgress } from "@mui/material";
import { useGetIndicesQuery } from "src/redux/services/IndexService";
import { useState } from "react";

const Index = () => {
  const { data: dataQueryIndices, isLoading } = useGetIndicesQuery(undefined);
  const data = [...(dataQueryIndices?.data || [])];

  const [openAddIndexModal, setOpenAddIndexModal] = useState(false);
  const pathPermissions = usePathPermission("/app/setup/indices/create");

  return (
    <Page
      label={"Indices"}
      breadcrumbs={[{ label: "Setup" }, { label: "Indices" }]}
      isFullWidth={false}
      boxRight={
        <ButtonAddBoxIcon
          label={"Create index"}
          permissions={pathPermissions}
          onSubmit={() => setOpenAddIndexModal(true)}
        />
      }
    >
      <SectionTight key={"index-table"} containsTable label="" md={12} lg={12}>
        {isLoading ? <LinearProgress /> : <IndexTable data={data} />}
      </SectionTight>
      <IndexModalWithForm
        actionType={"add"}
        openModal={openAddIndexModal}
        handleCloseModal={() => setOpenAddIndexModal(false)}
        names={data.map((d) => d.name.toLowerCase())}
      />
    </Page>
  );
};

export default Index;
