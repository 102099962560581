import { backendApi } from "../api";
import { docGetTag, docListTags, docPatchTag, listTag } from "./utils";

import {
  Invite,
  InviteSend,
} from "src/accurasee-backend-types/app/invite/invite.types";

const CACHE_TAG = "Invites";

export const inviteApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    sendInvite: build.mutation<any, InviteSend>({
      query: (data) => ({
        url: "/invites/send",
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    replyInvite: build.mutation({
      query: (data) => ({
        url: "/invites/reply",
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    invalidateInvite: build.mutation({
      query: ({ id }) => {
        return {
          url: `/invites/${id}/invalid-invite`,
          method: "PATCH",
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    getInvites: build.query<{ data: Invite[]; metadata: any }, any>({
      query: (params) => ({ url: "/invites", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getInvite: build.query<Invite, any>({
      query: (id) => ({
        url: `/invites/${id}`,
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: docGetTag(CACHE_TAG),
    }),
  }),
});

export const {
  useGetInviteQuery,
  useGetInvitesQuery,
  useInvalidateInviteMutation,
  useReplyInviteMutation,
  useSendInviteMutation,
} = inviteApi;

export default inviteApi;
