import Invites from "./components/Invites";
import Users from "./components/Users";
import UserRoles from "./components/UserRoles/UserRoles";
import Page from "src/components/Basic/Mixed/Pages/Page";

const CompanyMembers = () => {
  return (
    <Page
      label={"Company users setup"}
      breadcrumbs={[{ label: "Setup" }, { label: "Users" }]}
      isFullWidth={false}
    >
      <Invites />
      <Users />
      <UserRoles />
    </Page>
  );
};

export default CompanyMembers;
