import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("xl")]: {
      //position: "absolute",
      //bottom: theme.spacing(2),
      marginTop: "5rem",
    },
  },
}));
