import Typography from "@mui/material/Typography";

// loginStyles
import useStyles from "./styles";

export default function Copyright() {
  var classes = useStyles();
  return (
    <Typography color="secondary.dark" className={classes.copyright}>
      © {new Date().getFullYear()}{" "}
      <a
        style={{ textDecoration: "none", color: "inherit" }}
        href="https://www.isolve.se/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Isolve AB
      </a>
      , All rights reserved.
    </Typography>
  );
}
