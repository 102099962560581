import Page from "src/components/Basic/Mixed/Pages/Page";

import Tabs from "src/components/Basic/Mixed/Tabs/Tabs";
import Settings from "./Settings";
import NotificationList from "./NotificationList";
import { useLocation } from "react-router-dom";

// Hide Notifications temporarily since it shows incorrect info - FLOW-1261
// const TABS = ["Settings", "Notifications"];
const TABS = ["Settings"];

const Profile = () => {
  const location = useLocation();
  return (
    <Page label="My profile" isFullWidth={false}>
      <Tabs
        currentTab={location.state?.currentTab || "1"}
        name={"my-profile"}
        tabs={TABS}
      >
        <Settings />
        <NotificationList />
      </Tabs>
    </Page>
  );
};

export default Profile;
