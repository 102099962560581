import useTranslation from "src/hooks/useTranslationWrapper";

import { Button, Typography } from "@mui/material";

import BuildItems from "src/components/Builders/Container/BuildItems";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import flattenObject from "src/utils/flattenObject";

import useFormContainer from "src/hooks/useFormContainer";

import { TranslationExtra } from "../TranslationsStructure";
import getStructure, {
  GetStructureExtraProps,
} from "./TranslationExportModalGetStructure";
import ModalWrapper from "../../../../../components/Basic/Mixed/Modals/ModalWrapper";

interface ExportModalProps {
  handleCloseModal: () => void;
  translations: TranslationExtra[];
  searchedData: number[];
  openModal: boolean;
}

const toExportData = (formData: TranslationExtra[]) => {
  let exportData: any[] = [];
  formData.forEach((d) => {
    exportData.push({
      key: d.key,
      namespace: d.namespace,
      languages: Object.keys(d.languages).reduce((dic, key) => {
        dic[key] = d.languages[key].value;
        return dic;
      }, {}),
    });
  });
  return exportData.map((d) => flattenObject(d));
};

export default function TranslationExportModal(props: ExportModalProps) {
  const [t] = useTranslation();

  const { formData, helperText, structure } = useFormContainer<
    {
      exportType: "searched" | "all";
    },
    GetStructureExtraProps
  >({
    getStructure,
    initialFormData: {
      // Default type is searched
      exportType: "searched",
    },
    extraProps: {
      searchedDataLength: props.searchedData.length || 0,
      translationsLength: props.translations.length || 0,
    },
  });
  const commonPropsBuildItem = { data: formData, helperText };

  const exportData =
    props.searchedData &&
    props.searchedData.length > 0 &&
    formData?.exportType === "searched"
      ? [...props.translations].filter((translation, index) =>
          props.searchedData.includes(index),
        )
      : props.translations;

  return (
    <ModalWrapper
      handleCloseModal={props.handleCloseModal}
      openModal={props.openModal}
      label={"Export translation file"}
      subLabel={
        "You can export the translation file as csv, json for xlsx format"
      }
    >
      {props.searchedData && props.searchedData.length > 0 ? (
        <>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
          <Typography
            sx={{
              fontSize: "14px",
              margin: "var(--space-8px) 0 var(--space-16px) 0",
              textAlign: "center",
            }}
          >
            {t(
              "Please select the list of translations you'd like to include in the export file.",
            )}
          </Typography>
        </>
      ) : (
        <></>
      )}
      <SectionWithButtonContainer>
        <ExportButton
          color="primary"
          data={toExportData(exportData)}
          delimiter={";"}
          key={"export-csv"}
          fileName={"translations.csv"}
          fileType={"csv"}
          variant="contained"
          onDone={() => props.handleCloseModal()}
        >
          {t("Export csv")}
        </ExportButton>
        <ExportButton
          color="primary"
          data={toExportData(exportData)}
          key={"export-json"}
          fileName={"translations.json"}
          fileType={"json"}
          variant="contained"
          onDone={() => props.handleCloseModal()}
        >
          {t("Export json")}
        </ExportButton>
        <ExportButton
          color="primary"
          data={toExportData(exportData)}
          key={"export-xlsx"}
          fileName={"translations.xlsx"}
          fileType={"xlsx"}
          variant="contained"
          onDone={() => props.handleCloseModal()}
        >
          {t("Export xlsx")}
        </ExportButton>
        <Button
          onClick={() => props.handleCloseModal()}
          variant="outlined"
          sx={{ minWidth: "112px" }}
        >
          {t("Cancel")}
        </Button>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
}
