import { MUIDataTableColumnDef } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  ContractType,
  ContractTypeWithTotals,
} from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  useDeleteContractTypeMutation,
  useGetContractTypesTableQuery,
} from "src/redux/services/ContractTypesServices";
import clone from "src/utils/clone";
import { getYear } from "date-fns";
import { escape } from "src/utils/translate";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";

const ContractTypesTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<ContractType>();

  const [deleteContractType] = useDeleteContractTypeMutation();
  const {
    data: queryResult,
    isLoading,
    isError,
  } = useGetContractTypesTableQuery({});

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(`Could not load planned invoices`, {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const contractTypes = queryResult?.data || [];
  const count = queryResult?.metadata.count || 0;

  const contractTableStructure: {
    items: StructureItemTable<ContractTypeWithTotals>[];
  } = {
    items: [
      {
        type: "link",
        dataName: "number",
        headerLabel: "#",
        redirectLink: (props) =>
          location.pathname +
          "/" +
          String(props.data[props.rowIndex]._id) +
          "/overview",
      },
      {
        type: "link",
        dataName: "name",
        headerLabel: "Name",
        redirectLink: (props) =>
          location.pathname +
          "/" +
          String(props.data[props.rowIndex]._id) +
          "/overview",
      },
      {
        type: "text",
        dataName: "activeContracts",
        headerLabel: "Active Contracts",
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "contracts",
        headerLabel: "Contracts",
        alignColumnContent: "center",
      },
      {
        type: "text_currency",
        currencyCode: "SEK",
        dataName: "invoicedThisYear",
        headerLabel: `Invoiced${escape(" " + String(getYear(new Date())))}`,
        getValue: ({ data, rowIndex }) => {
          const invoicedCurrentYear = data[rowIndex]?.invoicedThisYear || 0;
          return invoicedCurrentYear;
        },
        alignColumnContent: "end",
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        onClick: ({ rowIndex, data }) => {
          let newData = clone(data);
          setIsOpenDeleteModal(true);
          setDeleteData(newData[rowIndex] as ContractType);
        },
      },
    ],
  };

  let contractTypeColumns: MUIDataTableColumnDef[] =
    contractTableStructure.items.map((item) =>
      BuilderColumnOptionsSingle({
        data: contractTypes,
        disabled: true,
        item,
      }),
    );

  const { options } = useMUIDatTableOptions({
    pagination: count > 10,
    dataFetchResult: { data: queryResult, isLoading },
  });

  return (
    <>
      <MuiDataTableWrapper
        isFetching={isLoading}
        data={contractTypes}
        columns={contractTypeColumns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
      <DeletionModal
        deleteData={deleteData}
        dataType={"contract type"}
        openModal={isOpenDeleteModal}
        deleteDataFunction={deleteContractType}
        setIsOpenDeleteModal={setIsOpenDeleteModal}
      />
    </>
  );
};
export default ContractTypesTable;
