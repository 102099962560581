/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";
import getValue from "./getValue";

const getOptions = (props: CommonCallbackProps) => {
  // Selected value can be either an array or a string, if item?.selectionOptions?.multiple is set then it is an array
  let selectedValue = getValue(props);

  const selectedValues = Array.isArray(selectedValue)
    ? selectedValue
    : [selectedValue];

  let options =
    typeof props.item.options === "function"
      ? props.item.options(props)
      : props.item.options;

  options = options?.filter(
    (o) => o.isActive || selectedValues?.includes(o.value),
  );

  return options;
};

export default getOptions;
