import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import { Index } from "src/accurasee-backend-types/app/index/index.types";
import clone from "../../../../../utils/clone";
import toUpdateData from "../../../../../utils/toUpdateData";

export type GetStructureExtraProps = {
  names: string[];
};

type ItemTypes = "add" | "edit" | "add_edit";

export const getStructure: UseFormContainerGetStructure<Partial<Index>> = ({
  setFormData,
  extraProps,
}) => {
  const structure: StructureContainer<Partial<Index>, ItemTypes> = {
    items: [
      {
        itemType: "edit",
        type: "text",
        dataName: "name",
        label: "Name",
        gridProps: { md: 6 },
        sx: { padding: "0.49rem", minHeight: "30px" },
      },
      {
        itemType: "add",
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Name",
        gridProps: { md: 6 },
        validate: (props: any) => {
          return (
            props.data?.name.length >= 0 &&
            !extraProps?.names?.includes(props.data?.name.toLowerCase().trim())
          );
        },
        getErrorText: () => "Name already in use. Please select another one",
        setFormDataCustom: (propsCustom) => {
          const startsWith =
            propsCustom.value.startsWith(propsCustom.data?.invoiceText) ||
            (propsCustom.data?.invoiceText?.startsWith(propsCustom.value) &&
              Math.abs(
                propsCustom.data?.invoiceText.length - propsCustom.value,
              ) === 1);

          const newData = {
            ...propsCustom.data,
            [propsCustom.item.dataName as string]: propsCustom.value,
            ...(!propsCustom.data?.invoiceText || startsWith
              ? { invoiceText: propsCustom.value }
              : {}),
          } as Partial<Index>;

          setFormData(newData);
        },
      },
      {
        itemType: "add_edit",
        required: true,
        type: "text_input",
        dataName: "invoiceText",
        label: "Text on invoice",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "add_edit",
        required: true,
        type: "number_input",
        dataName: "value",
        label: "Value",
        gridProps: { md: 3 },
        setFormData,
      },
      {
        itemType: "add_edit",
        type: "switch",
        dataName: "active",
        label: "Active/Inactive",
        gridProps: { md: 9, sx: { marginTop: 3 } },
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<Index>;
  initData?: Partial<Index>;
}) => {
  if (data === undefined) {
    return data;
  }
  const submitData = clone<Partial<Index>>(data);

  const partialData = toUpdateData({ data: submitData, initData });

  return partialData;
};
