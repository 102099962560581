import { backendApi } from "../api";
import { ReportTemplate } from "src/accurasee-backend-types/app/report_template/report_template.types";
import {
  docGetTag,
  docListTags,
  docPatchTag,
  docDeleteTags,
  listTag,
} from "./utils";

import {
  CACHE_TYPE as REPORT_CACHE_TYPE,
  CACHE_TYPE_BUDGET as BUDGET_REPORT_CACHE_TYPE,
} from "./ReportService";

const CACHE_TYPE = "ReportTemplates";

export const reportTemplatesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getReportTemplates: build.query<
      { data: ReportTemplate[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/report-templates", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getReportTemplate: build.query<ReportTemplate, any>({
      query: (id) => ({ url: `/report-templates/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    createReportTemplate: build.mutation<ReportTemplate, any>({
      query: (data) => ({ url: "/report-templates", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    // updatereportTemplates: build.mutation({
    //   query: (data) => {
    //     return {
    //       url: "/report-templates",
    //       method: "PATCH",
    //       data,
    //     };
    //   },
    //   invalidatesTags: docPatchTag(CACHE_TYPE),
    // }),
    updateReportTemplate: build.mutation<ReportTemplate, any>({
      query: ({ id, ...data }) => {
        return {
          url: `/report-templates/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag([
        CACHE_TYPE,
        REPORT_CACHE_TYPE,
        BUDGET_REPORT_CACHE_TYPE,
      ]),
    }),
    updateReportTemplateOrder: build.mutation<
      void,
      Array<{
        _id: string;
        order: number;
      }>
    >({
      query: (data) => {
        return {
          url: `/report-templates/bulk/order`,
          method: "PATCH",
          data,
        };
      },
      async onQueryStarted(arg, api) {
        // optimistic update to improve UX
        // we dont want the user to wait for the server's response
        // on every reordering action

        api.dispatch(
          reportTemplatesApi.util.updateQueryData(
            "getReportTemplates",
            undefined,
            (draftReportTemplates) => {
              const orderMap = arg.reduce(
                (acc, el, index) => {
                  acc[el._id] = index;
                  return acc;
                },
                {} as Record<string, number>,
              );

              draftReportTemplates.data = [...draftReportTemplates.data].sort(
                (a, b) => orderMap[String(a._id)] - orderMap[String(b._id)],
              );
            },
          ),
        );
      },
    }),
    deleteReportTemplate: build.mutation<any, ReportTemplate["_id"] | string>({
      query: (id) => ({ url: `/report-templates/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteReportTemplateMutation,
  useGetReportTemplatesQuery,
  useGetReportTemplateQuery,
  useCreateReportTemplateMutation,
  // useUpdatereportTemplatesMutation,
  useUpdateReportTemplateMutation,
  useUpdateReportTemplateOrderMutation,
} = reportTemplatesApi;

export default reportTemplatesApi;
