import {
  ProjectTrackerConsolidatedCompanyIncomeReport,
  ProjectTrackerRegion,
} from "src/accurasee-backend-types/app/project_tracker/project_tracker.types";
import { getStructure } from "./ProjectTrackerConsolidatedCompanyIncomeReportStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import ProjectTrackerConsolidatedCompanyIncomeReportSubTable from "./ProjectTrackerConsolidatedCompanyIncomeReportSubTable";
import SectionTransparentForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";

interface ProjectTrackerConsolidatedCompanyIncomeReportTableProps {
  data: ProjectTrackerConsolidatedCompanyIncomeReport[];
}

export function ProjectTrackerConsolidatedCompanyIncomeReportTable({
  data,
}: ProjectTrackerConsolidatedCompanyIncomeReportTableProps) {
  const mainData = data.filter((d) => d.depth === 0);
  const structure = getStructure({
    extraProps: { nameLabel: "Company group" },
  });

  const ProjectTrackerRegionTableColumns: MUIDataTableColumnDef[] =
    structure.items.map((item) =>
      BuilderColumnOptionsSingle<ProjectTrackerConsolidatedCompanyIncomeReport>(
        {
          data: mainData,
          disabled: true,
          item,
        },
      ),
    );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 50,
    subtableFunction: (
      rowData: { props: { data: ProjectTrackerRegion[] } }[],
      rowMeta: any,
    ) => {
      const current = rowData[rowMeta.dataIndex].props.data[rowMeta.dataIndex];

      let subData = data.filter(
        (d) => d.depth >= 1 && d.dependencyId === current._id,
      );

      const subDataIds = subData.map((s) => s._id);
      subData = [
        ...subData,
        ...data.filter(
          (d) =>
            d.dependencyId &&
            d.depth >= 1 &&
            subDataIds.includes(d.dependencyId),
        ),
      ];

      return (
        <ProjectTrackerConsolidatedCompanyIncomeReportSubTable data={subData} />
      );
    },
  });

  return (
    <SectionTransparentForTableWithToolbar>
      <MuiDataTableWrapper
        nameSpace={"main-table"}
        tableType="main"
        noBackground
        title={""}
        data={mainData}
        columns={ProjectTrackerRegionTableColumns}
        options={options}
        fontSizeCells={"14px"}
      />
    </SectionTransparentForTableWithToolbar>
  );
}

export default ProjectTrackerConsolidatedCompanyIncomeReportTable;
