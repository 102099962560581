import { ActivityLogListReturn } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { EMAIL_LINEBREAK } from "../../../../accurasee-backend-types/shared/constants/general";

const useActivityLogsGetStructure = ({
  type,
}: {
  type: ActivityLogListReturn["type"];
}) => {
  const show = {
    actorUserId: false,
    emailSender: false,
    emailRecipient: false,
    fileName: false,
  };

  switch (type) {
    case "contract_offer":
      show.emailSender = true;
      show.emailRecipient = true;
      break;
    case "microsoft_drive_file":
      show.actorUserId = true;
      show.fileName = true;
      break;
    default:
  }

  const getStructure: UserFormTableGetStructure<ActivityLogListReturn> = () => {
    return {
      items: [
        {
          getValue: ({ data, rowIndex }) => {
            return `${data[rowIndex].actorUserId?.firstName} ${data[rowIndex].actorUserId?.lastName}`;
          },
          type: "text",
          dataName: "actorUserId",
          headerLabel: "User",
          showColumnWhen: show.actorUserId,
        },
        {
          type: "text",
          dataName: "fileName",
          headerLabel: "File",
          showColumnWhen: show.fileName,
        },
        {
          type: "text",
          dataName: "emailSender",
          headerLabel: "Sender",
          showColumnWhen: show.emailSender,
        },
        {
          type: "text",
          dataName: "emailRecipient",
          headerLabel: "Recipient",
          showColumnWhen: show.emailRecipient,
        },
        {
          type: "badge",
          dataName: "action",
          headerLabel: "Action",
          alignColumnContent: "center",
        },
        {
          type: "text",
          dataName: "comment",
          headerLabel: "Comment",
          getValue: ({ data, rowIndex }) =>
            data[rowIndex]?.comment?.split(EMAIL_LINEBREAK).join(" ") || "",
        },
        {
          type: "date",
          dataName: "createdAt",
          headerLabel: "Created at",
        },
      ],
    };
  };
  return getStructure;
};

export default useActivityLogsGetStructure;
