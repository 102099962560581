/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    datePickerIcon: {
      "& button": {
        color: theme.palette.primary.main,
      },
    },
    fixLabelRadioGroup: {
      "& .MuiRadio-root": {
        alignSelf: "start",
      },
      "& .MuiFormControlLabel-label": {
        alignSelf: "start",
      },
    },
    labelFieldStyle: {
      transform: "none",
      position: "relative",
      fontSize: "12px",
      fontWeight: 600,
      display: "block",
      marginBottom: "6px",
    },
    labelFieldBySideStyle: {
      color: "var(--gray-7)",
      fontSize: "12px",
      fontWeight: 600,
      marginRight: "var(--space-8px)",
      textAlign: "right",
    },
    labelBold: { fontWeight: "600" },
  }),
);

export default useStyles;
