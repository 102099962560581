/**
 *Created by Mikael Lindahl on 2023-01-30
 */

import { Grid, InputLabel, TextField } from "@mui/material";
import parseInteger from "src/utils/parseInteger";
import useStyles from "../BuildItemsStyles";
import throwErrorDataName from "../Utils/throwErrorDataName";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import getHelperText from "../Utils/getHelperText";
import getRequired from "../Utils/getRequired";
import parseValue from "../Utils/parseValue";
import setValue from "../Utils/setValue";
import getValue from "../Utils/getValue";
import getShowWhen from "../Utils/getShowWhen";
import useTranslation from "../../../../hooks/useTranslationWrapper";

type NumberItemTProps = CommonBuilderContainerTypes;

const NumberItem = (props: NumberItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  const required = getRequired(commonCallbackProps);

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "NumberItem",
    });
  }

  const value = getValue(commonCallbackProps);

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      {props.item.label && (
        <InputLabel
          required={required}
          shrink
          htmlFor={props.item.dataName}
          className={classes.labelFieldStyle}
        >
          {t(props.item.label)}
        </InputLabel>
      )}
      <TextField
        disabled={props.itemDisabled}
        helperText={getHelperText(getHelperTextProps)}
        error={Boolean(getHelperText(getHelperTextProps))}
        name={props.item.dataName}
        id={props.item.dataName}
        required={required}
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputBase-input": {
            padding: "var(--space-12px) var(--space-16px)",
          },
          ...props.item.sx,
        }}
        type="number"
        onChange={(event) => {
          let changeProps = {
            ...commonCallbackProps,
            value: parseValue({
              value: parseInteger(event),
              parse: props.parse,
            }),
          };
          if (props.item.setFormDataCustom) {
            props.item.setFormDataCustom(changeProps);
          } else {
            setValue({ ...changeProps });
          }
        }}
        value={!isNaN(value) ? value : ""}
      />
    </Grid>
  ) : (
    <></>
  );
};

export default NumberItem;
