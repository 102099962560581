import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import CheckEmail from "./components/CheckEmail";
import Copyright from "src/components/Basic/Simple/Copyright/Copyright";
import ForgotPassword from "./components/ForgotPassword";
import LoginRegular from "./components/LoginRegular";
import RegisterUser from "./components/RegisterUser";
import useLoginStyles from "./LoginStyles";

const Login = () => {
  const classes = useLoginStyles();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // activeTabId value
  // 0: Login
  // 1: Register
  // 2: ForgotPassword
  // 3: CheckEmail - After sending request at ForgotPassword
  const [activeTabId, setActiveTabId] = useState(
    queryParams.has("inviteId") ? 1 : 0,
  );
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  return (
    <Grid container className={classes.container}>
      <Grid item lg={5} className={classes.logotypeContainer}></Grid>
      <Grid
        item
        lg={7}
        md={8}
        sm={12}
        className={classes.formContainer}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src="/Logo.png" alt="accurasee-logo" height={48} />
        <div className={classes.form}>
          {activeTabId === 0 && (
            <LoginRegular setActiveTabId={setActiveTabId} />
          )}
          {activeTabId === 1 && (
            <RegisterUser setActiveTabId={setActiveTabId} />
          )}
          {activeTabId === 2 && (
            <ForgotPassword
              setActiveTabId={setActiveTabId}
              setResetPasswordEmail={setResetPasswordEmail}
            />
          )}
          {activeTabId === 3 && (
            <CheckEmail
              setActiveTabId={setActiveTabId}
              email={resetPasswordEmail}
            />
          )}
        </div>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Login;
