/**
 *Created by Mikael Lindahl on 2023-11-23
 */

import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { escape } from "src/utils/translate";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";

export type GetStructureExtraProps = {
  user: ReturnUser;
  userRoles?: AccessUserRole[];
};
export const getStructure: UseFormContainerGetStructure<
  Partial<ReturnUser>,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const companies = extraProps?.user?.companies || {};

  const structure: StructureContainer<Partial<ReturnUser>> = {
    items: [
      {
        type: "text",
        dataName: "firstName",
        label: "First name",
        gridProps: { md: 6 },
      },
      {
        type: "text",
        dataName: "lastName",
        label: "Last name",
        gridProps: { md: 6 },
      },
      {
        type: "text",
        dataName: "email",
        label: "Email",
      },
      {
        type: "selector",
        dataName: `companies.${extraProps?.user?.activeCompanyId}.roleIds`,
        label: t("Role(s)"),
        selectionOptions: { multiple: true },
        options: extraProps?.userRoles?.map((role: any) => {
          return {
            label: role.name,
            value: role._id,
            isActive: role.active,
          };
        }),
        setFormData,
      },
      {
        type: "switch",
        dataName: `companies.${extraProps?.user?.activeCompanyId}.active`,
        label: `Active${extraProps?.user?.activeCompanyId ? ` at${escape(` ${companies[String(extraProps.user.activeCompanyId)]?.displayName}`)}` : ""}`,
        setFormData,
      },
    ],
  };
  return structure;
};

export const toFormData = ({
  data,
  activeCompanyId,
}: {
  data: Partial<ReturnUser> | undefined;
  activeCompanyId?: string;
}) => {
  const { _id, email, firstName, lastName, companies } = data || {};
  const initialFormData = {
    _id,
    email,
    firstName,
    lastName,
    companies,
    active:
      activeCompanyId && companies
        ? companies?.[activeCompanyId]?.active
        : false,
  };

  return initialFormData;
};

export const toSubmitData = ({
  data,
  initData,
  activeCompanyId,
}: {
  data: Partial<ReturnUser> | undefined;
  initData: Partial<ReturnUser> | undefined;
  activeCompanyId?: string;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<ReturnUser>;
  if (newData._id) {
    partialData = toUpdateData<Partial<ReturnUser>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  // BE allows payload.active
  partialData.active =
    activeCompanyId && newData?.companies
      ? newData?.companies?.[activeCompanyId]?.active
      : false;

  return partialData;
};
