import { StylingType } from "./getStylingByStatusType";
import { WarningType } from "src/accurasee-backend-types/app/warning/warning.types";

const getTypeByStatus = (status: string) => {
  let type: StylingType = "disabled";
  if (
    status === "invoiced" ||
    status === "delivered" ||
    status === "accepted" ||
    status === "approved" ||
    status === "won"
  ) {
    type = "success";
  }
  if (status === "ready_to_invoice") {
    type = "success-1";
  }
  if (status === "fullypaid") {
    type = "success-2";
  }
  if (status === "normal") {
    type = "success-3";
  }
  if (
    status === "sent" ||
    status === "resent" ||
    status === "unpaid" ||
    status === WarningType.UserAction
  ) {
    type = "warning";
  }
  if (status === "upcoming" || status === "not_invoiced" || status === "low") {
    type = "warning-1";
  }
  if (status === "unpaidoverdue" || status === "invoicedoverdue") {
    type = "warning-2";
  }
  if (status === "failureTemporary") {
    type = "warning-3";
  }
  if (
    status === "draft" ||
    status === "ongoing" ||
    status === "partiallyinvoiced" ||
    status === "other" ||
    status === WarningType.ReleaseNotes ||
    status === "inProgress" ||
    status === "new"
  ) {
    type = "information";
  }
  if (status === "exported") {
    type = "information-1";
  }
  if (
    status === "critical" ||
    status === "declined" ||
    status === "locked" ||
    status === "cancelled" ||
    status === "failure" ||
    status === "failurePermanent" ||
    status === "expired" ||
    status === "invalid" ||
    status === WarningType.ProductionIssue ||
    status === undefined ||
    status === "lost"
  ) {
    type = "alert";
  }
  if (status === "late") {
    type = "disabled";
  }
  return type;
};

export default getTypeByStatus;
