/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getLink = (props: CommonCallbackProps) => {
  return typeof props.item.redirectLink === "function"
    ? props.item.redirectLink(props)
    : props.item.redirectLink;
};

export default getLink;
