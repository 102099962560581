import _c from "src/constants/Constants";

type FormatIfEstimateProps = {
  isEstimate: boolean;
  value: number | string;
};

export function formatIfEstimate({ isEstimate, value }: FormatIfEstimateProps) {
  return `${isEstimate ? _c.ESTIMATE_INDICATOR : ""}${value}`;
}
