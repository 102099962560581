import { backendApi } from "../api";
import {
  ApiKeyInformation,
  Company,
  ProjectExternal,
  ReturnCompanyWithPermissions,
} from "src/accurasee-backend-types/app/company/company.types";
import {
  docListTags,
  docPatchTag,
  docDeleteTags,
  listTagTwo,
  listTagMultiple,
} from "./utils";

const CACHE_TAG = "Company";

export const companyApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    updateCompany: build.mutation({
      query: (data) => {
        return {
          url: "/company",
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: ["Company"],
    }),
    getCompany: build.query<{ company: ReturnCompanyWithPermissions }, any>({
      query: (params) => {
        return { url: "/company", params };
      },
      providesTags: ["Company"],
    }),
    createApiKey: build.mutation({
      query: (data) => ({
        url: "/company/api",
        method: "POST",
        data,
      }),
      invalidatesTags: ["ApiKeys"],
    }),
    getApiKeys: build.query({
      query: (params) => ({ url: "/company/api", params }),
      providesTags: ["ApiKeys"],
    }),
    deleteApiKey: build.mutation<any, ApiKeyInformation["_id"] | string>({
      query: (id) => ({ url: `/company/api/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags("ApiKeys"),
    }),
    getCompanySuperAdmin: build.query<{ data: Company[] }, any>({
      query: (params) => {
        return { url: "/superadmin/company", params };
      },
      providesTags: docListTags(CACHE_TAG),
    }),
    createCompanySuperAdmin: build.mutation({
      query: (data) => ({
        url: "/superadmin/company",
        method: "POST",
        data,
      }),
      invalidatesTags: listTagTwo(CACHE_TAG, "Users"),
    }),
    updateCompanySuperAdmin: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/superadmin/company/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    getAllProjectExternal: build.query<{ data: ProjectExternal[] }, any>({
      query: (params) => {
        return { url: "/company/all-project-externals", params };
      },
      providesTags: listTagMultiple(
        CACHE_TAG,
        "Contracts",
        "ContractOffers",
        "Projects",
      ),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateApiKeyMutation,
  useCreateCompanySuperAdminMutation,
  useDeleteApiKeyMutation,
  useGetAllProjectExternalQuery,
  useGetApiKeysQuery,
  useGetCompanyQuery,
  useGetCompanySuperAdminQuery,
  useUpdateCompanyMutation,
  useUpdateCompanySuperAdminMutation,
} = companyApi;

export default companyApi;
