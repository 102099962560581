/**
 *Created by Mikael Lindahl on 2023-11-30
 */

import ModalWrapper from "../../Basic/Mixed/Modals/ModalWrapper";
import InvoiceplanTemplate from "../../Screens/InvoiceplanTemplate/InvoiceplanTemplate";

type InvoicePlanModalProps = {
  contractId?: string;
  handleCloseModal: () => void;
  invoicePlanId?: string;
  openModal: boolean;
};

const InvoicePlanTemplateModal = (props: InvoicePlanModalProps) => {
  return (
    <ModalWrapper
      handleCloseModal={props.handleCloseModal}
      label={"Create invoice plan template"}
      openModal={props.openModal}
      boxStyle={{ width: "800px" }}
    >
      <InvoiceplanTemplate
        contractId={props.contractId}
        invoicePlanId={props.invoicePlanId}
        onClose={props.handleCloseModal}
      />
    </ModalWrapper>
  );
};

export default InvoicePlanTemplateModal;
