/**
 *Created by Mikael Lindahl on 2023-12-08
 */

import { Navigate, useLocation } from "react-router-dom";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";

type RouteWrapperProps = {
  element?: JSX.Element;
  isHardRefresh?: boolean; // Add isHardRefresh prop
  isRedirect?: boolean;
  isUserActive?: boolean;
  redirectPath?: RoutePath;
};

export const RouteElementWrapper = ({
  element,
  isHardRefresh, // Destructure isHardRefresh
  isRedirect,
  isUserActive,
  redirectPath,
}: RouteWrapperProps) => {
  const location = useLocation();

  if (isRedirect) {
    if (isHardRefresh) {
      // If isHardRefresh is true, perform a hard refresh
      window.location.href = redirectPath || "/login";
      return <></>;
    } else {
      // Regular redirect
      return (
        <Navigate
          to={redirectPath || "/login"}
          state={{ from: location, isUserActive }}
          replace
        />
      );
    }
  } else {
    // Regular route rendering
    return element || <></>;
  }
};

export default RouteElementWrapper;
