import BuilderColumnOptionsSingle from "../../Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "../../Basic/Mixed/Buttons/ButtonSubmitCore";
import ModalWrapper from "../../Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "../../Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "../../Basic/Simple/Sections/Section";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormTable from "src/hooks/useFormTable";
import { ArticleWithPermissions } from "src/accurasee-backend-types/app/article/article.types";
import { Cost } from "src/accurasee-backend-types/app/cost/cost.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useEffect, useState } from "react";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import getStructure, {
  GetStructureExtraProps,
  toSubmitData,
} from "./ReassignCostsModalStructure";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { usePatchCostsMutation } from "src/redux/services/CostService";

type ReassignCostsModalTProps = {
  articles: ArticleWithPermissions[];
  costs: Cost[];
  extraProps: GetStructureExtraProps;
  isLoading: boolean;
  handleCloseModal: () => void;
  openModal: boolean;
};

const ReassignCostsModal = (props: ReassignCostsModalTProps) => {
  const [selectedRows, setSelectedRows] = useState<Cost[]>([]);
  const [updateCosts] = usePatchCostsMutation();

  const {
    formData,
    setFormData,

    setHasTriedToSubmit,
    structure: costTableStructure,
  } = useFormTable<Cost, GetStructureExtraProps>({
    extraProps: {
      ...props.extraProps,
      selectedRows,
      setSelectedRows,
    },
    initialFormData: props.costs,
    getStructure,
  });

  useEffect(() => {
    setSelectedRows(formData.filter((cost) => !!cost.subcontractId));
  }, [formData]);

  const submitData = toSubmitData({
    data: selectedRows,
  });

  const { onFormSubmit, isSubmitting } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateCosts,
      },
      data: { update: submitData }, // Remove unchanged, deleted key-value(s) and added blank row
      name: "Costs",
    },
    onSuccess: () => {
      setFormData(props.costs);
      setSelectedRows([]);
      setHasTriedToSubmit(false);
      props.handleCloseModal();
    },
  });

  const costColumns: MUIDataTableColumnDef[] = costTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle({
        data: formData,
        item,
      }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: props.costs.length >= 15,
    filterOptions: {
      selectableRows: "none",
      selectToolbarPlacement: "none",
    },
  });

  return (
    <ModalWrapper
      handleCloseModal={() => {
        setFormData(props.costs);
        setSelectedRows([]);
        props.handleCloseModal();
      }}
      openModal={props.openModal}
      label={"Costs possible to re-assign to ATAs"}
    >
      <SectionContainer>
        <Section boxStyle={{ paddingTop: 0 }}>
          <MuiDataTableWrapper
            isFetching={props.isLoading}
            data={props.costs || []}
            columns={costColumns}
            options={options}
            tableType="main-with-filter"
          />
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer buttonCenter={true}>
        <ButtonGroup>
          <ButtonCancel
            onClick={() => {
              setFormData(props.costs);
              setSelectedRows([]);
              props.handleCloseModal();
            }}
          />
          <ButtonSubmitCore
            isSubmitting={isSubmitting}
            initialSubmitData={[]}
            isValid={selectedRows.length > 0}
            label={"assign costs to selected subcontracts"}
            onSubmit={() => {
              onFormSubmit().catch((e) => console.error(e));
            }}
            submitData={selectedRows}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};
export default ReassignCostsModal;
