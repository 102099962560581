import {
  getStructure,
  GetStructureExtraProps,
  ItemsTypes,
  toData,
  toSubmitData,
} from "./SubcontractStructure";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { escape } from "src/utils/translate";
import { listToDic } from "src/utils/transform";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetCurrenciesQuery } from "src/redux/services/CurrencyService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import { useParams } from "react-router-dom";
import { usePatchSubcontractMutation } from "src/redux/services/SubcontractService";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import clone from "src/utils/clone";
import Constants from "src/constants/Constants";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import subcontractApi from "src/redux/services/SubcontractService";
import SubcontractInvoiceRows from "./components/SubcontractInvoiceRows";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

const SubcontractContainer = () => {
  let { contractId, subcontractId } = useParams<{
    contractId: string;
    subcontractId: string;
  }>();

  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  const { data: subcontract, isLoading: isLoadingSubContract } =
    subcontractApi.useGetSubcontractQuery(subcontractId);
  const [patchSubcontract] = usePatchSubcontractMutation();

  const { data: articlesWithMetaData, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);
  const contractArticleDic = listToDic(
    contract?.contractArticles as ContractArticle[],
    (c) => c.articleId,
  );
  const articles = articlesWithMetaData?.data?.map((a) => {
    if (contractArticleDic[String(a._id)]) {
      return { ...a, ...contractArticleDic[String(a._id)], _id: a._id }; // _id is overwritten by row._id
    } else {
      return a;
    }
  });

  const {
    data: termsOfPaymentsQueryResult,
    isLoading: isLoadingTermsOfPayments,
  } = useGetTermsOfPaymentsQuery(undefined);
  const termsOfPayments = termsOfPaymentsQueryResult || [];

  const { data: customers, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);

  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: currenciesResponse, isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery(undefined);
  const currencies =
    currenciesResponse?.data && currenciesResponse?.data?.length > 0
      ? currenciesResponse?.data
      : Constants.DEFAULT_CURRENCIES;

  const {
    formData,
    helperText,
    structure,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
  } = useFormContainer<
    SubcontractWithPermissions,
    GetStructureExtraProps,
    ItemsTypes
  >({
    initialFormData: toData({ data: clone(subcontract) }),
    getStructure,
    extraProps: {
      currencies,
      customers: customers?.data,
      dimensions,
      subcontractDimensions: subcontract?.dimensionItems,
      termsOfPayments,
      users,
    },
  });

  const initialSubmitData = toSubmitData({
    data: subcontract,
    initData: subcontract,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: subcontract,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { update: patchSubcontract },
      data: { update: submitData },
      dataId: String(subcontract?._id),
      name: "Subcontract",
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    disabled: ![
      "alterationAndAdditionalWork",
      "service_order",
      "rental",
    ].includes(subcontract?.subcontractType || ""),
    helperText,
  };

  return (
    <PageWithTopPadding
      label={"Subcontract"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contract?._id}`,
        },
        {
          label: "Subcontracts",
          link: `/app/contracts/${contract?._id}/subcontracts`,
        },
        { label: `Subcontract${escape(` ${subcontract?.number || ""}`)}` },
      ]}
      isLoading={
        isLoadingContract ||
        isLoadingSubContract ||
        isLoadingTermsOfPayments ||
        isLoadingArticles ||
        isLoadingCurrencies ||
        isLoadingCustomers ||
        isLoadingDimensions ||
        isLoadingUsers
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit({ action: "update" }).catch((err) => console.log(err));
        }}
        ref={refForm}
      >
        <SectionContainer>
          <Section
            key={"subcontract-info"}
            label={"Subcontract info"}
            md={12}
            lg={4}
          >
            {BuildItems({
              items: structure.items.filter((item) => item.itemType === "info"),
              ...commonPropsBuildItem,
            })}
          </Section>
          <Section
            key={"subcontract-type"}
            label={
              ["milestone", "fixedprice"].includes(formData?.type!)
                ? "Milestone type details"
                : formData?.type === "periodic"
                  ? "Periodic type details"
                  : formData?.type === "runningrate"
                    ? "Running rate type details"
                    : ""
            }
            md={12}
            lg={4}
          >
            {BuildItems({
              items: structure.items.filter(
                (item) => item.itemType === "runningAndPeriodicType",
              ),
              ...commonPropsBuildItem,
            })}
            {BuildItems({
              items: structure.items.filter(
                (item) => item.itemType === "milestone",
              ),
              ...commonPropsBuildItem,
            })}
          </Section>
          <Section
            key={"subcontract-Customer"}
            label={"Customer"}
            md={12}
            lg={4}
          >
            {BuildItems({
              items: structure.items.filter(
                (item) => item.itemType === "customer",
              ),
              ...commonPropsBuildItem,
            })}
          </Section>
          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonClear
                id={"edit-subcontract-cancel"}
                onClick={() => {
                  setFormData(subcontract);
                  setHasTriedToSubmit(false);
                }}
              />
              <ButtonUpdate
                id={"edit-subcontract"}
                initialSubmitData={initialSubmitData}
                isValid={isFormValid}
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={subcontract?.permissions}
                submitData={submitData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
          {subcontract ? (
            <SubcontractInvoiceRows
              articles={articles}
              subcontract={subcontract}
            />
          ) : (
            <></>
          )}
        </SectionContainer>
      </form>
    </PageWithTopPadding>
  );
};

export default SubcontractContainer;
