import { getStructure } from "./ProjectTrackerPercentageOfCompletionVariant2GetStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ProjectTrackerPercentageOfCompletionVariant2Sub } from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import { userMUIDatSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

interface ProjectTrackerPercentageOfCompletionVariant2SubTableProps {
  data: ProjectTrackerPercentageOfCompletionVariant2Sub[];
}

export function ProjectTrackerPercentageOfCompletionSubTable({
  data,
}: ProjectTrackerPercentageOfCompletionVariant2SubTableProps) {
  const structure = getStructure();

  const ProjectTrackerPercentageOfCompletionVariant2SubTableColumns: MUIDataTableColumnDef[] =
    structure.items
      .filter((c) => ["main-and-sub", "sub"].includes(String(c.itemType)))
      .map((item) =>
        BuilderColumnOptionsSingle<ProjectTrackerPercentageOfCompletionVariant2Sub>(
          {
            data,
            disabled: true,
            item,
          },
        ),
      );

  const options = userMUIDatSubTableOptions();

  return (
    <SubTableContainer sx={{ padding: "10px 10px 10px 52px" }}>
      <MuiDataTableWrapper
        nameSpace={"sub-table"}
        tableType="main-nested-level-1"
        title={""}
        data={data}
        columns={ProjectTrackerPercentageOfCompletionVariant2SubTableColumns}
        options={options}
      />
    </SubTableContainer>
  );
}

export default ProjectTrackerPercentageOfCompletionSubTable;
