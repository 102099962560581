/**
 *Created by Mikael Lindahl on 2023-04-16
 */
import ButtonCore from "../../../Basic/Simple/Buttons/ButtonCore";
import ButtonPrimary from "../../../Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "../../../Basic/Simple/Buttons/ButtonSecondary";
import getCellLabel from "../Utils/getCellLabel";
import getDisabled from "../Utils/getDisabled";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";

type ButtonColumnItemTProps = CommonBuilderTableTypes;

const ButtonColumnItem = (props: ButtonColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const t = props.t;
  let Button: JSX.Element | undefined = undefined;

  const commonProps = {
    onClick: () =>
      props.item.onClick && props.item.onClick(commonCallbackProps),
    permissions: props.data[props.rowIndex]?.permissions,
    permissionType: props.item.permissionType,
  };

  const disabled = getDisabled(commonCallbackProps);
  const cellLabel = getCellLabel(commonCallbackProps);

  const sx = {
    fontSize: "12px",
    height: "fit-content",
    minWidth: "fit-content",
    padding: "var(--space-4px) var(--space-16px)",
    textTransform: "capitalize",
  };
  switch (props.buttonType) {
    case "button":
      Button = (
        <ButtonCore
          color={props.item.buttonColor || "primary"}
          variant={props.item.buttonVariant || "outlined"}
          disabled={disabled}
          sx={sx}
          {...commonProps}
        >
          {cellLabel ? t(cellLabel) : ""}
        </ButtonCore>
      );
      break;
    case "button_primary":
      Button = (
        <ButtonPrimary disabled={disabled} {...commonProps} sx={sx}>
          {cellLabel ? t(cellLabel) : ""}
        </ButtonPrimary>
      );
      break;
    case "button_secondary":
      Button = (
        <ButtonSecondary disabled={disabled} {...commonProps} sx={sx}>
          {cellLabel ? t(cellLabel) : ""}
        </ButtonSecondary>
      );
      break;
  }
  return Button ? Button : <></>;
};

export default ButtonColumnItem;
