import { CustomerWithTotals } from "src/accurasee-backend-types/app/customer/customer.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCustomersTableQuery } from "src/redux/services/CustomerService";
import { useNavigate, useLocation } from "react-router-dom";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import getStructure, {
  GetStructureExtraProps,
} from "./CustomersTableStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import useFormTable from "src/hooks/useFormTable";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import usePathPermission from "src/hooks/usePathPermission";
import useTranslation from "src/hooks/useTranslationWrapper";

const Customers = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pathPermissions = usePathPermission("/app/operations/customers/create");

  const {
    options,
    data: customers,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<CustomerWithTotals>({
    dataName: "customers",
    filterInit: {
      base: {
        sort: "asc",
        sortBy: "externalId",
      },
    },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Name").toLowerCase()}, ${t(
        "Address",
      ).toLowerCase()} ${t("or")} ${t("City").toLowerCase()}...`,
    },
    useGetDataQuery: useGetCustomersTableQuery,
  });

  const { structure } = useFormTable<
    CustomerWithTotals,
    GetStructureExtraProps
  >({
    extraProps: {
      location,
    },
    initialFormData: customers,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<CustomerWithTotals>({
      data: customers,
      item,
    }),
  );

  return (
    <Page
      label={"Customers"}
      breadcrumbs={[{ label: "Operations" }, { label: "Customers" }]}
      isLoading={isLoading}
      boxRight={
        <ButtonAddBoxIcon
          id="direct-customer-create-page"
          label="Create customer"
          permissions={pathPermissions}
          onSubmit={() => navigate("/app/operations/customers/create")}
        />
      }
    >
      <MuiDataTableWrapper
        data={customers}
        columns={columns}
        isFetching={isFetching}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default Customers;
