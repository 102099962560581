/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import {
  CommonTypes,
  HelperTextArray,
  StructureItemTable,
} from "./CommonBuilderTableTypes";
import { MUIDataTableColumnDef } from "mui-datatables";
import { TFunction } from "src/hooks/useTranslationWrapper";
import getColumnOptions from "./getColumnOptions";
import useStyles from "./BuilderColumnOptionsBaseStyles";

export type BuilderColumnOptionsBaseTProps<DataType = any> = {
  data: DataType[];
  disabled?: boolean;
  helperText?: HelperTextArray;
  item: StructureItemTable<DataType>;
  t: TFunction;
};

const BuilderColumnOptionsBase: <DataType = any>(
  props: BuilderColumnOptionsBaseTProps<DataType>,
) => MUIDataTableColumnDef = (props) => {
  const classes = useStyles();

  let options = {
    customFilterListOptions: props?.item?.customFilterListOptions || undefined,
    filter: props.item?.filter || false,
    filterType: props?.item?.filterType || undefined,
    filterList: props?.item?.filterList || undefined,
    filterOptions: props?.item?.filterOptions || undefined,
    sort: props.item?.sort || false,
    viewColumns: !!props.item.headerLabel,
    display:
      props.item.showColumnWhen === undefined
        ? true
        : typeof props.item.showColumnWhen === "function"
          ? props.item.showColumnWhen({ data: props.data, item: props.item })
          : props.item.showColumnWhen,
  };

  const commonProps: CommonTypes = {
    data: props.data,
    disabled: props.disabled,
    helperText: props.helperText,
    item: props.item,
    t: props.t,
  };

  let alignColumnContent = props.item.alignColumnContent;

  options = {
    ...options,
    ...getColumnOptions({
      ...commonProps,
    }),
  };

  return {
    name: props.item.dataName as string,
    label: props.item.headerLabel ? props.t(props.item.headerLabel) : " ",
    options: {
      setCellHeaderProps: () => ({
        style: {
          textAlign: alignColumnContent || "start",
        },
        // when header (button) is sortable
        className:
          alignColumnContent === "end"
            ? classes.endHeader
            : alignColumnContent === "center"
              ? classes.centerHeader
              : classes.startHeader,
      }),
      setCellProps: () => ({
        style: {
          textAlign: alignColumnContent || "start",
          whiteSpace: props.item?.whiteSpace || "normal",
        },
      }),
      ...options,
    },
  };
};

export default BuilderColumnOptionsBase;
