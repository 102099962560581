/**
 *Created by Mikael Lindahl on 2023-12-05
 */

import { useState } from "react";

const useReload = () => {
  const [reloadCount, setReloadCount] = useState(Math.random().toString());

  return {
    reloadKey: reloadCount,
    reload: () => setReloadCount(Math.random().toString()),
  };
};

export default useReload;
