import { LinkedInvoiceDataGetList } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";

interface GetStructureExtraProps {
  contract: Contract;
}

const getStructure: UserFormTableGetStructure<
  LinkedInvoiceDataGetList | InvoicePlanRow,
  GetStructureExtraProps
> = ({ extraProps }) => ({
  items: [
    {
      dataName: "quantity",
      headerLabel: "Quantity",
      type: "text",
      getValue: ({ data, rowIndex }) => {
        const invoiceData = data[rowIndex];
        let unit: string;
        if (isInvoicePlanRow(invoiceData)) {
          unit = invoiceData.unitExternalId!;
        } else {
          unit = invoiceData.unit;
        }
        return String(invoiceData.quantity) + " " + unit;
      },
    },
    {
      dataName: "pricePerUnit",
      headerLabel: "Price per unit",
      currencyCode: extraProps?.contract?.currencyCode,
      type: "text_currency",
    },
    {
      dataName: "date",
      headerLabel: "Report date",
      type: "date",
      getValue: ({ data, rowIndex }) => {
        const invoiceData = data[rowIndex];
        return isInvoicePlanRow(invoiceData)
          ? invoiceData.importDate
          : invoiceData.date;
      },
    },
  ],
});

function isInvoicePlanRow(obj: any): obj is InvoicePlanRow {
  // Replace "property" with a property that exists on InvoicePlanRow
  return obj && typeof obj.importDate !== "undefined";
}

export default getStructure;
