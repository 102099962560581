import { backendApi } from "../api";
import {
  ReportTemplateYFilter,
  ReportTemplateYFilterWithPermissions,
} from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import {
  docGetTag,
  docListTags,
  docPatchTag,
  docDeleteTags,
  listTag,
} from "./utils";

const CACHE_TYPE = "ReportTemplateYFilter";

export const ReportTemplateYFilterApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getReportTemplateYFilters: build.query<
      { data: ReportTemplateYFilterWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/report-template-filters", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getReportTemplateYFilter: build.query<
      ReportTemplateYFilterWithPermissions,
      any
    >({
      query: (id) => ({ url: `/report-template-filters/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    createReportTemplateYFilter: build.mutation<ReportTemplateYFilter, any>({
      query: (data) => ({
        url: "/report-template-filters",
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    // updateReportTemplateYFilters: build.mutation({
    //   query: (data) => {
    //     return {
    //       url: "/report-template-filters",
    //       method: "PATCH",
    //       data,
    //     };
    //   },
    //   invalidatesTags: docPatchTag(CACHE_TYPE),
    // }),
    updateReportTemplateYFilter: build.mutation<ReportTemplateYFilter, any>({
      query: ({ id, ...data }) => {
        return {
          url: `/report-template-filters/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deleteReportTemplateYFilter: build.mutation<
      any,
      ReportTemplateYFilter["_id"] | string
    >({
      query: (id) => ({
        url: `/report-template-filters/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteReportTemplateYFilterMutation,
  useGetReportTemplateYFiltersQuery,
  useGetReportTemplateYFilterQuery,
  useCreateReportTemplateYFilterMutation,
  // useUpdateReportTemplateYFiltersMutation,
  useUpdateReportTemplateYFilterMutation,
} = ReportTemplateYFilterApi;

export default ReportTemplateYFilterApi;
