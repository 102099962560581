import { Box } from "@mui/material";
import { ContractWithPermissionsAndTotals } from "src/accurasee-backend-types/app/contracts/contract.types";
import { escape } from "src/utils/translate";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useState } from "react";
import {
  usePatchPocContractsMutation,
  useGetPocDataQuery,
} from "src/redux/services/ContractService";
import getStructure, {
  getBookingDateStructure,
} from "./PercentageOfCompletionTableStructure";
import Page from "src/components/Basic/Mixed/Pages/Page";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useFormContainer from "src/hooks/useFormContainer";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";

const PercentageOfCompletionTable = () => {
  const [t] = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);

  const [patchPocContracts] = usePatchPocContractsMutation();

  // For Booking date
  const {
    formData: bookingDateFormData,
    helperText,
    structure: bookingDateStructure,
    setHasTriedToSubmit,
  } = useFormContainer<{
    bookingDate: Date;
  }>({
    getStructure: getBookingDateStructure,
    initialFormData: {
      // Default value is the nearest preceeding last of month
      bookingDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    },
  });
  const commonPropsBuildItem: any = { data: bookingDateFormData, helperText };

  // For table
  const {
    options,
    data: contracts,
    isLoading: isLoadingContracts,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractWithPermissionsAndTotals>({
    dataName: "contracts",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        sortBy: "number",
        sort: "desc",
      },
      match: {
        bookingDate: bookingDateFormData?.bookingDate,
      },
    },
    filterOptions: {
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()} ${t(
        "or",
      )} ${t("Contract name").toLowerCase()}...`,
      selectableRows: "multiple",
      selectFunction: (_, current: any) => {
        const newList = current.map(
          ({ dataIndex }: { dataIndex: number }) => contracts[dataIndex]._id,
        );
        setSelectedRows(newList);
      },
      selectToolbarPlacement: "none",
    },
    useGetDataQuery: useGetPocDataQuery,
    triggerChangeOnFilterInit: ["bookingDate"],
  });

  const structure = getStructure();

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contracts,
      disabled: true,
      item,
    }),
  );

  // Book handling
  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "POC contract",
      data: {
        update: {
          bookingDate: bookingDateFormData?.bookingDate,
          contractIds: selectedRows,
        },
      },
      apiMutations: { update: patchPocContracts },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  return (
    <Page
      label={"POC Adjustment list"}
      breadcrumbs={[{ label: "Operations" }, { label: "POC" }]}
      isLoading={isLoadingContracts}
      boxRight={
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit({ action: "update" }).catch((err) =>
              console.log(err),
            );
            setSelectedRows([]);
          }}
          ref={refForm}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              columnGap: "var(--space-8px)",
            }}
          >
            {BuildItems({
              items: bookingDateStructure.items,
              disabled: contracts.length === 0,
              ...commonPropsBuildItem,
            })}
            <ButtonPrimary
              key="book-poc"
              isLoading={isSubmitting}
              disabled={contracts.length === 0}
              onClick={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              sx={{ textTransform: "capitalize" }}
            >
              {selectedRows.length > 0
                ? t(
                    `Book POC for (${escape(
                      String(selectedRows.length),
                    )}) selected contracts`,
                  )
                : t("Book POC for all")}
            </ButtonPrimary>
          </Box>
        </form>
      }
    >
      <MuiDataTableWrapper
        data={contracts}
        columns={columns}
        isFetching={isFetching}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default PercentageOfCompletionTable;
