/**
 *Created by Mikael Lindahl on 2023-03-06
 */

type ParseValueTProps = {
  parse?: (value: any) => any;
  value: any;
};

const parseValue = (props: ParseValueTProps) => {
  return props.parse ? props.parse(props.value) : props.value;
};

export default parseValue;
