/**
 *Created by Mikael Lindahl on 2023-03-16
 */
import { StructureItemContainer } from "../CommonBuilderContainerTypes";

type ThrowErrorDataName = {
  item: StructureItemContainer;
  itemName: string;
};

const throwErrorDataName = (props: ThrowErrorDataName) => {
  console.log(`Missing data name for Item: ${props.itemName}`);
  throw new Error("Missing data name " + props.itemName);
};

export default throwErrorDataName;
