import { SxProps } from "@mui/system/styleFunctionSx";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const SubTableContainer = ({
  backgroundColor = "var(--white-primary)",
  children,
  colSpan = 12,
  sx,
}: {
  backgroundColor?: string;
  children?: JSX.Element | JSX.Element[] | string;
  colSpan?: number;
  sx?: SxProps;
}) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          backgroundColor: `${backgroundColor} !important`,
          boxShadow:
            "0px 20px 20px -28px var(--gray-4) inset,0px -20px 20px -28px var(--gray-4) inset",
          ...sx,
        }}
        colSpan={colSpan}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

export default SubTableContainer;
