import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";
import Cashflow from "src/components/Complex/Graph/Cashflow";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import ResultYearToDate from "src/components/Complex/Graph/ResultYearToDate";
import RevenueVsOperatingCosts from "src/components/Complex/Graph/RevenueVsOperatingCosts";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";

const ContractInsights = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  return (
    <PageWithTopPadding
      label={"Contract insights"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Insights" },
      ]}
      isLoading={isLoading}
    >
      <SectionContainer>
        <Cashflow contractId={contractId} />
        <RevenueVsOperatingCosts contractId={contractId} />
        <ResultYearToDate contractId={contractId} />
      </SectionContainer>
    </PageWithTopPadding>
  );
};

export default ContractInsights;
