import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box } from "@mui/material";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import { useGetMonthCashFlowQuery } from "src/redux/services/InsightService";
import BuildItems from "src/components/Builders/Container/BuildItems";
import getStructure from "./DateRangeStructure";
import Section from "src/components/Basic/Simple/Sections/Section";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";

const Cashflow = ({ contractId }: { contractId?: string }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const user = useAppSelector((state) => {
    return state.user.user;
  })!;

  const { activeCompanyId } = user;

  const { formData, helperText, structure } = useFormContainer<{
    from: Date;
    to: Date;
  }>({
    getStructure,
    initialFormData: {
      from: new Date(currentYear, 0),
      to:
        currentMonth === 0
          ? new Date(currentYear, 0)
          : new Date(currentYear, currentMonth - 1),
    },
  });
  const filter: any = {
    fromDate: toDay(formData?.from),
    toDate: toDay(formData?.to),
    companyId: activeCompanyId,
  };
  if (contractId) {
    filter.contractId = contractId;
  }
  const { data: queryResult, isLoading } = useGetMonthCashFlowQuery(filter);

  const commonPropsBuildItem = { data: formData, helperText };

  return (
    <Section
      label="Cashflow by month"
      rightBoxArea={BuildItems({
        items: structure.items,
        ...commonPropsBuildItem,
      })}
      rightBoxAreaSx={{
        display: "flex",
        columnGap: "var(--space-8px)",
        marginBottom: "var(--space-12px)",
      }}
      isLoading={isLoading}
    >
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={1000}
          height={500}
          data={queryResult || []}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            name={"Cashflow (Actual)"}
            dataKey="cashflow"
            fill="var(--green-primary)"
          />
          <Bar
            name={"Cashflow (Prognosis)"}
            dataKey="cashflowPrognosis"
            fill="var(--warning-3)"
          />
        </BarChart>
      </ResponsiveContainer>
      <Box sx={{ margin: "var(--space-12px)" }}></Box>
    </Section>
  );
};

export default Cashflow;
