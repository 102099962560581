/**
 *Created by Mikael Lindahl on 2023-05-24
 */
import React, { useState } from "react";

export type AdditionalFilter = {
  active?: boolean;
  bookingDate?: Date;
  contractId?: string;
  contractIds?: string[];
  contractOfferFamilyId?: string;
  endDate?: string;
  exported?: boolean;
  familyId?: string;
  startDate?: string;
  status?: string[];
};

export type BaseFilter = {
  page?: number;
  pageSize?: number;
  search?: string | undefined;
  sort?: "asc" | "desc" | undefined;
  sortBy?: string | string[] | undefined;
};

export type Filter = AdditionalFilter & BaseFilter;

export type FilterToParams = (v: Filter & AdditionalFilter) => any;

export type UseMUIDataTableFilterStatesTProps = {
  filterInit?: Filter & AdditionalFilter;
  filterToParams?: FilterToParams;
};

export type UseMUIDataTableFilterStatesReturn = {
  additionalFilter: AdditionalFilter;
  baseFilter: BaseFilter;
  params?: any;
  reset: () => void;
  setAdditionalFilter: React.Dispatch<React.SetStateAction<any>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSortDirection: React.Dispatch<
    React.SetStateAction<"asc" | "desc" | undefined>
  >;
  setSortBy: React.Dispatch<
    React.SetStateAction<string | string[] | undefined>
  >;
};

const useMUIDataTableFilterStates = (
  props?: UseMUIDataTableFilterStatesTProps,
) => {
  const { page, pageSize, search, sort, sortBy, ...additionalFilter } =
    props?.filterInit || {};

  const [_page, setPage] = useState(page || 0);
  const [_pageSize, setPageSize] = useState(pageSize || 10);
  const [_search, setSearch] = useState(search || "");
  const [_sort, setSortDirection] = useState(sort);
  const [_sortBy, setSortBy] = useState(sortBy);
  const [_additionalFilter, setAdditionalFilter] = useState(
    additionalFilter || {},
  );

  const filterToParams: FilterToParams = props?.filterToParams
    ? props?.filterToParams
    : (v) => v;

  const reset = () => {
    setPage(page || 0);
    setPageSize(pageSize || 10);
    setSearch(search || "");
    setSortDirection(sort);
    setSortBy(sortBy);
    setAdditionalFilter(additionalFilter || {});
  };

  const baseFilter = {
    page: _page,
    pageSize: _pageSize,
    search: _search,
    sort: _sort,
    sortBy: _sortBy,
  };

  const r: UseMUIDataTableFilterStatesReturn = {
    additionalFilter: _additionalFilter,
    baseFilter,
    params: filterToParams({ ...baseFilter, ..._additionalFilter }),
    reset,
    setAdditionalFilter,
    setPage,
    setPageSize,
    setSearch,
    setSortDirection,
    setSortBy,
  };
  return r;
};

export default useMUIDataTableFilterStates;
