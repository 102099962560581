/**
 *Created by Mikael Lindahl on 2024-06-05
 */

"use strict";

const localConfig = {
  azureClientId: "d34181f7-574d-41bf-9521-b40048b566fd",
};

export default localConfig;
