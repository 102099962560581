/**
 *Created by Mikael Lindahl on 2024-07-11
 */
import { TFunction } from "../hooks/useTranslationWrapper";
import getSelectOptions from "./getSelectOptions";

const getSelectOptionsDayWeekMonthQuarterYear = (t: TFunction) => {
  return getSelectOptions({
    data: [
      {
        label: "Day",
        value: "day",
      },
      {
        label: "Week",
        value: "week",
      },
      {
        label: "Month",
        value: "month",
      },
      {
        value: "quarter",
        label: "Quarter",
      },
      {
        label: "Year",
        value: "year",
      },
    ].map((c) => ({ label: t(c.label), value: c.value })),
  });
};

export default getSelectOptionsDayWeekMonthQuarterYear;
