import { format } from "date-fns";

const toDay = (date) => {
  if (date) {
    return format(new Date(date), "yyyy/MM/dd");
  } else {
    return "";
  }
};

export default toDay;
