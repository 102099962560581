/**
 *Created by Mikael Lindahl on 2023-12-28
 */
import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";
import SectionTransparentForTable from "./SectionTransparentForTable";

const SectionTransparentForTableWithToolbar = (
  props: Omit<SectionCoreTProps, "withTightTableHeading">,
) => {
  return (
    <SectionTransparentForTable
      transparent
      withTightTableHeading
      boxHeadingStyle={{ padding: 0 }}
      boxStyle={{ padding: 0, position: "relative" }}
      {...props}
    >
      {props.children}
    </SectionTransparentForTable>
  );
};

export default SectionTransparentForTableWithToolbar;
