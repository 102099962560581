import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { TabsType } from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import CustomerInvoices from "src/screens/App/Operations/Invoices/Customer/CustomerInvoices";
import Page from "src/components/Basic/Mixed/Pages/Page";
import PlannedInvoices from "src/screens/App/Operations/Invoices/Planned/PlannedInvoices";
import TabWithLinksTransparent from "src/components/Basic/Mixed/Tabs/TabWithLinksTransparent";

const RoutesInvoicesTab = () => {
  const url = "/app/operations/invoices";
  const path = "/app/operations/invoices";
  const pathToStrip: RoutePath = "/app/operations/invoices";
  const redirectPath: RoutePath = "/app/operations/invoices/planned";
  const getMainRouteUrl = (path: RoutePath) => path;

  const tabs: TabsType[] = [
    {
      name: "Planned invoices",
      mainRoute: {
        component: <PlannedInvoices />,
        path: "/app/operations/invoices/planned",
      },
    },
    {
      name: "Customer invoices",
      mainRoute: {
        component: <CustomerInvoices />,
        path: "/app/operations/invoices/customer",
      },
    },
  ];

  const breadcrumbs = [{ label: "Operations" }, { label: "Invoices" }];

  return (
    <Page label={"Invoices"} breadcrumbs={breadcrumbs}>
      <TabWithLinksTransparent
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name="operations-invoices"
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
      />
    </Page>
  );
};

export default RoutesInvoicesTab;
