import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { Types } from "mongoose";
import { useCallback, useState } from "react";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import PreviewInvoiceContainer from "src/components/Complex/Containers/PreviewInvoiceContainer";
import PromptDialog from "src/components/Basic/Simple/Dialogs/PromptDialog";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useInvoiceStateChange from "src/hooks/useInvoiceStateChange";
import usePreviewPlannedInvoices from "src/hooks/usePreviewPlannedInvoices";
import useTranslation from "src/hooks/useTranslationWrapper";

type PreviewInvoiceButtonTProps = {
  label?: string;
  isMilestone?: boolean;
  onPostPreview: () => void;
  selectedRows: (InvoicePlanRow | PlannedInvoiceGetList)[];
};
const PreviewInvoiceButton = ({
  label = "Preview invoice(s)",
  isMilestone,
  onPostPreview,
  selectedRows,
}: PreviewInvoiceButtonTProps) => {
  const [t] = useTranslation();
  const [error, setError] = useState("");
  const plannedInvoiceIds = selectedRows
    ?.map((row) =>
      isMilestone
        ? (row as InvoicePlanRow).plannedInvoiceId
        : (row as PlannedInvoiceGetList)._id,
    )
    ?.filter((id): id is Types.ObjectId => Boolean(id));

  const { isPreviewLoading, onPreview, previewData, setPreviewData } =
    usePreviewPlannedInvoices({ plannedInvoiceIds });

  const { messages, onInvoiceStateChange, isInvoiceStateChangeLoading } =
    useInvoiceStateChange(
      isMilestone
        ? { selectedPlannedInvoiceIds: plannedInvoiceIds }
        : { selectedRows: selectedRows as PlannedInvoiceGetList[] },
    );

  const onCloseModal = useCallback(() => {
    setPreviewData(undefined);
  }, [setPreviewData]);

  const handlePromptDialogClose = useCallback(() => {
    setError("");
  }, [setError]);

  const onApprove = useCallback(async () => {
    await onInvoiceStateChange({
      onPostMerge: () => {
        onPostPreview();
        setPreviewData(undefined);
      },
    });
    setPreviewData(undefined);
  }, [onInvoiceStateChange, onPostPreview, setPreviewData]);

  return (
    <>
      <ButtonPrimary
        disabled={selectedRows.length === 0}
        isLoading={isPreviewLoading}
        onClick={async () => {
          if (messages) {
            const msg = messages.slice(0, -2);
            setError(msg);
          } else {
            try {
              await onPreview();
            } catch (e) {
              console.error(e);
            }
          }
        }}
      >
        {t(label)}
      </ButtonPrimary>
      <ModalWrapper
        openModal={!!previewData}
        handleCloseModal={onCloseModal}
        label="Invoice preview"
      >
        {previewData ? (
          <PreviewInvoiceContainer previewData={previewData} />
        ) : (
          <></>
        )}
        <SectionWithButtonContainer
          buttonCenter
          sx={{ padding: "0 var(--space-32px)" }}
        >
          <ButtonGroup>
            <ButtonCancel onClick={onCloseModal} />
            <ButtonPrimary
              isLoading={isInvoiceStateChangeLoading}
              onClick={onApprove}
            >
              {t("Approve")}
            </ButtonPrimary>
          </ButtonGroup>
        </SectionWithButtonContainer>
      </ModalWrapper>
      <PromptDialog
        open={error !== ""}
        onClose={handlePromptDialogClose}
        title={error}
        message={error}
      />
    </>
  );
};

export default PreviewInvoiceButton;
