import { Types } from "mongoose";
import { CalculatedReportTemplateYFilter } from "../../app/report_template/report_template.types";
export interface TimeStep {
  endDate: Date;
  label: string;
  startDate: Date;
}

export const REPORT_TEMPLATE_CALCULATED: CalculatedReportTemplateYFilter[] = [
  {
    _id: new Types.ObjectId("66c32b0e6ab6520e827f558c"),
    filterId: new Types.ObjectId("66c32b0e6ab6520e827f558c"),
    label: "Summarized result",
    type: "summarized_result",
  },
  // Can't currently be used due to display error in the frontend
  // {
  //   _id: new Types.ObjectId("66c32b0e6ab6520e827f558c"),
  //   filterId: new Types.ObjectId("66c32baa9afd4bad7ce41616"),
  //   label: "Margin",
  //   type: "margin",
  // },
  {
    _id: new Types.ObjectId("66c491b7550a01dbd9e3c44c"),
    filterId: new Types.ObjectId("66c491b7550a01dbd9e3c44c"),
    label: "Summarized costs",
    type: "summarized_costs",
  },
  {
    _id: new Types.ObjectId("66c491de57a708dec2b9a046"),
    filterId: new Types.ObjectId("66c491de57a708dec2b9a046"),
    label: "Summarized incomes",
    type: "summarized_incomes",
  },
];
