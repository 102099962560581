/**
 *Created by Mikael Lindahl on 2024-04-18
 */
import React, { useEffect } from "react";
import { IconButton, Select, MenuItem, Box, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { UsePaginationReturn } from "../../../../hooks/usePagination";
import {
  NavigateBefore,
  KeyboardDoubleArrowLeft as NavigateFirst,
  NavigateNext,
  KeyboardDoubleArrowRight as NavigateLast,
} from "@mui/icons-material";

interface PaginationProps {
  count: number;
  pageSizeOptions: number[];
  pagination: UsePaginationReturn;
}

const PaginationBar: React.FC<PaginationProps> = ({
  count,
  pageSizeOptions,
  pagination,
}) => {
  const { page, pageSize, setCount, setPage, setPageSize, totalNumberOfPages } =
    pagination;

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
  };

  useEffect(() => {
    if (count !== undefined) setCount(count);
  }, [count]);

  const commonSx = { fontSize: "14px" };

  return (
    <Box display="flex" flexDirection="row">
      <Typography marginY={2} marginX={1} sx={commonSx}>
        Page
      </Typography>
      {totalNumberOfPages && (
        <Select
          value={String(page)}
          onChange={(e) => handlePageChange(Number(e.target.value))}
          variant="standard"
          disableUnderline={true}
          sx={commonSx}
        >
          {Array.from({ length: totalNumberOfPages }, (_, i) => i).map(
            (pageNumber) => {
              return (
                <MenuItem
                  sx={commonSx}
                  key={pageNumber}
                  value={String(pageNumber)}
                >
                  {pageNumber + 1}
                </MenuItem>
              );
            },
          )}
        </Select>
      )}
      <Typography marginY={2} marginX={1} sx={commonSx}>
        of {totalNumberOfPages}, Total documents: {count}
      </Typography>
      <Typography margin={2} sx={commonSx}>
        Rows per page:
      </Typography>
      <Select
        value={pageSize || ""}
        onChange={handlePageSizeChange}
        variant="standard"
        disableUnderline={true}
        sx={commonSx}
      >
        {pageSizeOptions.map((size) => (
          <MenuItem sx={commonSx} key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
      <IconButton disabled={page === 0} onClick={() => handlePageChange(0)}>
        <NavigateFirst />
      </IconButton>
      <IconButton
        disabled={page === 0}
        onClick={() => handlePageChange(page - 1)}
      >
        <NavigateBefore />
      </IconButton>
      <IconButton
        disabled={page === totalNumberOfPages - 1}
        onClick={() => handlePageChange(page + 1)}
      >
        <NavigateNext />
      </IconButton>
      <IconButton
        disabled={page === totalNumberOfPages - 1}
        onClick={() => handlePageChange(totalNumberOfPages - 1)}
      >
        <NavigateLast />
      </IconButton>
    </Box>
  );
};

export default PaginationBar;
