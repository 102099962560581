import { axios } from "../../general/Network";

const login = (data: any) => {
  return axios.post("/login", data);
};

const register = (data: any) => {
  return axios.post("/register", data);
};

const reset = (data: any) => {
  return axios.post("/login/reset", data);
};

const recoverPassword = (data: any) => {
  return axios.post("/login/recover", data);
};

const LoginService = {
  login,
  register,
  reset,
  recoverPassword,
};

export default LoginService;
