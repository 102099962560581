import toUpdateData from "../../../../utils/toUpdateData";
import { Cron } from "../../../../accurasee-backend-types/app/cron/cron.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";

export interface GetStructureExtraProps {}

const getStructure: UserFormTableGetStructure<Cron, GetStructureExtraProps> = ({
  setFormData,
  extraProps,
}) => ({
  items: [
    {
      type: "text",
      dataName: "name",
      headerLabel: "Name",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text_input",
      dataName: "schedule",
      headerLabel: "Schedule",
      setFormData,
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "switch",
      dataName: "isRunning",
      cellLabel: "is running",
      sx: { width: "100%" },
      setFormData,
      // sort: true,
    },
  ],
});

export const toSubmitData = (
  data: Partial<Cron>[],
  initialData: Partial<Cron>[],
) => {
  let submitData: Partial<Cron>[] = [];
  data.forEach((d, index) => {
    let update = toUpdateData({ data: d, initData: initialData[index] });
    if (Object.keys(update).length !== 0) {
      submitData.push({ _id: d._id, ...update });
    }
  });

  return submitData;
};

export default getStructure;
