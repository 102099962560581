/**
 * This is to display Label and Value inline
 */

import { Box, Grid, Typography } from "@mui/material";

import throwErrorDataName from "../Utils/throwErrorDataName";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import getValue from "../Utils/getValue";
import getShowWhen from "../Utils/getShowWhen";
import getTextColor from "../Utils/getTextColor";
import useTranslation from "src/hooks/useTranslationWrapper";
import toCurrency from "src/utils/toCurrency";

import useStyles from "../BuildItemsStyles";

type TextInlineItemTProps = CommonBuilderContainerTypes;

const TextInlineItem = (props: TextInlineItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "TextInlineItem" });
  }

  let value = getValue(commonCallbackProps);
  const textColor = getTextColor(commonCallbackProps);
  const valueColor =
    typeof props.item.textColor === "undefined" || textColor
      ? "var(--gray-9)"
      : "var(--negative-primary)";
  const currencyCode = props.item.currencyCode;
  if (currencyCode) {
    value = toCurrency({
      value,
      currencyCode,
      toInteger: true, // not use decimal places for displaying data
    });
  }

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          justifyContent: "space-between",
        }}
      >
        <Typography
          className={classes.labelBold}
          sx={{ color: "var(--gray-4)" }}
        >
          {t(props.item.label)}
        </Typography>
        <Typography className={classes.labelBold} sx={{ color: valueColor }}>
          {props.format ? props.format(value) : value || "-"}
        </Typography>
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

export default TextInlineItem;
