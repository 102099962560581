import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { Project } from "src/accurasee-backend-types/app/project/project.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";

import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { DimensionExtra } from "src/redux/services/DimensionService";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../../../../utils/getSelectOptions";
import getSelectOptionsDayMonthQuarterYear from "../../../../../../utils/getSelectOptionsDayMonthQuarterYear";
import getDimensionItems, {
  TmpDimensionItems,
} from "src/utils/getDimensionItems";
import { DimensionItemInvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { listToDic } from "src/utils/transform";
import getCustomerName from "../../../../../../utils/getCustomerName";

export type GetStructureExtraProps = {
  currencies?: Currency[];
  customers?: Customer[];
  dimensions?: DimensionExtra[];
  invoicePlanTemplates?: InvoicePlanTemplate[];
  subcontractDimensions?: DimensionItemInvoicePlan[];
  projects?: Project[];
  termsOfPayments?: TermsOfPayment[];
  users?: ReturnUser[];
};

export const toData = ({
  data,
}: {
  data?: Partial<SubcontractWithPermissions> | undefined;
}) => {
  if (data === undefined) {
    return undefined;
  }

  const tmpDimensionItems: TmpDimensionItems =
    listToDic<DimensionItemInvoicePlan>(data?.dimensionItems || [], (v) =>
      String(v.dimensionId),
    );

  return {
    ...data,
    tmpDimensionItems,
  } as SubcontractWithPermissions;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: SubcontractWithPermissions;
  initData?: SubcontractWithPermissions;
}) => {
  const newData = clone(data);

  let partialData: Partial<SubcontractWithPermissions>;

  if (newData && data?._id) {
    partialData = toUpdateData<Partial<SubcontractWithPermissions>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData || {};
  }

  return partialData;
};

export type ItemsTypes =
  | "info"
  | "runningAndPeriodicType"
  | "milestone"
  | "customer";

export const getStructure: UseFormContainerGetStructure<
  SubcontractWithPermissions,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const dimensionItems = getDimensionItems<
    SubcontractWithPermissions,
    ItemsTypes
  >({
    contractDimensions: extraProps?.subcontractDimensions,
    dimensions: extraProps?.dimensions,
    itemType: "info",
    setFormData,
  }).map((item) => ({
    ...item,
    disabled: true,
  }));

  const structure: StructureContainer<SubcontractWithPermissions, ItemsTypes> =
    {
      items: [
        {
          dataName: "name",
          itemType: "info",
          type: "text_input",
          label: "Name",
          disabled: true,
        },
        {
          dataName: "subcontractType",
          gridProps: { lg: 6 },
          itemType: "info",
          label: "Subcontract type",
          type: "text_input",
          getValue: ({ data }) => t(data?.subcontractType),
          disabled: true,
        },
        {
          dataName: "type",
          gridProps: { lg: 6 },
          itemType: "info",
          type: "selector",
          label: "Type",
          options: getSelectOptions({
            data: [
              {
                label: "Milestone",
                value: "milestone",
              },
              {
                label: "Periodic",
                value: "periodic",
              },
              {
                label: "Fixed price",
                value: "fixedprice",
              },
              {
                label: "Running rate",
                value: "runningrate",
              },
              {
                label: "Custom",
                value: "custom",
              },
            ].map((c) => ({ label: t(c.label), value: c.value })),
          }),
          disabled: true,
        },
        ...dimensionItems,
        {
          itemType: "runningAndPeriodicType",
          type: "object",
          showWhen: ({ data }) =>
            data?.type === "periodic" || data?.type === "runningrate",
          items: [
            {
              dataName: "invoicingPeriod.date",
              disabled: ({ data }) => data?.subcontractType !== "service_order",
              gridProps: { lg: 6 },
              type: "date",
              label: "Invoicing period start date",
            },
            {
              dataName: "invoicingPeriod.ends",
              disabled: ({ data }) => data?.subcontractType !== "service_order",
              gridProps: { lg: 6 },
              type: "date",
              label: "End date",
            },
          ],
        },
        {
          itemType: "runningAndPeriodicType",
          type: "object",
          label: "Invoice frequency",
          showWhen: ({ data }) =>
            data?.subcontractType === "rental" && data?.type === "runningrate",
          items: [
            {
              dataName: "invoicingPeriod.repeats.number",
              gridProps: { xs: 3 },
              required: true,
              type: "number",
              disabled: true,
              validate: (props) => {
                if (props.data?.invoicingPeriod?.repeats?.number) {
                  return props.data?.invoicingPeriod?.repeats?.number > 0;
                } else {
                  return false;
                }
              },
              getErrorText: () => "Not allowed",
              setFormData,
            },
            {
              dataName: "invoicingPeriod.repeats.unit",
              gridProps: { xs: 9 },
              required: true,
              disabled: true,
              type: "selector",
              options: getSelectOptionsDayMonthQuarterYear(t),
              setFormData,
            },
          ],
        },
        {
          itemType: "milestone",
          type: "object",
          showWhen: ({ data }) =>
            data?.type === "milestone" || data?.type === "fixedprice",
          items: [
            {
              dataName: "milestoneDetails.date",
              gridProps: { lg: 6 },
              type: "date",
              label: "Invoicing period start date",
              setFormData,
            },
            {
              dataName: "milestoneDetails.ends",
              gridProps: { lg: 6 },
              type: "date",
              label: "Estimated completion",
              setFormData,
            },
            {
              dataName: "milestoneDetails.total",
              gridProps: { lg: 6 },
              label: "Total price",
              type: "number_input_currency",
              disabled: true,
            },
          ],
        },
        {
          dataName: "customerId",
          label: "Customer",
          itemType: "customer",
          gridProps: { lg: 8 },
          options: getSelectOptions({
            data:
              extraProps?.customers?.map((c) => ({
                label: getCustomerName(c),
                value: String(c._id),
              })) || [],
          }),
          type: "autocomplete",
          setFormData,
        },
        {
          itemType: "customer",
          required: false,
          type: "autocomplete",
          dataName: "currencyCode",
          label: "Currency",
          gridProps: { lg: 4 },
          options: getSelectOptions({
            data: extraProps?.currencies?.map((c) => ({
              label: `${c.code} (${c.description})`,
              value: c.code,
            })),
          }),
          setFormData,
        },
        {
          itemType: "customer",
          type: "object",
          items: [
            {
              dataName: "termsOfPaymentId",
              gridProps: { lg: 4 },
              itemType: "customer",
              type: "autocomplete",
              label: "Terms of payment",
              options: getSelectOptions({
                data: extraProps?.termsOfPayments?.map((item) => {
                  return {
                    label: item.description,
                    value: String(item._id),
                  };
                }),
              }),
              setFormData,
            },
            {
              dataName: "VATType",
              gridProps: { lg: 4 },
              itemType: "customer",
              type: "text_input",
              label: "VAT type",
              disabled: true,
            },
            {
              dataName: "orderNumber",
              gridProps: { lg: 4 },
              itemType: "customer",
              type: "text_input",
              label: "Your order number",
              setFormData,
            },
          ],
        },
        {
          dataName: "ourReferenceId",
          itemType: "customer",
          type: "autocomplete",
          label: "Our reference",
          options: getSelectOptions({
            data: extraProps?.users?.map((user) => {
              return {
                label: `${user.firstName} ${user.lastName}`,
                value: String(user._id),
              };
            }),
          }),
          setFormData,
        },
        {
          dataName: "yourReference",
          itemType: "customer",
          required: false,
          type: "text_input",
          label: "Your reference",
          setFormData,
        },
        {
          dataName: "yourReferenceEmail",
          itemType: "customer",
          type: "text_input",
          required: false,
          label: "Contact person e-mail",
          setFormData,
        },
        {
          dataName: "invoiceEmail",
          itemType: "customer",
          type: "text_input",
          required: false,
          label: "Invoice e-mail",
          setFormData,
        },
      ],
    };

  return structure;
};
