import BuildItems from "src/components/Builders/Container/BuildItems";
import ContractArticles from "src/components/Complex/Containers/ContractArticles";
import Section from "src/components/Basic/Simple/Sections/Section";
import clone from "src/utils/clone";
import useFormContainer from "src/hooks/useFormContainer";
import useVatTypeTooltip from "src/hooks/useVatTypeTooltip";
import { Grid, LinearProgress } from "@mui/material";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { FormGlue } from "../../../../../../../hooks/useFormGlue";
import { InvoicePlanExtended } from "src/utils/getDimensionItems";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import {
  INVOICE_PLAN_TYPE,
  InvoicePlanRow,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { useEffect } from "react";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractTypeQuery } from "src/redux/services/ContractTypesServices";
import { useGetCurrenciesQuery } from "src/redux/services/CurrencyService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import {
  useGetActiveIndicesQuery,
  useGetIndexQuery,
} from "src/redux/services/IndexService";
import {
  toData,
  getStructure,
  GetStructureExtraProps,
  ItemsTypes,
  toSubmitData,
} from "./InvoicePlanContainerStructure";
import getSelectOptions from "../../../../../../../utils/getSelectOptions";

type InvoiceplanContainerTProps = {
  contract?: GetSingleContractWithPermissions;
  formGlue: FormGlue<InvoicePlanWithPermissions>;
  formGlueInvoiceRows: FormGlue<InvoicePlanRow[]>;
  invoicePlanInit?: Partial<InvoicePlanWithPermissions>;
  invoicePlanTemplates: InvoicePlanTemplate[];
};

export default function InvoicePlanContainer({
  contract,
  formGlue,
  formGlueInvoiceRows,
  invoicePlanInit,
  invoicePlanTemplates,
}: InvoiceplanContainerTProps) {
  const isCreate = !invoicePlanInit;

  // const [expandedArticles, setExpandedArticles] = useState(false);
  const { vatTypeTooltip, setVatTypeTooltipOnCustomer } = useVatTypeTooltip();

  const { data: contractType, isLoading: isLoadingContractTypes } =
    useGetContractTypeQuery(contract?.contractTypeId, {
      skip: contract?.contractTypeId === undefined,
    });

  const { data: customers, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);

  const { data: termsOfPayments, isLoading: isLoadingTermsOfPayments } =
    useGetTermsOfPaymentsQuery(undefined);

  const { data: articles, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);
  const articleList = articles?.data;

  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);

  const users = usersResponse?.data || [];

  const { data: currenciesResponse, isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery(undefined);

  const currencies = currenciesResponse?.data || [];

  const { data: dataQueryIndices, isLoading: isLoadingIndices } =
    useGetActiveIndicesQuery(undefined);
  const indexOptions = getSelectOptions({
    data: [...(dataQueryIndices?.data || [])].map((index) => ({
      label: `${index.name} (${index.value})`,
      value: String(index._id),
    })),
  });

  const { data: currentIndex, isLoading: isLoadingIndex } = useGetIndexQuery(
    invoicePlanInit?.indexOption?.indexId,
    { skip: !invoicePlanInit?.indexOption?.indexId },
  );

  if (
    !!currentIndex &&
    !indexOptions.map((v) => v.value).includes(String(currentIndex?._id))
  ) {
    indexOptions.push({
      label: `${currentIndex?.name} (${currentIndex?.value})`,
      value: String(currentIndex?._id),
      isActive: true,
    });
  }

  const isLoadingData =
    isLoadingArticles ||
    isLoadingContractTypes ||
    isLoadingCurrencies ||
    isLoadingCustomers ||
    isLoadingDimensions ||
    isLoadingIndex ||
    isLoadingIndices ||
    isLoadingTermsOfPayments ||
    isLoadingUsers;

  const {
    formData,
    hasTriedToSubmit,
    helperText,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<InvoicePlanExtended, GetStructureExtraProps, ItemsTypes>(
    {
      extraProps: {
        articles: articles?.data,
        contract,
        contractType,
        currencies,
        currentIndex,
        customers: customers?.data,
        dimensions,
        formGlueInvoiceRows,
        indexOptions,
        invoicePlanTemplates,
        setVatTypeTooltipOnCustomer,
        termsOfPayments,
        users,
        vatTypeTooltip,
      },
      formGlue,
      getStructure,
      initialFormData: toData({ data: clone(invoicePlanInit) }),
      toSubmitData,
    },
  );

  useEffect(() => {
    if (currentIndex?.active === false && !hasTriedToSubmit) {
      setHasTriedToSubmit(true);
    }
  }, [currentIndex, hasTriedToSubmit]);

  const commonPropsBuildItem = {
    data: formData,
    helperText,
    id: isCreate ? undefined : formData?._id,
  };

  if (isLoadingData) {
    return (
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
    );
  }
  return (
    <>
      <Section key={"invoice-plan"} label={"Invoice plan"} md={12} lg={4}>
        {BuildItems({
          items: structure.items.filter((item) => item.itemType === "plan"),
          ...commonPropsBuildItem,
        })}
      </Section>
      <Section
        key={"invoice-plan-type"}
        md={12}
        lg={4}
        label={
          formData?.type === INVOICE_PLAN_TYPE.milestone ||
          formData?.type === INVOICE_PLAN_TYPE.milestone_retained_funds
            ? "Milestone type details"
            : formData?.type === INVOICE_PLAN_TYPE.periodic
              ? "Periodic type details"
              : formData?.type === INVOICE_PLAN_TYPE.runningrate
                ? "Running rate type details"
                : formData?.type === INVOICE_PLAN_TYPE.singleinvoice
                  ? "Single type details"
                  : ""
        }
      >
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "runningAndPeriodicType",
          ),
          ...commonPropsBuildItem,
        })}
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "milestone",
          ),
          ...commonPropsBuildItem,
        })}
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "singleinvoice",
          ),
          ...commonPropsBuildItem,
        })}
      </Section>
      <Section key={"invoice-plan-Customer"} label={"Customer"} md={12} lg={4}>
        {BuildItems({
          items: structure.items.filter((item) => item.itemType === "customer"),
          ...commonPropsBuildItem,
        })}
      </Section>
      <ContractArticles
        key={"contract-articles"}
        articleList={articleList}
        contractArticlesInit={contract?.contractArticles}
        currencyCode={formData?.currencyCode}
        disabled
        isMilestone={
          formData?.type === INVOICE_PLAN_TYPE.milestone ||
          formData?.type === INVOICE_PLAN_TYPE.milestone_retained_funds
        }
        isView
        useExpand
        editRights={false}
      />
    </>
  );
}
