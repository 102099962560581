/**
 *Created by Mikael Lindahl on 2023-12-15
 */

import ButtonSecondary from "./ButtonSecondary";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ButtonCoreProps } from "./ButtonCore";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

type ButtonBackProps = ButtonCoreProps;

const ButtonClear = (props: Omit<ButtonBackProps, "children" | "onClick">) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <ButtonSecondary
      {...props}
      onClick={() => navigate(-1)}
      startIcon={<ArrowLeftIcon />}
    >
      {t("back")}
    </ButtonSecondary>
  );
};

export default ButtonClear;
