import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { CostWithPermissions } from "src/accurasee-backend-types/app/cost/cost.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { userMUIDataSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import getStructure, { GetStructureExtraProps } from "./CostSubtableStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useFormTable from "src/hooks/useFormTable";
import { useGetCostsSubtableQuery } from "src/redux/services/CostService";
import LinearProgress from "@mui/material/LinearProgress";

interface CostSubtableProps {
  articles: Article[];
  params: {
    contractId: string;
    name: string;
    unitExternalId?: string;
  };
  title?: string;
  titlePosition?: "middle" | "top";
}

const CostsSubtable = ({
  articles,
  params,
  title,
  titlePosition,
}: CostSubtableProps) => {
  const { data: subTableCostQueryResult, isLoading: isLoadingSubTableCosts } =
    useGetCostsSubtableQuery(params);

  const subCosts = subTableCostQueryResult?.data || [];

  const { structure } = useFormTable<
    CostWithPermissions,
    GetStructureExtraProps
  >({
    extraProps: {
      articles,
    },
    initialFormData: subCosts,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<CostWithPermissions>({
      data: subCosts,
      item,
    }),
  );
  const options = userMUIDataSubTableOptions({});
  if (isLoadingSubTableCosts) {
    return <LinearProgress />;
  }

  return (
    <MuiDataTableWrapper
      title={title || ""}
      titlePosition={titlePosition}
      data={subCosts}
      columns={columns}
      options={options}
      tableType="main-nested-level-1-centered"
    />
  );
};

export default CostsSubtable;
