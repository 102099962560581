import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import { LinkedInvoiceGetListGroupedByPlannedInvoiceRowId } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { PlannedInvoice } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { toDate } from "src/utils/date";
import { listToDic } from "src/utils/transform";

interface GetStructureExtraProps {
  articles: Article[];
  contracts: Contract[];
  plannedInvoices: PlannedInvoice[];
}

const getStructure: UserFormTableGetStructure<
  LinkedInvoiceGetListGroupedByPlannedInvoiceRowId,
  GetStructureExtraProps
> = ({ extraProps }) => {
  const plannedInvoiceDict = extraProps?.plannedInvoices
    ? listToDic<PlannedInvoice>(extraProps?.plannedInvoices, (c) => c._id)
    : {};

  return {
    items: [
      {
        dataName: "projectExternalId",
        headerLabel: "Contract",
        type: "text",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          // const contract = contractDict[String(invoiceData.contractId)];
          return !!invoiceData.contractName
            ? invoiceData.contractName
            : "Contract missing...";
        },
      },
      {
        dataName: "plannedInvoiceId",
        headerLabel: "Planned invoice",
        type: "link",
        redirectLink: ({ data, rowIndex }) =>
          data[rowIndex].contractId
            ? `/app/contracts/${data[rowIndex].contractId}/invoices/planned/${data[rowIndex].plannedInvoiceId}`
            : "",
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          const plannedInvoice =
            plannedInvoiceDict[String(invoiceData.plannedInvoiceId)];
          return plannedInvoice
            ? plannedInvoice.name + " - " + plannedInvoice.number
            : "-";
        },
      },
      {
        type: "text_with_optional_badge",
        dataName: "itemDescription",
        headerLabel: "Name",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          const isProcessing = data[rowIndex].linkedInvoiceData.some(
            (row) => row?.incomplete,
          );
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
      },
      {
        dataName: "timePeriod",
        headerLabel: "Time period",
        type: "text",
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          return `${toDate(invoiceData?.timePeriod?.from)} - ${toDate(
            invoiceData?.timePeriod?.to,
          )}`;
        },
      },
      {
        dataName: "articleId",
        headerLabel: "Article",
        type: "text",
        getValue: ({ data, rowIndex }) =>
          extraProps?.articles?.find(
            (article) => article._id === data[rowIndex].articleId,
          )?.name,
      },
      {
        dataName: "quantity",
        headerLabel: "Quantity",
        type: "text",
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          return invoiceData.quantity.toString() + " " + invoiceData.unit;
        },
      },
      {
        dataName: "pricePerUnit",
        headerLabel: "Price per unit",
        currencyCode: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          const plannedInvoice =
            plannedInvoiceDict[String(invoiceData.plannedInvoiceId)];
          return plannedInvoice?.currencyCode || "SEK";
        },
        type: "text_currency",
      },
      {
        dataName: "sourceName",
        headerLabel: "Source",
        type: "text",
        getValue: ({ data, rowIndex }) => data[rowIndex].sourceNames.join(", "),
      },
    ],
  };
};
export default getStructure;
