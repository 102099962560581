/**
 *Created by Mikael Lindahl on 2024-06-06
 */

import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { useContext, useEffect } from "react";
import { UserStateContext } from "../context/UserStateProvider";

export type AddColumnVisibility = (columns: MUIDataTableColumnDef[]) => void;
const usePersistColumnVisibility = ({
  columnNames,
  id,
}: {
  columnNames: string[];
  id: string;
}) => {
  const { getUserStateTable, setUserStateTable } = useContext(UserStateContext);

  useEffect(() => {
    let isUpdate = false;
    const userStateByTableId = getUserStateTable(id);

    const newState = columnNames?.reduce((acc, name) => {
      if (!userStateByTableId?.columnVisibility?.hasOwnProperty(name)) {
        acc = {
          ...acc,
          columnVisibility: {
            ...acc.columnVisibility,
            [name]: true,
          },
        }; // Set default value
        isUpdate = true;
      } else {
        acc = {
          ...acc,
          columnVisibility: {
            ...acc.columnVisibility,
            [name]: userStateByTableId?.columnVisibility?.[name], // Keep existing value,
          },
        };
      }

      return acc;
    }, userStateByTableId);

    if (isUpdate) {
      setUserStateTable({ id, data: newState });
    }
  }, []);

  const onColumnViewChange: MUIDataTableOptions["onColumnViewChange"] = (
    changedColumnName,
    action,
  ) => {
    const userStateByTableId = getUserStateTable(id);

    setUserStateTable({
      id,
      data: {
        ...userStateByTableId,
        columnVisibility: {
          ...userStateByTableId.columnVisibility,
          [changedColumnName]: action === "add",
        },
      },
    });
  };

  const addColumnVisibility: AddColumnVisibility = (columns) => {
    if (typeof columns === "string") return;

    const userStateByTableId = getUserStateTable(id);

    for (const c of columns) {
      if (typeof c === "string") continue;

      if (userStateByTableId) {
        if (
          c.options &&
          userStateByTableId?.columnVisibility?.hasOwnProperty(c.name)
        ) {
          c.options.display = userStateByTableId.columnVisibility[c.name];
        }
      }
    }
  };

  return { addColumnVisibility, onColumnViewChange };
};

export default usePersistColumnVisibility;
