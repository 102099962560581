import {
  getStructure,
  GetStructureExtraProps,
} from "./CustomFieldsGetStructure";
import {
  useDeleteCustomFieldMutation,
  useGetCustomFieldsQuery,
} from "src/redux/services/CustomFieldService";
import { Box, Typography } from "@mui/material";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import _c from "src/constants/Constants";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ConsentDialog from "src/components/Basic/Mixed/Dialogs/ConsentDialog";
import CustomFieldModal from "./CustomFieldModal";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormTable from "src/hooks/useFormTable";
import useTranslation from "src/hooks/useTranslationWrapper";

const CustomFields = () => {
  const [t] = useTranslation();

  const { data: customFieldsQuery, isLoading } =
    useGetCustomFieldsQuery(undefined);
  const customFields = customFieldsQuery?.data || [];
  const [deleteCustomField] = useDeleteCustomFieldMutation();

  // This helps to check if this new label is in use or not
  // All is lowercase, for example: Abc === abc
  let names: string[] = [];
  let offerCount = 0;
  let contractCount = 0;
  customFields.forEach((item) => {
    names.push(item.name.toLowerCase());
    if (item.appliedToSections.includes("offer")) {
      offerCount++;
    }
    if (item.appliedToSections.includes("contract")) {
      contractCount++;
    }
  });
  const availableSections = {
    contract: contractCount < _c.MAX_CUSTOM_FIELDS,
    offer: offerCount < _c.MAX_CUSTOM_FIELDS,
  };

  const [openYesNoDialog, setOpenYesNoDialog] = useState(false);
  const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false);
  const [openEditCustomFieldModal, setOpenEditCustomFieldModal] =
    useState(false);
  const [editCustomFieldData, setEditCustomFieldData] = useState<
    CustomField | undefined
  >();
  const [openDeleteCustomFieldModal, setOpenDeleteCustomFieldModal] =
    useState(false);
  const [deleteCustomFieldData, setDeleteCustomFieldData] = useState<
    CustomField | undefined
  >();

  const { formData, structure } = useFormTable<
    CustomField,
    GetStructureExtraProps
  >({
    extraProps: {
      onDelete: (props) => {
        setOpenDeleteCustomFieldModal(true);
        setDeleteCustomFieldData(props.data[props.rowIndex]);
      },
      onEdit: (props) => {
        setOpenEditCustomFieldModal(true);
        setEditCustomFieldData(props.data[props.rowIndex]);
      },
    },
    initialFormData: customFields,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<CustomField>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 50,
  });

  return (
    <Page
      label={"Custom Fields"}
      breadcrumbs={[{ label: "Setup" }, { label: "Custom Fields" }]}
      isFullWidth={false}
    >
      <SectionTight
        label="Custom Fields"
        subLabel={`Create and edit Custom Field - (*) Maximum ${_c.MAX_CUSTOM_FIELDS} for each applied section`}
        rightBoxArea={
          <ButtonPrimary
            onClick={() => {
              if (
                offerCount === _c.MAX_CUSTOM_FIELDS &&
                contractCount === _c.MAX_CUSTOM_FIELDS
              ) {
                setOpenYesNoDialog(true);
              } else {
                setOpenAddCustomFieldModal(true);
              }
            }}
          >
            {"Add a Custom Field"}
          </ButtonPrimary>
        }
        containsTable
        isLoading={isLoading}
      >
        {customFields.length === 0 ? (
          <Box sx={{ padding: "var(--space-24px)" }}>
            {t("No custom field exist ...")}
          </Box>
        ) : (
          <MuiDataTableWrapper
            nameSpace="main-table"
            tableType="compact"
            title=""
            data={formData}
            columns={columns}
            options={options}
          />
        )}
      </SectionTight>

      {/* Add form */}
      {openAddCustomFieldModal && (
        <CustomFieldModal
          availableSections={availableSections}
          openModal={openAddCustomFieldModal}
          names={names}
          handleCloseModal={() => setOpenAddCustomFieldModal(false)}
        />
      )}

      {/* Edit form */}
      {openEditCustomFieldModal && (
        <CustomFieldModal
          availableSections={availableSections}
          openModal={openEditCustomFieldModal}
          names={names?.filter(
            (name) => name !== editCustomFieldData?.name.toLowerCase(),
          )}
          row={editCustomFieldData}
          handleCloseModal={() => setOpenEditCustomFieldModal(false)}
        />
      )}

      {/* Delete modal */}
      <DeletionModal
        dataType="custom field"
        openModal={openDeleteCustomFieldModal}
        deleteData={deleteCustomFieldData}
        deleteDataFunction={deleteCustomField}
        setIsOpenDeleteModal={setOpenDeleteCustomFieldModal}
      />

      {openYesNoDialog && (
        <ConsentDialog
          handleClose={() => setOpenYesNoDialog(false)}
          open={openYesNoDialog}
          consentButtons={[{ label: t("Ok") }]}
        >
          <Typography
            mb={1}
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              textTransform: "uppercase",
            }}
            color="var(--black-7)"
          >
            {t("You've reached your limit custom fields")}
          </Typography>
        </ConsentDialog>
      )}
    </Page>
  );
};

export default CustomFields;
