import {
  Box,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  ExitToApp as LogoutIcon,
  Person as AccountIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { logout as logoutUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames";
import useStyles from "./HeaderStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";

interface HeaderSettingsProps {
  isSuperAdmin: boolean;
  user: Partial<ReturnUser>;
}

const HeaderSettings = ({ isSuperAdmin, user }: HeaderSettingsProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profileMenu, setProfileMenu] = useState<any>(null);

  return (
    <>
      <Box className={classes.settingsBox}>
        <IconButton
          id="header-profile-button"
          aria-haspopup="true"
          color="inherit"
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonSettings,
          )}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
          size="large"
        >
          <SettingsIcon classes={{ root: classes.settingsIcon }} />
        </IconButton>
      </Box>
      <Menu
        id="header-profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <Box sx={{ padding: "var(--space-8px) var(--space-16px)" }}>
          <Typography className={classes.menuTitle}>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography className={classes.menuSubTitle}>
            {user?.email}
          </Typography>
        </Box>
        <Divider sx={{ margin: "var(--space-8px) 0" }} />
        <Link
          style={{ textDecoration: "none" }}
          onClick={() => {
            setProfileMenu(null);
            navigate("/app/profile");
          }}
        >
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} />
            {t("My profile")}
          </MenuItem>
        </Link>
        {isSuperAdmin && (
          <Link
            style={{ textDecoration: "none" }}
            onClick={() => {
              setProfileMenu(null);
              navigate("/app/superadmin/companies");
            }}
          >
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AdminPanelSettingsIcon className={classes.profileMenuIcon} />
              {t("Super admin")}
            </MenuItem>
          </Link>
        )}
        <Divider sx={{ margin: "var(--space-8px) 0" }} />
        <Link
          onClick={() => dispatch(logoutUser())}
          style={{ textDecoration: "none" }}
        >
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <LogoutIcon className={classes.profileMenuIcon} />
            {t("Sign out")}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default HeaderSettings;
