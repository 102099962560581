import { backendApi } from "../api";
import {
  docDeleteTags,
  docListTags,
  docPatchTag,
  docPatchTagNew,
  listTag,
} from "./utils";
import {
  InvoicePlanTemplate,
  InvoicePlanTemplateWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { PlannedInvoice } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
const TEMPLATE_CACHE_TAG = "InvoicePlanTemplates";
export type ModifiedInvoicePlanTemplate = Omit<
  InvoicePlanTemplate,
  "companyId"
> & { id: InvoicePlanTemplate["_id"] };

export const invoiceplanApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceplanTemplate: build.query<
      InvoicePlanTemplateWithPermissions,
      any
    >({
      query: (id) => ({ url: `/invoiceplantemplates/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: docPatchTagNew(TEMPLATE_CACHE_TAG),
    }),
    getInvoiceplanTemplates: build.query<
      { data: InvoicePlanTemplateWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/invoiceplantemplates", params }),
      providesTags: docListTags(TEMPLATE_CACHE_TAG),
    }),
    createInvoiceplanTemplate: build.mutation<
      any,
      Partial<InvoicePlanTemplate>
    >({
      query: (data) => ({
        url: `/invoiceplantemplates`,
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(TEMPLATE_CACHE_TAG),
    }),
    patchInvoiceplanTemplate: build.mutation<
      { data?: PlannedInvoice; error?: any },
      ModifiedInvoicePlanTemplate & { id: ModifiedInvoicePlanTemplate["_id"] }
    >({
      query: ({ id, ...patch }) => ({
        url: `/invoiceplantemplates/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: docPatchTag(TEMPLATE_CACHE_TAG),
    }),
    deleteInvoiceplanTemplate: build.mutation<
      any,
      InvoicePlanTemplate["_id"] | string
    >({
      query: (id) => ({
        url: `/invoiceplantemplates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: docDeleteTags(TEMPLATE_CACHE_TAG),
    }),
  }),
});

export const {
  useCreateInvoiceplanTemplateMutation,
  useDeleteInvoiceplanTemplateMutation,
  useGetInvoiceplanTemplateQuery,
  useGetInvoiceplanTemplatesQuery,
  usePatchInvoiceplanTemplateMutation,
} = invoiceplanApi;

export default invoiceplanApi;
