/**
 *Created by Mikael Lindahl on 2023-04-19
 */

import getSetFormDataForInvoiceRows from "../getSetFormDataForInvoiceRows";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { StructureItemTable } from "../../components/Builders/Table/CommonBuilderTableTypes";

export const validateQuantity = ({
  articles,
}: {
  articles: (ContractArticle | Article)[] | undefined;
}) => {
  const validate: StructureItemTable["validate"] = ({ data, rowIndex }) => {
    const { articleId, isQuantityEstimate, quantity } = data[rowIndex];

    const quantityCurrent = quantity;

    const article = articles?.find((a) => String(a._id) === String(articleId));

    // Contract article
    if (
      article &&
      (article as ContractArticle).articleId &&
      (article as ContractArticle).quantityMin &&
      (article as ContractArticle)?.quantityMinValue !== undefined &&
      quantityCurrent !== undefined
    ) {
      return (article as ContractArticle)?.quantityMinValue <= quantityCurrent;
    } else if (quantity === 0 && !isQuantityEstimate) {
      return false;
    } else {
      return true;
    }
  };
  return validate;
};

export const getErrorTextQuantity: StructureItemTable["getErrorText"] = ({
  data,
  rowIndex,
}) => {
  const { isQuantityEstimate, quantity } = data[rowIndex];
  return quantity === 0 && !isQuantityEstimate
    ? "Can not be zero (only allowed for estimates)"
    : "Below minimal quantity";
};

export const setFormDataCustomQuantity = <DataType = any>({
  setFormData,
}: {
  setFormData: (data: DataType[]) => void;
}) => {
  const setFormDataCustom = getSetFormDataForInvoiceRows({
    setFormData,
    key: "quantity",
  });
  return setFormDataCustom;
};
