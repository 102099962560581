import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MailgunLogDetail from "./MailgunLogDetail";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import getStructure, { GetStructureExtraProps } from "./MailgunLogsStructure";
import useFormTable from "src/hooks/useFormTable";
import { MUIDataTableColumnDef } from "mui-datatables";
import { MailgunLog } from "src/accurasee-backend-types/app/mailgunlog/mailgunlog.types";
import { useGetMailgunLogsQuery } from "src/redux/services/MailgunLogService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";

const MailgunLogs = () => {
  const { data: logsQuery, isLoading: isLoadingLogs } = useGetMailgunLogsQuery({
    sort: "desc",
    sortBy: "updatedAt",
  });
  const logs = logsQuery?.data || [];
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<MailgunLog>();

  // add mailgun description
  const newLogs = [...logs].map((e) => {
    const { sender, recipient, subject } = e;
    return { ...e, description: `${sender} | ${recipient} | ${subject}` };
  });
  const { helperText, structure } = useFormTable<
    MailgunLog & { description: string },
    GetStructureExtraProps
  >({
    extraProps: {
      setOpenModal,
      setModalData,
    },
    initialFormData: newLogs,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: newLogs,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: newLogs.length >= 10,
  });

  return (
    <SectionContainer isLoading={isLoadingLogs}>
      <MuiDataTableWrapper
        data={newLogs}
        columns={columns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
      <MailgunLogDetail
        data={modalData}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
    </SectionContainer>
  );
};

export default MailgunLogs;
