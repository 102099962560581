import { backendApi } from "../api";
import { listTag } from "./utils";
import {
  IntegrationImportJob,
  IntegrationImportJobCreate,
  IntegrationImportGroup,
} from "../../accurasee-backend-types/app/integration_import_job/integration_import_job.types";

const CACHE_TAG = "IntegrationImportJobs";

export const integrationApi = backendApi.injectEndpoints({
  endpoints: (build) => {
    return {
      createIntegrationImportJob: build.mutation<
        any,
        IntegrationImportJobCreate[]
      >({
        query: (data) => {
          return { url: "/integration-import-job", method: "POST", data };
        },
        invalidatesTags: listTag(CACHE_TAG),
      }),
      getIntegrationImportJobGroups: build.query<
        {
          data: IntegrationImportGroup[];
          metadata: any;
        },
        any
      >({
        query: (params) => ({
          url: "/integration-import-job/group",
          params,
        }),
        providesTags: listTag(CACHE_TAG),
      }),
      getIntegrationImportJobsPendingAndRunning: build.query<
        {
          data: IntegrationImportJob[];
          metadata: any;
        },
        any
      >({
        query: (params) => ({
          url: "/integration-import-job/pending-and-running",
          params,
        }),
        providesTags: listTag(CACHE_TAG),
      }),
      deleteIntegrationImportJobs: build.mutation<any, any>({
        query: () => ({ url: "/integration-import-job", method: "DELETE" }),
        invalidatesTags: listTag(CACHE_TAG),
      }),
      runIntegrationImportJob: build.mutation<any, any>({
        query: () => {
          return { url: "/integration-import-job/run", method: "POST" };
        },
        invalidatesTags: listTag(CACHE_TAG),
      }),
    };
  },
  overrideExisting: false,
});

export const {
  useCreateIntegrationImportJobMutation,
  useDeleteIntegrationImportJobsMutation,
  useGetIntegrationImportJobGroupsQuery,
  useGetIntegrationImportJobsPendingAndRunningQuery,
  useRunIntegrationImportJobMutation,
} = integrationApi;
export default integrationApi;
