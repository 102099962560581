import { backendApi } from "../api";
import { listTag } from "./utils";

const CACHE_TAG_EXTERNAL = "DocumentationExternal";
export const documentationExternalApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentationExternal: build.query<{ data?: any; metadata?: any }, any>({
      query: (params) => ({ url: "/documentation-json", params }),
      providesTags: listTag(CACHE_TAG_EXTERNAL),
    }),
  }),
  overrideExisting: false,
});

export const { useGetDocumentationExternalQuery } = documentationExternalApi;

export default documentationExternalApi;
