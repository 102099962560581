import {
  getStructure,
  GetStructureExtraProps,
  ItemsTypes,
  toFormData,
  toSubmitData,
} from "./ConfigurationsDetailGetStructure";
import {
  useCreateCompanyConfigsSuperAdminHiddenComponentsMutation,
  useGetCompanyConfigsHiddenComponentsQuery,
  useUpdateCompanyConfigsSuperAdminHiddenComponentsMutation,
} from "src/redux/services/CompanyConfigsService";
import { HIDDEN_COMPONENT_TYPE } from "src/accurasee-backend-types/app/company_config/company_config.types";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import ByggkollenConfigIntegrations from "./Integrations/ByggkollenConfigIntegrations";
import CompanyConfigIntegrations from "./Integrations/CompanyConfigIntegrations";
import getHiddenComponent from "src/utils/getHiddenComponent";
import getContractTabStructure from "src/routes/App/Contracts/[contractId]/RoutesContractTabStructure";
import isDisableSubmit from "src/utils/isDisableSubmit";
import React from "react";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import SectionWithInnerBorder from "src/components/Basic/Simple/Sections/SectionWithInnerBorder";
import SidebarStructure from "src/components/Basic/Mixed/Sidebar/SidebarStructure";
import Tabs from "src/components/Basic/Mixed/Tabs/Tabs";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";

const CONFIGURATION_TABS = [
  "Hidden app components",
  "Logo",
  "Byggkollen",
  "Integrations",
  // "Colors",
];

const ConfigurationsDetail = ({ companyId }: { companyId: string }) => {
  const [t] = useTranslation();
  const { data: companyConfigsQuery, isLoading } =
    useGetCompanyConfigsHiddenComponentsQuery(companyId, { skip: !companyId });
  const config = companyConfigsQuery?.data;
  const hiddenComponentConfigs = config?.hiddenComponentConfigs || [];
  const [createCompanyConfigsSuperAdmin] =
    useCreateCompanyConfigsSuperAdminHiddenComponentsMutation();
  const [updateCompanyConfigsSuperAdmin] =
    useUpdateCompanyConfigsSuperAdminHiddenComponentsMutation();

  const routesContractTabStructure = getContractTabStructure({});
  // Structure is based on FE
  const companyConfigs = [
    HIDDEN_COMPONENT_TYPE.SIDEBAR,
    HIDDEN_COMPONENT_TYPE.CONTRACT_VIEW,
    HIDDEN_COMPONENT_TYPE.HEADER_LINK_MAIN_PAGE,
  ].map((type) => {
    const structure: any =
      type === HIDDEN_COMPONENT_TYPE.SIDEBAR
        ? SidebarStructure
        : type === HIDDEN_COMPONENT_TYPE.CONTRACT_VIEW
          ? [
              {
                label: routesContractTabStructure[0].name,
                children: routesContractTabStructure?.map((i) => ({
                  label: i.name,
                  link: i.mainRoute.path,
                })),
              },
            ]
          : [
              { label: "Help" },
              { label: "Search" },
              { label: "Notifications" },
            ];

    return {
      hiddenComponentType: type,
      hiddenComponents: structure?.map((item: any) => {
        const tabHiddenComponent = getHiddenComponent({
          name: item.label,
          type,
          hiddenComponentConfigs,
        });
        return {
          name: item.label,
          status:
            tabHiddenComponent?.status === undefined
              ? true
              : tabHiddenComponent?.status,
          subTabs: item?.children?.map((child: any) => {
            const subtabHiddenComponent = getHiddenComponent({
              name: child.label,
              type,
              hiddenComponentConfigs,
            });
            return {
              name: child.label,
              status:
                subtabHiddenComponent?.status === undefined
                  ? true
                  : subtabHiddenComponent?.status,
            };
          }),
        };
      }),
    };
  });

  const initialFormData = toFormData({
    data: companyConfigs,
    types: companyConfigs.map((config) => config.hiddenComponentType),
  });

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<any, GetStructureExtraProps, ItemsTypes>({
    initialFormData,
    extraProps: { structure: companyConfigs },
    getStructure,
  });

  const submitData = toSubmitData({
    data: formData,
    structure: companyConfigs,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update:
          // need companyId for both creating and updating
          !config && !isLoading
            ? createCompanyConfigsSuperAdmin
            : updateCompanyConfigsSuperAdmin,
      },
      data: {
        update: submitData,
      },
      dataId: companyId,
      name: "Configuration",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const submitDisabled = isDisableSubmit({
    data: submitData,
    initialData: { hiddenComponentConfigs: hiddenComponentConfigs },
    isValid: isFormValid,
  });

  return (
    <Tabs
      name="configurations"
      tabs={CONFIGURATION_TABS}
      tabContainerSx={{ marginTop: "var(--space-16px)" }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit({ action: "update" });
        }}
        ref={refForm}
      >
        <SectionContainer isLoading={isLoading}>
          {companyConfigs &&
            companyConfigs?.map((companyConfig, index) => {
              return (
                <React.Fragment key={index}>
                  <SectionWithInnerBorder
                    key={t(companyConfig.hiddenComponentType)}
                    label={t(companyConfig.hiddenComponentType)}
                    md={12}
                    boxStyle={{ padding: 0 }}
                  >
                    {BuildItems({
                      items: structure.items.filter(
                        (item) =>
                          item.itemType === companyConfig.hiddenComponentType,
                      ),
                      ...commonPropsBuildItem,
                    })}
                  </SectionWithInnerBorder>
                </React.Fragment>
              );
            })}
        </SectionContainer>
        <SectionWithButtonContainer>
          <ButtonGroup>
            <ButtonCancel
              onClick={() => {
                setFormData(initialFormData);
              }}
              disabled={submitDisabled}
            />
            <ButtonSubmitCore
              initialSubmitData={{
                hiddenComponentConfigs: hiddenComponentConfigs,
              }}
              isSubmitting={isSubmitting}
              isValid={isFormValid}
              label={hiddenComponentConfigs.length === 0 ? "create" : "save"}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={submitData}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </form>
      <p>Logos</p>
      <ByggkollenConfigIntegrations companyId={companyId} />
      <CompanyConfigIntegrations companyId={companyId} />
    </Tabs>
  );
};

export default ConfigurationsDetail;
