import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Types } from "mongoose";
import { listToDic } from "src/utils/transform";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetContractQuery } from "src/redux/services/ContractService";

export type ArticleWithContractArticle = Article | ContractArticle;

const useGetArticles = (contractId?: Types.ObjectId) => {
  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    contractId,
    { skip: !contractId },
  );

  const { data: articlesQuery, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);

  const articles = articlesQuery?.data;

  const contractArticleDic = listToDic(
    contract?.contractArticles || [],
    (c) => c.articleId,
  );

  const articlesWithContractArticleData = articles?.map((a) => {
    if (contractArticleDic[String(a._id)]) {
      return { ...a, ...contractArticleDic[String(a._id)], _id: a._id }; // _id is overwritten by row._id
    } else {
      return a;
    }
  }) as ArticleWithContractArticle[];

  return {
    articles,
    articlesWithContractArticleData,
    contract,
    isLoading: isLoadingArticles || isLoadingContract,
  };
};

export default useGetArticles;
