export const INTEGRATION_NAMES = ["Fortnox", "Visma", "3L"] as const;
export type IntegrationName = (typeof INTEGRATION_NAMES)[number];

export const SOURCE_VALUES = [...INTEGRATION_NAMES, "Internal"] as const;
export type Source = (typeof SOURCE_VALUES)[number];

export interface ImportResponse {
  upsertedCount: number;
  modifiedCount: number;
  message?: string;
}

export type RequestData = Record<string, unknown>;
export type Params = Record<string, string>;
