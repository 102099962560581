import {
  HelperText,
  StructureItemContainer,
} from "../CommonBuilderContainerTypes";
import { getNestedObjectData } from "../../../../utils/nestedData";
import { TFunction } from "../../../../hooks/useTranslationWrapper";

/**
 *Created by Mikael Lindahl on 2023-03-14
 */

type GetHelperTextTProps = {
  helperText?: HelperText;
  item: StructureItemContainer;
  t: TFunction;
};

const getHelperText = (props: GetHelperTextTProps) => {
  let helperText =
    props.helperText &&
    getNestedObjectData({
      data: props.helperText,
      key: props.item.dataName as string,
    });

  return props.item.getErrorTextNoTranslation
    ? helperText
    : props.t(helperText);
};

export default getHelperText;
