import { Cost } from "src/accurasee-backend-types/app/cost/cost.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCostsTableQuery } from "src/redux/services/CostService";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useGetSubcontractsQuery } from "src/redux/services/SubcontractService";
import { useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import CostsSubtable from "./CostSubtable";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ForwardCostsModal from "src/components/Complex/Modals/ForwardCostsModal";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import getStructure, {
  getForwardableCosts,
  getReassignableCosts,
  GetStructureExtraProps,
} from "./CostsTableStructure";
import getSummaries from "src/utils/getSummaries";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import ReassignCostsModal from "src/components/Complex/Modals/ReassignCostModal";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useFormTable from "src/hooks/useFormTable";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

const CostsTable = () => {
  const { t } = useTranslation();
  const { contractId } = useParams<{ contractId: string }>();
  const [openForwardModal, setOpenForwardModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);

  const { data: articlesQueryResult, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);

  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const reportYFilters =
    reportFiltersQuery?.data?.filter(
      (filter) => filter.transactionType === "cost",
    ) || [];

  const { data: subContractQueryResult, isLoading: isLoadingSubcontracts } =
    useGetSubcontractsQuery({ contractId });

  const articles = articlesQueryResult?.data || [];
  const subcontracts = subContractQueryResult?.data || [];

  const [fileDownloading, setFileDownloading] = useState({});

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const {
    options,
    data: costs,
    isLoading: isLoadingCosts,
  } = useMUIDataTableOptionsServerSide<Cost>({
    dataName: "costs",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        sortBy: "transactionDate",
        sort: "desc",
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      selectableRows: "none",
      selectToolbarPlacement: "none",
      searchPlaceholder: `${t("Search for")} ${t(
        "Description",
      ).toLowerCase()}, ${t("Account number").toLowerCase()} ${t("or")} ${t(
        "Account name",
      ).toLowerCase()}...`,
      customButtonsOnToolbar: [
        {
          buttonType: "icon",
          buttonElement: (
            <ExportButton
              buttonType="icon"
              data={() => exportDataRef.current}
              fileName="contract-costs.xlsx"
              fileType="xlsx"
            >
              <FileDownloadIcon />
            </ExportButton>
          ),
          showButton: true,
          title: "Export",
        },
        {
          actionFunction: () => setOpenForwardModal(true),
          showButton: ({ data }) => getForwardableCosts(data).length > 0,
          title: "Forward costs to invoices",
        },
        {
          actionFunction: () => setOpenReassignModal(true),
          showButton: ({ data }) =>
            getReassignableCosts(data).length > 0 && subcontracts.length > 0,
          title: "Re-assign costs to ATA",
        },
      ],
    },
    isRowExpandable: (data, dataIndex: number) => {
      return data.length !== dataIndex;
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const cost = costs[rowMeta.dataIndex];
      const params = {
        contractId: contractId!,
        name: cost.name,
        unitExternalId: cost.unitExternalId,
      };

      return (
        <SubTableContainer>
          <CostsSubtable articles={articles} params={params} />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: ["contractId"],
    useGetDataQuery: useGetCostsTableQuery,
  });

  const { idSummaries, dataWithSummaries: costsWithSummaries } = getSummaries({
    data: costs,
    sumTextKey: "name",
    keys: [{ name: "costPerUnit" }, { name: "quantity" }, { name: "value" }],
  });

  const { structure: costTableStructure } = useFormTable<
    Partial<Cost>,
    GetStructureExtraProps
  >({
    extraProps: {
      reportYFilters,
      articles,
      currencyCode: companyQuery?.company?.currencyCode || "SEK",
      fileDownloading,
      idSummaries,
      setFileDownloading,
    },
    initialFormData: costs,
    getStructure,
  });

  const costColumns: MUIDataTableColumnDef[] = costTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle({
        data: costsWithSummaries,
        disabled: true,
        item,
      }),
  );

  const forwardableCosts = getForwardableCosts(costs);
  const reAssignableCosts = getReassignableCosts(costs);

  const isLoading =
    isAllReportTemplateYFiltersLoading ||
    isLoadingArticles ||
    isLoadingCompany ||
    isLoadingCosts ||
    isLoadingCosts ||
    isLoadingSubcontracts;

  return (
    <>
      <MuiDataTableWrapper
        isFetching={isLoading}
        data={costsWithSummaries || []}
        columns={costColumns}
        options={{
          ...options,
          onTableChange: (action, tableState) => {
            exportDataRef.current = getExportData(tableState);
          },
        }}
        tableType="main-with-filter"
        noBackground={true}
      />
      <ForwardCostsModal
        articles={articles}
        costs={forwardableCosts}
        extraProps={{
          articles,
          fileDownloading,
          setFileDownloading,
        }}
        isLoading={isLoading}
        handleCloseModal={() => setOpenForwardModal(false)}
        openModal={openForwardModal}
      />
      <ReassignCostsModal
        articles={articles}
        costs={reAssignableCosts}
        extraProps={{
          articles,
          fileDownloading,
          setFileDownloading,
          subcontracts,
        }}
        isLoading={isLoading}
        handleCloseModal={() => setOpenReassignModal(false)}
        openModal={openReassignModal}
      />
    </>
  );
};

export default CostsTable;
