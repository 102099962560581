/**
 *Created by Mikael Lindahl on 2023-01-09
 */

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import useStyles from "./CommonStyles";
import usePreventWheelScrollChangeNumberInput from "src/hooks/usePreventWheelScrollChangeNumberInput";
import throwErrorDataName from "../Utils/throwErrorDataName";
import useTranslation from "src/hooks/useTranslationWrapper";
import getHelperText from "../Utils/getHelperText";
import getValue from "../Utils/getValue";
import setValue from "../Utils/setValue";
import parseValue from "../Utils/parseValue";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";

type InvoicingDateItemTProps = CommonBuilderContainerTypes;

const InvoicingDateItem = (props: InvoicingDateItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);
  const classes = useStyles();
  const textFieldRef = usePreventWheelScrollChangeNumberInput();

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "InvoicingDateItem",
    });
  }

  const required = getRequired(commonCallbackProps);
  const value = getValue(commonCallbackProps);

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel
        required={required}
        shrink
        htmlFor={props.item.dataName}
        className={classes.labelFieldStyle}
      >
        {t(props.item.label || "")}
      </InputLabel>
      <FormControl disabled={props.itemDisabled}>
        <RadioGroup
          row
          name={props.item.dataName}
          value={value || ""}
          onChange={(e) => {
            let changeProps = {
              ...commonCallbackProps,
              value: e.target.value === "custom" ? 1 : e.target.value,
            };

            if (props.item.setFormDataCustom) {
              props.item.setFormDataCustom(changeProps);
            } else {
              setValue({ ...changeProps });
            }
          }}
          className={classes.fixLabelRadioGroup}
        >
          <FormControlLabel
            value="last"
            control={<Radio />}
            label={
              <Box style={{ paddingTop: "9px" }}>
                {`Last of ${props?.data?.invoicingPeriod?.repeats?.unit}`}
              </Box>
            }
          />
          <FormControlLabel
            value="first"
            control={<Radio />}
            label={
              <Box style={{ paddingTop: "9px" }}>
                {`First of next ${props?.data?.invoicingPeriod?.repeats?.unit}`}
              </Box>
            }
          />
          {props.data?.invoicingPeriod?.repeats?.unit === "month" && (
            <FormControlLabel
              value="custom"
              control={<Radio />}
              checked={value !== "first" && value !== "last"}
              label={
                <>
                  <div
                    style={{
                      display: "inline-block",
                      paddingTop: "9px",
                      paddingRight: "9px",
                    }}
                  >
                    Custom
                  </div>
                  <TextField
                    ref={textFieldRef}
                    disabled={
                      props.itemDisabled ||
                      value === "first" ||
                      value === "last"
                    }
                    sx={{
                      "& .MuiInputBase-formControl input": {
                        padding: "var(--space-8px) var(--space-12px)",
                        width: "36px",
                      },
                    }}
                    type="number"
                    helperText={getHelperText(getHelperTextProps)}
                    error={Boolean(getHelperText(getHelperTextProps))}
                    onChange={(event) => {
                      let changeProps = {
                        ...commonCallbackProps,
                        value: parseValue({
                          value: event.target.value,
                          parse: props.parse,
                        }),
                      };
                      if (props.item.setFormDataCustom) {
                        props.item.setFormDataCustom(changeProps);
                      } else {
                        setValue({ ...changeProps });
                      }
                    }}
                    value={getValue(commonCallbackProps) || ""}
                  />
                </>
              }
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  ) : (
    <></>
  );
};

export default InvoicingDateItem;
