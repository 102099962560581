import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptions, {
  BuilderColumnOptionsBaseTProps,
} from "./BuilderColumnOptionsBase";
import { StructureItemTable } from "./CommonBuilderTableTypes";
import useTranslation from "../../../hooks/useTranslationWrapper";

type BuilderColumnOptionsListTProps<DataType = any> = Omit<
  BuilderColumnOptionsBaseTProps,
  "t" | "item"
> & { items: StructureItemTable<DataType>[] };

const BuilderColumnOptionsList: <DataType = any>(
  props: BuilderColumnOptionsListTProps<DataType>,
) => MUIDataTableColumnDef[] = ({ items, ...rest }) => {
  const [t] = useTranslation();

  return items.map((item) =>
    BuilderColumnOptions({
      item,
      ...rest,
      t,
    }),
  );
};

export default BuilderColumnOptionsList;
