/**
 *Created by Mikael Lindahl on 2024-01-02
 */

import BuildItems from "../BuildItems";
import React from "react";
import { BuildItemsTProps } from "../BuildItems";

const useBuildItems = (props: BuildItemsTProps) => {
  const [isTyping, setIsTyping] = React.useState(false);

  return {
    BuildItems: <BuildItems {...props} setIsTyping={setIsTyping} />,
    isTyping,
  };
};

export default useBuildItems;
