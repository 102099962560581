import { useState } from "react";
import useTranslation from "src/hooks/useTranslationWrapper";
import { MUIDataTableColumnDef } from "mui-datatables";

import { useGetUserRolesQuery } from "src/redux/services/UserRolesService";
import {
  useGetInvitesQuery,
  useInvalidateInviteMutation,
} from "src/redux/services/InviteService";

import { Invite } from "src/accurasee-backend-types/app/invite/invite.types";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";

import useFormTable from "src/hooks/useFormTable";
import useOnSubmit from "src/hooks/useOnSubmit";

import CompanyInviteModalWithForm from "./CompanyInviteModalWithForm";
import { getStructure, GetStructureProps } from "./InvitesGetStructure";
import SectionTight from "../../../../../components/Basic/Mixed/Sections/SectionTight";
import useMUIDataTableOptionsServerSide from "../../../../../hooks/useMUIDataTableOptionsServerSide";
import ButtonPrimary from "../../../../../components/Basic/Simple/Buttons/ButtonPrimary";
import usePathPermission from "../../../../../hooks/usePathPermission";

const Invites = () => {
  const [t] = useTranslation();
  const pathPermissions = usePathPermission("/app/setup/members");

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [invalidateInvite] = useInvalidateInviteMutation();

  const { data: userRolesQuery, isLoading: isLoadingUserRoles } =
    useGetUserRolesQuery({ undefined });

  const userRoles = userRolesQuery?.data || [];

  const {
    options,
    data: invites,
    isLoading: isLoadingInvites,
    isFetching,
  } = useMUIDataTableOptionsServerSide({
    defaultPaginationOptions: [5, 10, 15, 50],
    filterInit: {
      base: { pageSize: 10, sort: "desc", sortBy: "updatedAt" },
    },

    useGetDataQuery: useGetInvitesQuery,
  });

  const onSubmit = useOnSubmit({
    apiMutations: { update: invalidateInvite },
    name: "- Invite has been invalidated",
  });
  const { formData, helperText, structure } = useFormTable<
    Invite,
    GetStructureProps
  >({
    extraProps: { userRoles, onSubmitInviteUpdate: onSubmit },
    initialFormData: invites,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<Invite>({
      data: formData,
      helperText,
      item,
      disabled: true, // table is now readonly
    }),
  );

  return (
    <SectionTight
      label={"Invites"}
      containsTable
      rightBoxArea={
        <ButtonPrimary
          id="send-invite-button"
          onClick={() => setOpenInviteModal(true)}
          permissions={pathPermissions}
          permissionType={"writeRights"}
        >
          {t("Send invite")}
        </ButtonPrimary>
      }
      isLoading={isLoadingUserRoles || isLoadingInvites}
    >
      <MuiDataTableWrapper
        isFetching={isFetching}
        data={formData || []}
        columns={columns}
        options={options}
        tableType="compact"
      />
      {openInviteModal && (
        <CompanyInviteModalWithForm
          openModal={openInviteModal}
          onClose={() => setOpenInviteModal(false)}
        />
      )}
    </SectionTight>
  );
};

export default Invites;
