import { CommonCallbackProps } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";

export type GetStructureExtraProps = {
  onDelete: (props: CommonCallbackProps) => void;
  onEdit: (props: CommonCallbackProps) => void;
};
export const getStructure: UserFormTableGetStructureOptional<
  CustomField,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "fieldType",
        headerLabel: "Field type",
      },
      {
        type: "text",
        dataName: "appliedToSections",
        headerLabel: "Applied to",
      },
      {
        type: "text",
        dataName: "selectOptionValues",
        headerLabel: "Option values",
      },
      {
        dataName: "required",
        headerLabel: "Required",
        iconType: "check",
        type: "icon",
        alignColumnContent: "center",
        showCellWhen: ({ data, rowIndex }) => {
          return !!data[rowIndex]?.required;
        },
      },
      {
        type: "action_row",
        iconType: "edit",
        dataName: "edit",
        alignColumnContent: "end",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEdit) {
                props?.extraProps?.onEdit(onClickProps);
              }
            },
          },
          {
            elementType: "icon",
            iconType: "delete_no_cross",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onDelete) {
                props?.extraProps?.onDelete(onClickProps);
              }
            },
          },
        ],
      },
    ],
  };
};
