/**
 *Created by Mikael Lindahl on 2023-11-23
 */

import { Typography, Typography as MuiTypography } from "@mui/material";
import Box from "@mui/material/Box";
import useTranslation from "src/hooks/useTranslationWrapper";

const Label = (props: { label: string }) => {
  const [t] = useTranslation();

  return (
    <MuiTypography
      variant="body1"
      textTransform="uppercase"
      fontWeight="bold !important"
      fontSize="18px !important"
      marginBottom={"auto"}
    >
      {t(props.label)}
    </MuiTypography>
  );
};

const BoxHeadingLabel = (props: {
  label?: string;
  labelBadge?: JSX.Element;
}) => {
  return props?.label ? (
    props?.labelBadge ? (
      <Box sx={{ display: "flex", columnGap: "var(--space-16px)" }}>
        <Label label={props.label} />
        <Typography sx={{ color: "var(--black-7)" }}>—</Typography>
        {props?.labelBadge}
      </Box>
    ) : (
      <Label label={props.label} />
    )
  ) : (
    <></>
  );
};

export default BoxHeadingLabel;
