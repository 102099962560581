import { Company } from "src/accurasee-backend-types/app/company/company.types";
import { OnSubmit } from "src/hooks/useOnSubmit";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { Warning } from "src/accurasee-backend-types/app/warning/warning.types";
import clone from "src/utils/clone";
import Constants from "src/constants/Constants";

export interface GetStructureExtraProps {
  companies: Company[] | undefined;
  onSubmitActiveSwitch: OnSubmit;
  setOpenEditModal: (v: boolean) => void;
  setEditModalData: (data: Warning) => void;
}

const getStructure: UserFormTableGetStructure<
  Warning,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "text_with_prefix_icon",
      dataName: "issueType",
      headerLabel: "Type",
      sx: { width: "100%" },
    },
    {
      type: "badge",
      dataName: "criticalLevel",
      headerLabel: "Critical level",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "companyId",
      headerLabel: "Company",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) =>
        extraProps?.companies?.find(
          (company: Company) => company._id === data[rowIndex]?.companyId,
        )?.displayName,
    },
    {
      type: "text",
      dataName: "message",
      headerLabel: "Description",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) => {
        const name = data[rowIndex]?.message || "";
        const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
        return name.length > maxLength
          ? name.slice(0, maxLength) + "..."
          : name;
      },
    },
    {
      type: "switch",
      dataName: "active",
      cellLabel: "Active",
      setFormDataCustom: (propsCustom) => {
        let newData = clone(propsCustom.data);
        newData[propsCustom.rowIndex][propsCustom.item.dataName as string] =
          propsCustom.value;
        if (setFormData) {
          setFormData(newData);
        }
        if (extraProps?.onSubmitActiveSwitch) {
          extraProps?.onSubmitActiveSwitch({
            data: { update: { active: propsCustom.value } },
            dataId: String(newData[propsCustom.rowIndex]._id),
          });
        }
      },
    },
    {
      type: "icon_button",
      iconType: "edit",
      dataName: "edit",
      onClick: ({ rowIndex, data }) => {
        extraProps?.setOpenEditModal(true);
        extraProps?.setEditModalData(data[rowIndex]);
      },
    },
  ],
});

export default getStructure;
