/**
 *Created by Mikael Lindahl on 2024-03-19
 */
import { Box, Card, Typography } from "@mui/material";
import ButtonAdd from "src/components/Basic/Simple/Buttons/ButtonAdd";

type IntegrationImportTProps = {
  disabled?: boolean;
  label: string;
  onImport: () => void;
};

const IntegrationImport = (props: IntegrationImportTProps) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 var(--space-8px) 0 var(--space-16px)",
        }}
      >
        <Typography>{props.label}</Typography>
        <ButtonAdd
          disabled={props.disabled}
          onClick={() => props.onImport()}
          sx={{ marginTop: "0px" }}
        />
      </Box>
    </Card>
  );
};

export default IntegrationImport;
