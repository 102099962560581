import {
  AccessUserRole,
  AccessUserRoleWithPermissions,
} from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { backendApi } from "../api";

const CACHE_TAG = "UserRoles";

const userRolesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getUserRoles: build.query<
      { data?: AccessUserRoleWithPermissions[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/userroles", params }),
      providesTags: [CACHE_TAG],
    }),
    createUserRole: build.mutation<{ data?: AccessUserRole[] }, AccessUserRole>(
      {
        query: (data) => ({ url: "/userroles", method: "POST", data }),
        invalidatesTags: [CACHE_TAG, "Permissions"],
      },
    ),
    patchUserRole: build.mutation<
      any,
      Partial<AccessUserRole> & { id?: AccessUserRole["_id"] }
    >({
      query: ({ id, ...data }) => ({
        url: `/userroles/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [CACHE_TAG, "Permissions"],
    }),
    deleteUserRole: build.mutation<any, AccessUserRole["_id"] | string>({
      query: (id) => ({ url: `/userroles/${id}`, method: "DELETE" }),
      invalidatesTags: [CACHE_TAG],
    }),
  }),
});

export const {
  useCreateUserRoleMutation,
  useDeleteUserRoleMutation,
  useGetUserRolesQuery,
  usePatchUserRoleMutation,
} = userRolesApi;

export default userRolesApi;
