import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      backgroundColor: "var(--white-primary)",
    },
  }),
);

export default useStyles;
