import {
  CriticalLevel,
  Warning,
  WarningType,
} from "src/accurasee-backend-types/app/warning/warning.types";
import {
  SidebarState,
  updateSidebarState,
} from "src/redux/slices/sidebarState";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  LinearProgress,
  Link,
} from "@mui/material";
import { RootState } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetAllWarningsQuery } from "src/redux/services/WarningService";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import useTranslation from "src/hooks/useTranslationWrapper";
import { useGetIntegrationConfigQuery } from "src/redux/services/IntegrationService";

const WarningContainer = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // to check if re-auth needed
  const { data: integrationConfigQuery } =
    useGetIntegrationConfigQuery(undefined);

  // global
  const sidebarState = useSelector<RootState, SidebarState>(
    ({ sidebarState }) => sidebarState,
  );
  const [currentWarnings, setCurrentWarnings] = useState<Warning[]>([]);
  const { data: queryResult, isFetching } = useGetAllWarningsQuery(undefined);
  const warnings =
    queryResult?.data.filter((warning: Warning) => warning.active) || [];

  useEffect(() => {
    const newWarnings = integrationConfigQuery?.accounting.reAuthNeeded
      ? [
          {
            message: "Fortnox re-authorization required",
            issueType: WarningType.UserAction,
            criticalLevel: CriticalLevel.Critical,
            active: true,
          },
          ...warnings,
        ]
      : warnings;
    setCurrentWarnings(newWarnings);
    dispatch(
      updateSidebarState({
        ...sidebarState,
        isWarnings: newWarnings?.length > 0,
      }),
    );
  }, [queryResult, integrationConfigQuery]);

  if (!currentWarnings || currentWarnings?.length === 0) return <></>;

  const styling = getStylingByStatusType(
    currentWarnings[0]?.criticalLevel === CriticalLevel.Critical
      ? "alert"
      : currentWarnings[0]?.criticalLevel === CriticalLevel.Low
        ? "warning-1"
        : "success-3",
  );

  const type =
    currentWarnings[0]?.issueType === WarningType.ProductionIssue
      ? "error"
      : currentWarnings[0]?.issueType === WarningType.UserAction
        ? "warning"
        : "info";

  return (
    <Box sx={{ width: "100%" }}>
      {isFetching ? (
        <LinearProgress />
      ) : (
        <Alert
          variant="filled"
          severity={type as AlertColor}
          onClose={() => {
            const nextWarnings = currentWarnings.slice(1);
            dispatch(
              updateSidebarState({
                ...sidebarState,
                isWarnings: nextWarnings?.length > 0,
              }),
            );
            setCurrentWarnings(nextWarnings);
          }}
          sx={{
            ...styling,
            borderRadius: 0,
            padding: "0 var(--space-24px)",
            "& .MuiAlertTitle-root": {
              fontWeight: "bold",
              marginBottom: "var(--space-4px)",
            },
            "& .MuiAlert-message": { padding: "6px 0" },
          }}
        >
          <AlertTitle>{t(currentWarnings[0]?.issueType)}</AlertTitle>
          {t(currentWarnings[0]?.message)}
          {currentWarnings[0]?.page && (
            <Link
              sx={{
                color: styling.color,
                cursor: "pointer",
                fontWeight: "600",
                margin: "0 var(--space-4px)",
                textDecoration: "none",
                "&:hover": { borderBottom: "1px solid" },
              }}
              onClick={() => {
                currentWarnings[0]?.page && navigate(currentWarnings[0]?.page);
              }}
            >
              {t("View more")}
            </Link>
          )}
        </Alert>
      )}
    </Box>
  );
};

export default WarningContainer;
