import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import { setFormDataDatePair } from "src/utils/date";

import Constant from "src/constants/Constants";

const getStructure: UseFormContainerGetStructure<{ from: Date; to: Date }> = ({
  setFormData,
}) => {
  const structure: StructureContainer<{ from: Date; to: Date }> = {
    items: [
      {
        type: "date",
        dataName: "from",
        label: "From",
        inputFormat: "yyyy-MM",
        views: ["year", "month"],
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "to",
            otherAction: "to_future_of_year",
            rangeDiff: Constant.COMPANY_CASHFLOW_DATE_RANGE,
            value,
            valueKey: "from",
            setFormData,
          });
        },
      },
      {
        type: "date",
        dataName: "to",
        label: "To",
        inputFormat: "yyyy-MM",
        views: ["year", "month"],
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "from",
            otherAction: "to_past_of_year",
            rangeDiff: Constant.COMPANY_CASHFLOW_DATE_RANGE,
            value,
            valueKey: "to",
            setFormData,
          });
        },
      },
    ],
  };

  return structure;
};

export const getYearStructure: UseFormContainerGetStructure<{
  year: Date;
}> = ({ setFormData }) => {
  const structure: StructureContainer<{ year: Date }> = {
    items: [
      {
        type: "date",
        dataName: "year",
        label: "Year",
        inputFormat: "yyyy",
        views: ["year"],
        maxDate: new Date(),
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
