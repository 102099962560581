import { backendApi } from "../api";
import { docPatchTag, listTag } from "./utils";
import { Warning } from "src/accurasee-backend-types/app/warning/warning.types";

const CACHE_TAG = "Warnings";

export const warningsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getAllWarnings: build.query<{ data: Warning[] }, any>({
      query: () => ({
        url: "/warning",
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getWarningSuperAdmin: build.query<{ data: Warning[] }, any>({
      query: () => ({
        url: "/superadmin/warning",
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getWarning: build.query<{ data: Warning }, any>({
      query: (id) => ({
        url: `/superadmin/warning/${id}`,
      }),
      transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TAG),
    }),
    createWarningSuperAdmin: build.mutation({
      query: (data) => ({
        url: "/superadmin/warning",
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    updateWarningSuperAdmin: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/superadmin/warning/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWarningQuery,
  useGetWarningSuperAdminQuery,
  useGetAllWarningsQuery,
  useCreateWarningSuperAdminMutation,
  useUpdateWarningSuperAdminMutation,
} = warningsApi;

export default warningsApi;
