/**
 *Created by Mikael Lindahl on 2023-10-16
 */
import moment from "moment-timezone";

function getMonthAsString(offset: number) {
  return moment().add(offset, "months").format("MMMM");
}

export default getMonthAsString;
