/**
 *Created by Mikael Lindahl on 2024-07-11
 */
import sort from "./sort";

export type SelectOption = {
  disabled?: boolean;
  label: string;
  value: string;
  isActive: boolean;
};

type BaseOption = { [key: string]: any }; // Base type for minimum requirement
type OptionWithActive = BaseOption & { active?: boolean; disabled?: boolean };
type OptionWithIsActive = BaseOption & {
  isActive?: boolean;
  disabled?: boolean;
};

type SelectOptionInput<T> = T & (OptionWithActive | OptionWithIsActive);

const getSelectOptions = <T extends BaseOption>({
  data,
  label = "label",
  value = "value",
  isSort = true,
}: {
  data?: (SelectOptionInput<T> | string | number)[];
  label?: string | ((d: T) => string);
  value?: string | number | ((d: T) => string | number);
  isSort?: boolean;
}) => {
  if (!data) return [] as SelectOption[];

  let options = data.map((d) => {
    if (typeof d === "number") {
      d = String(d);
    }

    let isActive = true;

    if (typeof d !== "string") {
      if (d.active !== undefined) {
        isActive = d.active;
      } else if (d.isActive !== undefined) {
        isActive = d.isActive;
      }
    }

    return {
      label:
        typeof d === "string"
          ? d
          : typeof label === "function"
            ? label(d)
            : d[label],
      value:
        typeof d === "string"
          ? d
          : typeof value === "function"
            ? value(d)
            : d[value],
      isActive,
      ...(typeof d !== "string" && d.disabled !== undefined
        ? { disabled: d.disabled || !isActive }
        : {}),
      // typeof d !== "string" && d.disabled !== undefined ? d.disabled : false,
    };
  });

  if (isSort) {
    options = sort(options, (v) => v.label);
  }

  return options;
};

export default getSelectOptions;
