import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ExtentedCost } from "src/components/Complex/Modals/ForwardCostsModal";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { validateQuantity } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowQuantity";
import { getCommonCostTableColumns } from "src/screens/App/Contracts/[contractId]/Costs/utils/getCommonCostTableColumns";

export interface GetStructureExtraProps {
  articles: Article[];
  fileDownloading: any;
  selectedRows?: ExtentedCost[];
  setFileDownloading: (data: String[]) => void;
}

export const getStructure: UserFormTableGetStructure<
  ExtentedCost,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const commonItems = getCommonCostTableColumns(
    extraProps?.articles || [],
    extraProps?.setFileDownloading!,
    extraProps?.fileDownloading,
    t,
  );
  return {
    items: [
      ...commonItems.filter((item) => item.type !== "icon_button"),
      {
        type: "date_input",
        dataName: "exportDate",
        headerLabel: "Export date",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        setFormData,
      },
      {
        type: "number_input",
        dataName: "quantity",
        headerLabel: "Quantity",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        validate: validateQuantity({ articles: extraProps?.articles }),
        setFormData,
      },
      {
        type: "switch",
        dataName: "includeQuantity",
        cellLabel: "Export quantity",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        setFormData,
      },
      {
        type: "number_input",
        dataName: "pricePerUnit",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        headerLabel: "Price per unit",
        setFormData,
      },
      {
        type: "switch",
        dataName: "includePricePerUnit",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        cellLabel: "Export price",
        setFormData,
      },
      {
        type: "switch",
        dataName: "includeFile",
        cellLabel: "Export file",
        disabled: ({ data, rowIndex }) => {
          return !extraProps?.selectedRows?.some(
            (row) => row._id === data[rowIndex]?._id,
          );
        },
        showCellWhen: ({ data, rowIndex }) => {
          const files = data[rowIndex]?.files || [];
          return files.length > 0;
        },
        showColumnWhen: ({ data }) => {
          return data.some((cost) => !!cost.files);
        },
        setFormData,
      },
      ...commonItems.filter((item) => item.type === "icon_button"),
    ],
  };
};

export default getStructure;
