import { unstable_usePrompt, useBeforeUnload } from "react-router-dom";
import useTranslation from "./useTranslationWrapper";
import { useEffect, useRef } from "react";

const useConfirmLeave = ({
  when,
  message = "You have unsaved changes. Are you sure you want to leave?",
}: {
  when: boolean;
  message?: string;
}) => {
  const [t] = useTranslation();

  const whenRef = useRef(when);

  useEffect(() => {
    whenRef.current = when;
  }, [when]);

  useBeforeUnload((event) => {
    // src/components/Builders/Container/ItemTypes/TextInputItem.tsx
    // TextInputItem only update formData when the input is blurred
    // this will blur all inputs so that the formData is updated
    // that's when the condition check will be up to date
    for (const el of Array.from(document.querySelectorAll("input"))) {
      el.blur();
    }

    if (whenRef.current) {
      event.preventDefault();
    }
  });

  unstable_usePrompt({
    message: t(message),
    when: ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && whenRef.current,
  });

  if (!message.trim()) {
    throw new Error("Message is required");
  }
};

export default useConfirmLeave;
