import {
  useCreateBudgetMutation,
  useGetBudgetQuery,
  usePatchBudgetMutation,
} from "src/redux/services/BudgetService";
import { Budget } from "src/accurasee-backend-types/app/budget/budget.types";
import { escape } from "src/utils/translate";
import { Types } from "mongoose";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BudgetAdjustmentRows from "./components/BudgetAdjustmentRowsTable";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import EconomyContainer from "./components/EconomyContainer";
import getStructure from "./EconomyStructure";
import moment from "moment-timezone";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useFormGlue from "src/hooks/useFormGlue";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useReload from "src/hooks/useReload";

export const Economy = ({ date }: { date?: Date }) => {
  const { reloadKey, reload } = useReload();
  const { contractId } = useParams<{ contractId: string }>();
  const [totalCostAdjustments, setCostTotalAdjustments] = useState<number>(0);
  const [totalIncomeAdjustments, setTotalIncomeAdjustments] =
    useState<number>(0);

  const initialFormData = {
    // Default date is today
    bookingDate: date || new Date(moment().format("YYYY-MM-DD")),
  };
  const { formData, helperText, structure } = useFormContainer<{
    bookingDate: Date;
  }>({ getStructure, initialFormData });

  const filter: any = {
    bookingDate: toDay(formData?.bookingDate),
    contractId,
  };
  const { data: budget, isLoading: isLoadingBudget } =
    useGetBudgetQuery(filter);
  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  const budgetId = budget?.budgetId?.toString();
  const action = budgetId ? "update" : "create";

  const [patchBudget] = usePatchBudgetMutation();
  const [createBudget] = useCreateBudgetMutation();

  const formGlueContainer = useFormGlue<Budget>();
  const formGlueTable = useFormGlue<Budget["adjustmentRows"]>();

  const data = {
    ...formGlueContainer.submitData,
    adjustmentRows: formGlueTable.submitData,
  };
  const initialData = {
    ...formGlueContainer.submitDataInitial,
    adjustmentRows: formGlueTable.submitDataInitial,
  };

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { create: createBudget, update: patchBudget },
      data: {
        create: data,
        update: data,
      },
      dataId: budgetId,
      name: "Budget",
    },
    onSuccess: () => {
      formGlueContainer.setHasTriedToSubmit(false);
      formGlueTable.setHasTriedToSubmit(false);
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      ref={refForm}
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit({ action });
      }}
    >
      <PageWithTopPadding
        label={"Project economy"}
        breadcrumbs={[
          { label: "Contracts", link: "/app/contracts" },
          {
            label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
            link: `/app/contracts/${contract?._id}`,
          },
          { label: "Project economy" },
        ]}
        isLoading={isLoadingBudget || isLoadingContract}
        boxRight={BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      >
        <SectionContainer sx={{ marginTop: "3rem" }}>
          {budget && (
            <EconomyContainer
              budgetReturn={budget!}
              contractId={new Types.ObjectId(contractId)}
              currencyCode={contract?.currencyCode || "SEK"}
              formGlue={formGlueContainer}
              key={"container" + reloadKey}
              totalCostAdjustments={totalCostAdjustments}
              totalIncomeAdjustments={totalIncomeAdjustments}
            />
          )}
          {budget?.budgetId && (
            <BudgetAdjustmentRows
              adjustmentRows={budget?.adjustmentRows || []}
              budgetId={budget?.budgetId!}
              contractId={contract?._id!}
              formGlue={formGlueTable}
              key={"table" + reloadKey}
              permissions={budget.permissions!}
              setTotalCostAdjustments={setCostTotalAdjustments}
              setTotalIncomeAdjustments={setTotalIncomeAdjustments}
            />
          )}
        </SectionContainer>

        {/* Floating Button Wrapper as a Footer*/}
        <SectionFloatingForButtons isFullWidth>
          <SectionWithButtonContainer
            buttonLeft={!budget?.budgetId}
            sx={{ margin: 0 }}
          >
            <ButtonGroup>
              <ButtonClear onClick={reload} />
              <ButtonCreateOrUpdate
                initialSubmitData={initialData}
                isSubmitting={isSubmitting}
                isValid={
                  formGlueContainer.isFormValid && formGlueTable.isFormValid
                }
                label={action}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  formGlueContainer.setHasTriedToSubmit(true);
                  formGlueTable.setHasTriedToSubmit(true);
                }}
                permissions={budget?.permissions}
                submitData={data}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </SectionFloatingForButtons>
      </PageWithTopPadding>
    </form>
  );
};

export default Economy;
