/**
 *Created by Mikael Lindahl on 2023-01-30
 */

import AutoFileItem from "./ItemTypes/AutoFileItem";
import AutocompleteItem from "./ItemTypes/AutocompleteItem";
import BadgeItem from "./ItemTypes/BadgeItem";
import CheckboxItem from "./ItemTypes/CheckboxItem";
import DateItem from "./ItemTypes/DateItem";
import FileItem from "./ItemTypes/FileItem";
import FillerItem from "./ItemTypes/FillerItem";
import InvoicingDateItem from "./ItemTypes/InvoicingDateItem";
import NumberItem from "./ItemTypes/NumberItem";
import ObjectItem from "./ItemTypes/ObjectItem";
import SelectorItem from "./ItemTypes/SelectorItem";
import SwitchItem from "./ItemTypes/SwitchItem";
import SwitchWithRightObjectItem from "./ItemTypes/SwitchWithRightObjectItem";
import TextInlineItem from "./ItemTypes/TextInlineItem";
import TextInputItem from "./ItemTypes/TextInputItem";
import TextItem from "./ItemTypes/TextItem";
import ToggleItem from "./ItemTypes/ToggleItem";
import _c from "src/constants/Constants";
import formatValueDisplayedCurrency from "../Utils/formatValueDisplayedCurrency";
import parseDisabled from "./Utils/getDisabled";
import parseNumberInput from "../../../utils/parseNumberInput";
import { BuildItemCommonTProps } from "./CommonBuilderContainerTypes";
import {
  CommonBuilderContainerTypes,
  StructureItemContainer,
} from "./CommonBuilderContainerTypes";

export type BuildItem = {
  item: StructureItemContainer;
  index: string;
} & BuildItemCommonTProps;

const BuildItemInternal = ({ item, index, data, ...rest }: BuildItem) => {
  const itemDisabled = rest.disabled || parseDisabled({ item, data });

  const commonProps: CommonBuilderContainerTypes = {
    key: index,
    data: data,
    helperText: rest.helperText,
    index,
    item,
    itemDisabled,
    setIsTyping: rest.setIsTyping,
    translationNamespace:
      rest.translationNamespace || _c.DEFAULT_TRANSLATION_NAMESPACE,
  };

  switch (item.type) {
    case "autocomplete_add":
      return <AutocompleteItem {...commonProps} />;
    case "autocomplete":
      return <AutocompleteItem {...commonProps} />;
    case "auto_file":
      return <AutoFileItem {...commonProps} />;
    case "badge":
      return <BadgeItem {...commonProps} />;
    case "checkbox":
      return <CheckboxItem {...commonProps} />;
    case "date":
      return <DateItem {...commonProps} />;
    case "file":
      return <FileItem {...commonProps} />;
    case "filler":
      return <FillerItem {...commonProps} />;
    case "invoicing_date":
      return <InvoicingDateItem {...commonProps} />;
    case "number":
      return <NumberItem {...commonProps} />;
    case "number_input":
      return (
        <TextInputItem
          {...commonProps}
          parse={(value) => parseNumberInput(value)}
        />
      );
    case "number_input_currency":
      return (
        <TextInputItem
          {...commonProps}
          isCurrency
          formatValueDisplayed={formatValueDisplayedCurrency}
          parse={(value) => {
            return isNaN(value) || value === "" ? value : Number(value);
          }}
        />
      );
    case "object":
      return <ObjectItem item={item} index={index} data={data} {...rest} />;
    case "switch_with_right_object":
      return (
        <SwitchWithRightObjectItem
          item={item}
          index={index}
          data={data}
          {...rest}
        />
      );
    case "text":
      return <TextItem {...commonProps} />;
    case "text_currency":
      return (
        <TextItem
          {...{
            ...commonProps,
            isCurrency: true,
          }}
        />
      );
    case "text_currency_integer":
      return (
        <TextItem
          {...{
            ...commonProps,
            isCurrency: true,
            parse: (value) => Math.round(value),
          }}
        />
      );
    case "text_input":
      return <TextInputItem {...commonProps} />;
    case "text_input_currency":
      return <TextInputItem {...{ ...commonProps, isCurrency: true }} />;
    case "text_inline":
      return <TextInlineItem {...commonProps} />;
    case "toggle":
      return <ToggleItem {...commonProps} />;
    case "selector":
      return <SelectorItem {...commonProps} />;
    case "selector_color":
      return <SelectorItem {...{ ...commonProps, isColor: true }} />;
    case "selector_icon":
      return (
        <SelectorItem
          {...{ ...commonProps, iconPosition: item.iconPosition || "start" }}
        />
      );
    case "switch":
      return <SwitchItem {...commonProps} />;
    case "url_input":
      return <TextInputItem {...commonProps} />;
    default:
      return <></>;
  }
};

export default BuildItemInternal;
