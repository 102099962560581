import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";

import StatusCell from "src/components/Complex/Tables/StatusCell";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";

import { toDate } from "src/utils/date";
import getTypeByStatus from "src/utils/getTypeByStatus";
import getStylingByStatusType from "src/utils/getStylingByStatusType";

import {
  MailgunLog,
  MAILGUNSTATUS,
} from "src/accurasee-backend-types/app/mailgunlog/mailgunlog.types";
import EditorView from "../EmailTemplates/EditorView";
import useMailgunLogStyles from "./MailgunLogDetailStyles";

interface MailgunLogDetailProps {
  data?: MailgunLog;
  openModal: boolean;
  handleCloseModal: () => void;
}

const MailgunLogDetail = ({
  data,
  openModal,
  handleCloseModal,
}: MailgunLogDetailProps) => {
  const [t] = useTranslation();
  const styles = useMailgunLogStyles();

  const type = getTypeByStatus(data?.deliveredStatus || "information");
  const styling = getStylingByStatusType(type);

  const IconByStatus =
    data?.deliveredStatus === MAILGUNSTATUS.delivered
      ? CheckCircleIcon
      : data?.deliveredStatus === MAILGUNSTATUS.other
        ? InfoIcon
        : CancelIcon;

  return (
    <ModalWrapper handleCloseModal={handleCloseModal} openModal={openModal}>
      <IconByStatus
        className={styles.icon}
        sx={{
          color: `${
            data?.deliveredStatus === MAILGUNSTATUS.other
              ? "var(--information-2)"
              : styling.color
          }`,
        }}
      />
      <Typography className={styles.title}>
        {t(data?.deliveredStatus || "")}
      </Typography>
      <Typography className={styles.subTitle}>{data?.eventId}</Typography>
      <Box className={styles.detailWrapper}>
        <Box>
          <Typography className={styles.label}>{t("From")}</Typography>
          <Typography className={styles.labelContent}>
            {data?.sender}
          </Typography>
          <Typography
            className={styles.label}
            sx={{ marginTop: "var(--space-8px)" }}
          >
            {t("Delivered status")}
          </Typography>
          <StatusCell
            status={data?.deliveredCode}
            additionalText={data?.deliveredMessage}
            sx={{
              width: "fit-content",
              ...styling, // same styling to Status
            }}
          />
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography className={styles.label}>{t("To")}</Typography>
          <Typography className={styles.labelContent}>
            {data?.recipient}
          </Typography>
          <Typography
            className={styles.label}
            sx={{ marginTop: "var(--space-8px)" }}
          >
            {t("Timestamp")}
          </Typography>
          <Typography className={styles.labelContent}>
            {toDate(data?.createdAt)}
          </Typography>
        </Box>
      </Box>
      <Typography
        className={styles.label}
        sx={{ marginTop: "var(--space-16px)" }}
      >
        {t("Subject")}
      </Typography>
      <Typography className={styles.labelContent}>{data?.subject}</Typography>
      <Box className={styles.content}>
        <EditorView html={data?.content || t("No content")} />
      </Box>
      <ButtonPrimary
        sx={{
          margin: "auto",
          marginTop: "var(--space-24px)",
          display: "block",
        }}
        onClick={() => handleCloseModal()}
      >
        {t("Done")}
      </ButtonPrimary>
    </ModalWrapper>
  );
};

export default MailgunLogDetail;
