/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { StructureItemTable } from "../CommonBuilderTableTypes";
import clone from "src/utils/clone";

type SetValueTProps = {
  data?: any;
  item: StructureItemTable;
  rowIndex: number;
  value: any;
};

const setValue = (props: SetValueTProps) => {
  if (props.data && props.item.setFormData) {
    let newData = clone(props.data);
    newData[props.rowIndex][props.item.dataName as string] = props.value;

    props.item.setFormData(newData);
  }
};

export default setValue;
