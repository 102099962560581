/**
 *Created by Mikael Lindahl on 2024-08-12
 */

import getValue from "./getValue";
import getOptions from "./getOptions";
import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getSelectedOptions = (props: CommonCallbackProps) => {
  const value = getValue(props);

  const valueEnsuredArray = Array.isArray(value) ? value : [value];

  let options = getOptions(props);

  const selectedOptions =
    options?.filter((option) => valueEnsuredArray?.includes(option.value)) ||
    null;

  return selectedOptions;
};

export default getSelectedOptions;
