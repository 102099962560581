import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

export interface LoginInputProps {
  loginEmail: string;
  loginPassword: string;
}
export const getStructure: UseFormContainerGetStructure<
  Partial<LoginInputProps>
> = ({ setFormData }) => {
  const structure: StructureContainer<Partial<LoginInputProps>> = {
    items: [
      {
        type: "text_input",
        dataName: "loginEmail",
        label: "Email",
        required: true,
        textInputType: "email",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        enableEnterKeyPress: true,
        setFormData,
      },
      {
        type: "text_input",
        dataName: "loginPassword",
        label: "Password",
        required: true,
        textInputType: "password",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        enableEnterKeyPress: true,
        setFormData,
      },
    ],
  };
  return structure;
};
