/**
 *Created by Mikael Lindahl on 2023-02-01
 */

import { useRef, useState } from "react";
import useOnSubmit, {
  OnSubmitReturn,
  PropsSubmit,
  UseOnSubmit,
} from "./useOnSubmit";

type UseOnFormSubmit<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = {
  submitProps: UseOnSubmit<DataTypeCreate, DataTypeUpdate, DataTypeDelete>;
  onPostSubmit?: (props: OnSubmitReturn) => void;
  onSuccess?: (
    props: OnSubmitReturn<DataTypeCreate, DataTypeUpdate, DataTypeDelete>,
  ) => void;
};

const useOnFormSubmit = <DataType = any>(props: UseOnFormSubmit<DataType>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const refForm = useRef<any>();

  const onSubmit = useOnSubmit(props.submitProps);

  const onFormSubmit = async (propsSubmit?: PropsSubmit) => {
    if (props) {
      setIsSubmitting(true);

      const result = await onSubmit(propsSubmit);

      if (props.onPostSubmit) props.onPostSubmit(result);

      setIsSubmitting(false);
      if (props.onSuccess && result.status === "success") {
        props.onSuccess(result);
      }
    }
  };

  return { isSubmitting, onFormSubmit, refForm };
};

export default useOnFormSubmit;
