import { BuilderContainerCallbackProps } from "../CommonBuilderContainerTypes";

/**
 *Created by Mikael Lindahl on 2023-03-14
 */

type GetShowWhenTProps = BuilderContainerCallbackProps;

const getShowWhen = (props: GetShowWhenTProps) => {
  return typeof props.item.showWhen === "boolean"
    ? props.item.showWhen
    : !props.item.showWhen || props.item.showWhen(props);
};

export default getShowWhen;
