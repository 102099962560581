import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    label: { fontSize: "12px" },
    collectionTarget: {
      color: "var(--black-7)",
      lineHeight: "42px",
    },
  }),
);

export default useStyles;
