/**
 *Created by Mikael Lindahl on 2024-02-05
 */

import PlannedInvoicesBase from "src/components/Screens/PlannedInvoices/PlannedInvoices";

const PlannedInvoices = () => {
  return <PlannedInvoicesBase />;
};

export default PlannedInvoices;
