import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { Language } from "src/accurasee-backend-types/app/language/language.types";

import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

export type GetStructureExtraProps = {
  languages?: Language[];
};

export const getStructure: UseFormContainerGetStructure<
  ReturnUser,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<ReturnUser> = {
    items: [
      {
        type: "text_input",
        dataName: "firstName",
        label: "First name",
        required: true,
        setFormData,
      },
      {
        type: "text_input",
        dataName: "lastName",
        label: "Last name",
        required: true,
        setFormData,
      },
      {
        type: "autocomplete",
        dataName: "locale",
        label: "Language",
        options: extraProps?.languages?.map((language: Language) => {
          return {
            label: language.nativeName,
            value: language.code,
            isActive: language.isActive,
          };
        }),
        required: true,
        selectionOptions: { disableClearable: true },
        setFormData,
      },
    ],
  };

  return structure;
};
