import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";
import SectionCore from "../../Mixed/Sections/SectionCore";

const SectionWithInnerBorder = (
  props: Omit<SectionCoreTProps, "innerBorderColor">,
) => {
  return (
    <SectionCore innerBorderColor="var(--gray-2)" {...props}>
      {props.children}
    </SectionCore>
  );
};

export default SectionWithInnerBorder;
