import { axios } from "src/general/Network";
import { backendApi } from "../api";
import {
  docDeleteTags,
  docPatchTag,
  docGetTag,
  getPlainListAndIdTags,
} from "./utils";
import { InvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import {
  BillingBaseReturn,
  PlannedInvoice,
  PlannedInvoiceGetList,
  PlannedInvoiceWithPermissions,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

export type PaginationPlannedInvoices = {
  data: PlannedInvoiceWithPermissions[];
  metadata?: any;
};

const CACHE_TYPE = "PlannedInvoices";
export const plannedInvoiceApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getPlannedInvoices: build.query<
      { data: PlannedInvoiceGetList[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/plannedinvoices", params }),
      providesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),
    getPlannedInvoice: build.query<PlannedInvoiceWithPermissions, any>({
      query: (id) => ({ url: `/plannedinvoices/${id}` }),
      transformResponse: (res) => res?.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    getPlannedInvoicesTotalAndSumThisYear: build.query<any, any>({
      query: (params) => ({
        url: "/plannedinvoices/total-and-sum-this-year",
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),
    getPlannedInvoicesTotalAndSum: build.query<any, any>({
      query: (params) => ({
        url: "/plannedinvoices/total-and-sum",
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),
    getPlannedInvoiceBillingBase: build.query<BillingBaseReturn[], any>({
      query: (id) => ({ url: `/plannedinvoices/${id}/billing-base` }),
      transformResponse: (res) => res?.data,
      providesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),
    patchPlannedInvoice: build.mutation<
      any,
      Partial<PlannedInvoice> & { id: PlannedInvoice["_id"] }
    >({
      query: ({ id, ...patch }) => ({
        url: `/plannedinvoices/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deletePlannedInvoice: build.mutation<any, PlannedInvoice["_id"]>({
      query: (id) => ({
        url: `/plannedinvoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
    exportPlannedInvoice: build.mutation<any, PlannedInvoice["_id"]>({
      query: (id) => ({
        url: `/plannedinvoices/${id}/export`,
        method: "PUT",
      }),
      invalidatesTags: getPlainListAndIdTags(
        CACHE_TYPE,
        "Invoiceplans",
        "CustomerInvoices",
        "Subcontracts",
      ),
    }),
    mergeSubContracts: build.mutation<
      { data: PlannedInvoice },
      {
        subcontractIds: SubcontractWithPermissions["_id"][];
        plannedInvoiceIds: SubcontractWithPermissions["plannedInvoiceIds"];
      }
    >({
      query: ({ subcontractIds, plannedInvoiceIds }) => ({
        url: "/plannedinvoices/merge",
        method: "PUT",
        data: {
          subcontractIds,
          plannedInvoiceIds,
        },
      }),
      invalidatesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),
    mergeAndApprovePlannedInvoices: build.mutation<
      { data: PlannedInvoice },
      { plannedInvoiceIds: PlannedInvoice["_id"][] }
    >({
      query: ({ plannedInvoiceIds }) => ({
        url: "/plannedinvoices/merge-and-approve",
        method: "PUT",
        data: { plannedInvoiceIds },
      }),
      invalidatesTags: getPlainListAndIdTags(CACHE_TYPE, "Invoiceplans"),
    }),
    previewPlannedInvoice: build.mutation<
      PlannedInvoiceGetList,
      { plannedInvoiceIds: PlannedInvoiceGetList["_id"][] }
    >({
      query: ({ plannedInvoiceIds }) => ({
        url: "/plannedinvoices/preview",
        method: "PUT",
        data: { plannedInvoiceIds },
      }),
      transformResponse: (res) => res?.data,
      invalidatesTags: getPlainListAndIdTags(CACHE_TYPE),
    }),

    createPlannedInvoicesFromInvoicePlan: build.mutation<
      { data: PlannedInvoice[] },
      { id: InvoicePlan["_id"]; rowIds?: string[] }
    >({
      query: ({ id, rowIds }) => ({
        url: `/plannedinvoices/fromInvoiceplan`,
        method: "POST",
        data: {
          invoicePlanId: id,
          invoicePlanRowIds: rowIds,
        },
      }),
      invalidatesTags: getPlainListAndIdTags(CACHE_TYPE, "Invoiceplans"),
    }),
    createPlannedInvoicesFromMultipleInvoicePlan: build.mutation<
      any,
      InvoicePlan[]
    >({
      query: (data) => ({
        url: `/plannedinvoices/fromInvoiceplans`,
        method: "POST",
        data,
      }),
      invalidatesTags: getPlainListAndIdTags(CACHE_TYPE, "Invoiceplans"),
    }),
  }),
  overrideExisting: false,
});

export const getPlannedInvoicePdf = (id: PlannedInvoice["_id"]) => {
  return axios.get(`/plannedinvoices/${id}/pdf`, {
    responseType: "blob",
  });
};

export const {
  useCreatePlannedInvoicesFromInvoicePlanMutation,
  useDeletePlannedInvoiceMutation,
  useExportPlannedInvoiceMutation,
  useGetPlannedInvoiceBillingBaseQuery,
  useGetPlannedInvoiceQuery,
  useGetPlannedInvoicesQuery,
  useGetPlannedInvoicesTotalAndSumQuery,
  useGetPlannedInvoicesTotalAndSumThisYearQuery,
  useMergeSubContractsMutation,
  usePatchPlannedInvoiceMutation,
  usePreviewPlannedInvoiceMutation,
  useMergeAndApprovePlannedInvoicesMutation,
} = plannedInvoiceApi;

export default plannedInvoiceApi;
