import { Box } from "@mui/material";
import { SxProps } from "@mui/material";

type BoxScrollWithShadowProps = {
  children: JSX.Element | JSX.Element[] | string;
  maxHeight?: string;
  sx?: SxProps;
};

const BoxScrollWithShadow = (props: BoxScrollWithShadowProps) => {
  return (
    <Box
      sx={{
        display: "block",
        maxHeight: props?.maxHeight || "500px",
        overflowY: "auto",
        background:
          "linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%",
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
        backgroundAttachment: "local, local, scroll, scroll",
        ...props?.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default BoxScrollWithShadow;
