/**
 *Created by Mikael Lindahl on 2024-05-23
 */

import { CollectionName } from "../../../accurasee-backend-types/app/general/collection.types";
import { MicrosoftDriveConfig } from "../../../accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

export function combine(paths: string[]) {
  return paths
    .map((path) => path.replace(/^[\\|/]/, "").replace(/[\\|/]$/, ""))
    .join("/")
    .replace(/\\/g, "/");
}

export function getProjectPath({
  collectionName,
  config,
  leafFolder,
}: {
  collectionName: CollectionName;
  config: MicrosoftDriveConfig;
  leafFolder?: string;
}) {
  let path = "";
  switch (collectionName) {
    case "contracts":
      path = "Project documents";
      break;
    case "contract_offers":
      path = "Contract offers documents";
      break;
  }

  return combine([
    config.storageSaveLocation,
    path,
    ...(leafFolder ? [leafFolder] : []),
  ]);
}

export function getTemplatePath(config: any) {
  return combine([config.storageTemplateLocation]);
}
