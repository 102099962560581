/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import Box from "@mui/material/Box";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import InvoicesTotals from "src/components/Complex/Tables/InvoicesTotals";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTable from "src/components/Basic/Simple/Sections/SectionForTable";
import useFormTable from "src/hooks/useFormTable";
import { FormGlue } from "src/hooks/useFormGlue";
import { Grid } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { addRow } from "../utils/PlannedInvoiceRowUtils";
import { useGetCustomerQuery } from "src/redux/services/CustomerService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  getStructure,
  GetStructureExtraProps,
} from "./PlannedInvoiceRowsTableStructure";
import {
  PlannedInvoiceRow,
  PlannedInvoiceRowExtended,
  PlannedInvoiceWithPermissions,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import ButtonAdd from "src/components/Basic/Simple/Buttons/ButtonAdd";
import useGetArticles from "src/hooks/useGetArticles";

type PlannedInvoiceRowsRewriteTProps = {
  formGlue?: FormGlue<PlannedInvoiceRow[]>;
  plannedInvoice?: PlannedInvoiceWithPermissions;
};

const PlannedInvoiceRows = ({
  formGlue,
  plannedInvoice,
}: PlannedInvoiceRowsRewriteTProps) => {
  const {
    articlesWithContractArticleData,
    contract,
    isLoading: isLoadingArticles,
  } = useGetArticles(plannedInvoice?.contractId);

  const { data: customer, isLoading: isLoadingCustomer } = useGetCustomerQuery(
    plannedInvoice?.customerId,
    { skip: plannedInvoice?.customerId === undefined },
  );

  const data: PlannedInvoiceRowExtended[] =
    plannedInvoice?.invoiceRows?.map((d, index) => ({
      ...d,
      rowNumber: index + 1,
    })) || [];

  const { formData, helperText, setFormData, setHelperText, structure } =
    useFormTable<PlannedInvoiceRowExtended, GetStructureExtraProps>({
      extraProps: {
        articles: articlesWithContractArticleData,
        customer,
        plannedInvoice,
      },
      formGlue,
      initialFormData: data,
      getStructure,
    });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<PlannedInvoiceRowExtended>({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionForTable
      label={"invoice rows"}
      isLoading={isLoadingArticles || isLoadingCustomer}
    >
      <MuiDataTableWrapper
        data={formData || []}
        columns={columns}
        options={options}
      />
      <Grid item xs={12}>
        <Box
          sx={{
            margin:
              "var(--space-8px) var(--space-32px) var(--space-32px) var(--space-32px)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <ButtonAdd
            label={"Add row"}
            onClick={() =>
              addRow({
                contract,
                formData,
                helperText,
                plannedInvoice,
                setFormData,
                setHelperText,
              })
            }
          />
          <Box
            sx={{
              marginLeft: "auto",
              width: "fit-content",
            }}
          >
            {plannedInvoice && (
              <InvoicesTotals
                invoicePlan={plannedInvoice}
                invoiceRows={formData}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </SectionForTable>
  );
};

export default PlannedInvoiceRows;
