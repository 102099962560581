import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";
import {
  useCreateCompanySuperAdminMutation,
  useUpdateCompanySuperAdminMutation,
} from "src/redux/services/CompanyService";

import BuildItems from "src/components/Builders/Container/BuildItems";
import {
  Company,
  CompanySuperAdminAddEdit,
} from "src/accurasee-backend-types/app/company/company.types";

import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

import getStructure, { GetStructureExtraProps } from "./CompanyModalStructure";
import ButtonPrimary from "../../../../components/Basic/Simple/Buttons/ButtonPrimary";
import _c from "src/constants/Constants";
import ButtonSecondary from "../../../../components/Basic/Simple/Buttons/ButtonSecondary";
import getChangedData from "../../../../utils/getChangedData";
import ModalWrapper from "../../../../components/Basic/Mixed/Modals/ModalWrapper";

interface AddCompanyModalProps {
  companyNames: string[];
  data?: Company;
  openModal: boolean;
  handleCloseModal: () => void;
}

const CompanyModalWithForm = (props: AddCompanyModalProps) => {
  const [t] = useTranslation();

  const [create] = useCreateCompanySuperAdminMutation();
  const [update] = useUpdateCompanySuperAdminMutation();

  const data: Partial<Company> = props.data
    ? props.data
    : {
        reminderTime: _c.DEFAULT_REMINDER_TIME,
        reminderTimeZone: _c.DEFAULT_REMINDER_TIMEZONE,
        bkConnection: false,
      };

  const {
    formData,
    helperText,
    isFormValid,
    // setHelperText,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    Partial<CompanySuperAdminAddEdit>,
    GetStructureExtraProps
  >({
    extraProps: {
      companyNames: props.companyNames,
      isEditForm: !!props.data,
    },
    getStructure,
    initialFormData: data,
  });

  const { onFormSubmit, refForm } = useOnFormSubmit<
    Partial<CompanySuperAdminAddEdit>
  >({
    submitProps: {
      apiMutations: {
        create,
        update,
      },
      data: {
        create: !props.data ? formData : {},
        update:
          !!props.data && !!formData
            ? getChangedData({ data: formData, initData: props.data })
            : {},
      },
      dataId: String(props.data?._id),
      name: "Company information",
    },
    onSuccess: () => {
      // clear form data
      setFormData({ displayName: "" });
      setHasTriedToSubmit(false);

      // close Add modal
      props.handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={props.handleCloseModal}
      openModal={props.openModal}
      label={props.data ? "EDIT COMPANY" : "ADD COMPANY"}
      alignTextCenter={false}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit();
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              marginTop: "var(--space-24px)",
              flexDirection: "row-reverse",
            }}
          >
            <ButtonPrimary
              sx={{ marginLeft: 1 }}
              onClick={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              disabled={!isFormValid}
            >
              {props.data ? t("Save") : t("Add")}
            </ButtonPrimary>
            <ButtonSecondary
              onClick={() => {
                props.handleCloseModal();
              }}
            >
              {t("Cancel")}
            </ButtonSecondary>
          </Box>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default CompanyModalWithForm;
