/**
 *Created by Mikael Lindahl on 2024-06-04
 */

import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "../../Simple/Buttons/ButtonPrimary";
import ConsentDialog from "./ConsentDialog";
import React, { useContext, useState } from "react";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useMicrosoftDriveCreateDocumentFromTemplate from "src/hooks/useMicrosoftDrive/useMicrosoftDriveCreateDocumentFromTemplate";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { CollectionName } from "src/accurasee-backend-types/app/general/collection.types";
import { Grid, Typography } from "@mui/material";
import {
  getStructure,
  GetStructureExtraProps,
  MicrosoftDriveFileProps,
} from "./DialogMicrosoftDriveCreateFileFromTemplateGetStructure";
import { MicrosoftDriveContext } from "src/context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { useSnackbar } from "notistack";
import { MicrosoftTemplateData } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";
import {
  MicrosoftDocumentWrapper,
  MicrosoftFileDataValue,
} from "src/hooks/useMicrosoftDrive/useMicrosoftDriveList";
import { useGetCompanyConfigQuery } from "src/redux/services/CompanyConfigsService";

type MicrosoftDriveDialogProps = {
  allFiles: MicrosoftDocumentWrapper[];
  onClose: () => void;
  collectionName: CollectionName;
  templateData?: MicrosoftTemplateData;
  invalidate: () => void;
  leafFolder?: string;
  open: boolean;
  prefixDocumentName?: string;
};

const DialogMicrosoftDriveCreateFileFromTemplate = ({
  leafFolder,
  allFiles,
  onClose,
  collectionName,
  templateData,
  invalidate,
  open,
  prefixDocumentName,
}: MicrosoftDriveDialogProps) => {
  const [t] = useTranslation();

  const { data: companyConfigsQuery, isLoading: isLoadingCompanyConfigsQuery } =
    useGetCompanyConfigQuery(undefined);
  const { groups, types } = companyConfigsQuery?.data?.drive?.config || {};

  const [selectedTemplate, setSelectedTemplate] =
    useState<MicrosoftFileDataValue | null>(null);

  const { microsoftDriveClient } = useContext(MicrosoftDriveContext);
  const { enqueueSnackbar } = useSnackbar();

  const { createDocumentFromTemplate } =
    useMicrosoftDriveCreateDocumentFromTemplate({
      collectionName,
      leafFolder,
      service: microsoftDriveClient,
    });

  const chooseFilename: (fileName: string, n: number) => string = (
    fileName,
    n,
  ) => {
    const names = fileName.split(".");
    const ext = names.pop();
    const nameNoExt = names.join(".");
    const defaultFileName = n > 0 ? `${nameNoExt} (${n}).${ext}` : fileName;
    const existName = allFiles?.find((f) => defaultFileName === f.fileName);
    if (existName) {
      return chooseFilename(fileName, n + 1);
    }
    return defaultFileName;
  };

  const initialFormData = {
    selectedFileName: "",
    selectedGroup: "",
    selectedType: "",
  };
  const { formData, helperText, isFormValid, setFormData, structure } =
    useFormContainer<MicrosoftDriveFileProps, GetStructureExtraProps>({
      extraProps: { groups, types },
      getStructure,
      initialFormData,
    });

  const commonPropsBuildItem = {
    id: "microsoft-drive",
    data: formData,
    disabled: !selectedTemplate,
    helperText,
  };

  const onClear = () => {
    setFormData(initialFormData);
    setSelectedTemplate(null);
  };

  return (
    <ConsentDialog
      consentButtons={[
        {
          label: t("Cancel"),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            onClear(); // clear input value
            onClose();
          },
          variant: "outlined",
        },
        {
          label: t("Create"),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            createDocumentFromTemplate<ContractOffer, {}>({
              templateData,
              selectedFileName: formData?.selectedFileName || "",
              selectedGroup: formData?.selectedGroup || "",
              selectedTemplate,
              selectedType: formData?.selectedType || "",
            }).then(() => {
              onClear();
              invalidate();
              onClose();
            });
          },

          variant: "contained",
          disabled:
            !isFormValid || !selectedTemplate || isLoadingCompanyConfigsQuery,
        },
      ]}
      open={open}
    >
      <SectionContainer sx={{ width: "600px" }}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={selectedTemplate ? 0 : 2}
          >
            <ButtonPrimary
              sx={{ display: "block", margin: "auto" }}
              onClick={() =>
                microsoftDriveClient?.openPicker((files, e) => {
                  if (e) {
                    throw e;
                  }

                  if (files.length > 0) {
                    microsoftDriveClient
                      .getDriveItem({
                        driveId: files[0].parentReference?.driveId || "",
                        id: files[0].id,
                      })
                      .then((fileMetaData) => {
                        let metadata: { group?: string; type?: string } = {};
                        try {
                          metadata = JSON.parse(
                            decodeURIComponent(
                              fileMetaData.description as string,
                            ),
                          );
                        } catch (e) {}
                        const defaultFilename = `${prefixDocumentName}_${files[0].name}`;

                        const newFormData = {
                          ...formData,
                          ...(metadata.group
                            ? { selectedGroup: metadata.group }
                            : {}),
                          ...(metadata.type
                            ? { selectedType: metadata.type }
                            : {}),
                          selectedFileName: chooseFilename(defaultFilename, 0),
                        };
                        setFormData(newFormData);

                        setSelectedTemplate(files[0]);
                      })
                      .catch((e) => {
                        enqueueSnackbar(
                          t("An error occurred while picking template."),
                          {
                            variant: "error",
                          },
                        );
                        console.error(e);
                      });
                  }
                })
              }
            >
              {selectedTemplate
                ? t("Pick another template")
                : t("Please pick a template")}
            </ButtonPrimary>
          </Grid>
          {selectedTemplate && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              marginBottom={2}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "var(--gray-5)",
                  fontWeight: "bold",
                  marginTop: "var(--space-4px)",
                  textAlign: "center",
                }}
              >
                Selected template: {selectedTemplate?.name}
              </Typography>
            </Grid>
          )}
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Grid>
      </SectionContainer>
    </ConsentDialog>
  );
};

export default DialogMicrosoftDriveCreateFileFromTemplate;
