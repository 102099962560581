/**
 *Created by Mikael Lindahl on 2023-12-14
 */

import {
  CreateLabel,
  createLabels,
  DeleteLabel,
  deleteLabels,
  SubmitLabel,
  UpdateLabel,
  updateLabels,
} from "../components/Basic/Mixed/Buttons/ButtonSubmitCore";
import { AccessPermissionType } from "../accurasee-backend-types/app/access_role_permission/access_role_permission.types";

type GetPermissionTypeForButtonSubmit = {
  label: SubmitLabel;
};

const getPermissionTypeForButtonSubmit = (
  props: GetPermissionTypeForButtonSubmit,
) => {
  let permissionType: AccessPermissionType | undefined = undefined;
  if (createLabels.includes(props?.label as CreateLabel)) {
    permissionType = "writeRights";
  } else if (deleteLabels.includes(props?.label as DeleteLabel)) {
    permissionType = "deleteRights";
  } else if (updateLabels.includes(props?.label as UpdateLabel)) {
    permissionType = "updateRights";
  } else {
    console.error(
      `Missing label in getPermissionTypeForButtonSubmit: '${props?.label}'`,
    );
  }
  return permissionType;
};

export default getPermissionTypeForButtonSubmit;
