import { useState } from "react";

import { MUIDataTableColumnDef } from "mui-datatables";
import { LinearProgress } from "@mui/material";

import {
  useGetInvoiceplanTemplatesQuery,
  useDeleteInvoiceplanTemplateMutation,
} from "src/redux/services/InvoicePlanTemplateService";

import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useTranslation from "src/hooks/useTranslationWrapper";

import {
  InvoicePlanTemplate,
  InvoicePlanTemplateWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";

import clone from "src/utils/clone";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import FilterOwnContractsButtonTooltip from "../../../../components/Basic/Mixed/Buttons/FilterOwnContractsButtonTooltip";
import { useNavigate, useLocation } from "react-router-dom";

const InvoiceplanTemplatesTable = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [deleteInvoiceplanTemplate] = useDeleteInvoiceplanTemplateMutation();

  const [deleteData, setDeleteData] = useState<InvoicePlanTemplate>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const location = useLocation();

  const {
    options,
    data: templates,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide({
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: { pageSize: 10, sortBy: "createdAt", sort: "desc" },
    },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Name").toLowerCase()}...`,
    },
    useGetDataQuery: useGetInvoiceplanTemplatesQuery,
    customButton: ({ additionalFilter, setAdditionalFilter }) => {
      return (
        <FilterOwnContractsButtonTooltip
          additionalFilter={additionalFilter}
          setAdditionalFilter={setAdditionalFilter}
          ownButtonLabel={"templates"}
        />
      );
    },
  });

  const templateTableStructure: {
    items: StructureItemTable<InvoicePlanTemplateWithPermissions>[];
  } = {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "name",
        sort: true,
      },
      {
        dataName: "type",
        headerLabel: "Type",
        type: "text",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          let label = "Periodic";
          const value = data[rowIndex]?.type || "";
          switch (value) {
            case "milestone":
              label = "Milestone";
              break;
            case "fixedprice":
              label = "Fixed price";
              break;
            case "runningrate":
              label = "Running rate";
              break;
            case "custom":
              label = "Custom";
              break;
            default:
              break;
          }

          return label;
        },
      },

      {
        alignColumnContent: "end",
        type: "action_row",
        dataName: "action_row",
        headerLabel: "",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: ({ rowIndex, data }) => {
              navigate(location.pathname + "/" + String(data[rowIndex]._id));
            },
          },
          {
            elementType: "icon",
            iconType: "delete",
            onClick: ({ rowIndex, data }) => {
              let newData = clone(data);
              setIsOpenDeleteModal(true);
              setDeleteData(newData[rowIndex] as InvoicePlanTemplate);
            },
          },
        ],
      },
    ],
  };
  let templateTableColumns: MUIDataTableColumnDef[] =
    templateTableStructure.items.map((item) =>
      BuilderColumnOptionsSingle({
        data: templates,
        disabled: true,
        item,
      }),
    );

  return (
    <>
      <div style={{ height: 2 }}>{isLoading && <LinearProgress />}</div>
      <MuiDataTableWrapper
        data={templates || []}
        columns={templateTableColumns}
        options={options}
        isFetching={isFetching}
        tableType="main-with-filter"
        noBackground={true}
      />
      <DeletionModal
        deleteData={deleteData}
        dataType={"invoice plan template"}
        openModal={isOpenDeleteModal}
        deleteDataFunction={deleteInvoiceplanTemplate}
        setIsOpenDeleteModal={setIsOpenDeleteModal}
      />
    </>
  );
};

export default InvoiceplanTemplatesTable;
