import { Box, Grid, Typography } from "@mui/material";

import useStyles from "./SectionHtmlStyles";

interface BorderedSectionProps {
  icon?: JSX.Element;
  label?: string;
  labelPosition?: "left" | "center" | "right";
  children: any;
  xs?: number;
}

const SectionHtml = ({
  icon,
  label,
  labelPosition,
  children,
  ...gridProps
}: BorderedSectionProps) => {
  const classes = useStyles();

  return (
    <Grid item {...{ xs: 12, ...gridProps }}>
      <Box className={classes.mainContainer}>
        <Box className={classes.borderContainer}>
          <Box
            className={classes.header}
            sx={{
              justifyContent:
                labelPosition === "left"
                  ? "flex-start"
                  : labelPosition === "right"
                    ? "flex-end"
                    : "center", // Default
            }}
          >
            {(icon || label) && (
              <Box className={classes.headerLabel}>
                {icon && icon}
                {label && (
                  <Typography sx={{ fontWeight: "600" }}>{label}</Typography>
                )}
              </Box>
            )}
          </Box>

          <Box className={classes.childrenContainer}>{children}</Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default SectionHtml;
