import { ProjectSeries } from "src/accurasee-backend-types/app/company/company.types";

import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import { CommonCallbackProps } from "src/components/Builders/Table/CommonBuilderTableTypes";

import { OnSubmit } from "src/hooks/useOnSubmit";
import clone from "src/utils/clone";

export type GetStructureExtraProps = {
  projectNumberSeries: ProjectSeries[];
  onDelete: (props: CommonCallbackProps) => void;
  onEdit: (props: CommonCallbackProps) => void;
  onSubmitActiveSwitch: OnSubmit;
};
export const getStructure: UserFormTableGetStructureOptional<
  ProjectSeries,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "label",
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "prefix",
        headerLabel: "Prefix",
      },
      {
        type: "text",
        dataName: "firstNumber",
        headerLabel: "First number",
      },
      {
        type: "text",
        dataName: "lastNumber",
        headerLabel: "Last number",
      },
      {
        type: "switch",
        dataName: "isActive",
        headerLabel: "Active/Inactive",
        gridProps: { md: 12 },
        setFormDataCustom: (propsCustom) => {
          let newData = clone(propsCustom.data);
          newData[propsCustom.rowIndex][propsCustom.item.dataName as string] =
            propsCustom.value;
          if (props?.setFormData) {
            props?.setFormData(newData);
          }
          if (props?.extraProps?.onSubmitActiveSwitch) {
            const submitData = props?.extraProps?.projectNumberSeries.map(
              (u) => {
                if (u.id === newData[propsCustom.rowIndex].id) {
                  return { ...u, isActive: propsCustom.value };
                }
                return u;
              },
            );
            props?.extraProps?.onSubmitActiveSwitch({
              dataId: "",
              data: { update: { projectNumberSeries: submitData } },
            });
          }
        },
        alignColumnContent: "center",
      },
      {
        type: "action_row",
        iconType: "edit",
        dataName: "edit",
        alignColumnContent: "end",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEdit) {
                props?.extraProps?.onEdit(onClickProps);
              }
            },
          },
          {
            elementType: "icon",
            iconType: "delete_no_cross",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onDelete) {
                props?.extraProps?.onDelete(onClickProps);
              }
            },
          },
        ],
      },
    ],
  };
};
