import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { EUCOUNTRIES, EUCOUNTRIESWITHSWEDEN } from "src/constants/EuCountries";

const calculateVat = (vat: number, customer?: Customer) => {
  let country = customer?.countryCode;
  if (!customer) {
    console.error("Customer data not found");
    return vat;
  } else if (!country) {
    console.error("Country code not added to Customer");
    country = "SE";
  }

  if (
    (country === "SE" && customer.reversedConstructionVAT) ||
    (EUCOUNTRIES.includes(country) && customer.customerType === "COMPANY") ||
    !EUCOUNTRIESWITHSWEDEN.includes(country)
  ) {
    return 0;
  } else {
    return vat;
  }
};

export default calculateVat;
