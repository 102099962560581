import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Box, Grid } from "@mui/material";
import {
  AccessUserRole,
  UserRoleWithPermissions,
} from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./UserRoleModalGetStructure";

import {
  useCreateUserRoleMutation,
  usePatchUserRoleMutation,
} from "src/redux/services/UserRolesService";

interface PermissionModalProps {
  actionType: "add" | "edit";
  openModal: boolean;
  handleCloseModal: () => void;
  initialData?: Partial<AccessUserRole>;
  names?: string[];
}
const UserRoleModalWithForm = ({
  actionType,
  openModal,
  handleCloseModal,
  initialData = {},
  names = [],
}: PermissionModalProps) => {
  const [createUserRole] = useCreateUserRoleMutation();
  const [updateUserRole] = usePatchUserRoleMutation();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    Partial<UserRoleWithPermissions>,
    GetStructureExtraProps
  >({
    getStructure,
    extraProps: {
      names,
    },
    initialFormData: initialData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createUserRole,
        update: updateUserRole,
      },
      data: {
        create:
          actionType === "add"
            ? (toSubmitData({
                data: formData,
                initData: initialData,
              }) as AccessUserRole)
            : undefined,
        update:
          actionType === "edit"
            ? toSubmitData({ data: formData, initData: initialData })
            : undefined,
      },
      dataId: formData?._id ? String(formData?._id) : undefined,
      name: "User Role Permission",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setFormData({});
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    id: "permission",
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      label={actionType === "add" ? "Add role" : "Edit role"}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit();
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
          </Grid>
          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonCancel
                onClick={() => {
                  setHasTriedToSubmit(false);
                  setFormData({});
                  handleCloseModal();
                }}
              />
              <ButtonCreateOrUpdate
                initialSubmitData={{}}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                label={actionType === "add" ? "create" : "update"}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={formData?.permissions}
                submitData={formData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default UserRoleModalWithForm;
