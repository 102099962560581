import { backendApi } from "../api";
import {
  AccessEndpointMap,
  AccessRolePermissionWithPermissions,
} from "../../accurasee-backend-types/app/access_role_permission/access_role_permission.types";

const CACHE_TAG = "Permissions";

const permissionsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query<
      { data: AccessRolePermissionWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/permissions", params }),
      providesTags: [CACHE_TAG],
    }),
    getPermissionCheckAccess: build.query<
      {
        data: AccessEndpointMap;
        metadata: any;
      },
      any
    >({
      query: (id) => ({ url: "/permissions/checkaccess" }),
      providesTags: [CACHE_TAG],
    }),
    // Update by an array of permissions
    updatePermissions: build.mutation({
      query: (data) => ({
        url: "/permissions/bulk",
        method: "PATCH",
        data,
      }),
      invalidatesTags: [CACHE_TAG],
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetPermissionCheckAccessQuery,
  useUpdatePermissionsMutation,
} = permissionsApi;

export default permissionsApi;
