import { Box, Typography } from "@mui/material";
import useTranslation from "src/hooks/useTranslationWrapper";

export type DialogContentWrapperProps = {
  contentType?: "warning" | "alert";
  message?: string;
  title?: string;
};

const DialogContentWrapper = ({
  message,
  contentType = "warning",
  title,
}: DialogContentWrapperProps) => {
  const [t] = useTranslation();

  return (
    <Box sx={{ maxWidth: "800px", textAlign: "center" }}>
      {title && (
        <Typography
          key={"prompt"}
          sx={{
            fontWeight: "700 !important",
            fontSize: "16px",
            textTransform: "uppercase",
            ...(contentType === "alert"
              ? { color: "var(--negative-primary)" }
              : {}),
          }}
        >
          {t(title)}
        </Typography>
      )}
      {message && (
        <Typography key={"message-prompt"} mt="var(--space-8px)">
          {t(message)}
        </Typography>
      )}
    </Box>
  );
};

export default DialogContentWrapper;
