/**
 *Created by Mikael Lindahl on 2024-02-07
 */
import calculateDependable from "../accurasee-backend-types/shared/utils/calculateDependable";
import { InvoicePlanRowExtended } from "../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { PlannedInvoiceRowExtended } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

const updateRowTotals = (
  rows: (InvoicePlanRowExtended | PlannedInvoiceRowExtended)[],
) => {
  for (let i = 0; i < rows.length; i++) {
    rows[i] = {
      ...rows[i],
      ...calculateDependable({
        data: rows[i],
      }),
    };
  }
};

export default updateRowTotals;
