/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { ArticleWithTotals } from "src/accurasee-backend-types/app/article/article.types";
import { Unit } from "src/accurasee-backend-types/app/unit/unit.types";
import { escape } from "src/utils/translate";
import getYear from "date-fns/getYear";
import { ReturnCompanyWithPermissions } from "../../../../accurasee-backend-types/app/company/company.types";
import {
  articleTypeOptions,
  houseWorkTypeOptions,
} from "./[id]/ArticleContainerStructure";
import getSelectOptions from "../../../../utils/getSelectOptions";

export type GetStructureProps = {
  company?: ReturnCompanyWithPermissions;
  units: Unit[] | undefined;
  location: any;
};

export const getStructure: UserFormTableGetStructure<
  ArticleWithTotals,
  GetStructureProps
> = ({ setFormData, extraProps, navigate }) => {
  return {
    items: [
      {
        type: "text",
        dataName: "number",
        headerLabel: "#",
        sort: true,
      },
      {
        type: "text",
        dataName: "name",
        sort: true,
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "unitId",
        headerLabel: "Unit",
        sort: true,
        options: getSelectOptions({
          data: extraProps?.units,
          label: "name",
          value: "_id",
        }),
        // options: extraProps?.units?.map((a) => ({
        //   value: a._id,
        //   label: a.name,
        // })),
        getValue: ({ data, rowIndex }) =>
          extraProps?.units?.find(
            (unit) => String(unit._id) === String(data[rowIndex].unitId),
          )?.name,
        showCellWhen: (props: any) =>
          Boolean(props.data[props.rowIndex].unitId),
      },
      {
        type: "icon",
        iconType: "check",
        showCellWhen: ({ data, rowIndex }) => data[rowIndex].active,
        dataName: "active",
        headerLabel: "Active",
        alignColumnContent: "center",
        getValue: ({ data, rowIndex }) => data[rowIndex].active,
        filter: true,
        filterOptions: {
          names: ["Show inactive"],
        },
        sort: true,
      },
      {
        type: "switch",
        dataName: "houseWork",
        sort: true,
        headerLabel: "House work",
        showColumnWhen: false,
      },
      {
        type: "text",
        dataName: "houseWorkType",
        headerLabel: "House work type",
        sort: true,
        showCellWhen: (props: any) => props.data[props.rowIndex].houseWork,
        getValue: ({ data, rowIndex }) =>
          houseWorkTypeOptions.find(
            (option) => option.value === data[rowIndex].houseWorkType,
          )?.label,
        // options: houseWorkTypeOptions,
        showColumnWhen: false,
      },
      {
        type: "text",
        dataName: "type",
        headerLabel: "Type",
        sort: true,
        getValue: ({ data, rowIndex }) =>
          articleTypeOptions.find(
            (option) => option.value === data[rowIndex].type,
          )?.label,
        // options: articleTypeOptions,
        showColumnWhen: false,
      },
      {
        type: "number",
        dataName: "vat",
        headerLabel: "VAT %",
        sort: true,
        showCellWhen: (props: any) => props.data[props.rowIndex].vat,
        getValue: ({ data, rowIndex }) => {
          const { vat } = data[rowIndex];
          return vat ? `${vat * 100} %` : "-";
        },
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.company?.currencyCode,
        dataName: "totalEstSalesThisYear",
        headerLabel: `Estimated sales${escape(
          " " + String(getYear(new Date())),
        )}`,
        sx: { width: "100%" },
        alignColumnContent: "end",
      },
      {
        type: "icon_button",
        iconType: "edit",
        dataName: "",
        onClick: (props) => {
          navigate(
            extraProps?.location.pathname +
              "/" +
              String(props.data[props.rowIndex]._id),
          );
        },
      },
    ],
  };
};
