import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import getValue from "src/components/Builders/Container/Utils/getValue";

import { ContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";

import { ActiveContract } from "./TransferOwnershipsStructure";

export const enum Mode {
  basic = "basic",
  advanced = "advanced",
}

export interface GetStructureExtraProps {
  mode: Mode;
  users?: ReturnUser[];
}
export const getStructure: UseFormContainerGetStructure<
  Partial<ContractWithPermissions>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<ContractWithPermissions>> = {
    items: [
      {
        required: true,
        type: "autocomplete",
        dataName: "ownerIds",
        label: "Contract owner(s)",
        gridProps: { md: 12 },
        selectionOptions: { multiple: true },
        validate: (props) => {
          const value = getValue(props);
          return value.length > 0;
        },
        getErrorText: () => "You need to select at least one owner",
        options: extraProps?.users?.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user?._id as unknown as string,
            isActive: user.active,
          };
        }),
        setFormData,
      },
    ],
  };

  return structure;
};

export const getTableStructure: UserFormTableGetStructure<
  ActiveContract,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  return {
    items: [
      {
        headerLabel: "Contract name",
        type: extraProps?.mode === Mode.basic ? "text" : "text_input",
        dataName: "contractName",
        disabled: true,
        sx: { minWidth: 100 },
      },
      {
        type: "autocomplete",
        dataName: "ownerIds",
        headerLabel: "Contract owner(s)",
        selectionOptions: { multiple: true },
        options: extraProps?.users?.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: String(user._id),
            isActive: user.active,
          };
        }),
        showColumnWhen: () => extraProps?.mode === Mode.advanced,
        setFormData,
        validate: ({ data, rowIndex }) => {
          return data[rowIndex].ownerIds.length > 0;
        },
        getErrorText: () => "You need to select at least one owner",
        required: true,
        sx: { minWidth: 200 },
      },
    ],
  };
};

export default getTableStructure;
