import { backendApi } from "../api";
import { listTag } from "./utils";
import { ContractInsightWithPermissions } from "../../accurasee-backend-types/app/insight/insight.types";

const CACHE_TAG = "Insights";
export const insightApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getInsightContracts: build.query<
      { data: ContractInsightWithPermissions[]; metadata: any },
      any
    >({
      query: (params: any) => ({
        url: "/insights/contracts",
        params,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getMonthCashFlow: build.query<any, any>({
      query: (params: any) => ({
        url: "/insights/monthcashflow",
        params,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getMonthRevenueWithOperatingMargin: build.query<any, any>({
      query: (params: any) => ({
        url: "/insights/revenuewithoperatingmargin",
        params,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getYearToDateResult: build.query<any, any>({
      query: (params: any) => ({
        url: "/insights/yeartodateresult",
        params,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
  }),
});

export const {
  useGetInsightContractsQuery,
  useGetMonthCashFlowQuery,
  useGetMonthRevenueWithOperatingMarginQuery,
  useGetYearToDateResultQuery,
} = insightApi;

export default insightApi;
