import { Accounting } from "../../accurasee-backend-types/app/integrations/integrationconfig.types";
import { IntegrationName } from "../../accurasee-backend-types/app/integrations/integrationadapter.types";
import { backendApi } from "../api";
import { listTag } from "./utils";

const CACHE_TAG = "Integrations";

const integrationImportStubs = [
  "Company",
  "Currencies",
  "Customers",
  "Suppliers",
  "SupplierInvoices",
  "CustomerInvoices",
  "Units",
  "Articles",
  "Accounts",
  "Projects",
  "Dimensions",
  "Attachments",
  "Vouchers",
  "TermsOfPayments",
  "Fiscalyears",
] as const;

type IntegrationImportStub = (typeof integrationImportStubs)[number];

export const integrationApi = backendApi.injectEndpoints({
  endpoints: (build) => {
    const makeImportMutation = <T = any>(type: IntegrationImportStub) => {
      const mut = build.mutation<
        { upsertedCount: number; modifiedCount: number },
        any
      >({
        query: () => ({
          url: `/integration/import${type}`,
          method: "PUT",
        }),
        invalidatesTags: [type],
      });

      return mut;
    };

    return {
      getIntegrationConfig: build.query<
        {
          byggkollen: any;
          accounting: Accounting;
          operationLog: any;
        },
        any
      >({
        query: () => ({
          url: "/integration/config",
        }),
        providesTags: listTag(CACHE_TAG),
      }),
      patchIntegrationAccount: build.mutation<
        any,
        Partial<{
          integrationName: IntegrationName;
          username: string;
          password: string;
        }>
      >({
        query: (data) => ({
          url: "/integration/accounting",
          method: "PATCH",
          data,
        }),
        invalidatesTags: listTag(CACHE_TAG),
      }),
      selectIntegration: build.mutation({
        query: (data) => ({
          url: "/integration/select",
          method: "PUT",
          data,
        }),
        invalidatesTags: listTag(CACHE_TAG),
      }),
      oauth: build.mutation({
        query: (data) => ({
          url: "/integration/setup-token-from-user-access-request-code",
          method: "PUT",
          data,
        }),
        invalidatesTags: listTag(CACHE_TAG),
      }),
      getSetup: build.query({
        query: (params) => ({
          url: "/integration/setup",
          params,
        }),
        providesTags: listTag(CACHE_TAG),
      }),
      importCompany: makeImportMutation("Company"),
      importCurrencies: makeImportMutation("Currencies"),
      importCustomers: makeImportMutation("Customers"),
      importSuppliers: makeImportMutation("Suppliers"),
      importSupplierInvoices: makeImportMutation("SupplierInvoices"),
      importCustomerInvoices: makeImportMutation("CustomerInvoices"),
      importUnits: makeImportMutation("Units"),
      importArticles: makeImportMutation("Articles"),
      importAccounts: makeImportMutation("Accounts"),
      importProjects: makeImportMutation("Projects"),
      importDimensions: makeImportMutation("Dimensions"),
      importAttachments: makeImportMutation("Attachments"),
      importVouchers: makeImportMutation("Vouchers"),
      importTermsofPayments: makeImportMutation("TermsOfPayments"),
      importFiscalyears: makeImportMutation("Fiscalyears"),
      renewAccessToken: build.mutation({
        query: () => ({
          url: "/integration/renew-access-token",
          method: "PUT",
        }),
        invalidatesTags: listTag(CACHE_TAG),
      }),
    };
  },
  overrideExisting: false,
});

export type ImportMutationReturnType =
  | ReturnType<typeof useImportCompanyMutation>
  | ReturnType<typeof useImportCurrenciesMutation>
  | ReturnType<typeof useImportFiscalyearsMutation>
  | ReturnType<typeof useImportAccountsMutation>
  | ReturnType<typeof useImportTermsofPaymentsMutation>
  | ReturnType<typeof useImportUnitsMutation>
  | ReturnType<typeof useImportArticlesMutation>
  | ReturnType<typeof useImportSuppliersMutation>
  | ReturnType<typeof useImportCustomersMutation>
  | ReturnType<typeof useImportProjectsMutation>
  | ReturnType<typeof useImportVouchersMutation>
  | ReturnType<typeof useImportDimensionsMutation>
  | ReturnType<typeof useImportCustomerInvoicesMutation>
  | ReturnType<typeof useImportSupplierInvoicesMutation>
  | ReturnType<typeof useImportAttachmentsMutation>;

export const {
  useGetIntegrationConfigQuery,
  useGetSetupQuery,
  useImportAccountsMutation,
  useImportArticlesMutation,
  useImportAttachmentsMutation,
  useImportCompanyMutation,
  useImportCurrenciesMutation,
  useImportCustomerInvoicesMutation,
  useImportCustomersMutation,
  useImportDimensionsMutation,
  useImportFiscalyearsMutation,
  useImportProjectsMutation,
  useImportSupplierInvoicesMutation,
  useImportSuppliersMutation,
  useImportTermsofPaymentsMutation,
  useImportUnitsMutation,
  useImportVouchersMutation,
  useOauthMutation,
  usePatchIntegrationAccountMutation,
  useSelectIntegrationMutation,
  useRenewAccessTokenMutation,
} = integrationApi;
export default integrationApi;
