/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { BuilderContainerCallbackProps } from "../CommonBuilderContainerTypes";
import { setNestedObjectData } from "src/utils/nestedData";
import clone from "src/utils/clone";

type SetValueTProps = BuilderContainerCallbackProps & {
  value: any;
};

const setValue = (props: SetValueTProps) => {
  if (props.data && props.item.setFormData) {
    let newData = clone(props.data);

    setNestedObjectData({
      data: newData,
      key: props.item.dataName as string,
      value: props.value,
    });

    props.item.setFormData(newData);
  }
};

export default setValue;
