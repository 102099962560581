/**
 *Created by Mikael Lindahl on 2024-03-13
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { IntegrationImportJob } from "../accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { WebsocketMessage } from "../accurasee-backend-types/app/websocket/websocket.types";
import { baseWebsocketURL } from "../general/Network";

interface WebsocketContextType {
  integrationImportJobs: IntegrationImportJob[];
  latestIntegrationImportJobsByGroupId: Record<string, IntegrationImportJob>;
  messages: WebsocketMessage[];
  sendMessage: (message: string) => void;
  setIntegrationImportJobs: (jobs: IntegrationImportJob[]) => void;
  setLatestIntegrationImportJobsByGroupId: (
    data: Record<string, IntegrationImportJob>,
  ) => void;
  websocket: Socket | null;
}

const WebsocketContext = createContext<WebsocketContextType>({
  integrationImportJobs: [],
  latestIntegrationImportJobsByGroupId: {},
  messages: [],
  sendMessage: () => {},
  setIntegrationImportJobs: () => {},
  setLatestIntegrationImportJobsByGroupId: () => {},
  websocket: null,
});

export const useWebsocket = () => useContext(WebsocketContext);

interface WebsocketProviderProps {
  children: React.ReactNode;
  token?: string | null;
}

const WebsocketProvider: React.FC<WebsocketProviderProps> = ({
  token,
  children,
}) => {
  const [websocket, setWebsocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<WebsocketMessage[]>([]);
  const [integrationImportJobs, setIntegrationImportJobs] = useState<
    IntegrationImportJob[]
  >([]);
  const [
    latestIntegrationImportJobsByGroupId,
    setLatestIntegrationImportJobsByGroupId,
  ] = useState<Record<string, IntegrationImportJob>>({});
  useEffect(() => {
    const newWebsocket = io(baseWebsocketURL as string, {
      path: "/api/v1/socket.io",
      query: { token },
    });
    setWebsocket(newWebsocket);

    newWebsocket.on("message", (message: WebsocketMessage) => {
      switch (message.type) {
        case "integration_import":
          setIntegrationImportJobs((prevMessages) => {
            let doNotAdd = false;

            let filteredMessages = prevMessages.filter((prevMessage) => {
              const laterMessageExists =
                prevMessage._id === message.payload._id &&
                prevMessage?.updatedAt &&
                prevMessage.updatedAt > message.payload.updatedAt;

              if (!doNotAdd && laterMessageExists) {
                doNotAdd = true;
              }

              return (
                prevMessage._id !== message.payload._id || laterMessageExists
              );
            });

            if (doNotAdd) {
              filteredMessages = [...filteredMessages];
            } else {
              filteredMessages = [...filteredMessages, message.payload];
            }

            const _latestIntegrationImportJobsByGroupId = {
              ...latestIntegrationImportJobsByGroupId,
            };
            filteredMessages.forEach((job) => {
              if (job.groupId) {
                _latestIntegrationImportJobsByGroupId[String(job.groupId)] =
                  job;
              }
            });
            setLatestIntegrationImportJobsByGroupId(
              _latestIntegrationImportJobsByGroupId,
            );
            return filteredMessages;
          });
          break;
        default:
          setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    return () => {
      newWebsocket.close();
    };
  }, [token]);

  const sendMessage = (message: string) => {
    if (websocket) {
      websocket.emit("message", message);
    }
  };

  return (
    <WebsocketContext.Provider
      value={{
        integrationImportJobs,
        latestIntegrationImportJobsByGroupId,
        messages,
        sendMessage,
        setLatestIntegrationImportJobsByGroupId,
        setIntegrationImportJobs,
        websocket,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
