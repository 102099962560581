import { makeStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalStyle: {
      width: "fit-content",
      maxWidth: "1600px",
      minWidth: "640px",
      height: "fit-content",
      maxHeight: "90%",
      backgroundColor: "var(--white-primary)",
      borderRadius: "16px",
      boxShadow: "0px 5px 13px var(----black-2)",
      padding: "var(--space-32px) var(--space-48px)",
      overflow: "auto",
      "& input": {
        padding: "8px",
      },
      "& textarea": {
        overflow: "scroll !important",
        maxHeight: "216px",
      },
    },
  }),
);
