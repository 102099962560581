import { backendApi } from "../api";
import { docPatchTag, listTag } from "./utils";
import {
  CompanyConfig,
  CompanyConfigGet,
} from "src/accurasee-backend-types/app/company_config/company_config.types";

const CACHE_TAG = "CompanyConfigs";

export const companyConfigsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    updateCompanyConfigs: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/company-configs/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    getCompanyConfig: build.query<{ data: CompanyConfigGet }, any>({
      query: (id) => ({
        url: "/company-configs",
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getCompanyConfigSuperAdmin: build.query<{ data: CompanyConfigGet }, any>({
      query: (companyId) => ({
        url: `/superadmin/company-configs/${companyId}`,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getAllCompanyConfigsHiddenComponents: build.query<
      { data: Pick<CompanyConfig, "hiddenComponentConfigs"> },
      any
    >({
      query: () => ({
        url: "/company-configs/hidden-components",
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    getCompanyConfigsHiddenComponents: build.query<
      { data: Pick<CompanyConfig, "hiddenComponentConfigs"> },
      any
    >({
      query: (id) => ({
        url: `/superadmin/company-configs/${id}/hidden-components`,
      }),
      providesTags: listTag(CACHE_TAG),
    }),
    createCompanyConfigsSuperAdminHiddenComponents: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/superadmin/company-configs/${id}/hidden-components`,
        method: "POST",
        data,
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    updateCompanyConfigsSuperAdminHiddenComponents: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/superadmin/company-configs/${id}/hidden-components`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    updateCompanyConfigsSuperAdmin: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/superadmin/company-configs/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyConfigQuery,
  useGetCompanyConfigSuperAdminQuery,
  useCreateCompanyConfigsSuperAdminHiddenComponentsMutation,
  useUpdateCompanyConfigsMutation,
  useGetAllCompanyConfigsHiddenComponentsQuery,
  useGetCompanyConfigsHiddenComponentsQuery,
  useUpdateCompanyConfigsSuperAdminMutation,
  useUpdateCompanyConfigsSuperAdminHiddenComponentsMutation,
} = companyConfigsApi;

export default companyConfigsApi;
