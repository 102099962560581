/**
 *Created by Mikael Lindahl on 2023-03-16
 */

import { ArticleWithContractArticle } from "../../../hooks/useGetArticles";
import { PlannedInvoiceRow } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { StructureTable } from "../../Builders/Table/CommonBuilderTableTypes";
import { UserFormTableGetStructure } from "../../../hooks/useFormTable";
import { setFormDataCustomPricePerUnit } from "../../../utils/helpersBuilderTableStructure/helperInvoiceRowPricePerUnit";
import {
  getErrorTextQuantity,
  setFormDataCustomQuantity,
  validateQuantity,
} from "../../../utils/helpersBuilderTableStructure/helperInvoiceRowQuantity";

export type GetStructureExtraProps = {
  articles?: ArticleWithContractArticle[];
  currencyCode?: string;
};

export const getStructure: UserFormTableGetStructure<
  PlannedInvoiceRow,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureTable = {
    items: [
      {
        type: "text_input",
        dataName: "itemDescription",
        headerLabel: "Description",
        setFormData,
        sx: { width: "300px" },
      },
      {
        type: "number_input",
        dataName: "quantity",
        disabled: ({ data, rowIndex }) => !data[rowIndex].isQuantityEstimate,
        headerLabel: "Quantity",
        validate: validateQuantity({ articles: extraProps?.articles }),
        getErrorText: getErrorTextQuantity,
        setFormDataCustom: setFormDataCustomQuantity<PlannedInvoiceRow>({
          setFormData,
        }),
      },
      {
        type: "switch",
        dataName: "isQuantityEstimate",
        cellLabel: "Estimate",
        setFormData,
      },
      {
        type: "number_input_currency",
        currencyCode: extraProps?.currencyCode,
        dataName: "pricePerUnit",
        disabled: ({ data, rowIndex }) =>
          !data[rowIndex].isPricePerUnitEstimate,
        headerLabel: "Price",
        setFormDataCustom: setFormDataCustomPricePerUnit<PlannedInvoiceRow>({
          setFormData,
        }),
        alignColumnContent: "end",
      },
      {
        type: "switch",
        dataName: "isPricePerUnitEstimate",
        cellLabel: "Estimate",
        setFormData,
      },
      {
        type: "text",
        dataName: "totalExcludingVAT",
        headerLabel: "Tot Excl. Vat",
        alignColumnContent: "end",
      },
      {
        type: "percent",
        dataName: "percentVAT",
        headerLabel: "Vat",
        alignColumnContent: "end",
        getValue: ({ data, rowIndex }) => data[rowIndex].percentVAT * 100,
      },
      {
        type: "text",
        dataName: "total",
        headerLabel: "Total",
        alignColumnContent: "end",
      },
    ],
  };

  return structure;
};
