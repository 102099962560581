/**
 *Created by Mikael Lindahl on 2023-03-08
 */
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { Account } from "../../../../accurasee-backend-types/app/account/account.types";

const structure: {
  items: StructureItemTable<Account>[];
} = {
  items: [
    {
      dataName: "number",
      headerLabel: "#",
      type: "text",
      sort: true,
    },
    {
      dataName: "description",
      headerLabel: "description",
      type: "text",
      sort: true,
    },
    {
      type: "date",
      dataName: "createdAt",
      headerLabel: "Created",
      sort: true,
    },
    {
      type: "date",
      dataName: "updatedAt",
      headerLabel: "Updated",
      sort: true,
    },
  ],
};

export default structure;
