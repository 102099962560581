import { backendApi } from "../api";
import { listTag, docPatchTag } from "./utils";
import {
  Catalog,
  Translation,
} from "../../accurasee-backend-types/app/translation/translation.types";

const CACHE_TAG = "Translation";

export const translationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCatalog: build.query<{ data?: Catalog; metadata?: any }, any>({
      query: (language) => ({ url: "/translation/catalog/" + language }),
      // transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TAG),
    }),
    getTranslations: build.query<{ data?: Translation[]; metadata?: any }, any>(
      {
        query: (params) => ({ url: "/superadmin/translation", params }),
        // transformResponse: (response) => response.data,
        providesTags: listTag(CACHE_TAG),
      },
    ),
    createTranslations: build.mutation<any, Translation[]>({
      query: (data) => {
        return {
          url: "/superadmin/translation/bulk",
          method: "POST",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    deleteTranslations: build.mutation<any, string[]>({
      query: (data) => {
        return {
          url: "/superadmin/translation/bulk",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    updateTranslations: build.mutation<any, Translation[]>({
      query: (data) => {
        return {
          url: `/superadmin/translation/bulk`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateTranslationsMutation,
  useDeleteTranslationsMutation,
  useGetCatalogQuery,
  useGetTranslationsQuery,
  useUpdateTranslationsMutation,
} = translationApi;

export default translationApi;
