import LinkedInvoiceDataTable from "src/components/Complex/Tables/LinkedInvoiceDataTable";
import { useGetContractsQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";

const LinkedInvoiceData = () => {
  let { contractId } = useParams<{ contractId: string }>();

  const { data: queryResult } = useGetContractsQuery(undefined);
  const contracts = queryResult?.data || [];

  return (
    <LinkedInvoiceDataTable
      contractId={contractId}
      useColumns={[
        "projectExternalId",
        "timePeriod",
        "itemDescription",
        "articleId",
        "quantity",
        "pricePerUnit",
        "sourceName",
      ]}
      contracts={contracts}
    />
  );
};

export default LinkedInvoiceData;
