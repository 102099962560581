/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "../../../../hooks/useFormTable";
import { ProjectTrackerConsolidatedCompanyIncomeReport } from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import getMonthAsString from "src/utils/getMonthAsString";
import getYearAsString from "src/utils/getYearAsString";
import { escape } from "src/utils/translate";

export const getStructure: UserFormTableGetStructureOptional<
  ProjectTrackerConsolidatedCompanyIncomeReport
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: props?.extraProps?.nameLabel,
      },
      {
        type: "text_currency",
        dataName: "thisMonth",
        headerLabel: getMonthAsString(0),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "nextMonth",
        headerLabel: getMonthAsString(1),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "plus2Month",
        headerLabel: getMonthAsString(2),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "thisYear",
        headerLabel: escape(getYearAsString(0)),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "nextYear",
        headerLabel: escape(getYearAsString(1)),
        alignColumnContent: "end",
      },
    ],
  };
};
