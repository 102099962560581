/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { Typography, FormControl, FormControlLabel } from "@mui/material";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import getValue from "../Utils/getValue";
import getDisabled from "../Utils/getDisabled";
import setValue from "../Utils/setValue";
import FormHelperText from "@mui/material/FormHelperText";
import getHelperText from "../Utils/getHelperText";
import getCellLabel from "../Utils/getCellLabel";
import SwitchWrapper from "src/components/Basic/Simple/Switches/SwitchWrapper";

type SwitchColumnItemTProps = CommonBuilderTableTypes;

const SwitchColumnItem = (props: SwitchColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const t = props.t;
  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);
  const cellLabel = getCellLabel(commonCallbackProps);

  return (
    <FormControl
      sx={{ backgroundColor: "transparent" }}
      error={Boolean(getHelperText(getHelperTextProps))}
    >
      <FormControlLabel
        control={
          <SwitchWrapper
            checked={Boolean(getValue(commonCallbackProps))}
            name={props.item.dataName as string}
            disabled={itemDisabled}
            onClick={(event) => {
              event.preventDefault();

              let changeProps = {
                ...commonCallbackProps,
                value: !(props.data && Boolean(getValue(commonCallbackProps))),
              };
              if (props.item.setFormDataCustom) {
                props.item.setFormDataCustom(changeProps);
              } else {
                setValue(changeProps);
              }
            }}
          />
        }
        label={
          <Typography sx={{ fontSize: 12 }}>
            {cellLabel ? t(cellLabel) : ""}
          </Typography>
        }
        labelPlacement="end"
        sx={{ margin: 0, columnGap: "var(--space-8px)" }}
      />
      <FormHelperText
        sx={{
          marginLeft: 0,
          color: "red !important",
          textAlign: "center",
        }}
      >
        {getHelperText(getHelperTextProps)}
      </FormHelperText>
    </FormControl>
  );
};

export default SwitchColumnItem;
