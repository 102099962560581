import { CommonCallbackProps } from "../CommonBuilderTableTypes";

/**
 *Created by Mikael Lindahl on 2023-03-14
 */

type GetShowCellWhenTProps = CommonCallbackProps;

const getShowCellWhen = (props: GetShowCellWhenTProps) => {
  return (
    !props.item.showCellWhen ||
    (typeof props.item.showCellWhen === "boolean"
      ? props.item.showCellWhen
      : props.item.showCellWhen(props))
  );
};

export default getShowCellWhen;
