import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import { get as getUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import {
  useGetInviteQuery,
  useReplyInviteMutation,
} from "src/redux/services/InviteService";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import useTranslation from "src/hooks/useTranslationWrapper";

export function AcceptInvite() {
  let { id } = useParams<{ id: string }>();
  const [t] = useTranslation();

  const {
    data: invite,
    isLoading: isLoadingInvite,
    isError,
    isSuccess,
  } = useGetInviteQuery(id);
  const [replyInvite, { isLoading: isReplying }] = useReplyInviteMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [hasReplied, setHasReplied] = useState(true);

  useEffect(() => {
    if (invite) {
      setHasReplied(invite.status !== 10);
    }
  }, [invite]);
  const reply = (accepted: any) => () =>
    replyInvite({
      inviteId: id,
      accepted,
    }).then((res: any) => {
      if (res?.error) {
        return enqueueSnackbar("Could not respond to invite", {
          variant: "error",
        });
      }
      dispatch(getUser());
      enqueueSnackbar(
        `Successfully accepted invite to company ${invite?.companyName}`,
        {
          variant: "success",
        },
      );
      navigate("/app/dashboard");
      setHasReplied(true);
    });
  return (
    <Card>
      <CardHeader title="Invite" />
      <CardContent>
        {isLoadingInvite && <LinearProgress />}
        {isError && (
          <Typography color="error"> {t("Could not load invite")}</Typography>
        )}
        {isSuccess && (
          <Box>
            {hasReplied ? (
              <Typography>{t("You replied")}</Typography>
            ) : (
              <Typography>
                {invite.fromFirstName} {invite.fromLastName} {invite.fromEmail}{" "}
                {t("invites you to join company")} {invite.companyName}{" "}
                {t("in AccuraSee")}
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
      {isSuccess && (
        <CardActions>
          <Button
            variant="outlined"
            disabled={isReplying || hasReplied}
            onClick={reply(false)}
          >
            {t("Reject")}
          </Button>
          <Button
            variant="contained"
            disabled={isReplying || hasReplied}
            onClick={reply(true)}
          >
            {t("Accept")}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default AcceptInvite;
