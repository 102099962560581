/**
 *Created by Mikael Lindahl on 2023-03-20
 */

import cloneDeep from "lodash/cloneDeep";

const clone = <DataType = object>(data: DataType) => {
  if (typeof data !== "object") {
    return data;
  }

  // Can not do JSON.parse(JSON.stringify(data)) because it will not work with Date and File objects
  return cloneDeep(data) as DataType;
};

export default clone;
