import { backendApi } from "../api";
import { docDeleteTags, docListTags, docPatchTag, listTag } from "./utils";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";

const CACHE_TYPE = "CustomFields";

const customFieldsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomFields: build.query<{ data: CustomField[]; metadata: any }, any>({
      query: (params) => ({ url: "/custom-fields", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getCustomField: build.query<CustomField, any>({
      query: (id) => ({ url: `/custom-fields/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    createCustomField: build.mutation<any, CustomField>({
      query: (data) => ({ url: "/custom-fields", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    patchCustomField: build.mutation<
      CustomField,
      Partial<CustomField> & { id: CustomField["_id"] }
    >({
      query: ({ id, ...data }) => ({
        url: `/custom-fields/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deleteCustomField: build.mutation<any, CustomField["_id"] | string>({
      query: (id) => ({ url: `/custom-fields/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCustomFieldMutation,
  useGetCustomFieldsQuery,
  useGetCustomFieldQuery,
  useDeleteCustomFieldMutation,
  usePatchCustomFieldMutation,
} = customFieldsApi;

export default customFieldsApi;
