import { backendApi } from "../api";
import { docListTags } from "./utils";
import { IncomeWithPermissions } from "../../accurasee-backend-types/app/income/income.types";

const CACHE_INCOME_TYPES = "Incomes";
const costsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getIncomes: build.query<
      { data: IncomeWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/incomes", params }),
      providesTags: docListTags(CACHE_INCOME_TYPES),
    }),
  }),
  overrideExisting: false,
});

export const { useGetIncomesQuery } = costsApi;

export default costsApi;
