/**
 *Created by Mikael Lindahl on 2024-10-22
 */
import moment from "moment-timezone";

const getMinMaxDatesThisMonth = () => {
  const minDate = moment().startOf("month").format("YYYY-MM-DD");
  const maxDate = moment().add(1, "month").endOf("month").format("YYYY-MM-DD");

  return { minDate, maxDate };
};

export default getMinMaxDatesThisMonth;
