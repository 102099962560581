/**
 *Created by Mikael Lindahl on 2023-11-29
 */

import _ from "lodash";

type IsValidToSubmitProps<T = any> = {
  data: T;
  initialData: T;
  isValid?: boolean;
};

const isDisableSubmit = <T = any>(props: IsValidToSubmitProps<T>) => {
  const disable =
    props.data && props.initialData
      ? _.isEqual(props.data, props.initialData) || !props.isValid
      : false;
  return disable;
};

export default isDisableSubmit;
