/**
 *Created by Mikael Lindahl on 2023-04-27
 */

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Translation } from "../../accurasee-backend-types/app/translation/translation.types";
import clone from "src/utils/clone";

export type MissingTranslationsState = Translation[];

const initialState = [] as MissingTranslationsState;

const missingTranslationsSlice = createSlice({
  name: "missingTranslations",
  initialState,
  reducers: {
    updateMissingTranslations(state, action: PayloadAction<Translation[]>) {
      let newState = clone(state);

      let isUpdate = false;
      let newTranslations: Translation[] = [];

      for (let translation of action.payload) {
        const index = state.findIndex(
          (v) =>
            v.key === translation.key && v.namespace === translation.namespace,
        );

        if (index !== -1) {
          newState[index] = translation;
          isUpdate = true;
        } else {
          newTranslations.push(translation);
          isUpdate = true;
        }
      }

      newState = [...newState, ...newTranslations];

      if (!isUpdate) {
        return state;
      }
      return newState;
    },
    deleteMissingTranslations(
      state,
      action: PayloadAction<{ key: string; namespace: string }[]>,
    ) {
      const toDelete = action.payload.map((v) => v.key + "." + v.namespace);

      let newState = state.filter(
        (v) => !toDelete.includes(v.key + "." + v.namespace),
      );

      return newState;
    },
  },
});

export const { updateMissingTranslations, deleteMissingTranslations } =
  missingTranslationsSlice.actions;
export default missingTranslationsSlice.reducer;
