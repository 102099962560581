/**
 *Created by Mikael Lindahl on 2023-05-09
 */
import * as yup from "yup";
import {
  LanguageCode,
  languageCodes,
} from "../accurasee-backend-types/app/language/language.types";
import {
  namespaces,
  Translation,
} from "../accurasee-backend-types/app/translation/translation.types"; // Assuming you are using mongoose for ObjectId

// Helper function to Create a language object shape
const createLanguageObjectShape = (
  languageCodes: ReadonlyArray<LanguageCode>,
  validation: any,
) => {
  return Object.fromEntries(
    languageCodes.map((code) => [code, validation.notRequired()]),
  );
};

// Define the Namespaces validation
const namespaceValidation = yup.string().oneOf(namespaces, "Invalid Namespace");

// Define the Translation validation schema
const translationSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  key: yup.string().required("Wrong file format! Key is required"),
  languages: yup
    .object()
    .shape(createLanguageObjectShape(languageCodes, yup.string()))
    .required("Languages object is required")
    .test(
      "valid-language-codes",
      "Wrong file format! Invalid language code(s) found",
      function (value) {
        const invalidKeys = Object.keys(value).filter(
          (key) => !(languageCodes as ReadonlyArray<string>).includes(key),
        );
        if (invalidKeys.length > 0) {
          return this.createError({
            message: `Wrong file format! Invalid language code(s): ${invalidKeys.join(
              ", ",
            )}`,
          });
        }
        return true;
      },
    ),
  namespace: namespaceValidation.required(
    "Wrong file format! Namespace is required",
  ),
});

// Define the list of Translation objects validation schema
const translationsSchema = yup.array().of(translationSchema);

// Example usage
export const validateTranslations = (translations: Translation[]) => {
  try {
    translationsSchema.validateSync(translations);
    return { error: undefined };
  } catch (error: any) {
    return { error: error.message };
  }
};
