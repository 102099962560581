/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import { AdditionalFilter } from "src/hooks/useMUIDataTableFilterStates";
import { useEffect } from "react";
import BuildItems from "src/components/Builders/Container/BuildItems";
import getStructure from "./ContractDateFilterStructure";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";

type FilterData = {
  startDate: Date;
  endDate: Date;
};

type ContractInsightsDateFilterProps = {
  additionalFilter?: AdditionalFilter;
  filterData: FilterData;
  setAdditionalFilter?: React.Dispatch<React.SetStateAction<any>>;
  setFilterData: (data: { startDate: Date; endDate: Date }) => void;
};

const ContractInsightsDateFilter = (props: ContractInsightsDateFilterProps) => {
  const { formData, helperText, structure } = useFormContainer<FilterData>({
    getStructure,
    initialFormData: props.filterData,
  });

  useEffect(() => {
    if (
      formData !== undefined &&
      (formData.startDate !== props.filterData.startDate ||
        formData.endDate !== props.filterData.endDate)
    ) {
      props.setFilterData(formData);

      if (props?.setAdditionalFilter) {
        const additionalFilter = {
          ...props?.additionalFilter,
          endDate: toDay(formData?.endDate),
          startDate: toDay(formData?.startDate),
        };
        props.setAdditionalFilter(additionalFilter);
      }
    }
  }, [formData]);

  const commonPropsBuildItem: any = { data: formData, helperText };

  return BuildItems({
    items: structure.items,
    ...commonPropsBuildItem,
  });
};

export default ContractInsightsDateFilter;
