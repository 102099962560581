/**
 *Created by Mikael Lindahl on 2023-03-27
 */

import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { InvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import Grid from "@mui/material/Grid";
import useTranslation from "src/hooks/useTranslationWrapper";
import toCurrency from "src/utils/toCurrency";

type InvoiceplanRowsMilestoneInfoBoxTProps = {
  invoicePlan?: InvoicePlan | SubcontractWithPermissions;
};

const MilestoneInfoBox = ({
  invoicePlan,
}: InvoiceplanRowsMilestoneInfoBoxTProps) => {
  const [t] = useTranslation();

  const invoicedMilestone =
    invoicePlan?.invoiceRows.reduce((accumulator, row) => {
      return row.status === "exported"
        ? accumulator + row.totalExcludingVAT
        : accumulator;
    }, 0) || 0;
  const remaining = invoicePlan?.milestoneDetails?.total
    ? invoicePlan?.milestoneDetails?.total - invoicedMilestone
    : 0;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          columnGap: "var(--space-20px)",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize={16} sx={{ color: "var(--gray-5)" }}>
          <CheckIcon
            fontSize="inherit"
            sx={{
              color: "var(--green-7)",
              marginRight: "var(--space-8px)",
            }}
          />
          {t("Milestones invoiced")}
        </Typography>
        <Typography fontSize={16} color="var(--gray-7)">
          {toCurrency({
            value: invoicedMilestone,
            currencyCode: invoicePlan?.currencyCode,
            toInteger: true,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          columnGap: "var(--space-20px)",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize={16} sx={{ color: "var(--gray-5)" }}>
          <ScheduleIcon
            fontSize="inherit"
            sx={{
              color: "var(--warning-3)",
              marginRight: "var(--space-8px)",
            }}
          />
          {t("Remaining")}
        </Typography>
        <Typography fontSize={16} color="var(--gray-7)">
          {toCurrency({
            value: remaining,
            currencyCode: invoicePlan?.currencyCode,
            toInteger: true,
          })}
        </Typography>
      </Box>
    </Grid>
  );
};

export default MilestoneInfoBox;
