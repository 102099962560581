import useTranslation from "src/hooks/useTranslationWrapper";
import useFormTable from "src/hooks/useFormTable";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./BudgetAdjustmentRowsTableStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import Section from "src/components/Basic/Simple/Sections/Section";
import { Box, Grid, IconButton } from "@mui/material";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect } from "react";
import {
  AdjustmentType,
  Budget,
  BudgetAdjustmentRow,
} from "src/accurasee-backend-types/app/budget/budget.types";
import { Types } from "mongoose";
import { EditRights } from "src/accurasee-backend-types/app/general/editrights.types";
import { FormGlue } from "src/hooks/useFormGlue";

type BudgetAdjustmentRowsRewriteTProps = {
  adjustmentRows: Budget["adjustmentRows"];
  budgetId: Types.ObjectId;
  contractId: Types.ObjectId;
  formGlue: FormGlue<Budget["adjustmentRows"]>;
  permissions: EditRights["permissions"];
  setTotalCostAdjustments: (value: number) => void;
  setTotalIncomeAdjustments: (value: number) => void;
};

const BudgetAdjustmentRows = (props: BudgetAdjustmentRowsRewriteTProps) => {
  const [t] = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();

  const { formData, helperText, setFormData, setHelperText, structure } =
    useFormTable<BudgetAdjustmentRow, GetStructureExtraProps>({
      formGlue: props.formGlue,
      initialFormData: props.adjustmentRows,
      getStructure,
      toSubmitData,
    });
  useEffect(() => {
    props.setTotalCostAdjustments(
      formData
        .filter((adjRow) => adjRow.adjustmentType !== "income")
        .reduce((total, item) => total + item.value, 0),
    );
    props.setTotalIncomeAdjustments(
      formData
        .filter((adjRow) => adjRow.adjustmentType === "income")
        .reduce((total, item) => total + item.value, 0),
    );
  }, [formData]);

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<BudgetAdjustmentRow>({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <Section label={"Adjustments"} md={12} lg={12}>
      <Grid item xs={12}>
        <MuiDataTableWrapper
          title={"Data table"}
          data={formData || []}
          columns={columns}
          options={options}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <IconButton
          disableRipple
          color="primary"
          onClick={() => {
            setHelperText([...helperText, {}]);
            setFormData([
              ...formData,
              {
                budgetId: props.budgetId,
                contractId: props.contractId,
                eventDescription: "",
                adjustmentDate: new Date(),
                adjustmentType: "labour" as AdjustmentType,
                value: 0,
              },
            ]);
          }}
        >
          <AddCircleIcon fontSize="large" />
          <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
            {t(`${" Add adjustment"}`)}
          </span>
        </IconButton>
        <Box
          sx={{
            marginLeft: "auto",
            width: "fit-content",
          }}
        ></Box>
      </Grid>
    </Section>
  );
};

export default BudgetAdjustmentRows;
