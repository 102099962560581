import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@material-ui/core/styles";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--white-primary)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--white-primary)",
    color: "var(--negative-primary)",
    boxShadow: "0px 3px 15px var(--black-1)",
  },
}));
export default LightTooltip;
