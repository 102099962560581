import { LocationDescriptorObject } from "history";
import { SubcontractWithCostAndIncomes } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";

export interface GetStructureExtraProps {
  location: LocationDescriptorObject;
}

const getStructure: UserFormTableGetStructure<
  SubcontractWithCostAndIncomes,
  GetStructureExtraProps
> = ({ extraProps, t }) => ({
  items: [
    {
      dataName: "number",
      headerLabel: "Label",
      type: "link",
      redirectLink: (props) =>
        extraProps?.location.pathname +
        "/" +
        String(props.data[props.rowIndex]._id),
      sort: true,
      getValue: ({ data, rowIndex }) => {
        const subcontract = data[rowIndex];
        return `${String(subcontract.number)}. ${t(
          subcontract.subcontractType,
        )} - ${t(subcontract.type)}`;
      },
    },
    {
      type: "text_with_optional_badge",
      dataName: "name",
      headerLabel: "Description",
      textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
        const isProcessing =
          data[rowIndex].costs.some((row) => row?.incomplete) ||
          data[rowIndex].incomes.some((row) => row?.incomplete);
        return {
          badgeText: isProcessing ? "incomplete" : "",
          iconType: isProcessing ? "incomplete" : "check_circle",
          showBadge:
            !!data[rowIndex].costs.some((row) => row?.incomplete) ||
            !!data[rowIndex].incomes.some((row) => row?.incomplete),
        };
      },
    },
    {
      type: "badge",
      dataName: "status",
      headerLabel: "Status",
      alignColumnContent: "center",
      sort: true,
      filter: true,
      filterOptions: {
        names: [
          "Upcoming",
          "Ongoing",
          "Finished",
          "Locked",
          "Archived",
          "Invoiced",
          "Partially invoiced",
          "Cancelled",
        ],
      },
    },
    {
      type: "badge",
      dataName: "customerStatus",
      headerLabel: "Customer status",
      alignColumnContent: "center",
      sort: true,
      filter: true,
      filterOptions: {
        names: ["Draft", "Sent", "Approved", "Declined", "Resent"],
      },
    },
    {
      type: "text_currency_integer",
      dataName: "totalIncomes",
      headerLabel: "Incomes",
      sort: true,
      whiteSpace: "nowrap",
      alignColumnContent: "end",
    },
    {
      type: "text_currency_integer",
      dataName: "totalCosts",
      headerLabel: "Costs",
      sort: true,
      whiteSpace: "nowrap",
      alignColumnContent: "end",
    },
    {
      type: "text_currency_integer",
      dataName: "results",
      headerLabel: "Result",
      sort: true,
      whiteSpace: "nowrap",
      alignColumnContent: "end",
    },
  ],
});
export default getStructure;
