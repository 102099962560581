import { Grid } from "@mui/material";
import ProfileAvatar from "./components/ProfileAvatar/ProfileAvatar";
import ProfileDetails from "./components/ProfileDetails/ProfileDetails";
import ProfilePassword from "./components/ProfilePassword/ProfilePassword";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";

const Settings = () => (
  <SectionContainer
    columnSpacing={4}
    rowSpacing={3}
    sx={{ marginTop: "var(--space-32px)" }}
  >
    <Grid item xs={12}>
      <ProfileAvatar />
    </Grid>
    <Grid xs={12} md={6} item>
      <ProfileDetails />
    </Grid>
    <Grid xs={12} md={6} item>
      <ProfilePassword />
    </Grid>
  </SectionContainer>
);

export default Settings;
