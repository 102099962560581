import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { FileStorageExtra } from "src/components/Builders/Container/ItemTypes/FileItem";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import toDay from "src/utils/date";

interface ImportFormData {
  contractId: string;
  files: FileStorageExtra[];
  projectExternalId: string;
  costCenter: string;
  date: Date;
}

type ImportFormItemsTypes = "attachment" | "default_value";

export const getStructure: UseFormContainerGetStructure<
  ImportFormData,
  unknown,
  ImportFormItemsTypes
> = ({ setFormData }) => {
  const structure: StructureContainer<ImportFormData, ImportFormItemsTypes> = {
    items: [
      {
        itemType: "attachment",
        type: "file",
        dataName: "files",
        label: "Attachment",
        filesAllowed: [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        validate: (props) => {
          return props.data?.files.length === 1;
        },
        getErrorText: (props) => {
          if ((props.data?.files.length || 0) > 1) {
            return "Only one file allowed";
          }
          return "Please select a file";
        },
        setFormData,
      },
      {
        itemType: "default_value",
        required: true,
        type: "text",
        dataName: "projectExternalId",
        label: "Project number",
      },
      {
        itemType: "default_value",
        required: false,
        type: "text_input",
        dataName: "costCenter",
        label: "Cost center",
        setFormData,
      },
      {
        itemType: "default_value",
        required: true,
        type: "date",
        dataName: "date",
        label: "Date",
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initialData,
}: {
  data?: ImportFormData;
  initialData?: ImportFormData;
}) => {
  if (!data || !initialData) {
    return data;
  }

  const submitFormData = new FormData();

  const submitData = {
    file: data.files[0]?.file!,
    contractId: initialData.contractId,
    defaultProjectExternalId: data.projectExternalId,
    defaultDate: toDay(data.date),
    defaultDimensionName: data.costCenter,
  };

  for (const [key, value] of Object.entries(submitData)) {
    value && submitFormData.append(key, value);
  }

  return submitFormData;
};
