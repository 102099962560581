import { useState } from "react";
import { useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import useTranslation from "src/hooks/useTranslationWrapper";
import ImportModal from "./components/ImportModal";
import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import InsightReportsList from "src/screens/App/Insights/Reports/components/ReportsList";

const Budget = () => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [importModalOpen, setImportModalOpen] = useState(false);

  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  const { data: dataQuery, isLoading: isReportTemplatesLoading } =
    useGetReportTemplatesQuery(undefined);
  const reportTemplates = dataQuery?.data || [];

  const handleAddButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isLoading = isLoadingContract || isReportTemplatesLoading;

  return (
    <PageWithTopPadding
      label="Budget"
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Budget" },
      ]}
      isLoading={isLoading}
      boxRight={
        <>
          <ButtonPrimary
            onClick={handleAddButtonClick}
            startIcon={<AddBoxIcon />}
          >
            {t("Add budget")}
          </ButtonPrimary>
          <Menu
            id="add-budget-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleCloseMenu}>Manual</MenuItem>
            <MenuItem
              onClick={() => {
                setImportModalOpen(true);
                handleCloseMenu();
              }}
            >
              Import from XLSX
            </MenuItem>
          </Menu>
        </>
      }
    >
      <InsightReportsList isBudgetReport reportTemplates={reportTemplates} />
      <ImportModal
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        contract={contract!}
      />
    </PageWithTopPadding>
  );
};

export default Budget;
