import BuilderColumnOptionsSingle from "../../Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "../../Basic/Simple/Buttons/ButtonUpdate";
import DateItem from "../../Builders/Container/ItemTypes/DateItem";
import FillerItem from "../../Builders/Container/ItemTypes/FillerItem";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import toDay from "src/utils/date";
import useFormTable from "src/hooks/useFormTable";
import useStyles from "../Containers/ContractArticlesStyles";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Box, Grid, Modal } from "@mui/material";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { addMonths, isBefore, startOfMonth } from "date-fns";
import { useGetPlannedInvoicesQuery } from "src/redux/services/PlannedinvoiceService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetStructureExtraProps,
  getStructure,
} from "../Containers/ContractArticlesGetStructure";
import {
  useContractArticlesMutation,
  useGetContractQuery,
} from "src/redux/services/ContractService";

type ContractArticlePriceChangeTProps = {
  articles: Article[];
  articleIdsUsed: string[];
  contractArticles?: ContractArticle[];
  contractId: string;
  handleCloseModal: () => void;
  openModal: boolean;
  setIsFormValid?: (isValid: boolean) => void;
};
const ContractArticlePriceChangeModal = (
  props: ContractArticlePriceChangeTProps,
) => {
  const classes = useStyles();
  const initialTableData = props.contractArticles;

  const [updateContractArticles] = useContractArticlesMutation();
  const { data: plannedInvoices } = useGetPlannedInvoicesQuery(
    {
      contractId: props.contractId,
      exported: true,
      sortBy: "invoiceDate",
    },
    { skip: !props.contractId },
  );
  const { data: contract } = useGetContractQuery(props.contractId, {
    skip: props.contractId === undefined,
  });

  const [updatingContract, setUpdatingContract] = useState(false);
  const [priceQuantityUpdateDate, setpriceQuantityUpdateDate] = useState(
    startOfMonth(addMonths(new Date(), 1)),
  );
  const [helperText, setHelperText] = useState("");

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData: tableFormData,
    helperText: tableHelperText,
    structure,
  } = useFormTable<ContractArticle, GetStructureExtraProps>({
    extraProps: {
      articles: props.articles,
      articleIdsUsed: props.articleIdsUsed,
      isModal: true,
    },
    initialFormData: initialTableData,
    getStructure,
    setIsFormValidExternal: props.setIsFormValid,
  });

  const columns: MUIDataTableColumnDef[] = structure.items
    .map((item) =>
      BuilderColumnOptionsSingle<ContractArticle>({
        data: tableFormData,
        helperText: tableHelperText,
        item,
      }),
    )
    .filter((column: any) => {
      return column.name !== "delete";
    });

  const submissionData = {
    contractArticles: tableFormData,
    id: props.contractId,
    priceQuantityUpdateDate: toDay(priceQuantityUpdateDate),
  };

  const onChangeDate = (data: { priceQuantityUpdateDate: Date }) => {
    if (plannedInvoices?.data && contract) {
      const firstDate =
        plannedInvoices?.data.length > 0
          ? plannedInvoices?.data[0]?.invoiceDate
          : contract.startDate;
      const finalDate = new Date(contract.endDate!);
      const nInvoices = plannedInvoices?.metadata.count;
      if (nInvoices > 0 && isBefore(data.priceQuantityUpdateDate, firstDate)) {
        setHelperText(
          "Can't set date before final exported invoice or start date",
        );
      } else if (isBefore(finalDate, data.priceQuantityUpdateDate)) {
        setHelperText("Can't set date after contract end date");
      } else {
        setHelperText("");
        setpriceQuantityUpdateDate(data.priceQuantityUpdateDate);
      }
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalStyle}>
        <Section
          label={"Price and quantity updates"}
          subLabel={"Select the date you wish the changes to start from"}
          alignTextCenter={true}
          md={12}
          lg={12}
        >
          <FillerItem
            translationNamespace={"common"}
            key={"filler"}
            item={{ type: "filler", gridProps: { xs: 4 } }}
            itemDisabled={false}
          />
          <DateItem
            translationNamespace={"common"}
            key={"date"}
            item={{
              type: "date",
              gridProps: { xs: 4 },
              dataName: "priceQuantityUpdateDate",
              setFormData: onChangeDate,
            }}
            itemDisabled={false}
            helperText={{ priceQuantityUpdateDate: helperText }}
            data={{ priceQuantityUpdateDate }}
          />
        </Section>
        <Section>
          <Grid item xs={12}>
            <MuiDataTableWrapper
              title={"Data table"}
              data={tableFormData || []}
              columns={columns}
              options={{ selectableRows: "none", pagination: false }}
            />
          </Grid>
        </Section>
        <SectionWithButtonContainer buttonCenter={true}>
          <ButtonGroup>
            <ButtonCancel onClick={props.handleCloseModal} />
            <ButtonUpdate
              initialSubmitData={initialTableData}
              isValid={helperText === ""}
              isSubmitting={updatingContract}
              onSubmit={async () => {
                setUpdatingContract(true);
                const result: any =
                  await updateContractArticles(submissionData);
                setUpdatingContract(false);
                if (!result) {
                  return;
                }
                if (result?.error) {
                  enqueueSnackbar(t("Could not update"), {
                    variant: "error",
                  });
                  throw result?.error;
                } else {
                  enqueueSnackbar(t("Successful update"), {
                    variant: "success",
                  });
                }
                props.handleCloseModal();
              }}
              permissions={contract?.permissions}
              submitData={tableFormData}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </Box>
    </Modal>
  );
};

export default ContractArticlePriceChangeModal;
