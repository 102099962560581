import { SupplierInvoiceGetList } from "src/accurasee-backend-types/app/supplierinvoice/supplierinvoice.types";
import { backendApi } from "../api";
import { docListTags } from "./utils";

const CACHE_TAG = "SupplierInvoices";
const supplierApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierInvoices: build.query<
      { data?: SupplierInvoiceGetList[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/supplierinvoices", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
  }),
});

export const { useGetSupplierInvoicesQuery } = supplierApi;

export default supplierApi;
