/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getSx = (props: CommonCallbackProps) => {
  return (
    (typeof props.item.sx === "function"
      ? props.item.sx(props)
      : props.item.sx) || {}
  );
};

export const getSubSx = (props: CommonCallbackProps) => {
  return (
    (typeof props.item.subSx === "function"
      ? props.item.subSx(props)
      : props.item.subSx) || {}
  );
};

export default getSx;
