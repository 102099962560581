import { useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useTranslation from "src/hooks/useTranslationWrapper";

export interface Breadcrumb {
  icon?: JSX.Element;
  label: string;
  link?: string;
}

const BreadcrumbsWrapper = ({ list }: { list: Breadcrumb[] }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [...list].map((e, i) =>
    i === list.length - 1 ? (
      <Link
        component="button"
        underline="none"
        key={i}
        disabled
        sx={{ color: "var(--green-primary)", cursor: "auto" }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: "var(--space-4px)",
            alignItems: "center",
          }}
        >
          {e?.icon && e.icon}
          <Typography>{t(e.label)}</Typography>
        </Box>
      </Link>
    ) : (
      <Link
        component="button"
        key={i}
        onClick={() => navigate(e?.link || "")}
        disabled={i === list.length - 1 || !e?.link}
        sx={{
          color: "var(--gray-9)",
          textDecoration: "none",
          cursor: e?.link ? "pointer" : "auto",
          "&:hover, &:focus": {
            textDecoration: e?.link ? "underline" : "none",
          },
        }}
      >
        <Box
          key={i}
          sx={{
            display: "flex",
            columnGap: "var(--space-4px)",
            alignItems: "center",
          }}
        >
          {e?.icon && e.icon}
          <Typography>{t(e.label)}</Typography>
        </Box>
      </Link>
    ),
  );

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon fontSize="small" sx={{ color: "var(--gray-9)" }} />
      }
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbsWrapper;
