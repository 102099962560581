/**
 *Created by Mikael Lindahl on 2023-01-25
 */
import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";
import SectionCore from "../../Mixed/Sections/SectionCore";
const Section = (props: Omit<SectionCoreTProps, "withTightTableHeading">) => {
  return <SectionCore {...props}>{props.children}</SectionCore>;
};

export default Section;
