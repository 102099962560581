import { MUIDataTableColumnDef } from "mui-datatables";

import { ApiKeyInformation } from "src/accurasee-backend-types/app/company/company.types";

import useFormTable from "src/hooks/useFormTable";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";

import getStructure from "./ApiKeysTableStructure";
import { useState } from "react";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import { useDeleteApiKeyMutation } from "src/redux/services/CompanyService";

interface IApiKey {
  data: ApiKeyInformation[];
}

const ApiKeysTable = ({ data }: IApiKey) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<ApiKeyInformation>();
  const [deleteAPIKey] = useDeleteApiKeyMutation();

  const { structure } = useFormTable<ApiKeyInformation>({
    initialFormData: data,
    getStructure,
    extraProps: {
      setIsOpenDeleteModal,
      setDeleteData,
    },
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: data,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: data?.length >= 10,
  });

  return (
    <>
      <MuiDataTableWrapper data={data} columns={columns} options={options} />
      <DeletionModal
        deleteData={deleteData}
        dataType={"api key"}
        openModal={isOpenDeleteModal}
        deleteDataFunction={deleteAPIKey}
        setIsOpenDeleteModal={setIsOpenDeleteModal}
      />
    </>
  );
};

export default ApiKeysTable;
