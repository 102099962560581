import { backendApi } from "../api";
import { listTag } from "./utils";

import { Dimension } from "src/accurasee-backend-types/app/dimension/dimension.types";
import { DimensionItem } from "src/accurasee-backend-types/app/dimensionitem/dimensionitem.types";

export type DimensionExtra = Dimension & { items: DimensionItem[] };

const CACHE_TAG = "Dimensions";
export const dimensionApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getDimensions: build.query<
      {
        data: DimensionExtra[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/dimensions", params }),
      providesTags: listTag(CACHE_TAG),
    }),
  }),
});

export const { useGetDimensionsQuery } = dimensionApi;

export default dimensionApi;
