/**
 *Created by Mikael Lindahl on 2023-02-22
 */
import IconButtonCore from "../../../Basic/Simple/Buttons/IconButtonCore";
import getDisabled from "../Utils/getDisabled";
import getIconByType from "../Utils/getIconByType";
import getIconType from "../Utils/getIconType";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import getSx from "../Utils/getSx";
import { Badge, Box } from "@mui/material";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import get from "../Utils/get";

type IconColumnItemTProps = CommonBuilderTableTypes;

const IconColumnItem = (props: IconColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = getDisabled(commonCallbackProps);

  const iconBadge = get("iconBadge", commonCallbackProps);
  const iconType = getIconType(commonCallbackProps);
  const { icon, style } = getIconByType(iconType);
  const styling = getStylingByStatusType(style);

  // icon button
  if (props.isClickable) {
    return (
      <Box>
        {iconBadge !== undefined ? (
          <Badge
            badgeContent={iconBadge}
            color="error"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <IconButtonCore
              disabled={itemDisabled}
              onClick={() =>
                props.item.onClick && props.item.onClick(commonCallbackProps)
              }
              permissions={props.data[props.rowIndex]?.permissions}
              permissionType={props.item.permissionType}
            >
              {icon}
            </IconButtonCore>
          </Badge>
        ) : (
          <IconButtonCore
            disabled={itemDisabled}
            onClick={() =>
              props.item.onClick && props.item.onClick(commonCallbackProps)
            }
            permissions={props.data[props.rowIndex]?.permissions}
            permissionType={props.item.permissionType}
          >
            {icon}
          </IconButtonCore>
        )}
      </Box>
    );
  } else {
    // icon
    return (
      <Box
        sx={{
          backgroundColor: styling.backgroundColor,
          color: styling.color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...getSx(commonCallbackProps),
        }}
      >
        {icon}
      </Box>
    );
  }
};

export default IconColumnItem;
