/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "../../../../../hooks/useFormTable";
import { Index } from "../../../../../accurasee-backend-types/app/index/index.types";
import { CommonCallbackProps } from "../../../../../components/Builders/Table/CommonBuilderTableTypes";
import clone from "../../../../../utils/clone";
import { OnSubmit } from "../../../../../hooks/useOnSubmit";

export type GetStructureExtraProps = {
  onEdit: (props: CommonCallbackProps) => void;
  onSubmitActiveSwitch: OnSubmit;
};
export const getStructure: UserFormTableGetStructureOptional<
  Index,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
      },
      {
        type: "text",
        dataName: "invoiceText",
        headerLabel: "Text on invoice",
      },
      {
        type: "text",
        dataName: "value",
        headerLabel: "Value",
        alignColumnContent: "end",
      },
      {
        type: "switch",
        dataName: "active",
        cellLabel: "Active/Inactive",
        gridProps: { md: 12 },
        setFormDataCustom: (propsCustom) => {
          let newData = clone(propsCustom.data);
          newData[propsCustom.rowIndex][propsCustom.item.dataName as string] =
            propsCustom.value;
          if (props?.setFormData) {
            props?.setFormData(newData);
          }
          if (props?.extraProps?.onSubmitActiveSwitch) {
            const args = {
              data: { update: { active: propsCustom.value } },
              dataId: String(newData[propsCustom.rowIndex]._id),
            };

            props?.extraProps?.onSubmitActiveSwitch(args);
          }
        },
      },
      {
        type: "icon_button",
        iconType: "edit",
        dataName: "edit",
        onClick: (onClickProps) => {
          if (props?.extraProps?.onEdit) {
            props?.extraProps?.onEdit(onClickProps);
          }
        },
      },
    ],
  };
};
