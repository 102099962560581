/**
 *Created by Mikael Lindahl on 2023-12-19
 */

import AddCircleIcon from "@mui/icons-material/AddCircle";
import useTranslation from "src/hooks/useTranslationWrapper";
import { IconButton } from "@mui/material";

type ButtonAddRowProps = {
  disabled?: boolean;
  label?: string;
  onClick: () => void;
  sx?: any;
};

const ButtonAdd = (props: ButtonAddRowProps) => {
  const [t] = useTranslation();

  return (
    <IconButton
      disabled={props.disabled}
      disableRipple
      color="primary"
      onClick={props.onClick}
      sx={{ marginTop: "8px", ...(props.sx ? props.sx : {}) }}
    >
      <AddCircleIcon fontSize="large" />
      {props.label && (
        <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
          {t(props.label)}
        </span>
      )}
    </IconButton>
  );
};

export default ButtonAdd;
