export const cleanPath = (path, value, cleaned) => {
  const [root, ...childPath] = path;
  const type = typeof value;
  if (childPath.length > 0) {
    if (!cleaned[root]) {
      cleaned[root] = {};
    }
    return cleanPath(childPath, value, cleaned[root]);
  } else if (type !== "undefined") {
    if (type === "string") {
      cleaned[root] = value.trim();
    } else {
      cleaned[root] = value;
    }
  }
};

const cleanAndUnflattenData = (data) => {
  const cleaned = {};
  const dataCopy = JSON.parse(JSON.stringify(data));
  Object.keys(dataCopy).forEach((key) => {
    const path = key.split(".");
    const value = dataCopy[key];
    cleanPath(path, value, cleaned);
  });
  return cleaned;
};

export default cleanAndUnflattenData;
