import { EmailTemplate } from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { emailTypeToNiceName } from "../../../../accurasee-backend-types/app/general/mailgun/mailgun.types";
import { CommonCallbackProps } from "../../../../components/Builders/Table/CommonBuilderTableTypes";

export interface GetStructureExtraProps {
  onDelete: (p: CommonCallbackProps) => void;
  setOpenEditModal: (v: boolean) => void;
  setEditModalData: (id: string) => void;
}

const getStructure: UserFormTableGetStructure<
  EmailTemplate,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "text",
      dataName: "type",
      headerLabel: "Email template",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) =>
        emailTypeToNiceName[data[rowIndex]?.type] || data[rowIndex]?.type,
      sort: true,
    },
    {
      type: "text",
      dataName: "languageCode",
      headerLabel: "Language",
      sx: { width: "100%" },
      sort: true,
    },
    {
      type: "text",
      dataName: "description",
      headerLabel: "Description",
      sx: { width: "100%" },
      sort: true,
    },
    {
      type: "date",
      dataName: "updatedAt",
      headerLabel: "Last edited",
      sx: { width: "100%" },
      sort: true,
    },
    {
      type: "text",
      dataName: "editedBy",
      headerLabel: "Edited by",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) => data[rowIndex]?.editedBy?.name,
    },

    {
      type: "action_row",
      iconType: "edit",
      dataName: "edit",
      alignColumnContent: "end",
      actionRowOptions: [
        {
          elementType: "icon",
          iconType: "edit",
          onClick: ({ rowIndex, data }) => {
            extraProps?.setOpenEditModal(true);
            extraProps?.setEditModalData(String(data[rowIndex]?._id));
          },
        },
        {
          elementType: "icon",
          iconType: "delete_no_cross",
          useDeleteDialog: true,
          onClick: (onClickProps) => {
            if (extraProps?.onDelete) {
              extraProps?.onDelete(onClickProps);
            }
          },
        },
      ],
    },
  ],
});

export default getStructure;
