import { Notification } from "src/accurasee-backend-types/app/notification/notification.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import {
  CommonCallbackProps,
  IconTypes,
} from "src/components/Builders/Table/CommonBuilderTableTypes";

import getIconByType from "src/components/Builders/Table/Utils/getIconByType";
import getStylingByStatusType from "src/utils/getStylingByStatusType";

const getStructure: UserFormTableGetStructure<Notification> = ({
  setFormData,
  extraProps,
}) => ({
  items: [
    {
      type: "icon",
      dataName: "type",
      iconType: (props) =>
        (props.data[props.rowIndex].type as IconTypes) || "notification",
      sx: {
        width: 40,
        height: 40,
        borderRadius: "20px",
      },
    },
    {
      type: "text_with_label",
      dataName: "message",
      subLabel: (props) =>
        (props.data[props.rowIndex].type as IconTypes) || "notification",
      subLabelPosition: "top",
      sx: (props: CommonCallbackProps<Notification>) => {
        const { read } = props.data[props.rowIndex];
        return {
          fontWeight: `${read ? "normal" : "bold"}`,
          padding: "var(--space-4px) var(--space-8px)",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        };
      },
      subSx: (props: CommonCallbackProps<Notification>) => {
        const { read, type } = props.data[props.rowIndex];
        const { style } = getIconByType((type as IconTypes) || "notification");
        const styling = getStylingByStatusType(style);
        return {
          backgroundColor: `${styling.backgroundColor}`,
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: `${read ? "normal" : "bold"}`,
          padding: "var(--space-4px) var(--space-8px)",
          textTransform: "uppercase",
          width: "fit-content",
        };
      },
      headerLabel: "Notifications",
    },
    {
      type: "date",
      dataName: "createdAt",
      alignColumnContent: "end",
    },
  ],
});

export default getStructure;
