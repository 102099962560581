import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  useGetIntegrationConfigQuery,
  useGetSetupQuery,
  useOauthMutation,
  useSelectIntegrationMutation,
} from "src/redux/services/IntegrationService";
import { IntegrationOAuth } from "./IntegrationOAuth";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";

type AccountingProps = {
  getIntegrationConfigQuery: ReturnType<typeof useGetIntegrationConfigQuery>;
  oauthMutation: ReturnType<typeof useOauthMutation>;
};

// https://apps.fortnox.se/oauth-v1/auth?client_id=ZX89gse6PI2a&redirect_uri=http://localhost:3001/app/company/integrations&scope=archive%20bookkeeping%20connectfile%20article%20companyinformation%20settings%20invoice%20customer%20costcenter%20currency%20price%20project%20payment%20supplier%20supplierinvoice&response_type=code&state=fortnox&accessType=offline
// redirect_uri=http://localhost:3001/app/company/integrations&scope=archive%20bookkeeping%20connectfile%20article%20companyinformation%20settings%20invoice%20customer%20costcenter%20currency%20price%20project%20payment%20supplier%20supplierinvoice&response_type=code&state=fortnox&accessType=offline
export function Accounting(props: AccountingProps) {
  const {
    data: integration,
    isLoading,
    isSuccess,
  } = props.getIntegrationConfigQuery;
  const { data: setup, isLoading: isLoadingSetup } =
    useGetSetupQuery(undefined);
  const [selectIntegration, { isLoading: isSelecting }] =
    useSelectIntegrationMutation(undefined);

  const accounting = integration?.accounting;

  if (isLoading && isLoadingSetup) {
    return <LinearProgress />;
  }
  return (
    <SectionContainer>
      <SectionTight
        label={"Accounting"}
        subLabel={"Manage imports for your accouting integration"}
      >
        <Grid item xs={12}>
          <Stack spacing={2}>
            {!accounting && isSuccess && (
              <Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="integration-select-label">
                      Accounting Integration
                    </InputLabel>
                    <Select
                      labelId="integration-select-label"
                      id="integration-select"
                      label="Accounting integration"
                      disabled={isSelecting}
                      onChange={(event) =>
                        selectIntegration({
                          integrationName: event.target.value,
                        })
                      }
                    >
                      <MenuItem value={"Fortnox"}>Fortnox</MenuItem>
                      <MenuItem value={"Visma"}>Visma e-accounting</MenuItem>
                      <MenuItem value={"3L"}>3L</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            {accounting && (
              <>
                <Box>
                  {setup?.authType === "oauth" && (
                    <IntegrationOAuth
                      url={setup.url}
                      authorized={accounting?.authorized}
                      accounting={accounting}
                      oauthMutation={props.oauthMutation}
                    />
                  )}
                </Box>
              </>
            )}
            {integration?.byggkollen?.instanceUid && (
              <Box>
                <Typography variant="h6">Byggkollen</Typography>
                <Typography>
                  {t(
                    "Leverantörer, kunder och artiklar exporteras till Byggkollen",
                  )}
                </Typography>
                <Typography gutterBottom={true}>
                  {t("Byggkollen kundId:")} {integration.byggkollen.instanceUid}
                </Typography>
              </Box>
            )}
          </Stack>
        </Grid>
      </SectionTight>
    </SectionContainer>
  );
}

export default Accounting;
