import { axios } from "../../general/Network";
import { backendApi } from "../api";
import { docPatchTag, listTag } from "./utils";
import {
  ReturnUser,
  ReturnUserWithPermissions,
} from "src/accurasee-backend-types/app/user/user.types";
import { Cron } from "../../accurasee-backend-types/app/cron/cron.types";

const CACHE_TAG = "Users";

const get = (data: any, instanceId: string | null = null) => {
  const headers = {
    "instance-id": instanceId,
  };
  return axios.get("/user", { headers: headers });
};

const usersApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<
      { data?: ReturnUserWithPermissions[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/superadmin/user", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getCompanyUsers: build.query<
      { data?: ReturnUserWithPermissions[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/user/company", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getUser: build.query({
      query: (params) => {
        const instanceId = localStorage.getItem("instanceId");
        return {
          url: "/user",
          params,
          headers: { "instance-id": instanceId },
        };
      },
      providesTags: listTag(CACHE_TAG),
    }),
    updateUsers: build.mutation<any, Partial<Cron>[]>({
      query: (data) => {
        return {
          url: `/superadmin/user/bulk`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    updateUser: build.mutation<{ data?: ReturnUser; metadata?: any }, any>({
      query: (data) => {
        return {
          url: "/user",
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    updateUserById: build.mutation<
      any,
      Partial<ReturnUser> & { id: ReturnUser["_id"] }
    >({
      query: ({ id, ...data }) => {
        return {
          url: `/user/company/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetCompanyUsersQuery,
  useGetUserQuery,
  useUpdateUserByIdMutation,
  useUpdateUserMutation,
  useUpdateUsersMutation,
} = usersApi;

const UserService = {
  useGetCompanyUsersQuery,
  useUpdateUserByIdMutation,
  get,
};

export default UserService;
