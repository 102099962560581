import {
  KeyboardDoubleArrowDown as ToBottom,
  KeyboardDoubleArrowUp as ToTop,
} from "@mui/icons-material";
import { axios } from "src/general/Network";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import isEqual from "lodash/isEqual";
import MessageBase from "src/components/Basic/Simple/Messages/MessageBase";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import useTranslation from "src/hooks/useTranslationWrapper";

const StreamedTextDisplay = ({
  buttons,
  filters,
  id,
  mainUrl,
}: {
  buttons: { label: string; subUrl?: string }[];
  filters: any;
  id: string;
  mainUrl: string;
}) => {
  const [t] = useTranslation();
  const [text, setText] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  // Store initial filters
  const [initialFilters, setInitialFilters] = useState({});

  const boxRef = useRef<HTMLDivElement>(null);

  const fetchStreamedData = async ({ url }: { url: string }) => {
    try {
      setIsLoading(true);
      setText([]);
      setError(null);

      // Update the initial filters to match the current ones after fetching data
      setInitialFilters(filters);

      await axios.get(url, {
        params: filters,
        responseType: "stream",
        onDownloadProgress: ({ event }) => {
          const chunk = event.currentTarget.response;
          const newWords = chunk.split(" ");
          setText(newWords);
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("Error fetching streamed data");
      console.error("Error fetching streamed data:", error);
    }
  };

  const scrollToBottom = () => {
    if (boxRef.current) {
      boxRef.current.scrollTo({
        top: boxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (boxRef.current) {
      boxRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (!boxRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 1; // Allow small offset
    setIsAtBottom(atBottom);

    const isOverflowing = scrollHeight > clientHeight;
    setShowScrollButton(isOverflowing);
  };

  useEffect(() => {
    if (boxRef.current) {
      handleScroll(); // Check overflow on mount
      boxRef.current.addEventListener("scroll", handleScroll);
      return () => boxRef.current?.removeEventListener("scroll", handleScroll);
    }
  }, [text]);

  const showWarningMessage =
    Object.keys(initialFilters).length > 0 && !isEqual(initialFilters, filters);

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 28,
          marginBottom: "var(--space-16px)",
        }}
      >
        {t("AI Analysis Insights")}
      </Typography>
      {showWarningMessage && (
        <MessageBase
          title={t("Filters have changed")}
          type="warning"
          message={t("Please reprocess to view the updated data.")}
        />
      )}
      <Box sx={{ my: 2 }} />
      <ButtonGroup>
        {/* First button - primary */}
        <ButtonPrimary
          id={`${id}-${buttons[0].label.toLowerCase()}`}
          disabled={isLoading}
          onClick={() =>
            fetchStreamedData({ url: `${mainUrl}${buttons[0]?.subUrl ?? ""}` })
          }
          sx={{
            fontSize: "12px",
            padding: "0 var(--space-16px)",
          }}
        >
          {t(buttons[0].label)}
        </ButtonPrimary>
        {/* The rest button(s) - secondary */}
        {buttons.slice(1, 5).map((button) => (
          <ButtonSecondary
            id={`${id}-${button.label.toLowerCase()}`}
            disabled={isLoading}
            onClick={() =>
              fetchStreamedData({ url: `${mainUrl}${button?.subUrl ?? ""}` })
            }
            sx={{
              fontSize: "12px",
              padding: "0 var(--space-16px)",
            }}
          >
            {t(button.label)}
          </ButtonSecondary>
        ))}
      </ButtonGroup>
      <Divider sx={{ marginTop: "var(--space-16px)" }} />
      {error ? (
        <Typography color="error" mt="var(--space-24px)">
          {error}
        </Typography>
      ) : (
        <Box
          ref={boxRef}
          sx={{
            border: "2px solid var(--gray-5)",
            borderRadius: "8px",
            display: "block",
            marginTop: "var(--space-16px)",
            // excluding height of the heading and buttons - 84px for warning message
            maxHeight: `calc(100% - 131px${showWarningMessage ? " - 84px" : ""})`,
            overflow: "auto",
            padding: "var(--space-8px) var(--space-32px)",
          }}
        >
          {!isLoading && text.length === 0 ? (
            <Typography sx={{ color: "var(--gray-5)", fontStyle: "italic" }}>
              {t("Message from AI...")}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline", // Align the elements based on the text baseline
                flexWrap: "wrap",
              }}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeSanitize]}
                components={{
                  p: ({ node, ...props }) => (
                    <p style={{ margin: "var(--space-8px) 0" }} {...props}>
                      {props.children}
                    </p>
                  ),
                  ol: ({ node, ...props }) => (
                    <ol style={{ margin: "0" }} {...props}>
                      {props.children}
                    </ol>
                  ),
                  ul: ({ node, ...props }) => (
                    <ul style={{ margin: "0" }} {...props}>
                      {props.children}
                    </ul>
                  ),
                  li: ({ node, ...props }) => (
                    <li style={{ margin: "var(--space-8px) 0" }} {...props}>
                      {props.children}
                    </li>
                  ),
                }}
              >
                {/* add an indicator when it is loading */}
                {`${text.join(" ")}  ${isLoading ? "&#9679;" : ""}`}
              </ReactMarkdown>
            </Box>
          )}
          {showScrollButton && (
            <IconButton
              onClick={isAtBottom ? scrollToTop : scrollToBottom}
              sx={{
                bottom: "var(--space-32px)",
                right: "var(--space-8px)",
                backgroundColor: "var(--green-primary)",
                color: "var(--white-primary)",
                "&:hover": {
                  backgroundColor: "var(--green-1)",
                },
                padding: "var(--space-4px)",
                position: "absolute",
                transition: "all 0.2s",
              }}
            >
              {isAtBottom ? (
                <ToTop fontSize="small" />
              ) : (
                <ToBottom fontSize="small" />
              )}
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StreamedTextDisplay;
