/**
 *Created by Mikael Lindahl on 2024-04-18
 */
import ErrorBaseWithStack, {
  NotificationWithStackProps,
} from "./ErrorBaseWithStack";

const ErrorWithStack = (props: Omit<NotificationWithStackProps, "color">) => {
  return <ErrorBaseWithStack {...props} color={"error"} />;
};

export default ErrorWithStack;
