import axios from "axios";

const backendBaseUrl =
  process.env.NODE_ENV === "production"
    ? "/api/v1" // Makes sure that the backend and frontend subdomains match in production
    : process.env.REACT_APP_BACKEND_DEV_URL || "http://localhost:3001/api/v1";
const baseWebsocketURL = backendBaseUrl; //.replace("/api/v1", "");

axios.defaults.baseURL = backendBaseUrl;
axios.defaults.timeout = 300000;

const restoreToken = () => {
  const token = localStorage.getItem("token") || "";
  setToken(token);
};

const setToken = (token: string | null) => {
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = token;
  }
};

const clearToken = () => {
  localStorage.removeItem("token");
  axios.defaults.headers.common["Authorization"] = null;
};

restoreToken();

export { axios, setToken, clearToken, backendBaseUrl, baseWebsocketURL };
