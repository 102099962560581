import LoginService from "../services/LoginService";
import UserService from "../services/UserService";
import { Dispatch } from "redux";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  GET_USER,
  RESET_USER,
  RECOVER_PASSWORD_USER,
} from "./types";

export const login =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    const res = await LoginService.login({ email, password });
    dispatch({
      type: LOGIN_USER,
      payload: res.data,
    });
    return res.data;
  };

export const register =
  (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    inviteId: string | null,
  ) =>
  async (dispatch: Dispatch) => {
    const res = await LoginService.register({
      firstName,
      lastName,
      email,
      password,
      inviteId,
    });
    dispatch({
      type: REGISTER_USER,
      payload: res.data,
    });
    return res.data;
  };

export const logout = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: LOGOUT_USER,
    });
  } catch (err) {
    console.log(err);
  }
};

export const get = (instanceId?: string) => async (dispatch: Dispatch) => {
  try {
    const res = await UserService.get(null, instanceId);
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: LOGOUT_USER,
    });
  }
};

export const reset = (email: string) => async (dispatch: Dispatch) => {
  const res = await LoginService.reset({ email });
  dispatch({
    type: RESET_USER,
    payload: res.data,
  });
  return res.data;
};

export const recover =
  (newPassword: string, resetId: string | null) =>
  async (dispatch: Dispatch) => {
    const res = await LoginService.recoverPassword({
      key: resetId,
      password: newPassword,
    });
    dispatch({
      type: RECOVER_PASSWORD_USER,
      payload: res.data,
    });
    return res.data;
  };
