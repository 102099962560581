import getTypeByStatus from "../../../utils/getTypeByStatus";
import getStylingByStatusType, {
  StylingType,
} from "../../../utils/getStylingByStatusType";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box, SxProps } from "@mui/material";
import { CommonColumnOptionTypesBase } from "src/components/Builders/Table/CommonBuilderTableTypes";

interface StatusCellProps {
  additionalText?: string;
  icon?: JSX.Element;
  justifyContent?: CommonColumnOptionTypesBase["justifyContent"];
  status?: string;
  sx?: SxProps;
  stylingType?: StylingType;
}

const StatusCell = ({
  additionalText = "",
  icon,
  justifyContent,
  status = "",
  sx,
  stylingType,
}: StatusCellProps) => {
  const [t] = useTranslation();

  if (!status) return <></>;

  const styling = getStylingByStatusType(
    stylingType || getTypeByStatus(status),
  );

  return (
    <Box
      sx={{
        backgroundColor: styling.backgroundColor,
        borderRadius: "16px",
        padding: "var(--space-4px) var(--space-12px)",
        color: styling.color,
        columnGap: "var(--space-2px)",
        display: "inline-flex",
        textAlign: "center",
        fontSize: "10px",
        fontWeight: "bold",
        minWidth: justifyContent === "start" ? "fit-content" : "100px",
        maxWidth: "200px",
        alignItems: "center",
        justifyContent: justifyContent || "center",
        textTransform: "capitalize",
        ...sx,
      }}
    >
      {icon && icon}
      {status && t(status)}
      {additionalText && ` ${additionalText}`}
    </Box>
  );
};

export default StatusCell;
