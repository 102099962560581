import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type PersistTableState = {
  columnVisibility: Record<string, boolean>;
  rowsPerPage?: number;
  filter: Record<string, any>;
};

export type UserState = {
  reportFilter: Record<string, any>;
  table: Record<string, PersistTableState>;
};

export const initialUserStateTable = {
  columnVisibility: {},
  filter: {},
};

export const initialUserState = {
  reportFilter: {
    main: {}, // Insight reports
    contract: {},
  },
  table: {},
} as UserState;

const userStateSlice = createSlice({
  name: "userState",
  initialState: initialUserState,
  reducers: {
    updateUserState(state, action: PayloadAction<UserState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateUserState } = userStateSlice.actions;
export default userStateSlice.reducer;
