import { CommonCallbackProps } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";

export type GetStructureExtraProps = {
  onDelete: (props: CommonCallbackProps) => void;
  onEdit: (props: CommonCallbackProps) => void;
};

export const getStructure: UserFormTableGetStructure<
  ContactPerson,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "email",
        headerLabel: "Email",
      },
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
        getValue: ({ data, rowIndex }) => {
          const row = data[rowIndex];
          return `${row?.firstName} ${row?.lastName}`;
        },
      },
      {
        type: "text",
        dataName: "phone",
        headerLabel: "Phone",
      },
      {
        type: "action_row",
        iconType: "edit",
        dataName: "edit",
        alignColumnContent: "end",
        actionRowOptions: [
          {
            elementType: "icon",
            iconType: "edit",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onEdit) {
                props?.extraProps?.onEdit(onClickProps);
              }
            },
          },
          {
            elementType: "icon",
            iconType: "delete_no_cross",
            onClick: (onClickProps) => {
              if (props?.extraProps?.onDelete) {
                props?.extraProps?.onDelete(onClickProps);
              }
            },
          },
        ],
      },
    ],
  };
};
