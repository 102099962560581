/**
 *Created by Mikael Lindahl on 2024-09-19
 */

import { useEffect, useRef, useState } from "react";
import parseValue from "../Utils/parseValue";
import setValue from "../Utils/setValue";
import { TextColumnItemTProps } from "../ColumnItems/TextInputColumnItem";
import getValue from "../Utils/getValue";

const useTextInputColumnItem = ({ props }: { props: TextColumnItemTProps }) => {
  const t = props.t;

  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const value = getValue(commonCallbackProps);

  const [inputValue, setInputValue] = useState(
    value || (value === 0 ? value : ""),
  );

  const propsRef = useRef(props);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setInputValue(value || (value === 0 ? value : "")); // Update state when prop changes
  }, [props]);

  const setData = (value: any) => {
    let changeProps = {
      ...commonCallbackProps,
      data: propsRef.current.data,
      rowIndex: propsRef.current.rowIndex,
      value: parseValue({ value, parse: props.parse }),
    };
    if (props.item.setFormDataCustom) {
      props.item.setFormDataCustom(changeProps);
    } else {
      setValue({ ...changeProps });
    }
  };

  return {
    commonCallbackProps,
    getHelperTextProps,
    inputRef,
    inputValue,
    propsRef,
    setData,
    setInputValue,
    t,
    value,
  };
};

export default useTextInputColumnItem;
