/**
 *Created by Mikael Lindahl on 2023-11-27
 */

import React, { createContext, ReactNode } from "react";
import { useGetPermissionCheckAccessQuery } from "../redux/services/PermissionsService";
import { LinearProgress } from "@mui/material";
import { getPermission } from "../utils/getPermission";
import {
  RoutePath,
  routePathsWithPermission,
} from "../accurasee-backend-types/app/general/routes.types";
import {
  AccessEndpointMap,
  CheckAccessPermissions,
} from "../accurasee-backend-types/app/access_role_permission/access_role_permission.types";

export interface PathPermissions {
  [key: string]: CheckAccessPermissions;
}

// Define the context type
export const AccessPermissionContext = createContext<{
  accessEndpointMap: Partial<AccessEndpointMap>;
  pathPermissions: Partial<PathPermissions>;
}>({ accessEndpointMap: {}, pathPermissions: {} });

// Context provider component with types
interface AccessPermissionProviderProps {
  children: ReactNode;
}

export const AccessPermissionProvider: React.FC<
  AccessPermissionProviderProps
> = ({ children }) => {
  const { data: permissionCheckAccessResponse, isLoading } =
    useGetPermissionCheckAccessQuery(undefined);
  const accessEndpointMap = permissionCheckAccessResponse?.data;

  if (isLoading) {
    return <LinearProgress />;
  }

  const pathPermissions: PathPermissions = {};
  for (let path of routePathsWithPermission) {
    pathPermissions[path] = getPermission({
      accessEndpointMap: accessEndpointMap as AccessEndpointMap,
      path: path as RoutePath,
    });
  }

  return (
    <AccessPermissionContext.Provider
      value={{
        accessEndpointMap: accessEndpointMap as AccessEndpointMap,
        pathPermissions,
      }}
    >
      {children}
    </AccessPermissionContext.Provider>
  );
};
