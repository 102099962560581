import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    icon: {
      margin: "auto",
      display: "block",
      fontSize: "40px",
    },
    title: {
      fontWeight: "bold",
      fontSize: "24px",
      textTransform: "capitalize",
      textAlign: "center",
      color: "var(--black-7)",
    },
    subTitle: {
      fontSize: "16px",
      textAlign: "center",
      marginBottom: "var(--space-16px)",
      color: "var(--gray-4)",
    },
    detailWrapper: {
      display: "flex",
      justifyContent: "space-between",
      border: "solid 1px var(--gray-4)",
      borderRadius: "4px",
      marginBottom: "var(--space-16px)",
      padding: "var(--space-16px) var(--space-24px)",
    },
    label: {
      fontSize: "12px",
      color: "var(--gray-4)",
    },
    labelContent: {
      fontWeight: "bold",
      color: "var(--gray-7)",
    },
    content: {
      color: "var(--gray-4)",
      overflow: "scroll",
      maxHeight: "420px",
      marginTop: "var(--space-16px)",
      "& > div": {
        marginTop: "var(--space-24px)",
      },
    },
  }),
);

export default useStyles;
