import "./index.css";
import "./redux/api";
import "./utils/getTranslationCatalog";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import Themes from "./themes";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { store, persistor } from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Themes.default}>
            <SnackbarProvider maxSnack={3}>
              <CssBaseline />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your App, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
