/**
 *Created by Mikael Lindahl on 2024-03-20
 */
import sort from "src/utils/sort";
import {
  IntegrationImportJob,
  IntegrationImportGroup,
} from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";

export type GroupedIntegrationJob = {
  createdAt: Date;
  createdBy: IntegrationImportJob["createdBy"];
  groupId: string;
  groupMainImportName: IntegrationImportJob["groupMainImportName"];
  groupSize: number;
  jobs: IntegrationImportJob[];
  status: IntegrationImportJob["status"];
};

function groupIntegrationJobs(
  jobs: IntegrationImportJob[],
): IntegrationImportGroup[] {
  const groupedJobs: { [key: string]: IntegrationImportGroup } = {};

  jobs.forEach((job) => {
    const groupIdStr = job.groupId.toString();

    if (!groupedJobs[groupIdStr]) {
      groupedJobs[groupIdStr] = {
        ...job,
        jobs: [],
      };
    }

    const jobs = groupedJobs[groupIdStr].jobs;
    if (jobs) {
      jobs.push(job);

      jobs.sort(
        (a: IntegrationImportJob, b: IntegrationImportJob) =>
          a.groupPosition - b.groupPosition,
      );
    }
  });

  let groupedJobsArray = Object.values(groupedJobs);
  groupedJobsArray = sort(groupedJobsArray, (v) => v.createdAt);

  for (const group of groupedJobsArray) {
    if (group.jobs) {
      group.status = group.jobs.find(
        (j: IntegrationImportJob) => j.status === "running",
      )
        ? "running"
        : group.jobs.slice(-1)[0].groupPosition === group.jobs.length - 1
          ? group.jobs.slice(-1)[0].status
          : "pending";
    }
  }

  return groupedJobsArray;
}

export default groupIntegrationJobs;
