import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { Company } from "src/accurasee-backend-types/app/company/company.types";
import getSelectOptions from "src/utils/getSelectOptions";

export type GetStructureExtraProps = {
  companies: Company[] | undefined;
};

const getStructure: UseFormContainerGetStructure<
  Company,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Company> = {
    items: [
      {
        type: "autocomplete",
        dataName: "_id",
        gridProps: { md: 12 },
        label: "Select company",
        options: getSelectOptions({
          data: extraProps?.companies,
          label: (c) => c.displayName || "",
          value: (c) => String(c._id),
        }),
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
