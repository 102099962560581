import { CompanydataTypes } from "../general/companydata.types";
import { Types } from "mongoose";

export const appliedToSections = ["offer", "contract"] as const;
export type AppliedToSection = (typeof appliedToSections)[number];

export const fieldTypes = ["list", "free_text"] as const;
export type FieldType = (typeof fieldTypes)[number];

export interface CustomField extends CompanydataTypes {
  appliedToSections: AppliedToSection[];
  name: string;
  fieldType: FieldType;
  required?: boolean;
  selectOptionValues?: string[];
}

export type CustomFieldData = { customFieldId: Types.ObjectId; value: string };
