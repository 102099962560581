import BuilderColumnOptionsSingle from "../../Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "../../Basic/Mixed/Buttons/ButtonSubmitCore";
import ModalWrapper from "../../Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "../../Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "../../Basic/Simple/Sections/Section";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmitCustom from "src/hooks/useOnFormSubmitCustom";
import { ArticleWithPermissions } from "src/accurasee-backend-types/app/article/article.types";
import { CostWithPermissions } from "src/accurasee-backend-types/app/cost/cost.types";
import { LinkedInvoiceDataRequest } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { toDate } from "src/utils/date";
import { useCreateLinkedInvoiceDataMutation } from "src/redux/services/LinkedInvoiceDataService";
import { useEffect, useState } from "react";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import getStructure, {
  GetStructureExtraProps,
} from "./ForwardCostsModalStructure";

type ForwardCostsModalTProps = {
  articles: ArticleWithPermissions[];
  costs: CostWithPermissions[];
  extraProps: GetStructureExtraProps;
  isLoading: boolean;
  handleCloseModal: () => void;
  openModal: boolean;
};

export type ExtentedCost = CostWithPermissions & {
  exportDate?: Date;
  includeFile?: boolean;
  includePricePerUnit?: boolean;
  includeQuantity?: boolean;
};

const ForwardCostsModal = (props: ForwardCostsModalTProps) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState<ExtentedCost[]>([]);
  const [initialFormData, setInitialFormData] = useState<ExtentedCost[]>(
    props.costs.map((cost) => {
      return {
        ...cost,
        exportDate: new Date(),
        includeFile: false,
        includePricePerUnit: false,
        includeQuantity: true,
      };
    }),
  );

  useEffect(() => {
    setInitialFormData(
      props.costs.map((cost) => {
        return {
          ...cost,
          exportDate: new Date(),
          includeFile: false,
          includePricePerUnit: false,
          includeQuantity: true,
        };
      }),
    );
  }, [props.costs]);

  const [createLinkedInvoiceData] = useCreateLinkedInvoiceDataMutation();

  const {
    formData,
    // helperText,
    isFormValid,
    setHasTriedToSubmit,
    structure: costTableStructure,
  } = useFormTable<ExtentedCost, GetStructureExtraProps>({
    extraProps: {
      ...props.extraProps,
      selectedRows,
    },
    initialFormData,
    getStructure,
  });

  useEffect(() => {
    setSelectedRows(
      formData.filter((cost) =>
        selectedRows.some((row) => row._id === cost._id),
      ),
    );
  }, [formData]);

  const { isSubmitting: isSubmittingExport, onFormSubmit } =
    useOnFormSubmitCustom({
      onSubmit: async () => {
        try {
          for (const cost of selectedRows) {
            const postData: LinkedInvoiceDataRequest = {
              filter: {
                articleNumber: props.articles.find(
                  (a) => a._id === cost.articleId,
                )?.number!,
                date: toDate(cost.exportDate)!,
                projectExternalId: cost.projectExternalId,
              },
              data: {
                pricePerUnit: cost.includePricePerUnit
                  ? cost.costPerUnit
                  : undefined,
                quantity: cost.includeQuantity ? cost.quantity : undefined,
              },
              costId: cost._id,
              sourceName:
                cost.costType === "supplier-invoice"
                  ? "supplier-invoice"
                  : "fortnox",
              supplierInvoiceId: cost.supplierInvoiceId,
              files: cost.includeFile ? cost.files : undefined,
            };
            const response: any = await createLinkedInvoiceData(postData);
            if (response?.error) {
              throw new Error("error");
            }
          }
        } catch (error) {
          setHasTriedToSubmit(false);
          setSelectedRows([]);
          props.handleCloseModal();
          enqueueSnackbar(t("Could not forward cost properties"), {
            variant: "error",
          });
          return { status: "failure" };
        }
        return { status: "success" };
      },
      onSuccess: () => {
        setHasTriedToSubmit(false);
        setSelectedRows([]);
        props.handleCloseModal();
        enqueueSnackbar(t("Cost properties forwarded to invoice"), {
          variant: "success",
        });
      },
    });

  const costColumns: MUIDataTableColumnDef[] = costTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle({
        data: formData,
        item,
      }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: props.costs.length >= 15,
    filterOptions: {
      selectableRows: "multiple",
      selectFunction: (_, current) => {
        setSelectedRows(current.map((c) => formData[c.dataIndex]));
      },
      selectToolbarPlacement: "none",
    },
  });

  return (
    <ModalWrapper
      handleCloseModal={props.handleCloseModal}
      openModal={props.openModal}
      label={"Costs available for forwarding"}
    >
      <SectionContainer>
        <Section boxStyle={{ paddingTop: 0 }}>
          <MuiDataTableWrapper
            isFetching={props.isLoading}
            data={props.costs || []}
            columns={costColumns}
            options={options}
            tableType="main-with-filter"
          />
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer buttonCenter={true}>
        <ButtonGroup>
          <ButtonCancel
            onClick={() => {
              setSelectedRows([]);
              props.handleCloseModal();
            }}
          />
          <ButtonSubmitCore
            isSubmitting={isSubmittingExport}
            initialSubmitData={[]}
            isValid={isFormValid}
            label={"export selected costs to invoice"}
            onSubmit={() => {
              onFormSubmit().catch((e) => console.error(e));
            }}
            submitData={selectedRows}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};
export default ForwardCostsModal;
