import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import SupplierInvoicesTable from "src/components/Complex/Tables/Invoices/SupplierInvoiceTable";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";
import { escape } from "src/utils/translate";

function SupplierInvoices() {
  const { contractId } = useParams<{ contractId: string }>();

  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  return (
    <PageWithTopPadding
      label={"Supplier invoices"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Supplier invoices" },
      ]}
      isLoading={isLoadingContract}
    >
      <SupplierInvoicesTable
        useColumns={[
          "supplierInvoiceNumber",
          "dueDate",
          "invoiceDate",
          "supplierName",
          "status",
          "totalExcludingVAT",
          "totalVAT",
          "total",
          "files",
        ]}
      />
    </PageWithTopPadding>
  );
}
export default SupplierInvoices;
