/**
 *Created by Julius Abelson on 2023-05-11
 */

import Grid from "@mui/material/Grid";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
type FillerItemTProps = CommonBuilderContainerTypes;

const FillerItem = (props: FillerItemTProps) => {
  return <Grid item xs={12} {...props.item.gridProps}></Grid>;
};

export default FillerItem;
