/**
 *Created by Mikael Lindahl on 2024-03-06
 */

const isRequired = (value: any) => {
  return (
    value === undefined ||
    value === "" ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  );
};

export default isRequired;
