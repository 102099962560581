import { GET_DIMENSIONS, GET_DIMENSION_ITEMS } from "../actions/types";

const initialState = {
  dimension: {},
};

const dimensionReducer = (dimension = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DIMENSIONS:
      dimension.dimensions = payload.data;
      return { ...dimension };
    case GET_DIMENSION_ITEMS:
      dimension.dimensionItems = payload.data;
      return { ...dimension };
    default:
      return dimension;
  }
};

export default dimensionReducer;
