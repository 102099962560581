import { backendApi } from "../api";
import { Index } from "../../accurasee-backend-types/app/index/index.types";
import { getPlainListAndIdTags } from "./utils";

const CACHE_TAG = "Indices";

export const indexApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getIndex: build.query<Index, any>({
      query: (id) => ({ url: `/index/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: getPlainListAndIdTags(CACHE_TAG),
    }),
    getIndices: build.query<{ data?: Index[]; metadata?: any }, any>({
      query: (params) => ({ url: "/index", params }),
      providesTags: getPlainListAndIdTags(CACHE_TAG),
    }),
    getActiveIndices: build.query<{ data?: Index[]; metadata?: any }, any>({
      query: (params) => ({ url: "/index/active", params }),
      providesTags: getPlainListAndIdTags(CACHE_TAG),
    }),
    createIndex: build.mutation<any, Index>({
      query: (data) => {
        return {
          url: "/index",
          method: "POST",
          data,
        };
      },
      invalidatesTags: [CACHE_TAG],
    }),
    updateIndex: build.mutation<any, Partial<Index> & { id?: string }>({
      query: ({ id, ...data }) => {
        return {
          url: `/index/${id}`,
          method: "PATCH",
          data: data,
        };
      },
      invalidatesTags: getPlainListAndIdTags(CACHE_TAG, "PlannedInvoices"),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateIndexMutation,
  useGetActiveIndicesQuery,
  useGetIndexQuery,
  useGetIndicesQuery,
  useUpdateIndexMutation,
} = indexApi;

export default indexApi;
