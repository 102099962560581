import {
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import {
  CommonCallbackProps,
  StructureTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";

type StructureServerSideExtraProps<DataType = any> = {
  columns: ReportGetResponseColumn<DataType>[];
};

export const getStructure: UserFormTableGetStructure<
  Partial<ReportGetResponseData>,
  StructureServerSideExtraProps<Partial<ReportGetResponseData>>
> = ({ extraProps }) => {
  const structure: StructureTable<Partial<ReportGetResponseData>> = {
    items: [],
  };

  if (extraProps?.columns) {
    for (const column of extraProps?.columns) {
      const { dataName, headerLabel, type } = column;

      structure.items.push({
        dataName,
        headerLabel,
        type,
        alignColumnContent: ["text_currency_integer", "percent"].includes(type)
          ? "end"
          : "start",
        showCellWhen: ({ data, rowIndex }) =>
          data[rowIndex].filterType !== "empty",
        sx: (props: CommonCallbackProps<Partial<ReportGetResponseData>>) => {
          const { isBold, isLarge } = props.data[props.rowIndex];
          return {
            ...(isBold ? { fontWeight: "bold" } : {}),
            ...(isLarge ? { fontSize: "20px" } : {}),
          };
        },
      });
    }
  }

  return structure;
};

// export const getColumns = ({
//   columns,
//   data,
//   t,
// }: {
//   columns: ReportGetResponseColumn[];
//   data: ReportGetResponseData[];
//   t: TFunction;
// }) => {
//   return (
//     columns.map((c) => ({
//       ...c,
//       headerLabel: c.dataName,
//       name: c.dataName,
//       label: c.label,
//       options: {
//         display: true,
//         filter: false,
//         sort: false,
//         setCellHeaderProps: () => ({
//           style: {
//             textAlign: c.type === "text_currency_integer" ? "end" : "start",
//           },
//         }),
//         ...(c.type === "text_currency_integer"
//           ? getColumnOptions({
//               ColumnItem: TextColumnItem,
//               data,
//               isCurrency: true,
//               justifyContent: "end",
//               item: {
//                 dataName: c.dataName,
//                 headerLabel: c.label,
//                 type: "number",
//                 showCellWhen: ({ data, rowIndex }) =>
//                   data[rowIndex].filterType !== "empty",
//               },
//               t,
//             })
//           : {}),
//       },
//     })) || []
//   );
// };
