import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { Index } from "../../../../../accurasee-backend-types/app/index/index.types";
import { getStructure, GetStructureExtraProps } from "./IndexTableGetStructure";
import { useState } from "react";
import IndexModalWithForm from "./IndexModalWithForm";
import { useUpdateIndexMutation } from "../../../../../redux/services/IndexService";
import useOnSubmit from "../../../../../hooks/useOnSubmit";
import useFormTable from "../../../../../hooks/useFormTable";

type IndexTableTProps = { data: Index[] };

export function IndexTable({ data }: IndexTableTProps) {
  const [openEditIndexModal, setOpenEditIndexModal] = useState(false);
  const [indexEdit, setIndexEdit] = useState({});

  const [updateIndex] = useUpdateIndexMutation();

  const onSubmit = useOnSubmit({
    apiMutations: { update: updateIndex },
    name: "index",
  });

  const { formData, structure } = useFormTable<Index, GetStructureExtraProps>({
    getStructure,
    extraProps: {
      onEdit: (props) => {
        setOpenEditIndexModal(true);
        setIndexEdit(props.data[props.rowIndex]);
      },
      onSubmitActiveSwitch: onSubmit,
    },
    initialFormData: data,
  });

  const projectTrackerTableColumns: MUIDataTableColumnDef[] =
    structure.items.map((item) =>
      BuilderColumnOptionsSingle<Index>({
        data: formData,
        item,
      }),
    );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 50,
  });

  return (
    <>
      <MuiDataTableWrapper
        nameSpace={"main-table"}
        title={""}
        data={formData}
        columns={projectTrackerTableColumns}
        options={options}
        tableType={"compact"}
      />
      <IndexModalWithForm
        actionType={"edit"}
        openModal={openEditIndexModal}
        handleCloseModal={() => setOpenEditIndexModal(false)}
        initialData={indexEdit}
      />
    </>
  );
}

export default IndexTable;
