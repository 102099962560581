/**
 *Created by Mikael Lindahl on 2022-10-03
 */

import {
  ProjectExternal,
  ProjectSeries,
} from "src/accurasee-backend-types/app/company/company.types";
import { escape } from "src/utils/translate";

type OnErrorNumberSeries = {
  newProjectExternalId?: string;
  currentProjectExternalId?: string;
  existingExternals?: ProjectExternal[];
  existingContractNumbers?: number[];
  numberSeries?: ProjectSeries;
  isCreate?: boolean;
};

/**
 * Get first available number given a list of existing and first and last allowed numbers
 *
 * @param list
 * @param firstNumber
 * @param lastNumber
 *
 * @returns first available number
 */
export const getFirstAvailableNumber = (
  list: number[],
  firstNumber: number,
  lastNumber: number,
) => {
  list.sort();

  let count = Number(firstNumber);

  while (list.includes(count) && count <= lastNumber) {
    count += 1;
  }

  return count <= lastNumber ? count : null;
};

/**
 * Get number part of project external id using list with project number series containing
 * prefix and allowed numbers for each series. If no number series is available, guess number
 *
 * @param externalId
 * @param projectNumberSeries
 *
 * @returns number part of project external id
 */
export const getNumberPart = (
  externalId?: string,
  projectNumberSeries?: ProjectSeries,
) => {
  if (!externalId) throw new Error("Missing project external id");
  if (!projectNumberSeries) return guessNumberPart(externalId);

  const prefix = projectNumberSeries.prefix;

  let number: string;
  if (prefix) {
    number = externalId.replace(prefix, "");
  } else {
    const match = externalId.match(/\d+$/);

    if (match === null) {
      return -1;
    }

    number = match[0];
  }

  return Number(number);
};

/**
 * Get list of project externals that are within number series.
 *
 * @param projectExternals
 * @param series
 *
 * @returns list of valid ProjectExternal
 */
export const getValidProjectExternals = ({
  projectExternals,
  series,
}: {
  projectExternals: ProjectExternal[] | undefined;
  series?: ProjectSeries;
}) => {
  if (!series) return [];

  let valid = projectExternals?.filter((p) =>
    series.prefix ? series.prefix === p.parts.prefix : !p.parts.prefix,
  );

  valid = valid?.filter(
    (p) =>
      Number(series.firstNumber) <= p.parts.number &&
      p.parts.number <= Number(series.lastNumber),
  );

  return valid;
};

/**
 * Guess number part of project external id using regex. This function is useful when
 * inferring prefix and number from project external id when no number series is available.
 * Currently, it is used when displaying project external id in project list in order to add
 * padding to the number part of the project external id and in getNumberPart when project number
 * series is missing. Optimal would be to move away from guessing and use available number
 * series to get prefix and number part.
 *
 * @param externalId
 * @param projectNumberSeries
 *
 * @returns number part of project external id
 */
export const guessNumberPart = (
  externalId?: string,
  projectNumberSeries?: ProjectSeries,
) => {
  if (!externalId) throw new Error("Missing project external id");

  const match = externalId.match(/\d+$/);

  if (match === null) {
    return -1;
  }

  const number = match[0];

  return Number(number);
};

/**
 * Guess prefix part of project external using guessNumberPart
 *
 * @param externalId
 *
 * @returns prefix part of project external id
 */
export const guessPrefixPart = (externalId?: string) => {
  if (!externalId) throw new Error("Missing project external id");
  const number = guessNumberPart(externalId);
  const prefix =
    number === -1 ? externalId : externalId.replace(String(number), "");
  return prefix;
};

export const getPrefixPart = (projectNumberSeries?: ProjectSeries) => {
  return projectNumberSeries?.prefix;
};

/**
 * Check if project external id is within number series by checking
 * prefix and number against project external id
 *
 * @param projectExternalId
 * @param numberSeries
 *
 * @returns boolean
 */
export const isWithinNumberSeries = (
  projectExternalId?: string,
  numberSeries?: ProjectSeries,
) => {
  if (
    numberSeries?.prefix !== undefined &&
    !projectExternalId?.startsWith(numberSeries?.prefix)
  )
    return false;

  const numberPart = getNumberPart(projectExternalId, numberSeries);

  return (
    projectExternalId &&
    numberSeries &&
    Number(numberSeries.firstNumber) <= numberPart &&
    numberPart <= Number(numberSeries.lastNumber)
  );
};

/**
 * Check if project external already exists, is within number series or if all numbers are taken
 *
 * @param newProjectExternalId
 * @param currentProjectExternalId
 * @param existingExternals
 * @param existingContractNumbers
 * @param numberSeries
 * @param isCreate
 *
 * @returns error message if any
 */
export const onErrorNumberSeries = (props: OnErrorNumberSeries) => {
  let message = "";

  if (!props.numberSeries || props.existingExternals === undefined) {
    return message;
  }

  const validProjectExternal =
    getValidProjectExternals({
      projectExternals: props.existingExternals,
      series: props.numberSeries,
    }) || [];

  const existingExternalsFiltered = props.existingExternals.filter(
    (p) => p.parts.prefix === props.numberSeries?.prefix,
  );

  const number = getFirstAvailableNumber(
    existingExternalsFiltered.map((p) => p.parts.number),
    Number(props.numberSeries.firstNumber),
    Number(props.numberSeries.lastNumber),
  );

  if (number === null) {
    message = `Sorry there is no available project numbers in this series. Please add numbers to series or change to another series for this contract type`;
  } else if (
    props.newProjectExternalId &&
    validProjectExternal
      .map((p) => p.id)
      .includes(props.newProjectExternalId) &&
    props.isCreate
  ) {
    message = `This number already exists, please choose another. First available number is${escape(
      " " + String(number),
    )}`;
  } else if (
    !isWithinNumberSeries(props.newProjectExternalId, props.numberSeries) &&
    props.isCreate
  ) {
    message =
      "Number outside number series. Please make sure the number series of the contract type includes it";
  }

  return message;
};
