import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import IntegrationAuthModal from "./IntegrationAuthModal";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Accounting } from "src/accurasee-backend-types/app/integrations/integrationconfig.types";
import { Box } from "@mui/system";
import {
  Alert,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetIntegrationConfigQuery,
  useOauthMutation,
  useRenewAccessTokenMutation,
} from "src/redux/services/IntegrationService";
import TimeDifferenceNowBadge from "src/components/Basic/Simple/Badges/TimeDifferenceNowBadge";
import MessageBase from "../../../../../components/Basic/Simple/Messages/MessageBase";

type IntegrationOAuthTProps = {
  url: string;
  authorized: boolean | undefined;
  accounting: Accounting;
  oauthMutation: ReturnType<typeof useOauthMutation>;
};

export function IntegrationOAuth(props: IntegrationOAuthTProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const params = new URLSearchParams(location.search);

  const [setupIntegration, { isLoading, isUninitialized, isSuccess, error }] =
    props.oauthMutation;
  const { data, isLoading: isLoadingCompany } = useGetCompanyQuery(undefined);
  const company = data?.company;

  const { data: dataIntegrationConfig } =
    useGetIntegrationConfigQuery(undefined);
  const accounting = dataIntegrationConfig?.accounting;
  const [renewAccessToken] = useRenewAccessTokenMutation();
  useEffect(() => {
    if (params.has("code") && isUninitialized) {
      const code = params.get("code");
      setupIntegration({ code })
        .then((res) => {
          if ("data" in res) {
            navigate("/app/setup/integrations");
          }
        })
        .catch((e) => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized, isSuccess]);

  if (isLoadingCompany) {
    return <LinearProgress />;
  }

  return (
    <>
      <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Box>
          <ButtonPrimary
            id="re-authorize-fortnox"
            disabled={isLoading}
            onClick={() => setOpenAuthModal(true)}
          >
            {`${accounting?.authorized ? t("Re-authorize") : t("Authorize")} ${
              props.accounting.integrationName
            }`}
          </ButtonPrimary>
        </Box>
        <Box sx={{ marginTop: "0.5rem" }}>
          {isLoading && <CircularProgress />}
          {isSuccess && <MessageBase title={"Authorized"} type={"success"} />}
          {error && (
            <Typography marginTop={1}>
              Authorization error:{" "}
              {"data" in error ? error.data?.error?.message : "Unknown error"}.
              Please re-auth your client
            </Typography>
          )}
          {accounting?.reAuthNeeded && (
            <MessageBase title={"Re-authorization needed"} type={"alert"} />
          )}
        </Box>
        <Box sx={{ marginTop: "0.5rem" }}>
          {accounting?.authorized &&
            !accounting.reAuthNeeded &&
            !accounting?.refreshTokenExpired && (
              <Box>
                <ButtonPrimary
                  id="re-authorize-fortnox"
                  disabled={isLoading}
                  onClick={() =>
                    renewAccessToken(undefined).catch((e) => {
                      Alert(e);
                    })
                  }
                >
                  {t("Renew access token")}
                </ButtonPrimary>
              </Box>
            )}
          <Box>
            {!accounting?.isAccessTokenValid && accounting?.authorized && (
              <MessageBase
                title={"Access token is invalid"}
                message={"Please renew"}
                type={"alert"}
              />
            )}
            {accounting?.accessTokenExpired &&
              !accounting?.refreshTokenExpired &&
              accounting?.authorized && (
                <MessageBase
                  title={"Access token has expired"}
                  message={"Please renew"}
                  type={"alert"}
                />
              )}
            {!accounting?.isRefreshTokenValid && accounting?.reAuthNeeded && (
              <MessageBase
                title={"Refresh token is invalid"}
                message={"Please re-authorize"}
                type={"alert"}
              />
            )}
            {accounting?.refreshTokenExpired && (
              <MessageBase
                title={"Refresh token has expired"}
                message={"Please re-authorize"}
                type={"alert"}
              />
            )}
            {accounting?.authorized &&
              !accounting?.reAuthNeeded &&
              accounting?.isAccessTokenValid &&
              !accounting?.refreshTokenExpired &&
              accounting?.accessTokenExpirationDate && (
                <TimeDifferenceNowBadge
                  date={accounting?.accessTokenExpirationDate}
                  isError={accounting?.accessTokenExpired}
                  label={"Access token expiration"}
                />
              )}
            {accounting?.authorized &&
              !accounting?.reAuthNeeded &&
              accounting?.refreshTokenExpirationDate && (
                <TimeDifferenceNowBadge
                  date={accounting?.refreshTokenExpirationDate}
                  isError={accounting?.refreshTokenExpired}
                  label={"Refresh token expiration"}
                />
              )}
          </Box>
        </Box>
        <IntegrationAuthModal
          company={company}
          handleCloseModal={() => setOpenAuthModal(false)}
          accounting={accounting}
          openModal={openAuthModal}
          url={props.url}
          isError={!!error}
        />
        {!error &&
          accounting?.refreshTokenExpired === false &&
          accounting?.authorized &&
          !accounting?.reAuthNeeded && (
            <Typography
              style={{
                fontStyle: "italic",
                color: "var(--gray-4)",
                marginBottom: "0.5rem",
              }}
            >
              {t(
                "Your refresh token has not expired so you should not need to re-authorize your accounting integration.",
              )}
            </Typography>
          )}
      </Box>
    </>
  );
}
