import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useTranslation from "src/hooks/useTranslationWrapper";

const blobToText = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsText(blob);
  });
};

export function usePdfDownloader(getPdf: () => Promise<any>, filename: string) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);

  const downloadPdf = async () => {
    setIsFetchingPdf(true);
    try {
      const pdfRes = await getPdf();

      if (pdfRes?.data) {
        fileDownload(pdfRes.data, filename || "invoice.pdf", "application/pdf");
      }
      setIsFetchingPdf(false);
    } catch (error: any) {
      setIsFetchingPdf(false);

      const message = await blobToText(error?.response?.data);

      enqueueSnackbar(
        `${t("Could not Download pdf")} ${message ? `: ${t(message)}` : ""}`,
        { variant: "error" },
      );
    }
  };
  return { downloadPdf, isFetchingPdf };
}
