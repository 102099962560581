/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import _c from "src/constants/Constants";
import { ContractInsightWithPermissions } from "src/accurasee-backend-types/app/insight/insight.types";
import { ContractTypeWithPermissions } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { Types } from "mongoose";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import Constants from "src/constants/Constants";
import getCustomerName from "src/utils/getCustomerName";
import getSelectOptions from "src/utils/getSelectOptions";
import getTableFilterOptionIds from "src/utils/getTableFilterOptionIds";

interface GetStructureExtraProps {
  contractTypes?: ContractTypeWithPermissions[];
  currencyCode?: string;
  customers?: Customer[];
  users?: ReturnUser[];
}

type GetStructure = UserFormTableGetStructureOptional<
  Partial<ContractInsightWithPermissions>,
  GetStructureExtraProps
>;

export const getStructure: GetStructure = (props) => {
  const t = props?.t ? props.t : (s: string) => s;

  const items: ReturnType<GetStructure>["items"] = [
    {
      dataName: "number",
      headerLabel: "#",
      type: "text",
      sort: true,
    },
    {
      type: "text",
      dataName: "projectExternalId",
      headerLabel: "Project number",
      sort: true,
    },
    {
      type: "text",
      dataName: "projectName",
      headerLabel: "Project",
      sort: true,
      getValue: ({ data, rowIndex }) => {
        const name = data[rowIndex]?.projectName || "";
        const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
        return name.length > maxLength
          ? name.slice(0, maxLength) + "..."
          : name;
      },
    },
    {
      dataName: "name",
      headerLabel: "Description",
      type: "link",
      sort: true,
      redirectLink: (props) =>
        "/app/contracts/" + String(props.data[props.rowIndex].contractId),
      getValue: ({ data, rowIndex }) => {
        const name = data[rowIndex]?.name || "";
        const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
        return name.length > maxLength
          ? name.slice(0, maxLength) + "..."
          : name;
      },
      sx: { minWidth: "378px" },
    },
    {
      type: "text",
      dataName: "customerId",
      headerLabel: "Customer",
      getValue: ({ data, rowIndex }) => {
        return getCustomerName(data[rowIndex]?.customerId, {
          maxChars: Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE,
          excludeExternalId: true,
        });
      },
      ...getTableFilterOptionIds({
        options: getSelectOptions({
          data: props?.extraProps?.customers,
          label: (c) => c.name,
          value: (c) => String(c._id),
        }),
        dataLabel: t("Customers"),
        isFullWidth: false,
      }),
      sort: true,
    },
    {
      type: "text",
      dataName: "ownerIds",
      headerLabel: "Contract owners",
      sort: true,
      getValue: ({ data, rowIndex }) => {
        const owners =
          data[rowIndex]?.ownerIds?.map((ownerId: Types.ObjectId) => {
            const owner = props?.extraProps?.users?.find(
              (e) => e._id === ownerId,
            );
            return `${owner?.firstName} ${owner?.lastName}`;
          }) || [];

        return owners?.join(", ") || " ";
      },
      ...getTableFilterOptionIds({
        options: getSelectOptions({
          data: props?.extraProps?.users,
          label: (c) => `${c.firstName} ${c.lastName}`,
          value: (c) => String(c._id),
        }),
        dataLabel: t("Contract owners"),
        isFullWidth: false,
      }),
    },
    {
      type: "badge",
      dataName: "status",
      headerLabel: "Status",
      sort: true,
      filter: true,
      filterOptions: { names: _c.CONTRACT_STATUS_FILTER_OPTIONS },
      alignColumnContent: "center",
    },
    {
      type: "text",
      dataName: "contractTypeId",
      headerLabel: "Contract type",
      sort: true,
      getValue: ({ data, rowIndex }) => {
        const result = props?.extraProps?.contractTypes?.find(
          (e) => e._id === data[rowIndex]?.contractTypeId,
        );
        return result?.name || "";
      },
      ...getTableFilterOptionIds({
        options: getSelectOptions({
          data: props?.extraProps?.contractTypes,
          label: (c) => c.name,
          value: (c) => String(c._id),
        }),
        dataLabel: t("Contract types"),
      }),
    },
    {
      type: "text_currency",
      currencyCode: props?.extraProps?.currencyCode,
      dataName: "yearToDate",
      headerLabel: "Result year to date",
      sort: true,
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      currencyCode: props?.extraProps?.currencyCode,
      dataName: "result",
      headerLabel: "Results",
      sort: true,
      alignColumnContent: "end",
      sx: { fontWeight: "bold" },
    },
    {
      type: "percent",
      dataName: "margin",
      headerLabel: "Margin",
      sort: true,
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      currencyCode: props?.extraProps?.currencyCode,
      dataName: "incomes",
      headerLabel: "Incomes",
      sort: true,
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      currencyCode: props?.extraProps?.currencyCode,
      dataName: "costs",
      headerLabel: "Costs",
      getValue: ({ data, rowIndex }) => {
        const { costs } = data[rowIndex];
        return costs && costs > 0 ? `-${costs}` : 0;
      },
      getSheetExportValue: ({ data, rowIndex }) => {
        const { costs } = data[rowIndex];
        return costs && costs > 0 ? { v: -costs, t: "n" } : 0;
      },
      sort: true,
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      currencyCode: props?.extraProps?.currencyCode,
      dataName: "plannedInvoices",
      headerLabel: "Planned invoices",
      sort: true,
    },
    {
      type: "date",
      dataName: "startDate",
      sort: true,
      headerLabel: "Start date",
    },
    {
      type: "date",
      dataName: "endDate",
      sort: true,
      headerLabel: "End date",
    },
  ];

  return {
    items: items.map(
      (item, i) =>
        ({
          ...item,
          ...(i !== 0 && item.type !== "text_currency"
            ? { showCellWhen: (_props) => !_props.data[_props.rowIndex]?._id }
            : {}),
        }) as StructureItemTable,
    ),
  };
};
