import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import ButtonCore from "../../Simple/Buttons/ButtonCore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

type ConsentDialogTProps = {
  title?: string;
  children: React.ReactNode;
  consentButtons: {
    disabled?: boolean;
    label: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    styles?: any;
    variant?: "contained" | "outlined";
  }[];
  handleClose?: (id?: string) => void;
  maxWith?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  id?: string;
  open: boolean;
};

function ConsentDialog(props: ConsentDialogTProps) {
  const { open, handleClose, consentButtons } = props;

  return (
    <Dialog
      maxWidth={props?.maxWith || "lg"}
      style={{ color: "white" }}
      onClose={() => {
        if (handleClose) {
          handleClose();
        }
      }}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          padding: "2rem 4rem",
          borderRadius: "8px",
          backgroundColor: "var(--white-primary)",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", // Add this line to center children vertically
          textAlign: "center", // Optional: Center text within children
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {consentButtons.map((item, index) => {
          return (
            <ThemeProvider
              key={index}
              theme={() =>
                createTheme({
                  palette: {
                    primary: {
                      main: "#1F5436",
                    },
                  },
                })
              }
            >
              <ButtonCore
                disabled={item.disabled}
                isLoading={item.isLoading}
                key={index}
                onClick={(e) => {
                  if (item.onClick) {
                    item.onClick(e);
                  }
                  if (handleClose) {
                    handleClose(props.id);
                  }
                }}
                variant={
                  item.variant ||
                  (item.label === "Cancel" || item.label === "No"
                    ? "contained"
                    : "outlined")
                }
              >
                {item.label}
              </ButtonCore>
            </ThemeProvider>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}

export default ConsentDialog;
