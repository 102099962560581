import { Box, IconButton, SxProps } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Constants from "src/constants/Constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useTranslation from "src/hooks/useTranslationWrapper";

interface SidePanelProps {
  buttonSx?: SxProps;
  buttonTitle?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  isSidePanelActive?: boolean;
  leftSideContent:
    | (boolean | undefined | JSX.Element)[]
    | boolean
    | JSX.Element
    | undefined;
  rightSideContent:
    | (boolean | undefined | JSX.Element)[]
    | boolean
    | JSX.Element
    | undefined;
}

const SidePanel = ({
  buttonSx,
  buttonTitle,
  disabled,
  icon,
  isSidePanelActive,
  leftSideContent,
  rightSideContent,
}: SidePanelProps) => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [rightBoxHeight, setRightBoxHeight] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateRightBoxHeight = () => {
      const windowHeight = window.innerHeight;
      const headerHeight = document.querySelector("#header")?.clientHeight || 0;
      const pageHeadHeight =
        containerRef.current?.getBoundingClientRect().top || 0; // Distance from top

      const calculatedHeight =
        windowHeight - (headerHeight + pageHeadHeight + 16); // 16px for the bottom spacing
      setRightBoxHeight(calculatedHeight);
    };

    updateRightBoxHeight();

    // Update on window resize
    window.addEventListener("resize", updateRightBoxHeight);
    return () => window.removeEventListener("resize", updateRightBoxHeight);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        width: "calc(100% - 36px)",
        transition: "all 0.5s ease",
        columnGap: "var(--space-32px)",
      }}
    >
      {/* Left box */}
      <Box
        sx={{
          position: "relative",
          transition: "width 0.5s ease",
          width: "100%",
          ...(isVisible && {
            width: `calc(100% - ${Constants.VIEWER_WIDTH})`,
          }),
        }}
      >
        {leftSideContent}
        {isSidePanelActive && (
          <IconButton
            disabled={disabled}
            onClick={() => setIsVisible(!isVisible)}
            sx={{
              backgroundColor: "var(--warning)",
              position: "absolute",
              right: 0,
              top: 0,
              ":hover": {
                backgroundColor: "var(--warning-3)",
              },
              "& .Mui-disabled": { backgroundColor: "var(--black-1)" },
              ...buttonSx,
            }}
            title={
              isVisible
                ? t("Close")
                : t(buttonTitle || "View more for Analytics")
            }
          >
            {isVisible ? (
              <KeyboardArrowRightIcon />
            ) : icon ? (
              icon
            ) : (
              <AnalyticsIcon />
            )}
          </IconButton>
        )}
      </Box>

      {/* Right box */}
      {isSidePanelActive && (
        <Box
          sx={{
            backgroundColor: "var(--white-primary)",
            borderRadius: "8px",
            boxShadow: "-10px 0 20px -10px rgba(0, 0, 0, 0.5)",
            padding: "var(--space-24px) var(--space-32px)",
            transition: "width 0.5s ease, opacity 0.2s ease",
            width: 0,
            opacity: 0,
            height: rightBoxHeight ? `${rightBoxHeight}px` : "auto", // Dynamically set rightBoxHeight
            overflowY: "auto", // Allow scrolling if content exceeds rightBoxHeight
            ...(isVisible && {
              width: `${Constants.VIEWER_WIDTH}`,
              opacity: 1,
            }),
          }}
        >
          {rightSideContent}
        </Box>
      )}
    </Box>
  );
};

export default SidePanel;
