/**
 *Created by Mikael Lindahl on 2023-10-16
 */
import {
  ProjectTrackerConsolidatedCompanyIncomeReport,
  ProjectTrackerPercentageOfCompletion,
  ProjectTrackerPercentageOfCompletionSub,
  ProjectTrackerPercentageOfCompletionVariant2,
  ProjectTrackerPercentageOfCompletionVariant2Sub,
  ProjectTrackerRegion,
} from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";

export const projectTrackerRegionData: ProjectTrackerRegion[] = [
  {
    _id: 1,
    name: "Sektion NordÖst",
    month_minus_2: 2645184,
    month_minus_1: 2184435,
    year_to_date: 38103579,
    active_projects: 13591867,
    depth: 0,
    currencyCode: "SEK",
  },
  {
    _id: 2,
    name: "Företag 1",
    month_minus_2: 761801,
    month_minus_1: 569887,
    year_to_date: 12380300,
    active_projects: 3826037,
    dependencyId: 1,
    depth: 1,
    currencyCode: "SEK",
  },
  {
    _id: 3,
    name: "Projektledare 1",
    month_minus_2: 350489,
    month_minus_1: 122487,
    year_to_date: 4679014,
    active_projects: 1247890,
    dependencyId: 2,
    depth: 2,
    currencyCode: "SEK",
  },
  {
    _id: 4,
    name: "Projektledare 2",
    month_minus_2: -56000,
    month_minus_1: 430000,
    year_to_date: 3497612,
    active_projects: 687900,
    dependencyId: 2,
    depth: 2,
    currencyCode: "SEK",
  },
  {
    _id: 5,
    name: "Projektledare 3",
    month_minus_2: 467312,
    month_minus_1: 17400,
    year_to_date: 4203674,
    active_projects: 1890247,
    dependencyId: 2,
    depth: 2,
    currencyCode: "SEK",
  },
  {
    _id: 6,
    name: "Företag 2",
    month_minus_2: 642347,
    month_minus_1: -420348,
    year_to_date: 4376490,
    active_projects: 2346100,
    dependencyId: 1,
    depth: 1,
    currencyCode: "SEK",
  },
  {
    _id: 7,
    name: "Företag 3",
    month_minus_2: 1241036,
    month_minus_1: 2034896,
    year_to_date: 21346789,
    active_projects: 7419730,
    dependencyId: 1,
    depth: 1,
    currencyCode: "SEK",
  },
  {
    _id: 8,
    name: "Sektion Sydväst",
    month_minus_2: 3416789,
    month_minus_1: 1430789,
    year_to_date: 18467986,
    active_projects: 11348796,
    depth: 0,
    currencyCode: "SEK",
  },
];

export const projectTrackerPercentageOfCompletionData: ProjectTrackerPercentageOfCompletion[] =
  [
    {
      _id: 101000,
      userId: 1,
      userName: "Projektledare 1",
      estimatedCoverageRate: 160,
      estimatedProjectResult: 555200,
      percentageOfCompletionCurrent: 329500,
      percentageOfCompletionMinus1: 227500,
      percentageOfCompletionMinus2: 54500,
      currencyCode: "SEK",
    },
    {
      _id: 102000,
      userId: 2,
      userName: "Projektledare 2",
      estimatedCoverageRate: 190,
      estimatedProjectResult: 756000,
      percentageOfCompletionCurrent: 458000,
      percentageOfCompletionMinus1: 324000,
      percentageOfCompletionMinus2: 330500,
      currencyCode: "SEK",
    },
    {
      _id: 103000,
      userId: 3,
      userName: "Projektledare 3",
      estimatedCoverageRate: 210,
      estimatedProjectResult: 864000,
      percentageOfCompletionCurrent: 624000,
      percentageOfCompletionMinus1: 480600,
      percentageOfCompletionMinus2: 340680,
      currencyCode: "SEK",
    },
  ];

export const projectTrackerPercentageOfCompletionSubData: ProjectTrackerPercentageOfCompletionSub[] =
  [
    {
      _id: 1,
      userId: 1,
      projectName: "Projekt 1",
      hoursWorked: "1060/1600 = 66.3%",
      supplierCost: "150000/200000 = 75.0%",
      calculatedCompletion: 68.3,
      estimatedCompletion: 70.0,
      estimatedCoverageRate: 30,
      estimatedProjectResult: 245000,
      percentageOfCompletionCurrent: 171500,
      percentageOfCompletionMinus1: 115000,
      percentageOfCompletionMinus2: 12500,
      currencyCode: "SEK",
    },
    {
      _id: 2,
      userId: 1,
      projectName: "Projekt 2",
      hoursWorked: "1750/1700 = 102.9%",
      supplierCost: "300000/300000 = 100.0%",
      calculatedCompletion: 102.0,
      estimatedCompletion: 95.0,
      estimatedCoverageRate: 2,
      estimatedProjectResult: 25700,
      percentageOfCompletionCurrent: 15000,
      percentageOfCompletionMinus1: 22500,
      percentageOfCompletionMinus2: 30000,
      currencyCode: "SEK",
    },
    {
      _id: 3,
      userId: 1,
      projectName: "Projekt 3",
      hoursWorked: "500/1000 = 50.0%",
      supplierCost: "200000/350000 = 57.0%",
      calculatedCompletion: 53.3,
      estimatedCompletion: 53.3,
      estimatedCoverageRate: 20,
      estimatedProjectResult: 148600,
      percentageOfCompletionCurrent: 75000,
      percentageOfCompletionMinus1: 56000,
      percentageOfCompletionMinus2: 0,
      currencyCode: "SEK",
    },
    {
      _id: 4,
      userId: 1,
      projectName: "Projekt 4",
      hoursWorked: "750/1250 = 60.0%",
      supplierCost: "250000/500000 = 50.0%",
      calculatedCompletion: 55.0,
      estimatedCompletion: 55.0,
      estimatedCoverageRate: 14,
      estimatedProjectResult: 135900,
      percentageOfCompletionCurrent: 68000,
      percentageOfCompletionMinus1: 34000,
      percentageOfCompletionMinus2: 12000,
      currencyCode: "SEK",
    },
  ];

export const projectTrackerPercentageOfCompletionVariant2Data: ProjectTrackerPercentageOfCompletionVariant2[] =
  [
    {
      _id: 1,
      userId: 1,
      userName: "Projektledare 1",
      estimatedProjectResult: 1485000, // Removed 'kr' and converted to number
      estimatedCoverageRate: 30.6, // Converted to number
      accountingResults: 985000, // Removed 'kr' and converted to number
      percentageOfCompletionCurrent: 14250, // Removed 'kr' and converted to number
      currencyCode: "SEK",
    },
    {
      _id: 1,
      userId: 2,
      userName: "Projektledare 2",
      estimatedProjectResult: 1248900, // Removed 'kr' and converted to number
      estimatedCoverageRate: 24.0, // Converted to number
      accountingResults: 1350000, // Removed 'kr' and converted to number
      percentageOfCompletionCurrent: 65000, // Removed 'kr' and converted to number
      currencyCode: "SEK",
    },
    {
      _id: 1,
      userId: 3,
      userName: "Projektledare 3",
      estimatedProjectResult: 1560000, // Removed 'kr' and converted to number
      estimatedCoverageRate: 23.0, // Converted to number
      accountingResults: 1705000, // Removed 'kr' and converted to number
      percentageOfCompletionCurrent: -75000, // Removed 'kr' and converted to number
      currencyCode: "SEK",
    },
  ];

export const projectTrackerPercentageOfCompletionVariant2SubData: ProjectTrackerPercentageOfCompletionVariant2Sub[] =
  [
    {
      _id: 11,
      projectName: "Projekt 1",
      projectCost: "750000/1000000 = 75%",
      invoicedProject: "975000/1300000 = 75.0%",
      alterationAndAdditionalCost: 50000,
      alterationAndAdditionalIncome: 75000,
      adjustments: 150000,
      estimatedProjectResult: 475000,
      estimatedCoverageRate: 34.5, // Converted to number
      accountingResults: 250000,
      percentageOfCompletionCurrent: 106250,
      userId: 1,
      currencyCode: "SEK",
    },
    {
      _id: 11,
      projectName: "Projekt 2",
      projectCost: "550000/500000 = 110%",
      invoicedProject: "800000/800000 = 100.0%",
      alterationAndAdditionalCost: 125000,
      alterationAndAdditionalIncome: 150000,
      adjustments: -75000,
      estimatedProjectResult: 250000,
      estimatedCoverageRate: 26.3, // Converted to number
      accountingResults: 275000,
      percentageOfCompletionCurrent: -25000,
      userId: 1,
      currencyCode: "SEK",
    },
    {
      _id: 11,
      projectName: "Projekt 3",
      projectCost: "150000/500000 = 30%",
      invoicedProject: "400000/700000 = 57.0%",
      alterationAndAdditionalCost: 100000,
      alterationAndAdditionalIncome: 110000,
      adjustments: 0,
      estimatedProjectResult: 210000,
      estimatedCoverageRate: 25.9, // Converted to number
      accountingResults: 260000,
      percentageOfCompletionCurrent: -197000,
      userId: 1,
      currencyCode: "SEK",
    },
    {
      _id: 11,
      projectName: "Projekt 4",
      projectCost: "600000/1000000 = 60%",
      invoicedProject: "750000/1500000 = 50.0%",
      alterationAndAdditionalCost: 75000,
      alterationAndAdditionalIncome: 125000,
      adjustments: 0,
      estimatedProjectResult: 550000,
      estimatedCoverageRate: 35.5, // Converted to number
      accountingResults: 200000,
      percentageOfCompletionCurrent: 130000,
      userId: 1,
      currencyCode: "SEK",
    },
  ];

export const projectTrackerConsolidateCompanyIncomeReportMockData: ProjectTrackerConsolidatedCompanyIncomeReport[] =
  [
    {
      _id: 1,
      name: "Företagsgrupp 1",
      thisMonth: 1824441,
      nextMonth: 1477988,
      plus2Month: 1645259,
      thisYear: 4947688,
      nextYear: 16452590,
      currencyCode: "SEK",
      depth: 0,
    },
    {
      _id: 2,
      name: "Företagsgrupp 2",
      thisMonth: 1175778,
      nextMonth: 3673163,
      plus2Month: 1055776,
      thisYear: 5904717,
      nextYear: 12669312,
      currencyCode: "SEK",
      depth: 0,
    },
    {
      _id: 3,
      name: "Företag 1",
      thisMonth: 709784,
      nextMonth: 591908,
      plus2Month: 686624,
      thisYear: 1988316,
      nextYear: 6866240,
      currencyCode: "SEK",
      dependencyId: 1,
      depth: 1,
    },
    {
      _id: 4,
      name: "Företag 3",
      thisMonth: 554539,
      nextMonth: 433733,
      plus2Month: 493983,
      thisYear: 1482255,
      nextYear: 5433813,
      currencyCode: "SEK",
      dependencyId: 1,
      depth: 1,
    },
    {
      _id: 5,
      name: "Affärsområde 1",
      thisMonth: 323878,
      nextMonth: 197892,
      plus2Month: 153455,
      thisYear: 675225,
      nextYear: 1688005,
      currencyCode: "SEK",
      dependencyId: 3,
      depth: 2,
    },
    {
      _id: 6,
      name: "Affärsområde 2",
      thisMonth: 259416,
      nextMonth: 129327,
      plus2Month: 197074,
      thisYear: 585817,
      nextYear: 1773666,
      currencyCode: "SEK",
      dependencyId: 3,
      depth: 2,
    },
    {
      _id: 7,
      name: "Affärsområde 3",
      thisMonth: 126490,
      nextMonth: 264689,
      plus2Month: 336095,
      thisYear: 727274,
      nextYear: 4033140,
      currencyCode: "SEK",
      dependencyId: 3,
      depth: 2,
    },
  ];
