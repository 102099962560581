import { escape } from "src/utils/translate";
import { IncomeWithPermissions } from "src/accurasee-backend-types/app/income/income.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetIncomesQuery } from "src/redux/services/IncomeService";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import getStructure, { GetStructureExtraProps } from "./IncomesStructure";
import getSummaries from "src/utils/getSummaries";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import useFormTable from "src/hooks/useFormTable";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

const Incomes = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);

  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const reportYFilters =
    reportFiltersQuery?.data?.filter(
      (filter) => filter.transactionType === "income",
    ) || [];

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const {
    options,
    data: incomes,
    isLoading: isLoadingIncomes,
  } = useMUIDataTableOptionsServerSide<IncomeWithPermissions>({
    dataName: "incomes",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        sortBy: "transactionDate",
        sort: "desc",
      },
      match: {
        contractId,
      },
    },
    triggerChangeOnFilterInit: ["contractId"],
    useGetDataQuery: useGetIncomesQuery,
    customButton: () => (
      <ExportButton
        buttonType="icon"
        data={() => exportDataRef.current}
        fileName="contract-incomes.xlsx"
        fileType="xlsx"
      >
        <FileDownloadIcon />
      </ExportButton>
    ),
  });

  const { idSummaries, dataWithSummaries: incomeWithSummaries } = getSummaries({
    data: incomes,
    keys: [{ name: "value" }],
  });

  const { structure } = useFormTable<
    Partial<IncomeWithPermissions>,
    GetStructureExtraProps
  >({
    extraProps: {
      currencyCode: companyQuery?.company?.currencyCode || "SEK",
      idSummaries,
      reportYFilters,
    },
    initialFormData: incomes,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: incomeWithSummaries,
      disabled: true,
      item,
    }),
  );

  return (
    <PageWithTopPadding
      label={"Incomes"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Incomes" },
      ]}
      isLoading={
        isLoadingIncomes ||
        isAllReportTemplateYFiltersLoading ||
        isLoadingCompany ||
        isLoadingContract
      }
    >
      <MuiDataTableWrapper
        data={incomeWithSummaries}
        columns={columns}
        options={{
          ...options,
          onTableChange: (action, tableState) => {
            exportDataRef.current = getExportData(tableState);
          },
        }}
        tableType="main-with-filter"
        noBackground={true}
      />
    </PageWithTopPadding>
  );
};

export default Incomes;
