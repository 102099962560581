import {
  HIDDEN_COMPONENT_TYPE,
  HiddenComponentConfig,
} from "src/accurasee-backend-types/app/company_config/company_config.types";

const getHiddenComponent = ({
  name,
  type,
  hiddenComponentConfigs,
}: {
  name: string;
  type: HIDDEN_COMPONENT_TYPE;
  hiddenComponentConfigs: HiddenComponentConfig[];
}) => {
  const dataByType: any = hiddenComponentConfigs?.find(
    (i) => i.hiddenComponentType === type,
  );
  const hiddenComponents = dataByType?.hiddenComponents?.find(
    (hiddenComponent: any) => hiddenComponent.name === name,
  );

  return hiddenComponents;
};
export default getHiddenComponent;
