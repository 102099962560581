import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import {
  CompanyConfig,
  CompanyConfigGet,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import {
  Cpu,
  Memory,
  EphemeralStorage,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import getSelectOptions from "../../../../../utils/getSelectOptions";

export type ItemsTypes = "cronjobs" | "service";

export const getStructure: UseFormContainerGetStructure<CompanyConfigGet> = ({
  setFormData,
}) => {
  const structure: StructureContainer<CompanyConfigGet> = {
    items: [
      {
        itemType: "cronjobs",
        type: "switch",
        dataName: "resources.runIntegrationCronjobs",
        label: "Run Integration Cronjobs",
        setFormData,
      },
      {
        itemType: "service",
        type: "object",
        dataName: "resources.integrationService",
        items: [
          {
            gridProps: { md: 6 },
            type: "object",
            dataName: "resources.integrationService.requests",
            items: [
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName: "resources.integrationService.requests.memory",
                label: "Memory",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(Memory).map((key) => ({
                    label: `${key} - ${Memory[key]}`,
                    value: Memory[key],
                  })),
                }),
              },
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName: "resources.integrationService.requests.cpu",
                label: "CPU",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(Cpu).map((key) => ({
                    label: `${key} - ${Cpu[key]}`,
                    value: Cpu[key],
                  })),
                }),
              },
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName:
                  "resources.integrationService.requests.ephemeral-storage",
                label: "Ephemeral storage",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(EphemeralStorage).map((key) => ({
                    label: `${key} - ${EphemeralStorage[key]}`,
                    value: EphemeralStorage[key],
                  })),
                }),
              },
            ],
          },
          {
            gridProps: { md: 6 },
            type: "object",
            dataName: "resources.integrationService.limits",
            items: [
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName: "resources.integrationService.limits.memory",
                label: "Memory",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(Memory).map((key) => ({
                    label: `${key} - ${Memory[key]}`,
                    value: Memory[key],
                  })),
                }),
              },
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName: "resources.integrationService.limits.cpu",
                label: "CPU",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(Cpu).map((key) => ({
                    label: `${key} - ${Cpu[key]}`,
                    value: Cpu[key],
                  })),
                }),
              },
              {
                gridProps: { md: 4 },
                type: "selector",
                dataName:
                  "resources.integrationService.limits.ephemeral-storage",
                label: "Ephemeral storage",
                setFormData,
                options: getSelectOptions({
                  data: Object.keys(EphemeralStorage).map((key) => ({
                    label: `${key} - ${EphemeralStorage[key]}`,
                    value: EphemeralStorage[key],
                  })),
                }),
              },
            ],
          },
        ],
      },
    ],
  };
  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: CompanyConfigGet;
  initData?: CompanyConfigGet;
}) => {
  if (!data) return;

  const newData = clone(data);

  const partialData = toUpdateData<Partial<CompanyConfig>>({
    data: newData,
    initData,
  });

  return partialData;
};
