import CompanyDetails from "./components/CompanyDetails";
import Page from "src/components/Basic/Mixed/Pages/Page";

function CompanyOverview() {
  return (
    <Page
      label={"General company setup"}
      breadcrumbs={[{ label: "Setup" }, { label: "Company overview" }]}
      isFullWidth={false}
    >
      <CompanyDetails />
    </Page>
  );
}

export default CompanyOverview;
