/**
 *Created by Mikael Lindahl on 2023-12-14
 */
import ConsentDialog from "../../Mixed/Dialogs/ConsentDialog";
import useTranslation from "src/hooks/useTranslationWrapper";
import * as React from "react";
import { useState } from "react";
import { Grid, TextField } from "@mui/material";

type ActivityLogDialogTypes = {
  message: string;
  open: boolean;
  onSave: (e: React.MouseEvent<HTMLButtonElement>, message: string) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const ActivityLogDialog = (props: ActivityLogDialogTypes) => {
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  return (
    <ConsentDialog
      consentButtons={[
        {
          label: t("Cancel"),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            setInputValue(""); // clear input value
            props.onCancel(e);
          },
          variant: "outlined",
        },
        {
          label: t("Save"),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            props.onSave(e, inputValue);
            setInputValue(""); // clear input value
          },

          variant: "contained",
          disabled: inputValue.trim() === "", // disable if no input
        },
      ]}
      open={props.open}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>{t(props.message)}</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={7}
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            sx={{ minWidth: 600 }} // Set minimum width to 600px
          />
        </Grid>
      </Grid>
    </ConsentDialog>
  );
};

export default ActivityLogDialog;
