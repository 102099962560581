import { makeStyles } from "@mui/styles";

const LoginStyles = makeStyles((theme: any) => ({
  container: {
    height: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  logotypeContainer: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("lg")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {},
  },
  form: {
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 3,
    width: "100%",
    backgroundColor: theme.palette.primary.secondary,
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: "64%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "54%",
    },
  },
  tab: {
    fontWeight: 500,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    alignColumnContent: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    alignColumnContent: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  errorMessage: {
    textAlign: "center",
    width: "100%",
  },
  loginButton: {
    height: "60px",
    minWidth: "300px",
    width: "100%",
    fontSize: "16px",
    textTransform: "none",
    padding: "0.8rem 0",
  },
  cursor: {
    color: "white",
  },
  title: {
    color: "var(--black-7)",
    fontSize: "28px",
    fontWeight: "bold",
    textAlign: "center",
  },
  subTitle: {
    color: "var(--gray-5)",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "var(--space-16px)",
    textAlign: "center",
  },
}));

export default LoginStyles;
