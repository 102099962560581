import { UserFormTableGetStructure } from "src/hooks/useFormTable";

import { MailgunLog } from "src/accurasee-backend-types/app/mailgunlog/mailgunlog.types";

export interface GetStructureExtraProps {
  setModalData: (v: MailgunLog) => void;
  setOpenModal: (v: boolean) => void;
}

const getStructure: UserFormTableGetStructure<
  MailgunLog & { description: string },
  GetStructureExtraProps
> = ({ extraProps }) => ({
  items: [
    {
      type: "date",
      dataName: "createdAt",
      headerLabel: "Time",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "description",
      headerLabel: "Description",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "eventId",
      headerLabel: "Event ID",
      sx: { width: "100%" },
    },
    {
      type: "badge",
      dataName: "deliveredStatus",
      headerLabel: "Delivered status",
      sx: { width: "100%" },
      alignColumnContent: "center",
    },
    {
      type: "icon_button",
      iconType: "view",
      dataName: "edit",
      onClick: ({ rowIndex, data }) => {
        extraProps?.setOpenModal(true);
        extraProps?.setModalData(data[rowIndex]);
      },
    },
  ],
});

export default getStructure;
