import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getFinalFormatedValue = (
  props: CommonCallbackProps & { value: string | number },
) => {
  return typeof props.item.getFinalFormatedValue === "string"
    ? props.item.getFinalFormatedValue
    : typeof props.item.getFinalFormatedValue === "function"
      ? props.item.getFinalFormatedValue(props)
      : props.value === "undefined"
        ? "-"
        : props.value;
};

export default getFinalFormatedValue;
