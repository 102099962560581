/**
 *Created by Mikael Lindahl on 2023-03-15
 */

import {
  PlannedInvoiceGetList,
  PlannedInvoiceRow,
} from "../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import {
  useCreatePlannedInvoicesFromInvoicePlanMutation,
  useExportPlannedInvoiceMutation,
  useMergeAndApprovePlannedInvoicesMutation,
  usePatchPlannedInvoiceMutation,
} from "../redux/services/PlannedinvoiceService";
import { CO_INVOICE_ALLOWED_SAME_VALUES } from "src/accurasee-backend-types/shared/constants/general";
import { SerializedError } from "@reduxjs/toolkit";
import { Types } from "mongoose";
import { updateIndexRows } from "../utils/updateIndexRows";
import { useSnackbar } from "notistack";
import { useState } from "react";
import parseRestApiErrorMessage from "../utils/parseRestApiErrorMessage";
import updateRowTotals from "../utils/updateRowTotals";
import useTranslation, { TFunction } from "src/hooks/useTranslationWrapper";

type UseInvoiceStateChange = {
  selectedPlannedInvoiceIds?: Types.ObjectId[];
  selectedRows?: PlannedInvoiceGetList[];
};

type MutationResponse =
  | { data: any }
  | { error: { status: any; data: any } | SerializedError };

/**
 * The `useInvoiceStateChange` hook provides functionality to change invoice state on
 * invoice rows on invoice plan, planned invoice rows and/or customer invoice rows.
 * Can be used to create or save planned invoice. If updating already existing planned invoice
 * then only save operation can be performed. This steps can then be followed by
 * the approve-merge and export steps for changing state for invoices.
 *
 * @param {Object} props - The properties for the hook.
 * @param {Types.ObjectId[]} [props.selectedPlannedInvoiceIds] - An optional array of selected planned invoice IDs.
 *
 * @returns {Object} - An object containing the loading state and the `onInvoiceStateChange` function.
 * @returns {boolean} isInvoiceStateChangeLoading - Indicates if the export invoice process is currently loading.
 * @returns {Function} onInvoiceStateChange - A function to export invoices.
 *
 * @throws {Error} - Throws an error if any step in the process fails.
 */
const useInvoiceStateChange = (props: UseInvoiceStateChange) => {
  const selectedRows = props?.selectedRows || [];
  const selectedPlannedInvoiceIds =
    props?.selectedPlannedInvoiceIds ||
    selectedRows
      ?.map((row) => row._id)
      ?.filter((id): id is Types.ObjectId => Boolean(id));

  let _selectedPlannedInvoiceIds: Types.ObjectId[] = [
    ...(selectedPlannedInvoiceIds || []),
  ];

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createPlannedInvoicesFromInvoicePlan] =
    useCreatePlannedInvoicesFromInvoicePlanMutation();
  const [patchPlannedInvoice] = usePatchPlannedInvoiceMutation();
  const [exportPlannedInvoice] = useExportPlannedInvoiceMutation();
  const [mergeAndApprovePlannedInvoice] =
    useMergeAndApprovePlannedInvoicesMutation();

  const [isLoading, setIsLoading] = useState(false);

  const checkForErrorInResponse = ({
    errors,
    response,
    triggerThrow = true,
    step,
    t,
  }: {
    errors: any[];
    response: MutationResponse;
    triggerThrow?: boolean;
    step: "create" | "patch" | "export" | "merge-and-approve";
    t: TFunction;
  }) => {
    if ("error" in response) {
      const msg = parseRestApiErrorMessage(response.error);

      errors.push(new Error(`${t("Failed with")} ${t(step)}: ${msg}`));

      if (triggerThrow) {
        throw new Error("response failed");
      }
    }
  };

  // Those functions are to make a selected row become a Planned invoice row
  const onInvoiceStateChange = async (propsOnInvoiceStateChange?: {
    invoicePlanId?: Types.ObjectId;
    plannedInvoice?: { _id?: Types.ObjectId; invoiceRows: PlannedInvoiceRow[] };
    onlySave?: boolean;
    onPostMerge?: () => void;
  }) => {
    setIsLoading(true);
    const onPostMerge = propsOnInvoiceStateChange?.onPostMerge;

    updateIndexRows(
      propsOnInvoiceStateChange?.plannedInvoice?.invoiceRows || [],
    );

    // Update all rows, since also index rows can have changed
    updateRowTotals(
      propsOnInvoiceStateChange?.plannedInvoice?.invoiceRows || [],
    );

    const errors: any[] = [];

    try {
      if (propsOnInvoiceStateChange?.invoicePlanId) {
        const responseCreatePlannedInvoicesFromInvoicePlan =
          await createPlannedInvoicesFromInvoicePlan({
            id: propsOnInvoiceStateChange?.invoicePlanId,
          });

        checkForErrorInResponse({
          errors,
          response: responseCreatePlannedInvoicesFromInvoicePlan,
          step: "create",
          t,
        });

        if ("data" in responseCreatePlannedInvoicesFromInvoicePlan) {
          _selectedPlannedInvoiceIds =
            responseCreatePlannedInvoicesFromInvoicePlan?.data.data
              .map((d) => d._id)
              .filter((id): id is Types.ObjectId => Boolean(id));
        }

        enqueueSnackbar(t("Created Planned invoices"), {
          variant: "info",
        });
      } else if (propsOnInvoiceStateChange?.plannedInvoice) {
        const { _id, invoiceRows } = propsOnInvoiceStateChange.plannedInvoice;
        const responsePathPlannedInvoice = await patchPlannedInvoice({
          id: _id,
          invoiceRows,
        });

        checkForErrorInResponse({
          errors,
          response: responsePathPlannedInvoice,
          step: "patch",
          t,
        });

        if (propsOnInvoiceStateChange.onlySave) {
          setIsLoading(false);
          return enqueueSnackbar(t("Saved changes to invoice rows"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("Saved changes to invoice rows"), {
            variant: "info",
          });
        }
      }

      let plannedInvoiceId: any;
      if (_selectedPlannedInvoiceIds.length === 0) {
        console.error("No rows selected for export");
        setIsLoading(false);
        return enqueueSnackbar(t("No rows selected for export"), {
          variant: "info",
        });
      } else {
        const responseMergedAndApprovePlannedInvoice =
          await mergeAndApprovePlannedInvoice({
            plannedInvoiceIds: _selectedPlannedInvoiceIds,
          });

        checkForErrorInResponse({
          errors,
          response: responseMergedAndApprovePlannedInvoice,
          step: "merge-and-approve",
          t,
        });

        if ("data" in responseMergedAndApprovePlannedInvoice) {
          plannedInvoiceId =
            responseMergedAndApprovePlannedInvoice?.data.data._id;
        }

        enqueueSnackbar(t("Invoice approved successfully"), {
          variant: "info",
        });
      }

      const responseExportPlannedInvoice =
        await exportPlannedInvoice(plannedInvoiceId);

      checkForErrorInResponse({
        errors,
        response: responseExportPlannedInvoice,
        triggerThrow: false,
        step: "export",
        t,
      });

      if (errors.length > 0) {
        throw new Error("response failed");
      }

      if (onPostMerge) {
        onPostMerge();
      }

      enqueueSnackbar(t("Invoice exported successfully"), {
        variant: "success",
      });

      return setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);

      for (const error of errors) {
        console.error(error);
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      }
      if (error.message !== "response failed") {
        enqueueSnackbar(t("Could not approve invoice"), {
          variant: "error",
        });
      }
    }
  };

  // Check if all rows has the same and note in messages

  let messages = "";
  if (selectedRows.length > 0) {
    const first = selectedRows[0];
    const notSameCustomers = selectedRows.some(
      (row) => row?.customerId !== first?.customerId,
    );

    const notSameRemarks = selectedRows.some((row) => {
      const bothInAllowedValues =
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.remarks) &&
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.remarks);
      return !bothInAllowedValues && row.remarks !== first.remarks;
    });

    const notSameOrderNumber = selectedRows.some((row) => {
      const bothInAllowedValues =
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.yourOrderNumber) &&
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.yourOrderNumber);
      return (
        !bothInAllowedValues && row.yourOrderNumber !== first.yourOrderNumber
      );
    });

    const notSameYourReference = selectedRows.some((row) => {
      const bothInAllowedValues =
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.yourReference) &&
        CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.yourReference);
      return !bothInAllowedValues && row.yourReference !== first.yourReference;
    });

    if (
      notSameCustomers ||
      notSameRemarks ||
      notSameOrderNumber ||
      notSameYourReference
    ) {
      messages = `${t("To co-invoice invoices they must have the same")} `;
    }

    if (notSameCustomers) {
      messages += `${t("customer")}, `;
    }
    if (notSameRemarks) {
      messages += `${t("remarks")}, `;
    }

    if (notSameOrderNumber) {
      messages += `${t("order number")}, `;
    }
    if (notSameYourReference) {
      messages += `${t("your reference")}, `;
    }
  }

  return {
    isInvoiceStateChangeLoading: isLoading,
    messages,
    onInvoiceStateChange,
  };
};

export default useInvoiceStateChange;
