import DOMPurify from "dompurify";
import { Box, Skeleton } from "@mui/material";
const EditorView = ({
  html,
  isError,
  isLoading,
}: {
  html: string;
  isError?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton animation="wave" height={10} width="40%" />
        </>
      ) : (
        <Box
          sx={{
            minHeight: "50px",
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(html || ""),
          }}
        />
      )}
    </>
  );
};

export default EditorView;
