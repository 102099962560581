/**
 *Created by Mikael Lindahl on 2023-12-08
 */

const parseNumberInput = (value: any) => {
  if (value === "") return 0;

  return isNaN(value)
    ? value
    : value[0] === "0"
      ? value
      : value[value.length - 1] === "."
        ? value
        : Number(value);
};

export default parseNumberInput;
