/**
 *Created by Mikael Lindahl on 2023-04-03
 */

import { diff } from "deep-object-diff";
import clone from "./clone";

const getChangedData = <DataType extends {} = any>({
  data,
  initData,
}: {
  data: DataType;
  initData?: DataType;
}) => {
  let partialData: Partial<DataType> = clone(data);

  if (initData) {
    let updated: Partial<DataType> = diff(data, initData);
    let added: Partial<DataType> = diff(initData, data);

    for (let key in partialData) {
      if (updated[key] === undefined && added[key] === undefined) {
        delete partialData[key];
      }
    }
  }
  return partialData;
};

export default getChangedData;
