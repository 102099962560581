/**
 *Created by Mikael Lindahl on 2023-03-08
 */
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { InvoicePlanRowExtended } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { InvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import cloneDeep from "lodash/cloneDeep";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";

export type GetStructureExtraProps = {
  invoicePlan: InvoicePlan | undefined;
  setOffsetRowIndex: (index: number) => void;
  setOpenModal: (b: boolean) => void;
};

export const getStructure: UserFormTableGetStructure<
  InvoicePlanRowExtended,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const isMilestone = extraProps?.invoicePlan?.type === "milestone";

  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
      },
      {
        type: "text",
        dataName: "itemDescription",
        headerLabel: isMilestone ? "Milestone" : "Description",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        setFormData,
      },
      {
        type: "percent",
        dataName: "discount",
        showColumnWhen: () => !isMilestone,
        headerLabel: "Discount",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
      },
      {
        type: "switch",
        disabled: true,
        dataName: "isPeriodic",
        showColumnWhen: () => !isMilestone,
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        cellLabel: "Periodic",
      },
      {
        type: "text_with_edit_button",
        dataName: "offset",
        validate: (props) => {
          return props.data[props.rowIndex].isPeriodic
            ? props.data[props.rowIndex].offset?.unit !== undefined &&
                props.data[props.rowIndex].offset?.number !== undefined
            : true;
        },
        getErrorText: (props) => "Required",
        getValue: ({ data, rowIndex }) =>
          data[rowIndex]?.offset?.unit === undefined ||
          data[rowIndex]?.offset?.number === undefined
            ? "Missing data"
            : `${data[rowIndex]?.offset?.unit} ${data[rowIndex]?.offset?.number}`,
        headerLabel: "Offset",
        onClick: ({ rowIndex }) => {
          extraProps?.setOffsetRowIndex(rowIndex);
          extraProps?.setOpenModal(true);
        },
        showColumnWhen: ({ data }) =>
          !isMilestone && data.some((d) => d.isPeriodic),
        showCellWhen: ({ data, rowIndex }) => {
          return (
            Boolean(data[rowIndex].isPeriodic) &&
            Boolean(data[rowIndex].articleId)
          );
        },
      },
    ],
  };
};

export const toSubmitData = ({ data }: { data?: InvoicePlanRowExtended[] }) => {
  let submitData = cloneDeep(data);

  submitData = submitData?.map((d, index) => {
    delete d.rowNumber;
    d.status = d.status || "upcoming";
    for (let key in d) {
      if (d[key] === undefined) {
        delete d[key];
      }
    }
    return d;
  });
  return submitData as InvoicePlanRow[];
};

export const toData = (data: InvoicePlanRowExtended[]) =>
  data?.map((d, index) => ({
    ...d,
    rowNumber: index + 1,
    // status: d.status || "upcoming",
  }));
