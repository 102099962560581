/**
 *Created by Mikael Lindahl on 2024-04-16
 */

import { TFunction } from "src/hooks/useTranslationWrapper";
import moment from "moment-timezone";

const getFormattedDate = ({
  t,
  date,
}: {
  t: TFunction;
  date?: Date;
}): string => {
  if (!date) {
    return "Missing date";
  }

  return `${t(moment(date).format("MMMM"))} ${moment(date).format("D")}${t(
    "th",
  )} ${moment(date).format("YYYY")} ${t("at")}
  ${moment(date).format("HH:mm:ss")}`;
};

export default getFormattedDate;
