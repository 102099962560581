const findDuplicates = (arr: string[]): string[] => {
  const seen = new Set<string>();
  const duplicates = new Set<string>();

  arr.forEach((element) => {
    if (seen.has(element)) {
      duplicates.add(element);
    } else {
      seen.add(element);
    }
  });

  return Array.from(duplicates);
};

export const helperDuplicatedElement = (data: string[]) => {
  return findDuplicates(data);
};
