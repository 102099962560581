/**
 *Created by Mikael Lindahl on 2023-01-25
 */

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useStyles from "./SectionWithButtonsStyles";

type ButtonSectionTProps = {
  alignCenter?: boolean;
  children?: JSX.Element | JSX.Element[];
  label?: string;
  md?: number;
  lg?: number;
};

const SectionWithButtons = ({
  alignCenter,
  children,
  label,
  ...gridProps
}: ButtonSectionTProps) => {
  const classes = useStyles();

  const childrenLength = Array.isArray(children) ? children.length : 1;

  return (
    <Grid item {...gridProps}>
      <Box className={classes.boxSection}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: alignCenter
              ? "center"
              : childrenLength === 1
                ? "end"
                : "space-between",
            flexDirection: "row",
          }}
        >
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export default SectionWithButtons;
