import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./ContactPersonContainerStructure";
import { Types } from "mongoose";
import {
  useCreateContactPersonMutation,
  useUpdateContactPersonMutation,
} from "src/redux/services/ContactPersonService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";

type ContactPersonContainerTProps = {
  customerId?: Types.ObjectId | string;
  data?: ContactPerson;
  setOpenModal?: (v: boolean) => void;
  postCreate?: (data: Partial<ContactPerson>) => void;
  rerouteUrlOnSuccess?: { create?: () => string };
};

export const ContactPersonContainer = ({
  customerId,
  data,
  setOpenModal,
  postCreate,
  rerouteUrlOnSuccess,
}: ContactPersonContainerTProps) => {
  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data || [];

  const initialFormData =
    data ??
    ({
      ...(customerId ? { customerId } : {}),
    } as ContactPerson);

  const [createContactPerson] = useCreateContactPersonMutation();
  const [updateContactPerson] = useUpdateContactPersonMutation();
  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<ContactPerson>, GetStructureExtraProps>({
    getStructure,
    extraProps: { customers, customerId },
    initialFormData,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
  });
  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "Contact person",
      data: { create: submitData, update: submitData },
      dataId: String(data?._id) || "",
      apiMutations: {
        create: createContactPerson,
        update: updateContactPerson,
      },
      ...rerouteUrlOnSuccess,
    },
    onSuccess: ({ responseData }) => {
      setHasTriedToSubmit(false);
      setFormData(initialFormData);

      if (setOpenModal) setOpenModal(false);
      if (postCreate && responseData?.create) {
        const resData = responseData?.create;
        postCreate(resData);
      }
    },
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit({ action: data ? "update" : "create" });
      }}
      ref={refForm}
    >
      <SectionContainer isLoading={isLoadingCustomers}>
        <Section>
          {BuildItems({ items: structure.items, ...commonPropsBuildItem })}
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer buttonCenter>
        <ButtonGroup>
          {!setOpenModal ? (
            <ButtonClear onClick={() => setFormData(initialFormData)} />
          ) : (
            <ButtonCancel
              onClick={() => {
                setFormData(initialFormData);
                if (setOpenModal) setOpenModal(false);
              }}
            />
          )}
          <ButtonCreateOrUpdate
            initialSubmitData={{}}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            label={data ? "update" : "create"}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={formData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </form>
  );
};
export default ContactPersonContainer;
