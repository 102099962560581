import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import Page from "src/components/Basic/Mixed/Pages/Page";
import InsightReportsList from "./components/ReportsList";

const InsightReportsScreen = () => {
  const { data: dataQuery, isLoading } = useGetReportTemplatesQuery(undefined);
  const reportTemplates = dataQuery?.data || [];

  return (
    <Page
      label="Reports"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Reports" }]}
      isLoading={isLoading}
    >
      <InsightReportsList reportTemplates={reportTemplates} />
    </Page>
  );
};

export default InsightReportsScreen;
