import { CompanyConfig } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { helperDuplicatedElement } from "src/utils/helpersBuilderTableStructure/helperDuplicatedElement";
import { MicrosoftDriveConfig } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getValue from "src/components/Builders/Container/Utils/getValue";
import getSelectOptions from "../../../../utils/getSelectOptions";

export type ItemsTypes = "general" | "type_microsoft";

export const getStructure: UseFormContainerGetStructure<
  CompanyConfig["drive"]
> = ({ setFormData }) => {
  const structure: StructureContainer<CompanyConfig["drive"]> = {
    items: [
      {
        itemType: "general",
        type: "selector",
        dataName: "type",
        label: "Bucket or Microsoft",
        options: getSelectOptions({
          data: [
            { label: "Bucket", value: "bucket" },
            { label: "Microsoft", value: "microsoft" },
          ],
        }),
        setFormDataCustom: (props) => {
          const newData = {
            type: props.value,
            config:
              props.value === "bucket"
                ? null
                : (props.data?.config as MicrosoftDriveConfig),
          };

          setFormData(newData);
        },
      },
      {
        itemType: "type_microsoft",
        type: "object",
        showWhen: (props) => props.data?.type === "microsoft",
        items: [
          {
            type: "text_input",
            dataName: "config.storageSaveLocation",
            label: "Save location",
            setFormData,
          },
          {
            type: "text_input",
            required: true,
            dataName: "config.storageSharedSaveLocationUrl",
            label: "Shared save location Url",
            setFormData,
          },
          {
            type: "text_input",
            dataName: "config.storageSharedTemplateUrl",
            label: "Shared template Url",
            setFormData,
          },
          {
            type: "text_input",
            dataName: "config.storageTemplateLocation",
            label: "Template location",
            gridProps: { md: 6 },
            setFormData,
          },
          {
            type: "text_input",
            dataName: "config.storageType",
            label: "Type",
            gridProps: { md: 6 },
            setFormData,
          },
          {
            type: "text_input",
            dataName: "config.storageUrl",
            label: "Url",
            setFormData,
          },
          {
            type: "text_input",
            dataName: "config.groups",
            label: "Groups (comma separated)",
            gridProps: { md: 6 },
            validate: (props) => {
              const value = getValue(props);
              if (value) {
                return helperDuplicatedElement(value).length <= 0;
              } else {
                return true;
              }
            },
            getErrorText: (props) => {
              const value = getValue(props);
              const duplicates = helperDuplicatedElement(value);
              return `Duplicated elements found: ${duplicates?.join(", ")}`;
            },
            setFormDataCustom: (props) => {
              const newData = {
                type: props.data?.type,
                config: {
                  ...props.data?.config,
                  groups: props.value
                    ?.split(",")
                    .map((item: string) => item.trim()),
                },
              };
              setFormData(newData as CompanyConfig["drive"]);
            },
          },
          {
            itemType: "info",
            type: "text_input",
            dataName: "config.types",
            label: "Types (comma separated)",
            gridProps: { md: 6 },
            validate: (props) => {
              const value = getValue(props);
              if (value) {
                return helperDuplicatedElement(value).length <= 0;
              } else {
                return true;
              }
            },
            getErrorText: (props) => {
              const value = getValue(props);
              const duplicates = helperDuplicatedElement(value);
              return `Duplicated elements found: ${duplicates?.join(", ")}`;
            },
            setFormDataCustom: (props) => {
              const newData = {
                ...props.data,
                config: {
                  ...props.data?.config,
                  types: props.value
                    ?.split(",")
                    .map((item: string) => item.trim()),
                },
              };
              setFormData(newData as CompanyConfig["drive"]);
            },
          },
        ],
      },
    ],
  };
  return structure;
};

export const toSubmitData = (data?: CompanyConfig["drive"]) => {
  if (!data) return;
  const { config, type } = data;

  return { config, type };
};
