import { MUIDataTableColumnDef } from "mui-datatables";
import { LinearProgress } from "@mui/material";

import {
  DimensionExtra,
  useGetDimensionsQuery,
} from "src/redux/services/DimensionService";

import useFormTable from "src/hooks/useFormTable";

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";

import getStructure from "./DimensionsStructure";
import DimensionRowsSubtable from "./DimensionRowsSubtable";
import SectionTight from "../../../../components/Basic/Mixed/Sections/SectionTight";
import useMUIDataTableOptionsServerSide from "../../../../hooks/useMUIDataTableOptionsServerSide";

const Dimensions = () => {
  const {
    options,
    data: dimensions,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide({
    filterInit: {
      base: { sortBy: "number", sort: "desc" },
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const dimensionRows = dimensions[rowMeta.dataIndex].items;
      return <DimensionRowsSubtable dimensionRows={dimensionRows} />;
    },
    useGetDataQuery: useGetDimensionsQuery,
  });

  const { structure } = useFormTable<DimensionExtra>({
    initialFormData: dimensions,
    getStructure,
  });
  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<DimensionExtra>({
      data: dimensions,
      item,
    }),
  );

  return (
    <Page
      label={"Dimensions"}
      breadcrumbs={[{ label: "Setup" }, { label: "Dimensions" }]}
      isFullWidth={false}
      isLoading={isLoading}
    >
      <SectionTight key={"dimensions-table"} containsTable md={12} lg={12}>
        <div style={{ height: 2 }}>{isFetching && <LinearProgress />}</div>
        <MuiDataTableWrapper
          data={dimensions || []}
          columns={columns}
          isFetching={isFetching}
          options={options}
          title=""
          tableType="compact"
        />
      </SectionTight>
    </Page>
  );
};

export default Dimensions;
