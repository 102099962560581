import { StructureItemTable } from "../../components/Builders/Table/CommonBuilderTableTypes";
import getSetFormDataForInvoiceRows from "../getSetFormDataForInvoiceRows";

/**
 *Created by Mikael Lindahl on 2023-04-19
 */

export const validatePricePerUnit: StructureItemTable["validate"] = ({
  data,
  rowIndex,
}) => {
  const { isPricePerUnitEstimate, pricePerUnit } = data[rowIndex];
  return !(pricePerUnit === 0 && !isPricePerUnitEstimate);
};

export const getErrorTextPricePerUnit =
  "Can not be zero (only allowed for estimate)";

export const setFormDataCustomPricePerUnit = <DataType = any>({
  setFormData,
}: {
  setFormData: (data: DataType[]) => void;
}) => {
  const setFormDataCustom = getSetFormDataForInvoiceRows({
    setFormData,
    key: "pricePerUnit",
  });
  return setFormDataCustom;
};
