/**
 *Created by Mikael Lindahl on 2023-01-25
 */

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { SxProps } from "@mui/material";
import BoxHeading from "../Headings/BoxHeading";
import useStyles from "./SectionCoreStyles";
import React, { useEffect, useRef, useState } from "react";

export type SectionCoreTProps = {
  alignTextCenter?: boolean;
  boxStyle?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string;
  boxHeadingStyle?: React.CSSProperties;
  id?: string;
  isLoading?: boolean;
  label?: string;
  lg?: number;
  md?: number;
  rightBoxArea?: JSX.Element | JSX.Element[];
  rightBoxAreaSx?: SxProps;
  style?: React.CSSProperties;
  subLabel?: string;
  transparent?: boolean;
  innerBorderColor?: string;
  withTightTableHeading?: boolean;
  xs?: number;
};

const SectionCore = ({
  alignTextCenter,
  boxStyle,
  boxHeadingStyle,
  children,
  isLoading,
  label,
  rightBoxArea,
  rightBoxAreaSx,
  subLabel,
  transparent,
  innerBorderColor,
  withTightTableHeading,
  ...gridProps
}: SectionCoreTProps) => {
  const classes = useStyles();

  const boxHeadingRef = useRef<HTMLDivElement>(null);

  const [spacer, setSpacer] = useState(0);

  useEffect(() => {
    if (boxHeadingRef.current && boxHeadingRef.current.offsetHeight > 80) {
      const height = boxHeadingRef.current.offsetHeight;

      // Set the padding based on the height
      // You can adjust the calculation as per your requirement
      setSpacer(height - 70);
    }
  }, []);

  boxStyle = {
    ...(boxStyle || {}),
    ...(transparent ? { backgroundColor: "transparent" } : {}),
  };

  const sxTransparent = {
    ...(transparent ? { backgroundColor: "transparent" } : {}),
  };

  return (
    <Grid item {...{ xs: 12, ...gridProps }}>
      <Box className={classes.boxSection} style={boxStyle}>
        {!isLoading && (
          <BoxHeading
            ref={boxHeadingRef}
            alignTextCenter={alignTextCenter}
            label={label}
            rightBoxArea={rightBoxArea}
            rightBoxAreaSx={rightBoxAreaSx}
            subLabel={subLabel}
            withTightTableHeading={withTightTableHeading}
            sx={boxHeadingStyle || {}}
          />
        )}
        <Box sx={{ height: spacer }}></Box>
        {innerBorderColor ? (
          <Box
            sx={{
              border: `2px solid ${innerBorderColor}`,
              borderRadius: "8px",
              padding: "var(--space-24px) 0 var(--space-32px) 0",
            }}
          >
            <Grid container rowSpacing={3} columnSpacing={1} sx={sxTransparent}>
              {isLoading ? (
                <Box
                  width={"100%"}
                  sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
                >
                  <LinearProgress />
                </Box>
              ) : (
                children
              )}
            </Grid>
          </Box>
        ) : (
          <Grid container rowSpacing={3} columnSpacing={1} sx={sxTransparent}>
            {isLoading ? (
              <Box
                width={"100%"}
                sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
              >
                <LinearProgress />
              </Box>
            ) : (
              children
            )}
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default SectionCore;
