/**
 *Created by Mikael Lindahl on 2023-12-14
 */
import ConsentDialog from "../../Mixed/Dialogs/ConsentDialog";
import useTranslation from "src/hooks/useTranslationWrapper";
import * as React from "react";

type YesNoDialogTProps = {
  children: React.ReactNode;
  open: boolean;
  onNo: () => void;
  onYes: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const YesNoDialog = (props: YesNoDialogTProps) => {
  const [t] = useTranslation();

  return (
    <ConsentDialog
      consentButtons={[
        {
          label: t("No"),
          onClick: props.onNo,
          variant: "contained",
        },
        {
          label: t("Yes"),
          onClick: props.onYes,
          variant: "outlined",
        },
      ]}
      open={props.open}
    >
      {props.children}
    </ConsentDialog>
  );
};

export default YesNoDialog;
