/**
 *Created by Mikael Lindahl on 2023-11-27
 */

import { useContext } from "react";
import { RoutePath } from "../accurasee-backend-types/app/general/routes.types";
import { AccessPermissionContext } from "../context/AccessPermissionProvider";

const usePathPermission = (path: RoutePath) => {
  const { pathPermissions } = useContext(AccessPermissionContext);
  return pathPermissions[path];
};

export default usePathPermission;
