import { Divider, Grid, InputLabel } from "@mui/material";
import { setNestedObjectData } from "src/utils/nestedData";
import { StructureItemContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { Fragment, useEffect, useState } from "react";
import BuildItemInternal, { BuildItem } from "../BuildItemInternal";
import clone from "src/utils/clone";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import SwitchWrapper from "src/components/Basic/Simple/Switches/SwitchWrapper";
import useStyles from "../BuildItemsStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

type SwitchWithRightObjectItemTProps = BuildItem;

// This is to check value of the main switch
const checkStatus = ({
  array,
  data,
}: {
  array?: StructureItemContainer[];
  data: StructureItemContainer;
}) => {
  if (array?.length === 0) {
    return false;
  }
  // The main does no change from off only the child
  // as long as there is other children that still are off
  // If all are on the the main switch also is set to on
  const allFalse = array?.some((i) => !getValue({ data, item: i }));

  return !allFalse;
};

const SwitchWithRightObjectItem = (props: SwitchWithRightObjectItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  const [mainSwitch, setMainSwitch] = useState<true | false>();

  useEffect(() => {
    setMainSwitch(checkStatus({ array: props.item.items, data: props.data }));
  }, [props.data]);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  return getShowWhen(commonCallbackProps) ? (
    <Grid container item {...props.item.gridProps} alignItems="start">
      {props.item.label && (
        <>
          <Grid key="label" item xs={4}>
            <InputLabel
              required={getRequired(commonCallbackProps)}
              className={classes.labelFieldBySideStyle}
            >
              {t(props.item.label)}
            </InputLabel>
          </Grid>
          <Grid key="switch" item xs={2}>
            <SwitchWrapper
              checked={checkStatus({
                array: props.item.items,
                data: props.data,
              })}
              name={props.item.dataName}
              onClick={(event) => {
                event.preventDefault();

                let newData = clone(props.data);
                props.item.items?.forEach((sub) => {
                  setNestedObjectData({
                    data: newData,
                    key: sub.dataName as string,
                    value: !mainSwitch,
                  });
                  if (sub.setFormData) {
                    sub.setFormData(newData);
                  }
                });
                setMainSwitch(!mainSwitch);
              }}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        key="content"
        alignItems="start"
        xs={props.item.label ? 6 : 12}
      >
        <Grid
          container
          alignItems={"start"}
          columnSpacing={1}
          rowSpacing={props.item.label ? 1 : 4}
        >
          {props.item.items?.map((subItem: any, index: number) => {
            // Main has one child and they have the same label
            if (
              props.item.items?.length === 1 &&
              props.item.label === subItem.label
            )
              return <Fragment key={`sub-${props.index}-${index}`}></Fragment>;

            return (
              <Fragment key={`sub-${props.index}-${index}`}>
                <BuildItemInternal
                  {...props}
                  index={`${props.index}-${index}`}
                  item={subItem}
                />
                {!!props.item.dataName &&
                props.item.items?.length &&
                index + 1 < props.item.items?.length ? (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default SwitchWithRightObjectItem;
