import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { MUIDataTableColumnDef } from "mui-datatables";
import { MicrosoftTemplateTagItem } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { getStructure } from "./MicrosoftDocTemplateTagTableGetStructure";

type IndexTableTProps = { data: MicrosoftTemplateTagItem[] };

export function MicrosoftDocTemplateTagTable({ data }: IndexTableTProps) {
  const structure = getStructure();

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<MicrosoftTemplateTagItem>({
      data,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 10,
  });

  return (
    <MuiDataTableWrapper
      nameSpace={"main-table"}
      title={""}
      data={data}
      columns={columns}
      options={options}
      tableType={"compact"}
    />
  );
}

export default MicrosoftDocTemplateTagTable;
