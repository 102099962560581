/**
 *Created by Mikael Lindahl on 2024-04-07
 */

import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

type MemoryUsage = {
  heapTotal: number;
  heapUsed: number;
  recordedAtLine: string;
  stack: string;
  stepIndex: number;
};

type MemoryUsageChartProps = {
  data?: MemoryUsage[];
};

const MemoryUsageChart: React.FC<MemoryUsageChartProps> = ({ data }) => {
  if (!data) return <></>;

  const chartData = {
    labels: data.map((_, index) => index + 1),
    datasets: [
      {
        label: "Heap Used",
        data: data.map((item) => item.heapUsed),
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        pointRadius: 5, // Set the point radius to a larger value
      },
      {
        label: "Heap Total",
        data: data.map((item) => item.heapTotal),
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        pointRadius: 5, // Set the point radius to a larger value
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Add this line
    scales: {
      x: {
        type: "category" as const, // or 'linear', 'time', etc.
        // other options...
      },
      y: {
        type: "linear" as const, // or 'logarithmic', etc.
        ticks: {
          // Add this callback function to modify the y-axis labels
          callback: function (value: any) {
            return `${value} MB`;
          },
        },
        // other options...
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = chartData.datasets[context.datasetIndex].label;
            const memoryUsageItem = data[context.dataIndex];
            return [
              `${label}: ${context.parsed.y} MB`,
              `Recorded At Line: ${memoryUsageItem.recordedAtLine}`,
              `Step Index: ${memoryUsageItem.stepIndex}`,
              `Stack:`,
              ...memoryUsageItem.stack
                .split("\n")
                .map((line: string) => `  ${line}`),
            ];
          },
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default MemoryUsageChart;
