/**
 *Created by Mikael Lindahl on 2024-10-14
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    dropzoneBox: {
      "& .MuiDropzoneArea-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "300px",
        height: "80px",
        minHeight: "unset",
        backgroundColor: theme.palette.background.light,
      },
      "& .MuiTypography-subtitle1": {
        display: "none",
      },
      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& p": {
        fontSize: "14px",
      },
      "& svg": {
        height: "24px",
        weight: "24px",
      },
    },
  }),
);

export default useStyles;
