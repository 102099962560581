/**
 * This function takes a string as an argument
 * and returns true if the string starts with a number and false otherwise.
 *
 * @param str
 * @returns boolean
 */

const startsWithNumber = (str: string) => {
  return /^\d/.test(str);
};

export default startsWithNumber;
