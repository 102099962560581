import BuildItems from "src/components/Builders/Container/BuildItems";
import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import {
  HelperText,
  StructureContainer,
} from "src/components/Builders/Container/CommonBuilderContainerTypes";

const InsightReportFilter = ({
  structure,
  formData,
  helperText,
}: {
  structure: StructureContainer<Partial<ReportGetRequestQuery>, string>;
  formData?: Partial<ReportGetRequestQuery>;
  helperText: HelperText;
}) => {
  const commonPropsBuildItem = { data: formData, helperText };

  return (
    <>
      {BuildItems({
        items: structure.items,
        ...commonPropsBuildItem,
      })}
    </>
  );
};

export default InsightReportFilter;
