/**
 *Created by Mikael Lindahl on 2023-06-22
 */
import _c from "../constants/Constants";

export function decodeStringWithEscape(str: string) {
  const hasDelimiter = str.includes(_c.TRANSLATION_ESCAPE_START);
  if (!hasDelimiter) {
    return [{ text: str, isEscape: false }];
  }

  const startsWithDelimiter = str.startsWith(_c.TRANSLATION_ESCAPE_START);

  return str.split(_c.TRANSLATION_ESCAPE_START).reduce(
    (tot, part, i) => {
      part.split(_c.TRANSLATION_ESCAPE_END).reduce((totSubpart, subpart, j) => {
        if (subpart === "") {
          return totSubpart;
        } else if (!startsWithDelimiter && i === 0) {
          totSubpart.push({ text: subpart, isEscape: false });
        } else if (j % 2 === 1) {
          // For every first subpart, apply the function
          totSubpart.push({ text: subpart, isEscape: false });
        } else {
          totSubpart.push({ text: subpart, isEscape: true });
        }
        return totSubpart;
      }, tot);
      return tot;
    },
    [] as { text: string; isEscape: boolean }[],
  );
}

export function escape(text: string) {
  return `${_c.TRANSLATION_ESCAPE_START}${text}${_c.TRANSLATION_ESCAPE_END}`;
}

export function escapeDotsAndDollars(str: string) {
  str = str.replace(/[.]/g, "\\dot");
  return str.replace(/[$]/g, "\\dollar");
}
