import useTranslation from "src/hooks/useTranslationWrapper";
import { type MUIDataTableColumnDef } from "mui-datatables";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import BuilderColumnOptionsBase from "src/components/Builders/Table/BuilderColumnOptionsBase";
import useFormTable from "src/hooks/useFormTable";
import { userMUIDataSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import { getStructure } from "./ReportTableStructure";
import {
  ReportGetRequestQuery,
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import CircularProgress from "@mui/material/CircularProgress";
import { ReportContext } from "./ReportsList";
import { useContext } from "react";

const InsightReportSubTableContainer = ({
  filter,
  reportTemplateYFilterId,
  colSpan,
}: {
  filter: Partial<ReportGetRequestQuery>;
  reportTemplateYFilterId?: string;
  colSpan: number;
}) => {
  const { useGetNestedDataQuery } = useContext(ReportContext);

  const {
    data: dataQuery,
    isLoading,
    isFetching,
  } = useGetNestedDataQuery({
    ...filter,
    ...(reportTemplateYFilterId && { reportTemplateYFilterId }),
  });

  const reportNestedData = dataQuery?.data || [];
  const columnsServerSide = dataQuery?.columns || [];

  return (
    <SubTableContainer colSpan={colSpan}>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <InsightReportSubTable
          reportNestedData={reportNestedData}
          columnsServerSide={columnsServerSide}
        />
      )}
    </SubTableContainer>
  );
};

const InsightReportSubTable = ({
  columnsServerSide,
  reportNestedData,
}: {
  columnsServerSide: ReportGetResponseColumn[];
  reportNestedData: ReportGetResponseData[];
}) => {
  const [t] = useTranslation();

  const { formData: formDataTable, structure: structureTable } = useFormTable({
    extraProps: {
      columns: (columnsServerSide || []).map((column) =>
        column.dataName === "name"
          ? {
              ...column,
              headerLabel: "Account Number - Name",
            }
          : column,
      ),
    },
    getStructure: getStructure,
    initialFormData: reportNestedData,
  });

  const columns: MUIDataTableColumnDef[] = structureTable.items.map((item) =>
    BuilderColumnOptionsBase({
      data: formDataTable,
      item,
      t,
    }),
  );

  const options = userMUIDataSubTableOptions({});

  return (
    <MuiDataTableWrapper
      title={""}
      data={formDataTable}
      columns={columns}
      options={options}
      tableType="main-nested-level-1-centered"
    />
  );
};

export default InsightReportSubTableContainer;
