/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import {
  CommonCallbackProps,
  StructureItemTable,
} from "../CommonBuilderTableTypes";

const get = (
  key: keyof StructureItemTable<any>,
  props: CommonCallbackProps,
) => {
  return (
    (typeof props.item[key] === "function"
      ? props.item[key](props)
      : props.item[key]) || undefined
  );
};

export default get;
