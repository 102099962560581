import ContactPersonTable from "src/components/Screens/ContactPerson/ContactPersonTable";
import CustomerContainer from "src/components/Screens/Customer/CustomerContainer";
import NotFound from "src/components/Screens/NotFound/NotFound";
import Page from "src/components/Basic/Mixed/Pages/Page";
import { useGetCustomerQuery } from "src/redux/services/CustomerService";
import { useParams } from "react-router-dom";
import { escape } from "src/utils/translate";
export const Customer = () => {
  let { id } = useParams<{ id: string }>();
  const isCreate = id === undefined;

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    isError,
  } = useGetCustomerQuery(id, {
    skip: isCreate,
  });

  return (
    <Page
      label={id ? "Edit customer" : "Create customer"}
      breadcrumbs={[
        { label: "Operations" },
        { label: "Customers", link: "/app/operations/customers" },
        {
          label: id
            ? `Edit customer #${customer?.externalId ? escape(customer?.externalId) : ""}`
            : "Create customer",
        },
      ]}
      isFullWidth={false}
      isLoading={isLoadingCustomer}
    >
      {isError ? (
        <NotFound type="customer_list" />
      ) : (
        <CustomerContainer
          data={!isCreate ? customer : undefined}
          rerouteUrlOnSuccess={{
            create: () => "/app/operations/customers",
          }}
        />
      )}
      {id ? <ContactPersonTable /> : <></>}
    </Page>
  );
};

export default Customer;
