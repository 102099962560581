import { MUIDataTableColumnDef } from "mui-datatables";

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { userMUIDataSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";

import useFormTable from "src/hooks/useFormTable";

import { DimensionItem } from "src/accurasee-backend-types/app/dimensionitem/dimensionitem.types";

import getStructure from "./DimensionRowsStructure";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

interface InvoiceSubTableProps {
  dimensionRows: DimensionItem[];
}

const DimensionRowsSubtable = ({ dimensionRows }: InvoiceSubTableProps) => {
  // Sort Dimensions by code
  const sortedDimensionRows = [...dimensionRows].sort(
    (a, b) => Number(a.code) - Number(b.code),
  );
  const { structure } = useFormTable<DimensionItem>({
    initialFormData: sortedDimensionRows,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<DimensionItem>({
      data: sortedDimensionRows,
      item,
    }),
  );
  const options = userMUIDataSubTableOptions({});

  return (
    <SubTableContainer>
      <MuiDataTableWrapper
        title={""}
        data={sortedDimensionRows}
        columns={columns}
        options={options}
        tableType="main-nested-level-1-centered"
      />
    </SubTableContainer>
  );
};

export default DimensionRowsSubtable;
