/**
 *Created by Mikael Lindahl on 2023-12-28
 */
import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";
import SectionCore from "../../Mixed/Sections/SectionCore";
const SectionTransparentForTable = (props: SectionCoreTProps) => {
  return (
    <SectionCore
      boxStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      transparent={true}
      {...props}
    >
      {props.children}
    </SectionCore>
  );
};

export default SectionTransparentForTable;
