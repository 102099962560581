/**
 * This is to get Display Value By Id
 */

const getDisplayValueById = ({
  idToMatch,
  keyIdLookup = "_id", // to find item
  items,
  keyDisplayValue = "name", // to display item's data
}: {
  idToMatch: string;
  keyIdLookup?: string;
  items: any[];
  keyDisplayValue?: string;
}) => {
  const item = items.find((item) => item[keyIdLookup] === idToMatch);
  return item ? item[keyDisplayValue] : idToMatch;
};
export default getDisplayValueById;
