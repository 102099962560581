/**
 *Created by Mikael Lindahl on 2023-11-27
 */

function getStackTrace() {
  let stack;

  try {
    throw new Error();
  } catch (error: any) {
    stack = error.stack || "";
  }

  return stack;
}

export default getStackTrace;
