import {
  getStructure,
  GetStructureExtraProps,
} from "./ReportFiltersGetStructure";
import {
  ReportTemplateYFilter,
  ReportTemplateYFilterWithPermissions,
  TransactionTypesValues,
} from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import {
  useDeleteReportTemplateYFilterMutation,
  useGetReportTemplateYFiltersQuery,
  useUpdateReportTemplateYFilterMutation,
} from "src/redux/services/ReportTemplateFilterService";
import { Box } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import ReportTemplateModalWithForm from "./ReportFilterModalWithForm";
import useFormTable from "src/hooks/useFormTable";
import useOnSubmit from "src/hooks/useOnSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";

const ReportTemplates = ({
  transactionType,
}: {
  transactionType: TransactionTypesValues;
}) => {
  const [t] = useTranslation();
  const { data: dataQueryCompany, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const { data: dataQuery, isLoading } =
    useGetReportTemplateYFiltersQuery(undefined);
  const allReportTemplateYFilters = dataQuery?.data || [];

  // dimensions
  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const [deleteReportTemplateYFilter] =
    useDeleteReportTemplateYFilterMutation();
  const [updateReportTemplateYFilter] =
    useUpdateReportTemplateYFilterMutation();

  const [openAddReportTemplateModal, setOpenAddReportTemplateModal] =
    useState(false);
  const [openEditReportTemplateModal, setOpenEditReportTemplateModal] =
    useState(false);
  const [editReportTemplateData, setEditReportTemplateData] = useState<
    ReportTemplateYFilterWithPermissions | undefined
  >();
  const [openDeleteReportTemplateModal, setOpenDeleteReportTemplateModal] =
    useState(false);
  const [deleteReportTemplateData, setDeleteReportTemplateData] = useState<
    ReportTemplateYFilter | undefined
  >();

  // filter data based on transactionType
  const reportTemplateYFilters =
    [...allReportTemplateYFilters]?.filter(
      (item: ReportTemplateYFilter) => item.transactionType === transactionType,
    ) || [];

  // This helps to check if this new label is in use or not
  // All is lowercase, for example: Abc === abc
  const labels =
    reportTemplateYFilters?.map((item: ReportTemplateYFilter) =>
      item.label.toLowerCase(),
    ) || [];

  const onSubmit = useOnSubmit({
    apiMutations: {
      update: updateReportTemplateYFilter,
      delete: deleteReportTemplateYFilter,
    },
    name: "report filter",
  });

  const { formData, structure } = useFormTable<
    ReportTemplateYFilter,
    GetStructureExtraProps
  >({
    extraProps: {
      dimensions,
      onDelete: (props) => {
        setOpenDeleteReportTemplateModal(true);
        setDeleteReportTemplateData(props.data[props.rowIndex]);
      },
      onEdit: (props) => {
        setOpenEditReportTemplateModal(true);
        setEditReportTemplateData(props.data[props.rowIndex]);
      },
      onSubmitActiveSwitch: onSubmit,
    },
    initialFormData: reportTemplateYFilters,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ReportTemplateYFilter>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 50,
  });

  return (
    <>
      <SectionTight
        label={`${transactionType === "cost" ? "Cost" : "Revenue"} - Report Filter`}
        subLabel={
          "Create and edit Report Filter - Multiple series support enabled"
        }
        rightBoxArea={
          <ButtonPrimary onClick={() => setOpenAddReportTemplateModal(true)}>
            {"Add filter"}
          </ButtonPrimary>
        }
        containsTable
        isLoading={isLoading || isLoadingCompany || isLoadingDimensions}
      >
        {reportTemplateYFilters.length === 0 ? (
          <Box sx={{ padding: "var(--space-24px)" }}>
            {t("No filter exist ...")}
          </Box>
        ) : (
          <MuiDataTableWrapper
            nameSpace="main-table"
            tableType="compact"
            title=""
            data={formData}
            columns={columns}
            options={options}
          />
        )}
      </SectionTight>

      {/* Add form */}
      {openAddReportTemplateModal && (
        <ReportTemplateModalWithForm
          transactionType={transactionType}
          labels={labels}
          openModal={openAddReportTemplateModal}
          permissions={dataQueryCompany?.company?.permissions!}
          handleCloseModal={() => setOpenAddReportTemplateModal(false)}
        />
      )}

      {/* Edit form */}
      {openEditReportTemplateModal && (
        <ReportTemplateModalWithForm
          row={editReportTemplateData}
          labels={labels?.filter(
            (label) => label !== editReportTemplateData?.label.toLowerCase(),
          )}
          openModal={openEditReportTemplateModal}
          permissions={editReportTemplateData?.permissions!}
          handleCloseModal={() => setOpenEditReportTemplateModal(false)}
        />
      )}

      {/* Delete modal */}
      <DeletionModal
        dataType="report filter"
        openModal={openDeleteReportTemplateModal}
        deleteData={{
          ...deleteReportTemplateData,
          name: deleteReportTemplateData?.label,
        }}
        deleteDataFunction={deleteReportTemplateYFilter}
        setIsOpenDeleteModal={setOpenDeleteReportTemplateModal}
      />
    </>
  );
};

export default ReportTemplates;
