/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getDisabled = <DataType = any>(props: CommonCallbackProps<DataType>) => {
  return (
    (typeof props.item.disabled === "function"
      ? props.item.disabled(props)
      : props.item.disabled) || false
  );
};

export default getDisabled;
