import AppLink from "../../../components/Basic/Simple/Links/AppLink";
import Companies from "src/screens/App/Superadmin/Companies/Companies";
import Configurations from "src/screens/App/Superadmin/Configurations/Configurations";
import Crons from "../../../screens/App/Superadmin/Crons/Crons";
import EmailTemplates from "src/screens/App/Superadmin/EmailTemplates/EmailTemplates";
import Languages from "src/screens/App/Superadmin/Languages/Languages";
import MailgunLogs from "src/screens/App/Superadmin/MailgunLogs/MailgunLogs";
import Page from "src/components/Basic/Mixed/Pages/Page";
import TabWithLinksTransparent from "src/components/Basic/Mixed/Tabs/TabWithLinksTransparent";
import Translations from "src/screens/App/Superadmin/Translations/Translations";
import Warnings from "src/screens/App/Superadmin/Warnings/Warnings";
import { Box } from "@mui/material";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { TabsType } from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import { Translation } from "src/accurasee-backend-types/app/translation/translation.types";
import { useGetLanguagesSuperAdminQuery } from "src/redux/services/LanguageService";
import { useGetTranslationsQuery } from "src/redux/services/TranslationService";

const RoutesSuperadmin = () => {
  const url = "/app/superadmin";
  const path = "/app/superadmin";
  const pathToStrip = "/app/superadmin";
  const redirectPath: RoutePath = "/app/dashboard";
  const getMainRouteUrl = (path: RoutePath) => path;

  const { data: translationsQuery, isLoading: isLoadingTranslations } =
    useGetTranslationsQuery(undefined);

  const translations = translationsQuery?.data;

  const {
    data: languagesQuery,
    isLoading: isLoadingLanguages,
    isFetching: isFetchingLanguages,
  } = useGetLanguagesSuperAdminQuery(undefined);
  const languages = languagesQuery?.data || [];
  const activeLanguages = languages?.filter((l) => l.isActive) || [];

  const tabs: TabsType[] = [
    {
      name: "Companies",
      mainRoute: {
        component: <Companies />,
        path: "/app/superadmin/companies",
      },
    },
    {
      name: "Configurations",
      mainRoute: {
        component: <Configurations />,
        path: "/app/superadmin/configurations",
      },
    },
    {
      name: "Translations",
      mainRoute: {
        component: (
          <Translations
            languages={languages}
            translations={translations as Translation[]}
          />
        ),
        path: "/app/superadmin/translations",
      },
    },
    {
      name: "Languages",
      mainRoute: {
        component: <Languages languages={languages as Language[]} />,
        path: "/app/superadmin/languages",
      },
    },
    {
      name: "Email templates",
      mainRoute: {
        component: <EmailTemplates languages={activeLanguages as Language[]} />,
        path: "/app/superadmin/email-templates",
      },
    },
    {
      name: "Mailgun logs",
      mainRoute: {
        component: <MailgunLogs />,
        path: "/app/superadmin/mailgun-logs",
      },
    },
    {
      name: "Warnings",
      mainRoute: {
        component: <Warnings />,
        path: "/app/superadmin/warnings",
      },
    },
    {
      name: "Cron jobs",
      mainRoute: {
        component: <Crons />,
        path: "/app/superadmin/crons",
      },
    },
  ];
  return (
    <Page
      label="Super admin"
      boxRight={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <AppLink label={"Dev"} href={"/app/superadmin/developers"} />
          <AppLink label={"See documentation"} href="/documentation" />
        </Box>
      }
      isLoading={
        isLoadingTranslations || isLoadingLanguages || isFetchingLanguages
      }
    >
      <TabWithLinksTransparent
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name="superadmin"
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
      />
    </Page>
  );
};

export default RoutesSuperadmin;
