import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import { Types } from "mongoose";

export type AdditionalInfoData = {
  _id?: Types.ObjectId;
  remarks?: string;
  description?: string;
};

export type ItemsTypes = "invoice" | "contract";

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: AdditionalInfoData;
  initData?: AdditionalInfoData;
}) => {
  if (data === undefined || initData === undefined) {
    return data;
  }

  let submitData = clone(data);

  const partialData = toUpdateData({
    data: submitData,
    initData: initData,
  });

  return partialData as AdditionalInfoData;
};

export const getStructure: UseFormContainerGetStructure<AdditionalInfoData> = ({
  setFormData,
}) => ({
  items: [
    {
      itemType: "contract",
      type: "text_input",
      dataName: "description",
      gridProps: { xs: 6 },
      label: "Description - Maximum 500 characters",
      maxLength: 500,
      minRows: 3,
      multiline: true,
      getValue: ({ data }) => data?.description || "-",
      setFormData,
    },
    {
      itemType: "contract",
      type: "text_input",
      dataName: "remarks",
      gridProps: { xs: 6 },
      label: "Comments - Maximum 500 characters",
      maxLength: 500,
      minRows: 3,
      multiline: true,
      getValue: ({ data }) => data?.remarks || "-",
      setFormData,
    },
    {
      itemType: "invoice",
      type: "text_input",
      dataName: "remarks",
      gridProps: { md: 4 },
      label: "Invoice text - Maximum 500 characters",
      maxLength: 500,
      minRows: 3,
      multiline: true,
      getValue: ({ data }) => data?.remarks || "-",
      setFormData,
    },
  ],
});

export default getStructure;
