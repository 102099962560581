/**
 *Created by Mikael Lindahl on 2024-02-07
 */
import getSetFormDataForInvoiceRows from "../getSetFormDataForInvoiceRows";

export const setFormDataCustomDiscount = <DataType = any>({
  setFormData,
}: {
  setFormData: (data: DataType[]) => void;
}) => {
  const setFormDataCustom = getSetFormDataForInvoiceRows({
    setFormData,
    key: "discount",
  });
  return setFormDataCustom;
};
