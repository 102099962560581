import DOMPurify from "dompurify";

import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { EmailTemplate } from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";

import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import {
  EmailType,
  emailTypes,
  emailTypeToNiceName,
} from "src/accurasee-backend-types/app/general/mailgun/mailgun.types";
import { EmailTemplateModalProps } from "./EmailTemplateModalWithForm";

export type GetStructureExtraProps = Pick<
  EmailTemplateModalProps,
  "languages" | "fullTemplateEmailTypes" | "templatesByEmailType"
> & {
  emailTemplateId?: string;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data: Partial<EmailTemplate & { html: string }> | undefined;
  initData: Partial<EmailTemplate & { html: string }> | undefined;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<EmailTemplate & { html: string }>;

  if (newData._id) {
    partialData = toUpdateData<Partial<EmailTemplate & { html: string }>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }
  return {
    ...partialData,
    ...(partialData?.html
      ? {
          html: DOMPurify.sanitize(partialData?.html || "", {
            WHOLE_DOCUMENT: true,
          }),
        }
      : {}),
  };
};

const getStructure: UseFormContainerGetStructure<
  Partial<EmailTemplate>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const isEditForm = !!extraProps?.emailTemplateId;
  const structure: StructureContainer<Partial<EmailTemplate>> = {
    items: [
      {
        type: "date",
        dataName: "updatedAt",
        label: "Last edited",
        gridProps: { xs: 6 },
        disabled: isEditForm,
        showWhen: () => isEditForm,
      },
      {
        type: "text_input",
        dataName: "editedBy",
        label: "Edited by",
        gridProps: { xs: 6 },
        getValue: ({ data }) => data?.editedBy?.name || "-",
        disabled: isEditForm,
        showWhen: () => isEditForm,
      },
      {
        required: true,
        type: "selector",
        dataName: "type",
        label: "Email template",
        options: emailTypes.map((type) => {
          return {
            label: emailTypeToNiceName[type],
            value: type,
            isActive: !extraProps?.fullTemplateEmailTypes.includes(type),
          };
        }),
        disabled: isEditForm,
        setFormData,
      },
      {
        required: true,
        type: "autocomplete",
        dataName: "languageCode",
        label: "Language",
        options: ({ data }) => {
          return (
            extraProps?.languages?.map((language: Language) => {
              const templates = (
                extraProps?.templatesByEmailType && data?.type
                  ? extraProps?.templatesByEmailType[data.type as EmailType] ||
                    []
                  : []
              ) as EmailTemplate[];

              return {
                label: language.nativeName,
                value: language.code,
                isActive: !templates
                  .map((t) => t.languageCode)
                  .includes(language.code),
              };
            }) || []
          );
        },
        setFormData,
        gridProps: { xs: 6 },
        disabled: isEditForm,
      },
      {
        type: "text",
        dataName: "fileName",
        label: "File name",
        showWhen: isEditForm,
      },
      {
        type: "text_input",
        dataName: "description",
        label: "Description",
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        dataName: "subject",
        label: "Subject",
        multiline: true,
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        dataName: "html",
        label: "Template",
        multiline: true,
        minRows: 10,
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
