import { getStructure, NewPasswordInputProps } from "./NewPasswordGetStructure";
import { Grid, Typography, Fade } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import { recover as resetUserPassword } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useState } from "react";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import Copyright from "src/components/Basic/Simple/Copyright/Copyright";
import isDisableSubmit from "src/utils/isDisableSubmit";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useStyles from "./styles";
import useTranslation from "src/hooks/useTranslationWrapper";

const NewPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles();

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState<"Failed" | null>(null);
  const [success, setSuccess] = useState(false);

  const initialFormData = {
    newPassword: "",
  };

  const { formData, helperText, isFormValid, hasTriedToSubmit, structure } =
    useFormContainer<Partial<NewPasswordInputProps>>({
      getStructure,
      initialFormData,
    });

  const submitDisabled = isDisableSubmit({
    data: formData,
    initialData: {},
    isValid: isFormValid,
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        lg={7}
        md={8}
        sm={12}
        className={classes.formContainer}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src="/Logo.png" alt="accurasee-logo" height={48} />
        <div className={classes.form}>
          <Typography className={classes.title}>
            {t("Set new password")}
          </Typography>
          <Typography className={classes.subTitle}>
            {t("Provide your new password")}
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            {success && <Navigate to="/login" />}
            <SectionContainer>
              <Section boxStyle={{ padding: "var(--space-16px) 0" }}>
                {BuildItems({
                  items: structure.items,
                  ...commonPropsBuildItem,
                })}
              </Section>
              {error ? (
                <Section boxStyle={{ padding: "var(--space-16px) 0 0 0" }}>
                  <Fade in={!!error}>
                    <Typography color="error" className={classes.errorMessage}>
                      {error}
                    </Typography>
                  </Fade>
                </Section>
              ) : (
                <></>
              )}
            </SectionContainer>
            <SectionWithButtonContainer>
              <ButtonPrimary
                disabled={submitDisabled && !hasTriedToSubmit}
                onClick={() => {
                  if (!formData?.newPassword) return;
                  const { newPassword } = formData;
                  resetUserPassword(
                    newPassword,
                    queryParams.get("id"),
                  )(dispatch)
                    .then((data) => {
                      if (!data.error) setSuccess(true);
                    })
                    .catch((err) => {
                      console.error(err);
                      setError("Failed");
                    });
                }}
                className={classes.loginButton}
                type="submit"
              >
                {t("Reset password")}
              </ButtonPrimary>
            </SectionWithButtonContainer>
          </form>
        </div>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default NewPassword;
