import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import MicrosoftDriveTable from "src/components/Basic/Mixed/Tables/MicrosoftDriveTable";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import getContractOfferExternalId from "../../../../utils/getContractOfferExternalId";
import useOnFormSubmitCustom from "../../../../hooks/useOnFormSubmitCustom";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { Grid, Typography } from "@mui/material";
import { MicrosoftDriveContext } from "../../../../context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { MicrosoftDocumentWrapper } from "../../../../hooks/useMicrosoftDrive/useMicrosoftDriveList";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

interface CopyFilesCloneContractOfferProps {
  contractOffer?: Partial<ContractOffer>;
  contractOfferClone?: Partial<ContractOffer>;
  openModal: boolean;
  onClose: () => void;
}

const CopyFilesCloneContractOfferModalWithForm = ({
  contractOffer,
  contractOfferClone,
  openModal,
  onClose,
}: CopyFilesCloneContractOfferProps) => {
  const [t] = useTranslation();
  const { contractOfferId } = useParams<{ contractOfferId: string }>();
  const [selectedRows, setSelectedRows] = useState<MicrosoftDocumentWrapper[]>(
    [],
  );
  const { enqueueSnackbar } = useSnackbar();
  const { microsoftDriveClient } = useContext(MicrosoftDriveContext);

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmitCustom({
    onSubmit: async () => {
      if (contractOfferId) {
        try {
          await microsoftDriveClient?.copyFilesCloneContractOffer({
            contractOfferExternalId: getContractOfferExternalId(contractOffer),
            contractOfferCloneExternalId:
              getContractOfferExternalId(contractOfferClone),
            fileNames: selectedRows.map((r) => r.origin.name as string),
          });

          enqueueSnackbar(t("Files copied"), { variant: "success" });
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t("Could not copy files"), { variant: "error" });
          return { action: "update", status: "failure" };
        }

        return { action: "update", status: "success" };
      } else {
        return { action: "update", status: "failure" };
      }
    },
    onSuccess: onClose,
    onFailure: onClose,
  });

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit();
      }}
      ref={refForm}
    >
      <ModalWrapper
        handleCloseModal={() => onClose()}
        openModal={openModal}
        label={"Copy files to clone"}
      >
        <SectionContainer>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }}>
              {t("Please select files you want to copy over to the clone")}
            </Typography>
          </Grid>
          <MicrosoftDriveTable
            collectionName={"contract_offers"}
            excludeActionRow={true}
            leafFolder={getContractOfferExternalId(contractOffer, true)}
            onlyTable={true}
            prefixDocumentName={getContractOfferExternalId(contractOffer)}
            setSelectedRows={setSelectedRows}
          />
        </SectionContainer>

        <SectionWithButtonContainer buttonCenter>
          <ButtonGroup>
            <ButtonCancel onClick={() => onClose()} />
            <ButtonSubmitCore
              initialSubmitData={{}}
              isSubmitting={isSubmitting}
              isValid={true}
              label="copy"
              onSubmit={async () => {
                refForm.current.requestSubmit();
              }}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </ModalWrapper>
    </form>
  );
};

export default CopyFilesCloneContractOfferModalWithForm;
