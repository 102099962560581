import { ActivityLogListReturn } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { ActivityLogType } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { Types } from "mongoose";
import { useGetActivityLogsQuery } from "src/redux/services/ActivityLogService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";
import useActivityLogsGetStructure from "./hooks/useActivityLogsGetStructure";
import useFormTable from "src/hooks/useFormTable";

const ActivityLogs = ({
  groupId,
  label,
  type,
}: {
  groupId?: Types.ObjectId;
  label: string;
  type: ActivityLogType;
}) => {
  const { data: activityLogsQuery, isLoading } = useGetActivityLogsQuery(
    { groupId, type },
    { skip: !groupId },
  );
  const activityLogs = activityLogsQuery?.data || [];

  const getStructure = useActivityLogsGetStructure({ type });

  const { formData, structure } = useFormTable<ActivityLogListReturn>({
    initialFormData: activityLogs,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ActivityLogListReturn>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionForTableWithToolbar isLoading={isLoading} label={label} transparent>
      <MuiDataTableWrapper
        nameSpace="main-table"
        tableType="main-with-filter"
        title=""
        data={formData}
        columns={columns}
        options={options}
        disabled
      />
    </SectionForTableWithToolbar>
  );
};

export default ActivityLogs;
