/**
 * This is to remove keys with empty string as value
 */

const removeKeysWithEmptyStringAsValue = (data: { [key: string]: any }) => {
  const result: { [key: string]: any } = {};

  Object.keys(data).forEach((key) => {
    if (data[key] !== "") {
      result[key] = data[key];
    }
  });

  return result;
};

// This is to remove empty elements (arrays, strings) from the object
export const cleanObject = <T extends Record<string, any>>(obj: T): T => {
  const result = { ...obj }; // Create a shallow copy to avoid mutating the original object

  Object.keys(result).forEach((key) => {
    const value = result[key];

    // Check if the value is an empty array or empty string
    if (Array.isArray(value) && value.length === 0) {
      delete result[key]; // Remove the property if it's an empty array
    } else if (typeof value === "string" && value.trim() === "") {
      delete result[key]; // Remove the property if it's an empty string
    }
  });

  return result;
};

export default removeKeysWithEmptyStringAsValue;
