import "./App.css";

import { router } from "./routes/Routes";
import { RouterProvider } from "react-router-dom";

Error.stackTraceLimit = 20;

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
