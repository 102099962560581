export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER = "GET_USER";
export const RESET_USER = "RESET_USER";
export const RECOVER_PASSWORD_USER = "RECOVER_PASSWORD_USER";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const INVITE_TO_COMPANY = "INVITE_TO_COMPANY";

export const GET_FILE = "GET_FILE";
export const POST_FILE = "POST_FILE";
export const DELETE_FILE = "DELETE_FILE";

export const GET_DIMENSIONS = "GET_DIMENSIONS";
export const GET_DIMENSION_ITEMS = "GET_DIMENSION_ITEMS";

export const SET_API_KEYS = "SET_API_KEYS";
export const ADD_API_KEY = "ADD_API_KEY";

export const SET_INTEGRATIONS = "SET_INTEGRATIONS";

//Calculate wat value based on article id and Customer id
export const GET_WATCALCULATION = "GET_WATCALCULATION";
// UX
export const DIALOG_OPEN = "DIALOG_OPEN";
export const DIALOG_CLOSE = "DIALOG_CLOSE";
export const DIALOG_RESET = "DIALOG_RESET";
