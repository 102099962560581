/**
 *Created by Mikael Lindahl on 2023-12-15
 */

import ButtonSecondary from "./ButtonSecondary";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ButtonCoreProps } from "./ButtonCore";
const ButtonClear = (props: Omit<ButtonCoreProps, "children">) => {
  const [t] = useTranslation();

  return <ButtonSecondary {...props}>{t("clear")}</ButtonSecondary>;
};

export default ButtonClear;
