import { TabsType } from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import Budget from "src/screens/App/Contracts/[contractId]/Budget/Budget";
import BudgetAdjustment from "src/screens/App/Contracts/[contractId]/BudgetAdjustment/BudgetAdjustment";
import Contract from "src/screens/App/Contracts/[contractId]/Contract/Contract";
import ContractCosts from "src/screens/App/Contracts/[contractId]/Costs/ContractCosts";
import ContractInsights from "src/screens/App/Contracts/[contractId]/Insights/ContractInsights";
import ContractReports from "src/screens/App/Contracts/[contractId]/Reports/ContractReports";
import CustomerInvoice from "src/components/Screens/CustomerInvoiceBase/CustomerInvoiceBase";
import CustomerInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Customer/CustomerInvoices";
import Economy from "src/screens/App/Contracts/[contractId]/Economy/Economy";
import Incomes from "src/screens/App/Contracts/[contractId]/Incomes/Incomes";
import InvoicePlan from "src/screens/App/Contracts/[contractId]/InvoicePlans/[invoicePlanId]/InvoicePlan";
import InvoicePlans from "src/screens/App/Contracts/[contractId]/InvoicePlans/InvoicePlans";
import LinkedInvoiceData from "src/screens/App/Contracts/[contractId]/LinkedInvoiceData/LinkedInvoiceData";
import PlannedInvoice from "src/components/Screens/PlannedInvoiceBase/PlannedInvoiceBase";
import PlannedInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Planned/PlannedInvoices";
import RoutesInvoicesTab from "src/routes/App/Contracts/[contractId]/Invoices/RoutesInvoicesTab";
import SubcontractContainer from "src/screens/App/Contracts/[contractId]/Subcontracts/[subcontractId]/Subcontract";
import SubcontractsTable from "src/screens/App/Contracts/[contractId]/Subcontracts/Subcontracts";
import SupplierInvoicesTable from "src/screens/App/Contracts/[contractId]/Supplierinvoices/SupplierInvoices";

const getRoutesContractTabStructure = ({
  isServiceOrderContract,
  isSuperAdmin,
}: {
  isServiceOrderContract?: boolean;
  isSuperAdmin?: boolean;
}) => {
  const tabs: TabsType[] = [
    {
      name: "Contract",
      mainRoute: {
        component: <Contract />,
        path: "/app/contracts/:contractId",
      },
    },
    {
      name: "Invoice plans",
      mainRoute: {
        component: <InvoicePlans />,
        path: "/app/contracts/:contractId/invoiceplans",
        isHidden: isServiceOrderContract,
      },
      subRoutes: [
        {
          component: <InvoicePlan />,
          exact: false,
          path: "/app/contracts/:contractId/invoiceplans/create",
        },
        {
          component: <InvoicePlan />,
          exact: false,
          path: "/app/contracts/:contractId/invoiceplans/:invoicePlanId",
        },
      ],
    },
    {
      name: "Invoices",
      mainRoute: {
        component: <RoutesInvoicesTab />,
        path: "/app/contracts/:contractId/invoices",
      },
      subRoutes: [
        {
          component: <PlannedInvoice />,
          path: "/app/contracts/:contractId/invoices/planned/:id",
          exact: false,
        },
        {
          component: <CustomerInvoice />,
          path: "/app/contracts/:contractId/invoices/customer/:id",
          exact: false,
        },
      ],
      children: [
        {
          component: <PlannedInvoices />,
          path: "/app/contracts/:contractId/invoices/planned",
        },
        {
          component: <CustomerInvoices />,
          path: "/app/contracts/:contractId/invoices/customer",
        },
      ],
    },
    {
      name: "Supplier invoices",
      mainRoute: {
        component: <SupplierInvoicesTable />,
        path: "/app/contracts/:contractId/supplierinvoices",
      },
    },
    {
      name: "Incomes",
      mainRoute: {
        component: <Incomes />,
        path: "/app/contracts/:contractId/incomes",
      },
    },
    {
      name: "Costs",
      mainRoute: {
        component: <ContractCosts />,
        path: "/app/contracts/:contractId/costs",
      },
    },
    {
      name: "Linked invoice data",
      mainRoute: {
        component: <LinkedInvoiceData />,
        path: "/app/contracts/:contractId/linkedinvoicedata",
        isHidden: isServiceOrderContract,
      },
    },
    {
      name: "Subcontracts",
      mainRoute: {
        component: <SubcontractsTable />,
        path: "/app/contracts/:contractId/subcontracts",
      },
      subRoutes: [
        {
          component: <SubcontractContainer />,
          exact: false,
          path: "/app/contracts/:contractId/subcontracts/:subcontractId",
        },
      ],
    },
    {
      name: "Insights",
      mainRoute: {
        component: <ContractInsights />,
        path: "/app/contracts/:contractId/insights",
        isHidden: true,
      },
    },
    {
      name: "Project economy",
      mainRoute: {
        component: <Economy />,
        path: "/app/contracts/:contractId/economy",
        isHidden: isServiceOrderContract,
      },
    },
    {
      name: "Reports",
      mainRoute: {
        component: <ContractReports />,
        path: "/app/contracts/:contractId/report",
        isHidden: isServiceOrderContract,
      },
    },
    {
      name: "Budget",
      mainRoute: {
        component: <Budget />,
        path: "/app/contracts/:contractId/budget",
        isHidden: isServiceOrderContract || !isSuperAdmin,
      },
    },
    {
      name: "Budget adjustment",
      mainRoute: {
        component: <BudgetAdjustment />,
        path: "/app/contracts/:contractId/budget-adjustment",
        isHidden: isServiceOrderContract || !isSuperAdmin,
      },
    },
  ];
  return tabs;
};

export default getRoutesContractTabStructure;
