/**
 *Created by Mikael Lindahl on 2022-11-09
 */

import useTranslation from "src/hooks/useTranslationWrapper";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import toCurrency from "../../../utils/toCurrency";
import {
  InvoicePlan,
  InvoicePlanRow,
  InvoiceplanExtension,
} from "../../../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import {
  PlannedInvoice,
  PlannedInvoiceRow,
} from "../../../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import LightTooltip from "src/components/Basic/Simple/LightTooltip/LightTooltip";
import {
  CustomerInvoice,
  CustomerInvoiceRow,
} from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";

export default function InvoicesTotals({
  invoicePlan: plan,
  invoiceRows,
}: {
  invoicePlan?:
    | InvoicePlan
    | PlannedInvoice
    | CustomerInvoice
    | SubcontractWithPermissions;
  invoiceRows?: ((InvoicePlanRow | PlannedInvoiceRow | CustomerInvoiceRow) &
    InvoiceplanExtension)[];
}) {
  let invoicePlan = plan;

  if (plan && invoiceRows !== undefined) {
    const { total, totalExcludingVAT } = invoiceRows.reduce(
      (tot, v) => {
        tot.total += v.total || 0;
        tot.totalExcludingVAT += v.totalExcludingVAT || 0;
        return tot;
      },
      { total: 0, totalExcludingVAT: 0 },
    );

    invoicePlan = {
      ...plan,
      total,
      totalVAT: total - totalExcludingVAT,
      totalExcludingVAT,
    };
  }

  const [t] = useTranslation();

  const isMilestoneRetainedFunds =
    (invoicePlan as InvoicePlan)?.type === "milestone_retained_funds";
  const isMilestone =
    (invoicePlan as InvoicePlan)?.type === "milestone" ||
    isMilestoneRetainedFunds;

  // Total price and Total in invoice plan total are not the same - Milestone type only
  const showTotalWarning =
    isMilestone &&
    (invoicePlan as InvoicePlan)?.milestoneDetails?.total !==
      invoicePlan?.totalExcludingVAT;
  return (
    <Stack spacing={1}>
      {/* TODO move this logic to backend? i.e let backend set contractRemainder depending on plan type */}
      {(invoicePlan as InvoicePlan).type === "milestone" &&
        (invoicePlan as InvoicePlan).contractOffer && (
          <Box
            sx={{
              display: "flex",
              color: "var(--gray-6)",
              justifyContent: "space-between",
            }}
          >
            <Typography>{t("Offer remainder")}</Typography>
            {(invoicePlan as InvoicePlan).contractOffer !== undefined && (
              <Typography>
                {toCurrency({
                  value:
                    ((invoicePlan as InvoicePlan).contractOffer as number) -
                    (invoicePlan?.totalExcludingVAT || 0),
                  currencyCode: invoicePlan?.currencyCode,
                })}
              </Typography>
            )}
          </Box>
        )}
      <Box sx={{ display: "flex", columnGap: "20px" }}>
        <Typography
          sx={{
            color: showTotalWarning
              ? "var(--negative-primary)"
              : "var(--gray-6)",
            display: "inline-flex",
          }}
        >
          {showTotalWarning && (
            <LightTooltip
              title={t("Total price doesn't match with contract values")}
              arrow
              placement="top-start"
            >
              <ErrorIcon
                sx={{
                  verticalAlign: "middle",
                  marginRight: "8px",
                  color: "var(--negative-primary)",
                }}
              />
            </LightTooltip>
          )}
          {t("TOTAL excl. VAT")}
        </Typography>
        <Typography
          sx={{
            color: showTotalWarning
              ? "var(--negative-primary)"
              : "var(--gray-6)",
          }}
        >
          {toCurrency({
            value: invoicePlan?.totalExcludingVAT || 0,
            currencyCode: invoicePlan?.currencyCode,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          color: "var(--gray-6)",
          justifyContent: "space-between",
        }}
      >
        <Typography>{t("VAT")}</Typography>
        <Typography>
          {toCurrency({
            value: invoicePlan?.totalVAT || 0,
            currencyCode: invoicePlan?.currencyCode,
          })}
        </Typography>
      </Box>
      {(invoicePlan as PlannedInvoice)?.totalTaxReduction > 0 && (
        <Box
          sx={{
            display: "flex",
            color: "var(--gray-6)",
            justifyContent: "space-between",
          }}
        >
          <Typography>{t("Total tax reduction")}</Typography>
          <Typography>
            {toCurrency({
              value: (invoicePlan as PlannedInvoice).totalTaxReduction,
              currencyCode: invoicePlan?.currencyCode,
            })}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          color: "var(--green-primary)",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{t("TOTAL")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {toCurrency({
            value: invoicePlan?.total || 0,
            currencyCode: invoicePlan?.currencyCode,
          })}
        </Typography>
      </Box>
    </Stack>
  );
}
