/**
 *Created by Mikael Lindahl on 2022-09-27
 */

import { useState } from "react";
import { MUIDataTableColumnDef } from "mui-datatables";
import { Box, Typography } from "@mui/material";

import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { ProjectSeries } from "src/accurasee-backend-types/app/company/company.types";

import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "src/redux/services/CompanyService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";

import useFormTable from "src/hooks/useFormTable";
import useOnSubmit from "src/hooks/useOnSubmit";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import useTranslation from "src/hooks/useTranslationWrapper";

import _c from "src/constants/Constants";
import sort from "src/utils/sort";

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import ConsentDialog from "src/components/Basic/Mixed/Dialogs/ConsentDialog";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";

import {
  getStructure,
  GetStructureExtraProps,
} from "./CompanyProjectNumberSeriesGetStructure";
import SeriesModalWithForm from "./SeriesModalWithForm";

const CompanyProjectNumberSeries = ({ disabled }: { disabled: boolean }) => {
  const [t] = useTranslation();

  const { data: dataQueryCompany, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const [updateCompany] = useUpdateCompanyMutation();
  const { data: dataQueryContractType, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);

  const [openAddSeriesModal, setOpenAddSeriesModal] = useState(false);
  const [openEditSeriesModal, setOpenEditSeriesModal] = useState(false);
  const [editSeriesData, setEditSeriesData] = useState<
    ProjectSeries | undefined
  >();
  // This is to open Warning modal - not able to delete
  const [openDeleteSeriesWarning, setOpenDeleteSeriesWarning] = useState(false);
  const [openDeleteSeriesModal, setOpenDeleteSeriesModal] = useState(false);
  const [deleteSeriesData, setDeleteSeriesData] = useState<
    ProjectSeries | undefined
  >();

  const contractType = dataQueryContractType?.data || [];
  const contractTypProjectNumberSeriesMap = contractType?.reduce((dic, val) => {
    if (!dic[val.projectNumberSeriesId]) {
      dic[val.projectNumberSeriesId] = [];
    }
    dic[val.projectNumberSeriesId].push(val);
    return dic;
  }, {});

  let projectNumberSeries =
    dataQueryCompany?.company?.projectNumberSeries || [];
  // This helps to check if this new label is in use or not
  // All is lowercase, for example: Abc === abc
  projectNumberSeries = sort(projectNumberSeries, (p) => Number(p.firstNumber));
  const labels = projectNumberSeries?.map((item) => item.label.toLowerCase());

  const onDelete = async () => {
    let newData = [...projectNumberSeries];
    const index = newData.findIndex((u) => u.id === deleteSeriesData?.id);

    newData.splice(index, 1);

    const deleteResult: any = await updateCompany({
      projectNumberSeries: newData,
    });
    return deleteResult;
  };

  const onSubmit = useOnSubmit({
    apiMutations: {
      update: updateCompany,
    },
    name: "project number series",
  });

  const { formData, structure } = useFormTable<
    ProjectSeries,
    GetStructureExtraProps
  >({
    extraProps: {
      projectNumberSeries,
      onDelete: (props) => {
        const row = props.data[props.rowIndex];
        if (
          contractTypProjectNumberSeriesMap[row.id] ||
          projectNumberSeries.length === 1
        ) {
          setOpenDeleteSeriesWarning(true);
        } else {
          setOpenDeleteSeriesModal(true);
        }
        setDeleteSeriesData(row);
      },
      onEdit: (props) => {
        setOpenEditSeriesModal(true);
        setEditSeriesData(props.data[props.rowIndex]);
      },
      onSubmitActiveSwitch: onSubmit,
    },
    initialFormData: projectNumberSeries,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ProjectSeries>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 50,
  });

  return (
    <>
      <SectionTight
        label={"Project number series"}
        subLabel={
          "Create and edit company number series used on contract creation"
        }
        rightBoxArea={
          <ButtonPrimary onClick={() => setOpenAddSeriesModal(true)}>
            {t("Add series")}
          </ButtonPrimary>
        }
        containsTable
        isLoading={isLoadingCompany && isLoadingContractType}
      >
        {projectNumberSeries.length === 0 ? (
          <Box sx={{ padding: "var(--space-24px)" }}>
            {t("No number series exist ...")}
          </Box>
        ) : (
          <MuiDataTableWrapper
            nameSpace="main-table"
            tableType="compact"
            title=""
            data={formData}
            columns={columns}
            options={options}
          />
        )}
      </SectionTight>

      {/* Add form */}
      {openAddSeriesModal && (
        <SeriesModalWithForm
          projectNumberSeries={projectNumberSeries}
          labels={labels}
          openModal={openAddSeriesModal}
          permissions={dataQueryCompany?.company?.permissions!}
          handleCloseModal={() => setOpenAddSeriesModal(false)}
        />
      )}

      {/* Edit form */}
      {openEditSeriesModal && (
        <SeriesModalWithForm
          projectNumberSeries={projectNumberSeries}
          row={editSeriesData}
          labels={labels?.filter(
            (label) => label !== editSeriesData?.label.toLowerCase(),
          )}
          openModal={openEditSeriesModal}
          permissions={dataQueryCompany?.company?.permissions!}
          handleCloseModal={() => setOpenEditSeriesModal(false)}
        />
      )}

      {/* Delete modal */}
      <DeletionModal
        dataType="project number series"
        openModal={openDeleteSeriesModal}
        deleteData={{ name: deleteSeriesData?.label }}
        deleteDataFunctionCustom={onDelete}
        setIsOpenDeleteModal={setOpenDeleteSeriesModal}
      />

      {/* Delete warning */}
      {openDeleteSeriesWarning && (
        <ConsentDialog
          handleClose={() => setOpenDeleteSeriesWarning(false)}
          open={openDeleteSeriesWarning}
          consentButtons={[{ label: t("Close") }]}
        >
          <Typography color={"var(--black-7)"} fontSize={17}>
            {projectNumberSeries.length === 1 &&
              t(
                "Can not delete since there always needs to be at least one project number series",
              )}
            {projectNumberSeries.length !== 1 &&
              deleteSeriesData &&
              contractTypProjectNumberSeriesMap[deleteSeriesData?.id] &&
              `${t(
                `Can not delete this project number series since it exists on contract type(s):`,
              )} ${contractTypProjectNumberSeriesMap[deleteSeriesData?.id]
                .map((s: ContractType) => `"${s.name}"`)
                .join(", ")}`}
          </Typography>
        </ConsentDialog>
      )}
    </>
  );
};

export default CompanyProjectNumberSeries;
