import getYear from "date-fns/getYear";
import { CustomerWithTotals } from "src/accurasee-backend-types/app/customer/customer.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { escape } from "src/utils/translate";

export interface GetStructureExtraProps {
  location: any;
}

const getStructure: UserFormTableGetStructure<
  CustomerWithTotals,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "link",
      dataName: "externalId",
      headerLabel: "Id",
      sort: true,
      redirectLink: (props) =>
        extraProps?.location.pathname +
        "/" +
        String(props.data[props.rowIndex]._id),
    },
    {
      type: "link",
      dataName: "name",
      headerLabel: "name",
      sort: true,
      redirectLink: (props) =>
        extraProps?.location.pathname +
        "/" +
        String(props.data[props.rowIndex]._id),
    },

    {
      type: "text",
      dataName: "address1",
      headerLabel: "Address",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "city",
      headerLabel: "City",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "zipCode",
      headerLabel: "Zipcode",
      sx: { width: "100%" },
    },
    {
      type: "text_currency",
      dataName: "totalEstSalesThisYear",
      headerLabel: `Estimated sales${escape(
        " " + String(getYear(new Date())),
      )}`,
      sort: true,
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
    {
      type: "number",
      dataName: "numberOfActiveContracts",
      headerLabel: "Number of active Contracts",
      sort: true,
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
  ],
});

export default getStructure;
