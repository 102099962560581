import { CompanydataTypes } from "../general/companydata.types";

import { Types } from "mongoose";

enum INVITE_TYPES {
  "COMPANY_INVITE" = 1,
}

enum INVITE_STATUS {
  "SENT" = 10,
  "REJECTED" = 20,
  "ACCEPTED" = 30,
  "INVALID" = 40,
}

export interface Invite extends CompanydataTypes {
  _id?: Types.ObjectId;
  companyName: string;
  companyRoleIds?: Types.ObjectId[];
  createdAt?: Date;
  createdByUserId: Types.ObjectId;
  fromEmail: string;
  fromFirstName: string;
  fromLastName: string;
  message?: string;
  status: INVITE_STATUS;
  toEmail: string;
  type: INVITE_TYPES;
  validUntil?: Date;
}

export type InviteSend = {
  inviteType: Invite["type"];
  inviteEmail: Invite["toEmail"];
  inviteRoleIds: Invite["companyRoleIds"];
};

export { INVITE_STATUS, INVITE_TYPES };
