import { IconTypes } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import getIconByType from "src/components/Builders/Table/Utils/getIconByType";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useTranslation from "src/hooks/useTranslationWrapper";

type ButtonWithActionsDropdownTProps = {
  arrowDirection?: "down" | "up";
  actionOptions?: {
    disabled: boolean;
    label: string;
    iconType: IconTypes;
    onClick: () => void;
  }[];
  label: string;
  isColor?: boolean;
  isLoading: boolean;
};
const ButtonWithActionsDropdown = ({
  arrowDirection = "down",
  actionOptions,
  label,
  isColor,
  isLoading,
}: ButtonWithActionsDropdownTProps) => {
  const [t] = useTranslation();

  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);
  const [buttonHTMLElement, setButtonHTMLElement] =
    useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonSecondary
        isLoading={isLoading}
        onClick={(e) => {
          setButtonHTMLElement(e.currentTarget);
          setOpen(true);
          setButtonWidth(e.currentTarget.clientWidth);
        }}
        endIcon={
          arrowDirection === "down" ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowUpIcon />
          )
        }
      >
        {t(label)}
      </ButtonSecondary>

      <Menu
        id="dropdown-menu"
        anchorEl={buttonHTMLElement}
        open={open}
        onClose={() => {
          setOpen(false);
          setButtonHTMLElement(null);
        }}
        anchorOrigin={{
          vertical: arrowDirection === "up" ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: arrowDirection === "up" ? "bottom" : "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { minWidth: buttonWidth, borderRadius: "8px" },
        }}
        disableScrollLock
      >
        {actionOptions?.map((button) => {
          const { icon, styling } = getIconByType(button.iconType);
          return (
            <MenuItem
              key={`${button.label}-button`}
              // disabled when it is at the current value
              disabled={isLoading || button.disabled}
              onClick={() => {
                button.onClick();
                setOpen(false);
                setButtonHTMLElement(null);
              }}
              sx={{
                columnGap: "var(--space-12px)",
                textTransform: "capitalize",
                ...(isColor && !button.disabled
                  ? { "& svg": { color: styling.color } }
                  : {}),
              }}
            >
              {icon}
              <Typography color="var(--gray-11)">{t(button.label)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonWithActionsDropdown;
