import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { Language } from "../../../../../accurasee-backend-types/app/language/language.types";
import toUpdateData from "../../../../../utils/toUpdateData";

function useLanguagesStructure<ExtraProps = any>(extraProps?: ExtraProps) {
  const getStructure: UserFormTableGetStructure<Language> = ({
    setFormData,
  }) => {
    return {
      items: [
        {
          type: "text",
          dataName: "code",
          headerLabel: "Language",
        },
        {
          type: "text",
          dataName: "name",
          headerLabel: "Name",
          setFormData,
        },
        {
          type: "text",
          dataName: "nativeName",
          headerLabel: "Native name",
          setFormData,
        },
        {
          type: "switch",
          dataName: "isActive",
          cellLabel: "Active",
          setFormData,
        },
      ],
    };
  };

  const toSubmitData = (
    data: Partial<Language>[],
    initialData: Partial<Language>[],
  ) => {
    let submitData: Partial<Language>[] = [];
    data.forEach((d, index) => {
      let update = toUpdateData({ data: d, initData: initialData[index] });
      if (Object.keys(update).length !== 0) {
        submitData.push({ _id: d._id, ...update });
      }
    });

    return submitData;
  };

  return { getStructure, toSubmitData };
}

export default useLanguagesStructure;
