import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import { File as FileStorage } from "src/accurasee-backend-types/app/general/storage.types";
import { LinkedInvoiceDataGetList } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { userMUIDataSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useSnackbar } from "notistack";
import { useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import fileDownload from "js-file-download";
import FileService from "src/redux/services/FileService";
import getStructure from "./LinkedInvoiceDataRowsStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useFormTable from "src/hooks/useFormTable";

interface LinkedInvoiceDataRowsSubtableProps {
  contract: Contract;
  invoiceDataRows: LinkedInvoiceDataGetList[];
}

const LinkedInvoiceDataRowsSubtable = ({
  contract,
  invoiceDataRows,
}: LinkedInvoiceDataRowsSubtableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileDownloading, setFileDownloading] = useState({});

  const onDownloadFiles = async ({
    files,
    objectId,
  }: {
    files: FileStorage[];
    objectId: string;
  }) => {
    for (let file of files) {
      setFileDownloading({
        ...fileDownloading,
        [String(file._id)]: true,
      });
      try {
        const fileRes = await FileService.getFile(file._id, objectId);
        fileDownload(fileRes.data, file.originalName, file.mimeType);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("Could not download file(s)"), {
          variant: "error",
        });
      } finally {
        setFileDownloading({
          ...fileDownloading,
          [String(file._id)]: false,
        });
      }
    }
  };
  const { structure } = useFormTable<LinkedInvoiceDataGetList>({
    initialFormData: invoiceDataRows,
    getStructure,
    extraProps: { onDownloadFiles, contract },
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<LinkedInvoiceDataGetList>({
      data: invoiceDataRows,
      item,
    }),
  );
  const options = userMUIDataSubTableOptions({});

  return (
    <SubTableContainer>
      <MuiDataTableWrapper
        title={""}
        data={invoiceDataRows}
        columns={columns}
        options={options}
        tableType="main-nested-level-1-centered"
      />
    </SubTableContainer>
  );
};

export default LinkedInvoiceDataRowsSubtable;
