import NotFoundBase from "./NotFoundBase";

interface NotFoundProps {
  type?:
    | "article_list"
    | "contract_list"
    | "contract_offer_list"
    | "contract_type_list"
    | "customer_list"
    | "planned_invoice_list";
}

const NotFound = ({ type }: NotFoundProps) => {
  switch (type) {
    case "article_list":
      return (
        <NotFoundBase
          title="Article"
          redirect="/app/product-and-plans/articles"
          redirectLabel="Go to Article list"
        />
      );
    case "contract_list":
      return (
        <NotFoundBase
          title="Contract"
          redirect="/app/contracts"
          redirectLabel="Go to Contract list"
        />
      );
    case "contract_offer_list":
      return (
        <NotFoundBase
          title="Offer"
          redirect="/app/contract-offers"
          redirectLabel="Go to Offer list"
        />
      );
    case "contract_type_list":
      return (
        <NotFoundBase
          title="Contract type"
          redirect="/app/contracts/contracttypes"
          redirectLabel="Go to Contract type list"
        />
      );
    case "customer_list":
      return (
        <NotFoundBase
          title="Customer"
          redirect="/app/operations/customers"
          redirectLabel="Go to Customer list"
        />
      );
    case "planned_invoice_list":
      return (
        <NotFoundBase
          title="Planned invoice"
          redirect="/app/contracts"
          redirectLabel="Go to Contract list"
        />
      );
    default:
      return (
        <NotFoundBase
          title="Dashboard"
          redirect="/app/dashboard"
          redirectLabel="Go to dashboard"
        />
      );
  }
};
export default NotFound;
