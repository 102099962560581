/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    boxSection: {
      height: "100%",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingBottom: "2rem",
      paddingTop: "1rem",

      "& .MuiFormHelperText-root": {
        marginLeft: 2,
        marginRight: 0,
      },
      display: "flex",
      flexDirection: "row-reverse",
    },
  }),
);

export default useStyles;
