export enum ActionType {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
}

export enum CreatedByType {
  MigrationScript = "migrationScript",
  ApiCall = "apiCall",
  UserAction = "userAction",
  CronJob = "cronJob",
  Direct = "Direct",
  WebHook = "webHook",
}

export type EditedByType = CreatedByType;
