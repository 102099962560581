import { LanguageCode } from "../../language/language.types";

export const emailTypeToNiceName: Record<EmailType, string> = {
  "company-invite-existing-user": "Company Invitation (Existing User)",
  "company-invite-new-user": "Company Invitation (New User)",
  contract_offer_sent_to_customer: "Contract Offer Sent to Customer",
  contract_offer_to_customer_on_response:
    "Contract Offer to Customer on Response",
  contract_offer_to_user_on_response: "Contract Offer to User on Response",
  "contract-transfer-ownsership-current-user":
    "Contract Transfer Ownership (Current User)",
  "contract-transfer-ownsership-new-user":
    "Contract Transfer Ownership (New User)",
  "email-confirmation": "Email Confirmation",
  "email-templates": "Email Templates",
  "reset-password": "Password Reset",
  contract_reminder: "Contract Reminder Email",
  endDate_reminder: "End Date Reminder Email",
  health_check_integrations: "Integration health check",
  invoice: "Invoice Email",
  invoicereminder: "Invoice Reminder Email",
  paymentplan: "Payment Plan Email",
  welcome: "Welcome Email",
};

export const emailTypes = [
  "company-invite-existing-user",
  "company-invite-new-user",
  "contract-transfer-ownsership-current-user",
  "contract-transfer-ownsership-new-user",
  "contract_offer_sent_to_customer",
  "contract_offer_to_customer_on_response",
  "contract_offer_to_user_on_response",
  "contract_reminder",
  "email-confirmation",
  "email-templates",
  "endDate_reminder",
  "health_check_integrations",
  "invoice",
  "invoicereminder",
  "paymentplan",
  "reset-password",
  "welcome",
] as const;
export type EmailType = (typeof emailTypes)[number];

export interface Email {
  data: Record<string, any>;
  locale: LanguageCode;
  to: string;
  type: EmailType;
}

export interface InvoiceEmail extends Email {
  data: {
    companyName: string;
    invoiceDueDate: string;
    invoiceTotal: string | number;
    invoiceNumber: string | number;
  };
}

export interface WelcomeEmail extends Email {
  data: {
    firstName: string;
  };
}

export interface ConfirmationEmail extends Email {
  data: {
    firstName: string;
    key?: string;
    url?: string;
  };
}

export interface RecoveryEmail extends Email {
  data: {
    firstName: string;
    url: string;
    key: string;
  };
}

export interface CompanyInviteExistingUserEmail extends Email {
  data: {
    firstName: string;
    companyName: string;
    invitedByName: string;
    url: string;
  };
}

export type CompanyInviteNewUserEmail = CompanyInviteExistingUserEmail;

export interface Attachment {
  filename: string;
  data: Buffer;
}
