/**
 *Created by Mikael Lindahl on 2023-03-19
 */

import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  SxProps,
  ThemeProvider,
} from "@mui/material";
import { Breadcrumb } from "src/components/Basic/Mixed/Breadcrumb/BreadcrumbsWrapper";
import { createTheme, Theme } from "@mui/material/styles";
import PageHeading from "src/components/Basic/Mixed/Headings/PageHeading";
import React from "react";
import styles from "src/themes/default";

export type PageBaseProps = {
  boxRight?: JSX.Element | JSX.Element[];
  breadcrumbs?: Breadcrumb[];
  children?:
    | (boolean | undefined | JSX.Element)[]
    | boolean
    | JSX.Element
    | undefined;
  headingSx?: SxProps;
  isFullWidth?: boolean;
  isLoading?: boolean;
  label?: string;
  labelSize?: "normal" | "large";
  subLabel?: string;
  sx?: SxProps<Theme> | undefined;
};

const theme = createTheme(styles);

const PageBase = ({
  children,
  sx,
  isFullWidth,
  isLoading,
  ...rest
}: PageBaseProps) => {
  const childrenArray = React.Children.toArray(children);

  const content = (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: "var(--space-16px) 0",
          backgroundColor: "transparent",
          boxShadow: "none",
          ...sx,
        }}
      >
        <PageHeading
          headingSx={{
            ...rest?.headingSx,
            ...(!isFullWidth && isFullWidth !== undefined
              ? { maxWidth: "1300px" }
              : {}),
          }}
          {...rest}
        />
        {isLoading ? (
          <Box
            sx={{
              height: "3rem",
              paddingTop: rest?.boxRight ? "5rem" : "3rem",
            }}
          >
            <LinearProgress />
          </Box>
        ) : (
          <Grid container spacing={4} marginTop={3}>
            {childrenArray.map((child, index) => {
              return (
                <Grid
                  item
                  style={index === 0 ? { paddingTop: "0px" } : {}}
                  sx={{ width: "100%" }}
                  key={index}
                >
                  {child}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Card>
    </ThemeProvider>
  );
  return !isFullWidth && isFullWidth !== undefined ? (
    <Container>{content}</Container>
  ) : (
    content
  );
};

export default PageBase;
