/**
 *Created by Mikael Lindahl on 2022-12-15
 */

import { useEffect, useRef } from "react";

const usePreventWheelScrollChangeNumberInput = () => {
  const textFieldRef = useRef<any>(null);

  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();

    if (!textFieldRef.current) {
      return;
    }

    // This is to prevent whell scrolling from changing number values
    textFieldRef?.current?.addEventListener("wheel", handleWheel);

    return () => {
      if (!textFieldRef.current) {
        return;
      }

      textFieldRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return textFieldRef;
};

export default usePreventWheelScrollChangeNumberInput;
