import React, { createContext, ReactNode } from "react";
import { HiddenComponentConfig } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { LinearProgress } from "@mui/material";
import { ReturnUser } from "../accurasee-backend-types/app/user/user.types";
import { useGetAllCompanyConfigsHiddenComponentsQuery } from "src/redux/services/CompanyConfigsService";

// Define the context type
export const HiddenComponentContext = createContext<{
  hiddenComponentConfigs: Partial<HiddenComponentConfig>[];
}>({ hiddenComponentConfigs: [] });

// Context provider component with types
interface HiddenComponentProviderProps {
  children: ReactNode;
  user: Partial<ReturnUser>;
}

export const HiddenComponentProvider: React.FC<
  HiddenComponentProviderProps
> = ({ user, children }) => {
  const { data: companyConfigsQuery, isLoading } =
    useGetAllCompanyConfigsHiddenComponentsQuery(undefined);
  const config = companyConfigsQuery?.data;
  const hiddenComponentConfigs = config?.hiddenComponentConfigs || [];
  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <HiddenComponentContext.Provider
      value={{
        hiddenComponentConfigs,
      }}
    >
      {children}
    </HiddenComponentContext.Provider>
  );
};
