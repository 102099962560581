import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import { useTranslation } from "react-i18next";
import getOptions from "../Utils/getOptions";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import setValue from "../Utils/setValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useStyles from "./CommonStyles";

type ToggleItemTProps = CommonBuilderContainerTypes;

const ToggleInputItem = (props: ToggleItemTProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "ToggleInputItem",
    });
  }
  const options = getOptions(commonCallbackProps);

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      {props.item.label && (
        <InputLabel
          required={getRequired(commonCallbackProps)}
          shrink
          htmlFor={props.item.dataName}
          className={classes.labelFieldStyle}
        >
          {t(props.item.label)}
        </InputLabel>
      )}

      <ToggleButtonGroup
        sx={{ display: "block" }}
        value={getValue(commonCallbackProps) || ""}
        id={props.item.dataName}
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
          let changeProps = {
            ...commonCallbackProps,
            value,
          };
          if (props.item.setFormDataCustom) {
            props.item.setFormDataCustom(changeProps);
          } else {
            setValue({ ...changeProps });
          }
        }}
      >
        {options?.map((el) => (
          <ToggleButton
            key={`toggle-${el.label}`}
            sx={{
              padding: "var(--space-12px) 0",
              lineHeight: "20px",
              width: "50%",
            }}
            value={el.value}
            name={el.label}
          >
            {el.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  ) : (
    <></>
  );
};

export default ToggleInputItem;
