/**
 *Created by Mikael Lindahl on 2023-11-23
 */

import { Typography as MuiTypography } from "@mui/material";
import useTranslation from "src/hooks/useTranslationWrapper";

export type SubLabelType = "error" | "info" | "warning";

const BoxHeadingSubLabel = (props: {
  subLabelType?: SubLabelType;
  subLabel?: string;
}) => {
  const [t] = useTranslation();

  let color = "var(--gray-3)";
  switch (props.subLabelType) {
    case "error":
      color = "red";
      break;
    case "warning":
      color = "var(--warning-1)";
      break;
  }

  return props?.subLabel ? (
    <MuiTypography
      color={color}
      fontSize="16px !important"
      fontWeight="normal"
      variant="body1"
    >
      {t(props.subLabel)}
    </MuiTypography>
  ) : (
    <></>
  );
};

export default BoxHeadingSubLabel;
