import { ApiKeyInformation } from "src/accurasee-backend-types/app/company/company.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import clone from "src/utils/clone";

interface GetStructureExtraProps {
  setIsOpenDeleteModal: (v: boolean) => void;
  setDeleteData: (data: ApiKeyInformation) => void;
}

const getStructure: UserFormTableGetStructure<
  ApiKeyInformation,
  GetStructureExtraProps
> = ({ extraProps }) => ({
  items: [
    {
      type: "text",
      dataName: "description",
      headerLabel: "Description",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "createdByUserEmail",
      headerLabel: "Created by",
      sx: { width: "100%" },
    },
    {
      type: "date",
      dataName: "createdAt",
      headerLabel: "Creation date",
      sx: { width: "100%" },
    },
    {
      type: "icon_button",
      iconType: "delete",
      dataName: "delete",
      headerLabel: "",
      onClick: ({ rowIndex, data }) => {
        let newData = clone(data);
        extraProps?.setIsOpenDeleteModal(true);
        extraProps?.setDeleteData(newData[rowIndex] as ApiKeyInformation);
      },
    },
  ],
});

export default getStructure;
