import {
  useGetReportTemplatesQuery,
  useUpdateReportTemplateOrderMutation,
} from "src/redux/services/ReportTemplateService";
import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Report from "./Report";
import { ReportTemplate } from "src/accurasee-backend-types/app/report_template/report_template.types";

enum MoveDirection {
  Up = -1,
  Down = 1,
}

const Reports = () => {
  const [openCreateReportModal, setOpenCreateReportModal] = useState(false);

  const {
    data: dataQuery,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useGetReportTemplatesQuery(undefined);

  const [updateReportTemplateOrder] = useUpdateReportTemplateOrderMutation();

  const reportTemplates = dataQuery?.data || [];

  const isLoading = isLoadingReports || isFetchingReports;

  const handleMove = (index: number, direction: MoveDirection) => {
    if (index === 0 && direction === MoveDirection.Up) {
      return;
    }
    if (
      index === reportTemplates.length - 1 &&
      direction === MoveDirection.Down
    ) {
      return;
    }

    const newReportTemplateIds = [...reportTemplates].map((template) =>
      String(template._id),
    );

    [newReportTemplateIds[index], newReportTemplateIds[index + direction]] = [
      newReportTemplateIds[index + direction],
      newReportTemplateIds[index],
    ];

    updateReportTemplateOrder(
      newReportTemplateIds.map((id, index: number) => ({
        _id: id,
        order: index,
      })),
    );
  };

  const onCloseModal = useCallback(() => {
    setOpenCreateReportModal(false);
  }, [setOpenCreateReportModal]);

  return (
    <>
      <Page
        label={"Reports"}
        breadcrumbs={[{ label: "Setup" }, { label: "Reports" }]}
        isFullWidth={false}
        isLoading={isLoadingReports || isFetchingReports}
        boxRight={
          <ButtonAddBoxIcon
            label="Create report"
            onSubmit={() => setOpenCreateReportModal(true)}
            disabled={isLoading}
          />
        }
      >
        {reportTemplates?.length === 0 ? (
          <Typography>{"No report template exists ..."}</Typography>
        ) : (
          reportTemplates?.map((template, index: number) => (
            <Report
              key={`${template.name}-${index}`}
              data={template}
              onMoveUp={() => handleMove(index, MoveDirection.Up)}
              onMoveDown={() => handleMove(index, MoveDirection.Down)}
              disableMoveDown={index === reportTemplates.length - 1}
              disableMoveUp={index === 0}
            />
          ))
        )}
      </Page>
      {openCreateReportModal && (
        <Report
          data={{ name: "New report" } as ReportTemplate}
          openModal={openCreateReportModal}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default Reports;
