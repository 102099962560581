/**
 *Created by Mikael Lindahl on 2023-01-09
 */

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import VatWarningTooltip from "src/components/Basic/Simple/Tooltips/VatWarningTooltip";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import determineCurrencyStyling from "../../Utils/determineCurrencyStyling";
import getHelperText from "../Utils/getHelperText";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import parseValue from "../Utils/parseValue";
import setValue from "../Utils/setValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import useStyles from "./CommonStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";

type TextItemTProps = CommonBuilderContainerTypes & {
  isCurrency?: boolean;
};

const TextInputItem = (props: TextItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);
  const classes = useStyles();

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  const value = getValue(commonCallbackProps);

  const [inputValue, setInputValue] = useState(
    value || (value === 0 ? value : ""),
  );

  const [isOverflow, setIsOverflow] = useState(false);
  // Additional state for managing visibility of password
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef<HTMLInputElement>();
  const propsRef = useRef(props);

  propsRef.current = props;

  useEffect(() => {
    setInputValue(value || (value === 0 ? value : "")); // Update state when prop changes
  }, [props]);

  const setData = (value: any) => {
    let changeProps = {
      ...commonCallbackProps,
      data: propsRef.current.data,
      value: parseValue({
        value,
        parse: props?.parse,
      }),
    };

    if (props.item.setFormDataCustom) {
      props.item.setFormDataCustom(changeProps);
    } else {
      setValue({ ...changeProps });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      setIsOverflow(
        inputRef.current.scrollHeight > inputRef.current.clientHeight,
      );
    }
  }, [inputValue]);

  const currencyCode = props.item?.currencyCode || props.data?.currencyCode;
  const enableEnterKeyPress = props.item?.enableEnterKeyPress || false;

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const valueToSet = props?.formatValueDisplayed
      ? props?.formatValueDisplayed.reversed(event.target.value, currencyCode)
      : event.target.value;

    setInputValue(valueToSet);
  };

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "TextInputItem" });
  }
  let extra = {};

  if (currencyCode && props.isCurrency) {
    const { symbol, position, adornment } =
      determineCurrencyStyling(currencyCode);

    extra = {
      ...extra,
      InputProps: {
        [adornment]: (
          <InputAdornment position={position}>{symbol}</InputAdornment>
        ),
      },
    };
  }
  if (props.item.prefix) {
    extra = {
      ...extra,
      InputProps: {
        startAdornment: (
          <InputAdornment position={"start"}>
            {props.item.prefix}
          </InputAdornment>
        ),
      },
    };
  }

  const handleBlur = () => {
    setData(inputValue); // Call setData immediately
    if (props.setIsTyping) {
      props.setIsTyping(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputRef.current) {
      if (!enableEnterKeyPress) {
        event.preventDefault(); // Prevents the default action
        inputRef.current.blur(); // Triggers blur event
      } else {
        handleBlur();
      }
    }
  };

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel
        required={getRequired(commonCallbackProps)}
        shrink
        htmlFor={props.item.dataName}
        className={classes.labelFieldStyle}
      >
        {t(props.item.label)}
        {props.item.vatTypeTooltip && (
          <VatWarningTooltip {...props.item.vatTypeTooltip} />
        )}
      </InputLabel>

      <TextField
        // Disable auto complete in chrome
        // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        autoComplete={"new-password"}
        disabled={props.itemDisabled}
        error={Boolean(getHelperText(getHelperTextProps))}
        helperText={getHelperText(getHelperTextProps)}
        id={`${props.item.dataName}-${props.index}`}
        inputRef={inputRef}
        minRows={props.item?.minRows || 2}
        multiline={props.item.multiline}
        name={`${props.item.dataName}-${props.index}`}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          handleBlur();
        }}
        onChange={handleChange}
        onKeyDown={props.item?.multiline ? undefined : handleKeyPress}
        required={getRequired(commonCallbackProps)}
        type={showPassword ? "text" : props.item?.textInputType}
        sx={{
          "& textarea": {
            overflowY: isOverflow ? "scroll" : "hidden", // Show scrollbar when content overflows
            overflowX: "hidden", // Always hide horizontal scrollbar
            "&::-webkit-scrollbar": {
              display: isOverflow ? "auto" : "none", // Show scrollbar in WebKit browsers when content overflows
            },
            "&::-webkit-scrollbar-horizontal": {
              display: "none", // Hide horizontal scrollbar in WebKit browsers
            },
            scrollbarHeight: "none", // Hide horizontal scrollbar in Firefox
            scrollbarWidth: isOverflow ? "auto" : "none", // Show scrollbar in Firefox when content overflows
          },
          width: "100%",
          "& .MuiInputBase-input": {
            padding: "var(--space-12px) var(--space-16px)",
          },
          "& .MuiInputBase-multiline": {
            padding: "var(--space-12px) var(--space-16px)",
            // calculate height based on line-height - 23px
            height: `calc(${24 + (props.item?.minRows || 2) * 23}px)`,
          },
          "& .MuiInputBase-multiline textarea": {
            overflowY: isOverflow ? "scroll !important" : "hidden", // Show scrollbar when content overflows
            height: "100%!important",
            padding: "0 var(--space-12px) 0 0", // space between text and scrollbar
          },
          ...props.item.sx,
        }}
        value={
          props?.formatValueDisplayed
            ? props?.formatValueDisplayed.forward(inputValue, currencyCode)
            : inputValue
        }
        variant="outlined"
        // Add endAdornment for visibility toggle button
        InputProps={
          props.item?.textInputType === "password"
            ? {
                endAdornment:
                  props.item?.textInputType === "password" ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }
            : undefined
        }
        {...extra}
        inputProps={
          props.item?.maxLength
            ? { maxLength: props.item?.maxLength }
            : undefined
        }
      />
    </Grid>
  ) : (
    <></>
  );
};

export default TextInputItem;
