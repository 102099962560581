import { backendApi } from "../api";
import { axios } from "../../general/Network";
import {
  docGetTag,
  docListTags,
  docPatchTag,
  docGetTagMultipleList,
} from "./utils";
import {
  CustomerInvoice,
  CustomerInvoiceWithGetList,
  CustomerInvoiceWithPermissions,
} from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";

export const getCustomerInvoicePdf = (id: string) => {
  return axios.get(`/customerinvoices/${id}/pdf`, {
    responseType: "blob",
  });
};
const CACHE_TAG = "CustomerInvoices";

export const customerInvoiceApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerInvoices: build.query<
      { data: CustomerInvoiceWithGetList[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/customerinvoices", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getCustomerInvoice: build.query<
      CustomerInvoiceWithPermissions,
      string | undefined
    >({
      query: (id) => ({ url: `/customerinvoices/${id}` }),
      transformResponse: (res) => {
        // Bug not firing
        // console.log("transformResponse");
        return res.data;
      },
      providesTags: docGetTag(CACHE_TAG),
    }),
    emailCustomerInvoice: build.mutation<
      any,
      Partial<CustomerInvoice> & { id: string }
    >({
      query: (id) => ({ url: `/customerinvoices/${id}/email`, method: "PUT" }),
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
    bookPlannedInvoice: build.mutation<
      any,
      Partial<CustomerInvoice> & { id: string }
    >({
      query: ({ id }) => ({
        url: `/customerinvoices/${id}/book`,
        method: "PUT",
      }),
      invalidatesTags: docGetTagMultipleList(
        CACHE_TAG,
        CACHE_TAG,
        "Contracts",
        "Invoiceplans",
      ),
    }),
    getCustomerInvoicesTotalAndSumThisYear: build.query<any, any>({
      query: (params) => ({
        url: "/customerinvoices/total-and-sum-this-year",
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: docGetTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const {
  useEmailCustomerInvoiceMutation,
  useGetCustomerInvoicesQuery,
  useGetCustomerInvoiceQuery,
  useBookPlannedInvoiceMutation,
  useGetCustomerInvoicesTotalAndSumThisYearQuery,
} = customerInvoiceApi;

export default customerInvoiceApi;
