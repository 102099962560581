/**
 *Created by Mikael Lindahl on 2023-12-08
 */

import {
  Routes as RoutesReactRouterDom,
  Route,
  Outlet,
  createBrowserRouter,
} from "react-router-dom";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import Documentation from "../screens/Documentation/Documentation";
import DocumentationExternal from "../screens/DocumentationExternal/DocumentationExternal";
import Login from "../screens/Login/Login";
import NewPassword from "../screens/NewPassword/NewPassword";
import NotFound from "../components/Screens/NotFound/NotFound";
import RouteElementWrapper from "../components/Basic/Simple/Routes/RouteElementWrapper";
import RoutesApp from "./App/RoutesApp";
import SendContractOfferResponse from "src/screens/Respond/ContractOffer/SendContractOfferResponse";
import TranslationProvider from "src/context/TranslationProvider";

const Layout = () => {
  return (
    <TranslationProvider>
      <Outlet />
    </TranslationProvider>
  );
};

const Routes = () => {
  const { isAuth, user } = useAppSelector((state) => state.user);

  return (
    <RoutesReactRouterDom>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <RouteElementWrapper
              isRedirect={true}
              redirectPath={"/app/dashboard"}
            />
          }
        />
        <Route
          path="/app/respond/contract-offer/:id"
          element={<SendContractOfferResponse />}
        />
        <Route
          path="/app/*"
          element={
            <RouteElementWrapper
              element={<RoutesApp />}
              isRedirect={!isAuth || !user?.active}
              isUserActive={user?.active}
              redirectPath={"/login"}
            />
          }
        />
        <Route
          path="/documentation/*"
          element={
            <RouteElementWrapper
              element={<Documentation />}
              isRedirect={!isAuth}
              redirectPath={"/login"}
            />
          }
        />
        <Route
          path="/documentation-external/*"
          element={
            <RouteElementWrapper
              element={<DocumentationExternal />}
              isRedirect={!isAuth}
              redirectPath={"/login"}
            />
          }
        />
        <Route
          path="/login/*"
          element={
            <RouteElementWrapper
              element={<Login />}
              isHardRefresh={true} // To make sure to retrieve new jwttoken for login user
              isRedirect={isAuth && user?.active}
              redirectPath={"/app/dashboard"}
            />
          }
        />
        <Route path="/newpassword/*" element={<NewPassword />} />
        <Route path="/*" element={<NotFound />} />
      </Route>
    </RoutesReactRouterDom>
  );
};

export const router = createBrowserRouter([{ path: "*", element: <Routes /> }]);
