import {
  appliedToSections,
  CustomField,
  fieldTypes,
} from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import { helperDuplicatedElement } from "src/utils/helpersBuilderTableStructure/helperDuplicatedElement";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import getValue from "src/components/Builders/Container/Utils/getValue";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  // We limit number of custom fields for section - this help to check it
  availableSections: { contract: boolean; offer: boolean };
  names: string[];
};

export const getStructure: UseFormContainerGetStructure<
  Partial<CustomField>,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<Partial<CustomField>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Name",
        gridProps: { md: 6 },
        validate: (props: any) => {
          return (
            props.data?.name?.length >= 0 &&
            !extraProps?.names?.includes(props.data?.name.toLowerCase().trim())
          );
        },
        getErrorText: () => "Name already in use. Please select another one",
        setFormData,
      },
      {
        itemType: "info",
        type: "selector",
        required: true,
        dataName: "fieldType",
        label: "Field type",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: fieldTypes.map((value) => ({ value })),
          label: (s) => t(s.value),
          value: "value",
        }),
        noCapitalize: true,
        setFormData,
      },
      {
        itemType: "info",
        type: "selector",
        required: true,
        dataName: "appliedToSections",
        label: "Applied to",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: appliedToSections.map((s) => ({
            value: String(s),
            isActive: extraProps?.availableSections[s],
          })),
          label: (s) => t(s.value),
          value: "value",
        }),
        selectionOptions: { multiple: true },
        noCapitalize: true,
        setFormData,
      },
      {
        itemType: "info",
        type: "text_input",
        required: true,
        dataName: "selectOptionValues",
        label: "Select option values",
        gridProps: { md: 6 },
        showWhen: (props) => props.data?.fieldType === "list",
        validate: (props) => {
          const value = getValue(props);
          if (value) {
            return helperDuplicatedElement(value).length <= 0;
          } else {
            return true;
          }
        },
        getErrorText: (props) => {
          const value = getValue(props);
          const duplicates = helperDuplicatedElement(value);
          return `Duplicated elements found: ${duplicates?.join(", ")}`;
        },
        setFormDataCustom: (props) => {
          const newData = {
            ...props.data,
            selectOptionValues: props.value
              ?.split(",")
              .map((item: string) => item.trim()),
          };

          setFormData(newData);
        },
      },
      {
        type: "switch",
        dataName: "required",
        label: "Required",
        gridProps: { md: 12 },
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<CustomField>;
  initData?: Partial<CustomField>;
}) => {
  if (data === undefined) {
    return data;
  }
  const partialData = clone<Partial<CustomField>>(data);

  const submitData = {
    ...toUpdateData({ data: partialData, initData }),
    name: partialData.name,
  };

  return submitData;
};
