/**
 *Created by Mikael Lindahl on 2024-09-11
 */
import i18next from "i18next";

const determineCurrencyPosition: (currencyCode: string) => {
  position: "start" | "end";
  adornment: "startAdornment" | "endAdornment";
  symbol: string;
} = (currencyCode) => {
  const locale = currencyCode === "SEK" ? "sv-SE" : i18next.language || "sv-SE";

  const numberFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
  });

  // Format the number
  const formatted = numberFormat.format(1);

  // Determine the position of the currency symbol
  const symbol =
    numberFormat?.formatToParts(0)?.find((part) => part?.type === "currency")
      ?.value || "kr";

  return {
    symbol,
    position: formatted.startsWith(symbol) ? "start" : "end",
    adornment: formatted.startsWith(symbol) ? "startAdornment" : "endAdornment",
  };
};

export default determineCurrencyPosition;
