import { backendApi } from "../api";
import { listTag } from "./utils";

const CACHE_TAG = "Documentation";
export const documentationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentation: build.query<{ data?: any; metadata?: any }, any>({
      query: (params) => ({ url: "/superadmin/documentation-json", params }),
      providesTags: listTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const { useGetDocumentationQuery } = documentationApi;

export default documentationApi;
