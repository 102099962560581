import { createContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import InsightReport from "./Report";
import Tabs from "src/components/Basic/Mixed/Tabs/Tabs";
import { ReportTemplate } from "src/accurasee-backend-types/app/report_template/report_template.types";
import { escape } from "src/utils/translate";
import {
  useGetBudgetReportNestedDataQuery,
  useGetBudgetReportsQuery,
  useGetReportNestedDataQuery,
  useGetReportsQuery,
} from "src/redux/services/ReportService";

export const ReportContext = createContext<{
  isBudgetReport: boolean;
  useGetDataQuery: typeof useGetReportsQuery | typeof useGetBudgetReportsQuery;
  useGetNestedDataQuery:
    | typeof useGetReportNestedDataQuery
    | typeof useGetBudgetReportNestedDataQuery;
}>({
  isBudgetReport: false,
  useGetDataQuery: useGetReportsQuery,
  useGetNestedDataQuery: useGetReportNestedDataQuery,
});

const InsightReportsList = ({
  reportTemplates,
  isBudgetReport = false,
}: {
  reportTemplates: ReportTemplate[];
  isBudgetReport?: boolean;
}) => {
  const baseFilter: Partial<ReportGetRequestQuery> = {
    // Default is this fiscal year, for ex. from 1.1.2023 - today's date
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    resolution: "month",
  };

  return (
    <ReportContext.Provider
      value={{
        isBudgetReport,
        useGetDataQuery: isBudgetReport
          ? useGetBudgetReportsQuery
          : useGetReportsQuery,
        useGetNestedDataQuery: isBudgetReport
          ? useGetBudgetReportNestedDataQuery
          : useGetReportNestedDataQuery,
      }}
    >
      {reportTemplates.length === 0 ? (
        <Typography>
          There are no reports available. Please{" "}
          <Link
            component={RouterLink}
            variant="inherit"
            to="/app/setup/reports"
          >
            create a new report
          </Link>
          .
        </Typography>
      ) : (
        <Tabs
          name="reports-list"
          tabs={reportTemplates.map((reportTemplate) =>
            escape(reportTemplate.name),
          )}
          tabContainerSx={{ marginTop: 0 }}
          tabPanelSx={(theme) => ({
            padding: `${theme.spacing(3)} 0`,
          })}
        >
          {reportTemplates.map((reportTemplate) => (
            <InsightReport
              key={reportTemplate.name}
              reportTemplateId={String(reportTemplate._id)}
              reportTemplateName={reportTemplate.name}
              baseFilter={baseFilter}
            />
          ))}
        </Tabs>
      )}
    </ReportContext.Provider>
  );
};

export default InsightReportsList;
