/**
 *Created by Mikael Lindahl on 2024-09-11
 */

export const EMAIL_LINEBREAK = "<br />";

export const RETAINED_FUNDS_ROW_RATIO = 0.1;
export const RETAINED_FUNDS_TOTAL_RATIO = 0.05;

export const CO_INVOICE_ALLOWED_SAME_VALUES = ["", null, undefined, "-"];
