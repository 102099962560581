/**
 *Created by Mikael Lindahl on 2024-08-12
 */

import { SelectOption } from "./getSelectOptions";

const getIsInActiveWarning = (options: SelectOption[]) => {
  const filteredOptions = options?.filter((o) => o.isActive === false);
  return filteredOptions?.length > 0
    ? `Note: Selected ${
        filteredOptions?.map((o) => o.label).join(", ") || options[0].label
      } is not active`
    : "";
};

export default getIsInActiveWarning;
