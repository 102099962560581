/**
 * This is to get list of timer by separator, exp. 15mins, 30mins
 */

const getTimes = (separator = ["00", "30"]) => {
  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < separator.length; j++) {
      let time = i + ":" + separator[j];
      if (i < 10) {
        time = "0" + time;
      }
      times.push(time);
    }
  }
  return times;
};
export default getTimes;
