// import i18next from "i18next";

const getGroupDelimiter = (currencyCode: string | undefined): string => {
  // const locale = currencyCode === "SEK" ? "sv-SE" : i18next.language || "sv-SE";

  // const number = new Intl.NumberFormat(locale, {
  //   style: "currency",
  //   currency: currencyCode,
  //   maximumFractionDigits: 0,
  // }).format(1111);
  const group = " "; //number.replace(/1/g, "").split("").slice(0, 1).join("");

  return group;
};

export default getGroupDelimiter;
