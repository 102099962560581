import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormTable from "src/hooks/useFormTable";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import {
  getStructure,
  GetStructureExtraProps,
  getVouchersStructure,
  toData,
  toSubmitData,
} from "./POCSettingsStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetAccountsQuery } from "src/redux/services/AccountService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  DebitAndCredit,
  PocSettings,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import {
  useGetCompanyConfigQuery,
  useUpdateCompanyConfigsMutation,
} from "src/redux/services/CompanyConfigsService";

const POCSettings = () => {
  const { data: accountsQuery, isLoading } = useGetAccountsQuery(undefined);
  const accounts = accountsQuery?.data || [];

  const { data: companyConfigQuery, isLoading: isCompanyLoading } =
    useGetCompanyConfigQuery(undefined);
  const accountingSettings = companyConfigQuery?.data?.accountingSettings || {};
  const pocSettings: Partial<PocSettings> =
    accountingSettings?.pocSettings || {};

  const [updateCompanyConfig] = useUpdateCompanyConfigsMutation();

  // For Voucher section
  const initialVoucherFormData = {
    voucherSeries: pocSettings?.voucherSeries || "",
    voucherDescription: pocSettings?.voucherDescription || "",
  };
  const {
    formData: voucherFormData,
    isFormValid: isVoucherFormValid,
    setFormData: setVoucherFormData,
    structure: voucherStructure,
  } = useFormContainer<Partial<PocSettings>>({
    getStructure: getVouchersStructure,
    initialFormData: initialVoucherFormData,
  });
  const commonPropsBuildItem: any = { data: voucherFormData };

  // For Table
  const initialFormData = toData({ data: pocSettings as PocSettings });
  const {
    formData,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    helperText,
    structure,
  } = useFormTable<DebitAndCredit & { name: string }, GetStructureExtraProps>({
    getStructure,
    initialFormData,
    extraProps: { accounts },
  });

  const submitData = toSubmitData({ data: formData });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit<PocSettings>({
    submitProps: {
      apiMutations: { update: updateCompanyConfig },
      data: {
        update: {
          accountingSettings: {
            ...accountingSettings,
            pocSettings: { ...submitData, ...voucherFormData },
          },
        },
      },
      dataId: String(companyConfigQuery?.data?._id),
      name: "POC Settings",
    },
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<DebitAndCredit & { name: string }>({
      data: formData,
      item,
      helperText,
    }),
  );
  const { options } = useMUIDataTableOptions({ pagination: false });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        isLoading={isLoading || isCompanyLoading}
        label="Percentage of completion accounting settings"
        subLabel="Edit accounts for adjustments related to POC"
        rightBoxArea={
          <ButtonGroup>
            <ButtonClear
              id={"poc-clear-button"}
              onClick={() => {
                setFormData(initialFormData);
                setVoucherFormData(initialVoucherFormData);
              }}
            />
            <ButtonUpdate
              id={"poc-update-button"}
              initialSubmitData={{
                ...pocSettings,
                ...initialVoucherFormData,
              }}
              isValid={isFormValid && isVoucherFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={{ ...submitData, ...voucherFormData }}
            />
          </ButtonGroup>
        }
      >
        {BuildItems({
          items: voucherStructure.items,
          ...commonPropsBuildItem,
        })}
        <MuiDataTableWrapper
          tableType="compact"
          title=""
          data={formData || []}
          columns={columns}
          options={options}
        />
      </SectionTight>
    </form>
  );
};

export default POCSettings;
