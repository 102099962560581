/**
 *Created by Mikael Lindahl on 2023-03-16
 */

type BaseNestedObjectData = {
  data: any;
  key: string;
  separator?: string;
};

type GetNestedObjectData = BaseNestedObjectData;
type SetNestedObjectData = BaseNestedObjectData & { value: any };

export const getNestedObjectData = ({
  data,
  key,
  separator = ".",
}: GetNestedObjectData) => {
  const res = key.split(separator).reduce((p, c) => {
    return p?.[c];
  }, data);

  return res;
};

export const setNestedObjectData = ({
  data,
  key,
  value,
  separator = ".",
}: SetNestedObjectData) => {
  if (!data) {
    return;
  }

  const last = key.split(separator).slice(-1)[0];

  key.split(separator).reduce((p, c) => {
    if (!p[c] && c !== last) {
      p[c] = {};
    }
    if (c === last) {
      p[c] = value;
    }
    return p[c];
  }, data);
};
