/**
 *Created by Mikael Lindahl on 2024-02-29
 */
import { IntegrationImportName } from "src/accurasee-backend-types/app/integrations/integration.types";
import { IntegrationImportJobName } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { listToDic } from "../../../../../utils/transform";

export type ImportSetup = {
  name: IntegrationImportName;
  integrationImportJobName: IntegrationImportJobName;
};

const useIntegrationImportSetups = () => {
  const importSetups: ImportSetup[] = [
    {
      name: "Accounts",
      integrationImportJobName: "import_accounts",
    },
    {
      name: "Articles",
      integrationImportJobName: "import_articles",
    },
    {
      name: "Attachments",
      integrationImportJobName: "import_attachments",
    },
    {
      name: "Company info",
      integrationImportJobName: "import_company",
    },
    {
      name: "Currencies",
      integrationImportJobName: "import_currencies",
    },
    {
      name: "Customer Invoices",
      integrationImportJobName: "import_customer_invoices",
    },
    {
      name: "Customers",
      integrationImportJobName: "import_customers",
    },
    {
      name: "Dimensions",
      integrationImportJobName: "import_dimensions",
    },
    {
      name: "Fiscal years",
      integrationImportJobName: "import_fiscal_years",
    },
    {
      name: "Projects",
      integrationImportJobName: "import_projects",
    },
    {
      name: "Supplier Invoices",
      integrationImportJobName: "import_supplier_invoices",
    },
    {
      name: "Suppliers",
      integrationImportJobName: "import_suppliers",
    },
    {
      name: "Terms of payments",
      integrationImportJobName: "import_terms_of_payments",
    },
    {
      name: "Units",
      integrationImportJobName: "import_units",
    },
    {
      name: "Vouchers",
      integrationImportJobName: "import_vouchers",
    },
  ];

  const nameTypeToPretty: any = listToDic(
    importSetups,
    (s) => s.integrationImportJobName,
  );
  nameTypeToPretty.import_all = { name: "All" };

  return { importSetups, nameTypeToPretty };
};

export default useIntegrationImportSetups;
