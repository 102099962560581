/**
 *Created by Mikael Lindahl on 2023-03-22
 */

import Box from "@mui/material/Box";
import FileChip from "src/components/Basic/Simple/Chips/FileChip";
import FormHelperText from "@mui/material/FormHelperText";
import getHelperText from "../Utils/getHelperText";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import setValue from "../Utils/setValue";
import useStyles from "./CommonStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import { DropzoneArea } from "material-ui-dropzone";
import { File as FileStorage } from "src/accurasee-backend-types/app/general/storage.types";
import { Grid, InputLabel } from "@mui/material";
import { useState } from "react";

export type FileStorageExtra = Partial<FileStorage> & {
  isDeleted?: boolean;
  file?: File;
  originalName: string;
};

type FileItemTProps = CommonBuilderContainerTypes;

const FileItem = (props: FileItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  const allFiles = getValue(props) as FileStorageExtra[];
  const existingFiles = allFiles?.filter((f) => !f.isDeleted);
  const [error, setError] = useState<string>("");
  const label = props.item?.label || "Attachment";

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel shrink className={classes.labelFieldStyle}>
        {t(label)}
      </InputLabel>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Box className={classes.dropzoneBox}>
          <DropzoneArea
            showAlerts={false}
            acceptedFiles={
              props.item?.filesAllowed || [
                "image/jpeg",
                "image/png",
                "image/bmp",
                "application/pdf",
              ]
            }
            onDropRejected={() => {
              setError("Invalid file type");
            }}
            onChange={(loadedFiles) => {
              if (
                props.item.fileLimit &&
                existingFiles.length > props.item.fileLimit
              ) {
                return setError(
                  t(
                    "File limit reached. Please remove files before adding new",
                  ),
                );
              } else {
                setError("");
              }

              if (loadedFiles.length === 0) {
                return;
              }

              const loadedFile = loadedFiles[0];
              const file = {
                originalName: loadedFile.name,
                mimeType: loadedFile.type,
                size: loadedFile.size,
                file: loadedFile,
              } as FileStorageExtra;

              let value: FileStorageExtra[];
              if (props.item.fileReplace) {
                value = [file];
              } else {
                value = [...existingFiles, file];
              }

              let changeProps = { ...commonCallbackProps, value };

              if (props.item.setFormDataCustom) {
                props.item.setFormDataCustom(changeProps);
              } else {
                setValue(changeProps);
              }
            }}
            maxFileSize={5000000}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{
              container: { spacing: 1, direction: "row" },
            }}
            previewGridClasses={{
              container: classes.previewGridContainer,
            }}
            dropzoneText={t("Attach new file")}
            previewChipProps={{
              color: "primary",
              classes: {
                root: classes.previewChip,
              },
            }}
            clearOnUnmount={true} // Clear file loader on new file
            filesLimit={1} // Keep file loader memory to one (storing files in data instead)
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", paddingTop: "1rem" }}>
        <Box className={classes.attachmentsBox}>
          {allFiles &&
            allFiles?.map((file, index) =>
              !file.isDeleted ? (
                <FileChip
                  key={index}
                  index={index}
                  allFiles={allFiles}
                  file={file}
                  fileObjectId={props?.data?._id}
                  onDelete={() => {
                    let newFiles = [...allFiles];

                    if (newFiles[index]._id) {
                      // Exists in backend, need to be removed there

                      newFiles[index] = {
                        ...newFiles[index],
                        isDeleted: true,
                      };
                    } else {
                      // Else remove
                      newFiles.splice(index, 1);
                    }

                    let changeProps = {
                      ...commonCallbackProps,
                      value: newFiles,
                    };
                    if (props.item.setFormDataCustom) {
                      props.item.setFormDataCustom(changeProps);
                    } else {
                      setValue(changeProps);
                    }
                  }}
                />
              ) : (
                <div key={index}></div>
              ),
            )}
        </Box>
        <FormHelperText
          sx={{
            marginLeft: 0,
            color: "red !important",
            textAlign: "center",
          }}
        >
          {error !== "" ? error : getHelperText(getHelperTextProps)}
        </FormHelperText>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default FileItem;
