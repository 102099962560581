import { Account } from "src/accurasee-backend-types/app/account/account.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import clone from "src/utils/clone";
import Constants from "src/constants/Constants";
import getSelectOptions from "src/utils/getSelectOptions";
import {
  DebitAndCredit,
  PocSettings,
} from "src/accurasee-backend-types/app/company_config/company_config.types";

export type GetStructureExtraProps = {
  accounts: Account[];
};

export const getVouchersStructure: UseFormContainerGetStructure<
  Partial<PocSettings>
> = ({ setFormData }) => {
  const structure: StructureContainer<Partial<PocSettings>> = {
    items: [
      {
        type: "text_input",
        dataName: "voucherSeries",
        label: "Voucher series",
        setFormData,
        gridProps: { md: 4 },
        required: true,
      },
      {
        type: "text_input",
        dataName: "voucherDescription",
        label: "Voucher description",
        setFormData,
        gridProps: { md: 8 },
        required: true,
      },
    ],
  };

  return structure;
};

export const getStructure: UserFormTableGetStructure<
  DebitAndCredit & { name: string },
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: "Name",
        getValue: ({ data, rowIndex }) => t(data[rowIndex].name),
      },
      {
        type: "autocomplete",
        dataName: "debitAccount",
        headerLabel: "Debit account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        required: true,
        setFormData,
      },
      {
        type: "autocomplete",
        dataName: "creditAccount",
        headerLabel: "Credit account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        required: true,
        setFormData,
      },
    ],
  };
};

export const toData = ({ data }: { data: PocSettings }) => {
  const newData = Constants.ACCOUNTING_SETTINGS.map((name) => {
    const res = data[name as keyof PocSettings] as DebitAndCredit;
    return {
      name,
      creditAccount: res?.creditAccount,
      debitAccount: res?.debitAccount,
    };
  });
  return newData;
};

export const toSubmitData = ({ data }: { data?: any }) => {
  if (!data) return;

  const newData = clone(data);

  let submitData: PocSettings = newData?.reduce(
    (resData: any, currentData: any) =>
      Object.assign(resData, {
        [currentData.name]: {
          creditAccount: currentData.creditAccount,
          debitAccount: currentData.debitAccount,
        },
      }),
    {},
  );

  return submitData;
};
