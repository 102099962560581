/**
 *Created by Mikael Lindahl on 2023-05-11
 */

function sort<T>(
  array: T[],
  fun?: (obj: T) => any,
  direction?: "asc" | "desc",
): T[] {
  if (!array) return [];

  array = [...array];

  return array.sort((a, b) => {
    const aValue = fun ? fun(a) : a;
    const bValue = fun ? fun(b) : b;

    if (aValue < bValue) {
      return direction === "asc" ? 1 : -1;
    }
    if (aValue > bValue) {
      return direction === "asc" ? -1 : 1;
    }
    return 0;
  });
}

export default sort;
