import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { CostWithPermissions } from "src/accurasee-backend-types/app/cost/cost.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import toCurrency from "src/utils/toCurrency";

export interface GetStructureExtraProps {
  articles: Article[];
}

const getStructure: UserFormTableGetStructure<
  CostWithPermissions,
  GetStructureExtraProps
> = ({ extraProps, t }) => {
  return {
    items: [
      {
        type: "text_with_optional_badge",
        dataName: "name",
        headerLabel: "Description",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          const isProcessing = data[rowIndex]?.incomplete;
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
        getValue: ({ data, rowIndex }) => {
          return data[rowIndex]?.costParent === "voucher"
            ? data[rowIndex]?.accountName
            : data[rowIndex]?.name;
        },
      },
      {
        type: "text",
        dataName: "quantity",
        headerLabel: "Quantity",
        getValue: ({ data, rowIndex }) => {
          const rowData = data[rowIndex];
          const { quantity, unitExternalId } = rowData;
          const value = quantity
            ? `${quantity} ${unitExternalId || ""}`.trim()
            : "";
          return value;
        },
      },
      {
        type: "text",
        dataName: "costPerUnit",
        headerLabel: "Cost per unit",
        filter: false,
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.costPerUnit;
          return value
            ? toCurrency({
                value,
                currencyCode: "SEK", //data[rowIndex]?.currencyCode,
              })
            : "-";
        },
      },
      {
        type: "text",
        dataName: "value",
        headerLabel: "Total cost",
        filter: false,
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.value || 0;
          return toCurrency({
            value,
            currencyCode: "SEK", //data[rowIndex]?.currencyCode,
          });
        },
      },
      {
        type: "text",
        dataName: "costType",
        headerLabel: "Type",
        filter: true,
        getValue: ({ data, rowIndex }) => {
          return t(data[rowIndex]?.costType || "");
        },
      },
      {
        type: "date",
        dataName: "transactionDate",
        headerLabel: "Report date",
      },
    ],
  };
};

export default getStructure;
