/**
 *Created by Mikael Lindahl on 2023-11-23
 */

import { Typography } from "@mui/material";

const BadgeGreen = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        fontSize: "17px",
        textTransform: "capitalize",
        backgroundColor: "var(--green-8)",
        color: "var(--green-2)",
        borderRadius: "4px",
        padding: "0 var(--space-8px)",
      }}
    >
      {text}
    </Typography>
  );
};

export default BadgeGreen;
