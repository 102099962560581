/**
 *Created by Mikael Lindahl on 2023-01-09
 */

import { Box } from "@mui/material";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import get from "../Utils/get";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import throwErrorDataName from "../Utils/throwErrorDataName";
import toCurrency from "src/utils/toCurrency";
import useStyles from "./CommonStyles";
import useTranslation from "../../../../hooks/useTranslationWrapper";
import VatWarningTooltip from "src/components/Basic/Simple/Tooltips/VatWarningTooltip";

type TextItemTProps = CommonBuilderContainerTypes & {
  isCurrency?: boolean;
};

const TextItem = (props: TextItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);
  const classes = useStyles();
  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "TextItem" });
  }

  let value = getValue(commonCallbackProps);

  const isCurrency =
    (props.item.currencyCode || props.data.currencyCode) && props.isCurrency;
  if (isCurrency) {
    value = toCurrency({
      value,
      currencyCode: props.item.currencyCode || props.data.currencyCode,
      toInteger: true, // not use decimal places for displaying data
    });
  }
  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel
        required={getRequired(commonCallbackProps)}
        shrink
        htmlFor={props.item.dataName}
        className={classes.labelFieldStyle}
      >
        {t(props.item.label)}
        {props.item.vatTypeTooltip && (
          <VatWarningTooltip {...props.item.vatTypeTooltip} />
        )}
      </InputLabel>
      <Box
        title={props.format ? props.format(value) : value || ""}
        sx={{
          display: "block",
          backgroundColor: "var(--green-9)",
          borderRadius: "4px",
          width: "100%",
          minHeight: "36px",
          overflow: "hidden",
          padding: "var(--space-12px) var(--space-16px)",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          ...get("sx", commonCallbackProps),
        }}
      >
        {props.format ? props.format(value) : value || "-"}
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

export default TextItem;
