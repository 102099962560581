import {
  LinkedInvoiceDataRequest,
  LinkedInvoiceGetListGroupedByPlannedInvoiceRowId,
} from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";

import { backendApi } from "../api";
import {
  docDeleteTags,
  docListTags,
  docPatchTag,
  docMultipleListTag,
} from "./utils";

const CACHE_TYPE = "LinkedInvoiceData";
export const linkedInvoiceDataApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getLinkedInvoiceDataGroupedByPlannedInvoiceRowIdList: build.query<
      {
        data: LinkedInvoiceGetListGroupedByPlannedInvoiceRowId[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({
        url: "/linkedinvoicedata/grouped-by-planned-invoice-row-id",
        params,
      }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getLinkedInvoiceData: build.query({
      query: (id) => ({ url: `/linkedinvoicedata/${id}` }),
      transformResponse: (response) => response.data,
    }),
    createLinkedInvoiceData: build.mutation<any, LinkedInvoiceDataRequest>({
      query: (data) => ({ url: "/linkedinvoicedata", method: "POST", data }),
      invalidatesTags: docMultipleListTag(CACHE_TYPE, "Costs"),
    }),
    patchLinkedInvoiceData: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/linkedinvoicedata/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deleteLinkedInvoiceData: build.mutation({
      query: (id) => ({ url: `/linkedinvoicedata/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
});

export const {
  useCreateLinkedInvoiceDataMutation,
  useGetLinkedInvoiceDataGroupedByPlannedInvoiceRowIdListQuery,
} = linkedInvoiceDataApi;

export default linkedInvoiceDataApi;
