import { LinkedInvoiceDataGetList } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { File as FileStorage } from "src/accurasee-backend-types/app/general/storage.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";

interface GetStructureExtraProps {
  onDownloadFiles: ({
    files,
    objectId,
  }: {
    files: FileStorage[];
    objectId: string;
  }) => void;
  contract: Contract;
}

const getStructure: UserFormTableGetStructure<
  LinkedInvoiceDataGetList,
  GetStructureExtraProps
> = ({ extraProps }) => ({
  items: [
    {
      dataName: "quantity",
      headerLabel: "Quantity",
      type: "text",
      getValue: ({ data, rowIndex }) => {
        const invoiceData = data[rowIndex];
        return invoiceData.quantity.toString() + " " + invoiceData.unit;
      },
    },
    {
      dataName: "pricePerUnit",
      headerLabel: "Price per unit",
      currencyCode: extraProps?.contract?.currencyCode,
      type: "text_currency",
    },
    {
      dataName: "date",
      headerLabel: "Report date",
      type: "date",
    },
    {
      dataName: "sourceName",
      headerLabel: "Source",
      type: "text",
    },
    {
      type: "icon_button",
      dataName: "files",
      headerLabel: "File",
      iconType: "download",
      showColumnWhen: ({ data }) =>
        data.some((lid) => (lid?.files?.length || 0) > 0),
      showCellWhen: ({ data, rowIndex }) => {
        const files = data[rowIndex].files || [];
        return files.length > 0;
      },
      onClick: ({ data, rowIndex }) => {
        const files = data[rowIndex].files || [];
        extraProps?.onDownloadFiles({
          files,
          objectId: String(data[rowIndex].supplierInvoiceId),
        });
      },
    },
    {
      type: "text_with_optional_badge",
      dataName: "incomplete",
      headerLabel: "",
      textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
        const isProcessing = data[rowIndex]?.incomplete;
        return {
          badgeText: isProcessing ? "incomplete" : "",
          iconType: isProcessing ? "incomplete" : "check_circle",
          showBadge: !!isProcessing,
        };
      },
      getValue: () => "",
    },
  ],
});
export default getStructure;
