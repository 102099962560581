import { backendApi } from "../api";
import { docGetTag, listTag, docListTags, docPatchTag } from "./utils";
import {
  Customer,
  CustomerWithTotals,
} from "src/accurasee-backend-types/app/customer/customer.types";

const CACHE_TYPE = "Customers";

const customerApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<{ data: Customer[]; metadata: any }, any>({
      query: (params) => ({ url: "/customers", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getCustomersTable: build.query<
      { data: CustomerWithTotals[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/customers/table", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),

    getCustomer: build.query<Customer, any>({
      query: (id) => ({ url: `/customers/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    updateCustomer: build.mutation<Customer, any>({
      query: ({ id, ...data }) => {
        return {
          url: `/customers/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    createCustomer: build.mutation<Customer, any>({
      query: (data) => ({ url: "/customers", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
  }),
});
export const {
  useCreateCustomerMutation,
  useGetCustomerQuery,
  useGetCustomersQuery,
  useGetCustomersTableQuery,
  useUpdateCustomerMutation,
} = customerApi;

export default customerApi;
