import { EUCOUNTRIES } from "src/constants/EuCountries";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { VATTypeType } from "src/accurasee-backend-types/app/general/global.types";

const getVatType = (customer: Customer | undefined) => {
  let country = customer?.countryCode || "SE";

  let vatType: VATTypeType;
  if (country === "SE") {
    vatType = customer?.reversedConstructionVAT
      ? "Reversed construction VAT liability"
      : "Regular VAT";
  } else if (EUCOUNTRIES.includes(country)) {
    vatType =
      customer?.customerType === "COMPANY"
        ? "Reversed EU tax liability"
        : "Regular VAT";
  } else {
    vatType = "International customer, no VAT";
  }
  return vatType;
};

export default getVatType;
