/**
 * This is to get a standard error message for missing data
 */

import { TFunction } from "src/hooks/useTranslationWrapper";

const getMissingDataMessage = (data: string, t?: TFunction) => {
  return t
    ? t(`No ${data} found in the database`)
    : `No ${data} found in the database`;
};

export default getMissingDataMessage;
