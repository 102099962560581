import { backendApi } from "../api";
import { docListTags, docPatchTag } from "./utils";

import { Notification } from "src/accurasee-backend-types/app/notification/notification.types";

const CACHE_TAG = "Notifications";

export const notificationsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    get: build.query<{ data: Notification[] }, any>({
      query: (params) => {
        return { url: "/notification", params };
      },
      providesTags: docListTags(CACHE_TAG),
    }),
    getPreview: build.query<{ data: Notification[] }, any>({
      query: (params) => {
        return { url: "/notification/preview", params };
      },
      providesTags: docListTags(CACHE_TAG),
    }),
    update: build.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/notification/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const { useGetQuery, useGetPreviewQuery, useUpdateMutation } =
  notificationsApi;

export default notificationsApi;
