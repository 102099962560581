/**
 *Created by Mikael Lindahl on 2023-03-15
 */

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { LinearProgress, SxProps } from "@mui/material";
import MessageBase from "../../Simple/Messages/MessageBase";

type CardSectionContainerTProps = {
  children?:
    | undefined
    | false
    | JSX.Element
    | (undefined | false | JSX.Element)[];
  columnSpacing?: number;
  error?: {
    title?: string;
    message?: string;
    subMessage?: string;
  };
  isError?: boolean;
  isLoading?: boolean;
  rowSpacing?: number;
  sx?: SxProps;
};
const SectionContainer = ({
  children,
  columnSpacing,
  isLoading,
  isError,
  error,
  rowSpacing,
  sx,
}: CardSectionContainerTProps) => {
  return isLoading ? (
    <Box paddingY={1}>
      <LinearProgress />
    </Box>
  ) : isError ? (
    <Box paddingY={1}>
      <MessageBase
        message={error?.message}
        subMessage={error?.subMessage}
        textAlign="center"
        title={error?.title}
        type="warning"
      />
    </Box>
  ) : (
    <Box sx={{ width: "100%", marginTop: "1rem", ...sx }}>
      <Grid
        container
        columnSpacing={columnSpacing || 2}
        rowSpacing={rowSpacing || 2}
      >
        {children}
      </Grid>
    </Box>
  );
};

export default SectionContainer;
