/**
 *Created by Mikael Lindahl on 2023-03-27
 */

import {
  PlannedInvoice,
  PlannedInvoiceWithPermissions,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { lessOrEqualThan, setFormDataDatePair } from "src/utils/date";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../../utils/getSelectOptions";
import getCustomerName from "../../../../utils/getCustomerName";

export type GetStructureExtraProps = {
  customers?: Customer[];
  users?: ReturnUser[];
};
export type PlannedInvoiceExtended = PlannedInvoiceWithPermissions & {};

export const getStructure: UseFormContainerGetStructure<
  PlannedInvoiceExtended,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  // @ts-ignore
  const structure: StructureContainer<PlannedInvoiceExtended> = {
    allowFields: ["contractArticles"],
    label: "Edit invoices",
    subLabel: "Invoices / Invoice",
    items: [
      {
        gridProps: { lg: 3 },
        dataName: "invoiceDate",
        label: "Invoice date",
        type: "date",
        validate: (props) => {
          return lessOrEqualThan(props.data?.invoiceDate, props.data?.dueDate);
        },
        getErrorText: "Invoice date can not come after due date",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "dueDate",
            otherAction: "to_end_of_month",
            value,
            valueKey: "invoiceDate",
            setFormData,
          });
        },
      },
      {
        gridProps: { lg: 3 },
        dataName: "dueDate",
        label: "Due date",
        type: "date",
        validate: (props) => {
          return lessOrEqualThan(props.data?.invoiceDate, props.data?.dueDate);
        },
        getErrorText: "Due date can not come before invoice date",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "invoiceDate",
            otherAction: "to_start_of_month",
            value,
            valueKey: "dueDate",
            setFormData,
          });
        },
      },
      {
        gridProps: { xs: 3 },
        type: "autocomplete",
        dataName: "customerId",
        label: "Customer",
        options: getSelectOptions({
          data: extraProps?.customers,
          label: (c) => getCustomerName(c),
          value: (c) => String(c._id),
        }),
        setFormData,
      },
      {
        gridProps: { xs: 3 },
        dataName: "contractName",
        label: "Contract",
        type: "text",
      },
      {
        gridProps: { xs: 4 },
        type: "autocomplete",
        dataName: "ourReference",
        label: "Our reference",
        options: getSelectOptions({
          data: extraProps?.users,
          label: (user) => `${user.firstName} ${user.lastName}`,
          value: (user) => `${user.firstName} ${user.lastName}`,
        }),
        setFormData,
      },
      {
        gridProps: { xs: 4 },
        type: "text_input",
        dataName: "yourReference",
        label: "Your reference",
        setFormData,
      },
      {
        gridProps: { xs: 4 },
        type: "text_input",
        dataName: "yourOrderNumber",
        label: "Your order number",
        setFormData,
      },
      {
        gridProps: { lg: 6 },
        type: "file",
        dataName: "files",
        label: "Attachment",
        filesAllowed: ["application/pdf"],
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: PlannedInvoiceExtended;
  initData?: PlannedInvoiceWithPermissions;
}) => {
  if (data === undefined || initData === undefined) {
    return data;
  }

  const submitData = clone<PlannedInvoiceExtended>(data);

  const partialData: Partial<PlannedInvoice> = toUpdateData({
    data: submitData,
    initData: initData,
  });

  return partialData;
};
