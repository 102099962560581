import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonClear from "../../../../components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "../../../../components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "../../../../components/Basic/Simple/Buttons/ButtonUpdate";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../../../components/Basic/Mixed/Sections/SectionWithButtonContainer";
import getStructure, { toSubmitData } from "./CronsStructure";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "../../../../hooks/useOnFormSubmit";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  useGetCronQuery,
  useUpdateCronMutation,
} from "../../../../redux/services/CronService";

const Crons = () => {
  const { data: cronsQuery, isLoading: isLoadingCrons } =
    useGetCronQuery(undefined);
  const crons = cronsQuery?.data || [];

  // const { getStructure, toSubmitData } = useLanguagesStructure();

  const [updateCrons] = useUpdateCronMutation();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormTable({
    initialFormData: crons,
    getStructure,
  });

  const { onFormSubmit, refForm, isSubmitting } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateCrons,
      },
      data: { update: toSubmitData(formData, crons) }, // Remove unchanged, deleted key-value(s) and added blank row
      name: "Cron",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: formData,
      helperText,
      item,
    }),
  );

  return (
    <>
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
      >
        <SectionContainer isLoading={isLoadingCrons}>
          <MuiDataTableWrapper
            data={formData}
            columns={columns}
            options={options}
            tableType="main-with-filter"
            noBackground={true}
          />
        </SectionContainer>
      </form>
      <SectionWithButtonContainer>
        <ButtonGroup>
          <ButtonClear
            onClick={() => {
              if (crons) {
                setFormData(crons);
                setHasTriedToSubmit(false);
              }
            }}
          />
          <ButtonUpdate
            initialSubmitData={crons}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={formData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </>
  );
};

export default Crons;
