import { createApi } from "@reduxjs/toolkit/query/react";
import { axios, backendBaseUrl } from "../general/Network";
import { SerializedError } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";

export type AxiosResponseError = {
  error: { status: any; data: any } | SerializedError;
};

const axiosBaseQuery =
  (axiosOptions: AxiosRequestConfig) =>
  async (requestOptions: AxiosRequestConfig) => {
    try {
      const result = await axios({
        ...axiosOptions,
        ...requestOptions,
      });
      return { data: result.data };
    } catch (axiosError: any) {
      let err = axiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const tagTypes = [
  "Accounts",
  "ActivityLogs",
  "Articles",
  "Attachments",
  "ApiKeys",
  "Budgets",
  "Company",
  "CompanyConfigs",
  "ContactPerson",
  "ContractOffers",
  "ContractTypes",
  "Contracts",
  "Crons",
  "Currencies",
  "CustomFields",
  "Customers",
  "Costs",
  "CustomerInvoices",
  "Dimensions",
  "Documentation",
  "DocumentationExternal",
  "EmailTemplates",
  "Files",
  "Fiscalyears",
  "Incomes",
  "Indices",
  "Invoiceplans",
  "InvoicePlanTemplates",
  "Integrations",
  "IntegrationImportJobs",
  "Invites",
  "Insights",
  "Languages",
  "LinkedInvoiceData",
  "MailgunLogs",
  "Notifications",
  "Overview",
  "Projects",
  "Reports",
  "BudgetReports",
  "ReportTemplates",
  "ReportTemplateYFilter",
  "Subcontracts",
  "Suppliers",
  "SupplierInvoices",
  "Permissions",
  "PlannedInvoices",
  "TermsOfPayments",
  "Translation",
  "Units",
  "User",
  "Users",
  "UserRoles",
  "Vouchers",
  "Warnings",
] as const;
export const backendApi = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseURL: backendBaseUrl,
  }),
  tagTypes,
  endpoints: () => ({}),
});
