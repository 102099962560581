/**
 *Created by Mikael Lindahl on 2023-10-18
 */

import { Box, Tab } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React, { useState } from "react";
import TabContainer from "./TabContainer";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useTranslation from "src/hooks/useTranslationWrapper";

export type TabsTProps = {
  children: JSX.Element | JSX.Element[];
  currentTab?: string;
  disabled?: boolean[];
  name: string;
  onChange?: () => void;
  tabContainerSx?: SxProps<Theme>;
  tabLabelSx?: SxProps<Theme>;
  tabPanelSx?: SxProps<Theme>;
  tabs: string[];
};

const Tabs = ({
  tabContainerSx = [],
  tabLabelSx = [],
  tabPanelSx = [],
  ...props
}: TabsTProps) => {
  const [t] = useTranslation();
  const [currentTab, setCurrentTab] = useState(props?.currentTab || "1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setCurrentTab(newValue);
    if (props?.onChange) {
      props.onChange();
    }
  };

  return (
    <TabContainer
      value={currentTab}
      sx={[{ marginTop: "1rem" }, ...[tabContainerSx].flat()]}
    >
      {[
        <Box
          key={"box"}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            "& .Mui-disabled": { backgroundColor: "var(--black-1)" },
            "& .MuiTabs-root .MuiTabs-scrollButtons:first-of-type": {
              display: "none",
            },
          }}
        >
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
          >
            {props.tabs.map((tab, index) => (
              <Tab
                key={`${props.name}-tab-${index + 1}`}
                label={t(tab)}
                value={`${index + 1}`}
                sx={[
                  {
                    borderRadius: "4px 4px 0 0",
                    color: "var(--gray-5)",
                    "&:hover": { backgroundColor: "var(--gray-2)" },
                    fontWeight: 600,
                    textTransform: "capitalize",
                    transition: "all 0.2s",
                  },
                  ...[tabLabelSx].flat(),
                ]}
                disabled={props?.disabled && props.disabled[index]}
              />
            ))}
          </TabList>
        </Box>,
        ...React.Children.toArray(props.children).map((child, index) => (
          <TabPanel
            sx={[{ padding: "0" }, ...[tabPanelSx].flat()]}
            key={index}
            value={`${index + 1}`}
          >
            {child}
          </TabPanel>
        )),
      ]}
    </TabContainer>
  );
};

export default Tabs;
