import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import NotFound from "src/components/Screens/NotFound/NotFound";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import _c from "src/constants/Constants";
import {
  getStructure,
  GetStructureExtraProps,
  toData,
  toSubmitData,
} from "./ArticleContainerStructure";
import {
  useCreateArticleMutation,
  useGetArticleQuery,
  usePatchArticleMutation,
} from "src/redux/services/ArticleService";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { escape } from "src/utils/translate";
import { unitApi } from "src/redux/services/UnitService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useParams } from "react-router-dom";
import ButtonBack from "src/components/Basic/Simple/Buttons/ButtonBack";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useConfirmLeave from "src/hooks/useConfirmLeave";
import isDisableSubmit from "src/utils/isDisableSubmit";

const ArticleContainer = () => {
  let { id: articleId } = useParams<{ id: string }>();

  const [createArticle] = useCreateArticleMutation();
  const [patchArticle] = usePatchArticleMutation();

  const {
    data: articleQuery,
    isLoading: isLoadingArticle,
    isError,
  } = useGetArticleQuery(articleId, {
    skip: !articleId,
  });

  const { data: units, isLoading: isLoadingUnits } =
    unitApi.useGetUnitsQuery(undefined);

  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const article =
    articleId && articleQuery ? articleQuery.data : _c.INITIAL_ARTICLE_DATA;
  const initialFormData = toData({ data: article });

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<Article>, GetStructureExtraProps>({
    getStructure,
    extraProps: {
      dimensions,
      units,
    },
    initialFormData,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createArticle,
        update: patchArticle,
      },
      data: {
        create: submitData,
        update: submitData,
      },
      dataId: articleId,
      name: "Article",
      rerouteUrlOnSuccess: {
        create: ({ responseData }) =>
          `/app/product-and-plans/articles/${responseData?._id}`,
      },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  useConfirmLeave({
    when: !isDisableSubmit({
      data: formData,
      initialData: initialFormData,
      isValid: isFormValid,
    }),
  });

  return (
    <Page
      label={!articleId ? "Create an article" : "Edit article"}
      isFullWidth={false}
      isLoading={isLoadingArticle || isLoadingDimensions || isLoadingUnits}
      breadcrumbs={[
        { label: "Products & Plans" },
        { label: "Articles", link: "/app/product-and-plans/articles" },
        {
          label: `${
            !articleId
              ? "Create an article"
              : `Edit article${escape(` #${article?.number || ""}`)}`
          }`,
        },
      ]}
    >
      {isError ? (
        <NotFound type="article_list" />
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit({ action: articleId ? "update" : "create" });
          }}
          ref={refForm}
        >
          <SectionContainer>
            <Section key={"article-Create-form"}>
              {BuildItems({
                items: structure.items,
                ...commonPropsBuildItem,
              })}
            </Section>
          </SectionContainer>

          <SectionWithButtonContainer>
            <ButtonBack />
            <ButtonGroup>
              <ButtonClear
                onClick={() => {
                  setFormData(article);
                  setHasTriedToSubmit(false);
                }}
              />
              <ButtonCreateOrUpdate
                initialSubmitData={initialFormData}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                label={articleId ? "update" : "create"}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={article?.permissions}
                submitData={formData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      )}
    </Page>
  );
};

export default ArticleContainer;
