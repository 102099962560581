import Section from "src/components/Basic/Simple/Sections/Section";

import { PlannedInvoiceExtended } from "../PlannedInvoiceContainerStructure";
import useFormTable from "src/hooks/useFormTable";
import { BillingBaseReturn } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import getTableStructure from "./RunningRateBillingDataTableStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import RunningRateBillingSubTable from "./RunningRateBillingSubTable";
import useGetArticles from "../../../../../hooks/useGetArticles";

type PlannedInvoiceFormTProps = {
  contract: GetSingleContractWithPermissions;
  runningRateData?: BillingBaseReturn[];
  plannedInvoice: PlannedInvoiceExtended;
};

export default function RunningRateBillingDataTable({
  contract,
  runningRateData,
  plannedInvoice,
}: PlannedInvoiceFormTProps) {
  const { articles, isLoading: isLoadingArticles } = useGetArticles(
    plannedInvoice?.contractId,
  );

  const { structure: runningRateTableStructure } =
    useFormTable<BillingBaseReturn>({
      initialFormData: runningRateData,
      extraProps: {
        articles,
        plannedInvoice,
      },
      getStructure: getTableStructure,
    });
  let columns: MUIDataTableColumnDef[] = runningRateTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle<BillingBaseReturn>({
        data: runningRateData || [],
        item,
      }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: false,
    serverSide: false,
    subtableFunction: (rowData: any, rowMeta: any) => {
      const invoiceData = runningRateData![rowMeta.dataIndex].invoiceData || [];

      return (
        <RunningRateBillingSubTable
          contract={contract}
          invoiceDataRows={invoiceData}
        />
      );
    },
  });

  const isRunningRate = (runningRateData?.length || 0) > 0;

  return (
    <>
      {isRunningRate && (
        <Section
          key={"Planned-invoice-data"}
          label={"PLANNED INVOICE DATA"}
          isLoading={isLoadingArticles}
          md={6}
          lg={6}
        >
          <MuiDataTableWrapper
            data={runningRateData || []}
            columns={columns}
            options={options}
            title=""
          />
        </Section>
      )}
    </>
  );
}
