/**
 *Created by Mikael Lindahl on 2023-10-25
 */

import Modal from "@mui/material/Modal";
import { Box, SxProps } from "@mui/material";
import { useStyles } from "./ModalWrapperStyles";
import React from "react";
import BoxHeading from "../Headings/BoxHeading";
import { SubLabelType } from "../Headings/BoxHeadingSubLabel";

type ModalWrapperTProps = {
  alignTextCenter?: boolean;
  boxStyle?: React.CSSProperties;
  children: JSX.Element | JSX.Element[] | string;
  handleCloseModal: () => void;
  label?: string;
  labelBadge?: JSX.Element;
  openModal: boolean;
  rightBoxArea?: JSX.Element | JSX.Element[];
  rightBoxAreaSx?: SxProps;
  subLabel?: string;
  subLabelType?: SubLabelType;
};

const ModalWrapper = (props: ModalWrapperTProps) => {
  const classes = useStyles();

  return (
    <Modal
      open={props.openModal}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box className={classes.modalStyle} style={props.boxStyle}>
        {props.label && (
          <BoxHeading
            alignTextCenter={
              props.alignTextCenter !== undefined ? props.alignTextCenter : true
            }
            forModal={true}
            label={props.label}
            labelBadge={props.labelBadge}
            rightBoxArea={props.rightBoxArea}
            rightBoxAreaSx={props.rightBoxAreaSx}
            subLabel={props.subLabel}
            subLabelType={props.subLabelType}
          />
        )}
        {props.children}
      </Box>
    </Modal>
  );
};

export default ModalWrapper;
