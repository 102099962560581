import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import getStructure from "./NotificationsStructure";
import useFormTable from "src/hooks/useFormTable";
import { LinearProgress } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { Notification } from "src/accurasee-backend-types/app/notification/notification.types";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  useGetQuery,
  useUpdateMutation,
} from "src/redux/services/NotificationsService";

const NotificationList = () => {
  const { data: notificationRaw, isLoading: isLoadingNotification } =
    useGetQuery(undefined);
  const notifications = notificationRaw?.data || [];
  const [update] = useUpdateMutation();

  const { structure } = useFormTable<Notification>({
    initialFormData: notifications,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: notifications,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: notifications?.length >= 10,
    filterOptions: {
      selectableRows: "none",
      clickFunction: async (e: any) => {
        const { data, rowIndex } = e[0].props;
        const newData = { id: data[rowIndex]._id, read: true };
        await update(newData);
      },
    },
  });

  if (isLoadingNotification) {
    return <LinearProgress />;
  }

  return (
    <SectionContainer>
      <MuiDataTableWrapper
        title=""
        data={notifications}
        columns={columns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
    </SectionContainer>
  );
};

export default NotificationList;
