/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import { useEffect, useState } from "react";
import {
  BuilderContainerCallbackProps,
  HelperText,
  StructureContainer,
  StructureItemContainer,
} from "../components/Builders/Container/CommonBuilderContainerTypes";
import getValue from "../components/Builders/Container/Utils/getValue";
import getShowWhen from "../components/Builders/Container/Utils/getShowWhen";

import getRequired from "../components/Builders/Container/Utils/getRequired";
import { setNestedObjectData } from "../utils/nestedData";
import _ from "lodash";
import { validDate } from "../utils/date";
import useTranslationWrapper, { TFunction } from "./useTranslationWrapper";
import { FormGlue } from "./useFormGlue";
import validNumber from "src/utils/validNumber";
import validUrl from "src/utils/validUrl";
import isRequired from "../utils/isRequired";

export function flattenItems<DataType = any>({
  items,
}: {
  items: StructureItemContainer<DataType>[];
}) {
  const flattedItems = items.reduce((tot: any[], item: any) => {
    if (item.type === "object" && item.items) {
      item.items.forEach(
        (subItem: { showWhen?: ({ data }: any) => boolean }) => {
          subItem.showWhen = subItem.showWhen || item.showWhen;
        },
      );
      item.flattenName = item.name;
      tot = [...tot, ...flattenItems({ items: item.items })];
    } else {
      tot = [...tot, item];
    }
    return tot;
  }, []);

  return flattedItems;
}

export type UseFormContainerGetStructure<
  DataType,
  ExtraProps = any,
  ItemTypes = string,
> = ({
  extraProps,
  setFormData,
  t,
}: {
  extraProps?: ExtraProps;
  setFormData: (data: DataType) => void;
  t: TFunction;
}) => StructureContainer<DataType, ItemTypes>;

export type ToSubmitData<T> = ({
  data,
  initData,
}: {
  data?: T;
  initData?: T;
}) => Partial<T> | undefined;

function checkIfFormIsValid<
  DataType = any,
  StructureType extends { items: StructureItemContainer<DataType>[] } = any,
>({ structure, data }: { data?: DataType; structure: StructureType }) {
  if (!data) {
    return true;
  }

  const structureItems = flattenItems({ items: structure.items }).filter(
    (item) => {
      if (item.type === "object") {
        return false;
      }

      if (item.showWhen) {
        return getShowWhen({ data, item });
      } else {
        return true;
      }
    },
  );

  const res = structureItems.filter((item: any) => {
    const commonCallbackProps: BuilderContainerCallbackProps = {
      data,
      item,
    };

    const value = getValue(commonCallbackProps);

    const isValidDate = validDate(value, item.type);
    const isValidNumber = validNumber(value, item.type);
    const isValidUrl = validUrl(value, item.type);

    // Reminder isRequired thus not need to be check here to work
    // only in getErrorText
    if (
      !isValidDate ||
      !isValidNumber ||
      (!!value && !isValidUrl) ||
      (value !== undefined &&
        item.validate &&
        !item.validate(commonCallbackProps))
    ) {
      // If is not value then return true to that res has values. Below
      // if res has values then form is not valid
      return true;
    } else {
      return false;
    }
  });

  return res.length === 0 ? true : false;
}

function getErrorText(props: BuilderContainerCallbackProps) {
  return typeof props.item.getErrorText === "function"
    ? props.item.getErrorText(props)
    : props.item.getErrorText;
}

function getHelperText<
  DataType = any,
  StructureType extends { items: StructureItemContainer<DataType>[] } = any,
>({
  structure,
  data,
  hasTriedToSubmit,
}: {
  data?: DataType;
  structure: StructureType;
  hasTriedToSubmit: boolean;
}) {
  let helperText: HelperText = {};

  if (!data) {
    return helperText;
  }

  flattenItems({ items: structure.items }).forEach((item) => {
    const commonCallbackProps: BuilderContainerCallbackProps = {
      data,
      item,
    };

    if (item.type === "object") {
      return;
    }

    const showWhen = getShowWhen(commonCallbackProps);
    if (!showWhen) {
      return;
    }

    const value = getValue(commonCallbackProps);

    const isValidDate = validDate(value, item.type);
    const isValidNumber = validNumber(value, item.type);
    const isValidUrl = validUrl(value, item.type);
    let required;
    if (item.required) {
      required = getRequired(commonCallbackProps);
    }

    if (
      !isValidDate ||
      !isValidNumber ||
      (!!value && !isValidUrl) ||
      (required && isRequired(value) && hasTriedToSubmit) ||
      // Added data to params so ut can be used in validation
      // e.g. when one needs to check against another value
      (value !== undefined &&
        item.validate &&
        !item.validate(commonCallbackProps))
    ) {
      setNestedObjectData({
        data: helperText,
        key: item.dataName as string,
        value: !isValidNumber
          ? "Invalid number"
          : !isValidDate
            ? "Invalid date format"
            : !isValidUrl
              ? "Invalid url format"
              : required && isRequired(value) && hasTriedToSubmit
                ? "Required"
                : item.getErrorText
                  ? getErrorText(commonCallbackProps)
                  : "Error",
      });
    }
  });

  return helperText;
}

const useFormContainer = <
  DataType = any,
  ExtraProps = any,
  ItemsTypes = string,
>({
  extraProps,
  formGlue, // Use form glue to control submit of data from a parent component
  getStructure,
  isLoading, // Can be used to that form gets a last reload once all resources has been fetch. There might be helper text that depends on data in extraProps
  initialFormData,
  toSubmitData,
}: {
  extraProps?: ExtraProps;
  formGlue?: FormGlue;
  getStructure: UseFormContainerGetStructure<DataType, ExtraProps, ItemsTypes>;
  isLoading?: boolean;
  initialFormData?: DataType;
  toSubmitData?: ToSubmitData<DataType>;
}) => {
  const [t] = useTranslationWrapper();
  const [formData, setFormDataInternal] = useState<DataType | undefined>(
    () => initialFormData || ({} as DataType),
  );
  const [_initialFormData, _setInitialFormData] = useState({
    ...(initialFormData || {}),
  });

  const [isValidatingData, setIsValidatingData] = useState(false);

  const setFormData = (state: DataType | undefined) => {
    setIsValidatingData(true);
    setFormDataInternal(state);
    formGlue?.setFormDataInternal(state);
  };

  // Add setFormData to formGlue
  if (formGlue) {
    formGlue.setFormData = setFormData;
  }

  const structure = getStructure({ extraProps, setFormData, t });

  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  // Only runs on first render when used with callback
  const [helperText, setHelperText] = useState(() =>
    getHelperText<DataType>({
      data: initialFormData,
      structure,
      hasTriedToSubmit,
    }),
  );

  // Only runs on first render when used with callback
  const [isFormValid, setIsFormValid] = useState(() => {
    const isValid = checkIfFormIsValid<DataType>({
      data: initialFormData,
      structure,
    });

    return isValid;
  });

  useEffect(() => {
    if (initialFormData && !_.isEqual(_initialFormData, initialFormData)) {
      setFormData({ ...initialFormData });
      setHelperText(
        getHelperText<DataType>({
          data: initialFormData,
          structure,
          hasTriedToSubmit,
        }),
      );
      _setInitialFormData(initialFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    setHelperText(
      getHelperText<DataType>({ data: formData, structure, hasTriedToSubmit }),
    );
    setIsFormValid(
      checkIfFormIsValid<DataType>({
        data: formData,
        structure,
      }),
    );
    setIsValidatingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, hasTriedToSubmit, isLoading]);

  useEffect(() => {
    if (formGlue && toSubmitData) {
      const submitData = toSubmitData({
        data: formData,
        initData: initialFormData,
      });

      formGlue.setSubmitData(submitData);
      formGlue.setSubmitDataInitial({}); // Always empty set for submit data on create and update container form
    }
    if (formGlue && !_.isEqual(formData, formGlue?.formData)) {
      formGlue.setFormDataInternal(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (formGlue) {
      formGlue.setIsFormValid(isFormValid);
    }
  }, [isFormValid]);

  const hasDataChanged = !_.isEqual(formData, initialFormData);

  return {
    formData,
    ...(formGlue
      ? { hasTriedToSubmit: formGlue.hasTriedToSubmit }
      : { hasTriedToSubmit }),
    helperText,
    isFormValid,
    isValidatingData,
    hasDataChanged,
    setFormData,
    setHelperText,
    ...(formGlue
      ? { setHasTriedToSubmit: formGlue.setHasTriedToSubmit }
      : { setHasTriedToSubmit }),
    setIsFormValid,
    structure,
  };
};

export default useFormContainer;
