/**
 *Created by Mikael Lindahl on 2022-11-21
 */

const getPriceTotalExcludingVatAndTotal = ({
  quantity,
  pricePerUnit,
  percentVAT,
  discount,
}: {
  quantity: number;
  pricePerUnit: number;
  percentVAT: number;
  discount: number;
}) => {
  const totalExcludingVAT =
    (pricePerUnit * quantity * (100 - (discount || 0))) / 100;
  const VAT = totalExcludingVAT * percentVAT;
  const total = totalExcludingVAT + VAT;

  return { totalExcludingVAT, total };
};

export default getPriceTotalExcludingVatAndTotal;
