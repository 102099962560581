import { UserFormTableGetStructure } from "src/hooks/useFormTable";

import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";

import _c from "src/constants/Constants";
import toDate from "src/utils/date";

export type GetStructureExtraProps = {
  articles: (Article | ContractArticle)[] | undefined;
  subcontract: SubcontractWithPermissions | undefined;
};

export const getStructure: UserFormTableGetStructure<
  InvoicePlanRow,
  GetStructureExtraProps
> = ({ extraProps }) => {
  const isMilestone = extraProps?.subcontract?.type === "milestone";

  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
      },
      {
        type: "text_with_optional_badge",
        dataName: "itemDescription",
        headerLabel: isMilestone ? "Milestone" : "Description",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          const isProcessing = data[rowIndex]?.incomplete;
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
        sx: {
          minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ITEM_DESCRIPTION,
        },
      },
      {
        type: "text",
        dataName: "articleId",
        headerLabel: "Article",
        getValue: ({ data, rowIndex }) => {
          const res = extraProps?.articles?.find(
            (a) => data[rowIndex].articleId === a._id,
          );
          return res ? `${res?.number} - ${res?.name}` : "";
        },
      },
      {
        type: "date_input",
        dataName: "estimatedInvoiceDate",
        headerLabel: "Est Completion",
        label: "EST Completion",
        defaultValue: new Date(),
        showColumnWhen: () => isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
      },
      {
        type: "text_currency",
        dataName: "quantity",
        headerLabel: "Quantity",
        showColumnWhen: () => !isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.subcontract?.currencyCode,
        dataName: "pricePerUnit",
        headerLabel: "Price",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
        showColumnWhen: !isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        alignColumnContent: "end",
      },
      {
        type: "number_input",
        dataName: "totalExcludingVAT",
        headerLabel: "Total excl. VAT",
        showColumnWhen: isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.subcontract?.currencyCode,
        dataName: "totalExcludingVAT",
        showColumnWhen: () => !isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "Subtotal",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "percentVAT",
        showColumnWhen: () => !isMilestone,
        getValue: ({ data, rowIndex }) =>
          `${data[rowIndex].percentVAT * 100} %`,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "VAT %",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.subcontract?.currencyCode,
        dataName: "totalVAT",
        getValue: ({ data, rowIndex }) =>
          (data[rowIndex].total || 0) - (data[rowIndex].totalExcludingVAT || 0),
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "VAT",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.subcontract?.currencyCode,
        dataName: "total",
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "Total",
        alignColumnContent: "end",
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        showColumnWhen: () => isMilestone,
        badgeAdditionalText: (props) => {
          return props.data[props.rowIndex].status === "exported"
            ? toDate(props.data[props.rowIndex].invoiceDate)
            : "";
        },
      },
    ],
  };
};
