import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import { setFormDataDatePair } from "src/utils/date";

const getStructure: UseFormContainerGetStructure<{
  startDate: Date;
  endDate: Date;
}> = ({ setFormData }) => {
  const structure: StructureContainer<{ startDate: Date; endDate: Date }> = {
    items: [
      {
        type: "date",
        dataName: "startDate",
        label: "From",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "endDate",
            otherAction: "to_end_of_month",
            value,
            valueKey: "startDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
          "& .MuiInputBase-input": { padding: "10px", height: "20px" },
        },
      },
      {
        type: "date",
        dataName: "endDate",
        label: "To",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "startDate",
            otherAction: "to_start_of_month",
            value,
            valueKey: "endDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
          "& .MuiInputBase-input": { padding: "10px", height: "20px" },
        },
      },
    ],
  };

  return structure;
};

export default getStructure;
