import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import EditorView from "./EditorView";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionHtml from "src/components/Basic/Mixed/Sections/SectionHtml";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import _c from "src/constants/Constants";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { EmailTemplate } from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";
import { EmailType } from "../../../../accurasee-backend-types/app/general/mailgun/mailgun.types";
import { Grid } from "@mui/material";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { useTranslation } from "react-i18next";
import getStructure, {
  GetStructureExtraProps,
  toSubmitData,
} from "./EmailTemplateModalStructure";
import {
  useGetEmailTemplateQuery,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useGetEmailTemplateHtmlQuery,
} from "src/redux/services/EmailTemplateService";
import MessageBase from "../../../../components/Basic/Simple/Messages/MessageBase";

export interface EmailTemplateModalProps {
  emailTemplateId?: string;
  fullTemplateEmailTypes: EmailType[];
  handleCloseModal: () => void;
  languages: Language[];
  openModal: boolean;
  templatesByEmailType?: Record<EmailType, EmailTemplate[]>;
}

const EmailTemplateModalWithForm = ({
  emailTemplateId,
  fullTemplateEmailTypes,
  handleCloseModal,
  languages,
  openModal,
  templatesByEmailType,
}: EmailTemplateModalProps) => {
  const [t] = useTranslation();
  const prettify = require("html-prettify");

  const [createEmailTemplate] = useCreateEmailTemplateMutation();
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
  const {
    data: emailTemplate,
    isLoading: isLoadingEmailTemplate,
    isError: isErrorLoadingTemplate,
  } = useGetEmailTemplateQuery(emailTemplateId, { skip: !emailTemplateId });

  const {
    data: emailTemplateHtml,
    isLoading: isLoadingEmailTemplateHtml,
    isError: isErrorLoadingTemplateHtml,
  } = useGetEmailTemplateHtmlQuery(emailTemplateId, { skip: !emailTemplateId });

  const initialFormData = emailTemplateId
    ? {
        ...(emailTemplate?.data || {}),
        ...(!isErrorLoadingTemplateHtml && emailTemplateHtml?.data?.html
          ? { html: emailTemplateHtml?.data?.html }
          : { html: "" }),
      }
    : _c.INITIAL_EMAIL_TEMPLATE_DATA;

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    Partial<EmailTemplate & { html: string }>,
    GetStructureExtraProps
  >({
    extraProps: {
      fullTemplateEmailTypes,
      emailTemplateId,
      templatesByEmailType,
      languages,
    },
    getStructure,
    initialFormData,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit<
    Partial<EmailTemplate>
  >({
    submitProps: {
      apiMutations: {
        create: createEmailTemplate,
        update: updateEmailTemplate,
      },
      data: {
        create: submitData,
        update: submitData,
      },
      name: "Email template",
      dataId: emailTemplateId || "",
    },
    onSuccess: () => {
      // clear form data
      setFormData(initialFormData);
      setHasTriedToSubmit(false);
      // close Add modal
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={emailTemplateId ? "EDIT EMAIL TEMPLATE" : "CREATE EMAIL TEMPLATE"}
    >
      <SectionContainer
        isLoading={isLoadingEmailTemplate && isLoadingEmailTemplateHtml}
        isError={isErrorLoadingTemplate}
        error={{
          title: t("Error"),
          message: t("Error loading email template"),
        }}
      >
        <Section xs={6}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await onFormSubmit({
                action: emailTemplateId ? "update" : "create",
              });
            }}
            ref={refForm}
          >
            <Grid container rowSpacing={2} columnSpacing={1}>
              {BuildItems({
                items: structure.items,
                ...commonPropsBuildItem,
              })}
            </Grid>
            {isErrorLoadingTemplateHtml && (
              <MessageBase
                title={t("Error")}
                message={t("Error loading email template html")}
                type={"warning"}
              />
            )}
          </form>
        </Section>

        <SectionHtml label={t("PREVIEW")} xs={6}>
          <EditorView
            html={formData?.html || ""}
            isError={isErrorLoadingTemplateHtml}
            isLoading={isLoadingEmailTemplateHtml}
          />
        </SectionHtml>
      </SectionContainer>
      <SectionWithButtonContainer
        sx={{ padding: "0 var(--space-28px) 0 var(--space-24px)" }}
      >
        <ButtonSecondary
          disableRipple
          onClick={() => {
            const newFormData = {
              ...formData,
              ...(formData?.html ? { html: prettify(formData.html) } : {}),
            };
            setFormData(newFormData);
          }}
          disabled={!formData?.html}
          id={"cancel-contract"}
        >
          {t("beatify")}
        </ButtonSecondary>
        <ButtonGroup>
          <ButtonCancel
            onClick={() => {
              setFormData(initialFormData);
              handleCloseModal();
            }}
          />
          <ButtonSubmitCore
            initialSubmitData={{ html: "" }}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            label={emailTemplateId ? "save" : "create"}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={submitData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};

export default EmailTemplateModalWithForm;
