import { Avatar, Box, Typography } from "@mui/material";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import useTranslation from "src/hooks/useTranslationWrapper";

const ProfileAvatar = () => {
  const [t] = useTranslation();

  const user = useAppSelector((state) => state.user.user)!;

  return (
    <Box
      sx={{
        columnGap: "var(--space-24px)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Avatar
        src={user.avatar}
        sx={{ backgroundColor: "var(--gray-5)", height: 100, width: 100 }}
      />
      <Box sx={{ alignContent: "center" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <Box sx={{ display: "flex", columnGap: "var(--space-8px)" }}>
          <Typography sx={{ fontSize: "14px", color: "var(--gray-5)" }}>
            {t("Email")}
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            {user.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileAvatar;
