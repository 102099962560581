import { MUIDataTableColumnDef } from "mui-datatables";

const getDefaultPersistTableId = ({
  columns,
}: {
  columns: MUIDataTableColumnDef[];
}) => {
  const columnNames = columns.map((column) =>
    typeof column === "string" ? column : column.name,
  );
  return columnNames;
};

export default getDefaultPersistTableId;
