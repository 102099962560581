import { MUIDataTableColumn } from "mui-datatables";
import DraggableRow from "./DraggableRow";
import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";

interface DraggableTableBodyProps {
  data: any;
  setData: (v: any) => void;
  columns: MUIDataTableColumn[];
}

const DraggableTableBody: React.FC<DraggableTableBodyProps> = ({
  data,
  setData,
  columns,
}) => {
  const [draggingRowIndex, setDraggingRowIndex] = useState<number | null>(null);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    setDraggingRowIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    if (draggingRowIndex === null || draggingRowIndex === index) return;

    const updatedData = [...data];
    const [draggedRow] = updatedData.splice(draggingRowIndex, 1);
    updatedData.splice(index, 0, draggedRow);

    setDraggingRowIndex(index);
    setData(updatedData);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    setDraggingRowIndex(null);
  };

  return (
    <TableBody>
      {data.map((row: string[], index: number) => (
        <DraggableRow
          key={index}
          row={row}
          index={index}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          columns={columns}
        />
      ))}
    </TableBody>
  );
};

export default DraggableTableBody;
