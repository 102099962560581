/**
 *Created by Mikael Lindahl on 2023-02-08
 */

function flattenObj(obj: any, parent?: any, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (
      typeof obj[key] == "object" &&
      !Array.isArray(obj[key]) &&
      JSON.stringify(obj[key]) !== "{}"
    ) {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }

  return res;
}

export default flattenObj;
