/**
 *Created by Mikael Lindahl on 2023-10-16
 */

import { getStructure } from "./ProjectTrackerRegionGetStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ProjectTrackerRegion } from "src/accurasee-backend-types/app/project_tracker/project_tracker.types";
import { userMUIDatSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

interface ProjectTrackerRegionSubSubTableProps {
  data: ProjectTrackerRegion[];
}

function ProjectTrackerRegionSubSubTable({
  data,
}: ProjectTrackerRegionSubSubTableProps) {
  const options = userMUIDatSubTableOptions();
  const structure = getStructure({ extraProps: { nameLabel: "Project" } });

  const ProjectTrackerRegionSubSubTableColumns: MUIDataTableColumnDef[] =
    structure.items.map((item) =>
      BuilderColumnOptionsSingle<ProjectTrackerRegion>({
        data: data,
        disabled: true,
        item,
      }),
    );

  return (
    <SubTableContainer
      backgroundColor="var(--black-3)"
      sx={{ padding: "10px 10px 10px 38px !important" }}
    >
      <MuiDataTableWrapper
        nameSpace={"sub-sub-table"}
        tableType={"main-nested-level-2"}
        title={""}
        data={data}
        columns={ProjectTrackerRegionSubSubTableColumns}
        options={options}
      />
    </SubTableContainer>
  );
}

export default ProjectTrackerRegionSubSubTable;
