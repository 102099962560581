/**
 *Created by Mikael Lindahl on 2022-12-15
 */

import BuildItemInternal from "./BuildItemInternal";
import {
  BuildItemCommonTProps,
  StructureItemContainer,
} from "./CommonBuilderContainerTypes";

export type BuildItemsTProps = {
  items: StructureItemContainer[];
} & BuildItemCommonTProps;

const BuildItems = ({ items, ...rest }: BuildItemsTProps) => {
  return (
    <>
      {items.map((item, index) => (
        <BuildItemInternal
          key={index}
          item={item}
          index={String(index)}
          {...rest}
        />
      ))}
    </>
  );
};

export default BuildItems;
