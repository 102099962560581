import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import InvoicesTotals from "src/components/Complex/Tables/InvoicesTotals";
import MilestoneInfoBox from "src/screens/App/Contracts/[contractId]/InvoicePlans/[invoicePlanId]/InvoicePlanRow/components/MilestoneInfoBox";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTable from "src/components/Basic/Simple/Sections/SectionForTable";
import useFormTable from "src/hooks/useFormTable";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Box, Grid } from "@mui/material";
import { InvoicePlanRow } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { toData } from "src/screens/App/Contracts/[contractId]/InvoicePlans/[invoicePlanId]/InvoicePlanRow/InvoicePlanRowsStructure";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  getStructure,
  GetStructureExtraProps,
} from "./SubcontractInvoiceRowsStructure";

interface SubcontractInvoiceRowsProps {
  articles?: (Article | ContractArticle)[];
  subcontract: SubcontractWithPermissions;
}

const SubcontractInvoiceRows = (props: SubcontractInvoiceRowsProps) => {
  const isMilestone = props?.subcontract?.type === "milestone";

  const data: InvoicePlanRow[] = toData({
    data: props?.subcontract.invoiceRows,
  });

  const { formData, helperText, structure } = useFormTable<
    InvoicePlanRow,
    GetStructureExtraProps
  >({
    extraProps: {
      articles: props.articles,
      subcontract: props.subcontract,
    },
    initialFormData: data,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<InvoicePlanRow>({
      data: formData,
      helperText,
      item,
      disabled: true,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionForTable
      label={isMilestone ? "milestones" : "import data"}
      rightBoxArea={
        props?.subcontract && isMilestone ? (
          <MilestoneInfoBox invoicePlan={props?.subcontract} />
        ) : (
          <></>
        )
      }
    >
      <MuiDataTableWrapper
        title={"Data table"}
        data={formData || []}
        columns={columns}
        options={options}
      />
      {props?.subcontract ? (
        <Grid item xs={12}>
          <Box
            sx={{
              margin: "0 var(--space-32px) var(--space-32px) auto",
              width: "fit-content",
            }}
          >
            <InvoicesTotals
              invoicePlan={props?.subcontract}
              invoiceRows={formData}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </SectionForTable>
  );
};

export default SubcontractInvoiceRows;
