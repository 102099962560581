import {
  CREATE_COMPANY,
  GET_COMPANY,
  UPDATE_COMPANY,
  INVITE_TO_COMPANY,
  SET_INTEGRATIONS,
  SET_API_KEYS,
  ADD_API_KEY,
} from "../actions/types";

const initialState = {
  company: {},
};

const companyReducer = (company = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_COMPANY:
      return { ...company, company: payload.company };
    case GET_COMPANY:
      return { ...company, company: payload.company };
    case UPDATE_COMPANY:
      return { ...company, company: payload.company };
    case INVITE_TO_COMPANY:
      return { ...company, company: payload.company };
    case SET_INTEGRATIONS:
      return { ...company, integrations: payload };
    case SET_API_KEYS:
      return { ...company, apiKeys: payload };
    case ADD_API_KEY:
      return { ...company, apiKeys: [...company.apiKeys, payload] };

    default:
      return company;
  }
};

export default companyReducer;
