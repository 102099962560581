import { Box, Fade, Typography, Link } from "@mui/material";
import {
  getStructure,
  RegisterUserInputProps,
} from "./RegisterUserGetStructure";
import { register as registerUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import isDisableSubmit from "src/utils/isDisableSubmit";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useLoginStyles from "../LoginStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface RegisterUserProps {
  setActiveTabId: (v: number) => void;
}

const RegisterUser = ({ setActiveTabId }: RegisterUserProps) => {
  const [t] = useTranslation();
  const classes = useLoginStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [errorRegister, setErrorRegister] = useState<"Failed" | null>(null);

  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get("inviteId");
  const nextUrl = queryParams.get("next") || location.state?.from;

  const initialFormData = {
    firstName: process.env.REACT_APP_DEFAULT_REGISTER_FIRST_NAME_VALUE || "",
    lastName: process.env.REACT_APP_DEFAULT_REGISTER_LAST_NAME_VALUE || "",
    email:
      process.env.REACT_APP_DEFAULT_REGISTER_EMAIL_VALUE ||
      queryParams.get("email") ||
      "",
    password: process.env.REACT_APP_DEFAULT_REGISTER_PASSWORD_VALUE || "",
  };

  const { formData, helperText, isFormValid, hasTriedToSubmit, structure } =
    useFormContainer<Partial<RegisterUserInputProps>>({
      getStructure,
      initialFormData,
    });

  const submitDisabled = isDisableSubmit({
    data: formData,
    initialData: {},
    isValid: isFormValid,
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
      }}
    >
      <Typography className={classes.title}>
        {t("Create a new account")}
      </Typography>
      <Typography className={classes.subTitle}>
        {t("Please provide your details")}
      </Typography>
      {inviteId ? (
        <Typography className={classes.subTitle}>
          {t("Your inviter is")}
          <Typography
            sx={{
              display: "inline",
              fontWeight: "bold",
              marginLeft: "var(--space-4px)",
            }}
          >
            {inviteId}
          </Typography>
        </Typography>
      ) : (
        <></>
      )}
      <SectionContainer>
        <Section boxStyle={{ padding: "var(--space-16px) 0" }}>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
        {errorRegister ? (
          <Section boxStyle={{ padding: "var(--space-16px) 0 0 0" }}>
            <Fade in={!!errorRegister}>
              <Typography color="error" className={classes.errorMessage}>
                {errorRegister}
              </Typography>
            </Fade>
          </Section>
        ) : (
          <></>
        )}
      </SectionContainer>
      <SectionWithButtonContainer>
        <ButtonPrimary
          disabled={submitDisabled && !hasTriedToSubmit}
          onClick={() => {
            if (
              !formData?.firstName ||
              !formData?.lastName ||
              !formData?.email ||
              !formData?.password
            )
              return;
            const { firstName, lastName, email, password } = formData;
            registerUser(
              firstName,
              lastName,
              email,
              password,
              inviteId === null ? inviteId : inviteId.replace(/"/g, ""),
            )(dispatch)
              .then((data) => {
                if (nextUrl) {
                  navigate(nextUrl);
                }
              })
              .catch((err) => {
                console.error(err);
                setErrorRegister("Failed");
              });
          }}
          className={classes.loginButton}
          type="submit"
        >
          {t("Create account")}
        </ButtonPrimary>
      </SectionWithButtonContainer>
      <Box
        sx={{
          display: "flex",
          columnGap: "var(--space-4px)",
          justifyContent: "center",
          marginTop: "var(--space-32px)",
        }}
      >
        <Typography className={classes.subTitle}>
          {t("Already have an account?")}
        </Typography>
        <Link
          style={{
            cursor: "pointer",
            display: "block",
            fontWeight: 500,
            textDecoration: "none",
          }}
          onClick={() => setActiveTabId(0)}
        >
          {t("Login now")}
        </Link>
      </Box>
    </form>
  );
};

export default RegisterUser;
