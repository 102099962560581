// loginStyles
import useStyles from "./styles";

// [contractTypeId]
import { Typography } from "../../Mixed/Wrappers/Wrappers";

function hashCode(str: string) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "#" + "00000".substring(0, 6 - c.length) + c;
}

const UserAvatar = ({ color = "primary", ...props }) => {
  var classes = useStyles();

  var letters = props.name
    .split(" ")
    .map((word: string) => word[0])
    .join("");

  return (
    <div
      className={classes.avatar}
      style={{ backgroundColor: intToRGB(hashCode(letters)) }}
    >
      <Typography className={classes.text}>{letters[0]}</Typography>
    </div>
  );
};

export default UserAvatar;
