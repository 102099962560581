import { backendApi } from "../api";
import { listTag } from "./utils";
import { Currency } from "../../accurasee-backend-types/app/currency/currency.types";

const CACHE_TAG = "Currencies";
export const currencyApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<{ data?: Currency[]; metadata?: any }, any>({
      query: (params) => ({ url: "/currency", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getCurrency: build.query({
      query: (id) => ({ url: `/currency/${id}` }),
    }),
  }),
});

export const { useGetCurrenciesQuery, useGetCurrencyQuery } = currencyApi;

export default currencyApi;
