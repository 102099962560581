import Cashflow from "../Graph/Cashflow";
import Page from "src/components/Basic/Mixed/Pages/Page";
import ResultYearToDate from "../Graph/ResultYearToDate";
import RevenueVsOperatingCosts from "../Graph/RevenueVsOperatingCosts";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";

const CompanyInsights = () => {
  return (
    <Page
      label="Company insights"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Company" }]}
    >
      <SectionContainer>
        <Cashflow />
        <RevenueVsOperatingCosts />
        <ResultYearToDate />
      </SectionContainer>
    </Page>
  );
};

export default CompanyInsights;
