import { backendApi } from "../api";
import { docGetTag, listTag } from "./utils";
import { Account } from "src/accurasee-backend-types/app/account/account.types";
const CACHE_TAG = "Accounts";
const accountsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<{ data: Account[]; metadata: any }, any>({
      query: (params) => ({ url: "/accounts", params }),
      // transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TAG),
    }),
    getAccount: build.query<{ data: Account }, any>({
      query: (id) => ({ url: `/accounts/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});
export const { useGetAccountsQuery, useGetAccountQuery } = accountsApi;
export default accountsApi;
