import { MUIDataTableColumn, MUIDataTableMeta } from "mui-datatables";
import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useStyles from "./DraggableTableStyles";

interface DraggableRowProps {
  row: any;
  index: number;
  onDragStart: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
  columns: MUIDataTableColumn[];
}

const DraggableRow = ({
  row,
  index,
  onDragStart,
  onDragOver,
  onDrop,
  columns,
}: DraggableRowProps) => {
  const classes = useStyles();

  return (
    <TableRow
      className={classes.tableRow}
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index)}
    >
      {columns.map((col, i) => {
        const value = row[col.name];

        // Define tableMeta object (simplified version for illustration)
        const tableMeta = {
          rowIndex: index,
          currentTableData: [],
          columnData: { name: col.name, label: col.label },
          columnIndex: 0,
        } as unknown as MUIDataTableMeta;

        const Element = col.options?.customBodyRender
          ? col.options?.customBodyRender(value, tableMeta, () => null)
          : value;
        return (
          <TableCell key={i} className={classes.tableRowCell}>
            {Element}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default DraggableRow;
