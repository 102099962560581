import { MUIDataTableColumn } from "mui-datatables";
import { Typography } from "@mui/material";
import DraggableTableBody from "./DraggableTableBody";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./DraggableTableStyles";

interface DraggableDataTableProps {
  columns: MUIDataTableColumn[];
  data: any;
  setData: (v: any) => void;
  title?: string;
}

const DraggableDataTable = ({
  data,
  setData,
  columns,
  title,
}: DraggableDataTableProps) => {
  const classes = useStyles();

  return (
    <>
      {title && (
        <Typography variant="h6" className={classes.tableTitle}>
          {title}
        </Typography>
      )}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, i: number) => (
                <TableCell
                  key={`th-${i}-${col.label}`}
                  className={classes.tableHeadRowCell}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DraggableTableBody data={data} setData={setData} columns={columns} />
        </Table>
      </TableContainer>
    </>
  );
};
export default DraggableDataTable;
