import { Fade, Typography, Link } from "@mui/material";
import { getStructure, LoginInputProps } from "./LoginRegularGetStructure";
import { login as loginUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import isDisableSubmit from "src/utils/isDisableSubmit";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useLoginStyles from "../LoginStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface LoginRegularProps {
  setActiveTabId: (v: number) => void;
}

const LoginRegular = ({ setActiveTabId }: LoginRegularProps) => {
  const [t] = useTranslation();
  const classes = useLoginStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [errorLogin, setErrorLogin] = useState<"Failed" | string | null>(
    location?.state?.isUserActive === false
      ? "Sorry, your account has been deactivated. For further assistance, please contact your administrator."
      : null,
  );

  const initialFormData = {
    loginEmail: process.env.REACT_APP_DEFAULT_LOGIN_EMAIL_VALUE || "",
    loginPassword: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD_VALUE || "",
  };

  const { formData, helperText, isFormValid, hasTriedToSubmit, structure } =
    useFormContainer<Partial<LoginInputProps>>({
      getStructure,
      initialFormData,
    });

  const submitDisabled = isDisableSubmit({
    data: formData,
    initialData: {},
    isValid: isFormValid,
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const handleSubmit = () => {
    if (!formData?.loginEmail || !formData?.loginPassword) return;
    const { loginEmail, loginPassword } = formData;
    loginUser(
      loginEmail,
      loginPassword,
    )(dispatch)
      .then((data) => {
        if (!data?.user?.active) {
          setErrorLogin(
            "Sorry, your account has been deactivated. For further assistance, please contact your administrator.",
          );
        }
      })
      .catch((err) => {
        const errorMessage = parseRestApiErrorMessage(err.response);
        console.error(err);
        setErrorLogin(errorMessage || "Failed");
      });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Typography className={classes.title}>{t("Welcome back!")}</Typography>
      <Typography className={classes.subTitle}>
        {t("Provide your email and password")}
      </Typography>
      <SectionContainer>
        <Section boxStyle={{ padding: "var(--space-16px) 0" }}>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
        {errorLogin ? (
          <Section boxStyle={{ padding: "var(--space-16px) 0 0 0" }}>
            <Fade in={!!errorLogin}>
              <Typography color="error" className={classes.errorMessage}>
                {errorLogin}
              </Typography>
            </Fade>
          </Section>
        ) : (
          <></>
        )}
        <Section boxStyle={{ padding: "var(--space-16px) 0" }}>
          <Link
            style={{
              cursor: "pointer",
              fontWeight: 500,
              margin: "var(--space-8px) auto",
              textDecoration: "none",
            }}
            onClick={() => setActiveTabId(2)}
          >
            {t("Forgot password?")}
          </Link>
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer>
        <ButtonPrimary
          disabled={submitDisabled && !hasTriedToSubmit}
          className={classes.loginButton}
          type="submit"
        >
          {t("Login")}
        </ButtonPrimary>
      </SectionWithButtonContainer>
    </form>
  );
};

export default LoginRegular;
