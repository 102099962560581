import PageBase, { PageBaseProps } from "./PageBase";

const PageWithTopPadding = (props: PageBaseProps) => {
  return (
    <PageBase headingSx={{ paddingTop: "108px" }} {...props}>
      {props.children}
    </PageBase>
  );
};

export default PageWithTopPadding;
