/**
 * This section has no toolbar
 * Table with fullwidth
 */

import SectionCore, {
  SectionCoreTProps,
} from "../../Mixed/Sections/SectionCore";

const SectionForTable = (props: SectionCoreTProps) => {
  return (
    <SectionCore
      boxHeadingStyle={{
        padding: "var(--space-32px)",
        paddingBottom: "var(--space-24px)",
      }}
      boxStyle={{ padding: 0 }}
      {...props}
    >
      {props.children}
    </SectionCore>
  );
};

export default SectionForTable;
