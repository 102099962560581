/**
 *Created by Mikael Lindahl on 2023-12-14
 */
import ConsentDialog from "../../Mixed/Dialogs/ConsentDialog";
import useTranslation from "src/hooks/useTranslationWrapper";
import { AccessPermissionType } from "../../../../accurasee-backend-types/app/access_role_permission/access_role_permission.types";

type NoPermissionDialogTProps = {
  open: boolean;
  onClose: () => void;
  permissionType?: AccessPermissionType;
};

const NoPermissionDialog = (props: NoPermissionDialogTProps) => {
  const [t] = useTranslation();

  let text = "";
  switch (props.permissionType) {
    case "readRights":
      text = "read rights";
      break;
    case "updateRights":
      text = "update rights";
      break;
    case "writeRights":
      text = "write rights";
      break;
    case "deleteRights":
      text = "delete rights";
      break;
    case "exportRights":
      text = "export rights";
      break;
  }

  return (
    <ConsentDialog
      consentButtons={[
        {
          label: "Close",
          onClick: props.onClose,
        },
      ]}
      maxWith={"sm"}
      open={props.open}
    >
      <>
        <p>
          {`${t("You do not have permission for this action")}${
            text ? ` ${t(`since you are missing ${text} for this data`)}` : ""
          }.`}
        </p>
        <p>{` ${t("Please contact your administrator")}.`}</p>
      </>
    </ConsentDialog>
  );
};

export default NoPermissionDialog;
