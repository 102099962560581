import { Types } from "mongoose";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";

export interface ActiveContract {
  contractId: Types.ObjectId;
  contractName: string;
  ownerIds: string[];
}

export interface ContractsByUser {
  id: Types.ObjectId;
  name: string;
  activeContracts: ActiveContract[];
}

export interface GetStructureExtraProps {
  setOpenModal: (v: boolean) => void;
  setModalData: (data: ContractsByUser) => void;
}

const getStructure: UserFormTableGetStructure<
  ContractsByUser,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "text",
      dataName: "name",
      headerLabel: "Name",
      sort: true,
      sx: { minWidth: 200 },
    },
    {
      type: "text",
      dataName: "activeContracts",
      headerLabel: "Active contracts",
      sort: true,
      sx: { minWidth: 200 },
      getValue: ({ data, rowIndex }) => {
        return data[rowIndex]?.activeContracts.length || "0";
      },
    },
    {
      type: "icon_button",
      iconType: "edit",
      dataName: "",
      showCellWhen: ({ data, rowIndex }) =>
        !!data[rowIndex]?.activeContracts.length,
      onClick: ({ rowIndex, data }) => {
        extraProps?.setOpenModal(true);
        extraProps?.setModalData(data[rowIndex]);
      },
    },
  ],
});

export default getStructure;
