import {
  InvoicePlanRow,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { InvoiceStatusType } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { usePatchInvoiceplanMutation } from "src/redux/services/InvoiceplanService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import useTranslation from "src/hooks/useTranslationWrapper";

const useMarkInvoiceRowsAsInvoiced = ({
  invoicePlan,
  selectedInvoiceRows,
}: {
  invoicePlan?: InvoicePlanWithPermissions;
  selectedInvoiceRows: InvoicePlanRow[];
}) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [patchInvoicePlan] = usePatchInvoiceplanMutation();

  const [isMarkAsInvoicedLoading, setIsMarkAsInvoicedLoading] = useState(false);

  const onMarkAsInvoiced = async () => {
    setIsMarkAsInvoicedLoading(true);

    const selectedInvoiceIds = selectedInvoiceRows?.map((row) => row?._id);

    // No rows found
    if (selectedInvoiceIds.length === 0) {
      console.error("No rows selected for marking as invoiced");
      setIsMarkAsInvoicedLoading(false);
      return enqueueSnackbar("No rows selected for marking as invoiced", {
        variant: "info",
      });
    }

    let newInvoiceRows;
    if (invoicePlan) {
      newInvoiceRows = [...invoicePlan?.invoiceRows]?.map((row) => {
        if (selectedInvoiceIds.includes(row._id)) {
          return { ...row, status: "exported" as InvoiceStatusType };
        }
        return row;
      });
    } else {
      console.error("Missing invoicePlan");
      return;
    }

    // To mark invoiceRow(s) as invoiced
    try {
      const result = await patchInvoicePlan({
        id: invoicePlan?._id,
        invoiceRows: newInvoiceRows,
      });

      if ("error" in result) {
        const defaultMsg = t("InvoiceRow(s) couldn't be mark as invoiced");
        const subMsg = parseRestApiErrorMessage(result.error);
        const msg = subMsg ? defaultMsg + ": " + subMsg : defaultMsg;

        enqueueSnackbar(msg, { variant: "error" });
      }

      setIsMarkAsInvoicedLoading(false);

      return enqueueSnackbar("InvoiceRow(s) marked as invoiced", {
        variant: "success",
      });
    } catch (error: any) {
      setIsMarkAsInvoicedLoading(false);
      return enqueueSnackbar(
        "Could not mark invoiceRow(s) as invoiced. Error: " + error.message,
        { variant: "error" },
      );
    }
  };

  return { isMarkAsInvoicedLoading, onMarkAsInvoiced };
};

export default useMarkInvoiceRowsAsInvoiced;
