import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import {
  AccessUserRole,
  UserRoleWithPermissions,
} from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";

import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";

export type GetStructureExtraProps = {
  names: string[];
};

export const getStructure: UseFormContainerGetStructure<
  Partial<UserRoleWithPermissions>
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<UserRoleWithPermissions>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Name",
        gridProps: { md: 3 },
        validate: (props: any) => {
          return (
            props.data?.name.length >= 0 &&
            !extraProps?.names?.includes(props.data?.name.toLowerCase().trim())
          );
        },
        getErrorText: () => "Name already in use. Please select another one",
        setFormData,
      },
      {
        type: "text_input",
        dataName: "description",
        label: "Description",
        gridProps: { md: 9 },
        setFormData,
      },
      {
        type: "switch",
        dataName: "active",
        label: "Active/Inactive",
        gridProps: { md: 12 },
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<AccessUserRole>;
  initData?: Partial<AccessUserRole>;
}) => {
  if (data === undefined) {
    return data;
  }
  const submitData = clone<Partial<AccessUserRole>>(data);

  const partialData = toUpdateData({ data: submitData, initData });

  return partialData;
};
