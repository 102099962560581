import {
  getStructure,
  GetStructureExtraProps,
  PlannedInvoiceExtended,
  toSubmitData,
} from "./PlannedInvoiceContainerStructure";
import { BillingBaseReturn } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { escape } from "src/utils/translate";
import { FormGlue } from "src/hooks/useFormGlue";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import BuildItems from "src/components/Builders/Container/BuildItems";
import RunningRateBillingDataTable from "./RunningRateBillingDataTable/RunningRateBillingDataTable";
import Section from "src/components/Basic/Simple/Sections/Section";
import useFormContainer from "src/hooks/useFormContainer";

type PlannedInvoiceFormTProps = {
  contract: GetSingleContractWithPermissions;
  formGlue: FormGlue<PlannedInvoiceExtended>;
  runningRateData?: BillingBaseReturn[];
  plannedInvoice: PlannedInvoiceExtended;
};

export default function PlannedInvoiceContainer({
  contract,
  formGlue,
  runningRateData,
  plannedInvoice,
}: PlannedInvoiceFormTProps) {
  const { data: usersResponse, isLoading } = useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data || [];

  const { formData, helperText, structure } = useFormContainer<
    PlannedInvoiceExtended,
    GetStructureExtraProps
  >({
    extraProps: { customers, users },
    initialFormData: plannedInvoice,
    formGlue,
    getStructure,
    toSubmitData,
  });

  const isRunningRate = (runningRateData?.length || 0) > 0;

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <>
      <Section
        key={"Planned-invoice"}
        label={`PLANNED INVOICE${escape(" #" + plannedInvoice?.number) || ""}`}
        md={isRunningRate ? 6 : 12}
        lg={isRunningRate ? 6 : 12}
        isLoading={isLoading || isLoadingCustomers}
      >
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </Section>
      <RunningRateBillingDataTable
        contract={contract}
        runningRateData={runningRateData}
        plannedInvoice={plannedInvoice}
      />
    </>
  );
}
