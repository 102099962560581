/**
 *Created by Mikael Lindahl on 2023-06-07
 */

import ButtonCore, { ButtonCoreProps } from "./ButtonCore";

const ButtonSecondary = (props: ButtonCoreProps) => {
  const sx = props.sx || {};

  return (
    <ButtonCore variant="outlined" {...props} sx={sx}>
      {props.children}
    </ButtonCore>
  );
};

export default ButtonSecondary;
