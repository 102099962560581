/**
 *Created by Mikael Lindahl on 2022-11-08
 */

import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { createTheme, Theme } from "@mui/material/styles";
import defaultTheme from "src/themes/default";

declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }
}

const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "uppercase",
      whiteSpace: "pre",
      padding: "0 var(--space-8px)",
    },
  },
} as { styleOverrides: ComponentsOverrides<Theme>["MuiButton"] };

const MuiCheckbox = {
  styleOverrides: {
    root: {
      padding: 0,
      "&.Mui-disabled": {
        display: "none",
      },
      "&.MuiCheckbox-indeterminate svg": {
        fill: "var(--gray-2)",
      },
    },
  },
};

const MUIDataTableBodyCell = (props?: {
  fontSizeCells?: string;
  fontStyle?: string;
  withBorder?: boolean;
}) => ({
  styleOverrides: {
    root: {
      ...(!props?.withBorder ? { border: "none" } : {}),
      fontSize: props?.fontSizeCells || "16px",
      fontStyle: props?.fontStyle || "normal",
      color: "var(--black-6)",
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 2,
      },
      "&:last-of-type": {
        position: "sticky",
        right: 0,
        zIndex: 1,
      },
    },
  },
});

const MUIDataTableHeadCell = {
  styleOverrides: {
    root: {
      backgroundColor: "var(--green-primary) !important",
      color: "var(--white-primary)",
      textTransform: "capitalize",
      whiteSpace: "pre",
      textAlign: "left",
      fontWeight: "bold",
      fontSize: "14px",
      padding: "var(--space-8px) var(--space-16px)",
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 101,
      },
      "&:last-of-type": {
        position: "sticky",
        right: "-1px",
        zIndex: 100,
      },
    },
    data: {
      textTransform: "capitalize",
      fontWeight: "700",
      color: "var(--white-primary)",
    },
    // When sorting, the icon is white-primary
    sortAction: {
      "& .Mui-active .MuiTableSortLabel-icon": {
        color: "var(--white-primary)",
      },
    },
  },
} as { styleOverrides: any };

const MUIDataTableSelectCell = {
  styleOverrides: {
    fixedLeft: {
      zIndex: 101,
    },
    expandDisabled: {
      // Soft hide the expand button.
      display: "none",
    },
  },
};

const MUIDataTableToolbar = (filter = false, noBackground = false) => {
  return {
    styleOverrides: {
      iconActive: {
        '& svg[data-testid="SearchIcon"]': {
          display: "none !important",
        },
      },
      filterPaper: {
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      },
      root: {
        backgroundColor: "transparent",
        padding: 0,
        justifyContent: "end",
        width: "fit-content",
        marginLeft: "var(--space-16px)",
        // This has a white background
        ...(!noBackground ? { marginRight: "var(--space-16px)" } : {}),
        ...(!filter ? { display: "none !important" } : {}),
      },
      actions: {
        display: "inline-flex",
        alignItems: "end",
      },
    },
  };
};

// When any row is selected, there's a box to inform
const MUIDataTableToolbarSelect = {
  styleOverrides: {
    root: {
      border: "1px solid var(--gray-10)",
      borderRadius: "8px",
      flex: "none",
      height: "fit-content",
      padding: 0,
      width: "30%",
      "& > div": {
        width: "fit-content",
      },
    },
  },
};

const MuiFormControl = (baseTheme: any) => ({
  styleOverrides: {
    root: {
      backgroundColor: baseTheme.palette.white?.main,
    },
  },
});

const MuiList = {
  styleOverrides: {
    root: {
      backgroundColor: "var(--white-primary)",
      width: "100%",
    },
  },
};

const MuiInputBase = {
  styleOverrides: {
    root: { backgroundColor: "white" },
  },
};

const MuiPaper = (noBackground = false) =>
  ({
    styleOverrides: {
      root: {
        alignItems: "center",
        backgroundColor: "transparent",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "flex-end",
        boxShadow: "none",
        "& > div": {
          width: "100%",
          ...(noBackground ? { borderRadius: "12px 12px 0 0" } : {}),
        },
      },
    },
  }) as { styleOverrides: ComponentsOverrides<Theme>["MuiPaper"] };

const filter = {
  // This helps searchbar stay on right side
  MUIDataTableSearch: {
    styleOverrides: {
      main: {
        "& > div": {
          minWidth: "400px",
        },
      },
    },
  } as { styleOverrides: any },
  MUIDataTableFilterList: (noBackground = false) =>
    ({
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          margin: "0 0 1rem 0",
          // This has a white background
          ...(!noBackground ? { marginLeft: "var(--space-24px)" } : {}),
        },
      },
    }) as { styleOverrides: any },
  MUIDataTableFilter: {
    styleOverrides: {
      root: {
        backgroundColor: "var(--white-primary)",
        padding: "2rem",
        "& .MuiFormGroup-root div": {
          columnGap: "1em",
        },
        "& .MuiFormGroup-root .MuiGrid-item": {
          flex: "1 1 calc(33.333% - 10px)",
        },
        "& .MuiGrid-item": {
          alignSelf: "start",
        },
      },
      // group of title and resetlink
      reset: {
        display: "flex",
        alignItems: "center",
      },
      title: {
        fontWeight: "bold",
      },
      resetLink: {
        fontSize: "14px",
      },
      checkboxListTitle: {
        fontSize: "12px",
        fontWeight: 600,
        marginBottom: "var(--space-4px)",
      },
      checkboxFormControlLabel: {
        fontSize: "12px",
        marginLeft: 0,
        whiteSpace: "nowrap",
      },
      gridListTile: {
        marginTop: 0,
      },
    },
  } as { styleOverrides: any },
};

const MuiTableCell = (props?: { withBorder?: boolean }) =>
  ({
    styleOverrides: {
      head: {
        textAlign: "left",
        backgroundColor: "var(--green-primary) !important",
        borderBottom: "none !important",
      },
      root: {
        ...(!props?.withBorder ? { border: "none" } : {}),
        padding: "var(--space-8px) var(--space-16px)",
        textAlign: "left",
        "> span": {
          justifyContent: "center",
        },
        "& td": {
          padding: "var(--space-8px)",
        },
        "& input": {
          padding: "10px",
        },
      },
    },
  }) as { styleOverrides: ComponentsOverrides<Theme>["MuiTableCell"] };

const MuiTableRow = (props?: {
  fontSizeCells?: string;
  primaryColor?: string;
  secondaryColor?: string;
  disabled?: boolean;
  isTableScrollable?: boolean;
}) =>
  ({
    styleOverrides: {
      root: {
        whiteSpace: "nowrap",
        backgroundColor: "var(--white-primary)",

        ...(props?.isTableScrollable
          ? {
              "&.MuiTableRow-hover > td:first-child": {
                // First column shadow for even rows
                "::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "calc(100% - 4px)",
                  width: "10px",
                  height: "100%",
                  background:
                    "linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))",
                  pointerEvents: "none",
                },
              },
              "&.MuiTableRow-hover > td:last-child": {
                // Last column shadow for even rows
                "::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: "calc(100% - 4px)",
                  width: "10px",
                  height: "100%",
                  background:
                    "linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))",
                  pointerEvents: "none",
                },
              },
            }
          : {}),
        "&.MuiTableRow-hover:nth-of-type(even) > td": {
          backgroundColor: `${
            props?.disabled
              ? "var(--gray-2)"
              : props?.primaryColor || "var(--white-primary)"
          }`,
        },
        "&.MuiTableRow-hover:nth-of-type(odd) > td": {
          backgroundColor: `${
            props?.disabled
              ? "var(--gray-2)"
              : props?.secondaryColor || "var(--white-primary)"
          }`,
        },
        "&.MuiTableRow-hover:hover > td": !props?.disabled
          ? {
              backgroundColor: "var(--green-9)",
              transition: "all 0.2s ease",
            }
          : {},
      },
      footer: {
        backgroundColor: "transparent",
        "& > td": {
          backgroundColor: "var(--white-primary)",
          borderRadius: "0 0 12px 12px",
        },
      },
    },
  }) as { styleOverrides: ComponentsOverrides<Theme>["MuiTableRow"] };

const MuiTextField = {
  styleOverrides: {
    root: { backgroundColor: "transparent" },
  },
};

const Styles = {
  muiDataTableMainWithFilters:
    (props?: {
      fontSizeCells?: string;
      disabled?: boolean;
      isTableScrollable?: boolean;
      noBackground?: boolean;
    }) =>
    (baseTheme: any) =>
      createTheme({
        ...defaultTheme,
        components: {
          MuiButton,
          MuiCheckbox,
          MUIDataTableBodyCell: MUIDataTableBodyCell({
            fontSizeCells: props?.fontSizeCells,
            withBorder: true,
          }),
          MUIDataTableBodyRow: {
            styleOverrides: {
              root: {
                "&:last-of-type > td": {
                  borderBottom: "none",
                },
              },
            },
          },
          MUIDataTableHeadCell,
          MUIDataTablePagination: {
            styleOverrides: {
              tableCellContainer: {
                borderBottom: "none",
                borderRadius: "12px",
              },
            },
          },
          MUIDataTableSelectCell,
          MUIDataTableToolbar: MUIDataTableToolbar(true, props?.noBackground),
          MUIDataTableToolbarSelect,
          MuiFormControl: MuiFormControl(baseTheme),
          MuiList,
          MuiInputBase,
          MuiPaper: MuiPaper(props?.noBackground),
          MuiTableCell: MuiTableCell({ withBorder: true }),
          MuiTableRow: MuiTableRow({
            disabled: props?.disabled,
            isTableScrollable: props?.isTableScrollable,
          }),
          MuiTextField,
          MUIDataTableSearch: filter.MUIDataTableSearch,
          MUIDataTableFilterList: filter.MUIDataTableFilterList(
            props?.noBackground,
          ),
          MUIDataTableFilter: filter.MUIDataTableFilter,
        },
      }),
  muiDataTableMain:
    (props?: {
      fontSizeCells?: string;
      isTableScrollable?: boolean;
      noBackground?: boolean;
    }) =>
    (baseTheme: any) => {
      return createTheme({
        ...defaultTheme,
        components: {
          MuiButton,
          MuiCheckbox,
          MUIDataTableBodyRow: {
            styleOverrides: {
              root: {
                "&:last-of-type > td": {
                  borderBottom: "none",
                },
              },
            },
          },
          MUIDataTableBodyCell: MUIDataTableBodyCell({
            fontSizeCells: props?.fontSizeCells,
            withBorder: true,
          }),
          MUIDataTableHeadCell: {
            styleOverrides: {
              root: {
                ...MUIDataTableHeadCell.styleOverrides.root,
                fontSize: "16px",
                fontWeight: "600",
                padding: "var(--space-8px) var(--space-16px)",
              },
            },
          },
          MUIDataTablePagination: {
            styleOverrides: {
              tableCellContainer: {
                borderBottom: "none",
                borderRadius: "12px",
              },
            },
          },
          MUIDataTableSelectCell,
          MUIDataTableToolbar: MUIDataTableToolbar(),
          MUIDataTableToolbarSelect,
          MuiFormControl: MuiFormControl(baseTheme),
          MuiList,
          MuiInputBase,
          MuiPaper: MuiPaper(props?.noBackground),
          MuiTableCell: MuiTableCell({ withBorder: true }),
          MuiTableRow: MuiTableRow({
            isTableScrollable: props?.isTableScrollable,
          }),
          MuiTextField,
        },
      });
    },
  muiDataTableNested:
    (props?: {
      align?: "left" | "center" | "right";
      fontSizeCells?: string;
      fullWidth?: boolean;
      fontStyle?: string;
      hasBackground?: boolean;
      titlePosition?: "middle" | "top";
    }) =>
    (baseTheme: any) =>
      createTheme({
        ...defaultTheme,
        components: {
          MuiTableRow: MuiTableRow({
            primaryColor: props?.hasBackground
              ? "var(--black-3)"
              : "var(--white-primary)",
            secondaryColor: props?.hasBackground
              ? "var(--black-3)"
              : "var(--white-primary)",
          }),
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: "none",
                ...(props?.align === "center"
                  ? {
                      display: "flex",
                      justifyContent: "center",
                    }
                  : {}),
              },
            },
          },

          MUIDataTableHeadCell: {
            styleOverrides: {
              fixedHeader: {
                backgroundColor: props?.hasBackground
                  ? "var(--black-3)"
                  : "var(--white-primary)",
                fontSize: "16px",
                color: "var(--green-primary)",
                padding: "10px",
              },
            },
          },
          ...(props?.titlePosition === "top"
            ? {
                MUIDataTableToolbar: {
                  styleOverrides: {
                    root: {
                      position: "relative",
                    },
                    titleText: {
                      position: "absolute",
                      top: "36px",
                      right: "16px",
                      transform: "translateY(-100%)",
                      "&::before": {
                        position: "absolute",
                        content: '""',
                        top: "10px",
                        right: "-24px",
                        height: "12px",
                        width: "12px",
                        borderWidth: "3px 3px 0 0",
                        borderStyle: "solid",
                        borderColor: "var(--green-primary)",
                        transform: "rotate(45deg)",
                      },
                    },
                  },
                },
              }
            : {}),
          MUIDataTableSelectCell: {
            styleOverrides: {
              fixedLeft: {
                width: "40px",
                padding: "10px 8px !important",
              },
            },
          },
          MUIDataTableBodyRow: {
            styleOverrides: {
              root: {
                "&:last-of-type > td": {
                  borderBottom: "none",
                },
              },
            },
          },
          ...(props?.titlePosition === "top"
            ? {
                MUIDataTableToolbar: {
                  styleOverrides: {
                    root: {
                      position: "relative",
                    },
                    titleText: {
                      position: "absolute",
                      top: "36px",
                      right: "16px",
                      transform: "translateY(-100%)",
                      "&::before": {
                        position: "absolute",
                        content: '""',
                        top: "10px",
                        right: "-24px",
                        height: "12px",
                        width: "12px",
                        borderWidth: "3px 3px 0 0",
                        borderStyle: "solid",
                        borderColor: "var(--green-primary)",
                        transform: "rotate(45deg)",
                      },
                    },
                  },
                },
              }
            : {}),
          MuiTableCell: {
            styleOverrides: {
              head: { fontWeight: 600 },
              root: { fontStyle: props?.fontStyle },
            },
          },
        },
      }),
  muiDataCompactTable:
    (props?: {
      align?: "left" | "center" | "right";
      fontSizeCells?: string;
      fullWidth?: boolean;
      isTableScrollable?: boolean;
    }) =>
    (baseTheme: any) =>
      createTheme({
        ...defaultTheme,
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "uppercase",
                whiteSpace: "pre",
                backgroundColor: "white",
              },
            },
          },
          MuiCheckbox,
          MUIDataTableBodyCell: {
            styleOverrides: {
              root: {
                border: "none",
                fontSize: props?.fontSizeCells || "16px",
                color: "var(--black-6)",
                padding: "0 12px",
                "&:first-of-type": {
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                },
                "&:last-of-type": {
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                },
              },
            },
          },
          MUIDataTableSelectCell,
          MUIDataTableToolbar: MUIDataTableToolbar(),
          MuiFormControl: MuiFormControl(baseTheme),
          MuiList,
          MuiInputBase,
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: "rgba(0, 0, 0, 0.54)",
              },
            },
          },
          MuiTableRow: MuiTableRow({
            secondaryColor: "var(--green-10)",
            isTableScrollable: props?.isTableScrollable,
          }),
          MuiTextField,
          MuiCardContent: {
            styleOverrides: {
              root: { padding: 0 },
            },
          },
          MUIDataTableHeadCell: {
            styleOverrides: {
              root: {
                fontSize: "18px",
                borderBottom: "none",
                padding: "18px 20px",
                "& .MuiButton-root": {
                  fontSize: "16px !important",
                  fontWeight: 600,
                },
                "&:first-of-type": {
                  position: "sticky",
                  left: 0,
                  zIndex: 101,
                  ...(props?.isTableScrollable
                    ? {
                        // First column shadow for even rows
                        "::after": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: "calc(100% - 4px)",
                          width: "10px",
                          height: "100%",
                          background:
                            "linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))",
                          pointerEvents: "none",
                        },
                      }
                    : {}),
                },
                "&:last-of-type": {
                  position: "sticky",
                  right: 0,
                  zIndex: 100,
                  ...(props?.isTableScrollable
                    ? {
                        // Last column shadow for even rows
                        "::after": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          right: "calc(100% - 4px)",
                          width: "10px",
                          height: "100%",
                          background:
                            "linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))",
                          pointerEvents: "none",
                        },
                      }
                    : {}),
                },
              },
              fixedHeader: {
                fontSize: "16px",
                fontWeight: 600,
                borderBottom: "none",
                padding: "18px 24px",
              },
              sortAction: {
                "& .Mui-active .MuiTableSortLabel-icon": {
                  color: "var(--black-primary)",
                  fontSize: "18px",
                },
              },
            },
          },
        },
      }),
};

export default Styles;
