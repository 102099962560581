import { getNestedObjectData } from "src/utils/nestedData";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getValue from "src/components/Builders/Container/Utils/getValue";

export interface RegisterUserInputProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const getStructure: UseFormContainerGetStructure<
  Partial<RegisterUserInputProps>
> = ({ setFormData }) => {
  const structure: StructureContainer<Partial<RegisterUserInputProps>> = {
    items: [
      {
        type: "text_input",
        dataName: "firstName",
        label: "First name",
        required: true,
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
      },
      {
        type: "text_input",
        dataName: "lastName",
        label: "Last name",
        required: true,
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
      },
      {
        type: "text_input",
        dataName: "email",
        label: "Email address",
        required: true,
        textInputType: "email",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
      },
      {
        type: "text_input",
        dataName: "password",
        label: "Password",
        required: true,
        textInputType: "password",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
      },
      {
        type: "text_input",
        dataName: "confirmPassword",
        label: "Confirm password",
        required: true,
        textInputType: "password",
        sx: { "& .MuiInputBase-root": { borderRadius: "8px" } },
        setFormData,
        validate: (props: any) => {
          const confirmPassword = getValue(props);
          if (!confirmPassword) return true;

          const password = getNestedObjectData({
            data: props.data,
            key: "password",
          });

          return confirmPassword === password;
        },
        getErrorText: () =>
          "Your confirmation password does not match the password",
      },
    ],
  };
  return structure;
};
