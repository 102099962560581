/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import _c from "src/constants/Constants";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import {
  PlannedInvoice,
  PlannedInvoiceRowExtended,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { setFormDataCustomArticleId } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowArticleId";
import { setFormDataCustomDiscount } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowDiscount";
import { setFormDataCustomPricePerUnit } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowPricePerUnit";
import {
  getErrorTextQuantity,
  setFormDataCustomQuantity,
  validateQuantity,
} from "src/utils/helpersBuilderTableStructure/helperInvoiceRowQuantity";
import getSelectOptions from "../../../../utils/getSelectOptions";
import getSelectOptionsTextRow from "../../../../utils/getSelectOptionsTextRow";

export type GetStructureExtraProps = {
  articles: (Article | ContractArticle)[] | undefined;
  plannedInvoice: PlannedInvoice | undefined;
  customer: Customer | undefined;
};

export const getStructure: UserFormTableGetStructure<
  PlannedInvoiceRowExtended,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
      },
      {
        type: "text_input",
        dataName: "itemDescription",
        headerLabel: "Description",
        sx: {
          minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ITEM_DESCRIPTION,
        },
        setFormData,
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "autocomplete",
        dataName: "articleId",
        headerLabel: "Article",
        options: [
          ...getSelectOptionsTextRow(),
          ...getSelectOptions<Article | ContractArticle>({
            data: extraProps?.articles,
            value: "_id",
            label: (a) => `${a.number} - ${a.name}`,
          }),
        ],
        setFormDataCustom:
          setFormDataCustomArticleId<PlannedInvoiceRowExtended>({
            articles: extraProps?.articles,
            customer: extraProps?.customer,
            setFormData,
          }),
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "number_input",
        dataName: "quantity",
        headerLabel: "Quantity",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        validate: validateQuantity({ articles: extraProps?.articles }),
        getErrorText: getErrorTextQuantity,
        setFormDataCustom: setFormDataCustomQuantity<PlannedInvoiceRowExtended>(
          {
            setFormData,
          },
        ),
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "switch",
        dataName: "isQuantityEstimate",
        cellLabel: "Estimate",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        setFormData,
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "number_input_currency",
        currencyCode: extraProps?.plannedInvoice?.currencyCode,
        dataName: "pricePerUnit",
        headerLabel: "Price",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        setFormDataCustom:
          setFormDataCustomPricePerUnit<PlannedInvoiceRowExtended>({
            setFormData,
          }),
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "switch",
        dataName: "isPricePerUnitEstimate",
        cellLabel: "Estimate",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        setFormData,
      },
      {
        disabled: (props) => !!props.data[props.rowIndex].indexRow,
        type: "percent_input",
        dataName: "discount",
        getErrorText: (props) => "Not allowed",
        validate: (props) => {
          const value = props.data[props.rowIndex][props.item.dataName];
          return !isNaN(value) && 0 <= value && value <= 100;
        },
        headerLabel: "Discount",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        setFormDataCustom: setFormDataCustomDiscount<PlannedInvoiceRowExtended>(
          {
            setFormData,
          },
        ),
      },
      {
        headerLabel: "Use index",
        showCellWhen: (props) => props.data[props.rowIndex].isIndex,
        showColumnWhen: ({ data, item }) => data.some((row) => row?.isIndex),
        cellLabel: "Index",
        type: "icon",
        iconType: "check",
        dataName: "isIndex",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.plannedInvoice?.currencyCode,
        dataName: "totalExcludingVAT",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        headerLabel: "Subtotal",
      },
      {
        type: "text",
        dataName: "percentVAT",
        getValue: ({ data, rowIndex }) =>
          `${data[rowIndex].percentVAT * 100} %`,
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        headerLabel: "VAT %",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.plannedInvoice?.currencyCode,
        dataName: "totalVAT",
        getValue: ({ data, rowIndex }) =>
          (data[rowIndex].total || 0) - (data[rowIndex].totalExcludingVAT || 0),
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        headerLabel: "VAT",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.plannedInvoice?.currencyCode,
        dataName: "total",
        showCellWhen: (props) => Boolean(props.data[props.rowIndex].articleId),
        headerLabel: "Total",
      },
      {
        type: "text_with_optional_badge",
        dataName: "incomplete",
        headerLabel: "",
        textWithOptionalBadgeSetup: ({ data, rowIndex }) => {
          const isProcessing = data[rowIndex]?.incomplete;
          return {
            badgeText: isProcessing ? "incomplete" : "",
            iconType: isProcessing ? "incomplete" : "check_circle",
            showBadge: !!isProcessing,
          };
        },
        getValue: () => "",
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "Delete",
        onClick: ({ rowIndex, data }) => {
          let newFormData = [...data];
          newFormData.splice(rowIndex, 1);
          setFormData(newFormData);
        },
      },
    ],
  };
};
