import {
  CriticalLevel,
  Warning,
  WarningType,
} from "src/accurasee-backend-types/app/warning/warning.types";
import { Company } from "src/accurasee-backend-types/app/company/company.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../../utils/getSelectOptions";

export interface GetStructureExtraProps {
  companies: Company[] | undefined;
  isEditForm?: boolean;
}

export const toSubmitData = ({
  data,
  initData,
}: {
  data: Partial<Warning> | undefined;
  initData: Partial<Warning> | undefined;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<Warning>;

  if (newData._id) {
    partialData = toUpdateData<Partial<Warning>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  return partialData;
};

const getStructure: UseFormContainerGetStructure<
  Partial<Warning>,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<Partial<Warning>> = {
    items: [
      {
        required: true,
        type: "selector_icon",
        dataName: "issueType",
        label: "Type",
        options: getSelectOptions({
          data: [
            {
              label: t(WarningType.ProductionIssue),
              value: WarningType.ProductionIssue,
            },
            {
              label: t(WarningType.ReleaseNotes),
              value: WarningType.ReleaseNotes,
            },
            {
              label: t(WarningType.UserAction),
              value: WarningType.UserAction,
            },
          ],
        }),
        setFormData,
        gridProps: { xs: 6 },
        disabled: extraProps?.isEditForm,
      },
      {
        required: true,
        type: "selector_color",
        dataName: "criticalLevel",
        label: "Level",
        options: getSelectOptions({
          data: [
            {
              label: t(CriticalLevel.Critical),
              value: CriticalLevel.Critical,
            },
            {
              label: t(CriticalLevel.Low),
              value: CriticalLevel.Low,
            },
            {
              label: t(CriticalLevel.Normal),
              value: CriticalLevel.Normal,
            },
          ],
        }),
        setFormData,
        gridProps: { xs: 6 },
        disabled: extraProps?.isEditForm,
      },
      {
        type: "autocomplete",
        dataName: "companyId",
        gridProps: { md: 12 },
        label: "Select company",
        options: getSelectOptions<Company>({
          data: extraProps?.companies,
          label: (c) => c.displayName || "",
          value: (c) => String(c._id),
        }),
        setFormData,
      },
      {
        required: true,
        type: "text_input",
        dataName: "message",
        label: "Description",
        setFormData,
      },
      {
        type: "text_input",
        dataName: "page",
        label: "Page",
        setFormData,
      },
      {
        required: true,
        type: "switch",
        dataName: "active",
        label: "Active",
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
