import { CollectionThatContainFiles } from "../../accurasee-backend-types/app/general/collection.types";
import { axios } from "../../general/Network";
import { backendApi } from "../api";
import { docMultipleListTag } from "./utils";

// const CACHE_TYPE = "Files";
const filesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    postFile: build.mutation<any, FormData>({
      query: (data) => {
        return {
          url: "/files",
          method: "POST",
          data,
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: docMultipleListTag(
        "Contracts",
        "Invoiceplans",
        "PlannedInvoices",
        "CustomerInvoices",
      ),
    }),
    deleteFile: build.mutation<any, { id: any; objectId: any }>({
      query: ({ id, objectId }) => {
        return {
          url: `/files/${id}`,
          method: "DELETE",
          params: { objectId },
        };
      },
      invalidatesTags: docMultipleListTag(
        "Contracts",
        "Invoiceplans",
        "PlannedInvoices",
        "CustomerInvoices",
      ),
    }),
    postFileNoInvalidate: build.mutation<any, FormData>({
      query: (data) => {
        return {
          url: "/files",
          method: "POST",
          data,
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
      },
    }),
    deleteFileNoInvalidate: build.mutation<any, { id: any; objectId: any }>({
      query: ({ id, objectId }) => {
        return {
          url: `/files/${id}`,
          method: "DELETE",
          params: { objectId },
        };
      },
    }),
  }),
  overrideExisting: false,
});

const postFile = (data: any) => {
  return axios.post("/files/", data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

const getFile = (id: any, objectId: any) => {
  return axios.get(`/files/${id}`, {
    responseType: "blob",
    params: { objectId },
  });
};

const deleteFile = (
  id: any,
  objectId: any,
  objectType: CollectionThatContainFiles,
) => {
  return axios.delete(`/files/${id}`, {
    params: { objectType, objectId },
  });
};

export const FileService = {
  getFile,
  postFile,
  deleteFile,
};

export const {
  useDeleteFileMutation,
  useDeleteFileNoInvalidateMutation,
  usePostFileMutation,
  usePostFileNoInvalidateMutation,
} = filesApi;

export default FileService;
