import { backendApi } from "../api";
import { docListTags } from "./utils";

import { MailgunLog } from "src/accurasee-backend-types/app/mailgunlog/mailgunlog.types";

const CACHE_TAG = "MailgunLogs";

export const mailgunLogsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getMailgunLogs: build.query<{ data: MailgunLog[] }, any>({
      query: (params) => ({ url: "/superadmin/mailgun-log", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
  }),
  overrideExisting: false,
});

export const { useGetMailgunLogsQuery } = mailgunLogsApi;

export default mailgunLogsApi;
