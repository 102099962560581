import { EditRights } from "../general/editrights.types";
import { Mixed, Types } from "mongoose";

export const enum MAILGUNSTATUS {
  delivered = "delivered",
  failureTemporary = "failureTemporary",
  failurePermanent = "failurePermanent",
  other = "other",
}
interface MailgunLog {
  _id?: Types.ObjectId;
  recipient: string;
  sender: string;
  subject: string;
  deliveredStatus: MAILGUNSTATUS;
  deliveredCode: string;
  deliveredMessage?: string;
  payload?: Mixed;
  createdAt?: Date;
  eventId?: string;
  content: string;
}

export type DimensionWithPermissions = MailgunLog & EditRights;

export type { MailgunLog };
