import company from "./company";
import developerState from "../slices/developerState";
import dialog from "./dialog";
import dimension from "./dimension";
import file from "./file";
import sidebarState from "../slices/sidebarState";
import user from "./user";
import { backendApi } from "../api";
import { combineReducers } from "redux";
import missingTranslations from "../slices/missingTranslations";
import userState from "../slices/userState";

export default combineReducers({
  // Add the generated reducer as a specific top-level slice
  [backendApi.reducerPath]: backendApi.reducer,
  company,
  developerState,
  dialog,
  dimension,
  file,
  missingTranslations,
  sidebarState,
  user,
  userState,
});
