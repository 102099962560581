/**
 *Created by Mikael Lindahl on 2023-02-08
 */

/*
 *
 * Takes a list and convert it to a dictionary
 *
 * - `list` {array}
 * - `key` {callback} takes a list element and returns a key
 * - `multiple` {boolean} If multiple values exist for each key
 *
 */
export function listToDic<T>(
  list: T[] | undefined,
  key: (l: any) => string,
  multiple: true,
): { [key: string]: T[] };
export function listToDic<T>(
  list: T[] | undefined,
  key: (l: any) => string,
  multiple?: false | undefined,
): { [key: string]: T };
export function listToDic<T>(
  list: T[] | undefined,
  key: (l: any) => string,
  multiple = false,
) {
  if (list === undefined) {
    return {};
  }

  if (multiple === true) {
    let dic: { [key: string]: T[] } = {};

    list.forEach(function (l) {
      if (!dic[key(l)]) {
        dic[key(l)] = [l];
      } else {
        (dic[key(l)] as T[]).push(l);
      }
    });

    return dic;
  } else {
    let dic: { [key: string]: T } = {};

    list?.forEach(function (l) {
      dic[key(l)] = l;
    });

    return dic;
  }
}

export function dicToList<T>(dic: { [key: string]: T } | undefined) {
  let list: T[] = [];

  for (let key in dic) {
    list.push(dic[key]);
  }

  return list;
}
