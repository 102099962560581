/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getIconType = (props: CommonCallbackProps) => {
  return (
    (typeof props.item.iconType === "function"
      ? props.item.iconType(props)
      : props.item.iconType) || ""
  );
};

export default getIconType;
