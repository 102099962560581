/**
 *Created by Mikael Lindahl on 2024-09-23
 */

import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useFormTable from "src/hooks/useFormTable";
import { getStructure } from "./ReportTableStructure";
import {
  ReportGetRequestQuery,
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useRef } from "react";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import useTranslation from "src/hooks/useTranslationWrapper";
import BuilderColumnOptionsBase from "src/components/Builders/Table/BuilderColumnOptionsBase";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import InsightReportFilterSubTable from "./ReportSubTable";

const InsightReportsTable = ({
  columnsServerSide,
  reports,
  filter,
}: {
  columnsServerSide: ReportGetResponseColumn[];
  reports: ReportGetResponseData[];
  filter: Partial<ReportGetRequestQuery>;
}) => {
  const [t] = useTranslation();

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const { formData: formDataTable, structure: structureTable } = useFormTable({
    extraProps: {
      columns: columnsServerSide || [],
    },
    getStructure: getStructure,
    initialFormData: reports,
  });

  let columns: MUIDataTableColumnDef[] = structureTable.items.map((item) =>
    BuilderColumnOptionsBase({
      data: formDataTable,
      item,
      t,
    }),
  );

  const { options } = useMUIDataTableOptions({
    filterOptions: { selectableRows: "none" },
    pagination: true, //reports && reports?.length > 10,
    defaultPageSize: 50,
    defaultPageSizeOptions: [10, 50, 100],
    isRowExpandable: (dataIndex: number) => {
      const row = formDataTable[dataIndex];
      return row.filterType !== "calculated";
    },
    subtableFunction: (rowData: any, rowMeta: any) => {
      const row = formDataTable[rowMeta.dataIndex];

      return (
        <InsightReportFilterSubTable
          filter={filter}
          reportTemplateYFilterId={row.filterId}
          colSpan={columnsServerSide.length + 1}
        />
      );
    },
    customButton: () => (
      <ExportButton
        buttonType="icon"
        data={() => exportDataRef.current}
        fileName="insight-reports.xlsx"
        fileType="xlsx"
      >
        <FileDownloadIcon />
      </ExportButton>
    ),
  });

  return (
    <MuiDataTableWrapper
      columns={columns}
      data={reports}
      tableType="main-with-filter"
      options={{
        ...options,
        onTableChange: (action, tableState) => {
          exportDataRef.current = getExportData(tableState);
        },
      }}
      title=""
      noBackground
    />
  );
};

export default InsightReportsTable;
