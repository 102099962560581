/**
 *Created by Mikael Lindahl on 2024-08-22
 */

import { Box, Typography } from "@mui/material";

type DateBadgeProps = {
  label?: string;
  date?: Date;
  isError?: boolean;
};

const TimeDifferenceNowBadge: React.FC<DateBadgeProps> = ({
  date,
  isError,
  label,
}) => {
  const calculateTimeDifference = (date?: Date) => {
    if (!date) return { timeDifference: "", timeHasPassed: false };

    const now = new Date();
    const diff = new Date(date).getTime() - now.getTime();

    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (days < 0) {
      days = days + 1;
    }
    if (hours < 0) {
      hours = hours + 1;
    }

    return {
      timeHasPassed: diff < 0,
      timeDifference: `${days ? days + "d " : ""}${hours ? hours + "h " : ""}${minutes}m`,
    };
  };

  const { timeDifference, timeHasPassed } = calculateTimeDifference(date);

  return (
    <Box
      sx={{
        display: "inline-block",
        padding: "0.5rem 1rem",
        borderRadius: "0.5rem",
        color: "white",
      }}
    >
      <Typography
        sx={{
          fontSize: "17px",
          textTransform: "capitalize",
          backgroundColor:
            isError || timeHasPassed ? "var(--negative-3)" : "var(--green-8)",
          color:
            isError || timeHasPassed ? "var(--negative-2)" : "var(--green-2)",
          borderRadius: "4px",
          padding: "0 var(--space-8px)",
        }}
      >
        {`${label ? label + ": " : ""}${timeDifference}`}
      </Typography>
    </Box>
  );
};

export default TimeDifferenceNowBadge;
