import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

import {
  Customer,
  CustomerType,
} from "src/accurasee-backend-types/app/customer/customer.types";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";

import { EUCOUNTRIESWITHSWEDEN as EuCountries } from "src/constants/EuCountries";
import clone from "src/utils/clone";
import getCountryName from "src/utils/getCountryName";
import getVatType from "src/utils/getVatType";
import removeKeysWithEmptyStringAsValue from "src/utils/removeKeysWithEmptyStringAsValue";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  currencies?: Currency[];
  isCreate?: boolean;
  isShowingVatWarning?: boolean;
  termsOfPayments?: TermsOfPayment[];
  users?: ReturnUser[];
};

export type ItemsTypes =
  | "basic_details"
  | "invoice_details_term_of_payment"
  | "invoice_details_references"
  | "invoice_details_invoicing"
  | "rotrut";

export const toSubmitData = ({
  data,
  initData,
}: {
  data: Partial<Customer> | undefined;
  initData: Partial<Customer> | undefined;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<Customer>;

  if (newData._id) {
    partialData = toUpdateData<Partial<Customer>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  // Allow to a null for www
  if (partialData?.www === "") {
    partialData.www = null;
  }

  // cleanup data
  const cleanedPartialData = removeKeysWithEmptyStringAsValue(partialData);

  return cleanedPartialData as Customer;
};

export const getStructure: UseFormContainerGetStructure<
  Partial<Customer>,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<Customer>, ItemsTypes> = {
    items: [
      {
        showWhen: !extraProps?.isCreate,
        itemType: "basic_details",
        required: true,
        type: "text",
        dataName: "externalId",
        label: "Customer id",
        gridProps: { md: 6 },
      },
      {
        itemType: "basic_details",
        required: true,
        type: "toggle",
        dataName: "customerType",
        label: "Customer type",
        options: getSelectOptions({
          data: [
            {
              label: "COMPANY",
              value: CustomerType["Company"],
            },
            {
              label: "PRIVATE",
              value: CustomerType["Private"],
            },
          ],
        }),
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Name",
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "text_input",
        dataName: "address1",
        label: "Invoice address",
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "text_input",
        dataName: "address2",
        label: "Invoice address 2",
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "text_input",
        dataName: "zipCode",
        label: "Postcode",
        gridProps: { md: 4 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "text_input",
        dataName: "city",
        label: "City",
        gridProps: { md: 8 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "selector",
        dataName: "countryCode",
        label: "Country",
        options: getSelectOptions({
          data: EuCountries?.map((country: string) => {
            return {
              label: getCountryName.of(country) as string,
              value: country,
            };
          }),
        }),
        setFormData,
      },
      {
        itemType: "basic_details",
        required: true,
        type: "text_input",
        dataName: "organisationNumber",
        label: "Organisation number",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "text_input",
        textInputType: "tel",
        dataName: "phone1",
        label: "Phone",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "text_input",
        textInputType: "email",
        dataName: "emailInvoice",
        label: "Invoice Email",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "url_input",
        dataName: "www",
        label: "Web address",
        gridProps: { md: 6 },
        setFormData,
        getValue: (props) => props.data?.www || "",
      },
      {
        itemType: "basic_details",
        required: true,
        type: "switch",
        dataName: "reversedConstructionVAT",
        label: "Reversed construction VAT",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "text_input",
        dataName: "vatNumber",
        label: "VAT number",
        vatTypeTooltip: extraProps?.isShowingVatWarning
          ? {
              color: undefined,
              message: "VAT number is required for this VAT type",
            }
          : undefined,
        setFormData,
      },
      {
        itemType: "basic_details",
        required: false,
        type: "text",
        dataName: "vatType",
        label: "Type of VAT",
        gridProps: { md: 6 },
        getValue: (props) => getVatType(props?.data as Customer),
      },

      {
        itemType: "invoice_details_term_of_payment",
        required: false,
        type: "autocomplete",
        dataName: "termsOfPaymentId",
        label: "Payment terms",
        options: getSelectOptions({
          data: extraProps?.termsOfPayments,
          label: (c) => c.description,
          value: (c) => String(c._id),
        }),
        setFormData,
      },

      // priceList is not implemented yet on BE - coming soon
      {
        itemType: "invoice_details_invoicing",
        required: false,
        type: "selector",
        dataName: "priceList",
        label: "Price list",
        gridProps: { md: 6 },
        disabled: true,
        // setFormData,
      },
      {
        itemType: "invoice_details_invoicing",
        required: false,
        type: "autocomplete",
        dataName: "currencyCode",
        label: "Currency",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: extraProps?.currencies,
          label: (c) => `${c.code} (${c.description})`,
          value: (c) => c.code,
        }),
        setFormData,
      },
      {
        itemType: "invoice_details_invoicing",
        required: false,
        type: "text_input",
        dataName: "defaultDiscount",
        label: "Invoice discount (%)",
        gridProps: { md: 6 },
        setFormData,
      },

      {
        itemType: "invoice_details_references",
        required: false,
        type: "autocomplete",
        dataName: "ourReference",
        label: "Our reference",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: extraProps?.users,
          label: (el) => `${el.firstName} ${el.lastName}`,
          value: (el) => `${el.firstName} ${el.lastName}`,
        }),
        setFormData,
      },
      {
        itemType: "invoice_details_references",
        required: false,
        type: "text_input",
        dataName: "yourReference",
        label: "Your reference",
        gridProps: { md: 6 },
        setFormData,
      },

      {
        itemType: "rotrut",
        required: true,
        type: "text_input",
        dataName: "nameOfProperty",
        label: "Name of property/apartment no.",
        setFormData,
      },
      {
        itemType: "rotrut",
        required: true,
        type: "text_input",
        dataName: "cinOfHa",
        label: "HA's CIN",
        setFormData,
      },
    ],
  };

  return structure;
};
