/**
 *Created by Mikael Lindahl on 2023-07-04
 */
import { PlannedInvoiceRowExtended } from "../../app/plannedinvoice/plannedinvoice.types";
import getPriceTotalExcludingVatAndTotal from "./getPriceTotalExcludingVatAndTotal";
import { InvoicePlanRowExtended } from "../../app/invoiceplan/invoiceplan.types";

const calculateDependable = (props: {
  data: InvoicePlanRowExtended | PlannedInvoiceRowExtended;
}) => {
  const { totalExcludingVAT, total } = getPriceTotalExcludingVatAndTotal({
    quantity: props.data.quantity || 0,
    pricePerUnit: props.data.pricePerUnit || 0,
    percentVAT: props.data.percentVAT,
    discount: props.data.discount || 0,
  });

  const dependable:
    | Partial<PlannedInvoiceRowExtended>
    | Partial<InvoicePlanRowExtended> = {
    total: total,
    totalExcludingVAT: totalExcludingVAT,
  };

  return dependable;
};

export default calculateDependable;
