import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type SidebarState = {
  isSidebarOpened: boolean;
  expandedItem: string[];
  isWarnings: boolean;
};

const initialState = {
  isSidebarOpened: true,
  expandedItem: [],
  isWarnings: false,
} as SidebarState;

const sidebarStateSlice = createSlice({
  name: "sidebarState",
  initialState,
  reducers: {
    updateSidebarState(state, action: PayloadAction<SidebarState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSidebarState } = sidebarStateSlice.actions;
export default sidebarStateSlice.reducer;
