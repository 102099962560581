import { CustomerInvoiceWithGetList } from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCustomerInvoicesQuery } from "src/redux/services/CustomerInvoiceService";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import getSummaries from "src/utils/getSummaries";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useCustomerInvoicesTableStructure from "./hooks/useCustomerInvoicesTableStructure";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useTranslation from "src/hooks/useTranslationWrapper";

interface CustomerInvoiceTableProps {
  customerInvoiceFilter: any;
  label?: string;
  includeProjectNumber?: boolean;
  isLoading?: boolean;
  useColumns: string[];
  redirectFoundationPath?: string;
}

const CustomerInvoiceTable = ({
  customerInvoiceFilter,
  label,
  includeProjectNumber,
  isLoading,
  useColumns,
  redirectFoundationPath,
}: CustomerInvoiceTableProps) => {
  const [t] = useTranslation();
  const { contractId } = useParams<{ contractId: string }>();

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const {
    options,
    data: customerInvoices,
    isLoading: isCustomerInvoicesLoading,
  } = useMUIDataTableOptionsServerSide<CustomerInvoiceWithGetList>({
    dataName: "customer invoices",
    filterInit: {
      base: {
        ...customerInvoiceFilter,
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()}, ${t(
        "Name",
      ).toLowerCase()}, ${t("Customer name").toLowerCase()} ${t("or")} ${t(
        "Status",
      ).toLowerCase()}...`,
    },
    isRowExpandable: (data, dataIndex: number) => {
      return data.length !== dataIndex;
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const customerInvoice = customerInvoices[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={customerInvoice.currencyCode}
            invoiceRows={customerInvoice.invoiceRows}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
            ]}
          />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: contractId ? ["contractId"] : [],
    useGetDataQuery: useGetCustomerInvoicesQuery,
    customButton: () => (
      <ExportButton
        buttonType="icon"
        data={() => exportDataRef.current}
        fileName="customer-invoices.xlsx"
        fileType="xlsx"
      >
        <FileDownloadIcon />
      </ExportButton>
    ),
  });

  const { idSummaries, dataWithSummaries: customerInvoicesWithSummaries } =
    getSummaries({
      data: customerInvoices,
      sumTextKey: "invoiceNumber",
      keys: [
        { name: "totalExcludingVAT" },
        { name: "totalVAT" },
        { name: "total" },
      ],
    });

  const { getStructure, isLoadingCompany, isLoadingCompanyUsers } =
    useCustomerInvoicesTableStructure({
      idSummaries,
      includeProjectNumber,
      redirectFoundationPath,
    });

  let customerInvoiceColumns: MUIDataTableColumnDef[] = getStructure({ t })
    .items.map((item) =>
      BuilderColumnOptionsSingle({
        data: customerInvoicesWithSummaries,
        disabled: true,
        item,
      }),
    )
    .filter((column: any) => useColumns.includes(column.name));

  const isFetching =
    isLoading ||
    isLoadingCompany ||
    isCustomerInvoicesLoading ||
    isLoadingCompanyUsers;

  const getTable = (noBackground: boolean) => (
    <MuiDataTableWrapper
      data={customerInvoicesWithSummaries || []}
      columns={customerInvoiceColumns}
      isFetching={isFetching}
      options={{
        ...options,
        onTableChange: (action, tableState) => {
          exportDataRef.current = getExportData(tableState);
        },
      }}
      tableType="main-with-filter"
      noBackground={noBackground}
    />
  );

  return label ? (
    <SectionForTableWithToolbar
      isLoading={isFetching}
      label={label}
      transparent
    >
      {getTable(false)}
    </SectionForTableWithToolbar>
  ) : (
    getTable(true)
  );
};

export default CustomerInvoiceTable;
