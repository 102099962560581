import { DimensionItem } from "src/accurasee-backend-types/app/dimensionitem/dimensionitem.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";

const getStructure: UserFormTableGetStructure<DimensionItem> = () => ({
  items: [
    {
      dataName: "code",
      headerLabel: "#",
      type: "text",
    },
    {
      dataName: "name",
      headerLabel: "Name",
      type: "text",
    },
    {
      dataName: "active",
      headerLabel: "Active",
      type: "switch",
    },
  ],
});
export default getStructure;
