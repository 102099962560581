/**
 *Created by Mikael Lindahl on 2023-03-16
 */

import {
  StructureContainer,
  StructureItemContainer,
} from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { Dimension } from "src/accurasee-backend-types/app/dimension/dimension.types";
import {
  ContractDimension,
  ContractType,
} from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getValue from "src/components/Builders/Container/Utils/getValue";
import { dicToList, listToDic } from "src/utils/transform";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import { Index } from "src/accurasee-backend-types/app/index/index.types";
import _c from "src/constants/Constants";
import { SelectOption } from "src/utils/getSelectOptions";
import getSelectOptionsDayMonthQuarterYear from "../../../../../utils/getSelectOptionsDayMonthQuarterYear";
import getSelectOptionsAutomaticManual from "../../../../../utils/getSelectOptionsAutomaticManual";

export type GetStructureExtraProps = {
  companyHasBkConnection: boolean;
  currentIndex?: Index;
  dimensions: Dimension[];
  indexOptions: SelectOption[];
  projectNumberSeriesOptions: SelectOption[];
};

export type ItemsTypes = "info" | "renewal";

export type TmpContractDimensions =
  | {
      [key: string]: ContractDimension;
    }
  | undefined;

export type ContractTypeExtended = ContractType & {
  tmpContractDimensions: TmpContractDimensions;
};

export const getStructure: UseFormContainerGetStructure<
  ContractTypeExtended,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const dimensionItems =
    extraProps?.dimensions.map((d) => {
      const item: StructureItemContainer<ContractTypeExtended, ItemsTypes> = {
        gridProps: { xs: 6 },
        type: "switch",
        label: d.name,
        dataName: `tmpContractDimensions.${String(d._id)}.active`,
        setFormData,
      };
      return item;
    }) || [];

  const structure: StructureContainer<ContractTypeExtended, ItemsTypes> = {
    allowFields: ["contractArticles"],
    items: [
      {
        itemType: "info",
        gridProps: { xs: 12 },
        validate: (props) => {
          const value = getValue(props);
          return !(value && value.length < 3);
        },
        getErrorText: "Too short",
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Contract type name",
        setFormData,
      },
      {
        itemType: "info",
        gridProps: { xs: 12 },
        type: "autocomplete",
        required: true,
        dataName: "projectNumberSeriesId",
        label: "Project number series",
        options: extraProps?.projectNumberSeriesOptions,
        setFormData,
      },
      {
        itemType: "info",
        type: "object",
        label: "Include",
        items: [
          {
            gridProps: { xs: 6 },
            type: "switch",
            label: "House work",
            dataName: "contractTypeFeatures.houseWork",
            setFormData,
          },
          {
            gridProps: { xs: 6 },
            showWhen: (props) =>
              props.data?.renewalTerms?.renewalType === "manual",
            type: "switch",
            label: "Email reminder before end date",
            dataName: "contractTypeFeatures.endDateReminder",
            setFormData,
          },
          {
            gridProps: { xs: 6 },
            type: "switch",
            label: "BK connected",
            dataName: "contractTypeFeatures.bkConnected",
            setFormData,
            showWhen: () => !!extraProps?.companyHasBkConnection,
          },
          {
            gridProps: { xs: 6 },
            type: "switch",
            label: "Service order contract",
            dataName: "contractTypeFeatures.serviceOrderContract",
            setFormData,
            showWhen: ({ data }) =>
              !!extraProps?.companyHasBkConnection &&
              !!data?.contractTypeFeatures?.bkConnected,
          },
          {
            gridProps: { xs: 6 },
            type: "switch",
            label: "Total price",
            dataName: "contractTypeFeatures.totalPrice",
            setFormData,
          },
          ...dimensionItems,
          {
            gridProps: { xs: 2 },
            type: "switch",
            label: "Index",
            dataName: "indexOption.index",
            setFormDataCustom: (props) => {
              let newData = clone(props.data);
              if (newData) {
                newData.indexOption = {
                  index: props.value,
                };

                for (const contractArticle of newData?.contractArticles || []) {
                  contractArticle.isIndex = props.value;
                }

                setFormData(newData);
              }
            },
          },
          {
            gridProps: { xs: 6 },
            required: true,
            showWhen: (props) => !!props.data?.indexOption?.index,
            type: "autocomplete",
            label: "Set index to use",
            dataName: "indexOption.indexId",
            options: extraProps?.indexOptions,
            getErrorText: _c.ERROR_TEXTS.INACTIVE_INDEX,
            validate: (props) =>
              !(
                extraProps?.currentIndex?.active === false &&
                props.data?.indexOption?.indexId ===
                  extraProps?.currentIndex?._id
              ),
            setFormDataCustom: (props) => {
              let newData = clone(props.data);
              if (newData) {
                newData.indexOption = {
                  ...(props.data?.indexOption || {}),
                  indexId: props.value,
                };

                setFormData(newData);
              }
            },
          },
        ],
      },
      {
        itemType: "renewal",
        required: true,
        type: "selector",
        dataName: "renewalTerms.renewalType",
        label: "Renewal",
        options: getSelectOptionsAutomaticManual(t),
        setFormData,
      },
      {
        itemType: "renewal",
        gridProps: { xs: 6 },
        type: "object",
        // showWhen: (props) => props.data?.renewalTerms?.renewalType !== "manual",
        dataName: "cancellationTerms",
        label: "Cancellation terms",
        required: true,
        showWhen: (props) => props.data?.renewalTerms?.renewalType !== "manual",
        items: [
          {
            gridProps: { xs: 4 },
            required: true,
            type: "number",
            dataName: "cancellationTerms.number",
            setFormData,
          },
          {
            gridProps: { xs: 8 },
            required: true,
            type: "selector",
            dataName: "cancellationTerms.unit",
            options: getSelectOptionsDayMonthQuarterYear(t),
            setFormData,
          },
        ],
      },
      {
        itemType: "renewal",
        gridProps: { xs: 6 },
        type: "object",
        dataName: "renewalTerms",
        label: "Automatic renewal",
        showWhen: (props) =>
          props.data?.renewalTerms?.renewalType === "automatic",
        items: [
          {
            gridProps: { xs: 4 },
            required: (props) =>
              props.data?.renewalTerms?.renewalType === "automatic",
            type: "number",
            dataName: "renewalTerms.number",
            setFormData,
          },
          {
            gridProps: { xs: 8 },
            required: (props) =>
              props.data?.renewalTerms?.renewalType === "automatic",
            type: "selector",
            dataName: "renewalTerms.unit",
            setFormData,
            options: getSelectOptionsDayMonthQuarterYear(t),
          },
        ],
      },
    ],
  };

  return structure;
};

export const toData = ({
  data,
  extraProps,
}: {
  data: ContractType | undefined;
  extraProps: {
    dimensions: Dimension[] | undefined;
  };
}) => {
  if (data === undefined) {
    return undefined;
  }

  const contractDimensionDic = listToDic<ContractDimension>(
    data?.contractDimensions || [],
    (v) => v.dimensionId,
  );

  const tmpContractDimensions: TmpContractDimensions =
    extraProps?.dimensions?.reduce((dic: TmpContractDimensions, val) => {
      if (dic && val._id) {
        dic[String(val._id)] = {
          active:
            !!contractDimensionDic[String(val._id)] &&
            contractDimensionDic[String(val._id)].active,
          name: val.name,
          dimensionId: val._id,
        };
      }
      return dic;
    }, {});

  return {
    ...data,
    tmpContractDimensions,
  } as ContractTypeExtended;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<ContractTypeExtended>;
  initData?: ContractType;
}) => {
  if (!data) {
    return;
  }
  if (data.renewalTerms?.renewalType === "manual") {
    delete data.cancellationTerms;
  }
  const submitData = clone(data);

  if (data === undefined) {
    return data;
  }

  if (submitData.contractArticles) {
    // Cleanup bad data
    for (let article of submitData.contractArticles) {
      if (!article?.price) {
        article.price = 0;
      }
      if (!article?.quantity) {
        article.quantity = 0;
      }
      delete article._id;
      delete article.name;
      delete article.number;
    }
  }

  submitData.contractDimensions = dicToList(submitData.tmpContractDimensions);
  delete submitData.tmpContractDimensions;

  let partialData: Partial<ContractType>;
  if (submitData._id) {
    partialData = toUpdateData<Partial<ContractType>>({
      data: submitData,
      initData,
    });
  } else {
    partialData = submitData;
  }

  return partialData;
};
