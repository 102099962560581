/**
 *Created by Mikael Lindahl on 2023-05-09
 */

import useTranslation, { TFunction } from "./useTranslationWrapper";
import { Namespaces } from "../accurasee-backend-types/app/translation/translation.types";
import * as xlsx from "xlsx";

/**
 * Convert csv to json
 *
 * @param data {string} Text with csv data
 * @param delimiter {string} Csv data delimiter
 * @returns {*[]}
 */
function csvToJson(data: string, delimiter: string) {
  const data_rows = data.trim().split("\n");

  const header = data_rows[0].split(delimiter);
  const rows = data_rows.slice(1);

  let result = [];

  for (let row of rows) {
    let element = row
      .split(delimiter)
      .reduce((tot: { [key: string]: string }, val, i) => {
        tot[header[i]] = val;
        return tot;
      }, {});

    result.push(element);
  }

  return result;
}

const loadData = async (
  file: File,
  t: TFunction,
  readAs: "text" | "binary_string",
) => {
  const reader = new FileReader();

  const p = new Promise<{ data?: string | ArrayBuffer | null; error?: string }>(
    (resolve, reject) => {
      reader.onloadend = (e) => {
        let data = reader.result;
        resolve({ data });
      };

      reader.onerror = (e) => {
        console.error(e);
        resolve({
          error: `${t(
            "Something went wrong with the file reader when loading the file.",
          )}`,
        });
      };

      switch (readAs) {
        case "text":
          console.log("loadData readAsText");
          reader.readAsText(file);
          break;
        case "binary_string":
          console.log("loadData readAsBinaryString");
          reader.readAsBinaryString(file);
          break;
      }
    },
  );

  return p;
};

const useFileReaders = (props?: { translationNamespace: Namespaces }) => {
  const [t] = useTranslation(props?.translationNamespace);

  const loadCsv = async <FileData>(file: File, delimiter = ";") => {
    let { data, error } = await loadData(file, t, "text");

    if (error) {
      return { error };
    } else {
      try {
        const dataParsed = csvToJson(data as string, delimiter);
        return { data: dataParsed };
      } catch (error: any) {
        console.error(error);
        return {
          error: `${t("Failed to parse csv")} ${t("Error")}: ${t(
            error.message,
          )}`,
        };
      }
    }
  };

  const loadJson = async <FileData>(file: File, delimiter = ".") => {
    let { data, error } = await loadData(file, t, "text");

    if (error) {
      return { error };
    } else {
      try {
        const dataParsed = JSON.parse(data as string) as FileData;
        return { data: dataParsed };
      } catch (error: any) {
        console.error(error);
        return {
          error: `${t("Failed to parse csv")} ${t("Error")}: ${t(
            error.message,
          )}`,
        };
      }
    }
  };

  const loadXlsx = async <FileData>(file: File) => {
    let { data, error } = await loadData(file, t, "binary_string");

    if (error) {
      return { error };
    } else {
      try {
        let workbook = xlsx.read(data, {
          type: "binary",
        });

        let sheets: { sheetName: string; data: FileData }[] = [];
        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          let sheet_data = xlsx.utils.sheet_to_json<{ key: string }>(
            workbook.Sheets[sheetName],
            { defval: "" },
          );

          let dataXlsx: any[] = [];
          for (let row of sheet_data) {
            dataXlsx.push(row);
          }

          const dataParsed = dataXlsx as unknown as FileData;

          sheets.push({ sheetName, data: dataParsed });
        });

        return { data: sheets[0].data };
      } catch (error: any) {
        console.error(error);
        return {
          error: `${t("Failed to parse xlsx")} ${t("Error")}: ${t(
            error.message,
          )}`,
        };
      }
    }
  };

  return { loadCsv, loadJson, loadXlsx };
};

export default useFileReaders;
