/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import OffsetModal from "../../Complex/Modals/OffsetModal";
import clone from "../../../utils/clone";
import useFormTable from "src/hooks/useFormTable";
import { InvoicePlanRowExtended } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useEffect, useState } from "react";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
  toData,
} from "./InvoiceplanTemplateTableStructure";
import {
  InvoicePlan,
  InvoicePlanRow,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";

type InvoiceplanRowsTemplateTProps = {
  data: InvoicePlanRow[];
  isFetching?: boolean;
  invoicePlan: InvoicePlan;
  selectedRows: number[];
  setSelectedRows: (data: InvoicePlanRow[]) => void;
  setIsFormValidRows: (data: boolean) => void;
  view: "create" | "edit";
};

const InvoiceplanTemplateTable = (props: InvoiceplanRowsTemplateTProps) => {
  const [offsetRowIndex, setOffsetRowIndex] = useState<number>();
  const [openModel, setOpenModal] = useState(false);

  const data: InvoicePlanRowExtended[] = toData(props?.data);

  const { formData, helperText, isFormValid, structure, setFormData } =
    useFormTable<InvoicePlanRowExtended, GetStructureExtraProps>({
      extraProps: {
        invoicePlan: props.invoicePlan,
        setOffsetRowIndex,
        setOpenModal,
      },
      initialFormData: data,
      getStructure,
    });
  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<InvoicePlanRowExtended>({
      data: formData,
      helperText,
      item,
    }),
  );

  useEffect(() => {
    const submitData: InvoicePlanRow[] = toSubmitData({ data: formData });
    props.setSelectedRows(submitData);
  }, [formData]);

  useEffect(() => {
    props.setIsFormValidRows(isFormValid);
  }, [isFormValid]);

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
    filterOptions: {
      selectableRows: props.view === "create" ? "multiple" : "none",
      selectFunction: (_, current) => {
        const submitData: InvoicePlanRow[] = toSubmitData({ data: formData });
        props.setSelectedRows(current.map((c) => submitData[c.dataIndex]));
      },
      selectToolbarPlacement: "none",
      rowsSelected: props.selectedRows,
    },
  });

  return (
    <>
      <MuiDataTableWrapper
        title={"Data table"}
        data={formData || []}
        columns={columns}
        isFetching={props.isFetching}
        options={options}
      />
      <OffsetModal
        openModal={openModel}
        data={
          offsetRowIndex !== undefined
            ? formData[offsetRowIndex].offset
            : undefined
        }
        handleCloseModal={() => {
          setOffsetRowIndex(undefined);
          setOpenModal(false);
        }}
        onChange={({ data }) => {
          let newFormData = [...clone(formData)];

          if (offsetRowIndex !== undefined) {
            newFormData[offsetRowIndex].offset = data;

            setFormData(newFormData);
          }
        }}
      />
    </>
  );
};

export default InvoiceplanTemplateTable;
