import ApiKeysTable from "./ApiKeysTable";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import LinearProgress from "@material-ui/core/LinearProgress";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import getIconByType from "src/components/Builders/Table/Utils/getIconByType";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ApiKeyReqeust } from "src/accurasee-backend-types/app/company/company.types";
import { GetStructureExtraProps, getStructure } from "./ApiKeysCardStructure";
import { Grid, Typography } from "@mui/material";
import {
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
} from "src/redux/services/CompanyService";
import { useGetLanguagesQuery } from "src/redux/services/LanguageService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useBuildItems from "../../../../../components/Builders/Container/Hooks/useBuildItems";

const ApiKeysCard = () => {
  const [t] = useTranslation();
  const [token, setToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [createApiKey] = useCreateApiKeyMutation();
  const { icon } = getIconByType("copy");

  const { data: languagesQuery } = useGetLanguagesQuery(undefined);
  const languages = languagesQuery?.data || [];
  const { data: apiKeys, isLoading } = useGetApiKeysQuery(undefined);

  const initialFormData = {
    description: "",
    email: "",
    locale: "sv",
  };

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<ApiKeyReqeust, GetStructureExtraProps>({
    getStructure,
    extraProps: {
      languages,
      apiKeys: apiKeys || [],
    },
    initialFormData,
  });

  const {
    isSubmitting: isSubmittingExport,
    onFormSubmit,
    refForm,
  } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createApiKey,
      },
      data: { create: formData },
      name: "Api key",
    },
    onSuccess: ({ responseData }) => {
      if (responseData?.create?.token) {
        setToken(responseData?.create?.token);
      }
      setHasTriedToSubmit(false);
      setFormData(initialFormData);
      enqueueSnackbar(t("API Key generated"), {
        variant: "success",
      });
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const { BuildItems, isTyping } = useBuildItems({
    items: structure.items,
    ...commonPropsBuildItem,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((err) => console.log(err));
      }}
      ref={refForm}
    >
      <SectionContainer>
        <SectionTight
          label={"API keys"}
          subLabel={
            "Here you can see currently existing API keys and generate new ones."
          }
        >
          {token && (
            <Grid item xs={12}>
              <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                <Typography>{t("Token (only visable once)") + ":"}</Typography>
              </Grid>
              {/* This is the row for the token and the icon */}
              <Grid item xs={12} container alignItems="center">
                {/* This is the cell for the token */}
                <Grid item>
                  <Typography>{"... " + token.slice(-20, -1)}</Typography>
                </Grid>
                {/* This is the cell for the icon */}
                <Grid item xs>
                  <ButtonPrimary
                    onClick={() => navigator.clipboard.writeText(token)}
                    startIcon={icon}
                    sx={{ marginLeft: 5 }}
                  >
                    {t("Copy")}
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Grid>
          )}
          {BuildItems}
          <Grid item xs={12} md={4}>
            <ButtonAddBoxIcon
              label={"Create api key"}
              isValid={isFormValid && !isTyping}
              initialSubmitData={{}}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              isSubmitting={isSubmittingExport}
              sx={{ marginLeft: 5, margin: 4 }}
              submitData={formData}
            />
          </Grid>
          {isLoading && <LinearProgress />}
          <ApiKeysTable data={apiKeys || []} />
        </SectionTight>
      </SectionContainer>
    </form>
  );
};

export default ApiKeysCard;
