import { ContractWithPermissionsAndTotals } from "src/accurasee-backend-types/app/contracts/contract.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";

export const getBookingDateStructure: UseFormContainerGetStructure<{
  bookingDate: Date;
}> = ({ setFormData }) => {
  const structure: StructureContainer<{ bookingDate: Date }> = {
    items: [
      {
        type: "date",
        dataName: "bookingDate",
        label: "Booking date",
        setFormData,
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
          "& .MuiInputBase-input": { padding: "10px", height: "20px" },
        },
      },
    ],
  };

  return structure;
};

const getStructure: UserFormTableGetStructureOptional<
  ContractWithPermissionsAndTotals
> = () => ({
  items: [
    {
      type: "text",
      dataName: "number",
      headerLabel: "#",
      sort: true,
    },
    {
      type: "text",
      dataName: "projectName",
      headerLabel: "Project",
      sort: true,
    },
    {
      type: "text",
      dataName: "name",
      headerLabel: "Contract name",
      sort: true,
    },
    {
      type: "text_currency",
      dataName: "totalSupplierCosts",
      headerLabel: "Supplier costs",
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      dataName: "totalLabourCosts",
      headerLabel: "Labour costs",
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
    {
      type: "text_currency",
      dataName: "incomeAdjustments",
      headerLabel: "incomes",
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
  ],
});

export default getStructure;
