import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import {
  AccountingSettings,
  TemporaryCosts,
} from "src/accurasee-backend-types/app/company_config/company_config.types";
import {
  useGetCompanyConfigQuery,
  useUpdateCompanyConfigsMutation,
} from "src/redux/services/CompanyConfigsService";
import { getStructure } from "./TemporaryCostSettingsStructure";
import BuildItems from "src/components/Builders/Container/BuildItems";

const TemporaryCostSettings = () => {
  const { data: companyConfigQuery, isLoading: isCompanyLoading } =
    useGetCompanyConfigQuery(undefined);
  const accountingSettings = companyConfigQuery?.data?.accountingSettings || {};
  const temporaryCostsSettings: Partial<TemporaryCosts> =
    accountingSettings?.temporaryCosts || {};

  const [updateCompanyConfig] = useUpdateCompanyConfigsMutation();

  const {
    formData,
    isFormValid,
    setFormData,
    structure,
    helperText,
    setHasTriedToSubmit,
  } = useFormContainer<Partial<TemporaryCosts>>({
    getStructure: getStructure,
    initialFormData: temporaryCostsSettings,
  });

  const { isSubmitting, onFormSubmit, refForm } =
    useOnFormSubmit<AccountingSettings>({
      submitProps: {
        apiMutations: { update: updateCompanyConfig },
        data: {
          update: {
            accountingSettings: {
              ...accountingSettings,
              temporaryCosts: formData,
            },
          },
        },
        dataId: String(companyConfigQuery?.data?._id),
        name: "Temporary costs settings",
      },
    });
  const commonPropsBuildItem: any = { data: formData, helperText };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        isLoading={isCompanyLoading}
        label="Temporary costs settings"
        // subLabel="Edit settings  for adjustments related to POC"
        rightBoxArea={
          <ButtonGroup>
            <ButtonClear
              id={"tmp-cost-clear-button"}
              onClick={() => {
                setFormData(temporaryCostsSettings);
              }}
            />
            <ButtonUpdate
              id={"tmp-cost-update-button"}
              initialSubmitData={temporaryCostsSettings}
              isValid={isFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={formData}
            />
          </ButtonGroup>
        }
      >
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </SectionTight>
    </form>
  );
};

export default TemporaryCostSettings;
