import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { CompanySuperAdminAddEdit } from "src/accurasee-backend-types/app/company/company.types";
import getTimes from "../../../../utils/getTimes";
import { tz } from "moment-timezone";
import { INTEGRATION_NAMES } from "../../../../accurasee-backend-types/app/integrations/integrationadapter.types";
import getSelectOptions from "../../../../utils/getSelectOptions";

export interface GetStructureExtraProps {
  data?: Partial<CompanySuperAdminAddEdit>;
  companyNames: string[];
  isEditForm?: boolean;
}

const getStructure: UseFormContainerGetStructure<
  Partial<CompanySuperAdminAddEdit>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const times = getTimes();
  const timezones = tz.names();

  const structure: StructureContainer<Partial<CompanySuperAdminAddEdit>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "displayName",
        label: "Display name",
        gridProps: { md: 12 },
        validate: (props: any) => {
          let labels = extraProps?.companyNames;

          return (
            props.data?.displayName.length >= 0 &&
            !labels?.includes(props.data?.displayName.toLowerCase().trim())
          );
        },
        getErrorText: () =>
          "Company Name already in use. Please select another one",
        setFormData,
      },
      {
        required: true,
        type: "selector",
        dataName: "integrationName",
        label: "Integration name",
        gridProps: { md: 6 },
        options: getSelectOptions({
          data: INTEGRATION_NAMES.map((s) => String(s)),
        }),
        setFormData,
        disabled: extraProps?.isEditForm,
      },
      {
        type: "selector",
        dataName: "reminderTime",
        label: "Time",
        gridProps: { xs: 6 },
        options: getSelectOptions({
          data: times,
        }),
        required: true,
        setFormData,
      },
      {
        type: "selector",
        dataName: "reminderTimeZone",
        label: "TimeZone",
        gridProps: { xs: 6 },
        options: getSelectOptions({
          data: timezones,
        }),
        required: true,
        setFormData,
      },
      {
        type: "date",
        dataName: "earliestImportDate",
        label: "Earliest import date",
        gridProps: { xs: 6 },
        setFormData,
      },
      {
        type: "switch",
        dataName: "bkConnection",
        label: "BK connection",
        gridProps: { xs: 6 },
        required: true,
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
