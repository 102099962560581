/**
 *Created by Mikael Lindahl on 2023-01-30
 */
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import { SxProps } from "@mui/system/styleFunctionSx";
import getHelperText from "../Utils/getHelperText";
import getIconByType from "src/components/Builders/Table/Utils/getIconByType";
import getOptions from "../Utils/getOptions";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import getTypeByStatus from "src/utils/getTypeByStatus";
import setValue from "../Utils/setValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import useStyles from "../BuildItemsStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import getValue from "../Utils/getValue";
import getSelectedOptions from "../Utils/getSelectedOptions";
import getIsInActiveWarning from "../../../../utils/getIsInActiveWarning";

type SelectorItemTProps = CommonBuilderContainerTypes & {
  isColor?: boolean;
  iconPosition?: "start" | "end";
};

const SelectorItem = (props: SelectorItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  let helperTextProps = { helperText: props.helperText, item: props.item, t };

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "SelectorItem",
    });
  }

  const required = getRequired(commonCallbackProps);

  const value = getValue(commonCallbackProps);

  let options = getOptions(commonCallbackProps);

  const selectedOptions = getSelectedOptions(commonCallbackProps);

  const sx: SxProps = {
    "& .MuiSelect-select": {
      padding: "var(--space-12px) var(--space-16px)",
    },
    ...(props?.item?.noCapitalize ? {} : { textTransform: "capitalize" }),
    ...(props?.isColor && typeof value === "string"
      ? {
          "& .MuiSelect-select": {
            backgroundColor: getStylingByStatusType(getTypeByStatus(value))
              .backgroundColor,
            color: getStylingByStatusType(getTypeByStatus(value)).color,
          },
        }
      : {}),
    ...(props?.iconPosition
      ? {
          "& .MuiSelect-select": {
            display: "flex",
            columnGap: "var(--space-4px)",
            justifyContent: "left",
            ...(props?.iconPosition === "end"
              ? { flexFlow: "row-reverse" }
              : {}),
          },
        }
      : {}),
    ...(props.item?.sx ? props.item?.sx : {}),
  };

  const itemSx = {
    ...(props?.item?.noCapitalize ? {} : { textTransform: "capitalize" }),
    ...(props?.iconPosition
      ? {
          display: "flex",
          columnGap: "var(--space-4px)",
          justifyContent: "left",
          ...(props?.iconPosition === "end" ? { flexFlow: "row-reverse" } : {}),
        }
      : {}),
  };

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <FormControl
        error={Boolean(getHelperText(helperTextProps))}
        sx={{
          width: "100%",
          ...(selectedOptions?.some((o) => o.isActive === false) && {
            "& .MuiOutlinedInput-root": {
              borderColor: "var(--warning)",
              "& fieldset": {
                borderColor: "var(--warning)", // Set your desired color here
              },
              "&:hover fieldset": {
                borderColor: "var(--warning)", // Color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "var(--warning)", // Color when focused
              },
            },
          }),
        }}
      >
        {props.item.label && (
          <InputLabel
            required={required}
            shrink
            htmlFor={props.item.dataName}
            id={props.item.dataName}
            className={classes.labelFieldStyle}
          >
            {t(props.item.label)}
          </InputLabel>
        )}
        <Select
          // default to display 5 entries at a time
          MenuProps={{ sx: { maxHeight: "232px", ...props.item.sx } }}
          labelId={props.item.dataName}
          id={props.item.dataName}
          disabled={props.itemDisabled}
          name={props.item.dataName}
          // if item?.selectionOptions?.multiple then value should be array otherwise string for single selector
          value={
            !options || options.length === 0 || value === null ? "" : value
          } // To avoid MUI warning out-of-range
          multiple={props.item?.selectionOptions?.multiple}
          required={required}
          onChange={(event) => {
            let changeProps = {
              ...commonCallbackProps,
              value: event.target.value,
            };

            if (props.item.setFormDataCustom) {
              props.item.setFormDataCustom(changeProps);
            } else {
              setValue(changeProps);
            }
          }}
          sx={sx}
        >
          {options?.map((option) => {
            const type = getTypeByStatus(option.value);
            const { color } = getStylingByStatusType(type);
            const { icon } = getIconByType(type);

            return (
              <MenuItem
                key={`${props.item.dataName}.${option.value}`}
                value={option.value}
                disabled={
                  !option?.isActive && !props.item?.selectionOptions?.multiple
                }
                sx={{
                  ...(props?.isColor ? { color } : {}),
                  ...itemSx,
                  ...(!option?.isActive &&
                    props.item?.selectionOptions?.multiple && {
                      color: "gray", // Example disabled color
                      // pointerEvents: "none", // Disable pointer events
                    }),
                }}
              >
                {props?.iconPosition !== undefined ? icon : <></>}
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        {selectedOptions?.some((o) => o.isActive === false) && (
          <FormHelperText sx={{ color: "var(--gray-3)" }}>
            {getIsInActiveWarning(selectedOptions)}
          </FormHelperText>
        )}
        {getHelperText(helperTextProps) && (
          <FormHelperText>{getHelperText(helperTextProps)}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  ) : (
    <></>
  );
};

export default SelectorItem;
