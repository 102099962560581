/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { StructureItemTable } from "../CommonBuilderTableTypes";
import { getNestedObjectData } from "../../../../utils/nestedData";

type GetValueTProps = {
  data?: any;
  item: StructureItemTable;
  rowIndex: number;
};

const getIsEstimate = (props: GetValueTProps) => {
  const value =
    props?.data &&
    (typeof props.item.getIsEstimate === "string"
      ? getNestedObjectData({
          data: props.data[props.rowIndex],
          key: props.item.getIsEstimate,
        })
      : props.item.getIsEstimate
        ? props.item.getIsEstimate({
            data: props.data,
            rowIndex: props.rowIndex,
            item: props.item,
          })
        : false);

  return value;
};

export default getIsEstimate;
