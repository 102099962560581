/**
 *Created by Mikael Lindahl on 2022-12-12
 */

import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";

type VatWarningTooltipTProps = {
  color: SvgIconTypeMap["props"]["color"];
  message: string;
};

const VatWarningTooltip = (props: VatWarningTooltipTProps) => {
  return props.message ? (
    <Tooltip title={props.message} placement="top-end">
      <span
        style={{
          display: "inline-flex",
          transform: "translateY(5px)",
        }}
      >
        <ErrorOutlineOutlinedIcon
          fontSize="small"
          sx={{ width: 17, height: 17, marginLeft: "4px" }}
          color={"disabled" || props.color}
        />
        {/*</IconButton>*/}
      </span>
    </Tooltip>
  ) : (
    <></>
  );
};

export default VatWarningTooltip;
