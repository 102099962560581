import {
  ForgotPasswordInputProps,
  getStructure,
} from "./ForgotPasswordGetStructure";
import { Fade, Typography, Link } from "@mui/material";
import { reset as resetUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import isDisableSubmit from "src/utils/isDisableSubmit";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useLoginStyles from "../LoginStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface ForgotPasswordProps {
  setActiveTabId: (v: number) => void;
  setResetPasswordEmail: (v: string) => void;
}

const ForgotPassword = ({
  setActiveTabId,
  setResetPasswordEmail,
}: ForgotPasswordProps) => {
  const [t] = useTranslation();
  const classes = useLoginStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState<"Failed" | null>(null);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const initialFormData = {
    loginEmail: process.env.REACT_APP_DEFAULT_LOGIN_EMAIL_VALUE || "",
  };

  const { formData, helperText, isFormValid, hasTriedToSubmit, structure } =
    useFormContainer<Partial<ForgotPasswordInputProps>>({
      getStructure,
      initialFormData,
    });

  const submitDisabled = isDisableSubmit({
    data: formData,
    initialData: {},
    isValid: isFormValid,
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
      }}
    >
      <Typography className={classes.title}>{t("Forgot password?")}</Typography>
      <Typography className={classes.subTitle}>
        {t("Enter your email and we'll send you a password reset link")}
      </Typography>
      <SectionContainer>
        <Section boxStyle={{ padding: "var(--space-16px) 0" }}>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer>
        <ButtonPrimary
          disabled={
            (submitDisabled && !hasTriedToSubmit) || isResettingPassword
          }
          onClick={() => {
            if (!formData?.loginEmail) return;
            const { loginEmail } = formData;
            setIsResettingPassword(true);
            resetUser(loginEmail)(dispatch)
              .then((data) => {
                setTimeout(() => {
                  setIsResettingPassword(false);
                }, 10000);
                enqueueSnackbar(
                  t(
                    "A password reset email has been sent to your email address",
                  ),
                  { variant: "success" },
                );
                setResetPasswordEmail(loginEmail);
                setActiveTabId(3);
              })
              .catch((err) => {
                console.error(err);
                setIsResettingPassword(false);
                enqueueSnackbar("Can't sent email", { variant: "error" });
                setError(
                  err.response.data.error.message ?? "Request is failed",
                );
              });
          }}
          className={classes.loginButton}
          type="submit"
        >
          {t("Send Request")}
        </ButtonPrimary>
      </SectionWithButtonContainer>
      {error ? (
        <Fade in={!!error}>
          <Typography
            color="error"
            mt="var(--space-24px)"
            className={classes.errorMessage}
          >
            {error}
          </Typography>
        </Fade>
      ) : (
        <></>
      )}
      <Link
        style={{
          cursor: "pointer",
          display: "block",
          fontWeight: 500,
          marginTop: "var(--space-24px)",
          textAlign: "center",
          textDecoration: "none",
        }}
        onClick={() => setActiveTabId(0)}
      >
        <ArrowBackIcon
          fontSize="small"
          sx={{ marginRight: "var(--space-8px)", verticalAlign: "middle" }}
        />
        {t("Back to Login")}
      </Link>
    </form>
  );
};

export default ForgotPassword;
