import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import {
  AccessEndpointMap,
  CheckAccessPermissions,
} from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";
import getStackTrace from "./getStackTrace";

export const getPermission = ({
  accessEndpointMap,
  path,
}: {
  accessEndpointMap: Partial<AccessEndpointMap>;
  path: RoutePath;
}) => {
  if (accessEndpointMap && !accessEndpointMap[path]) {
    console.error(`No permission found for path: ${path}`);
    console.error(getStackTrace());
  }

  const accessEndpoint = accessEndpointMap && accessEndpointMap[path];

  const returnEntry: CheckAccessPermissions = {
    readRights: false,
    writeRights: false,
    ...(accessEndpoint && accessEndpoint.permissions),
  };

  return returnEntry;
};
