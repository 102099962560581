import { HIDDEN_COMPONENT_TYPE } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { HiddenComponentContext } from "src/context/HiddenComponentProvider";
import { useContext } from "react";

const useHeaderHiddenComponents = () => {
  const { hiddenComponentConfigs } = useContext(HiddenComponentContext);

  const headerHiddenComponents =
    hiddenComponentConfigs.find(
      (item) =>
        item.hiddenComponentType ===
        HIDDEN_COMPONENT_TYPE.HEADER_LINK_MAIN_PAGE,
    )?.hiddenComponents || [];
  return headerHiddenComponents;
};

export default useHeaderHiddenComponents;
