/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { BuilderContainerCallbackProps } from "../CommonBuilderContainerTypes";

const parseRequired = (props: BuilderContainerCallbackProps) => {
  return (
    (typeof props.item.disabled === "function"
      ? props.item.disabled(props)
      : props.item.disabled) || false
  );
};

export default parseRequired;
