import { DimensionExtra } from "src/redux/services/DimensionService";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";

const getStructure: UserFormTableGetStructure<DimensionExtra> = () => ({
  items: [
    {
      dataName: "number",
      headerLabel: "#",
      type: "text",
      sort: true,
    },
    {
      dataName: "name",
      headerLabel: "Name",
      type: "text",
      sort: true,
    },
    {
      type: "switch",
      dataName: "active",
      headerLabel: "Active",
    },
  ],
});
export default getStructure;
