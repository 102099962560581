/**
 *Created by Mikael Lindahl on 2024-09-17
 */

import getSelectOptions from "./getSelectOptions";

const getSelectOptionsTextRow = () => {
  return getSelectOptions({
    data: [
      {
        value: null,
        label: "Text row",
      },
    ],
  });
};

export default getSelectOptionsTextRow;
