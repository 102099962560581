import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import LinearProgress from "@mui/material/LinearProgress";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import structure from "./AccountsStructure";
import useMUIDataTableFilterStates from "src/hooks/useMUIDataTableFilterStates";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Account } from "src/accurasee-backend-types/app/account/account.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetAccountsQuery } from "src/redux/services/AccountService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";

// TODO remove this component?
const Accounts = () => {
  const [t] = useTranslation();
  const { additionalFilter, baseFilter, params, ...setFunctions } =
    useMUIDataTableFilterStates();

  const { data, isLoading } = useGetAccountsQuery(params);

  const accounts = (data ? data?.data : []).map((i, index) => ({
    rowNumber: String(index + 1),
    ...i,
  }));

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<Account>({
      data: accounts,
      item,
    }),
  );

  const count = data?.metadata.count || 0;

  const { options } = useMUIDatTableOptions({
    dataFetchResult: { data, isLoading },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Name").toLowerCase()} ${t(
        "or",
      )} ${t("Number").toLowerCase()}...`,
    },
    filterStates: { additionalFilter, baseFilter, ...setFunctions },
    pagination: count > 10,
    serverSide: true,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Page
      label={"Accounts"}
      breadcrumbs={[{ label: "Operations" }, { label: "Accounts" }]}
    >
      <SectionContainer>
        <MuiDataTableWrapper
          data={accounts}
          columns={columns}
          options={options}
          tableType="main-with-filter"
          noBackground={true}
        />
      </SectionContainer>
    </Page>
  );
};

export default Accounts;
