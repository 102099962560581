/**
 *Created by Mikael Lindahl on 2023-11-23
 */
import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { InviteSend } from "src/accurasee-backend-types/app/invite/invite.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { Types } from "mongoose";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getSelectOptions from "src/utils/getSelectOptions";

export type GetStructureExtraProps = {
  userRoles?: AccessUserRole[];
};
export const getStructure: UseFormContainerGetStructure<
  InviteSend,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<InviteSend> = {
    items: [
      {
        type: "text_input",
        required: true,
        dataName: "inviteEmail",
        label: "Email",
        setFormData,
      },
      {
        type: "autocomplete",
        required: true,
        dataName: "inviteRoleIds",
        label: t("Role(s)"),
        selectionOptions: { multiple: true },
        options: getSelectOptions({
          data: extraProps?.userRoles,
          label: (c) => t(c.name),
          value: (c) => String(c?._id),
        }),
        setFormDataCustom: (props) => {
          if (props.data) {
            let inviteRoleIds: Types.ObjectId[] = [];
            if (Array.isArray(props.value)) {
              inviteRoleIds = props.value;
            } else {
              inviteRoleIds = [props.value];
            }
            setFormData({ ...props.data, inviteRoleIds });
          }
        },
      },
    ],
  };
  return structure;
};
