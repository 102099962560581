/**
 *Created by Mikael Lindahl on 2023-01-30
 */
import { Grid, InputLabel } from "@mui/material";
import BuildItemInternal, { BuildItem } from "../BuildItemInternal";
import useStyles from "../BuildItemsStyles";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import useTranslation from "src/hooks/useTranslationWrapper";

type ObjectItemTProps = BuildItem;

const ObjectItem = (props: ObjectItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  return getShowWhen(commonCallbackProps) ? (
    <Grid container item {...props.item.gridProps} alignItems={"start"}>
      {props.item.label && (
        <Grid key={"label"} item xs={12} color={"var(--gray-3)"}>
          <InputLabel
            required={getRequired(commonCallbackProps)}
            className={classes.labelFieldStyle}
          >
            {t(props.item.label)}
          </InputLabel>
        </Grid>
      )}
      <Grid
        container
        key={"content"}
        alignItems={"start"}
        columnSpacing={1}
        rowSpacing={3}
      >
        {props.item.items?.map((subItem: any, index: number) => {
          return (
            <BuildItemInternal
              key={`${props.index}-${index}`}
              {...props}
              index={`${props.index}-${index}`}
              item={subItem}
            />
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default ObjectItem;
