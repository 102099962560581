import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { ReturnCompanyWithPermissions } from "src/accurasee-backend-types/app/company/company.types";
import getCountryName from "src/utils/getCountryName";
import getTimes from "../../../../../../utils/getTimes";
import { tz } from "moment-timezone";
import getSelectOptions from "../../../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  templateNames: string[];
  users?: ReturnUser[];
};

export const getStructure: UseFormContainerGetStructure<
  ReturnCompanyWithPermissions,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const times = getTimes();
  const timezones = tz.names();

  const structure: StructureContainer<ReturnCompanyWithPermissions> = {
    items: [
      {
        gridProps: { xs: 4 },
        type: "text",
        disabled: true,
        dataName: "name",
        label: "Company name",
      },
      {
        gridProps: { xs: 4 },
        type: "text",
        disabled: true,
        dataName: "country",
        label: "Country code",
        getValue: (props) =>
          props?.data?.country ? getCountryName.of(props?.data?.country) : "",
      },
      {
        gridProps: { xs: 4 },
        type: "text",
        disabled: true,
        dataName: "address1",
        label: "Street name",
      },
      {
        gridProps: { xs: 4 },
        type: "text",
        disabled: true,
        dataName: "zipCode",
        label: "Zipcode",
      },
      {
        gridProps: { xs: 4 },
        type: "text",
        disabled: true,
        dataName: "city",
        label: "City",
      },
      {
        gridProps: { xs: 4 },
        type: "text",
        dataName: "organisationNumber",
        label: "Organisation number",
      },
      {
        gridProps: { xs: 4 },
        type: "text_input",
        dataName: "displayName",
        label: "Display name",
        setFormData,
      },
      {
        type: "selector",
        dataName: "reminderTime",
        label: "Email reminder sendout time",
        gridProps: { xs: 4 },
        options: getSelectOptions({
          data: times,
        }),
        required: true,
        setFormData,
      },
      {
        type: "selector",
        dataName: "reminderTimeZone",
        label: "Email reminder sendout time zone",
        gridProps: { xs: 4 },
        options: getSelectOptions({
          data: timezones,
        }),
        required: true,
        setFormData,
      },
    ],
  };

  return structure;
};
