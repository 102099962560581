import {
  HelperTextArray,
  StructureItemTable,
} from "../CommonBuilderTableTypes";

/**
 *Created by Mikael Lindahl on 2023-03-14
 */

type GetHelperTextTProps = {
  helperText?: HelperTextArray;
  item: StructureItemTable;
  rowIndex: number;
};

const getHelperText = (props: GetHelperTextTProps) => {
  let helperText =
    props.helperText &&
    props.helperText[props.rowIndex][props.item.dataName as string];

  return helperText;
};

export default getHelperText;
