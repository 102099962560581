/**
 *Created by Mikael Lindahl on 2024-02-28
 */

import { INTEGRATION_IMPORTS_DEPENDENCIES } from "../../accurasee-backend-types/shared/constants/integration.constants";
import { IntegrationImportJobName } from "../../accurasee-backend-types/app/integration_import_job/integration_import_job.types";

const getDependenciesForIntegration = ({
  integrationImportJobName,
}: {
  integrationImportJobName: IntegrationImportJobName;
}) => {
  let stop = false;

  let currentIntegrationNames: IntegrationImportJobName[] = [
    integrationImportJobName,
  ];

  let dependencies: IntegrationImportJobName[] = [];

  while (!stop) {
    const nextIntegrationNames: IntegrationImportJobName[] = [];

    for (const currentIntegrationName of currentIntegrationNames) {
      const currentIntegrationDependencies = [
        ...INTEGRATION_IMPORTS_DEPENDENCIES[currentIntegrationName],
        currentIntegrationName,
      ];
      const numberOfDependencies = currentIntegrationDependencies.length;

      for (const dependency of currentIntegrationDependencies) {
        // if (!dependencies.includes(dependency)) {
        // Find the index of the element with 'abc'
        let indexCurrent = dependencies.indexOf(currentIntegrationName);
        let indexDependency = dependencies.indexOf(dependency);
        // Check if 'abc' was found
        if (indexCurrent !== -1 && indexDependency === -1) {
          // Insert 'dependency' right before the found index
          dependencies.splice(indexCurrent, 0, dependency);
        } else if (indexCurrent !== -1 && indexCurrent < indexDependency) {
          dependencies.splice(indexDependency, 1);
          dependencies.splice(indexCurrent, 0, dependency);
        } else if (indexDependency === -1) {
          // If 'abc' is not found, you can push to the end or handle as needed
          dependencies.push(dependency);
        }

        if (indexDependency === -1 && numberOfDependencies > 1) {
          nextIntegrationNames.push(dependency);
        }
        // }
      }
    }

    if (nextIntegrationNames.length === 0) {
      stop = true;
    }

    currentIntegrationNames = nextIntegrationNames;
  }

  return dependencies;
};

export default getDependenciesForIntegration;
