import { useNavigate } from "react-router-dom";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import ContractsTable from "src/screens/App/Contracts/components/ContractsTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import usePathPermission from "src/hooks/usePathPermission";

const Contracts = () => {
  const contractFilter = {
    status: ["upcoming", "ongoing", "invoiced", "partiallyinvoiced"],
    sortBy: "projectExternalId",
    sort: "desc",
  };
  const navigate = useNavigate();
  const pathPermissions = usePathPermission("/app/contracts/create");

  return (
    <Page
      label={"Contract list"}
      breadcrumbs={[{ label: "Contracts" }]}
      boxRight={
        <ButtonAddBoxIcon
          id="direct-contract-create-page"
          permissions={pathPermissions}
          label="Create contract"
          onSubmit={() => navigate("/app/contracts/create")}
        />
      }
    >
      <ContractsTable contractFilter={contractFilter} />
    </Page>
  );
};

export default Contracts;
