import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { TemporaryCosts } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { isNumber } from "lodash";

export type GetStructureExtraProps = {};

export const getStructure: UseFormContainerGetStructure<
  Partial<TemporaryCosts>,
  GetStructureExtraProps
> = ({ extraProps, setFormData, t }) => {
  const structure: StructureContainer<Partial<TemporaryCosts>> = {
    items: [
      {
        type: "number_input",
        dataName: "deletionDay",
        label: "Delete costs before date",
        validate: ({ data }) => {
          return (
            !!data?.deletionDay &&
            isNumber(data?.deletionDay) &&
            data.deletionDay > 0 &&
            data.deletionDay <= 31
          );
        },
        getErrorText: ({ data }) => {
          if (!isNumber(data?.deletionDay)) {
            return "Must be a number";
          } else if (data?.deletionDay && data.deletionDay <= 0) {
            return "Value must be greater than 0";
          } else if (data?.deletionDay && data.deletionDay > 31) {
            return "Value must be less than 32";
          } else return "";
        },
        setFormData,
        gridProps: { md: 4 },
      },
    ],
  };
  return structure;
};
