import { format } from "date-fns";

import moment from "moment-timezone";
import { StructureItemContainer } from "../components/Builders/Container/CommonBuilderContainerTypes";
import { TableItemType } from "src/accurasee-backend-types/frontend/Table/CommonBuilderTableTypesShared";
import clone from "./clone";
import { getNestedObjectData, setNestedObjectData } from "./nestedData";
import _c from "src/constants/Constants";

export const toDate = (date: any) => {
  date = moment(date);

  return date.isValid() ? moment(date).format(_c.DATE_FORMAT_SHORT) : null;
};

const toDay = (date: Date | string | undefined) => {
  try {
    if (date) {
      return format(new Date(date), "yyyy-MM-dd");
    } else {
      return "";
    }
  } catch (e) {
    if (typeof date === "string") {
      return date;
    } else {
      return "";
    }
  }
};

export const toEndOfMonthDate = (date: string | Date) => {
  return new Date(toEndOfMonth(date));
};

export const toEndOfMonth = (date: string | Date) => {
  return moment(date).endOf("month").format(_c.DATE_FORMAT_SHORT);
};

export const toStartOfMonthDate = (date: string) => {
  return new Date(toStartOfMonth(date));
};

export const toStartOfMonth = (date: string) => {
  return moment(date).startOf("month").format(_c.DATE_FORMAT_SHORT);
};

export const toPastDate = (date: Date, range: number) =>
  moment(date).subtract(range, "months");

export const toFutureDate = (date: string | Date, range: number) =>
  moment(date).add(range, "months");

export default toDay;

export const validDate = <DataType = any>(
  value: any,
  itemComponentType?: StructureItemContainer<DataType>["type"] | TableItemType,
) => {
  if (
    !itemComponentType ||
    itemComponentType === "date" ||
    itemComponentType === "date_input"
  ) {
    const isValid = moment(value).isValid() || value === "";

    return isValid;
  } else {
    return true;
  }
};

const compareDates = (d1: any, d2: any) => {
  if (validDate(d1) && validDate(d2)) {
    d1 = new Date(d1);
    d2 = new Date(d2);

    return d1.getTime() === d2.getTime() ? 0 : d1 < d2 ? -1 : 1;
  } else {
    return null;
  }
};

export const lessThan = (d1: any, d2: any) => {
  const cmp = compareDates(d1, d2);

  return cmp === -1 || cmp === null;
};

export const lessOrEqualThan = (d1: any, d2: any) => {
  const cmp = compareDates(d1, d2);

  return cmp === -1 || cmp === 0 || cmp === null;
};

export const setFormDataDatePair = (props: {
  valueKey: string;
  otherKey: string;
  otherAction:
    | "to_end_of_month"
    | "to_start_of_month"
    | "to_past_of_year"
    | "to_future_of_year";
  rangeDiff?: number;
  data: any;
  value: any;
  setFormData: (data: any) => void;
}) => {
  if (props.data) {
    let newData = {
      ...clone(props.data),
    };

    // Only update other if value i a valid date
    if (validDate(props.value)) {
      const currentOther =
        getNestedObjectData({ data: newData, key: props.otherKey }) ||
        new Date();
      if (props.otherAction === "to_past_of_year") {
        const from = moment(newData.from);
        const to = moment(props.value);

        const other =
          props.rangeDiff &&
          (to.diff(from, "months") > props.rangeDiff || lessThan(to, from))
            ? toPastDate(props.value, props.rangeDiff)
            : currentOther;

        setNestedObjectData({
          data: newData,
          key: props.otherKey,
          value: other,
        });
      } else if (props.otherAction === "to_future_of_year") {
        const from = moment(props.value);
        const to = moment(newData.to);

        const other =
          props.rangeDiff &&
          (to.diff(from, "months") > props.rangeDiff || lessThan(to, from))
            ? toFutureDate(props.value, props.rangeDiff)
            : currentOther;

        setNestedObjectData({
          data: newData,
          key: props.otherKey,
          value: other,
        });
      } else {
        const other = (
          props.otherAction === "to_end_of_month"
            ? lessThan(currentOther, props.value)
            : lessThan(props.value, currentOther)
        )
          ? props.otherAction === "to_end_of_month"
            ? toEndOfMonthDate(props.value)
            : toStartOfMonthDate(props.value)
          : currentOther;

        setNestedObjectData({
          data: newData,
          key: props.otherKey,
          value: other,
        });
      }
    }

    setNestedObjectData({
      data: newData,
      key: props.valueKey,
      value: props.value,
    });

    props.setFormData(newData);
  }
};
