/**
 *Created by Mikael Lindahl on 2023-01-25
 */

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useStyles from "./SectionTightStyles";
import BoxHeadingTight from "../Headings/BoxHeadingTight";
import { Card, CardContent, Divider } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

type CardSectionTightTProps = {
  alignTextCenter?: boolean;
  children?:
    | boolean
    | JSX.Element
    | string
    | (JSX.Element | boolean | string)[];
  containsTable?: boolean;
  isLoading?: boolean;
  label?: string;
  lg?: number;
  md?: number;
  rightBoxArea?: JSX.Element | JSX.Element[];
  subLabel?: string;
  xs?: number;
};

const SectionTight = ({
  alignTextCenter,
  children,
  containsTable,
  isLoading,
  label,
  rightBoxArea,
  subLabel,
  ...gridProps
}: CardSectionTightTProps) => {
  const classes = useStyles();

  return (
    <Grid item {...{ xs: 12, ...gridProps }}>
      <Card sx={{ width: "100%", borderRadius: "12px", boxShadow: "none" }}>
        <Box className={classes.boxSectionTight}>
          <BoxHeadingTight
            alignTextCenter={alignTextCenter}
            label={label}
            rightBoxArea={rightBoxArea}
            subLabel={subLabel}
          />
          <Divider />
          <CardContent
            sx={{
              padding: containsTable
                ? 0
                : "var(--space-16px) var(--space-24px)",
            }}
          >
            <Grid container rowSpacing={3} columnSpacing={1}>
              {isLoading ? (
                <Box
                  width={"100%"}
                  sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
                >
                  <LinearProgress />
                </Box>
              ) : containsTable ? (
                <Grid item xs={12}>
                  {children}
                </Grid>
              ) : (
                children
              )}
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default SectionTight;
