/**
 *Created by Mikael Lindahl on 2023-10-17
 */
/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "../../../../hooks/useFormTable";
import {
  ProjectTrackerPercentageOfCompletion,
  ProjectTrackerPercentageOfCompletionSub,
} from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import getMonthAsString from "../../../../utils/getMonthAsString";

export type GetStructureExtraProps = {
  nameLabel: "Region" | "Company" | "Project";
};

export type PercentageOfCompletionItemTypes = "main" | "sub" | "main-and-sub";

export const getStructure: UserFormTableGetStructureOptional<
  | ProjectTrackerPercentageOfCompletion
  | ProjectTrackerPercentageOfCompletionSub,
  GetStructureExtraProps,
  PercentageOfCompletionItemTypes
> = () => {
  return {
    items: [
      {
        type: "text",
        dataName: "userName",
        headerLabel: "Project leader",
        itemType: "main",
      },
      {
        type: "text",
        dataName: "projectName",
        headerLabel: "Project",
        itemType: "sub",
      },
      {
        type: "text",
        dataName: "hoursWorked",
        headerLabel: "Hours worked",
        itemType: "sub",
      },
      {
        type: "text",
        dataName: "supplierCost",
        headerLabel: "Supplier cost",
        itemType: "sub",
      },
      {
        type: "percent",
        dataName: "calculatedCompletion",
        headerLabel: "Cal. completion",
        itemType: "sub",
      },
      {
        type: "percent",
        dataName: "estimatedCompletion",
        headerLabel: "Est. comletion",
        itemType: "sub",
      },
      {
        type: "percent",
        dataName: "estimatedCoverageRate",
        headerLabel: "Est. Coverage Rate",
        itemType: "sub",
      },
      {
        type: "text_currency",
        dataName: "estimatedProjectResult",
        headerLabel: "Est. Project Result",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "percentageOfCompletionCurrent",
        headerLabel: "Current POC",
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },

      {
        type: "text_currency",
        dataName: "percentageOfCompletionMinus1",
        headerLabel: `POC ${getMonthAsString(-1)}`,
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "percentageOfCompletionMinus2",
        headerLabel: `POC ${getMonthAsString(-2)}`,
        itemType: "main-and-sub",
        alignColumnContent: "end",
      },
    ],
  };
};
