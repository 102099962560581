import "swagger-ui-react/swagger-ui.css";
import { useEffect } from "react";
import { useGetDocumentationExternalQuery } from "src/redux/services/DocumentationExternalService";
import SwaggerUI from "swagger-ui-react";

const DocumentationExternal = () => {
  const {
    data: swaggerData,
    error,
    isLoading,
  } = useGetDocumentationExternalQuery(undefined);

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch documentation external:", error);
    }
  }, [error]);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        swaggerData && <SwaggerUI spec={swaggerData} />
      )}
    </div>
  );
};

export default DocumentationExternal;
