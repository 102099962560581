import {
  GetStructureExtraProps,
  getSubcontractOfferStructure,
} from "./ContractOffersStructure";
import { Box, LinearProgress } from "@mui/material";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetSubcontractOffersQuery } from "src/redux/services/ContractOfferService";
import { userMUIDatSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useFormTable from "src/hooks/useFormTable";

interface SubcontractOfferTableProps {
  contractOffer: ContractOffer;
}

const SubcontractOfferTable = ({
  contractOffer,
}: SubcontractOfferTableProps) => {
  const { data: subcontractOffersQuery, isLoading } =
    useGetSubcontractOffersQuery(contractOffer?._id, {
      skip: !contractOffer?._id,
    });
  const subcontractOffers = subcontractOffersQuery?.data || [];
  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data;
  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { structure } = useFormTable<
    Partial<ContractOffer>,
    GetStructureExtraProps
  >({
    extraProps: { customers, users },
    initialFormData: subcontractOffers,
    getStructure: getSubcontractOfferStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: subcontractOffers,
      item,
    }),
  );
  const options = userMUIDatSubTableOptions({});

  if (isLoading || isLoadingCustomers || isLoadingUsers) {
    return (
      <Box paddingY={1}>
        <LinearProgress />
      </Box>
    );
  }

  console.log("subcontractOffers", subcontractOffers);

  return (
    <MuiDataTableWrapper
      data={subcontractOffers}
      columns={columns}
      options={options}
      tableType="main-nested-level-1-centered"
    />
  );
};

export default SubcontractOfferTable;
