import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";

type ButtonUpdateTProps = Omit<ButtonSubmitCoreTProps, "label">;

const ButtonUpdate = (props: ButtonUpdateTProps) => {
  return <ButtonSubmitCore {...props} label={"update"} />;
};

export default ButtonUpdate;
