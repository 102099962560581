import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import getValue from "src/components/Builders/Container/Utils/getValue";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { ModifiedInvoicePlanTemplate } from "src/redux/services/InvoiceplanService";
import toUpdateData from "src/utils/toUpdateData";
import clone from "src/utils/clone";
import { InvoicePlanRow } from "../../../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import getSelectOptionsDayMonthQuarterYear from "../../../utils/getSelectOptionsDayMonthQuarterYear";
import getSelectOptions from "../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  templateNames?: string[];
  users?: ReturnUser[];
  view: "create" | "edit";
};

export const getStructure: UseFormContainerGetStructure<
  InvoicePlanTemplate,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<InvoicePlanTemplate> = {
    label: `${extraProps?.view} invoice plan template`,
    items: [
      {
        gridProps: { xs: 6 },
        getErrorText: ({ data }: any) => {
          if (data.name && data.name.length < 3) {
            return "Template name need to be greater than 3";
          }
          return "Name already in use. Please select another one";
        },
        validate: (props) => {
          const value = getValue(props);

          if (value) {
            if (value.length < 3) {
              return false;
            }
            return !extraProps?.templateNames?.includes(
              value.toLowerCase().trim(),
            );
          }
          return true;
        },
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Template names",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "selector",
        required: true,
        dataName: "type",
        label: "Type",
        disabled: true,
        options: getSelectOptions({
          data: [
            {
              label: "Milestone",
              value: "milestone",
            },
            {
              label: "Periodic",
              value: "periodic",
            },
            // {
            //   label: "Fixed price",
            //   value: "fixedprice",
            // },
            {
              label: "Running rate",
              value: "runningrate",
            },
            // {
            //   label: "Custom",
            //   value: "custom",
            // },
          ].map((c) => ({ label: t(c.label), value: c.value })),
        }),
      },
      {
        type: "object",
        showWhen: ({ data }) =>
          data?.type === "periodic" || data?.type === "runningrate",
        label: "Invoicing period",
        items: [
          {
            type: "object",
            items: [
              {
                gridProps: { xs: 4 },
                type: "number",
                dataName: "invoicingPeriod.repeats.number",
                setFormData,
              },
              {
                gridProps: { xs: 8 },
                type: "selector",
                dataName: "invoicingPeriod.repeats.unit",
                options: getSelectOptionsDayMonthQuarterYear(t),
                setFormData,
              },
            ],
          },
        ],
      },
      {
        showWhen: ({ data }) =>
          data?.type === "periodic" || data?.type === "runningrate",
        required: true,
        type: "invoicing_date",
        dataName: "invoicingPeriod.postingDate",
        label: "Invoicing date",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        showWhen: ({ data }) =>
          data?.type === "periodic" || data?.type === "runningrate",
        type: "selector",
        dataName: "reminder",
        label: "Reminder",
        options: getSelectOptions({
          data: [
            {
              label: "Invoice date",
              value: "invoicedate",
            },
            {
              label: "Weekly",
              value: "weekly",
            },
          ],
        }),
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "autocomplete",
        dataName: "ownerIds",
        label: "Invoice plan template owner(s)",
        selectionOptions: { multiple: true },
        validate: (props) => {
          const value = getValue(props);
          return value.length > 0;
        },
        getErrorText: () => "You need to select at least one owner",
        options: getSelectOptions({
          data: extraProps?.users,
          label: (user) => `${user.firstName} ${user.lastName}`,
          value: (user) => String(user?._id),
        }),
        setFormData,
      },
      {
        showWhen: ({ data }) => data?.type === "periodic",
        required: true,
        type: "switch",
        dataName: "invoicingPeriod.allowExternalRowAdding",
        label: "Allow external row addition",
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: InvoicePlanTemplate;
  initData?: InvoicePlanTemplate;
}) => {
  if (!data) {
    return;
  }
  let newRows = clone(data.invoiceRows);
  newRows = newRows?.map((element) => {
    return {
      articleId: element.articleId,
      articleNumber: element.articleNumber,
      discount: element.discount,
      houseWork: element.houseWork,
      houseWorkType: element.houseWorkType,
      isPeriodic: element.isPeriodic,
      isPricePerUnitEstimate: element.isPricePerUnitEstimate,
      isQuantityEstimate: element.isQuantityEstimate,
      itemDescription: element.itemDescription,
      textRow: element.textRow,
      unitId: element.unitId,
      offset: element.offset,
    } as Partial<InvoicePlanRow>;
  });
  // To pick necessary infos from invoiceplanTemplate
  const { _id } = data;
  const modifiedFormData = data as unknown as ModifiedInvoicePlanTemplate;
  const { name, type, reminder, invoicingPeriod, ownerIds } = modifiedFormData;
  // Create request data
  let partialData: Partial<InvoicePlanTemplate>;

  let newTemplate: ModifiedInvoicePlanTemplate = {
    name,
    type,
    reminder,
    id: _id,
    invoiceRows: newRows,
    invoicingPeriod: invoicingPeriod,
    ownerIds: ownerIds,
  };
  if (newTemplate._id) {
    partialData = toUpdateData<Partial<InvoicePlanTemplate>>({
      data: newTemplate,
      initData,
    });
  } else {
    partialData = newTemplate;
  }

  return partialData;
};
