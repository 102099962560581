import ReportFilters from "./ReportFilters/ReportFilters";
import CompanyProjectNumberSeries from "./CompanyProjectNumberSeries/CompanyProjectNumberSeries";
import Page from "src/components/Basic/Mixed/Pages/Page";

const NumberSeries = () => {
  return (
    <Page
      label={"Company number series"}
      breadcrumbs={[{ label: "Setup" }, { label: "Company number series" }]}
      isFullWidth={false}
    >
      <ReportFilters transactionType="cost" />
      <ReportFilters transactionType="income" />
      <CompanyProjectNumberSeries disabled={false} />
    </Page>
  );
};

export default NumberSeries;
