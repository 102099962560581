import { ReportTemplateYFilter } from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import { checkOverlappedNumber } from "src/screens/App/Setup/NumberSeries/CompanyProjectNumberSeries/lib/checkForOverlap";
import { IncomeWithPermissions } from "src/accurasee-backend-types/app/income/income.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { checkDimension } from "src/screens/App/Setup/NumberSeries/CompanyProjectNumberSeries/lib/Utils";
import { Types } from "mongoose";

export interface GetStructureExtraProps {
  currencyCode: string;
  idSummaries?: Types.ObjectId;
  reportYFilters: ReportTemplateYFilter[];
}

const getStructure: UserFormTableGetStructure<
  Partial<IncomeWithPermissions>,
  GetStructureExtraProps
> = ({ extraProps, t }) => {
  const idSummaries = extraProps?.idSummaries;

  return {
    items: [
      {
        type: "text",
        dataName: "_id",
        headerLabel: "#",
        filter: false,
        getValue: ({ data, rowIndex }) => {
          return data[rowIndex]._id !== idSummaries ? rowIndex + 1 : "Sum";
        },
      },
      {
        type: "text",
        dataName: "name",
        headerLabel: "Description",
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
      },
      {
        type: "text",
        dataName: "accountName",
        headerLabel: "Number series",
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        getValue: ({ data, rowIndex }) => {
          const accountNumber = data[rowIndex]?.accountNumber || "";
          const reportYFilters = extraProps?.reportYFilters || [];
          // No series to check
          if (extraProps?.reportYFilters?.length === 0) {
            return "-";
          }
          let label = "";
          reportYFilters.forEach((filter) => {
            const existingRanges = filter?.accountNumberSeries.map(
              ({ firstNumber, lastNumber }) => ({
                firstNumber: Number(firstNumber),
                lastNumber: Number(lastNumber),
              }),
            );
            const seriesRes = checkOverlappedNumber({
              existingRanges,
              num: accountNumber as number,
            });

            const dimensionRes = checkDimension(
              filter?.dimensions.map((d) => d.dimensionItemId),
              data[rowIndex],
            );
            // Add dimension check
            if (seriesRes?.isOverlapped && dimensionRes?.match) {
              label += filter?.label + ", ";
            }
          });

          // Slice to remvoe last comma
          return label.length > 0 ? label.slice(0, -2) : "-";
        },
      },
      {
        type: "text",
        dataName: "incomeType",
        headerLabel: "Type",
        filter: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        getValue: ({ data, rowIndex }) => {
          return t(data[rowIndex]?.incomeType || "");
        },
      },
      {
        type: "text_currency",
        dataName: "accountNumber",
        headerLabel: "Account number",
        filter: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        getValue: ({ data, rowIndex }) => data[rowIndex]?.accountNumber,
        alignColumnContent: "end",
      },
      {
        type: "text",
        dataName: "accountName",
        headerLabel: "Account name",
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== idSummaries,
        filter: true,
      },
      {
        type: "text_currency",
        dataName: "value",
        headerLabel: "Total income",
        filter: false,
        currencyCode: extraProps?.currencyCode,
        alignColumnContent: "end",
      },
    ],
  };
};

export default getStructure;
