import _c from "src/constants/Constants";

import {
  Customer,
  CustomerWithTotals,
} from "src/accurasee-backend-types/app/customer/customer.types";

import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import CustomerContainer from "src/components/Screens/Customer/CustomerContainer";

type CustomerModalTProps = {
  data?: CustomerWithTotals;
  openModal: boolean;
  setOpenModal: (v: boolean) => void;
  postCreate?: (data: Customer) => void;
};

export const CustomerModal = ({
  data,
  openModal,
  setOpenModal,
  postCreate,
}: CustomerModalTProps) => {
  return (
    <ModalWrapper
      handleCloseModal={() => setOpenModal(false)}
      openModal={openModal}
      label={data ? "EDIT CUSTOMER" : "CREATE A CUSTOMER"}
      alignTextCenter={false}
      boxStyle={{ width: "600px" }}
    >
      <CustomerContainer setOpenModal={setOpenModal} postCreate={postCreate} />
    </ModalWrapper>
  );
};

export default CustomerModal;
