/**
 *Created by Mikael Lindahl on 2024-02-05
 */

import CustomerInvoicesBase from "src/components/Screens/CustomerInvoices/CustomerInvoices";

const CustomerInvoices = () => {
  return <CustomerInvoicesBase />;
};

export default CustomerInvoices;
