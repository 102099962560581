import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getSelectOptions from "../../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  searchedDataLength: number;
  translationsLength: number;
};

const getStructure: UseFormContainerGetStructure<
  {
    exportType: "searched" | "all";
  },
  GetStructureExtraProps
> = ({ extraProps, setFormData }) => {
  const structure: StructureContainer<{ exportType: "searched" | "all" }> = {
    items: [
      {
        type: "toggle",
        dataName: "exportType",
        options: getSelectOptions({
          data: [
            {
              label: `SEARCHED RESULTS ONLY (${extraProps?.searchedDataLength})`,
              value: "searched",
            },
            {
              label: `ALL TRANSLATIONS (${extraProps?.translationsLength})`,
              value: "all",
            },
          ],
        }),
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
