import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import LinearProgress from "@mui/material/LinearProgress";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import getStructure from "./SuppliersStructure";
import useFormTable from "src/hooks/useFormTable";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import { MUIDataTableColumnDef } from "mui-datatables";
import { SupplierWithTotalsPurchased } from "src/accurasee-backend-types/app/supplier/supplier.types";
import { useGetSuppliersTableQuery } from "src/redux/services/SupplierService";

const Suppliers = () => {
  const {
    options,
    data: suppliers,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<SupplierWithTotalsPurchased>({
    dataName: "customers",
    filterInit: {
      base: {
        sort: "asc",
        sortBy: "number",
      },
    },
    useGetDataQuery: useGetSuppliersTableQuery,
  });

  const { structure } = useFormTable<SupplierWithTotalsPurchased>({
    initialFormData: suppliers,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<SupplierWithTotalsPurchased>({
      data: suppliers,
      item,
    }),
  );

  if (isLoading) return <LinearProgress />;

  return (
    <Page
      label={"Suppliers"}
      breadcrumbs={[{ label: "Operations" }, { label: "Suppliers" }]}
    >
      <MuiDataTableWrapper
        data={suppliers}
        columns={columns}
        isFetching={isFetching}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default Suppliers;
