import { EditRights } from "../general/editrights.types";
import { Types } from "mongoose";
import { LanguageCode } from "../language/language.types";

export interface Resource {
  [language: string]: ResourceLanguage;
}

export interface ResourceLanguage {
  [namespace: string]: ResourceKey;
}

export type ResourceKey = {
  [key: string]: any;
};

export type Catalog = Resource;

export const namespaces = ["backend", "common", "header"] as const;
export type Namespaces = (typeof namespaces)[number];
export type Translation = {
  _id?: Types.ObjectId;
  key: string;
  languages: { [key in LanguageCode]?: string };
  namespace: Namespaces;
  path?: string;
  stackTrace?: string;
};

export type TranslationPermissions = Translation & EditRights;
