import { Account } from "src/accurasee-backend-types/app/account/account.types";
import { DimensionExtra } from "src/redux/services/DimensionService";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { Types } from "mongoose";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import getSelectOptions from "src/utils/getSelectOptions";
import { AccountingAdjustment } from "src/accurasee-backend-types/app/company_config/company_config.types";

export type AccountingSettingKey =
  | "fixedATAAdjustment"
  | "fixedRentalAdjustment"
  | "fixedServiceOrderAdjustment"
  | "runningRateATAAdjustment"
  | "runningRateRentalAdjustment"
  | "runningRateServiceOrderAdjustment";

export type GetStructureExtraProps = {
  accounts: Account[];
  dimensions?: DimensionExtra[];
};

export type ItemType = "dimension" | "account";

export const getStructure: UseFormContainerGetStructure<
  Partial<AccountingAdjustment>,
  GetStructureExtraProps
> = ({ extraProps, setFormData }) => {
  const structure: StructureContainer<Partial<AccountingAdjustment>> = {
    items: [
      {
        itemType: "dimension",
        type: "autocomplete",
        dataName: "dimensionTemp",
        label: "Dimensions",
        gridProps: { md: 4 },
        options: getSelectOptions({
          data: extraProps?.dimensions?.flatMap((dimension) =>
            dimension.items.map((dimensionItem) => ({
              label: `${dimension.name} - ${dimensionItem.name}`,
              value: `${dimension._id}.${dimensionItem._id}`,
            })),
          ),
        }),
        setFormData,
      },
      {
        itemType: "account",
        type: "autocomplete",
        dataName: "salesAccount",
        label: "Main account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        setFormData,
        gridProps: { md: 4 },
      },
      {
        itemType: "account",
        type: "autocomplete",
        dataName: "constructionAccount",
        label: "Reversed charge account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        setFormData,
        gridProps: { md: 4 },
      },
      {
        itemType: "account",
        type: "autocomplete",
        dataName: "euAccount",
        label: "EU Reversed charge account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        setFormData,
        gridProps: { md: 4 },
      },
      {
        itemType: "account",
        type: "autocomplete",
        dataName: "euVatAccount",
        label: "EU account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        setFormData,
        gridProps: { md: 4 },
      },
      {
        itemType: "account",
        type: "autocomplete",
        dataName: "exportAccount",
        label: "Export account",
        options: getSelectOptions({
          data: extraProps?.accounts,
          label: (account) => `${account.number} - ${account.description}`,
          value: (account) => Number(account.number),
        }),
        setFormData,
        gridProps: { md: 4 },
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
}: {
  data?: Partial<AccountingAdjustment & { dimensionTemp: string }>;
}) => {
  if (!data) {
    return;
  }
  let partialData = clone(data);

  // re-structure values for dimension
  if (partialData?.dimensionTemp) {
    const dimension = {
      dimensionId: new Types.ObjectId(
        partialData?.dimensionTemp?.split(".")[0],
      ),
      dimensionItemId: new Types.ObjectId(
        partialData?.dimensionTemp?.split(".")[1],
      ),
    };
    partialData.dimension = dimension;
  } else if (partialData.dimensionTemp === undefined) {
    delete partialData.dimension;
  }

  delete partialData.dimensionTemp;

  return partialData;
};
