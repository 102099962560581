import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./CustomFieldModalGetStructure";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import {
  useCreateCustomFieldMutation,
  usePatchCustomFieldMutation,
} from "src/redux/services/CustomFieldService";
import { Box, Grid } from "@mui/material";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

interface CustomFieldModalProps {
  availableSections: { contract: boolean; offer: boolean };
  names: string[];
  openModal: boolean;
  row?: CustomField;
  handleCloseModal: () => void;
}
const CustomFieldModal = ({
  availableSections,
  names,
  openModal,
  row,
  handleCloseModal,
}: CustomFieldModalProps) => {
  const [createCustomField] = useCreateCustomFieldMutation();
  const [patchCustomField] = usePatchCustomFieldMutation();

  const initialData =
    row ?? ({ fieldType: "list" } as unknown as Partial<CustomField>);

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<CustomField>, GetStructureExtraProps>({
    getStructure,
    extraProps: { availableSections, names },
    initialFormData: initialData,
  });

  const submitData = toSubmitData({ data: formData, initData: initialData });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { create: createCustomField, update: patchCustomField },
      data: {
        create: !row?._id ? submitData : undefined,
        update: row?._id ? submitData : undefined,
      },
      dataId: String(formData?._id),
      name: "Custom fields",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setFormData(initialData);
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    id: "custom-fields",
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={row?._id ? "Edit custom field" : "Add custom field"}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            onFormSubmit();
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
          </Grid>
          <SectionWithButtonContainer buttonCenter>
            <ButtonGroup>
              <ButtonCancel
                onClick={() => {
                  setHasTriedToSubmit(false);
                  setFormData(initialData);
                  handleCloseModal();
                }}
              />{" "}
              <ButtonCreateOrUpdate
                initialSubmitData={initialData}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                label={row?._id ? "update" : "create"}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                submitData={submitData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default CustomFieldModal;
