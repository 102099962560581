import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableContainer: { boxShadow: "none" },
    tableHeadRowCell: {
      flex: 1,
      fontSize: "16px",
      fontWeight: 600,
      borderBottom: "none",
      padding: "var(--space-16px) var(--space-24px)",
    },
    tableRow: {
      cursor: "grab",
      whiteSpace: "nowrap",
      backgroundColor: "var(--white-primary)",
      "&.MuiTableRow-root:nth-of-type(even)": {
        backgroundColor: "var(--white-primary)",
      },
      "&.MuiTableRow-root:nth-of-type(odd)": {
        backgroundColor: "var(--green-10)",
      },
      "&.MuiTableRow-root:hover": {
        backgroundColor: "var(--green-9)",
        transition: "all 0.2s",
      },
    },
    tableRowCell: {
      borderBottom: "none",
      flex: 1,
      padding: "var(--space-8px) var(--space-24px)",
    },
    tableTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      padding: "var(--space-16px) var(--space-24px)",
      textTransform: "uppercase",
    },
    boxSection: {
      height: "100%",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingBottom: "2rem",
      paddingTop: "1rem",

      "& .MuiFormHelperText-root": {
        marginLeft: 2,
        marginRight: 0,
      },
      display: "flex",
      flexDirection: "row-reverse",
    },
  }),
);

export default useStyles;
