import Page from "src/components/Basic/Mixed/Pages/Page";
import SupplierInvoicesTable, {
  SupplerInvoiceFilterBase,
} from "src/components/Complex/Tables/Invoices/SupplierInvoiceTable";

const SupplierInvoices = () => {
  const supplierInvoiceFilterBase: SupplerInvoiceFilterBase = {
    sortBy: "supplierInvoiceNumber",
    sort: "desc",
  };

  return (
    <Page
      label="Supplier invoice list"
      breadcrumbs={[
        { label: "Operations" },
        { label: "Supplier invoice list" },
      ]}
    >
      <SupplierInvoicesTable
        supplierInvoiceFilterBase={supplierInvoiceFilterBase}
        useColumns={[
          "supplierInvoiceNumber",
          "projectExternalId",
          "dueDate",
          "invoiceDate",
          "supplierName",
          "status",
          "totalExcludingVAT",
          "totalVAT",
          "total",
          "files",
        ]}
      />
    </Page>
  );
};

export default SupplierInvoices;
