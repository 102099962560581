/**
 *Created by Mikael Lindahl on 2023-01-09
 */

import { useState } from "react";
import { Customer } from "../accurasee-backend-types/app/customer/customer.types";
import useTranslation from "src/hooks/useTranslationWrapper";
import { VatTypeTooltip } from "../components/Builders/Container/CommonBuilderContainerTypes";

const useVatTypeTooltip = () => {
  const [t] = useTranslation();

  const [vatTypeTooltip, setVatTypeTooltip] = useState<VatTypeTooltip>({
    color: "inherit",
    message: "",
  });

  function setVatTypeTooltipOnCustomer(customer: Customer) {
    let country = customer?.countryCode;
    setVatTypeTooltip({
      color: "inherit",
      message: t(""),
    });
    if (!country) {
      setVatTypeTooltip({
        color: "warning",
        message: t("Country code not added to Customer. Regular VAT applied."),
      });
    }
  }
  return { vatTypeTooltip, setVatTypeTooltip, setVatTypeTooltipOnCustomer };
};

export default useVatTypeTooltip;
