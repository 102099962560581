/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";
import { getNestedObjectData } from "../../../../utils/nestedData";

const getValue = (props: CommonCallbackProps) => {
  let value =
    props?.data &&
    (props.item.getValue
      ? props.item.getValue({
          data: props.data,
          rowIndex: props.rowIndex,
          item: props.item,
        })
      : getNestedObjectData({
          data: props.data[props.rowIndex],
          key: props.item.dataName as string,
        }));

  // Special treatment for specific types
  switch (props.item.type) {
    case "selector":
      if (props.item?.selectionOptions?.multiple) {
        value =
          value !== undefined
            ? typeof value === "string"
              ? value.split(",").filter((e) => e !== "") // remove empty elements
              : value
            : [];
      } else {
        value = value === undefined ? "" : value;
      }
  }

  return value;
};

export default getValue;
