/**
 *Created by Mikael Lindahl on 2023-12-15
 */
import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";
import React from "react";

type ButtonSubmitTProps = Omit<ButtonSubmitCoreTProps, "dialogContent"> & {
  dialogContent: React.ReactNode;
};

const ButtonSubmitWithYesNoDialog = (props: ButtonSubmitTProps) => {
  return <ButtonSubmitCore {...props} />;
};

export default ButtonSubmitWithYesNoDialog;
