import { Box, LinearProgress } from "@mui/material";

// components
import Header from "src/components/Basic/Mixed/Header";
import Sidebar, {
  DrawerHeader,
} from "src/components/Basic/Mixed/Sidebar/Sidebar";

// context
import { useAppSelector } from "src/redux/hooks/useAppSelector";

import Accounting from "src/screens/App/Setup/Accounting/Accounting";
import Accounts from "src/screens/App/Operations/Account/Accounts";
import Article from "src/screens/App/ProductsAndPlans/Article/[id]/ArticleContainer";
import Articles from "src/screens/App/ProductsAndPlans/Article/Articles";
import CompanyInsights from "src/components/Complex/Sections/CompanyInsights";
import ContractInsightsTable from "src/screens/App/Insights/Contract/ContractTable";
import CompanyIntegrations from "src/screens/App/Setup/Integrations/Integrations";
import CompanyInvoiceplanTemplates from "src/screens/App/Contracts/InvoicePlanTemplates/InvoicePlanTemplates";
import CompanyMembers from "src/screens/App/Setup/Members";
import CompanyOverview from "src/screens/App/Setup/Overview/CompanyOverview";
import ContractCreate from "src/screens/App/Contracts/Create/ContractCreate";
import ContractCreateFromOfferId from "src/screens/App/Contracts/Create/[contractOfferId]/ContractCreateFromOfferId";
import Contracts from "src/screens/App/Contracts/Contracts";
import ContractType from "src/screens/App/Contracts/ContractTypes/[contractTypeId]/overview/ContractType";
import ContractTypeCreate from "src/screens/App/Contracts/ContractTypes/Create/ContractTypeCreate";
import ContractTypes from "src/screens/App/Contracts/ContractTypes/ContractTypes";
import Customer from "src/screens/App/Operations/Customers/[id]/Customer";
import CustomerInvoice from "src/components/Screens/CustomerInvoiceBase/CustomerInvoiceBase";
import Customers from "src/screens/App/Operations/Customers/CustomersTable";
import CustomFields from "src/screens/App/Setup/CustomFields/CustomFields";
import Dashboard from "src/screens/App/Dashboard";
import Developers from "src/screens/App/Superadmin/Developers/Developers";
import Dimensions from "src/screens/App/Setup/Dimensions/Dimensions";
import ErrorPage from "src/components/Screens/ErrorPage/ErrorPage";
import FileDrive from "src/screens/App/Setup/FileDrive/FileDrive";
import Index from "src/screens/App/Setup/Index/Index";
import InsightReports from "src/screens/App/Insights/Reports/Reports";
import InviteResponse from "src/screens/App/InviteResponse/InviteResponse";
import InvoicePlanTemplate from "src/screens/App/Contracts/InvoicePlanTemplates/[:InvoicePlanTemplateId]/InvoicePlanTemplate";
import LinkedInvoiceData from "src/screens/App/Contracts/[contractId]/LinkedInvoiceData/LinkedInvoiceData";
import NotFound from "src/components/Screens/NotFound/NotFound";
import NumberSeries from "src/screens/App/Setup/NumberSeries/NumberSeries";
import PercentageOfCompletionTable from "src/screens/App/Operations/PercentageOfCompletion/PercentageOfCompletionTable";
import PlannedInvoice from "src/components/Screens/PlannedInvoiceBase/PlannedInvoiceBase";
import Profile from "src/screens/App/Profile/Profile";
import ProjectTrackerRegion from "src/screens/App/ProjectTracker/ProjectTracker";
import Reports from "src/screens/App/Setup/Report/Reports";
import RouteElementWrapper from "src/components/Basic/Simple/Routes/RouteElementWrapper";
import RoutesAppStyles from "./RoutesAppStyles";
import RoutesContract from "src/routes/App/Contracts/[contractId]/RoutesContract";
import ContractOffer from "src/screens/App/ContractOffers/[contractOfferId]/ContractOffer";
import ContractOfferCreate from "src/screens/App/ContractOffers/Create/ContractOfferCreate";
import GuestHeader from "src/components/Basic/Mixed/Header/GuestHeader";
import RoutesContractOffersTab from "src/routes/App/ContractOffers/RoutesContractOffersTab";
import RoutesInvoicesTab from "src/routes/App/Operations/Invoices/RoutesInvoicesTab";
import RoutesSuperadmin from "src/routes/App/Superadmin/RoutesSuperadmin";
import SupplierInvoices from "src/screens/App/Operations/SupplierInvoices/SupplierInvoices";
import Suppliers from "src/screens/App/Operations/Suppliers/Suppliers";
import TransferOwnerships from "src/screens/App/Operations/TransferOwnerships/TransferOwnerships";
import Unauthorized from "src/screens/Unauthorized/Unauthorized";
import WebsocketProvider from "src/context/WebsocketProvider";

import _r, {
  RoutePath,
} from "src/accurasee-backend-types/app/general/routes.types";
import {
  AccessPermissionContext,
  AccessPermissionProvider,
} from "src/context/AccessPermissionProvider";
import { get as getUser } from "src/redux/actions/user";
import { getPermission } from "src/utils/getPermission";
import { HiddenComponentProvider } from "src/context/HiddenComponentProvider";
import { MicrosoftDriveProvider } from "src/context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { UserStateProvider } from "src/context/UserStateProvider";

// Necessary to make sure that user has been initialized before rendering routes. Otherwise,
// useGetPermissionCheckAccessQuery will get outdated token in header
const LoadUser = () => {
  const user = useAppSelector((state) => state.user)!;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.isAuth) {
      const id = localStorage.getItem("instanceId");
      if (id === null) {
        localStorage.setItem("instanceId", Date.now().toString());
      } else {
        dispatch(getUser(id));
      }
    }
  }, [dispatch, user?.isAuth]);

  if (!user?.isInitialized) {
    return <LinearProgress />;
  }

  if (!user?.user?.activeCompanyId) {
    return (
      <>
        <GuestHeader />
        <DrawerHeader />
        <ErrorPage
          title="Sorry, user does not have an active company"
          subtitle="For further assistance, please contact your administrator."
          statusCode={400}
        />
      </>
    );
  }

  if (!user?.user?.active) {
    return (
      <>
        <GuestHeader />
        <DrawerHeader />
        <ErrorPage
          title="Sorry, your account has been deactivated"
          subtitle="For further assistance, please contact your administrator."
          statusCode={403}
        />
      </>
    );
  }

  return (
    <WebsocketProvider token={user?.token}>
      <AccessPermissionProvider>
        <MicrosoftDriveProvider>
          <HiddenComponentProvider user={user?.user}>
            <UserStateProvider>
              <RoutesApp />
            </UserStateProvider>
          </HiddenComponentProvider>
        </MicrosoftDriveProvider>
      </AccessPermissionProvider>
    </WebsocketProvider>
  );
};

type RouteObject = {
  exact?: boolean;
  path: RoutePath;
  component: JSX.Element;
};

const RoutesApp = () => {
  // global
  const sidebarState = useAppSelector((state) => state.sidebarState);

  const userState = useAppSelector((state) => state.user);
  const isAuth = userState.isAuth;
  const user = userState.user!;

  // Display the list of companies
  const companies = Object.keys(user?.companies!).map((key) => {
    const name = user?.companies ? user?.companies[key]?.displayName : "";
    return {
      active: user?.companies ? user?.companies[key]?.active : false,
      displayName: name,
      id: key,
    };
  });

  const { accessEndpointMap } = useContext(AccessPermissionContext);

  const apUrls = Object.keys(accessEndpointMap || []);

  if (apUrls.filter((x) => !_r.find((y) => y === x))?.length > 0) {
    console.log(
      "Exists in accessPermissions but not in routes",
      apUrls.filter((x) => !_r.find((y) => y === x)),
    );
    console.log(
      "Exists in routes but not in accessPermissions",
      _r.filter((x) => !apUrls.find((y) => y === x)),
    );
  }

  const pathToStrip = "/app";

  const routesMapper: RouteObject[] = [
    {
      path: "/app/dashboard",
      component: <Dashboard />,
    },
    {
      path: "/app/profile",
      component: <Profile />,
    },
    {
      path: "/app/invite/:id",
      component: <InviteResponse />,
    },
  ];

  const routesMapperPermission: RouteObject[] = [
    { path: "/app/setup/accounting", component: <Accounting /> },
    { path: "/app/setup/custom-fields", component: <CustomFields /> },
    { path: "/app/setup/dimensions", component: <Dimensions /> },
    { path: "/app/setup/file-drive", component: <FileDrive /> },
    { path: "/app/setup/indices", component: <Index /> },
    { path: "/app/setup/integrations", component: <CompanyIntegrations /> },
    { path: "/app/setup/members", component: <CompanyMembers /> },
    { path: "/app/setup/number-series", component: <NumberSeries /> },
    { path: "/app/setup/overview", component: <CompanyOverview /> },
    { path: "/app/setup/reports", component: <Reports /> },
    {
      path: "/app/contract-offers/overview/:contractOfferId",
      component: <ContractOffer />,
    },
    {
      path: "/app/contract-offers",
      component: <RoutesContractOffersTab />,
    },
    {
      path: "/app/contract-offers/create",
      component: <ContractOfferCreate />,
    },
    {
      path: "/app/contracts/create/:contractOfferId",
      component: <ContractCreateFromOfferId />,
    },
    {
      path: "/app/contracts/create",
      component: <ContractCreate />,
    },
    {
      path: "/app/contracts/contracttypes/create",
      component: <ContractTypeCreate />,
    },
    {
      path: "/app/contracts/contracttypes/:contractTypeId/overview",
      component: <ContractType />,
    },
    {
      path: "/app/contracts/contracttypes",
      component: <ContractTypes />,
    },
    {
      path: "/app/contracts/invoiceplantemplates/:invoicePlanTemplateId",
      component: <InvoicePlanTemplate />,
    },
    {
      path: "/app/contracts/invoiceplantemplates",
      component: <CompanyInvoiceplanTemplates />,
    },
    {
      path: "/app/contracts",
      exact: true,
      component: <Contracts />,
    },
    {
      path: "/app/contracts/:contractId",
      component: <RoutesContract />,
    },

    { path: "/app/product-and-plans/articles/create", component: <Article /> },
    { path: "/app/product-and-plans/articles/:id", component: <Article /> },
    {
      path: "/app/product-and-plans/articles",
      component: <Articles />,
    },
    {
      path: "/app/operations/customers/create",
      component: <Customer />,
    },
    {
      path: "/app/operations/customers/:id",
      component: <Customer />,
    },
    {
      path: "/app/operations/customers",
      component: <Customers />,
    },
    { path: "/app/operations/customers", component: <Customers /> },
    { path: "/app/operations/poc", component: <PercentageOfCompletionTable /> },
    {
      path: "/app/operations/supplierinvoices",
      component: <SupplierInvoices />,
    },
    { path: "/app/operations/suppliers", component: <Suppliers /> },
    {
      path: "/app/operations/invoices",
      component: <RoutesInvoicesTab />,
    },
    {
      path: "/app/operations/invoices/planned/:id",
      component: <PlannedInvoice />,
    },
    {
      path: "/app/operations/invoices/customer/:id",
      component: <CustomerInvoice />,
    },
    { path: "/app/operations/accounts", component: <Accounts /> },
    {
      path: "/app/operations/linkedinvoicedata",
      component: <LinkedInvoiceData />,
    },
    {
      path: "/app/operations/transferownerships",
      component: <TransferOwnerships />,
    },
    { path: "/app/project-tracker", component: <ProjectTrackerRegion /> },
    { path: "/app/insights/company", component: <CompanyInsights /> },
    {
      path: "/app/insights/contract",
      component: (
        <ContractInsightsTable
          filter={{
            status: ["upcoming", "ongoing", "invoiced", "partiallyinvoiced"],
            sortBy: "number",
            sort: "desc",
            // Default is this fiscal year, for ex. from 1.1.2023 - today's date
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(),
          }}
        />
      ),
    },
    { path: "/app/insights/reports", component: <InsightReports /> },
    { path: "/app/superadmin/developers", component: <Developers /> },
    { path: "/app/superadmin", component: <RoutesSuperadmin /> },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        companies={companies}
        isSuperAdmin={
          !!accessEndpointMap["/app/superadmin"]?.permissions.readRights
        }
        user={user}
      />
      <Sidebar />
      <RoutesAppStyles open={sidebarState?.isSidebarOpened}>
        <DrawerHeader isWarnings={sidebarState.isWarnings} />
        <Routes>
          {routesMapper.map((route) => {
            return (
              <Route
                element={route.component}
                key={route.path}
                path={route.path.replace(pathToStrip, "") + "/*"}
              ></Route>
            );
          })}

          {routesMapperPermission.map((route) => {
            const permissions = getPermission({
              accessEndpointMap,
              path: route.path,
            });

            const canRead = permissions.readRights;

            return (
              <Route
                key={route.path}
                path={
                  route.path.replace(pathToStrip, "") +
                  (route?.exact === true ? "" : "/*")
                }
                element={
                  <RouteElementWrapper
                    element={canRead ? route.component : <Unauthorized />}
                    isRedirect={!isAuth}
                    redirectPath={"/login"}
                  />
                }
              />
            );
          })}

          <Route path="/*" element={<NotFound />}></Route>
        </Routes>
      </RoutesAppStyles>
    </Box>
  );
};

export default LoadUser;
