/**
 *Created by Mikael Lindahl on 2024-10-01
 */

import { Customer } from "../accurasee-backend-types/app/customer/customer.types";
const getCustomerName = (
  customer?: Customer,
  options?: { maxChars?: number; excludeExternalId?: boolean },
) => {
  const name =
    customer?.name &&
    options?.maxChars &&
    customer?.name?.length > options?.maxChars
      ? customer?.name.slice(0, options?.maxChars) + "..."
      : customer?.name;

  return customer && !options?.excludeExternalId
    ? `${name} - ${customer.externalId}`
    : name
      ? name
      : "";
};

export default getCustomerName;
