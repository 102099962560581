import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";

export default makeStyles((theme: any) =>
  createStyles({
    logotype: {
      color: "white",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: 18,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    appBar: {
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      //
      backgroundColor: theme.palette.white.main,
      boxShadow: "none",
    },
    /////
    activeCompany: {
      display: "flex",
      padding: "var(--space-4px) var(--space-16px)",
      marginBottom: "var(--space-8px)",
    },
    draweBackdropModal: {
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        height: "100vh",
        opacity: 0.3,
        backgroundColor: theme.palette.secondary.dark,
        transition: theme.transitions.create(["background-color", "width"]),
        bottom: 0,
        top: 0,
        left: 0,
        zIndex: -999,
      },
    },
    toolbar: {
      color: "var(--green-3)",
      padding: 0,
      borderBottom: "1px solid var(--gray-2)",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      padding: "0 12px 0 24px",
      alignItems: "center",
      minHeight: "64px",
      width: "100%",
    },
    hide: { display: "none" },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      transition: "all 0.1s",
      backgroundColor: theme.palette.background.default,
      marginRight: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIconWrapper: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    styledInputBase: {
      "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
          width: "20ch",
        },
        "&::placeholder": {
          color: "var(--gray-5)",
          fontSize: "18px",
        },
      },
    },
    styledHelp: {
      textDecoration: "none",
      color: "var(--gray-5)",
      fontWeight: "500",
      fontSize: "14px",
      cursor: "pointer",
      transition: "all 0.1s",
      "&:hover, &:focus": {
        color: "var(--green-primary)",
        textDecoration: "underline",
      },
      marginLeft: theme.spacing(1),
      padding: "var(--space-4px) var(--space-16px)",
    },
    searchFocused: {
      backgroundColor: alpha(theme.palette.common.black, 0.08),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 250,
      },
    },
    messageContent: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenu: {
      marginTop: theme.spacing(7),
      top: "0 !important",
    },
    headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.light,
        color: theme.palette.primary,
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(1),
      fontSize: "14px",
      padding: "var(--space-4px) var(--space-16px)",
      borderRadius: "8px",
      transition: "all 0.1s",
      "&:hover, &:focus": {
        backgroundColor: "var(--green-10)",
      },
    },
    notifications: {
      color: "var(--gray-5)",
      fontSize: "14px !important",
      fontWeight: "500 !important",
    },
    selectOptions: {
      "& div[aria-label='Without label']": {
        display: "flex",
      },
    },
    settingsBox: {
      width: "60px",
      borderLeft: "1px solid lightgrey",
      display: "flex",
      justifyContent: "center",
      marginLeft: "1rem",
    },
    headerMenuButtonSettings: {
      margin: 0,
      padding: "var(--space-4px) var(--space-8px)",
    },
    settingsIcon: {
      fontSize: 24,
      color: theme.palette.text.secondary,
    },
    headerIcon: {
      fontSize: 22,
      color: theme.palette.text.secondary,
    },
    headerIconCollapse: {
      color: theme.palette.primary.dark,
    },
    menuTitle: {
      color: "var(--green-primary)",
      fontWeight: "bold",
      fontSize: "17px",
    },
    menuSubTitle: {
      color: "var(--gray-5)",
      fontSize: "14px",
    },
    profileMenu: {
      minWidth: 265,
      top: "0 !important",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    profileMenuItem: {
      color: theme.palette.primary,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    profileMenuLink: {
      fontSize: 16,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    messageNotification: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.light,
      },
    },
    messageNotificationSide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
    messageNotificationBodySide: {
      alignItems: "flex-start",
      marginRight: 0,
    },
    sendMessageButton: {
      height: 40,
      margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: "space-between",
      textTransform: "none",
    },
    sendButtonIcon: {
      marginLeft: theme.spacing(2),
    },
    purchaseBtn: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      marginRight: theme.spacing(3),
    },
  }),
);
