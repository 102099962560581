import { BuilderContainerCallbackProps } from "../CommonBuilderContainerTypes";

type GetTextColorTProps = BuilderContainerCallbackProps;

const getTextColor = (props: GetTextColorTProps) => {
  return (
    !props.item.textColor ||
    (typeof props.item.textColor === "boolean"
      ? props.item.textColor
      : props.item.textColor(props))
  );
};

export default getTextColor;
