/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import TextField from "@mui/material/TextField";
import determineCurrencyStyling from "../../Utils/determineCurrencyStyling";
import get from "../Utils/get";
import getDisabled from "../Utils/getDisabled";
import getHelperText from "../Utils/getHelperText";
import getRequired from "../Utils/getRequired";
import useTextInputColumnItem from "../Hooks/useTextInputColumnItem";
import { ChangeEvent, FocusEvent } from "react";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { InputAdornment } from "@mui/material";

export type TextColumnItemTProps = CommonBuilderTableTypes;

const TextInputColumnItem = (props: TextColumnItemTProps) => {
  const {
    commonCallbackProps,
    getHelperTextProps,
    inputRef,
    inputValue,
    propsRef,
    setInputValue,
    setData,
    t,
  } = useTextInputColumnItem({ props });

  propsRef.current = props;

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const valueToSet = props?.formatValueDisplayed
      ? props?.formatValueDisplayed.reversed(event.target.value, currencyCode)
      : event.target.value;

    setInputValue(valueToSet);
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);

  let extra = {};
  const unit = props.item.unit || props.unit;
  if (props.item.unit || props.unit) {
    extra = {
      ...extra,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">{unit ? t(unit) : ""}</InputAdornment>
        ),
      },
    };
  }

  const currencyCode = get("currencyCode", commonCallbackProps);

  if (props.item.currencyCode && props.isCurrency) {
    const { symbol, position, adornment } = determineCurrencyStyling(
      get("currencyCode", commonCallbackProps)!,
    );

    extra = {
      ...extra,
      InputProps: {
        [adornment]: (
          <InputAdornment position={position}>{symbol}</InputAdornment>
        ),
      },
    };
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputRef.current) {
      event.preventDefault(); // Prevents the default action
      inputRef.current.blur(); // Triggers blur event
    }
  };

  return (
    <TextField
      color={get("textInputColor", commonCallbackProps)} //>props.highlighted ? "success" : "primary"}
      disabled={itemDisabled}
      error={Boolean(getHelperText(getHelperTextProps))}
      focused={get("textInputFocused", commonCallbackProps)}
      helperText={getHelperText(getHelperTextProps)}
      hiddenLabel
      inputRef={inputRef}
      key={`${String(props.item.dataName)}-${props.rowIndex}`}
      label=""
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      required={getRequired(commonCallbackProps)}
      size="small"
      style={props.style}
      sx={get("sx", commonCallbackProps)}
      value={
        props?.formatValueDisplayed
          ? props?.formatValueDisplayed.forward(inputValue, currencyCode)
          : inputValue
      }
      onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setData(inputValue); // Call setData immediately
      }}
      variant="outlined"
      {...extra}
    />
  );
};

export default TextInputColumnItem;
