/**
 *Created by Mikael Lindahl on 2024-03-25
 */
import { IntegrationImportJobName } from "../../app/integration_import_job/integration_import_job.types";

const INTEGRATION_IMPORTS_DEPENDENCIES: Record<
  Exclude<IntegrationImportJobName, "import_price_list">,
  IntegrationImportJobName[]
> = {
  import_accounts: [],
  import_articles: ["import_accounts", "import_units"],
  import_attachments: [],
  import_company: [],
  import_currencies: [],
  import_customer_invoices: [
    "import_articles",
    "import_currencies",
    "import_customers",
    "import_dimensions",
    "import_fiscal_years",
    "import_projects",
    "import_units",
  ],
  import_customers: ["import_currencies", "import_terms_of_payments"],
  import_dimensions: [],
  import_fiscal_years: [],
  import_projects: [],
  import_supplier_invoices: [
    "import_articles",
    "import_currencies",
    "import_dimensions",
    "import_fiscal_years",
    "import_projects",
    "import_suppliers",
    "import_units",
  ],
  import_suppliers: ["import_currencies", "import_terms_of_payments"],
  import_terms_of_payments: [],
  import_units: [],
  import_vouchers: ["import_accounts", "import_supplier_invoices"],
};

const INTEGRATION_IMPORTS_DEPENDENCIES_ALL: IntegrationImportJobName[] = [
  // Not dependent on other imports
  "import_accounts",
  "import_attachments",
  "import_company",
  "import_currencies",
  "import_dimensions",
  "import_fiscal_years",
  "import_projects",
  "import_terms_of_payments",
  "import_units",

  // Dependent on other imports. See INTEGRATION_IMPORTS_DEPENDENCIES
  "import_customers",
  "import_suppliers",
  "import_articles",
  "import_vouchers",
  "import_customer_invoices",
  "import_supplier_invoices",
];

export {
  INTEGRATION_IMPORTS_DEPENDENCIES,
  INTEGRATION_IMPORTS_DEPENDENCIES_ALL,
};
