import { File } from "src/accurasee-backend-types/app/general/storage.types";
import FileService from "src/redux/services/FileService";
import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";

interface downloadCostFileProps {
  file: File;
  supplierInvoiceId: string;
  fileDownloading: any;
  setFileDownloading: (data: String[]) => void;
}

async function DownloadCostFile({
  file,
  supplierInvoiceId,
  fileDownloading,
  setFileDownloading,
}: downloadCostFileProps) {
  const { enqueueSnackbar } = useSnackbar();
  setFileDownloading({
    ...fileDownloading,
    [String(file._id)]: true,
  });
  try {
    const fileRes = await FileService.getFile(file._id, supplierInvoiceId);
    fileDownload(fileRes.data, file.originalName, file.mimeType);
  } catch (error) {
    console.error(error);
    enqueueSnackbar(t("Could not download pdf"), {
      variant: "error",
    });
  } finally {
    setFileDownloading({
      ...fileDownloading,
      [String(file._id)]: false,
    });
  }
}

export default DownloadCostFile;
