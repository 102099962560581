/**
 *Created by Mikael Lindahl on 2023-06-29
 */
import Button from "@mui/material/Button";
import NoPermissionDialog from "../Dialogs/NoPermissionDialog";
import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button/Button";
import {
  AccessPermissionType,
  CheckAccessPermissions,
} from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";

export type ButtonCoreProps = MuiButtonProps & {
  isLoading?: boolean;
  permissionType?: AccessPermissionType;
  permissions?: Partial<CheckAccessPermissions>;
  target?: string;
};

const ButtonCore = ({
  isLoading,
  onClick,
  permissions,
  permissionType,
  ...props
}: ButtonCoreProps) => {
  const sx = props.sx || {};

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [openNoPermissionDialog, setOpenNoPermissionDialog] =
    React.useState(false);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.clientWidth);
    }
  }, []);

  const hasPermission =
    permissions && permissionType ? permissions[permissionType] : true;

  return (
    <>
      <Button
        ref={buttonRef}
        color="primary"
        disableRipple
        onClick={(e) => {
          e.stopPropagation();

          if (isLoading) {
            return;
          }

          if (!hasPermission) {
            setOpenNoPermissionDialog(true);
          } else if (onClick) {
            onClick(e);
          }
        }}
        {...props}
        sx={{
          borderRadius: "8px",
          boxShadow: "none",
          height: "40px",
          minWidth: buttonWidth ? `${buttonWidth}px` : "110px",
          padding: "0.25rem 1.5rem",
          textTransform: "uppercase",
          "&:hover": {
            // Set the background color to the same as the normal state
            backgroundColor:
              props.variant === "outlined"
                ? "inherit"
                : props.color
                  ? `${props.color}.main`
                  : "primary.main", // Adjust this value to match your button's color
            // You can also reset other hover styles if needed
          },
          ...sx,
        }}
      >
        {!isLoading && props.children}
        {isLoading && !props.disabled && (
          <Box sx={{ paddingTop: "5px", marginX: "auto" }}>
            {/* Add a Box with left margin */}
            <CircularProgress color={"inherit"} size={24} />
          </Box>
        )}
      </Button>
      <NoPermissionDialog
        open={openNoPermissionDialog}
        onClose={() => setOpenNoPermissionDialog(false)}
        permissionType={permissionType}
      />
    </>
  );
};

export default ButtonCore;
