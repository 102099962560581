import { backendApi } from "../api";
import { docMultipleListTag, docPatchTagNew, listTagTwo } from "./utils";
import {
  InvoicePlan,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
const CACHE_TAG = "Invoiceplans";
export type ModifiedInvoicePlanTemplate = Omit<
  InvoicePlanTemplate,
  "companyId"
> & { id: InvoicePlanTemplate["_id"] };

export const invoiceplanApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceplans: build.query<
      { data?: InvoicePlanWithPermissions[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/invoiceplans", params }),
      // transformResponse: (response) => response.data,
      providesTags: [CACHE_TAG], // Important need to match the specific tag for invalidation listTab(CACHE_TAG) can not be match against [CACHE_TAG]
    }),
    getInvoiceplan: build.query<InvoicePlanWithPermissions, any>({
      query: (id) => ({ url: `/invoiceplans/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: docPatchTagNew(CACHE_TAG), // Need this to work. Does not work with [CACHE_TAG]
    }),
    patchInvoiceplan: build.mutation<
      any,
      Partial<InvoicePlan> & { id: InvoicePlan["_id"] }
    >({
      query: ({ id, ...patch }) => ({
        url: `/invoiceplans/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: [CACHE_TAG, "PlannedInvoices"], // Important need to match the specific tag for invalidation listTab(CACHE_TAG) can not be match against [CACHE_TAG]
    }),
    deleteInvoiceplan: build.mutation<any, InvoicePlan["_id"] | string>({
      query: (id) => ({
        url: `/invoiceplans/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: docMultipleListTag(CACHE_TAG, "PlannedInvoices"),
    }),
    createInvoiceplan: build.mutation<any, InvoicePlan>({
      query: (data) => ({
        url: `/invoiceplans`,
        method: "POST",
        data,
      }),
      invalidatesTags: listTagTwo(CACHE_TAG, "PlannedInvoices"), // Important need to match the specific tag for invalidation listTab(CACHE_TAG) can not be match against [CACHE_TAG]
    }),
  }),
});

export const {
  useCreateInvoiceplanMutation,
  useDeleteInvoiceplanMutation,
  useGetInvoiceplanQuery,
  useGetInvoiceplansQuery,
  usePatchInvoiceplanMutation,
} = invoiceplanApi;

export default invoiceplanApi;
