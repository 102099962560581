import {
  SubcontractWithCostAndIncomes,
  SubcontractWithPermissions,
} from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { backendApi } from "../api";
import { docDeleteTags, docGetTag, docMultipleListTag, listTag } from "./utils";

const CACHE_TAG = "Subcontracts";
export const subcontractApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getSubcontracts: build.query<
      { data?: SubcontractWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/subcontracts", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getSubcontractsTable: build.query<
      { data: SubcontractWithCostAndIncomes[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/subcontracts/table", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getSubcontract: build.query<SubcontractWithPermissions, any>({
      query: (id) => ({ url: `/subcontracts/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TAG),
    }),
    createSubcontract: build.mutation({
      query: (data) => ({ url: `/subcontracts`, method: "POST", data }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    patchSubcontract: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/subcontracts/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docMultipleListTag(CACHE_TAG, "PlannedInvoices"),
    }),
    deleteSubcontract: build.mutation({
      query: (id) => ({ url: `/subcontracts/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TAG),
    }),
    markSubcontractAsInvoiced: build.mutation<
      any,
      { id: SubcontractWithCostAndIncomes["_id"] }
    >({
      query: ({ id }) => ({
        url: `/superadmin/subcontracts/${id}/mark-as-invoiced`,
        method: "PUT",
      }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
  }),
});

export const {
  useGetSubcontractQuery,
  useGetSubcontractsQuery,
  usePatchSubcontractMutation,
  useMarkSubcontractAsInvoicedMutation,
} = subcontractApi;

export default subcontractApi;
