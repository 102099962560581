import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import UserRoleModalWithForm from "./components/UserRoleModalWithForm";
import UserRoleTable from "./components/UserRoleTable";
import { useGetUserRolesQuery } from "src/redux/services/UserRolesService";
import { useState } from "react";
import usePathPermission from "src/hooks/usePathPermission";

const UserRoles = () => {
  const [openAddPermissionModal, setOpenAddPermissionModal] = useState(false);

  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRolesQuery({
      undefined,
    });

  const data = userRolesResponse?.data || [];

  const pathPermissions = usePathPermission("/app/setup/members");

  return (
    <SectionTight
      containsTable={true}
      label={"Roles and roles' permissions"}
      isLoading={isLoadingUserRoles}
      subLabel={"Create and edit roles'permissions"}
      rightBoxArea={
        <ButtonAddBoxIcon
          label={"Add user role"}
          onSubmit={() => setOpenAddPermissionModal(true)}
          permissions={pathPermissions}
        />
      }
    >
      <UserRoleTable data={data} />
      <UserRoleModalWithForm
        actionType={"add"}
        openModal={openAddPermissionModal}
        handleCloseModal={() => setOpenAddPermissionModal(false)}
        names={data.map((d) => d.name.toLowerCase())}
      />
    </SectionTight>
  );
};

export default UserRoles;
