import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import { EMAIL_LINEBREAK } from "../../../../accurasee-backend-types/shared/constants/general";

export type MessageBaseProps = {
  email?: string;
  message?: string;
  subMessage?: string;
  textAlign?: "left" | "center" | "right";
  title?: string;
  type?: "alert" | "message" | "success" | "warning";
};

const MessageBase = ({
  email,
  message,
  subMessage,
  textAlign = "left",
  title,
  type,
}: MessageBaseProps) => {
  const styling = getStylingByStatusType(type);
  return (
    <Box
      sx={{
        borderRadius: "8px",
        ...(!!type
          ? { backgroundColor: styling.backgroundColor, color: styling.color }
          : {}),
        columnGap: "var(--space-8px)",
        display: "flex",
        marginTop: "var(--space-16px)",
        padding: "var(--space-8px) var(--space-16px)",
        ...{ textAlign },
      }}
    >
      {type === "warning" && <ErrorIcon />}
      {type === "success" && <CheckCircleIcon />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: "var(--space-4px)",
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
        {message &&
          message.split(EMAIL_LINEBREAK).map((m, index) => (
            <Typography key={index} component="span" sx={{ display: "block" }}>
              {m}
            </Typography>
          ))}
        {subMessage && <Typography component="span">{subMessage}</Typography>}
        {email && (
          <Typography
            component="span"
            sx={{ fontWeight: 600, marginLeft: "var(--space-4px)" }}
          >
            {email}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MessageBase;
