import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { ProjectSeries } from "src/accurasee-backend-types/app/company/company.types";
import {
  checkOverlappedNumberSeries,
  isInvalidNumberSeriesRelation,
  isNumberToSmall,
  NumberSeries,
} from "../../../../../utils/numberSeriesCheck/numberSeriesCheck";

export type GetStructureExtraProps = {
  existingRanges: NumberSeries[];
  labels: string[];
};

export const getStructure: UseFormContainerGetStructure<
  Partial<ProjectSeries>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<ProjectSeries>> = {
    items: [
      {
        required: true,
        type: "text_input",
        dataName: "label",
        label: "Label",
        gridProps: { md: 6 },
        validate: (props: any) => {
          return (
            props.data?.label.length >= 0 &&
            !extraProps?.labels?.includes(
              props.data?.label.toLowerCase().trim(),
            )
          );
        },
        getErrorText: () => "Name already in use. Please select another one",
        setFormData,
      },
      {
        type: "text_input",
        dataName: "prefix",
        label: "Prefix",
        gridProps: { md: 6 },
        setFormData,
      },
      {
        required: true,
        type: "number",
        dataName: "firstNumber",
        label: "First number",
        gridProps: { md: 3 },
        validate: (props) => {
          if (
            isNumberToSmall(props.data?.firstNumber) ||
            isNumberToSmall(props.data?.lastNumber) ||
            isInvalidNumberSeriesRelation(
              props.data as NumberSeries | undefined,
            )
          ) {
            return false;
          }

          const res = checkOverlappedNumberSeries({
            listNumberSeries: extraProps?.existingRanges,
            singleNumberSeries: props.data as NumberSeries | undefined,
          });

          return !res.isOverlapFirstNumber && !res.isOverlapInterval;
        },
        getErrorText: ({ data }: any) => {
          if (data.lastNumber && data.firstNumber >= data.lastNumber) {
            // Validate relation between firstNumber and lastNumber
            return "First number cant be equal or larger than last number";
          }
          if (Number(data.firstNumber) < 1) {
            return "Number series needs to start from 1 or above";
          }
          return "Number is overlapping with existing series. Please correct it";
        },
        setFormData,
      },
      {
        required: true,
        type: "number",
        dataName: "lastNumber",
        label: "Last number",
        gridProps: { md: 3 },
        validate: (props) => {
          if (
            isNumberToSmall(props.data?.firstNumber) ||
            isNumberToSmall(props.data?.lastNumber) ||
            isInvalidNumberSeriesRelation(
              props.data as NumberSeries | undefined,
            )
          ) {
            return false;
          }

          const res = checkOverlappedNumberSeries({
            listNumberSeries: extraProps?.existingRanges,
            singleNumberSeries: props.data as NumberSeries | undefined,
          });

          return !res.isOverlapLastNumber && !res.isOverlapInterval;
        },
        getErrorText: ({ data }: any) => {
          if (data.lastNumber && data.firstNumber >= data.lastNumber) {
            // Validate relation between firstNumber and lastNumber
            return "First number cant be equal or larger than last number";
          }
          if (Number(data.firstNumber) < 1) {
            return "Number series needs to start from 1 or above";
          }
          return "Number is overlapping with existing series. Please correct it";
        },
        setFormData,
      },

      {
        type: "switch",
        dataName: "isActive",
        label: "Active/Inactive",
        gridProps: { md: 12 },
        setFormData,
      },
    ],
  };

  return structure;
};
