/**
 *Created by Mikael Lindahl on 2024-06-27
 */

interface JobStatusParams {
  jobs: any[];
}

function isAllJobsDisabled(params: JobStatusParams) {
  const { jobs } = params;

  return (
    jobs &&
    jobs.some(
      (j) =>
        (j.status === "pending" || j.status === "running") &&
        !(j.groupStatus === "error" || j.groupStatus === "completed"),
    )
  );
}

export default isAllJobsDisabled;
