import ButtonPrimary from "../../../../../components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "../../../../../components/Basic/Simple/Buttons/ButtonSecondary";
import ModalWrapper from "../../../../../components/Basic/Mixed/Modals/ModalWrapper";
import Section from "../../../../../components/Basic/Simple/Sections/Section";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Accounting } from "../../../../../accurasee-backend-types/app/integrations/integrationconfig.types";
import { Box, Divider, Typography } from "@mui/material";
import { ReturnCompanyWithPermissions } from "src/accurasee-backend-types/app/company/company.types";
import { styled } from "@mui/system";

interface ExportModalProps {
  accounting?: Accounting;
  company?: ReturnCompanyWithPermissions;
  handleCloseModal: () => void;
  isError: boolean;
  openModal: boolean;
  url: string;
}

const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function IntegrationAuthModal(props: ExportModalProps) {
  const [t] = useTranslation();

  return (
    <ModalWrapper
      handleCloseModal={() => props.handleCloseModal()}
      openModal={props.openModal}
      label={
        !props.accounting?.authorized
          ? "Integration authorization"
          : "Integration re-authorization"
      }
      subLabel={
        !props.accounting?.authorized
          ? "Steps to authorize your integration"
          : "Steps to re-authorize your integration"
      }
    >
      <Section>
        <Box
          sx={{ marginTop: "1rem" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          {props.accounting?.authorized &&
            !props.accounting?.reAuthNeeded &&
            props.accounting?.refreshTokenExpired === false &&
            !props.isError && (
              <Box>
                <WarningText>
                  <Typography variant="h6">Note</Typography>
                  <p>
                    {t(
                      "Your refresh token has not expired so you should not need to re-authorize your accounting integration. If this is the case and you can not import from your accounting integration then something has gone wrong",
                    )}
                  </p>
                </WarningText>
              </Box>
            )}
          <Typography variant="h6">Step 1</Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
          >
            <p>
              {`${t("Make sure you have correct ")} ${
                props.accounting?.integrationName
              } ${t("integration account and password for your company")}${
                " " + (props.company?.name || props.company?.displayName)
              }`}
            </p>
          </Box>
          <Divider sx={{ width: "100%", marginBottom: "2rem" }} />
          <Typography variant="h6">Step 2</Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
            textAlign={"center"}
            // marginTop={1}
          >
            <p>
              {t(
                "Goto your accounting integration and following instructions.",
              )}
            </p>
            <Box>
              <ButtonSecondary onClick={props.handleCloseModal}>
                {t("Cancel")}
              </ButtonSecondary>
              <ButtonPrimary
                sx={{ marginLeft: 1 }}
                href={props.url}
                target={"_blank"}
              >
                {t("Go to") + ` ${props.accounting?.integrationName}`}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Section>
    </ModalWrapper>
  );
}
