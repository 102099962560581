/**
 *Created by Mikael Lindahl on 2023-10-16
 */
import moment from "moment-timezone";

function getYearAsString(offset: number) {
  return moment().add(offset, "years").format("YYYY");
}

export default getYearAsString;
