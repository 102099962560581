import _c from "src/constants/Constants";
import getMinMaxDatesThisMonth from "../utils/getMinMaxDatesThisMonth";
import { UseMUIDataTableFilterStatesReturn } from "src/hooks/useMUIDataTableFilterStates";
import { UserStateContext } from "src/context/UserStateProvider";
import { cleanObject } from "src/utils/removeKeysWithEmptyStringAsValue";
import { useContext, useEffect } from "react";
import {
  DisplayData,
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  MUIDataTableState,
} from "mui-datatables";

const usePersistTableFilter = ({
  id,
  onFilterChange: _onFilterChange,
  onChangeAdditionalFilter,
}: {
  id: string;
  onFilterChange?: MUIDataTableOptions["onFilterChange"];
  onChangeAdditionalFilter?: UseMUIDataTableFilterStatesReturn["setAdditionalFilter"];
}) => {
  const {
    getUserStateTable,
    checkIfUserStateTableIsUninitialized,
    checkIfUserStateTableINeedsToBeConvertedToNewStructure,
    setUserStateTable,
  } = useContext(UserStateContext);

  useEffect(() => {
    const userStateByTableId = getUserStateTable(id);

    if (userStateByTableId && onChangeAdditionalFilter) {
      // Using cleanObject helps the empty filters not impact some initial filter settings
      const newFilters = cleanObject(userStateByTableId?.filter);
      onChangeAdditionalFilter({
        ...newFilters,
      });
    }

    // Need to set user state either if it is uninitialized or
    // if it has the old strucutre this structure needs to be
    // converted to new and then set. getUserStateTable(id) always
    // need to return and object since it can be used before this
    // useEffect is performed at end of rendering. At that point
    // this code makes sure that the user state is updated with default
    // values or old converted structure. If state exists and is in new
    // format state thus not need to be updated
    if (
      checkIfUserStateTableIsUninitialized({ id }) ||
      checkIfUserStateTableINeedsToBeConvertedToNewStructure({ id })
    ) {
      setUserStateTable({ id, data: userStateByTableId });
    }
  }, []);

  const onFilterChange = (
    changedColumn: string | MUIDataTableColumn | null,
    filterList: MUIDataTableState["filterList"],
    type: FilterType | "chip" | "reset",
    changedColumnIndex: number,
    _displayData: DisplayData,
  ) => {
    const userStateByTableId = getUserStateTable(id);

    if (type === "reset") {
      setUserStateTable({
        id,
        data: {
          ...userStateByTableId,
          filter: {},
        },
      });
    } else {
      const updatedFilter = filterList[changedColumnIndex];

      const columnName = changedColumn?.toString() || "";

      if (_onFilterChange) {
        _onFilterChange(
          changedColumn,
          filterList,
          type,
          changedColumnIndex,
          _displayData,
        );
      }

      setUserStateTable({
        id,
        data: {
          ...userStateByTableId,
          filter: {
            ...userStateByTableId?.filter,
            [columnName]: updatedFilter,
          },
        },
      });
    }
  };

  const onSetFilterList = (columns: MUIDataTableColumnDef[]) => {
    if (typeof columns === "string") return;

    const userStateTable = getUserStateTable(id);

    for (const c of columns) {
      if (typeof c === "string") continue;

      if (userStateTable) {
        if (c.options && userStateTable?.filter?.hasOwnProperty(c.name)) {
          const filter = [...userStateTable.filter[c.name]];

          // Add-hook code to ensure filter is set to current month if third option for date
          // filters are set to true (isThisMonth).
          if (
            _c.FILTER_DATE_FIELDS.includes(c.name) &&
            filter.length > 2 &&
            filter[2] === "true"
          ) {
            const dates = getMinMaxDatesThisMonth();

            if (filter[0] !== dates.minDate || filter[1] !== dates.maxDate) {
              filter[0] = dates.minDate;
              filter[1] = dates.maxDate;
            }
          }

          c.options.filterList = filter;
        }
      }
    }
  };

  return { onFilterChange, onSetFilterList };
};

export default usePersistTableFilter;
