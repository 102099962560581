/**
 *Created by Mikael Lindahl on 2024-03-20
 */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Collapse from "@mui/material/Collapse";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import IntegrationImportJob from "./IntegrationImportJob";
import addJobs from "../Utils/addJobs";
import getFormattedDate from "src/utils/getFormattedDate";
import useIntegrationImportSetups from "../Hooks/useIntegrationImportSetups";
import useTranslation from "src/hooks/useTranslationWrapper";
import { IntegrationImportGroup as IntegrationImportGroupType } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { useGetIntegrationImportJobGroupsQuery } from "src/redux/services/IntegrationImportJobService";
import { useState } from "react";
import { useWebsocket } from "src/context/WebsocketProvider";
import {
  Box,
  Card,
  Divider,
  Typography,
  CardContent,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

const getCreator = (name?: string) => {
  if (name === "checkAndRunImportsCronJob") {
    return "scheduler";
  }
  return name;
};

const IntegrationImportGroup = (props: {
  groupedJob: IntegrationImportGroupType;
}) => {
  const [t] = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const { integrationImportJobs: integrationImportJobsFromWebsocket } =
    useWebsocket();

  const { data: dataIntegrationImportJobGroupsQuery, isLoading } =
    useGetIntegrationImportJobGroupsQuery(
      { groupId: props.groupedJob.groupId, includeJobs: true },
      { skip: !isExpanded },
    );

  let jobs = dataIntegrationImportJobGroupsQuery?.data[0]
    ? dataIntegrationImportJobGroupsQuery?.data[0].jobs
    : props.groupedJob.jobs || [];

  const formattedDate = getFormattedDate({
    t,
    date: props.groupedJob.createdAt,
  });

  jobs = addJobs({
    currentJobs: jobs || [],
    newJobs: integrationImportJobsFromWebsocket,
    groupId: props.groupedJob.groupId,
  });

  const { nameTypeToPretty } = useIntegrationImportSetups();
  const jobRunning = jobs.find((j) => j.status === "running");
  const stepRunning = jobs.find((j) =>
    j?.steps?.find((s) => s.status === "running"),
  );

  return (
    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding:
            "var(--space-8px) var(--space-8px) var(--space-8px) var(--space-16px)",
        }}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap="var(--space-8px)"
          >
            <Typography sx={{ fontSize: "16px" }}>
              {`${t("Import Job")} ${t(
                nameTypeToPretty[props.groupedJob.groupMainImportName]?.name,
              )}`}
            </Typography>
            <>
              {props.groupedJob.groupStatus === "pending" && (
                <HourglassEmptyIcon
                  style={{ color: "orange" }}
                  fontSize="small"
                />
              )}
              {props.groupedJob.groupStatus === "running" && (
                <CircularProgress
                  color="primary"
                  size={20}
                  sx={{ padding: "var(--space-2px)" }}
                />
              )}
              {props.groupedJob.groupStatus === "completed" && (
                <CheckCircleIcon style={{ color: "green" }} fontSize="small" />
              )}
              {props.groupedJob.groupStatus === "error" && (
                <ErrorIcon style={{ color: "red" }} fontSize="small" />
              )}
            </>
            {props.groupedJob.retryCount !== 0 && (
              <Typography sx={{ fontSize: "14px" }} margin={1} marginRight={2}>
                {`# ${t("of retries")} ${props.groupedJob.retryCount}`}
              </Typography>
            )}
            {jobRunning && stepRunning && (
              <Typography sx={{ fontSize: "14px" }} margin={1} marginRight={2}>
                {`Running Job ${nameTypeToPretty[jobRunning.name].name} Step ${
                  jobRunning.currentStepIndex + 1
                }/${jobRunning.steps?.length}`}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap="var(--space-4px)"
            color="var(--gray-4)"
          >
            <Typography sx={{ fontSize: "12px" }}>{t("Created by")}</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {getCreator(props.groupedJob?.createdBy?.name)}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>{t("on")}</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {formattedDate}
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ textAlign: "center" }}
            >
              {!(
                props.groupedJob.groupMainImportName === "import_all" ||
                jobs?.length === 1
              ) ? (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={"100%"}
                  >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <Typography sx={{ fontSize: "16px" }} margin={1}>
                      {`1. ${t("Pre steps")}`}
                    </Typography>
                    {jobs
                      ?.slice(0, -1)
                      .map((job, index) => (
                        <IntegrationImportJob key={index} job={job} />
                      ))}
                  </Box>

                  <Divider
                    sx={{
                      width: "100%",
                      marginBottom: "2rem",
                      marginTop: "2rem",
                    }}
                  />
                  <Typography sx={{ fontSize: "18px" }} margin={1}>
                    {`2. ${t("Final step")}`}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginBottom={2}
                    width={"100%"}
                  >
                    {jobs?.slice(-1)[0] && (
                      <IntegrationImportJob job={jobs?.slice(-1)[0]} />
                    )}
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  rowGap="var(--space-12px)"
                  marginBottom={3}
                  width={"100%"}
                >
                  {jobs?.map((job, index) => (
                    <IntegrationImportJob key={index} job={job} />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default IntegrationImportGroup;
