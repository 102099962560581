/**
 *Created by Mikael Lindahl on 2023-06-07
 */

import ButtonCore, { ButtonCoreProps } from "./ButtonCore";

const ButtonPrimary = (props: ButtonCoreProps) => {
  return (
    <ButtonCore variant="contained" {...props}>
      {props.children}
    </ButtonCore>
  );
};

export default ButtonPrimary;
