/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import getDisabled from "../Utils/getDisabled";
import getRequired from "../Utils/getRequired";
import getValue from "../Utils/getValue";
import setValue from "../Utils/setValue";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import getHelperText from "../Utils/getHelperText";
type DateColumnItemTProps = CommonBuilderTableTypes;

const DateInputColumnItem = (props: DateColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);
  const value = getValue({
    data: props.data,
    item: props.item,
    rowIndex: props.rowIndex,
  });

  return (
    <ThemeProvider
      theme={(baseTheme) =>
        createTheme({
          components: {
            MuiPickersPopper: {
              styleOverrides: {
                root: {
                  backgroundColor: "var(--white-primary)",
                },
              },
            },
          },
        })
      }
    >
      <DesktopDatePicker
        inputFormat="yyyy-MM-dd"
        disabled={itemDisabled}
        value={value}
        onChange={(value, keyboardInputValue) => {
          // Keyboard input value is undefined when using date picker dialog
          // or the current value of the edited date in the text box
          value =
            keyboardInputValue !== undefined
              ? new Date(keyboardInputValue)
              : value;

          let changeProps = {
            ...commonCallbackProps,
            value,
          };
          if (props.item.setFormDataCustom) {
            props.item.setFormDataCustom(changeProps);
          } else {
            setValue({ ...changeProps });
          }
        }}
        renderInput={(params) => (
          <TextField
            required={getRequired(commonCallbackProps)}
            helperText={getHelperText(getHelperTextProps)}
            error={Boolean(getHelperText(getHelperTextProps))}
            size={"small"}
            style={{ minWidth: 160 }}
            {...params}
            id={props.item.dataName as string}
            hiddenLabel
            name={props.item.dataName as string}
          />
        )}
      />
    </ThemeProvider>
  );
};

export default DateInputColumnItem;
