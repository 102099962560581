import {
  initialUserState,
  initialUserStateTable,
  PersistTableState,
  updateUserState,
  UserState,
} from "../redux/slices/userState";
import { getNestedObjectData, setNestedObjectData } from "src/utils/nestedData";
import { RootState } from "src/redux/store";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import flattenObj from "src/utils/flattenObject";
import React, { createContext } from "react";

// Define the context type
export const UserStateContext = createContext<{
  checkIfUserStateTableIsUninitialized: ({ id }: { id: string }) => boolean;
  checkIfUserStateTableINeedsToBeConvertedToNewStructure: ({
    id,
  }: {
    id: string;
  }) => boolean;
  getUserStateTable: (id: string) => PersistTableState;
  userState: UserState;
  setUserState: (userState: UserState) => void;
  setUserStateTable: ({ id, data }: { id: string; data: any }) => void;
}>({
  checkIfUserStateTableIsUninitialized: () => false,
  checkIfUserStateTableINeedsToBeConvertedToNewStructure: () => false,
  getUserStateTable: () => initialUserStateTable, // Returns PersistTableState
  userState: initialUserState,
  setUserState: () => {},
  setUserStateTable: () => {},
});

// Context provider component with types
interface UserStateProviderProps {}

export const UserStateProvider: React.FC<UserStateProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const userState = useSelector<RootState, UserState>(({ userState }) => {
    const flattenedInitial = flattenObj(initialUserState);

    for (const key in flattenedInitial) {
      const value = flattenedInitial[key as keyof typeof flattenedInitial];
      const currentVal = getNestedObjectData({ data: userState, key });

      if (currentVal === undefined) {
        setNestedObjectData({ data: userState, key, value });
      }
    }
    return userState;
  });

  const setUserState = (userState: UserState) => {
    dispatch(updateUserState(userState));
  };

  const getUserStateTable = (id: string) => {
    let currentState = userState?.table?.[id];

    if (checkIfUserStateTableIsUninitialized({ id })) {
      currentState = initialUserStateTable;
    } else if (checkIfUserStateTableINeedsToBeConvertedToNewStructure({ id })) {
      // To convert table previous structure
      currentState = {
        ...initialUserStateTable,
        ...currentState,
      };
    } else {
      const flattenedInitialUserStateTable = flattenObj(initialUserStateTable);
      for (const key in flattenedInitialUserStateTable) {
        const value =
          flattenedInitialUserStateTable[
            key as keyof typeof flattenedInitialUserStateTable
          ];
        const currentVal = getNestedObjectData({ data: currentState, key });
        if (currentVal === undefined) {
          setNestedObjectData({ data: currentState, key, value });
        }
      }
    }
    return currentState;
  };

  const setUserStateTable = ({ id, data }: { id: string; data: any }) => {
    const key = `table.${id}`;
    let newUserState = cloneDeep(userState);
    // This function sets nested data even if it is keys is not defined
    // It then just adds the neccesary keys
    setNestedObjectData({ data: newUserState, key, value: data });
    dispatch(updateUserState(newUserState));
  };

  const checkIfUserStateTableIsUninitialized = ({ id }: { id: string }) => {
    const currentState = userState?.table?.[id];

    return !currentState;
  };

  const checkIfUserStateTableINeedsToBeConvertedToNewStructure = ({
    id,
  }: {
    id: string;
  }) => {
    const currentState = userState?.table?.[id];

    return (
      !!currentState && !currentState.columnVisibility && !currentState.filter
    );
  };

  return (
    <UserStateContext.Provider
      value={{
        checkIfUserStateTableIsUninitialized,
        checkIfUserStateTableINeedsToBeConvertedToNewStructure,
        getUserStateTable,
        userState,
        setUserState,
        setUserStateTable,
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
};
