import { usePreviewPlannedInvoiceMutation } from "src/redux/services/PlannedinvoiceService";
import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { Types } from "mongoose";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import useTranslation from "src/hooks/useTranslationWrapper";

const usePreviewPlannedInvoices = ({
  plannedInvoiceIds,
}: {
  plannedInvoiceIds?: Types.ObjectId[];
}) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [previewData, setPreviewData] = useState<PlannedInvoiceGetList>();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [previewPlannedInvoice] = usePreviewPlannedInvoiceMutation();

  const onPreview = async () => {
    setIsPreviewLoading(true);

    // No rows found
    if (plannedInvoiceIds === undefined || plannedInvoiceIds?.length === 0) {
      console.error("No rows selected for preview");
      setIsPreviewLoading(false);
      return enqueueSnackbar("No rows selected for preview", {
        variant: "info",
      });
    }

    let msg = "Planned invoice(s) previewed";
    let variant: VariantType = "success";

    // To preview Planned invoice(s)
    try {
      const result = await previewPlannedInvoice({ plannedInvoiceIds });

      if ("error" in result) {
        throw result?.error;
      }

      if ("data" in result) {
        setPreviewData(result?.data);
      }
    } catch (error: any) {
      const mainMsg = t("Planned invoice(s) couldn't be previewed");
      const subMsg = parseRestApiErrorMessage(error);

      msg = subMsg ? mainMsg + ": " + subMsg : mainMsg;
      variant = "error";
    }

    setIsPreviewLoading(false);
    enqueueSnackbar(msg, { variant });
    return;
  };

  return {
    isPreviewLoading,
    onPreview,
    previewData,
    setPreviewData,
  };
};

export default usePreviewPlannedInvoices;
