/**
 *Created by Mikael Lindahl on 2023-12-14
 */
import ConsentDialog from "../../Mixed/Dialogs/ConsentDialog";
import TextField from "@mui/material/TextField";
import useTranslation from "src/hooks/useTranslationWrapper";
import { useState, ReactNode, MouseEvent } from "react";

type YesNoDialogTProps = {
  children: ReactNode;
  open: boolean;
  onNo: () => void;
  onYes: (e: MouseEvent<HTMLButtonElement>, comment: string) => void;
};

const YesNoWithCommentDialog = (props: YesNoDialogTProps) => {
  const [t] = useTranslation();
  const [comment, setComment] = useState("");

  return (
    <ConsentDialog
      consentButtons={[
        {
          label: t("No"),
          onClick: props.onNo,
          variant: "contained",
        },
        {
          label: t("Yes"),
          onClick: (e) => props.onYes(e, comment),
          variant: "outlined",
        },
      ]}
      open={props.open}
    >
      {props.children}
      <TextField
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t("Enter your comment here")}
        fullWidth
        margin="normal"
        multiline
      />
    </ConsentDialog>
  );
};

export default YesNoWithCommentDialog;
