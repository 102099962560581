import useTranslation from "src/hooks/useTranslationWrapper";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BuildItems from "src/components/Builders/Container/BuildItems";
import useTranslationImportModalStructure, {
  TranslationImport,
} from "../hooks/useTranslationImportModalStructure";
import useFormContainer from "src/hooks/useFormContainer";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import { Translation } from "src/accurasee-backend-types/app/translation/translation.types";
import { setNestedObjectData } from "src/utils/nestedData";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import { useRef } from "react";

interface ImportModalProps {
  handleCloseModal: (
    action: "add" | "cancel",
    data?: Translation[],
    importType?: TranslationImport["importType"],
  ) => void;
  translations: Translation[];
  openModal: boolean;
}

const initialData: TranslationImport = {
  files: [],
};

export default function TranslationImportModalWithForm(
  props: ImportModalProps,
) {
  const [t] = useTranslation();
  const { getStructure } = useTranslationImportModalStructure();

  const {
    formData,
    hasDataChanged,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer({
    initialFormData: initialData,
    getStructure,
  });

  const refForm = useRef<any>();

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={() => props.handleCloseModal("cancel")}
      openModal={props.openModal}
      label={"Import translation file"}
      subLabel={"Select a translation file to import"}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (formData?.fileData) {
            const data = formData?.fileData.map((d: any) =>
              Object.keys(d).reduce((dic, key) => {
                setNestedObjectData({ data: dic, key, value: d[key] });

                return dic;
              }, {}),
            );

            props.handleCloseModal("add", data, formData?.importType);
            setFormData(initialData);
            setHasTriedToSubmit(false);
          }
        }}
        ref={refForm}
      >
        <Section>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
        <SectionWithButtonContainer buttonCenter>
          <Button
            onClick={() => {
              props.handleCloseModal("cancel");
              setHasTriedToSubmit(false);
              setFormData(initialData);
            }}
            variant="outlined"
            sx={{ minWidth: "112px" }}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={
              !isFormValid || !hasDataChanged || formData?.files?.length === 0
            }
            // type="submit"
            style={{ marginLeft: 16, minWidth: "112px" }}
            onClick={() => {
              setHasTriedToSubmit(true);
              refForm.current.requestSubmit();
            }}
          >
            {t("Add")}
          </LoadingButton>
        </SectionWithButtonContainer>
      </form>
    </ModalWrapper>
  );
}
