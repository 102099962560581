import { getStructure } from "./ProfilePasswordGetStructure";
import { Password } from "src/accurasee-backend-types/app/user/user.types";
import { UPDATE_USER } from "src/redux/actions/types";
import { useDispatch } from "react-redux";
import { useUpdateUserMutation } from "src/redux/services/UserService";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import getChangedData from "src/utils/getChangedData";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";

const ProfilePassword = () => {
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const initialFormData: Password = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  };
  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    structure,
    setHasTriedToSubmit,
  } = useFormContainer<Password>({
    getStructure,
    initialFormData,
  });

  const submitData = getChangedData<Partial<Password>>({
    data: formData || {},
    initData: initialFormData,
  });
  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { update: updateUser },
      data: {
        update: {
          currentPassword: submitData.currentPassword,
          newPassword: submitData.newPassword,
        },
      },
      name: "Password",
    },
    onSuccess: ({ responseData }) => {
      setFormData({ ...initialFormData });
      setHasTriedToSubmit(false);
      // Keep this to dispathc update of user since the store value
      // might be used for legacy code concerning login flow
      dispatch({ type: UPDATE_USER, payload: responseData?.update });
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        label={"Password"}
        subLabel={"Update password"}
        rightBoxArea={
          <ButtonGroup>
            <ButtonClear
              id={"update-profile-password-cancel"}
              onClick={() => setFormData(initialFormData)}
            />
            <ButtonUpdate
              id={"update-profile-password"}
              initialSubmitData={{}}
              isValid={isFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={submitData}
            />
          </ButtonGroup>
        }
      >
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </SectionTight>
    </form>
  );
};

export default ProfilePassword;
