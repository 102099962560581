import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";

type ButtonUpdateTProps = Omit<ButtonSubmitCoreTProps, "label"> & {
  label: "create" | "update";
};

const ButtonUpdate = (props: ButtonUpdateTProps) => {
  return <ButtonSubmitCore {...props} />;
};

export default ButtonUpdate;
