import { useNavigate } from "react-router-dom";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import ContractTypesTable from "./components/ContractTypesTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import usePathPermission from "src/hooks/usePathPermission";

const ContractsTypes = () => {
  const navigate = useNavigate();
  const pathPermissions = usePathPermission(
    "/app/contracts/contracttypes/create",
  );

  return (
    <Page
      label={"Contract type list"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        { label: "Contract types" },
      ]}
      boxRight={
        <ButtonAddBoxIcon
          id="direct-contract-type-create-page"
          label="Create contract type"
          onSubmit={() => navigate("/app/contracts/contracttypes/create")}
          permissions={pathPermissions}
        />
      }
    >
      <ContractTypesTable />
    </Page>
  );
};

export default ContractsTypes;
