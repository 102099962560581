import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import InvoicePlanTemplatesTable from "./InvoicePlanTemplateTable";

const InvoicePlanTemplates = () => {
  return (
    <Page
      label={"Invoice plan templates"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: "Invoice plan templates",
        },
      ]}
    >
      <SectionContainer>
        <InvoicePlanTemplatesTable />
      </SectionContainer>
    </Page>
  );
};

export default InvoicePlanTemplates;
