import i18next from "i18next";

const toCurrency: (options: {
  value: string | number;
  currencyCode: string | undefined;
  toInteger?: boolean;
}) => string = ({ value, currencyCode, toInteger = false }) => {
  // Add logic to determine where the currency symbol should be placed
  // This is a basic example, adjust according to your actual requirements

  const formatter = new Intl.NumberFormat(
    currencyCode === "SEK" ? "sv-SE" : i18next.language || "sv-SE",
    {
      style: "currency",
      currency: currencyCode,
      maximumFractionDigits: toInteger ? 0 : 2, // (causes 2500.99 to be printed as $2,501)
    },
  );

  return formatter.format(isNaN(Number(value)) ? 0 : Number(value));
};

export default toCurrency;
