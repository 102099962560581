import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "../../Basic/Simple/Buttons/ButtonClear";
import ButtonCreate from "src/components/Basic/Simple/Buttons/ButtonCreate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import Constants from "src/constants/Constants";
import ContactPersonModal from "../../Complex/Modals/ContactPersonModal";
import CustomerModal from "src/components/Complex/Modals/customer/CustomerModal";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import clone from "src/utils/clone";
import dimensionApi from "src/redux/services/DimensionService";
import getContractOfferExternalId from "../../../utils/getContractOfferExternalId";
import moment from "moment-timezone";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import usePathPermission from "src/hooks/usePathPermission";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import { ContractCreate as ContractCreateType } from "src/utils/getDimensionItems";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { MicrosoftDriveContext } from "../../../context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { useCreateContractMutation } from "src/redux/services/ContractService";
import { useGetContactPeopleQuery } from "src/redux/services/ContactPersonService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetProjectsQuery } from "src/redux/services/ProjectService";
import { useNavigate } from "react-router-dom";
import { escape } from "src/utils/translate";
import { useSnackbar } from "notistack";
import {
  GetStructureExtraProps,
  ItemsTypes,
  useContractCreateStructure,
  toData,
  toSubmitData,
  getContractFeatures,
} from "./hooks/useContractCreateStructure";
import {
  useCreateContractOfferMutation,
  useGetContractOfferQuery,
} from "src/redux/services/ContractOfferService";
import {
  useGetAllProjectExternalQuery,
  useGetCompanyQuery,
} from "src/redux/services/CompanyService";
import {
  useGetContractTypeQuery,
  useGetContractTypesQuery,
} from "src/redux/services/ContractTypesServices";

export function addDaysToDate(date: Date, daysToAdd: number) {
  let newDate = moment.utc(new Date(date)).add(daysToAdd, "days").toDate();
  return newDate;
}

export type CreationCategory = "contract_offer" | "contract";

const ContractCreateBase = ({
  contractOfferId,
  creationCategory = "contract",
  rerouteUrlOnSuccess = "/app/contracts",
  createMutation,
}: {
  contractOfferId?: string;
  creationCategory?: CreationCategory;
  rerouteUrlOnSuccess?: string;
  createMutation: ReturnType<
    typeof useCreateContractMutation | typeof useCreateContractOfferMutation
  >;
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const pathPermissions = usePathPermission("/app/operations/customers");

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const { microsoftDriveClient } = useContext(MicrosoftDriveContext);

  const { enqueueSnackbar } = useSnackbar();
  const { data: offer, isLoading: isOfferLoading } = useGetContractOfferQuery(
    contractOfferId,
    { skip: contractOfferId === undefined },
  );

  const { data: projectsRes, isLoading: isLoadingProjects } =
    useGetProjectsQuery(undefined);
  const projects = projectsRes?.data;

  const { data: contactsRes, isLoading: isLoadingContacts } =
    useGetContactPeopleQuery(undefined);
  const contacts = contactsRes?.data || [];
  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data;

  /////Get contract typesBackend
  const { data: contractTypesQuery, isLoading: isLoadingContractTypes } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data;

  const { data: _contractType, isLoading: isLoadingContractType } =
    useGetContractTypeQuery(offer?.contractTypeId, {
      skip: offer?.contractTypeId === undefined,
    });

  /////dimensions
  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    dimensionApi.useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const {
    data: projectExternalsQuery,
    isLoading: isLoadingProjectExternalIds,
  } = useGetAllProjectExternalQuery(undefined);
  const projectExternals = projectExternalsQuery?.data || [];

  const isLoadingData =
    isLoadingContacts ||
    isLoadingProjects ||
    isLoadingCustomers ||
    isLoadingDimensions ||
    isLoadingContractType ||
    isLoadingContractTypes ||
    isLoadingCompany ||
    isOfferLoading ||
    isLoadingProjectExternalIds;

  const [contractType, setContractType] = useState<ContractType>();
  const [currencyCode, setCurrencyCode] = useState("SEK");

  useEffect(() => {
    if (_contractType) {
      setContractType(_contractType);
    }
  }, [_contractType]);

  const deadline = useMemo(() => {
    return addDaysToDate(new Date(), Constants.DEFAULT_OFFER_DEADLINE);
  }, []);

  const offerData: Partial<Contract> = offer
    ? {
        contactPersonId: offer.contactPersonId,
        contractOfferId: offer._id,
        contractTypeId: offer.contractTypeId,
        currencyCode: offer.currencyCode,
        customFieldsData: offer.customFieldsData,
        customerId: offer.customerId,
        ...getContractFeatures({
          creationCategory,
          contractType,
          totalPrice: offer.offerPrice,
        }),
        description: offer?.description,
        name:
          creationCategory === "contract_offer"
            ? offer.name + " (Clone)"
            : offer.name,
      }
    : {};

  const initialFormData =
    creationCategory === "contract"
      ? toData({
          data: {
            endDate: new Date(moment().add(6, "months").format("YYYY-MM-DD")),
            name: "",
            startDate: new Date(moment().format("YYYY-MM-DD")),
            ...offerData,
            ...(offer
              ? {
                  _id: offer.pendingContractId,
                  projectExternalId: offer.projectExternalId,
                }
              : {}),
          } as Partial<Contract>,
        })
      : ({
          currencyCode: "SEK",
          deadline: offer?.deadline || deadline,
          ...offerData,
          ...(offer
            ? {
                description: offer.description,
                projectExternalId: offer.projectExternalId,
              }
            : {}),
        } as Partial<ContractOffer>);

  const extraProps: GetStructureExtraProps = {
    company,
    contacts,
    contractType,
    contractTypes,
    creationCategory,
    currencyCode,
    customers,
    dimensions,
    projectExternals,
    isContactModalOpen,
    isCustomerModalOpen,
    isFromOffer: !!offer,
    projects,
    setContractType,
    setCurrencyCode,
    setIsContactModalOpen,
    setIsCustomerModalOpen,
  };

  const { getStructure } = useContractCreateStructure();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<ContractCreateType, GetStructureExtraProps, ItemsTypes>({
    getStructure,
    initialFormData,
    extraProps,
  });

  const submitData = toSubmitData({ creationCategory, data: formData });
  const submitDataForContractOffer = clone(submitData) as ContractOffer;

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createMutation[0],
      },
      data: {
        create:
          creationCategory === "contract"
            ? submitData
            : submitDataForContractOffer,
      },
      name: creationCategory === "contract" ? "Contract" : "Offer",
      rerouteUrlOnSuccess: {
        create: ({ responseData }) =>
          `${rerouteUrlOnSuccess}/${responseData?._id}`,
      },
    },
    onSuccess: ({ responseData }) => {
      const redirectUrl = `${rerouteUrlOnSuccess}/${responseData?.create?._id}`;

      if (microsoftDriveClient && formData && offer) {
        microsoftDriveClient
          .copyContractOfferToProject({
            contractOfferExternalId: getContractOfferExternalId(offer),
            projectExternalId: formData.projectExternalId,
          })
          .then(() => {
            // Time out to let microsoft drive update
            setTimeout(() => {
              enqueueSnackbar(t("Offer documents copied to project"), {
                variant: "success",
              });
              navigate(redirectUrl);
            }, 5000);
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar(t("Failed to copy offer documents to project"), {
              variant: "error",
            });
            navigate(redirectUrl);
          });
      } else if (microsoftDriveClient && responseData?.create) {
        const collectionName =
          creationCategory === "contract" ? "contracts" : "contract_offers";
        const newLeafFolderName =
          creationCategory === "contract"
            ? responseData?.create.projectExternalId
            : getContractOfferExternalId(responseData?.create, true);
        const newFolder = microsoftDriveClient?.getProjectPath({
          collectionName,
          leafFolder: newLeafFolderName,
        });
        microsoftDriveClient
          ?.createFolder({
            newLeafFolderName,
            sourcePath: microsoftDriveClient?.getProjectPath({
              collectionName,
            }),
          })
          .then((res) => {
            if (res !== "exist") {
              enqueueSnackbar(`${t("Created folder")}: ${newFolder}`, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(
                t(
                  `Folder ${escape(newFolder)} already exists no need to create`,
                ),
                {
                  variant: "info",
                },
              );
            }
            navigate(redirectUrl);
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar(t(`Failed to create folder ${escape(newFolder)}`), {
              variant: "error",
            });
            navigate(redirectUrl);
          });
      } else {
        navigate(redirectUrl);
      }
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const isDimensionActivated = contractType?.contractDimensions
    ? contractType?.contractDimensions.some((dimension) => dimension.active)
    : false;
  const isTotalPrice = contractType?.contractTypeFeatures?.totalPrice;
  const isHouseWork = contractType?.contractTypeFeatures?.houseWork;
  // const isEndDateReminder = contractType?.contractTypeFeatures?.endDateReminder;
  const isBkConnection = contractType?.contractTypeFeatures?.bkConnected;

  const isAdditionalContractInfo =
    contractType &&
    (isDimensionActivated ||
      isTotalPrice ||
      isHouseWork ||
      // isEndDateReminder ||
      isBkConnection);

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
        ref={refForm}
      >
        <Page
          breadcrumbs={
            creationCategory === "contract"
              ? [
                  { label: "Contracts", link: "/app/contracts" },
                  { label: "Add new contract" },
                ]
              : [
                  { label: "Offers", link: "/app/contract-offers" },
                  { label: "Add new offer" },
                ]
          }
          isFullWidth={false}
          isLoading={isLoadingData}
          label={
            creationCategory === "contract"
              ? "Create a new Contract"
              : "Create a new offer"
          }
        >
          <SectionContainer>
            {
              <Section
                key={`${creationCategory}-section`}
                label={
                  creationCategory === "contract"
                    ? "Contract info"
                    : "Offer info"
                }
                lg={12}
              >
                {BuildItems({
                  items: structure.items.filter(
                    (item) =>
                      item.itemType === "info" ||
                      item.itemType === "info_" + creationCategory,
                  ),
                  ...commonPropsBuildItem,
                })}
              </Section>
            }
            {creationCategory === "contract" && isAdditionalContractInfo ? (
              <Section key={"dimensions"} label={"Additional info"} lg={12}>
                {BuildItems({
                  items: structure.items.filter(
                    (item) => item.itemType === "additional_contract_data",
                  ),
                  ...commonPropsBuildItem,
                })}
              </Section>
            ) : (
              <></>
            )}
          </SectionContainer>

          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonClear
                id={"Create-contract-cancel"}
                onClick={() => {
                  setFormData(initialFormData);
                  setHasTriedToSubmit(false);
                }}
              />
              <ButtonCreate
                id={"Create-contract-create"}
                initialSubmitData={{}}
                isValid={isFormValid}
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                submitData={submitData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </Page>
      </form>
      {/* Add Customer Modal */}
      <CustomerModal
        openModal={isCustomerModalOpen && !!pathPermissions?.writeRights}
        setOpenModal={setIsCustomerModalOpen}
        postCreate={(resData) => {
          // To show Customer info to Contract Create form
          if (resData._id !== undefined && formData) {
            const state: ContractCreateType = {
              ...formData,
              ...(creationCategory === "contract"
                ? { customerName: resData.name }
                : {}),
              customerId: resData._id,
            };
            setFormData(state);
          }
        }}
      />

      {/* Add Contact Modal */}
      <ContactPersonModal
        customerId={formData?.customerId}
        openModal={isContactModalOpen && !!pathPermissions?.writeRights}
        setOpenModal={setIsContactModalOpen}
        postCreate={(resData) => {
          // To show Customer info to Offer Create form
          if (!!resData && formData) {
            const state = {
              ...formData,
              contactPersonId: resData._id,
            };
            setFormData(state);
          }
        }}
      />
    </>
  );
};

export default ContractCreateBase;
