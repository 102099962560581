/**
 * This is to get icon and style by type (Notifications from BE)
 */

import {
  AccountBox as CustomerIcon,
  Archive as ArchiveIcon,
  Adb as DebugIcon,
  BusinessCenter as DeliveredIcon,
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
  Description as DescriptionIcon,
  DiscFull as DiscIcon,
  Done as ShippedIcon,
  Edit as EditIcon,
  Email as MessageIcon,
  Error as DefenceIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  FileDownload,
  InfoOutlined as InfoOutlinedIcon,
  LocalOffer as TicketIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  NotificationsNone as NotificationsIcon,
  Publish as UploadIcon,
  Report as ReportIcon,
  ShoppingCart as ShoppingCartIcon,
  SmsFailed as FeedbackIcon,
  ThumbUp as ThumbUpIcon,
  Timelapse as TimelapseIcon,
  Undo as UndoIcon,
  Visibility as VisibilityIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { IconTypes } from "../CommonBuilderTableTypes";
import getStylingByStatusType, {
  StylingType,
  stylingProps,
} from "src/utils/getStylingByStatusType";

const getIconByType = (type: IconTypes | string = "message") => {
  let icon: any;
  let style: StylingType = "";
  switch (type) {
    case "alert":
      icon = <ErrorOutlineOutlinedIcon />;
      break;
    case "archived":
      icon = <ArchiveIcon />;
      break;
    case "check":
      icon = <CheckIcon />;
      style = "black";
      break;
    case "check_circle":
      icon = <CheckCircleIcon />;
      style = "success-no-background";
      break;
    case "contract":
      icon = <DescriptionIcon />;
      style = "information";
      break;
    case "copy":
      icon = <ContentCopyIcon />;
      style = "information";
      break;
    case "customer":
      icon = <CustomerIcon />;
      style = "information";
      break;
    case "debug":
      icon = <DebugIcon />;
      break;
    case "defence":
      icon = <DefenceIcon />;
      style = "warning-1";
      break;
    case "delete":
      icon = <DeleteForeverIcon />;
      break;
    case "delete_no_cross":
      icon = <DeleteIcon />;
      break;
    case "delivered":
      icon = <DeliveredIcon />;
      style = "information-1";
      break;
    case "disc":
      icon = <DiscIcon />;
      style = "information";
      break;
    case "download":
      icon = <FileDownload />;
      break;
    case "e_commerce":
      icon = <ShoppingCartIcon />;
      style = "information";
      break;
    case "edit":
      icon = <EditIcon />;
      break;
    case "feedback":
      icon = <FeedbackIcon />;
      style = "disabled";
      break;
    case "finished":
      icon = <CheckCircleIcon />;
      style = "success-1";
      break;
    case "incomplete":
      icon = <TimelapseIcon />;
      style = "alert";
      break;
    case "information":
      icon = <InfoOutlinedIcon />;
      style = "alert";
      break;
    case "locked":
      icon = <LockIcon />;
      style = "alert";
      break;
    case "message":
      icon = <MessageIcon />;
      style = "disabled";
      break;
    case "notification":
      icon = <NotificationsIcon />;
      style = "disabled";
      break;
    case "offer":
      icon = <TicketIcon />;
      style = "warning";
      break;
    case "reopen":
      icon = <LockOpenIcon />;
      style = "success";
      break;
    case "report":
      icon = <ReportIcon />;
      style = "alert";
      break;
    case "shipped":
      icon = <ShippedIcon />;
      style = "success-1";
      break;
    case "undo":
      icon = <UndoIcon />;
      break;
    case "upload":
      icon = <UploadIcon />;
      style = "information";
      break;
    case "view":
      icon = <VisibilityIcon />;
      break;
    case "warning":
      icon = <WarningAmberIcon />;
      break;
    default:
      // info
      icon = <ThumbUpIcon />;
      style = "information";
      break;
  }

  return {
    icon,
    style,
    styling: getStylingByStatusType(style) as stylingProps,
  };
};
export default getIconByType;
