import { makeStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: "var(--white-2)",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "var(--space-8px)",
      padding: "var(--space-16px) var(--space-24px)",
      width: "100%",
    },
    leftBox: {
      display: "flex",
      flexDirection: "column",
      rowGap: "var(--space-8px)",
    },
    rightBox: {
      display: "flex",
      flexDirection: "column",
      rowGap: "var(--space-8px)",
      textAlign: "right",
    },
    contentTitle: { fontSize: "14px" },
    content: { fontWeight: "bold" },
    warning: { color: "var(--orange-1)", fontStyle: "italic" },
  }),
);
