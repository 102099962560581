import { IntegrationImportStepType } from "../../app/integration_import_job/integration_import_job.types";

const integrationImportStepTypeToReadable: Record<
  IntegrationImportStepType,
  string
> = {
  file_sie: "Fetching sie file",
  list_json: "Fetching list",
  post_processing_customer_invoices_update_statuses:
    "Post processing update contract and invoice status",
  post_processing_voucher_generate_costs: "Post processing generate costs",
  post_processing_voucher_generate_incomes: "Post processing generate incomes",
  single_file_to_bucket: "Downloading files",
  single_json: "Fetching singles",
};
export default integrationImportStepTypeToReadable;
