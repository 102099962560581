import { HIDDEN_COMPONENT_TYPE } from "src/accurasee-backend-types/app/company_config/company_config.types";
import { HiddenComponentContext } from "src/context/HiddenComponentProvider";
import { useContext } from "react";

const useSidebarHiddenComponents = () => {
  const { hiddenComponentConfigs } = useContext(HiddenComponentContext);

  const sidebarHiddenComponents =
    hiddenComponentConfigs.find(
      (item) => item.hiddenComponentType === HIDDEN_COMPONENT_TYPE.SIDEBAR,
    )?.hiddenComponents || [];
  return sidebarHiddenComponents;
};

export default useSidebarHiddenComponents;
