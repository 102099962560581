import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import UserRoleModalWithForm from "./UserRoleModalWithForm";
import UserRolePermissionModal from "./UserRolePermissionModalWithForm";
import useFormTable from "src/hooks/useFormTable";
import useOnSubmit from "src/hooks/useOnSubmit";
import {
  AccessRolePermission,
  AccessRolePermissionWithPermissions,
} from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";
import {
  AccessUserRole,
  AccessUserRoleWithPermissions,
} from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetPermissionsQuery } from "src/redux/services/PermissionsService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import {
  getStructure,
  GetStructureExtraProps,
} from "./UserRoleTableGetStructure";
import {
  useDeleteUserRoleMutation,
  usePatchUserRoleMutation,
} from "src/redux/services/UserRolesService";
import BuilderColumnOptionsList from "../../../../../../../components/Builders/Table/BuilderColumnOptionsList";

type UserRoleTableTProps = { data: AccessUserRoleWithPermissions[] };

export function UserRoleTable({ data }: UserRoleTableTProps) {
  const [openDeleteUserRoleModal, setOpenUserRoleDeleteModal] = useState(false);
  const [openEditUserRoleModal, setOpenEditUserRoleModal] = useState(false);
  const [openEditUserRolePermissionModal, setOpenEditUserRolePermissionModal] =
    useState(false);
  const [userRoleDelete, setUserRoleDelete] = useState<
    AccessUserRole | undefined
  >();
  const [userRoleEdit, setUserRoleEdit] = useState<
    AccessUserRole | undefined
  >();
  const [userRoleIdPermissionEdit, setUserRoleIdPermissionEdit] =
    useState<AccessRolePermission[]>();
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>();
  const [deleteUserRole] = useDeleteUserRoleMutation();
  const [updateUserRole] = usePatchUserRoleMutation();

  const permissionQuery = {
    sortBy: "collectionTarget",
    roleId: [userRoleIdPermissionEdit],
  };

  const { data: permissions, isFetching } = useGetPermissionsQuery(
    permissionQuery,
    {
      skip: userRoleIdPermissionEdit === undefined,
    },
  );

  const userRolePermissionEdit = permissions?.data;

  const roleNames = data?.map((item: AccessUserRole) =>
    item.name.toLowerCase(),
  );

  const onSubmit = useOnSubmit({
    apiMutations: {
      update: updateUserRole,
    },
    name: "user role",
  });

  const { formData, structure } = useFormTable<
    AccessUserRoleWithPermissions,
    GetStructureExtraProps
  >({
    extraProps: {
      clickedRowIndex,
      isFetching,
      onDelete: (props) => {
        setOpenUserRoleDeleteModal(true);
        setUserRoleDelete(props.data[props.rowIndex]);
      },
      onEdit: (props) => {
        setOpenEditUserRoleModal(true);
        setUserRoleEdit(props.data[props.rowIndex]);
      },
      onEditPermissions: (props) => {
        setOpenEditUserRolePermissionModal(true);
        setUserRoleEdit(props.data[props.rowIndex]);
        setUserRoleIdPermissionEdit(props.data[props.rowIndex]._id);
      },
      onSubmitActiveSwitch: onSubmit,
      setClickedRowIndex,
    },
    initialFormData: data,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] =
    BuilderColumnOptionsList<AccessUserRoleWithPermissions>({
      items: structure.items,
      data: formData,
    });

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 50,
  });

  const names = roleNames.filter(
    (name) => name !== userRoleEdit?.name.toLowerCase(),
  );

  return (
    <>
      <MuiDataTableWrapper
        nameSpace={"main-table"}
        tableType={"compact"}
        title={""}
        data={formData}
        columns={columns}
        options={options}
      />
      <DeletionModal
        deleteData={userRoleDelete}
        dataType={"user role"}
        openModal={openDeleteUserRoleModal}
        deleteDataFunction={deleteUserRole}
        setIsOpenDeleteModal={setOpenUserRoleDeleteModal}
      />
      <UserRoleModalWithForm
        actionType={"edit"}
        openModal={openEditUserRoleModal}
        handleCloseModal={() => setOpenEditUserRoleModal(false)}
        initialData={userRoleEdit}
        names={names}
      />
      <UserRolePermissionModal
        openModal={openEditUserRolePermissionModal && !isFetching}
        data={
          userRolePermissionEdit ||
          ([] as AccessRolePermissionWithPermissions[])
        }
        roleName={userRoleEdit?.name}
        handleCloseModal={() => setOpenEditUserRolePermissionModal(false)}
      />
    </>
  );
}

export default UserRoleTable;
