import toUpdateData from "../../../../utils/toUpdateData";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { ReturnUser } from "../../../../accurasee-backend-types/app/user/user.types";

export interface GetStructureExtraProps {}

const getStructure: UserFormTableGetStructure<
  ReturnUser,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "text",
      dataName: "name",
      headerLabel: "Name",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) =>
        data[rowIndex].firstName + " " + data[rowIndex].lastName,
      // sort: true,
    },

    {
      type: "switch",
      dataName: "isDeveloper",
      cellLabel: "is developer",
      sx: { width: "100%" },
      setFormData,
      // sort: true,
    },
  ],
});

export const toSubmitData = (
  data: Partial<ReturnUser>[],
  initialData: Partial<ReturnUser>[],
) => {
  let submitData: Partial<ReturnUser>[] = [];
  data.forEach((d, index) => {
    let update = toUpdateData({ data: d, initData: initialData[index] });
    if (Object.keys(update).length !== 0) {
      submitData.push({ _id: d._id, ...update });
    }
  });

  return submitData;
};

export default getStructure;
