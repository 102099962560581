/**
 *Created by Mikael Lindahl on 2024-02-08
 */

import { useLocation, matchPath } from "react-router-dom";

const useRouteMatch = (paths: string | string[]) => {
  const location = useLocation();
  const pathArray = Array.isArray(paths) ? paths : [paths];

  for (const path of pathArray) {
    const match = matchPath({ path, end: true }, location.pathname);

    if (match) {
      return {
        path: match.pattern.path, // the path pattern used to match
        url: location.pathname.slice(0, match.pathname.length), // the matched portion of the URL
        isExact: location.pathname === match.pathname, // true if the entire URL was matched (no trailing characters)
        params: match.params, // an object containing key/value pairs of the dynamic params from the URL
      };
    }
  }

  return null;
};

export default useRouteMatch;
