/**
 *Created by Mikael Lindahl on 2024-03-20
 */

import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import IntegrationImport from "./IntegrationImport";
import IntegrationImportGroup from "./IntegrationImportGroup";
import LinearProgress from "@material-ui/core/LinearProgress";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import PaginationBar from "src/components/Basic/Mixed/Pagination/PaginationBar";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import SwitchWrapper from "src/components/Basic/Simple/Switches/SwitchWrapper";
import addJobs from "../Utils/addJobs";
import clone from "src/utils/clone";
import getDependenciesForIntegration from "src/utils/getDependenciesForIntegration/getDependenciesForIntegration";
import isAllJobsDisabled from "../Utils/isAllJobsDisabled";
import isJobDisabled from "../Utils/isJobDisabled";
import sort from "src/utils/sort";
import useGroupedJobs from "../Hooks/useGroupedJobs";
import useIntegrationImportSetups from "../Hooks/useIntegrationImportSetups";
import useQueueJobsForCurrentSteps from "../Hooks/useQueueJobsForCurrentSteps";
import useTranslation from "src/hooks/useTranslationWrapper";
import { INTEGRATION_IMPORTS_DEPENDENCIES_ALL } from "src/accurasee-backend-types/shared/constants/integration.constants";
import { IntegrationImportJobName } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { IntegrationImportName } from "src/accurasee-backend-types/app/integrations/integration.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { updateDeveloperState } from "src/redux/slices/developerState";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useWebsocket } from "src/context/WebsocketProvider";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  useDeleteIntegrationImportJobsMutation,
  useGetIntegrationImportJobsPendingAndRunningQuery,
  useRunIntegrationImportJobMutation,
} from "src/redux/services/IntegrationImportJobService";

const IntegrationImports = () => {
  const [currentImport, setCurrentImport] = useState<
    IntegrationImportJobName | "import_all" | null
  >(null);
  const [currentImportNames, setCurrentImportNames] = useState<
    IntegrationImportJobName[]
  >([]);
  const [t] = useTranslation();
  const [triggerFullDownload, setTriggerFullDownload] = useState(false);

  let {
    countCompletedAndError,
    developerState,
    groupedCompletedAndError,
    groupedPendingAndRunning,
    isLoadingCompletedAndError,
    isLoadingRunningAndPending,
    pagination,
    isShowOnlyErrors,
    pageSizeOptions,
    setIsShowOnlyErrors,
  } = useGroupedJobs();

  // console.log("groupedPendingAndRunning", groupedPendingAndRunning);

  const dispatch = useDispatch();
  const gridRef = useRef<HTMLDivElement>(null);

  const user = useSelector(
    (state: { user: { user: ReturnUser } }) => state.user.user,
  );
  const {
    integrationImportJobs: integrationImportJobsFromWebsocket,
    setLatestIntegrationImportJobsByGroupId,
    setIntegrationImportJobs,
  } = useWebsocket();

  const [deleteMutation] = useDeleteIntegrationImportJobsMutation();
  const [runMutation] = useRunIntegrationImportJobMutation();

  const { data: integrationImportJobsPendingAndRunningQuery } =
    useGetIntegrationImportJobsPendingAndRunningQuery(undefined);

  const jobsPendingAndRunning =
    integrationImportJobsPendingAndRunningQuery?.data;

  const jobsWithBroadcast = addJobs({
    currentJobs: jobsPendingAndRunning || [],
    newJobs: integrationImportJobsFromWebsocket,
  });

  const { importSetups } = useIntegrationImportSetups();

  const queueJobsForCurrentSteps = useQueueJobsForCurrentSteps(
    currentImportNames,
    triggerFullDownload,
  );

  const buttonSetup: {
    name: IntegrationImportName;
    nameTranslated: string;
    integrationImportJobName: IntegrationImportJobName;
  }[] = sort(
    importSetups.map(({ name, integrationImportJobName }) => ({
      name,
      nameTranslated: t(name),
      integrationImportJobName,
    })),
    (v) => v.nameTranslated,
  );

  useEffect(() => {
    if (currentImport !== null) {
      queueJobsForCurrentSteps(currentImport === "import_all")
        .catch((error) => console.error(error))
        .finally(() => {
          setCurrentImport(null);
          setCurrentImportNames([]);
        });
    }
  }, [currentImportNames]);

  useEffect(() => {
    if (currentImport === "import_all") {
      setCurrentImportNames(INTEGRATION_IMPORTS_DEPENDENCIES_ALL);
    } else if (currentImport !== null) {
      const pre = getDependenciesForIntegration({
        integrationImportJobName: currentImport,
      });

      setCurrentImportNames(pre);
    }
  }, [currentImport]);

  return (
    <SectionContainer>
      <SectionTight
        label={"Accounting imports"}
        subLabel={"Manage imports for your accounting integration"}
        rightBoxArea={
          <>
            {user.isDeveloper && (
              <SwitchWrapper
                sx={{ marginTop: "1rem" }}
                checked={!!developerState.debug["integration_import"]}
                onClick={() => {
                  const newDeveloperState = clone(developerState);
                  newDeveloperState.debug["integration_import"] =
                    !newDeveloperState.debug["integration_import"];

                  dispatch(updateDeveloperState({ ...newDeveloperState }));
                }}
                label={"is debug"}
              />
            )}
          </>
        }
      >
        <Grid
          container
          paddingTop={3}
          columnSpacing={2}
          rowSpacing={4}
          marginLeft={0}
        >
          {developerState.debug["integration_import"] && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "var(--space-8px)",
                }}
              >
                {t("Testing")}
              </Typography>

              <Box sx={{ display: "flex", columnGap: "var(--space-16px)" }}>
                <ButtonPrimary
                  onClick={() => {
                    runMutation(undefined).catch((e) => console.error(e));
                  }}
                >
                  Process one queue entry
                </ButtonPrimary>
                <ButtonPrimary
                  onClick={() => {
                    deleteMutation(undefined).catch((e) => console.error(e));
                    setLatestIntegrationImportJobsByGroupId({});
                    setIntegrationImportJobs([]);
                  }}
                >
                  Delete queue
                </ButtonPrimary>
                <SwitchWrapper
                  checked={
                    developerState?.showTestData &&
                    !!developerState.showTestData["integration_import"]
                  }
                  onClick={() => {
                    const newDeveloperState = clone(developerState);

                    if (!newDeveloperState.showTestData)
                      newDeveloperState.showTestData = {};

                    newDeveloperState.showTestData["integration_import"] =
                      !newDeveloperState.showTestData["integration_import"];

                    dispatch(updateDeveloperState({ ...newDeveloperState }));
                  }}
                  label={"show test data"}
                />
                <SwitchWrapper
                  checked={triggerFullDownload}
                  onClick={() => {
                    setTriggerFullDownload(!triggerFullDownload);
                  }}
                  label={"trigger full download"}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "var(--space-8px)",
                }}
              >
                {t("Trigger imports")}
              </Typography>
              <Grid container spacing={1} id="import-all-results" ref={gridRef}>
                <Grid key={"all"} item xs={3}>
                  <IntegrationImport
                    disabled={isAllJobsDisabled({
                      jobs: jobsWithBroadcast,
                    })}
                    label={"All"}
                    onImport={() => {
                      setCurrentImport("import_all");
                    }}
                  />
                </Grid>
                {buttonSetup.map(
                  ({ name, nameTranslated, integrationImportJobName }) => (
                    <Grid key={name} item xs={3}>
                      <IntegrationImport
                        disabled={isJobDisabled({
                          job: jobsWithBroadcast,
                          integrationImportJobName,
                        })}
                        label={nameTranslated}
                        onImport={() =>
                          setCurrentImport(integrationImportJobName)
                        }
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Divider textAlign="left">
              {isLoadingRunningAndPending || isLoadingCompletedAndError ? (
                <CircularProgress
                  color="primary"
                  size={35}
                  sx={{ padding: "var(--space-4px)", verticalAlign: "middle" }}
                />
              ) : (
                <OfflineBoltOutlinedIcon
                  fontSize="large"
                  sx={{ color: "var(--gray-4)", verticalAlign: "middle" }}
                />
              )}
            </Divider>
          </Grid>
          <Grid item id="queue-feed-pending-running" xs={6}>
            {isLoadingRunningAndPending ? (
              <LinearProgress />
            ) : (
              <>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginBottom: "var(--space-8px)",
                  }}
                >
                  {t("In Progress import jobs")}
                </Typography>
                <Grid container spacing={1} id="import-all-results">
                  {sort(
                    groupedPendingAndRunning,
                    (v) => v.createdAt,
                    "desc",
                  ).map((groupedJob, index) => (
                    <Grid key={String(groupedJob.groupId)} item xs={12}>
                      <IntegrationImportGroup groupedJob={groupedJob} />
                    </Grid>
                  ))}{" "}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item id="queue-feed-completed" xs={6}>
            {isLoadingCompletedAndError ? (
              <LinearProgress />
            ) : (
              <Box
                style={{
                  overflowY: "auto",
                  width: "100%",
                  minHeight: "300px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginBottom: "var(--space-8px)",
                  }}
                >
                  {t("Finished import jobs")}
                </Typography>
                {countCompletedAndError > 10 && (
                  <PaginationBar
                    count={countCompletedAndError}
                    pageSizeOptions={pageSizeOptions}
                    pagination={pagination}
                  />
                )}
                <SwitchWrapper
                  checked={isShowOnlyErrors}
                  onClick={() => setIsShowOnlyErrors(!isShowOnlyErrors)}
                  label={"Show only jobs with errors"}
                />
                <Grid container spacing={1} marginTop={1}>
                  {sort(
                    groupedCompletedAndError,
                    (v) => v.createdAt,
                    "asc",
                  ).map((groupedJob) => (
                    <Grid key={String(groupedJob.groupId)} item xs={12}>
                      <IntegrationImportGroup groupedJob={groupedJob} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </SectionTight>
    </SectionContainer>
  );
};

export default IntegrationImports;
