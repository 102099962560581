import { SendContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

export const getStructure: UseFormContainerGetStructure<SendContractOffer> = ({
  setFormData,
}) => {
  const structure: StructureContainer<SendContractOffer> = {
    items: [
      {
        type: "text_input",
        required: true,
        dataName: "message",
        label: "Message",
        minRows: 7,
        multiline: true,
        setFormData,
      },
    ],
  };
  return structure;
};
