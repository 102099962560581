import {
  addRow,
  getStructure,
  getFormInfoStructure,
  GetStructureExtraProps,
  ReportRowProps,
  ReportInfoProps,
} from "./ReportStructure";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ReportTemplate } from "src/accurasee-backend-types/app/report_template/report_template.types";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import {
  useCreateReportTemplateMutation,
  useUpdateReportTemplateMutation,
  useDeleteReportTemplateMutation,
} from "src/redux/services/ReportTemplateService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreate from "src/components/Basic/Simple/Buttons/ButtonCreate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonDeleteWithYesNoDialog from "src/components/Basic/Simple/Buttons/ButtonDeleteWithYesNoDialog";

const Report = ({
  data,
  disableMoveDown,
  disableMoveUp,
  openModal = false,
  onCloseModal,
  onMoveDown,
  onMoveUp,
}: {
  data: ReportTemplate;
  disableMoveDown?: boolean;
  disableMoveUp?: boolean;
  openModal?: boolean;
  onCloseModal?: () => void;
  onMoveDown?: () => void;
  onMoveUp?: () => void;
}) => {
  const [t] = useTranslation();

  const { data: dataReportTemplateYFiltersQuery, isLoading } =
    useGetReportTemplateYFiltersQuery(undefined);
  const [createReport] = useCreateReportTemplateMutation(undefined);
  const [updateReportTemplate] = useUpdateReportTemplateMutation();
  const [deleteReportTemplate, { isLoading: isDeleting }] =
    useDeleteReportTemplateMutation();

  const reportTemplateYFilters = dataReportTemplateYFiltersQuery?.data || [];

  const initialFormData = data.yFilterIds?.map(
    ({ filterId, filterType, isBold, isLarge }) => ({
      filterId,
      filterType,
      isBold,
      isLarge,
    }),
  );

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHelperText,
    structure,
    setHasTriedToSubmit,
  } = useFormTable<ReportRowProps, GetStructureExtraProps>({
    extraProps: { reportTemplateYFilters },
    initialFormData,
    getStructure,
  });

  const initialFormInfoData = {
    name: data.name,
  };

  const {
    formData: formInfoData,
    helperText: helperTextInfoData,
    isFormValid: isFormInfoValid,
    setFormData: setFormInfoData,
    structure: formInfoStructure,
    setHasTriedToSubmit: setHasTriedToSubmitFormInfo,
  } = useFormContainer<ReportInfoProps>({
    initialFormData: initialFormInfoData,
    getStructure: getFormInfoStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ReportRowProps>({
      data: formData,
      item,
    }),
  );

  const initialSubmitData = {
    yFilterIds: initialFormData,
    ...initialFormInfoData,
  };

  const submitData = {
    yFilterIds: formData.map((item) => ({
      ...item,
      filterId: String(item.filterId) === "" ? null : item.filterId,
    })),
    name: formInfoData?.name,
  };

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createReport,
        update: updateReportTemplate,
        delete: deleteReportTemplate,
      },
      data: {
        create: submitData,
        update: submitData,
        delete: [String(data._id)],
      },
      dataId: String(data._id),
      name: "Report template",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setHasTriedToSubmitFormInfo(false);
      if (openModal && onCloseModal) onCloseModal();
    },
  });

  const commonPropsBuildItem = {
    data: formInfoData,
    helperText: helperTextInfoData,
  };

  const getForm = () => (
    <>
      <Box sx={{ padding: "var(--space-24px)" }}>
        {BuildItems({
          items: formInfoStructure.items,
          ...commonPropsBuildItem,
        })}
      </Box>
      <Divider />
      {formData.length === 0 ? (
        <Box sx={{ padding: "var(--space-24px)" }}>{t("No row exist ...")}</Box>
      ) : (
        <MuiDataTableWrapper
          nameSpace="main-table"
          tableType="compact"
          title=""
          data={formData}
          setData={setFormData}
          columns={columns}
          draggable
        />
      )}
      <Box sx={{ padding: "var(--space-12px)" }}>
        <IconButton
          disableRipple
          color="primary"
          onClick={() =>
            addRow({
              formData,
              helperText,
              setFormData,
              setHelperText,
            })
          }
        >
          <AddCircleIcon />
          <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
            {t("Add row")}
          </span>
        </IconButton>
      </Box>
    </>
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit({ action: onCloseModal ? "create" : "update" }).catch(
          (e) => console.error(e),
        );
      }}
      ref={refForm}
    >
      {onCloseModal ? (
        <ModalWrapper
          handleCloseModal={onCloseModal}
          openModal={openModal}
          label={"Create a report"}
        >
          <Box sx={{ width: "680px" }}>
            {getForm()}
            <SectionWithButtonContainer buttonCenter>
              <ButtonGroup>
                <ButtonCancel
                  id="create-report-cancel-button"
                  onClick={() => {
                    setFormInfoData(initialFormInfoData);
                    setFormData(initialFormData);
                    onCloseModal();
                  }}
                />
                <ButtonCreate
                  id="create-report-button"
                  initialSubmitData={{}}
                  isSubmitting={isSubmitting}
                  isValid={isFormValid}
                  onSubmit={() => {
                    refForm.current.requestSubmit();
                    setHasTriedToSubmit(true);
                  }}
                  submitData={submitData}
                />
              </ButtonGroup>
            </SectionWithButtonContainer>
          </Box>
        </ModalWrapper>
      ) : (
        <SectionTight
          label={data.name || "Untitled report"}
          subLabel="Edit report information and structure"
          containsTable
          isLoading={isLoading}
          rightBoxArea={
            <ButtonGroup>
              <IconButton disabled={disableMoveUp} onClick={onMoveUp}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton disabled={disableMoveDown} onClick={onMoveDown}>
                <ArrowDownwardIcon />
              </IconButton>
              <ButtonDeleteWithYesNoDialog
                isLoading={isDeleting}
                dialogContent={t(
                  `Are you sure you want to delete this report?`,
                )}
                onSubmit={() => {
                  onFormSubmit({ action: "delete" }).catch((err) =>
                    console.error(err),
                  );
                }}
              />
              <ButtonClear
                id={"update-report-template-filter-cancel"}
                onClick={() => {
                  setFormInfoData(initialFormInfoData);
                  setFormData(initialFormData);
                }}
              />
              <ButtonUpdate
                id={"update-report-template-filter"}
                initialSubmitData={initialSubmitData}
                isValid={isFormValid && isFormInfoValid && !isDeleting}
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                submitData={submitData}
              />
            </ButtonGroup>
          }
        >
          {getForm()}
        </SectionTight>
      )}
    </form>
  );
};

export default Report;
