/**
 *Created by Mikael Lindahl on 2024-10-22
 */

import {
  MUIDataTableCustomFilterListOptions,
  MUIDataTableFilterOptions,
} from "mui-datatables";
import { getCustomMultiselectFilterOptions } from "src/components/Complex/Containers/Filterhelper";
import { SelectOption } from "src/utils/getSelectOptions";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import getDisplayValueById from "src/utils/getDisplayValueById";

const getTableFilterOptionIds = ({
  dataLabel = "",
  isFullWidth,
  options,
}: {
  dataLabel: string;
  isFullWidth?: boolean;
  options?: SelectOption[];
}) => {
  const filterOptions: MUIDataTableFilterOptions =
    getCustomMultiselectFilterOptions({
      dataLabel,
      isFullWidth,
      options: options || [],
      renderValue: (v: string) =>
        getDisplayValueById({
          keyDisplayValue: "label",
          keyIdLookup: "value",
          idToMatch: v,
          items: options || [],
        }),
    });

  const customFilterListOptions: MUIDataTableCustomFilterListOptions = {
    render: (values: string[]) =>
      values.map((v: string) =>
        getDisplayValueById({
          keyDisplayValue: "label",
          keyIdLookup: "value",
          idToMatch: v,
          items: options || [],
        }),
      ),
  };

  return {
    filter: true,
    filterType: "custom" as StructureItemTable["filterType"],
    filterOptions,
    customFilterListOptions,
  };
};

export default getTableFilterOptionIds;
