import { backendApi } from "../api";
import { docMultipleListTag, docListTags } from "./utils";
import { Language } from "../../accurasee-backend-types/app/language/language.types";

const CACHE_TAG = "Languages";

export const languageApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getLanguages: build.query<{ data?: Language[]; metadata?: any }, any>({
      query: (params) => ({ url: "/language", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getLanguagesSuperAdmin: build.query<
      { data?: Language[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/superadmin/language", params }),
      providesTags: docMultipleListTag(CACHE_TAG, "Translation"),
    }),
    createLanguage: build.mutation<any, Language>({
      query: (data) => {
        return {
          url: "/superadmin/language",
          method: "POST",
          data,
        };
      },
      invalidatesTags: docMultipleListTag(CACHE_TAG, "Translation"),
    }),
    updateLanguages: build.mutation<any, Partial<Language>[]>({
      query: (data) => {
        return {
          url: "/superadmin/language/bulk",
          method: "PATCH",
          data: data,
        };
      },
      invalidatesTags: docMultipleListTag(CACHE_TAG, "Translation"),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateLanguageMutation,
  useGetLanguagesQuery,
  useGetLanguagesSuperAdminQuery,
  useUpdateLanguagesMutation,
} = languageApi;

export default languageApi;
