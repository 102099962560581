import getYear from "date-fns/getYear";
import { SupplierWithTotalsPurchased } from "src/accurasee-backend-types/app/supplier/supplier.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { escape } from "src/utils/translate";

const getStructure: UserFormTableGetStructure<
  SupplierWithTotalsPurchased
> = () => ({
  items: [
    {
      dataName: "number",
      headerLabel: "#",
      type: "text",
      sort: true,
    },
    {
      dataName: "name",
      headerLabel: "name",
      type: "text",
      sort: true,
    },
    {
      type: "text",
      dataName: "address1",
      headerLabel: "Address",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "city",
      headerLabel: "City",
      sx: { width: "100%" },
    },
    {
      type: "text",
      dataName: "zipCode",
      headerLabel: "Zipcode",
      sx: { width: "100%" },
    },
    {
      type: "text_currency",
      dataName: "totalPurchasesThisYear",
      headerLabel: `Purchases${escape(" " + String(getYear(new Date())))}`,
      sx: { width: "100%" },
      alignColumnContent: "end",
    },
  ],
});

export default getStructure;
