import { DIALOG_OPEN, DIALOG_CLOSE, DIALOG_RESET } from "../actions/types";

const initialState = {};

const dialogReducer = (state = initialState, action) => {
  const { type, id } = action;

  switch (type) {
    case DIALOG_OPEN:
      return { ...state, [id]: { ...state[id], open: true } };
    case DIALOG_CLOSE:
      return { ...state, [id]: { ...state[id], open: false } };
    case DIALOG_RESET:
      return { ...state, [id]: { ...state[id], open: false, success: false } };
    default:
      return state;
  }
};

export default dialogReducer;
