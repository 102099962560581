/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "../../../../hooks/useFormTable";
import { ProjectTrackerRegion } from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import getMonthAsString from "../../../../utils/getMonthAsString";

export const getStructure: UserFormTableGetStructureOptional<
  ProjectTrackerRegion
> = (props) => {
  return {
    items: [
      {
        type: "text",
        dataName: "name",
        headerLabel: props?.extraProps?.nameLabel,
      },
      {
        type: "text_currency",
        dataName: "month_minus_1",
        headerLabel: getMonthAsString(-2),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "month_minus_1",
        headerLabel: getMonthAsString(-1),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "year_to_date",
        headerLabel: "Year to date",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "active_projects",
        headerLabel: "Active projects",
        alignColumnContent: "end",
      },
    ],
  };
};
