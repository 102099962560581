import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import useFileReaders from "../../../../../hooks/useFileReaders";
import { FileStorageExtra } from "../../../../../components/Builders/Container/ItemTypes/FileItem";
import { validateTranslations } from "../../../../../validation/translation.validate";
import getSelectOptions from "../../../../../utils/getSelectOptions";
export type TranslationImport<FileData = any> = {
  importType?: "merge" | "replace";
  files?: FileStorageExtra[];
  fileData?: FileData;
  fileError?: string;
};

interface GetStructureExtraProps {
  setFileContent: (v: any) => void;
}

const useTranslationImportModalStructure = () => {
  const { loadCsv, loadJson, loadXlsx } = useFileReaders();

  const filesAllowed = {
    "application/json": "json",
    "text/csv": "csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  };

  const getStructure: UseFormContainerGetStructure<
    TranslationImport,
    GetStructureExtraProps
  > = ({ setFormData, extraProps }) => {
    const structure: StructureContainer = {
      items: [
        {
          // gridProps: { xs: 6 },
          type: "selector",
          dataName: "importType",
          label: "What type of import do you like to perform?",
          options: getSelectOptions({
            data: [
              {
                label: "Merges existing translations",
                value: "merge",
              },
              {
                label: "Replaces all current translations",
                value: "replace",
              },
            ],
          }),
          required: true,
          setFormData,
        },
        {
          type: "file",
          dataName: "files",
          label: "Attachment",
          filesAllowed: Object.keys(filesAllowed),
          required: true,
          fileReplace: true,
          validate: ({ data }) => {
            const { error } = validateTranslations(data.fileData);
            return !Boolean(error);
          },
          getErrorText: ({ data }) => {
            const { error } = validateTranslations(data.fileData);
            return error || "";
          },
          setFormDataCustom: (props) => {
            // file content handling
            if (props.value.length > 0) {
              const fileExtended = props.value[0] as FileStorageExtra;

              if (fileExtended.mimeType === undefined || !fileExtended.file) {
                return;
              }

              let newData = clone(props.data);
              newData[props.item.dataName as string] = props.value;

              switch (filesAllowed[fileExtended.mimeType]) {
                case "csv":
                  loadCsv(fileExtended.file)
                    .then(({ data, error }) => {
                      if (data) {
                        newData.fileData = data;
                      } else {
                        newData.fileError = error;
                      }

                      setFormData(newData);
                    })
                    .catch((e) => console.error(e));
                  break;
                case "json":
                  loadJson(fileExtended.file)
                    .then(({ data, error }) => {
                      if (data) {
                        newData.fileData = data;
                      } else {
                        newData.fileError = error;
                      }
                      setFormData(newData);
                    })
                    .catch((e) => console.error(e));
                  break;
                case "xlsx":
                  loadXlsx(fileExtended.file)
                    .then(({ data, error }) => {
                      if (data) {
                        newData.fileData = data;
                      } else {
                        newData.fileError = error;
                      }
                      setFormData(newData);
                    })
                    .catch((e) => console.error(e));
                  break;
              }
            }

            // Remove imported file
            let newData = clone(props.data);
            newData[props.item.dataName as string] = props.value;
            setFormData(newData);
          },
        },
      ],
    };

    return structure;
  };

  return { getStructure };
};

export default useTranslationImportModalStructure;
