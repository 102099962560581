import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Box, Grid } from "@mui/material";
import { EditRights } from "src/accurasee-backend-types/app/general/editrights.types";
import { ProjectSeries } from "src/accurasee-backend-types/app/company/company.types";
import { nanoid } from "nanoid";
import { useUpdateCompanyMutation } from "src/redux/services/CompanyService";
import {
  getStructure,
  GetStructureExtraProps,
} from "./SeriesModalGetStructure";
import { ExistingRange } from "./lib/checkForOverlap";

interface SeriesModalWithFormProps {
  handleCloseModal: () => void;
  labels: string[];
  openModal: boolean;
  permissions: EditRights["permissions"];
  projectNumberSeries: ProjectSeries[];
  row?: ProjectSeries;
}
const SeriesModalWithForm = ({
  handleCloseModal,
  labels,
  openModal,
  permissions,
  projectNumberSeries,
  row,
}: SeriesModalWithFormProps) => {
  const [updateCompany] = useUpdateCompanyMutation();

  const initialData = row || { label: "" };

  // Exclude its own range if it is edit form
  const series = row
    ? projectNumberSeries?.filter((item: ProjectSeries) => item.id !== row?.id)
    : projectNumberSeries;

  const existingRanges: ExistingRange[] = series?.map((el: ProjectSeries) => ({
    firstNumber: Number(el.firstNumber),
    lastNumber: Number(el.lastNumber),
    prefix: el.prefix,
  }));

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<ProjectSeries>, GetStructureExtraProps>({
    getStructure,
    extraProps: {
      existingRanges,
      labels,
    },
    initialFormData: initialData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateCompany,
      },
      data: {
        update: {
          projectNumberSeries: !row
            ? // for Add form
              [
                ...projectNumberSeries,
                {
                  ...formData,
                  firstNumber: formData?.firstNumber?.toString(),
                  lastNumber: formData?.lastNumber?.toString(),
                  id: nanoid(),
                },
              ]
            : // for Edit form
              projectNumberSeries.map((item) => {
                if (item.id === row?.id) {
                  return {
                    ...formData,
                    firstNumber: formData?.firstNumber?.toString(),
                    lastNumber: formData?.lastNumber?.toString(),
                  };
                }
                return item;
              }),
        },
      },
      name: "Project series",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setFormData(initialData);
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    id: "project-series",
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={!row ? "Add series" : "Edit series"}
      alignTextCenter={false}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            onFormSubmit();
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
          </Grid>
          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonCancel
                onClick={() => {
                  setHasTriedToSubmit(false);
                  setFormData(initialData);
                  handleCloseModal();
                }}
              />
              <ButtonCreateOrUpdate
                initialSubmitData={initialData}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                label={!row ? "create" : "update"}
                submitData={formData}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={permissions}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default SeriesModalWithForm;
