/**
 *Created by Mikael Lindahl on 2023-04-17
 */
import { PlannedInvoiceRow } from "../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

export const isEstimateRows = (invoiceRows?: PlannedInvoiceRow[]) => {
  return getEstimateRows(invoiceRows)?.length !== 0;
};

export const getEstimateRows = (invoiceRows?: PlannedInvoiceRow[]) => {
  return (
    invoiceRows?.filter(
      (r) => r.isQuantityEstimate || r.isPricePerUnitEstimate,
    ) || []
  );
};
