import get from "../Utils/get";
import getSx, { getSubSx } from "../Utils/getSx";
import getValue from "../Utils/getValue";
import { Box, Typography } from "@mui/material";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";

type TextWithLabelColumnItemTProps = CommonBuilderTableTypes;

const TextWithLabelColumnItem = (props: TextWithLabelColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const value = getValue(commonCallbackProps);
  const subLabel = get("subLabel", commonCallbackProps);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${
          props.item?.subLabelPosition === "top" ? "column" : "column-reverse"
        }`,
      }}
    >
      {subLabel && (
        <Typography sx={getSubSx(commonCallbackProps)}>{subLabel}</Typography>
      )}
      <Typography sx={getSx(commonCallbackProps)}>{value}</Typography>
    </Box>
  );
};

export default TextWithLabelColumnItem;
