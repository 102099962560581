/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import {
  BuilderContainerCallbackProps,
  StructureItemContainer,
} from "../CommonBuilderContainerTypes";

const get = (
  key: keyof StructureItemContainer,
  props: BuilderContainerCallbackProps,
) => {
  return (
    (typeof props.item[key] === "function"
      ? props.item[key](props)
      : props.item[key]) || undefined
  );
};

export default get;
