import { getStructure } from "./ProjectTrackerPercentageOfCompletionGetStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ProjectTrackerPercentageOfCompletionSub } from "src/accurasee-backend-types/app/project_tracker/project_tracker.types";
import { userMUIDatSubTableOptions } from "src/hooks/useMUIDataTableOptions";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

interface ProjectTrackerPercentageOfCompletionSubTableProps {
  data: ProjectTrackerPercentageOfCompletionSub[];
}

export function ProjectTrackerPercentageOfCompletionSubTable({
  data,
}: ProjectTrackerPercentageOfCompletionSubTableProps) {
  const structure = getStructure();

  const ProjectTrackerPercentageOfCompletionSubTableColumns: MUIDataTableColumnDef[] =
    structure.items
      .filter((c) => ["main-and-sub", "sub"].includes(String(c.itemType)))
      .map((item) =>
        BuilderColumnOptionsSingle<ProjectTrackerPercentageOfCompletionSub>({
          data,
          disabled: true,
          item,
        }),
      );

  const options = userMUIDatSubTableOptions();

  return (
    <SubTableContainer sx={{ padding: "10px 10px 10px 52px" }}>
      <MuiDataTableWrapper
        nameSpace={"sub-table"}
        tableType="main-nested-level-1"
        title={""}
        data={data}
        columns={ProjectTrackerPercentageOfCompletionSubTableColumns}
        options={options}
      />
    </SubTableContainer>
  );
}

export default ProjectTrackerPercentageOfCompletionSubTable;
