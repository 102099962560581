import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import {
  BillingBaseReturn,
  PlannedInvoice,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { toDate } from "src/utils/date";

interface GetStructureExtraProps {
  articles: Article[];
  contracts: Contract[];
  plannedInvoice: PlannedInvoice;
}

const getStructure: UserFormTableGetStructure<
  BillingBaseReturn,
  GetStructureExtraProps
> = ({ extraProps }) => {
  return {
    items: [
      {
        dataName: "itemDescription",
        headerLabel: "Name",
        type: "text",
      },
      {
        dataName: "timePeriod",
        headerLabel: "Time period",
        type: "text",
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          return `${toDate(invoiceData?.timePeriod?.from)} - ${toDate(
            invoiceData?.timePeriod?.to,
          )}`;
        },
      },
      {
        dataName: "articleId",
        headerLabel: "Article",
        type: "text",
        getValue: ({ data, rowIndex }) =>
          extraProps?.articles?.find(
            (article) => article._id === data[rowIndex].articleId,
          )?.name,
      },
      {
        dataName: "quantity",
        headerLabel: "Quantity",
        type: "text",
        getValue: ({ data, rowIndex }) => {
          const invoiceData = data[rowIndex];
          return invoiceData.quantity.toString() + " " + invoiceData.unit;
        },
      },
      {
        dataName: "pricePerUnit",
        headerLabel: "Price per unit",
        type: "text_currency",
        currencyCode: extraProps?.plannedInvoice?.currencyCode,
      },
    ],
  };
};
export default getStructure;
