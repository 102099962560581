/**
 *Created by Mikael Lindahl on 2023-03-15
 */

import { Box, SxProps } from "@mui/material";
import React from "react";

type CardButtonContainerTProps = {
  buttonCenter?: boolean;
  buttonLeft?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
};

const SectionWithButtonContainer = ({
  buttonCenter,
  buttonLeft,
  children,
  sx,
}: CardButtonContainerTProps) => {
  const childrenArray = React.Children.toArray(children).filter(
    (child) => !!child,
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: buttonCenter
          ? "center"
          : childrenArray?.length > 1
            ? "space-between"
            : buttonLeft
              ? "start"
              : "end",
        columnGap: "var(--space-8px)",
        marginTop: "1rem",
        ...sx,
      }}
    >
      {childrenArray}
    </Box>
  );
};

export default SectionWithButtonContainer;
