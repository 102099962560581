import {
  ActivityLog,
  ActivityLogListReturn,
  CreateActivityLog,
} from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { backendApi } from "../api";
import { docListTags, listTag } from "./utils";
const CACHE_TYPE = "ActivityLogs";

const activityLogsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    createActivityLog: build.mutation<any, CreateActivityLog>({
      query: (data) => ({ url: "/activity-logs", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    getActivityLogs: build.query<
      { data: ActivityLogListReturn[]; metadata: any },
      Partial<Pick<ActivityLog, "groupId" | "type">>
    >({
      query: (params) => ({ url: "/activity-logs", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateActivityLogMutation, useGetActivityLogsQuery } =
  activityLogsApi;

export default activityLogsApi;
