/**
 *Created by Mikael Lindahl on 2024-10-03
 */

import { Types } from "mongoose";
import { getNestedObjectData, setNestedObjectData } from "./nestedData";

function getSummaries<T = any>({
  addFirstOf,
  data,
  keys,
  sumTextKey, // key for column to show "Sum" - without getValue()
}: {
  addFirstOf?: string[];
  data: Partial<T>[];
  keys: { name: string; callback?: (v: Partial<T>) => any }[];
  sumTextKey?: string;
}) {
  const summaries: any = {
    _id: new Types.ObjectId("66fe78bd3e26c3800ab7e658"),
  };

  keys.forEach(({ name, callback }) => {
    const value = data.reduce(
      (sum, obj) =>
        sum +
        (callback
          ? callback(obj)
          : getNestedObjectData({ data: obj, key: name }) || 0),
      0,
    );
    setNestedObjectData({ data: summaries, key: name, value });
  });

  if (sumTextKey) {
    setNestedObjectData({ data: summaries, key: sumTextKey, value: "Sum" });
  }
  if (addFirstOf) {
    addFirstOf.forEach((key) => {
      const value = getNestedObjectData({ data: data[0], key });
      setNestedObjectData({ data: summaries, key, value });
    });
  }

  const dataWithSummaries =
    data.length === 0 ? [] : [...data, summaries as unknown as Partial<T>];

  return {
    dataWithSummaries,
    summaries: summaries as Partial<T>,
    idSummaries: summaries._id,
  };
}

export default getSummaries;
