import { Box, Divider, Typography } from "@mui/material";
import classnames from "classnames";
import useStyles from "./WidgetWithContentStyles";

type WidgetWithContentProps = {
  title?: string;
  mainNumber: string;
  mainNumberDesc: string;
  subNumber?: string;
  subNumberDesc?: string;
  mode?: "dark" | "light";
};

const WidgetWithContent = ({
  mainNumber,
  mainNumberDesc,
  subNumber,
  subNumberDesc,
  title,
  mode = "light",
}: WidgetWithContentProps) => {
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.widgetWrapper, {
        [classes.darkBackground]: mode === "dark",
        [classes.lightBackground]: mode === "light",
      })}
    >
      <Typography
        className={classnames(classes.title, {
          [classes.darkText]: mode === "dark",
          [classes.lightText]: mode === "light",
        })}
      >
        {title}
      </Typography>
      <Typography
        className={classnames(classes.mainNumber, {
          [classes.darkText]: mode === "dark",
          [classes.lightMainText]: mode === "light",
        })}
      >
        {mainNumber}
      </Typography>
      <Typography
        className={classnames(classes.numberDesc, {
          [classes.darkText]: mode === "dark",
          [classes.lightText]: mode === "light",
        })}
      >
        {mainNumberDesc}
      </Typography>

      {subNumber && (
        <>
          <Divider sx={{ paddingTop: "var(--space-8px)" }} />
          <Box
            className={classnames(classes.subNumberWrapper, {
              [classes.darkText]: mode === "dark",
              [classes.lightText]: mode === "light",
            })}
          >
            <Typography className={classes.numberDesc}>
              {subNumberDesc}
            </Typography>
            <Typography className={classes.subNumber}>{subNumber}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WidgetWithContent;
