/**
 *Created by Mikael Lindahl on 2024-10-10
 */

import { useCreateSubContractOfferMutation } from "../redux/services/ContractService";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const useCreateSubContractOfferFromContract = ({
  contractId,
}: {
  contractId?: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [createSubContractOffer] = useCreateSubContractOfferMutation();

  const createSubContractOfferFromContract = async () => {
    if (!contractId) {
      enqueueSnackbar("No contract id", { variant: "error" });
      return;
    }

    try {
      const result = await createSubContractOffer(contractId);

      if ("error" in result) {
        throw new Error("Error creating sub contract offer");
      }

      console.log(result);

      enqueueSnackbar("Sub contract offer created", { variant: "success" });

      navigate(`/app/contract-offers/overview/${result?.data?.data._id}`);
    } catch (error) {
      console.error(error);

      enqueueSnackbar("Error creating sub contract offer", {
        variant: "error",
      });
    }
  };

  return createSubContractOfferFromContract;
};

export default useCreateSubContractOfferFromContract;
