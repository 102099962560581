/**
 *Created by Mikael Lindahl on 2023-01-09
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    datePickerIcon: {
      "& button": {
        color: theme.palette.primary.main,
      },
    },
    dropzoneBox: {
      "&.MuiBox-root": {
        width: "100%",
      },
      "& .MuiDropzoneArea-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80px",
        width: "100%",
        minHeight: "unset",
        backgroundColor: theme.palette.background.light,
      },
      "& .MuiTypography-subtitle1": {
        display: "none",
      },
      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& p": {
        fontSize: "14px",
      },
      "& svg": {
        height: "24px",
        weight: "24px",
      },
    },
    attachmentsBox: {
      "&.MuiBox-root": { display: "flex", flexWrap: "wrap", width: "auto" },
      maxWidth: "100%",
      [theme.breakpoints.up("xl")]: {
        width: "50%",
        display: "inline-block",
      },
      "& > div": {
        margin: "0 8px 8px 0",
      },
    },
    fixLabelRadioGroup: {
      "& .MuiRadio-root": {
        alignSelf: "start",
      },
      "& .MuiFormControlLabel-label": {
        alignSelf: "start",
      },
    },
    labelFieldStyle: {
      transform: "none",
      position: "relative",
      fontSize: "12px",
      fontWeight: 600,
      display: "block",
      marginBottom: "6px",
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    previewGridContainer: {
      marginTop: "0.5rem",
      marginLeft: "0.25rem",
    },
  }),
);
export default useStyles;
