import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    endHeader: {
      "& span": {
        justifyContent: "flex-end",
      },
      "& button": {
        margin: 0,
        padding: 0,
      },
    },
    centerHeader: {
      "& button": {
        margin: 0,
      },
    },
    startHeader: {
      "& span": {
        justifyContent: "flex-start",
      },
    },
  }),
);

export default useStyles;
