/**
 *Created by Mikael Lindahl on 2023-03-15
 */

import Box from "@mui/material/Box";
import React from "react";

type ButtonGroupTProps = {
  children?: React.ReactNode;
};
const ButtonGroup = ({ children }: ButtonGroupTProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        columnGap: "var(--space-8px)",
      }}
    >
      {children}
    </Box>
  );
};

export default ButtonGroup;
