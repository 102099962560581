/**
 *Created by Mikael Lindahl on 2024-04-11
 */

import BugReportIcon from "@mui/icons-material/BugReport";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import React, { useState } from "react";
import getFormattedDate from "src/utils/getFormattedDate";
import useTranslation from "src/hooks/useTranslationWrapper";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { IntegrationImportJobError } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";

export type NotificationWithStackProps = {
  color: string;
  error: IntegrationImportJobError;
  title?: string;
};

const ErrorBaseWithStack: React.FC<NotificationWithStackProps> = ({
  color,
  error,
  title,
}) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" flexDirection="row" columnGap="var(--space-4px)">
      <Typography sx={{ fontSize: "14px" }} marginTop={1} color={color}>
        {error.message}
      </Typography>
      <IconButton onClick={handleClickOpen}>
        <BugReportIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <Box paddingY={2} paddingX={3}>
          <DialogTitle id="alert-dialog-title">
            <Typography sx={{ fontWeight: 600 }}>
              {t(title || "Details")}
            </Typography>
            {error.timestamp && (
              <Typography variant="subtitle2" sx={{ color: "var(--gray-4)" }}>
                {`${t("On:")} ${getFormattedDate({
                  t,
                  date: error.timestamp,
                })}`}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            {error.details && (
              <DialogContentText
                marginBottom={2}
                color={"dark"}
                id="alert-dialog-description"
              >
                {error.details || t("No stack trace available")}
              </DialogContentText>
            )}
            <DialogContentText color={"dark"} id="alert-dialog-description">
              {error.stack || t("No stack trace available")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" width="100%">
              <ButtonPrimary onClick={handleClose} autoFocus>
                Close
              </ButtonPrimary>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ErrorBaseWithStack;
