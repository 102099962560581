import { backendApi } from "../api";
import { docGetTag, docPatchTag, listTag } from "./utils";
import {
  Article,
  ArticleWithPermissions,
  ArticleWithTotals,
} from "../../accurasee-backend-types/app/article/article.types";

const CACHE_TAG = "Articles";
export const articleApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<
      { data?: ArticleWithPermissions[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/articles", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getArticlesTable: build.query<
      { data?: ArticleWithTotals[]; metadata?: any },
      any
    >({
      query: (params) => ({ url: "/articles/table", params }),
      providesTags: listTag(CACHE_TAG),
    }),
    getArticle: build.query<
      { data: ArticleWithPermissions },
      string | undefined
    >({
      query: (id) => ({ url: `/articles/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
    createArticle: build.mutation<any, Partial<Article>>({
      query: (data) => ({ url: "/articles", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    patchArticle: build.mutation<Article, Partial<Article> & { id: string }>({
      query: ({ id, ...data }) => ({
        url: `/articles/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
});

export const {
  useCreateArticleMutation,
  useGetArticleQuery,
  useGetArticlesQuery,
  useGetArticlesTableQuery,
  usePatchArticleMutation,
} = articleApi;

export default articleApi;
