/**
 *Created by Mikael Lindahl on 2023-11-06
 */

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tabsBox: {
    borderBottom: "none",
    backgroundColor: "var(--white-primary)",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",

    "& .MuiButtonBase": {
      padding: "5px",
    },
    "& .MuiTabs-root .MuiTabs-scrollButtons": {
      transition: "width 0.5s ease",
      width: "40px",
    },
    "& .MuiTabs-root .MuiTabs-scrollButtons.Mui-disabled": {
      width: 0,
    },
    // This is for unselected tab color
    "& .MuiTab-root": {
      color: "var(--black-5)",
      textTransform: "none",
      padding: "0px 32px",
      fontSize: "16px",
    },
    "& .Mui-selected": {
      backgroundColor: "var(--green-primary)",
      color: "var(--white-primary)",
      borderRadius: "3px",
    },
  },
  tabsTransparentBox: {
    display: "flex",
    justifyContent: "space-between",

    "& .MuiButtonBase": {
      padding: "5px",
    },
    "& .MuiTabs-root .MuiTabs-scrollButtons:first-of-type": {
      display: "none",
    },
    // This is for unselected tab color
    "& .MuiTab-root": {
      color: "var(--black-5)",
      textTransform: "none",
      padding: "0 var(--space-16px)",
      transition: "all 0.2s",
      borderRadius: "4px 4px 0 0",
      "&:hover": { backgroundColor: "var(--gray-2)" },
    },
    "& .Mui-selected": {
      color: "var(--green-primary)",
    },
  },
}));
