import useTranslation from "src/hooks/useTranslationWrapper";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BuildItems from "src/components/Builders/Container/BuildItems";
import getStructure from "./AddRowModalStructure";
import useFormContainer from "src/hooks/useFormContainer";
import {
  Namespaces,
  Translation,
} from "src/accurasee-backend-types/app/translation/translation.types";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import { useRef } from "react";

interface AddRowModalProps {
  handleCloseModal: (
    action: "add" | "cancel",
    data?: { key: string; namespace: Namespaces },
  ) => void;
  translations: Translation[];
  openModal: boolean;
}

export default function AddRowModalWithForm(props: AddRowModalProps) {
  const [t] = useTranslation();

  const initialFormData = { key: "", namespace: "common" as Namespaces };
  const {
    formData,
    helperText,
    isFormValid,
    setHasTriedToSubmit,
    setFormData,
    structure,
  } = useFormContainer({
    extraProps: {
      isUniquePair: (data) => {
        return !Boolean(
          props.translations.find(
            (t) => t.key === data.key && t.namespace === data.namespace,
          ),
        );
      },
    },
    initialFormData,
    getStructure,
  });

  const refForm = useRef<any>();

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={() => props.handleCloseModal("cancel")}
      openModal={props.openModal}
      label={"Add row"}
      subLabel={"Please provide a unique key namespace pair"}
    >
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          props.handleCloseModal("add", formData);
        }}
      >
        <Section>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
        <SectionWithButtonContainer buttonCenter={true}>
          <Button
            onClick={() => {
              setFormData(initialFormData);
              props.handleCloseModal("cancel");
            }}
            variant="outlined"
            sx={{ minWidth: "112px" }}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={
              !isFormValid ||
              (formData?.key === "" &&
                (formData?.namespace as Readonly<string>) === "")
            }
            // type="submit"
            style={{ marginLeft: 16, minWidth: "112px" }}
            onClick={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
          >
            {t("Add")}
          </LoadingButton>
        </SectionWithButtonContainer>
      </form>
    </ModalWrapper>
  );
}
