/**
 *Created by Mikael Lindahl on 2024-02-19
 */

import { HiddenComponent } from "../accurasee-backend-types/app/company_config/company_config.types";

const getHiddenComponentStatus = (hiddenComponent?: HiddenComponent) => {
  return hiddenComponent?.status === undefined ? true : hiddenComponent?.status;
};

export default getHiddenComponentStatus;
