import _c from "src/constants/Constants";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import getStructure, {
  GetStructureExtraProps,
  toSubmitData,
} from "./WarningModalWithFormStructure";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import {
  useCreateWarningSuperAdminMutation,
  useUpdateWarningSuperAdminMutation,
} from "src/redux/services/WarningService";
import { Grid } from "@mui/material";
import { useGetCompanySuperAdminQuery } from "src/redux/services/CompanyService";
import { Warning } from "src/accurasee-backend-types/app/warning/warning.types";

interface WarningModalWithFormProps {
  warning?: Warning;
  openModal: boolean;
  handleCloseModal: () => void;
}

const WarningModalWithForm = ({
  warning,
  openModal,
  handleCloseModal,
}: WarningModalWithFormProps) => {
  const { data: companiesQuery, isLoading: isLoadingCompanies } =
    useGetCompanySuperAdminQuery(undefined);
  const companies = companiesQuery?.data || [];

  const [createWarningSuperAdmin] = useCreateWarningSuperAdminMutation();
  const [updateWarningSuperAdmin] = useUpdateWarningSuperAdminMutation();

  const initialFormData = warning ? warning : _c.INITIAL_WARNING_DATA;

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<Warning>, GetStructureExtraProps>({
    extraProps: { isEditForm: !!warning, companies },
    getStructure,
    initialFormData,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit<
    Partial<Warning>
  >({
    submitProps: {
      apiMutations: {
        create: createWarningSuperAdmin,
        update: updateWarningSuperAdmin,
      },
      data: {
        create: submitData,
        update: submitData,
      },
      name: "Warning",
      dataId: String(warning?._id) || "",
    },
    onSuccess: () => {
      // clear form data
      setFormData(initialFormData);
      setHasTriedToSubmit(false);
      // close Add modal
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={warning?._id ? "EDIT WARNING" : "CREATE A WARNING"}
      boxStyle={{ width: "640px" }}
    >
      <SectionContainer isLoading={isLoadingCompanies}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onFormSubmit({
              action: warning?._id ? "update" : "create",
            });
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            {BuildItems({
              items: structure.items,
              ...commonPropsBuildItem,
            })}
          </Grid>
        </form>
      </SectionContainer>
      <SectionWithButtonContainer>
        <ButtonGroup>
          <ButtonCancel
            onClick={() => {
              setFormData(initialFormData);
              handleCloseModal();
            }}
          />
          <ButtonSubmitCore
            initialSubmitData={{}}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            label={warning?._id ? "save" : "create"}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={submitData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};

export default WarningModalWithForm;
