/**
 *Created by Mikael Lindahl on 2023-10-18
 */

import { AccessPermissionContext } from "src/context/AccessPermissionProvider";
import { Box, Tab } from "@mui/material";
import { getPermission } from "src/utils/getPermission";
import { Link, useLocation, Route, Routes } from "react-router-dom";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { useContext } from "react";
import NotFoundBase from "src/components/Screens/NotFound/NotFoundBase";
import RouteElementWrapper from "../../Simple/Routes/RouteElementWrapper";
import sort from "src/utils/sort";
import TabContainer from "./TabContainer";
import TabList from "@mui/lab/TabList";
import useRouteMatch from "src/hooks/useRouteMatch";
import useStyles from "./TabsWithLinkStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import Unauthorized from "src/screens/Unauthorized/Unauthorized";

type TabRoute = {
  component: JSX.Element;
  exact?: boolean;
  path: RoutePath;
  isHidden?: boolean;
};

type TabRouteMain = TabRoute & { otherEligiblePaths?: RoutePath[] };

export type TabsType = {
  name: string;
  mainRoute: TabRouteMain;
  children?: TabRoute[];
  subRoutes?: TabRoute[];
};

export type TabsTProps = {
  basePath: string;
  baseUrl: string;
  getMainRouteUrl: (path: RoutePath) => string;
  isSubTabs?: boolean;
  name: string;
  pathToStrip: string;
  redirectPath: RoutePath;
  tabs: TabsType[];
  navigationButtons?: JSX.Element;
  noBackground?: boolean;
  noIndicator?: boolean; // set when list of tabs is changed
  top?: string | number; // to fixed with top
};

const TabsWithLink = (props: TabsTProps) => {
  const [t] = useTranslation();
  const { accessEndpointMap } = useContext(AccessPermissionContext);
  const componentName = `tabs-${props.name.split(" ").join("-")}`;
  const classes = useStyles();

  const tabs = props.tabs.filter((tab) =>
    getPermission({ accessEndpointMap, path: tab.mainRoute.path }),
  );

  let pathToTabIndex: Partial<{ [K in RoutePath]: string }> = {};
  let paths: RoutePath[] = [];
  tabs.reduce((pathToTabIndex, tab, index) => {
    pathToTabIndex[tab.mainRoute.path] = index.toString();
    paths.push(tab.mainRoute.path);
    if (tab.children) {
      tab.children.forEach((child) => {
        pathToTabIndex[child.path] = index.toString();
        paths.push(child.path);
      });
    }
    if (tab.subRoutes) {
      tab.subRoutes.forEach((subTab) => {
        pathToTabIndex[subTab.path] = index.toString();
        paths.push(subTab.path);
      });
    }
    return pathToTabIndex;
  }, pathToTabIndex);
  paths = sort(
    paths.map((p) => ({ p, n: p.split("/").length })),
    (v) => v.n,
  )
    .reverse()
    .map((v) => v.p);
  const routeMatch = useRouteMatch(paths);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <>
      {props?.top ? <Box sx={{ height: "48px" }} /> : <></>}
      <TabContainer
        value={pathToTabIndex[routeMatch?.path || "0"]} // If no query param, default to first tab
        sx={{ marginTop: "1rem" }}
      >
        {[
          <Box
            key={"box"}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              ...(props?.top
                ? {
                    position: "fixed",
                    zIndex: 1001,
                    top: props.top,
                    width: "calc(100% - 240px - 48px)",
                  }
                : {}),
            }}
            className={
              props?.noBackground ? classes.tabsTransparentBox : classes.tabsBox
            }
          >
            <TabList
              key={"tab-list"}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              id="contract-side-bar"
              sx={{
                ...(props?.noIndicator && {
                  "& .MuiTabs-indicator": { display: "none" },
                }),
              }}
            >
              {tabs.map(({ name, mainRoute: { path, isHidden } }, index) => {
                if (isHidden)
                  return <div key={`${componentName}-tab-${index + 1}`}></div>;

                query.set(componentName, String(index + 1));
                return (
                  <Tab
                    key={`${componentName}-tab-${index + 1}`}
                    id={`${componentName}-tab-${index + 1}`}
                    aria-controls={`${componentName}-tab-${index + 1}`}
                    label={t(name)}
                    to={props.getMainRouteUrl(path)}
                    value={index.toString()}
                    component={Link}
                  />
                );
              })}
            </TabList>
            {props?.navigationButtons && props.navigationButtons}
          </Box>,
          <Routes key={"switch"}>
            {tabs.reduce(
              (
                tot,
                {
                  mainRoute: { component, exact, path, isHidden },
                  children,
                  subRoutes,
                },
                indexMain,
              ) => {
                const relativePath = path.replace(props.pathToStrip, "");

                const permission = getPermission({
                  accessEndpointMap,
                  path,
                });

                const canRead = permission.readRights;

                tot = [
                  ...tot,
                  <Route
                    key={indexMain + "tab"}
                    path={
                      exact !== undefined || exact
                        ? relativePath + "/*"
                        : relativePath
                    }
                    element={
                      <RouteElementWrapper
                        element={canRead ? component : <Unauthorized />}
                        isRedirect={!permission}
                        redirectPath={props.redirectPath}
                      />
                    }
                  >
                    {/* children config helps to render child route element */}
                    {children
                      ? children.map((child, index) => {
                          const permission = getPermission({
                            accessEndpointMap,
                            path: child.path,
                          });

                          const canRead = permission.readRights;

                          return (
                            <Route
                              key={`${indexMain}${index}-child-route`}
                              path={child.path.replace(props.pathToStrip, "")}
                              element={
                                canRead ? child.component : <Unauthorized />
                              }
                            />
                          );
                        })
                      : null}
                  </Route>,
                  ...(subRoutes
                    ? subRoutes.map(({ component, exact, path }, indexSub) => {
                        const relativePath = path.replace(
                          props.pathToStrip,
                          "",
                        );

                        const permission = getPermission({
                          accessEndpointMap,
                          path,
                        });

                        const canRead = permission.readRights;

                        return (
                          <Route
                            key={`${indexMain}${indexSub} tab`}
                            path={
                              exact !== undefined || exact
                                ? relativePath + "/*"
                                : relativePath
                            }
                            element={
                              <RouteElementWrapper
                                element={canRead ? component : <Unauthorized />}
                                isRedirect={!permission}
                                redirectPath={props.redirectPath}
                              />
                            }
                          />
                        );
                      })
                    : []),
                ];
                return tot;
              },
              [] as JSX.Element[],
            )}
            {!props?.isSubTabs && (
              <Route
                path="/*"
                element={
                  <NotFoundBase
                    redirect={props.baseUrl}
                    redirectLabel={`Go to ${props.name} page`}
                  />
                }
              />
            )}
          </Routes>,
        ]}
      </TabContainer>
    </>
  );
};

export default TabsWithLink;
