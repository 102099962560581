import { MUIDataTableOptions } from "mui-datatables";
import { useContext, useEffect } from "react";
import { UserStateContext } from "src/context/UserStateProvider";

const usePersistRowsPerPage = ({
  id,
  onChangeRowsPerPage: _onChangeRowsPerPage,
}: {
  id: string;
  onChangeRowsPerPage?: MUIDataTableOptions["onChangeRowsPerPage"];
}) => {
  const { getUserStateTable, setUserStateTable } = useContext(UserStateContext);

  const userStateByTableId = getUserStateTable(id);
  useEffect(() => {
    const rowsPerPage = userStateByTableId?.rowsPerPage;
    if (
      userStateByTableId &&
      rowsPerPage !== undefined &&
      _onChangeRowsPerPage
    ) {
      _onChangeRowsPerPage(rowsPerPage);
    }
  }, []);

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    if (rowsPerPage) {
      const userStateByTableId = getUserStateTable(id);

      if (_onChangeRowsPerPage) {
        _onChangeRowsPerPage(rowsPerPage);
      }

      setUserStateTable({
        id,
        data: { ...userStateByTableId, rowsPerPage },
      });
    }
  };

  return { onChangeRowsPerPage };
};

export default usePersistRowsPerPage;
