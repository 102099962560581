import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "../../Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "../../Basic/Simple/Buttons/ButtonSecondary";
import ModalWrapper from "../../Basic/Mixed/Modals/ModalWrapper";
import Section from "../../Basic/Simple/Sections/Section";
import SectionWithButtons from "../../Basic/Mixed/Sections/SectionWithButtons";
import _c from "src/constants/Constants";
import cleanAndUnflattenData from "src/utils/cleanAndUnflattenData";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Offset } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { getStructure, GetStructureExtraProps } from "./OffsetModalStructure";

interface OffsetModalTProps {
  openModal: boolean;
  data?: Offset;
  handleCloseModal: () => void;
  onChange: ({ data }: { data: Offset }) => void;
}
const OffsetModal = (props: OffsetModalTProps) => {
  const [t] = useTranslation();

  const data: Partial<Offset> = {
    format: _c.OFFSET_OPTIONS[0],
    ...props.data,
  };

  const { formData, helperText, structure, setFormData } = useFormContainer<
    Partial<Offset>,
    GetStructureExtraProps
  >({
    initialFormData: data,
    getStructure,
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const onClose = () => {
    setFormData(data);
    props.handleCloseModal();
  };

  return (
    <ModalWrapper
      handleCloseModal={onClose}
      openModal={props.openModal}
      label={"Edit offset"}
    >
      <Section>
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </Section>
      <SectionWithButtons alignCenter>
        <ButtonGroup>
          <ButtonCancel onClick={onClose} />
          <ButtonSecondary
            onClick={() => {
              setFormData({ unit: "month", number: 0, ...data });
            }}
          >
            {t("Add defaults")}
          </ButtonSecondary>
          <ButtonPrimary
            onClick={() => {
              props.onChange({
                data: cleanAndUnflattenData(formData) as Offset,
              });
              setFormData(data);
              props.handleCloseModal();
            }}
          >
            {t("Save")}
          </ButtonPrimary>
        </ButtonGroup>
      </SectionWithButtons>
    </ModalWrapper>
  );
};
export default OffsetModal;
