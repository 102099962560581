import useFormContainer from "src/hooks/useFormContainer";
import getStructure from "./ConfigurationsCompanyFilterStructure";
import BuildItems from "src/components/Builders/Container/BuildItems";
import { useEffect } from "react";
import { Company } from "src/accurasee-backend-types/app/company/company.types";

type ConfigurationsCompanyFilterProps = {
  companies: Company[];
  selectedCopmpany?: Company;
  setFilterData: (v: Company) => void;
};

const ConfigurationsCompanyFilter = (
  props: ConfigurationsCompanyFilterProps,
) => {
  const { formData, helperText, structure } = useFormContainer<Company>({
    getStructure,
    extraProps: { companies: props.companies },
    initialFormData: props.selectedCopmpany,
  });

  useEffect(() => {
    if (
      formData !== undefined &&
      formData._id !== props?.selectedCopmpany?._id
    ) {
      props.setFilterData(formData);
    }
  }, [formData]);

  const commonPropsBuildItem: any = { data: formData, helperText };

  return BuildItems({
    items: structure.items,
    ...commonPropsBuildItem,
  });
};

export default ConfigurationsCompanyFilter;
