const parseInteger = (event) => {
  const { value } = event.target;
  if (!value) {
    return value;
  } else {
    try {
      const parseValue = parseInt(value || 0);
      return parseValue;
    } catch (err) {
      console.error(err);
      return value;
    }
  }
};

export default parseInteger;
