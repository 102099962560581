/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getCellLabel = <DataType = any>(props: CommonCallbackProps<DataType>) => {
  return (
    (typeof props.item.cellLabel === "function"
      ? props.item.cellLabel(props)
      : props.item.cellLabel) || ""
  );
};

export default getCellLabel;
