/**
 *Created by Mikael Lindahl on 2023-04-25
 */

import _c from "src/constants/Constants";
import getStackTrace from "../utils/getStackTrace";
import { RootState } from "src/redux/store";
import { TOptions } from "i18next";
import { buffer, debounceTime } from "rxjs/operators";
import { distinct, Observable, Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation, UseTranslationOptions } from "react-i18next";
import {
  decodeStringWithEscape,
  escapeDotsAndDollars,
} from "../utils/translate";
import {
  MissingTranslationsState,
  updateMissingTranslations,
  deleteMissingTranslations,
} from "../redux/slices/missingTranslations";
import {
  Namespaces,
  namespaces as translationNamespaces,
  Translation,
} from "src/accurasee-backend-types/app/translation/translation.types";

type ObservableEvent = { action: "delete" | "update"; entry: Translation };

interface BufferedObservable {
  observable: Observable<ObservableEvent[]>;
  next: (value: ObservableEvent) => void;
}

// Define a custom TFunction type that extends the original TFunction
export type TFunction = (
  key: undefined | string | string[],
  options?: TOptions,
) => string;

function createBufferedObservable(): BufferedObservable {
  // Create a Subject to emit values
  const subject = new Subject<ObservableEvent>();

  // Use the distinct operator with a custom key selector function
  const uniqueObservable = subject.pipe(
    distinct(
      (value: ObservableEvent) => value.entry.key + "." + value.entry.namespace,
    ),
  );

  // Use the buffer and debounceTime operators to collect values within a 1-second time window
  const bufferedObservable = uniqueObservable.pipe(
    buffer(subject.pipe(debounceTime(1000))),
  );

  // Return the buffered observable and the next function to emit new values
  return {
    observable: bufferedObservable,
    next: (value: ObservableEvent) => subject.next(value),
  };
}

let observable: BufferedObservable["observable"] | null = null;
let next: BufferedObservable["next"] | null = null;

export function useTranslationWrapper(
  ns?: string | string[],
  options?: UseTranslationOptions,
): [TFunction, typeof i18n] {
  const { t: originalT, i18n } = useTranslation(ns, options);
  const dispatch = useDispatch();
  const location = useLocation();

  const missingTranslations = useSelector<RootState, MissingTranslationsState>(
    ({ missingTranslations }) => missingTranslations,
  );

  if (observable === null && next === null) {
    const { observable: o, next: n } = createBufferedObservable();
    observable = o;
    next = n;
    // Subscribe to the buffered observable
    observable.subscribe((bufferedValues) => {
      if (bufferedValues && bufferedValues.length > 0) {
        const updates = bufferedValues
          .filter((b) => b.action === "update")
          .map((b) => b.entry);
        const remove = bufferedValues
          .filter((b) => b.action === "delete")
          .map((b) => ({ key: b.entry.key, namespace: b.entry.namespace }));

        if (remove) {
          dispatch(deleteMissingTranslations(remove));
        }
        if (updates) {
          dispatch(updateMissingTranslations(updates));
        }
      }
    });
  }

  const _customT: TFunction = (key, options) => {
    // You can add any custom logic, error handling, or additional functionality here
    // For example, you could log a warning if a key doesn't exist:

    if (key === undefined) {
      return "";
    }

    let keys = typeof key === "object" ? key : [key];
    const english = keys.map((k) => k.trim());

    // Should not store mongo keys with . or $ in the key
    keys = english.map((k) => escapeDotsAndDollars(k)); // escape . and $ in keys

    const namespaces = (options?.ns &&
      (typeof options?.ns === "string" ? [options.ns] : options.ns)) || [
      _c.DEFAULT_TRANSLATION_NAMESPACE,
    ];

    let matchedKey: string | null = null;
    let matchedNamespace: string | null = null;

    for (const key of keys) {
      for (const ns of namespaces) {
        if (i18n.exists(key, { ns })) {
          matchedKey = key;
          matchedNamespace = ns;

          break;
        }
      }
      if (matchedNamespace !== null) {
        break;
      }
    }

    const found = missingTranslations.find(
      (m) => m.key === keys[0] && m.namespace === namespaces[0],
    );

    if (
      // keys[0] === "Contract offers" ||
      matchedKey === null &&
      matchedNamespace === null &&
      !found
    ) {
      if (
        !(translationNamespaces as ReadonlyArray<string>).includes(
          namespaces[0],
        )
      ) {
        console.error(
          `Missing translation namespace: "${namespaces[0]}" for key: "${keys[0]}". Please add it to namespaces in translation.types.ts`,
        );
      } else {
        const stackTrace = getStackTrace();
        const currentPath = location.pathname;

        const entry: Translation = {
          key: keys[0], // Add first key
          namespace: namespaces[0] as Namespaces, // Add first namespace
          languages: {
            en: english[0],
          },
          path: currentPath,
          stackTrace,
        };
        if (next !== null && entry.key !== "") {
          next({ action: "update", entry });
        }
      }
    } else if (matchedKey !== null && matchedNamespace !== null && found) {
      if (next !== null) {
        next({ action: "delete", entry: found });
      }
    }

    // If translation does not exist then use english and not . or $ escaped keys
    if (matchedKey === null && matchedNamespace === null) {
      keys = english;
    }

    // Call the original `t` function to translate the keys
    return originalT(keys, options) as string;
  };

  const customT: TFunction = (key, options) => {
    // You can add any custom logic, error handling, or additional functionality here
    // For example, you could log a warning if a key doesn't exist:
    if (key === undefined) {
      return "";
    }

    let keys = typeof key === "object" ? key : [key];
    keys = keys.map((k) => k.trim()); // remove blank space before and after

    const decodedKeys = keys.map((k) => decodeStringWithEscape(k));

    // Translate strings that are not escaped
    return decodedKeys[0]
      .map((s) => (s.isEscape ? s.text : _customT(s.text, options)))
      .join("");
  };

  return [customT, i18n];
}

export default useTranslationWrapper;
