import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Box, Grid } from "@mui/material";
import { Index } from "src/accurasee-backend-types/app/index/index.types";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./IndexModalGetStructure";
import {
  useCreateIndexMutation,
  useUpdateIndexMutation,
} from "src/redux/services/IndexService";

interface SeriesModalProps {
  actionType: "add" | "edit";
  openModal: boolean;
  handleCloseModal: () => void;
  initialData?: Partial<Index>;
  names?: string[];
}

const IndexModalWithForm = ({
  actionType,
  openModal,
  handleCloseModal,
  initialData = { active: false },
  names = [],
}: SeriesModalProps) => {
  const [createIndex] = useCreateIndexMutation();
  const [updateIndex] = useUpdateIndexMutation();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<Index>, GetStructureExtraProps>({
    getStructure,
    initialFormData: initialData,
    extraProps: {
      names,
    },
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { create: createIndex, update: updateIndex },
      data: {
        create:
          actionType === "add"
            ? (toSubmitData({ data: formData, initData: initialData }) as Index)
            : undefined,
        update:
          actionType === "edit"
            ? toSubmitData({ data: formData, initData: initialData })
            : undefined,
      },
      dataId: String(formData?._id),
      name: "Index",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      setFormData(initialData);
      handleCloseModal();
    },
  });

  const commonPropsBuildItem = {
    id: "index",
    data: formData,
    helperText,
  };

  return (
    <ModalWrapper
      label={actionType === "add" ? "Add index" : "Edit index"}
      openModal={openModal}
      handleCloseModal={() => {
        setHasTriedToSubmit(false);
        setFormData(initialData);
        handleCloseModal();
      }}
    >
      <Box sx={{ width: "680px" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            onFormSubmit();
          }}
          ref={refForm}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            {BuildItems({
              items: structure.items.filter((item) =>
                ["add_edit", actionType].includes(String(item.itemType)),
              ),
              ...commonPropsBuildItem,
            })}
          </Grid>
          <SectionWithButtonContainer>
            <ButtonGroup>
              <ButtonCancel
                onClick={() => {
                  setHasTriedToSubmit(false);
                  setFormData(initialData);
                  handleCloseModal();
                }}
              />
              <ButtonCreateOrUpdate
                initialSubmitData={initialData}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                label={actionType === "add" ? "create" : "update"}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                submitData={formData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </form>
      </Box>
    </ModalWrapper>
  );
};

export default IndexModalWithForm;
