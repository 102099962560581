import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    widgetWrapper: {
      borderRadius: "12px",
      height: "100%",
      padding: "var(--space-16px) var(--space-28px)",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
    },
    mainNumber: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: 1,
      paddingTop: "var(--space-8px)",
    },
    subNumber: {
      fontSize: "24px",
      fontWeight: 600,
    },
    numberDesc: { fontSize: "16px" },
    subNumberWrapper: {
      alignItems: "baseline",
      display: "flex",
      columnGap: "var(--space-8px)",
    },
    darkBackground: {
      backgroundColor: "var(--green-primary)",
      borderColor: "var(--green-primary)",
    },
    lightBackground: {
      backgroundColor: "var(--white-primary)",
      borderColor: "var(--gray-2)",
    },
    darkText: { color: "var(--white-primary)" },
    lightText: { color: "var(--gray-5)" },
    lightMainText: { color: "var(--green-primary)" },
  }),
);

export default useStyles;
