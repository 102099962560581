import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "../../../../components/Basic/Simple/Buttons/ButtonPrimary";
import CompanyModalWithForm from "./CompanyModalWithForm";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTransparentForTableWithToolbar from "../../../../components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";
import getStructure, { GetStructureExtraProps } from "./CompaniesStructure";
import useFormTable from "src/hooks/useFormTable";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanySuperAdminQuery } from "src/redux/services/CompanyService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Company,
  CompanyListReturn,
} from "src/accurasee-backend-types/app/company/company.types";

const Companies = () => {
  const [t] = useTranslation();

  const { data: companiesQuery, isLoading: isLoadingCompanies } =
    useGetCompanySuperAdminQuery(undefined);
  const companies = companiesQuery?.data || [];

  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  // import file modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState<
    Partial<CompanyListReturn>
  >({});

  const { helperText, structure } = useFormTable<
    CompanyListReturn,
    GetStructureExtraProps
  >({
    extraProps: {
      setOpenEditModal,
      setEditModalData,
    },
    initialFormData: companies,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: companies,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: companies.length >= 10,
  });

  // This is to check if a company/integration name is existing
  const companyNames = companies?.map((item) => item.displayName.toLowerCase());

  return (
    <SectionContainer>
      <SectionTransparentForTableWithToolbar
        rightBoxArea={
          <ButtonPrimary onClick={() => setOpenAddCompanyModal(true)}>
            {t("Add company")}
          </ButtonPrimary>
        }
        isLoading={isLoadingCompanies}
      >
        <MuiDataTableWrapper
          columns={columns}
          data={companies}
          noBackground={true}
          options={options}
          tableType="main-with-filter"
        />
      </SectionTransparentForTableWithToolbar>
      <CompanyModalWithForm
        key={"add"}
        companyNames={companyNames}
        openModal={openAddCompanyModal}
        handleCloseModal={() => setOpenAddCompanyModal(false)}
      />
      <CompanyModalWithForm
        key={"edit"}
        companyNames={companyNames?.filter(
          (name) => name !== editModalData?.displayName?.toLowerCase(),
        )}
        data={editModalData as Company}
        openModal={openEditModal}
        handleCloseModal={() => setOpenEditModal(false)}
      />
    </SectionContainer>
  );
};

export default Companies;
