/**
 *Created by Mikael Lindahl on 2022-12-20
 */

import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useStyles from "../BuildItemsStyles";
import throwErrorDataName from "../Utils/throwErrorDataName";
import getRequired from "../Utils/getRequired";
import getValue from "../Utils/getValue";
import setValue from "../Utils/setValue";
import getHelperText from "../Utils/getHelperText";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import getShowWhen from "../Utils/getShowWhen";

import useTranslation from "src/hooks/useTranslationWrapper";

type DateItemTProps = CommonBuilderContainerTypes;

const DateItem = (props: DateItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };
  const classes = useStyles();

  let valueLocal = getValue(commonCallbackProps);
  valueLocal =
    valueLocal === undefined
      ? new Date()
      : valueLocal === ""
        ? null
        : new Date(valueLocal);

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "DateItem" });
  }
  const required = getRequired(commonCallbackProps);

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <InputLabel
          required={required}
          shrink
          htmlFor={props.item.dataName}
          id={props.item.dataName}
          className={classes.labelFieldStyle}
        >
          {t(props.item.label || "")}
        </InputLabel>
        <DesktopDatePicker
          views={props.item.views || ["year", "day"]}
          inputFormat={props.item.inputFormat || "yyyy-MM-dd"}
          maxDate={props.item.maxDate}
          disabled={props.itemDisabled}
          value={valueLocal}
          onChange={(value, keyboardInputValue) => {
            // Keyboard input value is undefined when using date picker dialog
            // or the current value of the edited date in the text box
            value =
              keyboardInputValue !== undefined
                ? new Date(keyboardInputValue)
                : value;

            let changeProps = {
              ...commonCallbackProps,
              value,
            };
            if (props.item.setFormDataCustom) {
              props.item.setFormDataCustom(changeProps);
            } else {
              setValue({ ...changeProps });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: "100%",
                "& input": {
                  padding: "var(--space-12px) var(--space-16px)",
                },
                ...props.item.sx,
              }}
              helperText={getHelperText(getHelperTextProps)}
              error={Boolean(getHelperText(getHelperTextProps))}
              id={props.item.dataName}
              name={props.item.dataName}
              className={classes.datePickerIcon}
            />
          )}
          PaperProps={{
            sx: {
              backgroundColor: "var(--white-primary)",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              "& .MuiPickersDay-today": {
                "&:not(.Mui-selected)": {
                  borderColor: "var(--green-primary)",
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Grid>
  ) : (
    <></>
  );
};

export default DateItem;
