import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";
import {
  GetStructureExtraProps,
  items,
  ItemsTypes,
  calculateResultData,
  getStructure,
  toSubmitData,
} from "./EconomyContainerStructure";
import { Grid, Divider } from "@mui/material";
import Section from "src/components/Basic/Simple/Sections/Section";
import BuildItems from "src/components/Builders/Container/BuildItems";
import React, { useEffect, useState } from "react";
import {
  Budget,
  BudgetReturnWithPermission,
  MainBudget,
  ResultData,
} from "src/accurasee-backend-types/app/budget/budget.types";
import { Types } from "mongoose";
import { FormGlue } from "src/hooks/useFormGlue";

type EconomyContainerTProps = {
  budgetReturn: BudgetReturnWithPermission;
  contractId: Types.ObjectId;
  currencyCode: string;
  formGlue: FormGlue<Budget>;
  totalCostAdjustments: number;
  totalIncomeAdjustments: number;
};

export default function EconomyContainer({
  budgetReturn,
  contractId,
  currencyCode,
  formGlue,
  totalCostAdjustments,
  totalIncomeAdjustments,
}: EconomyContainerTProps) {
  const [t] = useTranslation();

  const initialFormData: MainBudget = {
    _id: budgetReturn?.budgetId,
    estimatedTotalPrice: budgetReturn?.estimatedTotalPrice,
    supplierCosts: budgetReturn?.supplierCosts,
    labourCosts: budgetReturn?.labourCosts,
    contractId,
  };
  const [resultsData, setResultsData] = useState<ResultData>(
    calculateResultData(
      budgetReturn?.ATAData?.totals,
      totalCostAdjustments,
      totalIncomeAdjustments,
      budgetReturn,
    ),
  );

  const { formData, helperText, structure } = useFormContainer<
    MainBudget,
    GetStructureExtraProps,
    ItemsTypes
  >({
    // Retype
    extraProps: {
      currencyCode,
      ATAData: budgetReturn?.ATAData,
      resultsData: resultsData,
      totalPrice: budgetReturn.totalPrice,
    },
    formGlue,
    getStructure,
    initialFormData: initialFormData,
    toSubmitData,
  });

  useEffect(() => {
    setResultsData(
      calculateResultData(
        budgetReturn?.ATAData?.totals,
        totalCostAdjustments,
        totalIncomeAdjustments,
        {
          ...budgetReturn,
          ...formData,
        },
      ),
    );
  }, [budgetReturn, formData, totalCostAdjustments, totalIncomeAdjustments]);

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <>
      <Section
        key={"budget"}
        label={!formData?._id ? "Create budget" : "Budget"}
        md={12}
        lg={5}
      >
        {BuildItems({
          items: structure.items.filter((item) => item.itemType === "budget"),
          ...commonPropsBuildItem,
        })}
      </Section>
      <Grid item lg={1} sx={{ display: "flex", alignItems: "center" }}>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ margin: "auto", height: "80%" }}
        />
      </Grid>
      <Section
        key={"alteration-and-additional-work"}
        md={12}
        lg={3}
        label={"Alteration and additional work"}
      >
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "alterationAndAdditionalWork",
          ),
          ...commonPropsBuildItem,
        })}
        <>
          {items.alterationAndAdditionalWork.map((e) => (
            <React.Fragment key={`${e.label}`}>
              <Grid item xs={12}>
                <Divider textAlign="center" sx={{ fontWeight: "700" }}>
                  {t(e.label)}
                </Divider>
              </Grid>
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === e.type,
                ),
                ...commonPropsBuildItem,
              })}
            </React.Fragment>
          ))}
        </>
      </Section>
      <Section key={"project-results"} md={12} lg={3} label={"Project results"}>
        {items.results.map((e) => (
          <React.Fragment key={`${e.label}`}>
            <Grid item xs={12}>
              <Divider textAlign="center" sx={{ fontWeight: "700" }}>
                {t(e.label)}
              </Divider>
            </Grid>
            {BuildItems({
              items: structure.items.filter((item) => item.itemType === e.type),
              ...commonPropsBuildItem,
            })}
          </React.Fragment>
        ))}
      </Section>
    </>
  );
}
