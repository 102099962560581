import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import getCountryName from "src/utils/getCountryName";
import { CompanyListReturn } from "../../../../accurasee-backend-types/app/company/company.types";
export interface GetStructureExtraProps {
  setOpenEditModal: (v: boolean) => void;
  setEditModalData: (data: CompanyListReturn) => void;
}

const getStructure: UserFormTableGetStructure<
  CompanyListReturn,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => ({
  items: [
    {
      type: "text",
      dataName: "displayName",
      headerLabel: "Display name",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text",
      dataName: "name",
      headerLabel: "Name",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text",
      dataName: "integrationName",
      headerLabel: "Integration",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "date",
      dataName: "earliestImportDate",
      headerLabel: "Earliest import date",
      showCellWhen: ({ data, rowIndex }) => !!data[rowIndex].earliestImportDate,
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "icon",
      dataName: "bkConnection",
      iconType: "check",
      showCellWhen: ({ data, rowIndex }) => data[rowIndex].bkConnection,
      headerLabel: "BK connection",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text",
      dataName: "country",
      headerLabel: "Country",
      sx: { width: "100%" },
      getValue: ({ data, rowIndex }) => {
        const value = data[rowIndex]?.country;
        if (!value) {
          return "-";
        } else {
          return getCountryName.of(value);
        }
      },
      // sort: true,
    },
    {
      type: "text",
      dataName: "address1",
      headerLabel: "Street",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text",
      dataName: "zipCode",
      headerLabel: "Zipcode",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "text",
      dataName: "city",
      headerLabel: "City",
      sx: { width: "100%" },
      // sort: true,
    },
    {
      type: "icon_button",
      iconType: "edit",
      dataName: "edit",
      onClick: ({ rowIndex, data }) => {
        extraProps?.setOpenEditModal(true);
        extraProps?.setEditModalData(data[rowIndex]);
      },
    },
  ],
});

export default getStructure;
