import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const [t] = useTranslation();

  return (
    <Box
      sx={{
        padding: "32px 48px",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        style={{
          fontWeight: "700",
          fontSize: "72px",
          color: "var(--black-7)",
          opacity: 0.6,
        }}
      >
        401
      </Typography>
      <Typography
        variant="h2"
        style={{
          fontWeight: "700",
          color: "var(--black-7)",
          marginTop: "var(--space-8px)",
          marginBottom: "var(--space-32px)",
        }}
      >
        {t("Unauthorized")}
      </Typography>
      <Link
        component={RouterLink}
        to="/app/dashboard"
        underline="none"
        sx={{
          backgroundColor: "var(--green-primary)",
          color: "var(--white-primary)",
          padding: "var(--space-8px) var(--space-16px)",
          borderRadius: "4px",
        }}
      >
        {t("Go to Dashboard")}
      </Link>
    </Box>
  );
};
export default Unauthorized;
