import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";

const getContractOfferExternalId = (
  contractOffer?: Partial<ContractOffer>,
  asMain?: boolean,
) => {
  if (!contractOffer) return "...";
  return `${contractOffer.projectExternalId} (${
    contractOffer.mainContractOfferNumber
  }${
    contractOffer.category === "sub" && !asMain
      ? "." + contractOffer.subContractOfferNumber
      : ""
  })`;
};

export default getContractOfferExternalId;
