/**
 *Created by Mikael Lindahl on 2023-11-29
 */

import { useState } from "react";
import isEqual from "lodash/isEqual";

export type FormGlue<T = any, TSubmit = any> = {
  formData?: T;
  hasTriedToSubmit: boolean;
  isFormValid: boolean;
  isEmpty: boolean;
  isHasDataChanged: boolean;
  setFormData?: (data: T) => void; // Has to be added on later in useFormContainer or useFormTable
  setFormDataInternal: (data: T) => void;
  setHasTriedToSubmit: (value: boolean) => void;
  setIsFormValid: (value: boolean) => void;
  setSubmitData: (data: TSubmit) => void;
  setSubmitDataInitial: (data: TSubmit) => void;
  submitData?: TSubmit;
  submitDataInitial?: TSubmit;
};

/**
 *  Hook that provides state for managing form data and submit data i
 *  child components. E.g. if you have a page with two components that
 *  modify the same data (invoiceRows vs rest of data is typical case),
 *  you can pass form glue to respective component to track state in
 *  parent component. Now one can have a submit function in parent
 *  component and track the state changes that will submit all data from
 *  both child components. Form glue alsa tracks has tried to submit and
 *  is form valid.
 */
const useFormGlue = <T = any, TSubmit = any>() => {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [submitData, setSubmitData] = useState<TSubmit>();
  const [formData, setFormDataInternal] = useState<T>();
  const [submitDataInitial, setSubmitDataInitial] = useState<TSubmit>();

  const getIsHasDataChanged = (data: any, dataInitial: any) => {
    if (data === null || data === undefined) return false;
    if (Array.isArray(data)) {
      if (data.length === 0) return false;

      if (Array.isArray(dataInitial)) {
        if (data.length !== dataInitial.length) return true;

        return data.every((d, i) => !isEqual(d, dataInitial[i]));
      }
    }
    if (typeof data === "object")
      return Object.keys(data).length !== 0 || !isEqual(data, dataInitial);

    return false;
  };

  const getIsEmpty = (data: any) => {
    if (data === null || data === undefined) return true;
    if (Array.isArray(data)) return data.length === 0;
    if (typeof data === "object") return Object.keys(data).length === 0;
    return false;
  };

  const isEmpty = getIsEmpty(submitData);

  const isHasDataChanged = getIsHasDataChanged(submitData, submitDataInitial);

  const formGlue: FormGlue<T, TSubmit> = {
    formData,
    hasTriedToSubmit,
    isEmpty,
    isFormValid,
    isHasDataChanged,
    setFormDataInternal,
    setHasTriedToSubmit,
    setIsFormValid,
    setSubmitData,
    setSubmitDataInitial,
    submitData,
    submitDataInitial,
  };
  return formGlue;
};

export default useFormGlue;
