import { Badge, Typography } from "src/components/Basic/Mixed/Wrappers";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography as MuiTypography,
} from "@mui/material";
import { Types } from "mongoose";
import { UPDATE_USER } from "src/redux/actions/types";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useUpdateUserMutation } from "src/redux/services/UserService";
import classNames from "classnames";
import getStylingByStatusType from "src/utils/getStylingByStatusType";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserAvatar from "src/components/Basic/Simple/UserAvatar";
import useStyles from "./HeaderStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface HeaderCompaniesProps {
  activeCompanyId?: Types.ObjectId;
  companies: { active: boolean; displayName: any; id: string }[];
}

const HeaderCompanies = ({
  activeCompanyId,
  companies,
}: HeaderCompaniesProps) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [companyMenu, setCompanyMenu] = useState<any>(null);
  const [updateUser] = useUpdateUserMutation();

  const activeCompany = companies?.find(
    (company) => company.id === activeCompanyId?.toString(),
  );
  return (
    <>
      <IconButton
        id="header-companies-button"
        color="inherit"
        aria-haspopup="true"
        aria-controls="mail-menu"
        onClick={(e) => {
          setCompanyMenu(e.currentTarget);
        }}
        className={classes.headerMenuButton}
        size="large"
      >
        <Typography className={classes.notifications}>
          {t("Company")}
        </Typography>
        {companies.length > 0 && (
          <Badge badgeContent={companies.length} color="primary">
            <KeyboardArrowDownIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        )}
      </IconButton>
      <Menu
        id="header-companies-menu"
        open={Boolean(companyMenu)}
        anchorEl={companyMenu}
        onClose={() => setCompanyMenu(null)}
        MenuListProps={{ className: classes.headerMenuList }}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <Box
          sx={{
            marginBottom: "var(--space-8px)",
            padding: "var(--space-8px) var(--space-16px)",
          }}
        >
          <MuiTypography className={classes.menuTitle}>
            {t("Companies")}
          </MuiTypography>
        </Box>
        <Divider />
        <Box sx={{ marginTop: "var(--space-8px)" }}>
          {activeCompany && (
            <Box className={classes.activeCompany}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar name={activeCompany.displayName} />
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="bold">
                  {activeCompany.displayName}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    fontSize: "12px",
                    color: getStylingByStatusType("active").color,
                  }}
                >
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor:
                        getStylingByStatusType("active").backgroundColor,
                      margin: "auto var(--space-8px) auto 0",
                    }}
                  />
                  {t("Active")}
                </Box>
              </div>
            </Box>
          )}
          <MuiTypography
            className={classes.menuSubTitle}
            sx={{
              fontWeight: "600",
              padding: "var(--space-8px) var(--space-16px)",
            }}
          >
            {t("Switch")}
          </MuiTypography>
          {companies
            ?.filter((company) => company.id !== activeCompanyId?.toString())
            .map((company: any) => {
              return (
                <MenuItem
                  key={company.id}
                  disabled={!company?.active}
                  onClick={async () => {
                    try {
                      const response: any = await updateUser({
                        activeCompanyId: company.id,
                      });
                      if (response?.error) {
                        throw response?.error;
                      }
                      dispatch({ type: UPDATE_USER, payload: response?.data });

                      // Remove query params if changing company since otherwise code query params for response from
                      // follows and leads to un-auth message for company that was changed to
                      const { pathname } = location;
                      navigate(pathname);
                      window.location.reload();
                    } catch (err: any) {
                      enqueueSnackbar(
                        `Could not update the active company to ${company.displayName}`,
                        { variant: "error" },
                      );
                    }
                  }}
                  className={classes.messageNotification}
                >
                  <div className={classes.messageNotificationSide}>
                    <UserAvatar name={company.displayName} />
                  </div>
                  <div
                    className={classNames(
                      classes.messageNotificationSide,
                      classes.messageNotificationBodySide,
                    )}
                  >
                    <Typography weight="medium">
                      {company.displayName}
                    </Typography>
                    {!company?.active && (
                      <Typography size="sm">{` (${t("Deactivated")})`}</Typography>
                    )}
                  </div>
                </MenuItem>
              );
            })}
        </Box>
      </Menu>
    </>
  );
};

export default HeaderCompanies;
