/**
 *Created by Mikael Lindahl on 2023-11-23
 */
import { UseFormContainerGetStructure } from "../../../hooks/useFormContainer";
import { Offset } from "../../../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import _c from "../../../constants/Constants";
import cloneDeep from "lodash/cloneDeep";
import { StructureContainer } from "../../Builders/Container/CommonBuilderContainerTypes";
import getSelectOptionsDayWeekMonthQuarterYear from "../../../utils/getSelectOptionsDayWeekMonthQuarterYear";
import getSelectOptions from "../../../utils/getSelectOptions";

function getOffsetOptions(dataUnit?: Offset["unit"]) {
  return _c.OFFSET_OPTIONS.filter((option) => {
    switch (dataUnit) {
      case "day":
        return ["yyyy-MM-dd"].includes(option.value);
      case "month":
        return ["MMMM", "MMM"].includes(option.value);
      case "quarter":
        return ["Qo", "QQQ"].includes(option.value);
      case "week":
        return ["w"].includes(option.value);
      case "year":
        return ["yyyy", "yyy"].includes(option.value);
      default:
        return false;
    }
  });
}

export type GetStructureExtraProps = {};
export const getStructure: UseFormContainerGetStructure<
  Partial<Offset>,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<Partial<Offset>> = {
    items: [
      {
        gridProps: { xs: 6 },
        dataName: "unit",
        type: "selector",
        options: getSelectOptionsDayWeekMonthQuarterYear(t),
        setFormDataCustom: (props) => {
          if (props.data) {
            let newData = cloneDeep(props.data);
            newData = {
              ...newData,
              format: {
                value:
                  (getOffsetOptions(props.value)[0]
                    ?.value as Offset["format"]["value"]) || "MMMM",
                label: getOffsetOptions(props.value)[0]?.label || "",
              },
              unit: props.value,
            };
            setFormData(newData);
          }
        },
        label: "Unit",
      },
      {
        gridProps: { xs: 6 },
        dataName: "number",
        type: "number",
        label: t("Offset"),
        setFormData,
      },
      {
        required: true,
        dataName: "format.value",
        type: "autocomplete",
        label: t("Format"),
        options: ({ data }) => {
          return getSelectOptions({
            data: getOffsetOptions(data?.unit),
          });
        },
        setFormDataCustom: (props) => {
          if (props.data) {
            let newData = cloneDeep(props.data);

            const options = getOffsetOptions(props?.data?.unit);

            newData = {
              ...newData,
              format: {
                value: props.value,
                label: options.find((o) => o.value === props.value)?.label,
              },
            };
            setFormData(newData);
          }
        },
      },
    ],
  };
  return structure;
};
