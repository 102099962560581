import isURL from "validator/es/lib/isURL";
import { StructureItemContainer } from "../components/Builders/Container/CommonBuilderContainerTypes";
import { TableItemType } from "src/accurasee-backend-types/frontend/Table/CommonBuilderTableTypesShared";

const validUrl = <DataType = any>(
  value: any,
  itemComponentType?: StructureItemContainer<DataType>["type"] | TableItemType,
) => {
  let isValidUrl = true;

  if (
    value !== undefined &&
    itemComponentType &&
    ["url_input"].includes(itemComponentType)
  ) {
    isValidUrl = isURL(value, { require_protocol: false });
  }

  return isValidUrl;
};

export default validUrl;
