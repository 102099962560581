/**
 * This is to handle file automatically
 * Includes: uploading, downloading and deleting
 */

import FormHelperText from "@mui/material/FormHelperText";
import FileChip from "src/components/Basic/Simple/Chips/FileChip";
import getHelperText from "../Utils/getHelperText";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import useStyles from "./CommonStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box, Grid, InputLabel } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { FileStorageExtra } from "./FileItem";
import { useState } from "react";
import { usePostFileMutation } from "src/redux/services/FileService";
import {
  CommonBuilderContainerTypes,
  StructureItemContainer,
} from "../CommonBuilderContainerTypes";

type AutoFileItemTProps = CommonBuilderContainerTypes & {
  item: StructureItemContainer;
};

const AutoFileItem = (props: AutoFileItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);
  const [postFileMutation] = usePostFileMutation();

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };
  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  const allFiles = getValue(props) as FileStorageExtra[];
  const existingFiles = allFiles?.filter((f) => !f.isDeleted);
  const [error, setError] = useState<string>("");
  const label = props.item?.label || "Attachment";

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "AutoFileItem" });
  }

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel shrink>{t(label)}</InputLabel>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Box className={classes.dropzoneBox}>
          <DropzoneArea
            showAlerts={false}
            acceptedFiles={
              props.item?.filesAllowed || [
                "image/jpeg",
                "image/png",
                "image/bmp",
                "application/pdf",
              ]
            }
            onDropRejected={() => {
              setError("Invalid file type");
            }}
            onChange={async (loadedFiles) => {
              if (
                props.item.fileLimit &&
                existingFiles.length > props.item.fileLimit
              ) {
                return setError(
                  t(
                    "File limit reached. Please remove files before adding new",
                  ),
                );
              } else {
                setError("");
              }
              if (loadedFiles.length === 0) {
                return;
              }
              const loadedFile = loadedFiles[0];
              const fileData = new FormData();
              if (props.data?._id) {
                fileData.append("objectId", props.data?._id);
              }
              fileData.append("file", loadedFile);
              const result: any = await postFileMutation(fileData);
              if (result?.error) {
                setError(t("Could not upload file!!"));
                return;
              }
            }}
            maxFileSize={5000000}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{
              container: { spacing: 1, direction: "row" },
            }}
            previewGridClasses={{
              container: classes.previewGridContainer,
            }}
            dropzoneText={t("Attach new file")}
            previewChipProps={{
              color: "primary",
              classes: {
                root: classes.previewChip,
              },
            }}
            clearOnUnmount // Clear file loader on new file
            filesLimit={1} // Keep file loader memory to one (storing files in data instead)
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", paddingTop: "1rem" }}>
        <Box className={classes.attachmentsBox}>
          {existingFiles &&
            existingFiles?.map((file, index) => (
              <FileChip
                key={index}
                index={index}
                allFiles={allFiles}
                file={file}
                fileObjectId={props?.data?._id}
              />
            ))}
        </Box>
        <FormHelperText
          sx={{
            marginLeft: 0,
            color: "red !important",
            textAlign: "center",
          }}
        >
          {error !== "" ? error : getHelperText(getHelperTextProps)}
        </FormHelperText>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default AutoFileItem;
