/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { CommonCallbackProps } from "../CommonBuilderTableTypes";

const getRequired = (props: CommonCallbackProps) => {
  return (
    (typeof props.item.required === "function"
      ? props.item.required(props)
      : props.item.required) || false
  );
};

export default getRequired;
