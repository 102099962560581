/**
 *Created by Mikael Lindahl on 2024-02-29
 */

import { Types } from "mongoose"; // Assuming you're using react-i18next for translation
import { useCreateIntegrationImportJobMutation } from "src/redux/services/IntegrationImportJobService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  IntegrationImportJobCreate,
  IntegrationImportJobName,
} from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";

const useQueueJobsForCurrentSteps = (
  currentSetups: IntegrationImportJobName[],
  triggerFullDownload?: boolean,
) => {
  const { t } = useTranslation();
  const [createIntegrationImportJobMutation] =
    useCreateIntegrationImportJobMutation();
  const { enqueueSnackbar } = useSnackbar();

  const queueJobsForCurrentSetups = async (isAll?: boolean) => {
    try {
      const payload: IntegrationImportJobCreate[] = [];

      const groupId = new Types.ObjectId();
      const groupMainImportName = isAll
        ? "import_all"
        : currentSetups.slice(-1)[0];

      for (
        let groupPosition = 0;
        groupPosition < currentSetups.length;
        groupPosition++
      ) {
        const name = currentSetups[groupPosition];

        payload.push({
          name,
          groupId,
          groupMainImportName,
          groupPartImportName: name,
          groupPosition,
          groupSize: currentSetups.length,
          isFirstImport: !!triggerFullDownload,
        });
      }

      const result = await createIntegrationImportJobMutation(payload);

      if ("data" in result && result?.data) {
        enqueueSnackbar(
          `${t(
            `Succeeded to queue Integration import ${payload.length} jobs`,
          )}`,
          { variant: "success" },
        );
      } else {
        enqueueSnackbar(
          `${t(`Failed to queue Integration import ${payload.length} jobs`)}`,
          { variant: "error" },
        );
      }
    } catch (error: any) {
      enqueueSnackbar(
        `${t("Queuing of integration jobs failed")} ${error.message}`,
        {
          variant: "error",
        },
      );
    }
  };

  return queueJobsForCurrentSetups;
};

export default useQueueJobsForCurrentSteps;
