import _c from "src/constants/Constants";
import { setNestedObjectData } from "src/utils/nestedData";
import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { AccessRolePermissionWithPermissions } from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";

export const useUserRolePermission = ({
  data,
}: {
  data: AccessRolePermissionWithPermissions[];
}) => {
  const [formData, setFormData] =
    useState<AccessRolePermissionWithPermissions[]>(data);
  // This is to filter only modified permissions
  const [modifiedPermissions, setModifiedPermissions] = useState<string[]>([]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const onChangeRolePermissions = ({
    key,
    rowIndex,
    value,
  }: {
    key: string;
    rowIndex: number;
    value: boolean | string[];
  }) => {
    let newFormData = cloneDeep(formData);
    const newRow = newFormData[rowIndex];

    setNestedObjectData({ data: newRow, key, value });

    if (key === "filters" && newRow.collectionTarget === "contracts") {
      newFormData = newFormData?.map((obj, index) =>
        index === rowIndex &&
        _c.AUTH_RIGHT_CONTROLLED_BY_CONTRACT.includes(obj?.collectionTarget)
          ? { ...obj, filters: value as string[] }
          : obj,
      );
    }

    setFormData(newFormData);
    setModifiedPermissions((modifiedPermissions) => [
      ...modifiedPermissions,
      newRow.collectionTarget,
    ]);
  };

  return {
    formData,
    modifiedPermissions,
    onChangeRolePermissions,
    setFormData,
    setModifiedPermissions,
  };
};
