import { backendApi } from "../api";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import {
  docDeleteTags,
  docGetTag,
  listTag,
  docListTags,
  docPatchTag,
} from "./utils";

const CACHE_TYPE = "ContactPerson";
const contactPersonApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getContactPeople: build.query<
      { data: ContactPerson[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/contact-people", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getContactPerson: build.query<ContactPerson, any>({
      query: (id) => ({ url: `/contact-people/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    updateContactPerson: build.mutation<ContactPerson, any>({
      query: ({ id, ...data }) => {
        return {
          url: `/contact-people/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    createContactPerson: build.mutation<ContactPerson, any>({
      query: (data) => ({ url: "/contact-people", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    deleteContactPerson: build.mutation<any, ContactPerson["_id"] | string>({
      query: (id) => ({ url: `/contact-people/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
});
export const {
  useGetContactPeopleQuery,
  useGetContactPersonQuery,
  useCreateContactPersonMutation,
  useDeleteContactPersonMutation,
  useUpdateContactPersonMutation,
} = contactPersonApi;
export default contactPersonApi;
