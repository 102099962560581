/**
 *Created by Mikael Lindahl on 2023-12-15
 */
import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";

type ButtonCreateTProps = Omit<ButtonSubmitCoreTProps, "label">;

const ButtonCreate = (props: ButtonCreateTProps) => {
  return <ButtonSubmitCore {...props} label={"save"} />;
};

export default ButtonCreate;
