import {
  Namespaces,
  Translation,
} from "../../../../accurasee-backend-types/app/translation/translation.types";
import {
  checkIfPlaceHolderOrEmpty,
  TranslationExtra,
  TranslationExtraStatus,
  TranslationExtraStatusMissed,
} from "./TranslationsStructure";
import { LanguageCode } from "../../../../accurasee-backend-types/app/language/language.types";
import { dicToList, listToDic } from "../../../../utils/transform";
import clone from "../../../../utils/clone";
import sort from "../../../../utils/sort";
import { TranslationImport } from "./hooks/useTranslationImportModalStructure";
import { escapeDotsAndDollars } from "../../../../utils/translate";

interface AddRowTProps {
  data: { key: string; namespace: Namespaces };
  initialData: Translation[];
  formData: TranslationExtra[];
  languages: LanguageCode[];
}

export const addRow = (props: AddRowTProps) => {
  let itemByLanguage: TranslationExtra["languages"] = {};
  props.languages.forEach((lan) => {
    itemByLanguage = {
      ...itemByLanguage,
      [lan]: { value: "", status: "" },
    } as TranslationExtra["languages"];
  });

  const key = escapeDotsAndDollars(props.data.key);

  const newFormData = [
    // This helps show the added row on the top of the list
    {
      ...props.data,
      key,
      languages: { ...itemByLanguage },
      status: "Added" as TranslationExtraStatus,
      statusMissed: "" as TranslationExtraStatusMissed,
    },
    ...props.formData,
  ];

  return newFormData;

  // props.setInitialData(newInitialData);
};

/**
 * normalize object of translations
 *
 * @param data
 * @returns languages and normalizedTranslations as Array
 */
export const normalize = (data: any) => {
  let languages: string[] = [];
  let normalizedTranslations: any = [];
  // TODO: Find better validation for file format
  if (Object.keys(data)[0] !== "en") {
    return { error: "Wrong format", languages, normalizedTranslations };
  }
  languages = Object.keys(data);
  normalizedTranslations = Object.keys(data[languages[0]])?.reduce(
    (cur: any, item: string, index: number) => {
      const row = {
        id: index,
        key: item,
        status: "",
      };
      languages.forEach((el) => {
        row[el] = data[el][item];
      });
      cur.push(row);
      return cur;
    },
    [],
  );
  return { languages, normalizedTranslations };
};

export const addImport = ({
  activeLanguages,
  data,
  formData,
  importType,
}: {
  activeLanguages: LanguageCode[];
  data: Translation[];
  formData: TranslationExtra[];
  importType: TranslationImport["importType"];
}) => {
  let importDic = listToDic(data, (d) => d.key + "." + d.namespace);
  let currentDic = listToDic(clone(formData), (d) => d.key + "." + d.namespace);

  for (let key in currentDic) {
    if (importDic[key]) {
      for (let language of activeLanguages) {
        const value = importDic[key].languages[language];
        const current = currentDic[key].languages[language];
        if (value === undefined) {
          continue;
        } else if (checkIfPlaceHolderOrEmpty(value)) {
          continue;
        }
        if (
          current !== undefined &&
          checkIfPlaceHolderOrEmpty(current?.value)
        ) {
          currentDic[key].languages[language] = {
            originalValue: "",
            value,
            status: "Updated",
          };
          currentDic[key].status = "Updated";
        } else if (
          currentDic[key].languages[language]?.value !==
          importDic[key].languages[language]
        ) {
          currentDic[key].languages[language] = {
            originalValue: currentDic[key].languages[language]?.value || "",
            value,
            status: "Updated",
          };
          currentDic[key].status = "Updated";
        }
      }
    } else if (!importDic[key] && importType === "replace") {
      currentDic[key].status = "Deleted";
    }
  }

  for (let key in importDic) {
    if (!currentDic[key]) {
      currentDic[key] = {
        key: importDic[key].key,
        namespace: importDic[key].namespace,
        languages: Object.keys(importDic[key].languages).reduce(
          (dic, language) => {
            dic[language] = {
              originalValue: "",
              value: importDic[key].languages[language],
              status: "",
            };
            return dic;
          },
          {} as TranslationExtra["languages"],
        ) as TranslationExtra["languages"],
        status: "Added",
        statusMissed: "",
      };
    }
  }

  let newFormData = dicToList(currentDic);

  newFormData = sort(newFormData, (d) => d.key.toLowerCase() + d.namespace);

  return newFormData;
};
