import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    mainContainer: {
      padding: "1rem 2rem 1rem 2rem",
    },
    borderContainer: {
      position: "relative",
      border: "1px solid var(--white-5)",
      borderRadius: "4px",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      top: "-12px",
      position: "absolute",
    },
    headerLabel: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      columnGap: "var(--space-8px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: "var(--white-primary)",
      padding: "0 var(--space-16px)",
      margin: "0 var(--space-24px)",
    },
    childrenContainer: {
      padding: "var(--space-12px) var(--space-16px)",
      pointerEvents: "none",
      minHeight: "216px",
    },
  }),
);

export default useStyles;
