import { useCreateContractMutation } from "src/redux/services/ContractService";
import ContractCreateBase from "../../../../components/Screens/ContractCreateBase/ContractCreateBase";

const ContractCreate = () => {
  const createMutation = useCreateContractMutation();

  return (
    <ContractCreateBase
      creationCategory="contract"
      rerouteUrlOnSuccess="/app/contracts"
      createMutation={createMutation}
    />
  );
};

export default ContractCreate;
