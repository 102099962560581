import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { useMarkSubcontractAsInvoicedMutation } from "src/redux/services/SubcontractService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import useTranslation from "src/hooks/useTranslationWrapper";

const useMarkSubcontractsAsInvoiced = ({
  selectedSubcontracts,
}: {
  selectedSubcontracts: SubcontractWithPermissions[];
}) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [markSubcontractAsInvoiced] = useMarkSubcontractAsInvoicedMutation();

  const [
    isMarkSubcontractsAsInvoicedLoading,
    setIsMarkSubcontractsAsInvoicedLoading,
  ] = useState(false);

  const onMarkSubcontractsAsInvoiced = async () => {
    setIsMarkSubcontractsAsInvoicedLoading(true);

    const filteredSubcontractIds = selectedSubcontracts?.filter(
      (subcontract) => !!subcontract?._id,
    );

    // No rows found
    if (filteredSubcontractIds.length === 0) {
      console.error("No rows selected");
      setIsMarkSubcontractsAsInvoicedLoading(false);
      return enqueueSnackbar("No rows selected", {
        variant: "info",
      });
    } else {
      try {
        const results = await Promise.allSettled(
          filteredSubcontractIds?.map(async ({ _id: id, name }) => {
            try {
              const result = await markSubcontractAsInvoiced({ id });
              return {
                id,
                name,
                ...result, // this includes data or error
              };
            } catch (error) {
              throw new Error(`Failed to fetch subcontract with id: ${id}`); // Explicit error message
            }
          }),
        ).then((results) =>
          results.map((result) =>
            result.status === "fulfilled" ? result.value : result.reason,
          ),
        );

        // Determine if all calls were successful
        const allSuccess = results.every((result) => !("error" in result));

        if (allSuccess) {
          // If all API calls were successful, show one success message only
          enqueueSnackbar("Subcontract(s) marked as invoiced", {
            variant: "success",
          });
        } else {
          // If there are any errors, show individual messages for success and failure
          results.forEach((item) => {
            if ("error" in item) {
              const defaultMsg = t(
                `Couldn't mark subcontract ${item.name} as invoiced`,
              );
              const subMsg = parseRestApiErrorMessage(item.error);
              const msg = subMsg ? defaultMsg + ": " + subMsg : defaultMsg;
              enqueueSnackbar(msg, { variant: "error" });
            } else {
              enqueueSnackbar(
                `Subcontract ${item.name} has been marked as invoiced successfully`,
                { variant: "success" },
              );
            }
          });
        }

        setIsMarkSubcontractsAsInvoicedLoading(false);
        return;
      } catch (error: any) {
        setIsMarkSubcontractsAsInvoicedLoading(false);
        return enqueueSnackbar(
          "Could not mark subcontract(s) as invoiced. Error: " + error.message,
          { variant: "error" },
        );
      }
    }
  };

  return { isMarkSubcontractsAsInvoicedLoading, onMarkSubcontractsAsInvoiced };
};

export default useMarkSubcontractsAsInvoiced;
