/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import _ from "lodash";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "../components/Builders/Table/CommonBuilderTableTypes";
import { FormGlue } from "./useFormGlue";
import { useEffect, useState } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { validDate } from "../utils/date";
import clone from "src/utils/clone";
import getRequired from "../components/Builders/Table/Utils/getRequired";
import getShowCellWhen from "../components/Builders/Table/Utils/getShowCellWhen";
import getValue from "../components/Builders/Table/Utils/getValue";
import get from "../components/Builders/Table/Utils/get";
import isRequired from "../utils/isRequired";
import useTranslationWrapper, { TFunction } from "./useTranslationWrapper";
import validNumber from "src/utils/validNumber";
import validUrl from "src/utils/validUrl";

export type UserFormTableGetStructure<
  DataType,
  ExtraProps = any,
  ItemTypes = string,
> = ({
  extraProps,
  navigate,
  setFormData,
  t,
}: {
  extraProps?: ExtraProps;
  navigate: NavigateFunction;
  setFormData: (data: DataType[]) => void;
  t: TFunction;
}) => {
  items: StructureItemTable<DataType, ItemTypes>[];
};

export type UserFormTableGetStructureOptional<
  DataType,
  ExtraProps = any,
  ItemTypes = string,
> = (props?: {
  extraProps?: ExtraProps;
  setFormData?: (data: DataType[]) => void;
  navigate?: NavigateFunction;
  t?: TFunction;
}) => {
  items: StructureItemTable<DataType, ItemTypes>[];
};

type ToSubmitData<T> = ({
  data,
  initData,
}: {
  data?: T[];
  initData?: T[];
}) => Partial<T>[] | undefined;

function checkIfFormIsValid<
  DataType = any,
  StructureType extends { items: StructureItemTable<DataType>[] } = any,
>({ structure, data }: { data?: DataType[]; structure: StructureType }) {
  if (!data) {
    return true;
  }

  let res: any[] = [];
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const structureItems = structure.items.filter((item) => {
      if (item.showCellWhen) {
        return getShowCellWhen({ data, item, rowIndex });
      } else {
        return true;
      }
    });

    res = [
      ...res,
      structureItems.filter((item: any) => {
        const commonCallbackProps: CommonCallbackProps = {
          data,
          item,
          rowIndex,
        };
        const value = getValue(commonCallbackProps);

        const isValidDate = validDate(value, item.type);
        const isValidNumber = validNumber(value, item.type);
        const isValidUrl = validUrl(value, item.type);

        // Reminder isRequired thus not need to be check here to work
        // only in getErrorText
        if (
          !isValidNumber ||
          !isValidDate ||
          !isValidUrl ||
          (value !== undefined &&
            item.validate &&
            !item.validate({ data, item, rowIndex }))
        ) {
          // If is not value then return true to that res has values. Below
          // if res has values then form is not valid
          return true;
        } else {
          return false;
        }
      }),
    ];
  }

  return res.reduce((tot, item) => {
    tot += item.length;
    return tot;
  }, 0) === 0
    ? true
    : false;
}

function getErrorText(props: CommonCallbackProps) {
  return typeof props.item.getErrorText === "function"
    ? props.item.getErrorText(props)
    : props.item.getErrorText;
}

export function getHelperText<
  DataType = any,
  StructureType extends { items: StructureItemTable<DataType>[] } = any,
>({
  structure,
  data,
  hasTriedToSubmit,
}: {
  data?: DataType[];
  structure: StructureType;
  hasTriedToSubmit: boolean;
}) {
  let helperText: any[] = [];

  if (!data) {
    return [];
  }

  structure.items.forEach((item) => {
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      const commonCallbackProps: CommonCallbackProps = {
        data,
        item,
        rowIndex,
      };

      const value = getValue(commonCallbackProps);
      const type = get("type", commonCallbackProps);

      const isValidDate = validDate(value, type);
      const isValidNumber = validNumber(value, type);
      const isValidUrl = validUrl(value, type);

      if (!helperText[rowIndex]) {
        helperText.push({});
      }
      let required;
      if (item.required) {
        required = getRequired(commonCallbackProps);
      }

      if (
        !isValidNumber ||
        !isValidDate ||
        !isValidUrl ||
        (required && isRequired(value) && hasTriedToSubmit) ||
        // Added data to params so ut can be used in validation
        // e.g. when one needs to check against another value
        (value !== undefined &&
          item.validate &&
          !item.validate(commonCallbackProps))
      ) {
        helperText[rowIndex][item.dataName] = !isValidNumber
          ? "Invalid number format"
          : !isValidDate
            ? "Invalid date format"
            : !isValidUrl
              ? "Invalid url format"
              : required && isRequired(value) && hasTriedToSubmit
                ? "Required"
                : item.getErrorText
                  ? getErrorText(commonCallbackProps)
                  : "Error";
      }
    }
  });

  return helperText;
}

const useFormTable = <DataType = any, ExtraProps = any, ItemsTypes = string>({
  extraProps,
  formGlue,
  getStructure,
  initialFormData,
  setIsFormValidExternal,
  toSubmitData,
}: {
  extraProps?: ExtraProps;
  formGlue?: FormGlue<DataType[]>;
  getStructure: UserFormTableGetStructure<DataType, ExtraProps, ItemsTypes>;
  initialFormData?: DataType[];
  setIsFormValidExternal?: (val: boolean) => void;
  toSubmitData?: ToSubmitData<DataType>;
}) => {
  const [t] = useTranslationWrapper();
  const navigate = useNavigate();
  const [formData, setFormDataInternal] = useState([
    ...(initialFormData?.map((data) => ({ ...data })) || []),
  ]);
  const [_initialFormData, _setInitialFormData] = useState([
    ...(initialFormData || []),
  ]);

  const setFormData = (data: DataType[]) => {
    setFormDataInternal(data);
    formGlue?.setFormDataInternal(data);
  };

  // Add setFormData to formGlue
  if (formGlue) {
    formGlue.setFormData = setFormData;
  }

  const structure = getStructure({ extraProps, navigate, setFormData, t });

  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  // Only runs on first render when used with callback
  const [helperText, setHelperText] = useState(() =>
    getHelperText<DataType>({
      data: initialFormData,
      structure,
      hasTriedToSubmit,
    }),
  );

  // Only runs on first render when used with callback
  const [isFormValid, setIsFormValid] = useState(() => {
    const isValid = checkIfFormIsValid<DataType>({
      data: initialFormData,
      structure,
    });

    return isValid;
  });

  useEffect(() => {
    if (initialFormData && !_.isEqual(_initialFormData, initialFormData)) {
      setFormData([...initialFormData]);
      setHelperText(
        getHelperText<DataType>({
          data: initialFormData,
          structure,
          hasTriedToSubmit,
        }),
      );
      _setInitialFormData(clone(initialFormData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    setHelperText(
      getHelperText<DataType>({ data: formData, structure, hasTriedToSubmit }),
    );
    setIsFormValid(
      checkIfFormIsValid<DataType>({
        data: formData,
        structure,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, hasTriedToSubmit]);

  useEffect(() => {
    if (setIsFormValidExternal) {
      setIsFormValidExternal(isFormValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  useEffect(() => {
    if (formGlue) {
      formGlue.setIsFormValid(isFormValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  useEffect(() => {
    if (formGlue) {
      // If changes before submit has to be applied to list data then
      // run toSubmitData on each change
      const submitData = toSubmitData
        ? toSubmitData({
            data: formData,
            initData: initialFormData,
          })
        : formData;

      // Same as above but for initial data. Initial submit data can then me used
      // to compare with submit data to see if changes has been made
      const submitDataInit = toSubmitData
        ? toSubmitData({
            data: initialFormData,
            initData: initialFormData,
          })
        : initialFormData;
      formGlue.setSubmitData(submitData);
      formGlue.setSubmitDataInitial(submitDataInit);

      if (!_.isEqual(formData, formGlue?.formData)) {
        formGlue?.setFormDataInternal(formData);
      }
    }
  }, [formData]);

  // This helps update helperText based on formGlue
  useEffect(() => {
    if (formGlue) {
      setHelperText(
        getHelperText<DataType>({
          data: formData,
          structure,
          hasTriedToSubmit: formGlue?.hasTriedToSubmit,
        }),
      );
    }
  }, [formGlue?.hasTriedToSubmit]);

  return {
    formData,
    helperText,
    hasTriedToSubmit,
    isFormValid,
    setFormData,
    setHelperText,
    setHasTriedToSubmit,
    structure,
  };
};

export default useFormTable;
