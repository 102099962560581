import moment from "moment/moment";
import { ContractOfferTemplateData } from "../../../accurasee-backend-types/app/contract_offer/contract_offer.types";
import { MicrosoftTemplateTagItemBase } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

const templateTagsContractOffer: MicrosoftTemplateTagItemBase<ContractOfferTemplateData>[] =
  [
    {
      key: "anvmail",
      description: "My email",
      property: "user.email",
      source: "template_data",
    },
    {
      key: "dagensdatum",
      description: "Todays date in company timezone",
      property: (data) =>
        data?.companyId?.reminderTimeZone
          ? moment().tz(data?.companyId?.reminderTimeZone).format("YYYY-MM-DD")
          : "Failure: Can not set data since timezone is missing on company",
      source: "template_data",
    },
    {
      key: "dokumentnamn; Anbud",
      description: "Document name",
      property: (data) => `${data?.name}; Anbud`,
      source: "template_data",
    },
    {
      key: "egetforetag",
      description: "My company",
      property: "companyId.name",
      source: "template_data",
    },
    {
      key: "egenkontaktperson",
      description: "Our reference/s (contract offer owners)",
      property: (data) =>
        data?.owners.map((o) => `${o.firstName} ${o.lastName}`).join(",") || "",
      source: "template_data",
    },
    {
      key: "egenemail",
      description: "Our email/s (contract offer owners)",
      property: (data) => data?.owners.map((o) => o.email).join(",") || "",
      source: "template_data",
    },
    {
      key: "foretag",
      description: "Customer company",
      property: "customerId.name",
      source: "template_data",
    },
    {
      key: "kontaktfornamn",
      description: "Customer contact first name",
      property: "contactPersonId.firstName",
      source: "template_data",
    },
    {
      key: "kontaktefternamn",
      description: "Customer contact last name",
      property: "contactPersonId.lastName",
      source: "template_data",
    },
    {
      key: "ort",
      description: "Customer city",
      property: "customerId.city",
      source: "template_data",
    },
    {
      key: "postadress",
      description: "Customer mailing address",
      property: "customerId.address1",
      source: "template_data",
    },
    {
      key: "postnr",
      description: "Customer zipcode",
      property: "customerId.zipCode",
      source: "template_data",
    },
    {
      key: "projekt",
      description: "Project name",
      property: "name",
      source: "template_data",
    },
    {
      key: "projektanbsum",
      description: "Offer total price",
      property: "offerPrice",
      source: "template_data",
    },
    {
      key: "projektarbetschef",
      description: "Project manager name",
      property: (data) =>
        data?.owners.map((o) => `${o.firstName} ${o.lastName}`).join(",") || "",
      source: "template_data",
    },
    {
      key: "projektbestallare",
      description: "Customer company",
      property: "customerId.name",
      source: "template_data",
    },
    {
      key: "projektbestkontaktperson",
      description: "Customer company, contact person first last",
      property: (data) =>
        `${data?.contractPersonId?.firstName} ${data?.contractPersonId?.lastName}`,
      source: "template_data",
    },
    {
      key: "projektnr",
      description: "Project id",
      property: "projectExternalId",
      source: "template_data",
    },
    {
      key: "sidhuvud",
      description: "Todays date in company timezone",
      property: (data) =>
        data?.companyId?.reminderTimeZone
          ? moment().tz(data?.companyId?.reminderTimeZone).format("YYYY-MM-DD")
          : "Failure: Can not set data since timezone is missing on company",
      source: "template_data",
    },
    {
      key: "upprattadav",
      description: "Create by",
      property: (data) => `${data?.user?.firstName} ${data?.user?.lastName}`,
      source: "template_data",
    },
  ];

export default templateTagsContractOffer;
