/**
 * This is to check if an array of a range is overlapped
 * @returns {boolean} true for overlapped and false for non overlapped
 *
 */

export type ExistingRange = {
  firstNumber: number;
  lastNumber: number;
};

// For single number
interface checkOverlappedNumberProps {
  existingRanges?: ExistingRange[];
  num: number;
  prefix?: string;
}

// For numbers (firstNumber and lastNumber)
interface checkOverlappedNumbersProps {
  existingRanges?: ExistingRange[];
  range: ExistingRange;
}

export const checkOverlappedNumber = ({
  existingRanges,
  num,
}: checkOverlappedNumberProps) => {
  let isOverlapped = false;
  let arrIndex = -1;

  if (!num) return;
  else if (!existingRanges || existingRanges.length === 0)
    return { isOverlapped: true, arrIndex }; // Dimension only match

  existingRanges?.forEach((e, index) => {
    if (num >= e.firstNumber && num <= e.lastNumber) {
      isOverlapped = true;
      arrIndex = index;
    }
  });

  return { isOverlapped, arrIndex };
};

const checkOverlappedNumbers = ({
  existingRanges,
  range,
}: checkOverlappedNumbersProps) => {
  if (!existingRanges) return;

  const { firstNumber, lastNumber } = range;

  let isOverlapped = false;
  let list: number[] = [];

  if (!firstNumber || !lastNumber) return;

  // TODO: Fix this
  if (firstNumber && lastNumber) {
    const newArr = [...existingRanges, { firstNumber, lastNumber }];
    newArr.forEach((e) => {
      newArr.forEach((ee) => {
        if (ee !== e) {
          if (
            e.firstNumber >= ee.firstNumber &&
            e.firstNumber <= ee.lastNumber
          ) {
            isOverlapped = true;
            list.push(e.firstNumber);
          }

          if (e.lastNumber >= ee.firstNumber && e.lastNumber <= ee.lastNumber) {
            isOverlapped = true;
            list.push(e.lastNumber);
          }
        }
      });
    });
  }

  // This list shows if any of input numbers is overlapped
  const filteredList = list.filter(
    (el) => el === firstNumber || el === lastNumber,
  );
  return { isOverlapped, list: filteredList };
};

export default checkOverlappedNumbers;
