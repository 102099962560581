/**
 *Created by Julius Abelson on 2023-02-23
 */
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import StatusCell from "src/components/Complex/Tables/StatusCell";
import VatWarningTooltip from "src/components/Basic/Simple/Tooltips/VatWarningTooltip";
import get from "../Utils/get";
import getRequired from "../Utils/getRequired";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";
import throwErrorDataName from "../Utils/throwErrorDataName";
import toCurrency from "src/utils/toCurrency";
import useTranslation from "../../../../hooks/useTranslationWrapper";
import { Box } from "@mui/material";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";

type TextItemTProps = CommonBuilderContainerTypes & {
  isCurrency?: boolean;
};

const BadgeItem = (props: TextItemTProps) => {
  const [t] = useTranslation(props.translationNamespace);
  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  if (!props.item.dataName) {
    return throwErrorDataName({ item: props.item, itemName: "BadgeItem" });
  }

  let value = getValue(commonCallbackProps);

  const isCurrency =
    (props.item.currencyCode || props.data.currencyCode) && props.isCurrency;
  if (isCurrency) {
    value = toCurrency({
      value,
      currencyCode: props.item.currencyCode || props.data.currencyCode,
      toInteger: true, // not use decimal places for displaying data
    });
  }
  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <InputLabel
        required={getRequired(commonCallbackProps)}
        shrink
        htmlFor={props.item.dataName}
      >
        {t(props.item.label)}
        {props.item.vatTypeTooltip && (
          <VatWarningTooltip {...props.item.vatTypeTooltip} />
        )}
      </InputLabel>
      <Box
        title={props.format ? props.format(value) : value || ""}
        sx={{
          display: "block",
          paddingY: "1.0rem",
          borderRadius: "4px",
          minHeight: "50px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          ...get("sx", commonCallbackProps),
          ...(props.item.leftAlign
            ? { paddingX: 0, width: "fit-content" }
            : { paddingX: "1.0rem", width: "100%" }),
        }}
      >
        <StatusCell
          status={value}
          additionalText={get("badgeAdditionalText", commonCallbackProps) || ""}
        />
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

export default BadgeItem;
