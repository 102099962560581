/**
 *Created by Mikael Lindahl on 2023-12-15
 */

import ButtonSubmitCore, {
  ButtonSubmitCoreTProps,
} from "../../Mixed/Buttons/ButtonSubmitCore";

type ButtonDeleteWithYesNoDialogProps = Omit<ButtonSubmitCoreTProps, "label">;

const ButtonDeleteWithYesNoDialog = (
  props: ButtonDeleteWithYesNoDialogProps,
) => {
  return <ButtonSubmitCore color={"warning"} label={"delete"} {...props} />;
};

export default ButtonDeleteWithYesNoDialog;
