/**
 *Created by Mikael Lindahl on 2023-02-15
 */

import { BuilderContainerCallbackProps } from "../CommonBuilderContainerTypes";

const getRequired = (props: BuilderContainerCallbackProps) => {
  return typeof props.item.required === "function"
    ? props.item.required(props)
    : props.item.required;
};

export default getRequired;
