/**
 * Created by Mikael Lindahl on 2023-12-28
 * This section has Heading (label) at the same line to
 * the table toolbar
 */

import SectionCore, {
  SectionCoreTProps,
} from "../../Mixed/Sections/SectionCore";

const SectionForTableWithToolbar = (
  props: Omit<SectionCoreTProps, "withTightTableHeading">,
) => {
  return (
    <SectionCore
      withTightTableHeading
      boxStyle={{ padding: 0, position: "relative" }}
      {...props}
    >
      {props.children}
    </SectionCore>
  );
};

export default SectionForTableWithToolbar;
