/**
 *Created by Mikael Lindahl on 2023-11-30
 */
import InvoicePlanTemplateBase from "src/components/Screens/InvoiceplanTemplate/InvoiceplanTemplate";
import Page from "src/components/Basic/Mixed/Pages/Page";

const InvoicePlanTemplate = () => {
  return (
    <Page
      label={"Edit invoice plan template"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: "Invoice plan templates",
          link: "/app/contracts/invoiceplantemplates",
        },
        {
          label: "Edit invoice plan template",
        },
      ]}
    >
      <InvoicePlanTemplateBase />
    </Page>
  );
};

export default InvoicePlanTemplate;
