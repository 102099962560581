export type StylingType =
  | ""
  | "active"
  | "alert"
  | "black"
  | "deactivated"
  | "disabled"
  | "information"
  | "information-1"
  | "message"
  | "success"
  | "success-no-background"
  | "success-1"
  | "success-2"
  | "success-3"
  | "warning"
  | "warning-1"
  | "warning-2"
  | "warning-3";

export interface stylingProps {
  color?: string;
  backgroundColor?: string;
}

const getStylingByStatusType = (
  type: StylingType = "success",
): stylingProps => {
  let styling = {};
  switch (type) {
    case "active":
      styling = {
        color: "var(--green-7)",
        backgroundColor: "var(--green-7)",
      };
      break;
    case "deactivated":
      styling = {
        color: "var(--gray-5)",
        backgroundColor: "var(--gray-5)",
      };
      break;
    case "success":
      styling = {
        color: "var(--green-2)",
        backgroundColor: "var(--green-4)",
      };
      break;
    case "success-no-background":
      styling = {
        color: "var(--green-2)",
      };
      break;
    case "success-1":
      styling = {
        color: "var(--green-6)",
        backgroundColor: "var(--green-5)",
      };
      break;
    case "success-2":
      styling = {
        color: "var(--white-primary)",
        backgroundColor: "var(--green-7)",
      };
      break;
    case "success-3":
      styling = {
        color: "var(--green-1)",
        backgroundColor: "var(--green-8)",
      };
      break;
    case "warning":
      styling = {
        color: "var(--black-9)",
        backgroundColor: "var(--warning-1)",
      };
      break;
    case "warning-1":
      styling = {
        color: "var(--black-10)",
        backgroundColor: "var(--warning-2)",
      };
      break;
    case "warning-2":
      styling = {
        color: "var(--white-primary)",
        backgroundColor: "var(--orange-1)",
      };
      break;
    case "warning-3":
      styling = {
        color: "var(--orange-2)",
        backgroundColor: "var(--orange-3)",
      };
      break;
    case "information":
      styling = {
        color: "var(--information-1)",
        backgroundColor: "var(--information)",
      };
      break;
    case "information-1":
      styling = {
        color: "var(--white-primary)",
        backgroundColor: "var(--information-2)",
      };
      break;
    case "alert":
      styling = {
        color: "var(--negative-2)",
        backgroundColor: "var(--negative-3)",
      };
      break;
    case "disabled":
      styling = {
        color: "var(--black-primary)",
        backgroundColor: "var(--white-3)",
      };
      break;
    case "black":
      styling = {
        color: "black",
        backgroundColor: "inherit",
      };
      break;
    case "message":
      styling = {
        color: "var(--black-primary)",
        backgroundColor: "var(--gray-2)",
      };
      break;
    default:
      styling = {
        color: "var(--black-primary)",
        backgroundColor: "var(--white-3)",
      };
      break;
  }
  return styling;
};

export default getStylingByStatusType;
