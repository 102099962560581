import _c from "src/constants/Constants";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import { Types } from "mongoose";
import ContactPersonContainer from "src/components/Screens/ContactPerson/ContactPersonContainer";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";

type ContactPersonModalTProps = {
  customerId?: Types.ObjectId | string;
  data?: ContactPerson;
  openModal: boolean;
  setOpenModal: (v: boolean) => void;
  postCreate?: (data: Partial<ContactPerson>) => void;
};

export const ContactPersonModal = ({
  customerId,
  data,
  openModal,
  setOpenModal,
  postCreate,
}: ContactPersonModalTProps) => {
  return (
    <ModalWrapper
      handleCloseModal={() => setOpenModal(false)}
      openModal={openModal}
      label={data ? "EDIT CONTACT PERSON" : "CREATE A CONTACT PERSON"}
      boxStyle={{ width: "600px" }}
    >
      <ContactPersonContainer
        customerId={customerId}
        data={data}
        setOpenModal={setOpenModal}
        postCreate={postCreate}
      />
    </ModalWrapper>
  );
};
export default ContactPersonModal;
