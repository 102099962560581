/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { Box, FormControl, Grid, IconButton } from "@mui/material";
import getValue from "../Utils/getValue";
import EditIcon from "@mui/icons-material/Edit";
import getDisabled from "../Utils/getDisabled";
import getHelperText from "../Utils/getHelperText";
import FormHelperText from "@mui/material/FormHelperText";

type TextWithEditButtonColumnItemTProps = CommonBuilderTableTypes;

const TextWithEditButtonColumnItem = (
  props: TextWithEditButtonColumnItemTProps,
) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  return (
    <FormControl
      sx={{ backgroundColor: "transparent" }}
      error={Boolean(getHelperText(getHelperTextProps))}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={10}>
          {getValue(commonCallbackProps)}
        </Grid>
        <Grid item xs={2}>
          <IconButton
            disabled={itemDisabled}
            onClick={() =>
              props.item.onClick && props.item?.onClick(commonCallbackProps)
            }
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </Box>
      {getHelperText(getHelperTextProps) && (
        <FormHelperText
          sx={{
            marginLeft: 2,
            color: "red !important",
            textAlign: "left",
          }}
        >
          {getHelperText(getHelperTextProps)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextWithEditButtonColumnItem;
