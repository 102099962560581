export const normalizedYearResultData = ({
  rawData,
  increaseColor,
  decreaseColor,
  totalColor,
}: {
  rawData: any;
  increaseColor: string;
  decreaseColor: string;
  totalColor: string;
}) => {
  let result: any = [];

  // Filter out value = 0 in order not to show them in chart
  const revenues = rawData?.revenues
    ?.filter((item: { label: string; value: number }) => !!item.value)
    ?.reduce(
      (
        resultArray: {
          label: string;
          value: number[];
          fill: string;
          diff: number;
        }[],
        currentElement: { label: string; value: number },
      ) => {
        const prevValue =
          resultArray.length > 0
            ? resultArray[resultArray.length - 1].value[1]
            : 0;
        const newValue = prevValue + currentElement.value;
        resultArray.push({
          ...currentElement,
          value: [prevValue, newValue],
          fill: increaseColor,
          diff: newValue - prevValue,
        });
        return resultArray;
      },
      [],
    );
  const costs = rawData?.costs
    ?.filter((item: { label: string; value: number }) => !!item.value)
    ?.reduce(
      (
        resultArray: {
          label: string;
          value: number[];
          fill: string;
          diff: number;
        }[],
        currentElement: { label: string; value: number },
      ) => {
        const prevValue =
          resultArray.length > 0
            ? resultArray[resultArray.length - 1].value[1]
            : rawData.totalRevenue || 0;
        const newValue = prevValue - currentElement.value;
        resultArray.push({
          ...currentElement,
          value: [prevValue, newValue],
          fill: decreaseColor,
          diff: prevValue - newValue,
        });
        return resultArray;
      },
      [],
    );
  result = result.concat(revenues);
  result = result.concat(costs);
  result = result.concat({
    label: "Net income",
    value: [rawData.netIncome, 0],
    fill: totalColor,
    diff: rawData.netIncome,
  });

  return result;
};
